<template>
  <div>
    <LuottotietoSopimus ref="luottotietoSopimus" :btn="false" />
    <v-btn v-if="btn" color="primary" @click="avaaLuottotiedot()">
      Hae Luottotiedot
    </v-btn>
    <v-dialog
      v-model="lsAuki"
      persistent
      :fullscreen="wSize.sizeClass.value === 'small'"
    >
      <v-card class="dialog-card">
        <div class="dialog-header">
          <h1>Luottotietojen Haku</h1>
          <v-chip color="action">Beta</v-chip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <div class="background-mirror"></div>
                <v-icon color="action" large>mdi-information</v-icon>
              </div>
            </template>
            <div>
              Yritysluottotietokysely:
              <ul>
                <li>Yksilöintitiedot</li>
                <li>Perustiedot</li>
                <li>Rekisteritiedot</li>
                <li>Maksuhäiriöt ja ref-merkintä</li>
              </ul>
              Henkilöluottotietokysely:
              <ul>
                <li>Henkilöluottotiedot, sis. yritysyhteydet</li>
              </ul>
            </div>
          </v-tooltip>

          <v-spacer></v-spacer>
          <v-btn icon @click="suljeLuottotietoDialog()">
            <v-icon>close</v-icon>
          </v-btn>
        </div>
        <div class="dialog-content-container">
          <div v-if="currentStep === 'loading'" class="loading-container">
            <v-progress-circular indeterminate color="primary" />
          </div>
          <div
            v-else-if="currentStep === 'error'"
            class="content-error content"
          >
            <div
              v-if="!$store.state.user.vainYksiPaamies"
              class="valitse-paamies"
            >
              <CardTitle text="Päämies" :center="true" color="black--text" />
              <AutocompletePaamies
                v-model="user.valittuPaamies"
                :dense="true"
                :outlined="true"
                :emit-empty="false"
                label=""
              />
            </div>

            <div>
              {{ error?.text }}
            </div>
            <v-btn
              v-if="error?.btnText"
              color="action"
              @click="error?.btnClick()"
            >
              {{ error?.btnText }}
            </v-btn>
          </div>
          <div
            v-else-if="currentStep === 'haku'"
            class="content-perustiedot content"
          >
            <div class="form-perustiedot">
              <div>
                <v-form ref="form">
                  <AutocompletePaamies
                    v-if="!$store.state.user.vainYksiPaamies"
                    v-model="user.valittuPaamies"
                    :dense="true"
                    :outlined="true"
                  />
                  <v-select
                    v-if="naytaTyyppi"
                    v-model="haku.tyyppi"
                    label="Haun tyyppi"
                    outlined
                    :items="tyyppiOptions"
                    item-text="teksti"
                    item-value="arvo"
                    required
                    :rules="[$validationRules.required]"
                  ></v-select>
                  <v-select
                    v-if="haku.tyyppi === 'H'"
                    v-model="haku.kayttotarkoitus"
                    label="Käyttötarkoitus"
                    outlined
                    :items="kayttotarkoitusOptionsArray"
                    item-text="teksti"
                    item-value="arvo"
                    required
                    :rules="[$validationRules.required]"
                  ></v-select>
                  <v-text-field
                    v-if="haku.tyyppi"
                    v-model="haku.tunnus"
                    :label="tunnusLabel"
                    outlined
                    required
                    :rules="tunnusRules"
                  ></v-text-field>
                </v-form>
              </div>
            </div>
            <div v-if="haku.tyyppi" class="footer">
              <v-btn
                color="action"
                :disabled="!luottotiedotHaettavissa || !valittuHinta"
                @click="haeLuottotiedot"
              >
                Hae Luottotiedot
                <span v-if="valittuHinta">({{ valittuHinta }}€)</span>
              </v-btn>
              {{ disabledMsg }}
            </div>
          </div>

          <div
            v-else-if="currentStep === 'raportti'"
            class="content-raportti content"
          >
            <LuottotietoHakuRaportti v-if="raporttiData" :data="raporttiData" />
            <div v-else>
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { useWindowSize } from "@/composables/windowSize";
import { mapState } from "vuex";
import LuottotietoSopimus from "@/components/LuottotietoSopimus";
import LuottotietoHakuRaportti from "@/components/LuottotietoHakuRaportti";
import { LuottotietoSopimusTila } from "../enums";
import AutocompletePaamies from "@/components/AutocompletePaamies";

export default {
  name: "Luottotiedot",
  components: {
    AutocompletePaamies,
    LuottotietoSopimus,
    LuottotietoHakuRaportti,
  },
  props: {
    asiakas: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    btn: {
      type: Boolean,
      required: false,
      default() {
        return true;
      },
    },
  },
  data() {
    return {
      lsAuki: false,
      currentStep: "haku",
      sopimus: null,
      luottotietoData: null,
      raporttiData: null,
      naytaTyyppi: true,
      naytaTunnus: true,
      haku: {
        tyyppi: null, // "H"
        tunnus: null, // "010100-123D" or "0114451-6"
        paamies_id: null, // 1000
        kayttotarkoitus: null, // "1"
      },
      error: null,
      tyyppiOptions: [
        {
          teksti: "Henkilötietohaku",
          arvo: "H",
        },
        {
          teksti: "Yritystietohaku",
          arvo: "Y",
        },
      ],
      kayttotarkoitusOptions: {
        1: "Luotonmyöntäminen",
        2: "Luotonvalvonta",
        3: "Perintä",
        5: "Vastuuhenkilöasema",
        6: "Henkilön oma suostumus",
        7: "Huoneenvuokraus",
        8: "Työnhakijan tai työntekijän arviointi",
        9: "Viranomaisen lakiin perustuva tiedonsaantioikeus tai viranomaistuen myöntäminen",
        A: "Takaus tai pantin anto",
        B: "Sopimusehtojen määrittely jos sopimuksen tekemisestä ei voi kieltäytyä",
        C: "Todistus velvoitteiden hoitokyvystä ja rahanpesun ehkäiseminen",
      },
    };
  },
  computed: {
    ...mapState({
      luottotiedot: (state) => state.luottotiedot,
      user: (state) => state.user,
    }),
    wSize() {
      return useWindowSize();
    },

    valittuPaamies() {
      return this.user.valittuPaamies;
    },

    tunnusLabel() {
      if (this.haku.tyyppi === "H") {
        return "Henkilötunnus";
      } else if (this.haku.tyyppi === "Y") {
        return "Y-Tunnus";
      } else {
        return "Henkilötunnus / Y-Tunnus";
      }
    },
    luottotiedotHaettavissa() {
      // Käytetään computedia painikkeen rules checkkiin, koska painikkeen validation vaihtuu formin haku.tyyppi kentän perusteella.
      if (this.haku.tyyppi === "H") {
        if (!this.luottotiedot.sopimus.henkilo) {
          return false;
        }
        if (
          this.$validationRules.required(this.haku.kayttotarkoitus) !== true ||
          this.$validationRules.onkoHtunnus(this.haku.tunnus) !== true
        ) {
          return false;
        }
        return true;
      } else if (
        this.haku.tyyppi === "Y" &&
        this.$validationRules.onkoYtunnus(this.haku.tunnus) === true
      ) {
        return true;
      } else {
        return false;
      }
    },
    disabledMsg() {
      if (this.haku.tyyppi === "H" && !this.luottotiedot.sopimus.henkilo) {
        return this.$t(
          "common:creditRating.personalCreditInformationInquiriesNeed"
        );
      } else {
        return null;
      }
    },
    tunnusRules() {
      if (this.haku.tyyppi === "H") {
        return [
          this.$validationRules.required,
          this.$validationRules.onkoHtunnus,
        ];
      } else if (this.haku.tyyppi === "Y") {
        return [
          this.$validationRules.required,
          this.$validationRules.onkoYtunnus,
        ];
      } else {
        return [this.$validationRules.required];
      }
    },
    kayttotarkoitusOptionsArray() {
      return Object.entries(this.kayttotarkoitusOptions).map(
        ([arvo, teksti]) => ({
          arvo,
          teksti,
        })
      );
    },
    valittuHinta() {
      const porrashinnasto = this.valittuPaamies.porrashinnasto_set;
      let hinta = "";
      if (this.haku.tyyppi === "H") {
        hinta = porrashinnasto.find(
          (item) => item.koodi === "ONLINE_LUOTTOTIETO_H_41"
        ).ahinta;
        return hinta;
      } else if (this.haku.tyyppi === "Y") {
        hinta = porrashinnasto.find(
          (item) => item.koodi === "ONLINE_LUOTTOTIETO_Y_Q057"
        ).ahinta;
        return hinta;
      } else {
        return null;
      }
    },
  },
  watch: {
    valittuPaamies(newValue, oldValue) {
      if (newValue && newValue?.id !== oldValue?.id && this.lsAuki === true) {
        this.avaaLuottotiedot();
      }
    },
  },
  methods: {
    async haeLuottotiedot() {
      if (this.$refs.form.validate()) {
        this.currentStep = "raportti";
        const tyyppi = this.haku.tyyppi;
        const tunnus = this.haku.tunnus;
        const paamies_id = this.haku.paamies_id;
        const kayttotarkoitus = this.haku.kayttotarkoitus;

        await this.$store
          .dispatch("luottotiedot/haeLuottotiedot", {
            tyyppi,
            tunnus,
            paamies_id,
            kayttotarkoitus,
          })
          .then(() => {
            if (this.luottotiedot.data.consumerResponse?.errorMessage) {
              this.setError(
                this.luottotiedot.data.consumerResponse.errorMessage.errorText
              );
            } else if (this.luottotiedot.data.companyResponse?.errorMessage) {
              this.setError(
                this.luottotiedot.data.companyResponse.errorMessage.errorText
              );
            } else {
              this.luoLuottotietoRaportti();
            }
          })
          .catch((error) => {
            this.setError(error.message);
          });
      } else {
        this.setError("Tietojen validoinnissa tapahtui virhe.");
      }
    },
    async avaaLuottotiedot() {
      this.currentStep = "loading";
      this.lsAuki = true;

      if (!this.user.valittuPaamies.id) {
        return this.setError("Tarkista valittu päämies.");
      }

      // Alustetaan arvot
      this.haku.paamies_id = this.user.valittuPaamies.id;

      await this.$store.dispatch(
        "luottotiedot/haeSopimuksenTila",
        this.haku.paamies_id
      );

      if (this.luottotiedot.sopimus) {
        if (this.luottotiedot.sopimus.tila === LuottotietoSopimusTila.EI_OLE) {
          this.setError(
            "Valitettavasti sinulla ei ole oikeuksia hakea luottotietoja, luottotietojen hakemiseen tarvitsemme luottotietosopimuksen.",
            "Tee Luottotietosopimus",
            this.avaaSopimusnakyma
          );
        } else if (
          this.luottotiedot.sopimus.tila ===
          LuottotietoSopimusTila.ODOTTAA_ALLEKIRJOITUSTA
        ) {
          this.setError(
            "Emme ole saaneet vielä vahvistusta siitä, että sopimus on allekirjoitettu. Jos olet allekirjoittanut sopimuksen, voit käydä vahvistamassa sen sopimusnäkymästä.",
            "Siirry sopimusnäkymään",
            this.avaaSopimusnakyma
          );
        } else if (this.haku.paamies_id === null) {
          this.setError("Tarkista valittu päämies.");
        } else {
          this.currentStep = "haku";
          // Käsitellään propit jos on
        }
      } else {
        this.setError(
          "Valitettavasti sinulla ei ole oikeuksia hakea luottotietoja, luottotietojen hakemiseen tarvitsemme luottotietojenhakusopimuksen.",
          "Tee Luottotietosopimus",
          this.avaaSopimusnakyma
        );
      }
    },
    luoLuottotietoRaportti() {
      if (this.haku.tyyppi === "H" && this.luottotiedot.data.consumerResponse) {
        const cr = this.luottotiedot.data.consumerResponse;
        this.raporttiData = {
          type: "41",
          valuutta: cr.responseHeader?.currencyCode,
          pvm: cr.responseHeader?.timeStamp.date,
          tarkoitus: this.kayttotarkoitusOptions[this.haku.kayttotarkoitus],
          hetu: cr.personIdentification?.personId,
          nimi: cr.personIdentification?.name,
          osoite: {
            street: cr.personIdentification?.addressWhenDefaulted?.street,
            zip: cr.personIdentification?.addressWhenDefaulted?.zip,
            town: cr.personIdentification?.addressWhenDefaulted?.town,
          },
          yritysyhteyksia:
            cr.personInChargeSummary?.partisipationInBusinessRow?.length === 0
              ? false
              : true,
          liiketoimintakielto:
            cr.creditInformationData?.creditInformationEntrySummary
              .banOfBusiness,
          edunvalvonta:
            cr.creditInformationData?.creditInformationEntrySummary
              ?.supervision,
          merkinnat: cr.creditInformationData?.creditInformationEntryRow,
          yritysyhteydet: cr.personInChargeSummary?.partisipationInBusinessRow,
        };
      } else if (
        this.haku.tyyppi === "Y" &&
        this.luottotiedot.data.companyResponse
      ) {
        const cr = this.luottotiedot.data.companyResponse;

        this.raporttiData = {
          type: "Q057", // Q057, Q085, Q392

          yksilointi: {
            nimi: cr.identificationData?.name,
            yritysmuoto: cr.identificationData?.companyFormText,
            kieli: cr.identificationData?.companyLanguageCode,
            kotipaikka: cr.identificationData?.domicile,
            paatoimiala: {
              koodi: cr.identificationData.lineOfBusiness?.lineOfBusinessCode,
              teksti: cr.identificationData.lineOfBusiness?.lineOfBusinessText,
            },
            kayntiosoite: {
              katu: cr.identificationData.address?.street,
              kaupunki: cr.identificationData.address?.town,
              zip: cr.identificationData.address?.zip,
            },
            postiosoite: {
              katu: cr.identificationData.postalAddress?.street,
              kaupunki: cr.identificationData.postalAddress?.town,
              zip: cr.identificationData.postalAddress?.zip,
            },
            yhteystiedot: {
              email: cr.identificationData.contactInformation?.email,
              puhelin: cr.identificationData.contactInformation?.phone,
              fax: cr.identificationData.contactInformation?.fax,
              www: cr.identificationData.contactInformation?.www,
            },
          },
          perus: {
            yritysmuoto: {
              koodi: cr.companyBasics.statusAndType?.companyFormCode,
              teksti: cr.companyBasics.statusAndType?.companyFormText,
            },
            paatoimiala: {
              koodi:
                cr.companyBasics.statusAndType?.lineOfBusiness
                  .lineOfBusinessCode,
              teksti:
                cr.companyBasics.statusAndType?.lineOfBusiness
                  .lineOfBusinessText,
            },
            sektoriuokitus: cr.companyBasics.statusAndType?.sectorText,
            valuutta: cr.responseHeader?.currencyCode,
            liikevaihtoluokka: {
              min: cr.companyBasics.turnoverRow[0]?.latestTurnoverMin,
              max: cr.companyBasics.turnoverRow[0]?.latestTurnoverMax,
            },
            henkilostoluokka: {
              min: cr.companyBasics.personnelCategoryRow[0]
                ?.latestPersonnelCategoryMin,
              max: cr.companyBasics.personnelCategoryRow[0]
                ?.latestPersonnelCategoryMax,
            },
            osakepaaoma: cr.companyBasics.shareCapitalData?.shareCapital,
            osakkeidenLkm: cr.companyBasics.shareCapitalData?.numberOfShares,
            tuontitoimintaa: cr.companyBasics.statusAndType?.importStatus, // Voi olla myös null, vaikka pitäisi tulla 0 tai 1, tarvitaan toinen test case
            vientitoimintaa: cr.companyBasics.statusAndType?.exportStatus, // Voi olla myös null, vaikka pitäisi tulla 0 tai 1, tarvitaan toinen test case
            omistustyyppi: cr.companyBasics.statusAndType?.ownerTypeText,
            pvmAloitus: cr.companyBasics.statusAndType?.startingDate,
          },
          rekisteri: {
            tyonantaja: {
              nimi: "Työnantajarekisteri",
              tila: cr.registerData.employerRegister?.status,
              pvm: cr.registerData.employerRegister?.date,
            },
            kauppa: {
              nimi: "Kaupparekisteri",
              tila: cr.registerData.tradeRegister?.status,
              pvm: cr.registerData.tradeRegister?.date,
            },
            ennakkoperinta: {
              nimi: "Ennakkoperintärekisteri",
              tila: cr.registerData.prepaymentRegister?.status,
              pvm: cr.registerData.prepaymentRegister?.date,
            },
            arvolisavero: {
              nimi: "Arvolisäverovelvollisuus",
              tila: cr.registerData.liableToPayVAT?.status,
              pvm: cr.registerData.liableToPayVAT?.date,
              tyyppi: cr.registerData.liableToPayVAT?.type,
            },
            vakuutusmaksuvero: {
              nimi: "Vakuutusmaksuverovelvollisuus",
              tila: cr.registerData.liableToPayInsurancePremiumTax?.status,
              pvm: cr.registerData.liableToPayInsurancePremiumTax?.date,
            },
          },
          luottohairio: cr.companyPaymentDefaultData?.paymentDefaultRow,
        };
      } else {
        return this.setError("Raportin luonti ei onnistunut.");
      }
    },

    suljeLuottotietoDialog() {
      this.haku.tyyppi = null;
      this.haku.tunnus = null;
      this.haku.paamies_id = null;
      this.haku.kayttotarkoitus = null;
      this.lsAuki = false;
      this.currentStep = "haku";
      this.error = null;
      this.raporttiData = null;
    },
    avaaSopimusnakyma() {
      this.suljeLuottotietoDialog();
      this.$refs.luottotietoSopimus.avaaSopimusDialog();
    },
    setError(text = null, btnText = null, btnClick = null) {
      this.error = {
        text: text,
        btnText: btnText,
        btnClick: btnClick,
      };
      this.currentStep = "error";
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-card {
  display: flex;
  flex-direction: column;
  padding: 0rem 1rem 1rem 1rem;
  gap: 1rem;
  overflow-y: auto;
  height: 90vh;
}
.dialog-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  position: sticky;
  top: 0;
  background-color: var(--v-white-base);
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}
.dialog-content-container {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content-raportti {
  height: fit-content;
  min-height: 100%;
}
.content-error {
  gap: 1rem;
}
.content-perustiedot {
  .form-perustiedot {
    height: 50%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.loading-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background: var(--v-primary-base);
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--v-accent-base);
}
.valitse-paamies {
  position: absolute;
  top: 25%;
}
.paamies {
  margin-bottom: 2rem;
}
.background-mirror {
  background-color: black;
  border-radius: 25px;
  position: absolute;
  height: 27px;
  width: 27px;
  margin: 4px;
}
</style>
