<template>
  <div>
    <CardTitle
      color="black--text"
      :text="$t('common:priceList')"
      :center="false"
    />

    <v-data-table
      :headers="TableHeadersPorrashinnasto"
      :items="porrashinnasto"
      :hide-default-footer="porrashinnasto?.length <= 10"
    >
      <template #item.ahinta="{ item }">
        {{ item.ahinta }}&nbsp;€ / kpl
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { TableHeadersPorrashinnasto } from "@/utils/tableHeaders";
import { mapState } from "vuex";

export default {
  name: "AsetuksetHinnasto",
  components: {},
  data() {
    return {
      TableHeadersPorrashinnasto: TableHeadersPorrashinnasto,
    };
  },
  computed: {
    ...mapState({
      paamies: (state) => state.user.valittuPaamies,
    }),
    porrashinnasto() {
      return this.paamies.porrashinnasto_set;
    },
  },
  methods: {},
};
</script>
<style scoped lang="scss"></style>
