import BaseOptimizedCountSelaa from "@/store/modules/baseOptimizedCountSelaa";
import { HttpError } from "@/utils/errors";
import { doRequestWithTimeout } from "@/utils/misc";

export default class TilitysSelaa extends BaseOptimizedCountSelaa {
  state() {
    return {
      ...super.state(),
      apiName: "Tilitys",
      searchTerms: { paamies: [] },
    };
  }

  mutations() {
    return {
      ...super.mutations(),
      resetViewViewKohtainen(state) {
        state.searchTerms = { paamies: [] };
      },
    };
  }

  actions() {
    return {
      ...super.actions(),
      async lataaErittely({ getters }, { tilitysId, erittelyId }) {
        const request = await doRequestWithTimeout(
          getters.api,
          {
            url: `${tilitysId}/erittely/${erittelyId}/`,
          },
          "doSingleRequest"
        );

        if (!request.success) throw new HttpError(request);

        return request;
      },
      async lataaLasku({ getters }, { laskuId, kirjeId }) {
        const api = getters.apiModule.getInstance("TilitysKululasku");

        const request = await doRequestWithTimeout(
          api,
          {
            url: `${laskuId}/${kirjeId}/`,
          },
          "doSingleRequest"
        );

        if (!request.success) throw new HttpError(request);

        return request;
      },
    };
  }
}
