<template>
  <v-form v-model="valid" @submit.prevent="submit">
    <v-row align="center">
      <v-col class="mr-6 shrink">
        <StatusIcon
          class="status-icon--rounded"
          type="timeline"
          size="small"
          :icon="icon"
          bg-color="green"
          :pointer="false"
        />
      </v-col>
      <v-col class="grow">
        <v-textarea
          ref="messageInput"
          v-model="value.message"
          class="pt-0 mt-0"
          rows="1"
          label=""
          :rules="[$validationRules.required]"
          :hide-details="true"
          required
          auto-grow
          color="action"
          :placeholder="msgInputPlaceholder"
          persistent-placeholder
          :validate-on-blur="false"
        />
      </v-col>
      <v-col class="shrink">
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn
              type="submit"
              icon
              class="ma-0 ml-4 success--text"
              :disabled="!valid || loading"
              :loading="loading"
              v-on="on"
            >
              <v-icon>send</v-icon>
            </v-btn>
          </template>
          <template #default>
            {{ submitBtnTooltipText }}
          </template>
        </v-tooltip>
      </v-col>
    </v-row>
    <PeruutaHyvaksyPainikerivi
      v-if="painikkeet"
      :submit-disabled="!valid || loading"
      :submit-loading="loading"
      :peruuta-teksti="peruutaTeksti"
      :submit-teksti="submitTeksti"
      @close="$emit('close')"
    />
  </v-form>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";

export default {
  name: "FormUusiViesti",
  mixins: [FormMixin],
  props: {
    focusOnUpdate: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    icon: {
      type: String,
      required: false,
      default() {
        return "comment";
      },
    },
    msgInputPlaceholder: {
      type: String,
      required: false,
      default() {
        return this.$t("common:formUusiViesti.newMessage");
      },
    },
    submitBtnTooltipText: {
      type: String,
      required: false,
      default() {
        return this.$t("common:sendMessage");
      },
    },
    peruutaTeksti: {
      type: String,
      required: false,
      default() {
        return this.$t("common:close");
      },
    },
    submitTeksti: {
      type: String,
      required: false,
      default() {
        return this.$t("common:submit");
      },
    },
    painikkeet: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
  },
  updated() {
    if (this.focusOnUpdate) {
      this.$nextTick(() => {
        this.$refs.messageInput.focus();
        this.$refs.messageInput.resetValidation();
      });
    }
  },
};
</script>

<style lang="scss" scoped></style>
