var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "info-icon-input-wrapper" },
    [
      _vm._t("default", function () {
        return [_c("v-icon", [_vm._v("info")])]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }