var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-autocomplete", {
        ref: "autocompleteYTJHaku",
        attrs: {
          label: _vm.$t("common:autocompleteYTJHaku.companySearch"),
          items: _vm.yritysItems,
          loading: _vm.autocompleteLoading,
          "search-input": _vm.searchQuery,
          "hide-no-data": !_vm.searchQuery || _vm.autocompleteLoading,
          "item-text": "autocomplete_selite",
          "item-value": "tunnus",
          "prepend-inner-icon": "search",
          placeholder: _vm.placeholder,
          "persistent-placeholder": "",
          outlined: "",
          "return-object": "",
          hint: _vm.inputHint,
        },
        on: {
          "update:searchInput": function ($event) {
            _vm.searchQuery = $event
          },
          "update:search-input": function ($event) {
            _vm.searchQuery = $event
          },
          input: _vm.kasitteleValinta,
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function ({ item }) {
              return [
                _c(
                  "v-list-item-content",
                  [
                    _c("v-list-item-title", [
                      _vm._v(" " + _vm._s(item.autocomplete_selite) + " "),
                    ]),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "no-data",
            fn: function () {
              return [
                _c(
                  "p",
                  { staticClass: "pa-4 autocomplete-ytj-haku__no-data-text" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "common:autocompleteYTJHaku.searchFoundNoCompanies"
                          )
                        ) +
                        " "
                    ),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "common:autocompleteYTJHaku.dataIsSearchedFromMsg"
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
                _c("ul", [
                  _c("li", [
                    _vm._v(
                      _vm._s(_vm.$t("common:autocompleteYTJHaku.ltdCompanies"))
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("common:autocompleteYTJHaku.housingLtdCompanies")
                      )
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      _vm._s(_vm.$t("common:autocompleteYTJHaku.cooperatives"))
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "common:autocompleteYTJHaku.insuranceCorporations"
                        )
                      )
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("common:autocompleteYTJHaku.publicLtdCompanies")
                      )
                    ),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.autocompleteValue,
          callback: function ($$v) {
            _vm.autocompleteValue = $$v
          },
          expression: "autocompleteValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }