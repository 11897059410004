<template>
  <v-form ref="formToimeksianto" @submit.prevent="submit">
    <v-row>
      <!-- Vasen sarake -->
      <v-col class="pa-6" md="6">
        <span class="paper__title hidden-md-and-up">
          {{ $t("common:collectionAssignment") }}
        </span>

        <!-- Päämies -->
        <v-hover v-slot="{ hover }" open-delay="50">
          <v-card
            class="pa-2 show-hidden-child-on-hover"
            :class="
              hover && $store.getters['user/salliPaamiehenTyhjennys']
                ? 'elevation-2 hoverBackground'
                : 'elevation-0'
            "
          >
            <div>
              <template v-if="toimeksianto.paamies.id">
                <v-row justify="space-between">
                  <v-col cols="6" class="py-0">
                    <h3>{{ $t("common:mandator") }}</h3>
                  </v-col>
                  <v-col
                    v-if="$store.getters['user/salliPaamiehenTyhjennys']"
                    cols="3"
                    class="col-icon py-0"
                  >
                    <LomakenappiIkonilla
                      ikoni="clear"
                      :infoteksti="$t('clear')"
                      @click="tyhjennaPaamies"
                    />
                  </v-col>
                </v-row>
                <OsoiteKappale
                  :renderoi-tyhja="true"
                  :nayta-nimi="true"
                  :nimi="toimeksianto.paamies.nimi"
                  :postiosoite="
                    toimeksianto.paamies.postiosoite
                      ? toimeksianto.paamies.postiosoite.postiosoite
                      : null
                  "
                  :postinumero="
                    toimeksianto.paamies.postiosoite
                      ? toimeksianto.paamies.postiosoite.postinumero
                      : null
                  "
                  :postitoimipaikka="
                    toimeksianto.paamies.postiosoite
                      ? toimeksianto.paamies.postiosoite.postitoimipaikka
                      : null
                  "
                />
              </template>
              <template v-else>
                <AutocompletePaamies
                  ref="autocompletePaamies"
                  v-model="toimeksianto.paamies"
                  :label="$t('common:mandator')"
                  :tarkista-toimeksianto-esto="true"
                  tarkistettava-oikeus="onlinerestapi.onlinerestapi_toimeksianto_uusi"
                  :required-class="false"
                />
              </template>
            </div>
          </v-card>
        </v-hover>

        <!-- Velallinen -->
        <div v-if="toimeksianto.paamies.id" class="pa-2">
          <h3 class="my-8">
            {{ $t("common:debtorData") }}
          </h3>
          <v-text-field
            v-model="toimeksianto.velallinen.tunnus"
            :label="$t('common:companyIdOrSocialId')"
            :error-messages="tunnusErrors"
            :rules="vaadiTunnus ? [$validationRules.required] : []"
            :class="vaadiTunnus ? 'required' : ''"
            @input="tarkistaTunnus()"
          />
          <FormPvm
            :pvm="syntymapaiva"
            :disabled="vaadiTunnus"
            :vuosi-otsikko="$t('Syntymäpäivä / Vuosi')"
            :infoteksti="syntymapaivaInfo"
          />
          <v-select
            v-model="toimeksianto.velallinen.tyyppi"
            :label="$t('common:type')"
            type="text"
            :items="vastapuolenTyypit"
            item-value="value"
            item-text="selite"
            :menu-props="{ zIndex: '999' }"
            :rules="[$validationRules.required]"
            class="required"
            @input="tarkistaTunnus()"
          />
          <v-text-field
            v-model="toimeksianto.velallinen.yhtiomuoto"
            :label="$t('common:formToimeksianto.companyForm')"
            :disabled="toimeksianto.velallinen.tyyppi === 'H'"
          />
          <v-select
            v-model="toimeksianto.velallinen.kieli"
            :label="$t('common:language')"
            type="text"
            :items="kielet || []"
            :value="kielet ? kielet[0] : ''"
            item-value="value"
            item-text="selite"
            :menu-props="{ zIndex: '999' }"
            :rules="[$validationRules.required]"
            class="required"
          />
          <h3 class="my-8">
            {{ $t("common:addressData") }}
          </h3>
          <div v-if="toimeksianto.velallinen.tyyppi === 'H'" class="comboField">
            <v-text-field
              v-model="toimeksianto.velallinen.etunimi"
              :label="$t('common:firstName')"
              :rules="[$validationRules.required]"
              :hint="$t('common:formToimeksianto.giveOneOrMoreMsg')"
              class="required"
            />
            <span class="comboField__separator"></span>
            <v-text-field
              v-model="toimeksianto.velallinen.sukunimi"
              :label="$t('common:lastName')"
              :rules="[$validationRules.required]"
              class="required"
            />
          </div>
          <v-text-field
            v-else
            v-model="toimeksianto.velallinen.nimi"
            :label="$t('common:name')"
            :rules="[$validationRules.required]"
            class="required"
          />
          <v-text-field
            v-model="toimeksianto.velallinen.postiosoite.vastaanottaja"
            :label="$t('common:recipient')"
          />
          <v-text-field
            v-model="toimeksianto.velallinen.postiosoite.osoite"
            :label="$t('common:address')"
            :rules="[$validationRules.required]"
            class="required"
          />
          <v-row>
            <v-col md="5" class="py-1">
              <v-text-field
                v-model="toimeksianto.velallinen.postiosoite.numero"
                :label="$t('common:zipCode')"
                :rules="[$validationRules.required]"
                class="required"
              />
            </v-col>
            <v-col md="7" class="py-1">
              <v-text-field
                v-model="toimeksianto.velallinen.postiosoite.toimipaikka"
                :label="$t('common:postalDistrict')"
                :rules="[$validationRules.required]"
                class="required"
              />
            </v-col>
          </v-row>
          <v-select
            v-model="toimeksianto.velallinen.postiosoite.maa"
            :label="$t('common:country')"
            type="text"
            :items="maat"
            item-value="value"
            item-text="selite"
            :menu-props="{ zIndex: '999', auto: true }"
            :rules="[$validationRules.required]"
            class="required"
          />
        </div>
        <template v-else>
          <p class="ma-2 font-no-data">
            {{ $t("common:formToimeksianto.firstSelectMandator") }}.
          </p>
        </template>
      </v-col>

      <!-- Oikea sarake -->
      <v-col class="pa-6" md="6">
        <v-col class="pa-2 pt-0">
          <span class="paper__title hidden-sm-and-down">
            {{ $t("common:collectionAssignment") }}
          </span>
          <template v-if="toimeksianto.paamies.id">
            <div class="py-12"></div>
            <AutocompleteYTJHaku
              :input-hint="
                $t('common:formToimeksianto.searchCorporateDebtorMsg')
              "
              @autocomplete-input="asetaYrityksenTiedotLomakkeelle"
            />
            <h3 class="mt-8 mb-8">
              {{ $t("common:contactInformation") }}
            </h3>
            <v-text-field
              v-model="toimeksianto.velallinen.emailosoite.email"
              :label="$t('common:emailAddress')"
            />
            <InputPuhelinNumero
              v-model:puh="toimeksianto.velallinen.puhelin.numero"
              :required="false"
              :outlined="false"
            />
            <h3 class="my-8">
              {{ $t("common:formToimeksianto.otherInfo") }}
            </h3>
            <v-text-field
              v-model="toimeksianto.pm_asiakasnumero"
              :label="$t('common:customerNo')"
            />
            <v-text-field
              v-model="toimeksianto.saatavalaji"
              :label="$t('common:formToimeksianto.receivableType')"
            />
            <h3 class="my-8">
              {{ $t("common:attachments") }}
            </h3>
            <v-file-input
              v-model="toimeksianto.tiedostot"
              chips
              counter="5"
              multiple
              :error-messages="liiteErrors"
              :rules="[$validationRules.smallerThanOrEqualTo(5, 5)]"
              title="title"
              :placeholder="$t('common:attachmentAddDots')"
              persistent-placeholder
              :validate-on-blur="true"
              @click:clear="liiteErrors = []"
            />
          </template>
        </v-col>
      </v-col>
    </v-row>

    <v-row v-if="toimeksianto.paamies.id" class="px-8">
      <v-checkbox
        v-model="toimeksianto.muistutettu"
        :label="$t('common:formToimeksianto.debtorSentReminder')"
        class="mr-8"
      ></v-checkbox>
      <v-checkbox
        v-if="toimeksianto.muistutettu"
        v-model="toimeksianto.muistutetaanko"
        :label="$t('common:formToimeksianto.collectionAgencyReminder')"
        class="mr-8"
      ></v-checkbox>
      <v-checkbox
        v-if="!toimeksianto.id"
        v-model="riidaton"
        :label="$t('common:formToimeksianto.assuranceClaimUndisputed')"
        :rules="[$validationRules.required]"
      ></v-checkbox>
    </v-row>

    <!-- Saatavarivit -->
    <div v-if="toimeksianto.paamies.id" class="pa-6">
      <h2 class="mb-4">
        {{ $t("common:formToimeksianto.receivableRows") }}
      </h2>
      <SaatavariviTable
        ref="saatavariviTable"
        v-model="toimeksianto"
        cell-size="small"
      />
    </div>
  </v-form>
</template>

<script>
import { mapState } from "vuex";
import OsoiteKappale from "@/components/OsoiteKappale";
import AutocompletePaamies from "@/components/AutocompletePaamies";
import AutocompleteYTJHaku from "@/components/AutocompleteYTJHaku";
import SaatavariviTable from "@/components/SaatavariviTable";
import { Velallinen } from "@/class/Velallinen";
import { MaksimiUploadTiedostoKoko } from "@/utils/constants";
import FormPvm from "@/components/FormPvm";
import LomakenappiIkonilla from "@/components/LomakenappiIkonilla";
import InputPuhelinNumero from "@/components/InputPuhelinNumero.vue";

export default {
  name: "FormToimeksianto",
  components: {
    InputPuhelinNumero,
    AutocompletePaamies,
    AutocompleteYTJHaku,
    OsoiteKappale,
    SaatavariviTable,
    FormPvm,
    LomakenappiIkonilla,
  },
  data() {
    return {
      liiteErrors: [],
      tunnusErrors: [],
      odotusaikaYlitetty: false,
      syntymapaiva: {},
      riidaton: false,
    };
  },
  computed: {
    ...mapState({
      luotuToimeksianto: (state) => state.perintaUusi.luotuToimeksianto,
      toimeksianto: (state) => state.perintaUusi.toimeksianto,
      kielet: (state) => state.yleinen.kielet,
      vastapuolenTyypit: (state) => state.yleinen.vastapuolenTyypit,
      maat: (state) => state.yleinen.maat,
    }),
    vaadiTunnus() {
      return (
        this.toimeksianto.velallinen.tyyppi === "Y" ||
        this.toimeksianto.paamies.vaadi_hetu
      );
    },
    syntymapaivaInfo() {
      if (this.toimeksianto.paamies.vaadi_hetu)
        return this.$t("Päämies vaatii henkilövelalliselle hetun.");

      return this.$t("Henkilövelalliselle anna hetu tai syntymäpäivä");
    },
  },
  watch: {
    toimeksianto: {
      deep: true,
      handler() {
        if (this.odotusaikaYlitetty) this.$emit("muokkaus");
      },
    },
    syntymapaiva: {
      deep: true,
      handler(sp) {
        this.henkiloTunnusTaiSyntymapaiva();

        if (sp.vuosi && sp.kuukausi && sp.paiva) {
          this.toimeksianto.velallinen.syntymapaiva = new Date(
            sp.vuosi,
            sp.kuukausi - 1,
            sp.paiva
          );
        } else {
          this.toimeksianto.velallinen.syntymapaiva = null;
        }
      },
    },
    "toimeksianto.velallinen.tyyppi"(tyyppi) {
      if (tyyppi === "Y") this.syntymapaiva = {};

      this.henkiloTunnusTaiSyntymapaiva();
    },
  },
  mounted() {
    setTimeout(() => {
      this.odotusaikaYlitetty = true;
    }, 2000);

    if (this.$store.state.user.valittuPaamies.id) {
      this.toimeksianto.paamies = this.$store.state.user.valittuPaamies;
    }
  },
  methods: {
    submit() {
      this.$emit("submit", this.toimeksianto);
    },
    tyhjennaPaamies() {
      this.toimeksianto.paamies = {};
    },
    validoiToimeksianto() {
      let lomakeValidi = true;

      if (this.toimeksianto.tiedostot.length > 5) {
        this.liiteErrors = [this.$t("common:attachmentTooMany")];
        lomakeValidi = false;
      }

      let liianSuurenTiedostonNimi = "";
      const sallittuTiedostoKokoYlitetty = this.toimeksianto.tiedostot.some(
        (tiedosto) => {
          liianSuurenTiedostonNimi = tiedosto.name;
          return tiedosto.size > MaksimiUploadTiedostoKoko;
        }
      );
      if (sallittuTiedostoKokoYlitetty) {
        this.liiteErrors = [
          this.$t("common:attachmentSizeExceeded", {
            filename: liianSuurenTiedostonNimi,
          }),
        ];
        lomakeValidi = false;
      }

      if (this.toimeksianto.rivit.length === 0) {
        this.$naytaVirheilmoitus(
          this.$t("common:formToimeksianto.addOneReceivableRow")
        );
        lomakeValidi = false;
      }
      const formToimeksiantoValid = this.$refs.formToimeksianto.validate();
      const saatavariviTableValid = this.$refs.saatavariviTable.validate();
      if (!formToimeksiantoValid || !saatavariviTableValid) {
        this.$naytaVirheilmoitus(this.$t("common:checkFields"));
        lomakeValidi = false;
      }

      if (!this.henkiloTunnusTaiSyntymapaiva()) {
        this.$naytaVirheilmoitus(this.$t("common:checkFields"));
        lomakeValidi = false;
      }
      return lomakeValidi;
    },
    asetaYrityksenTiedotLomakkeelle(yritys) {
      this.toimeksianto.velallinen = new Velallinen(yritys);
    },
    async tarkistaTunnus() {
      this.tunnusErrors = [];

      this.henkiloTunnusTaiSyntymapaiva();

      const tunnus = this.toimeksianto.velallinen.tunnus;

      if (!tunnus || this.toimeksianto.velallinen.postiosoite.maa !== "FI")
        return;

      const query = new URLSearchParams();
      query.append("method", "tarkista_tunnus");
      query.append("tunnus", tunnus);
      query.append("tyyppi", this.toimeksianto.velallinen.tyyppi);

      const request = await this.$doRequestWithTimeout(
        this.$api.Yleinen,
        {
          url: "tarkista/",
          query: query.toString(),
        },
        "pushRequest"
      );

      const tunnusOnOikeellinen = request.result.body;
      if (!tunnusOnOikeellinen && this.toimeksianto.velallinen.tunnus) {
        this.tunnusErrors.push("Tarkista tunnus");
      }
    },
    henkiloTunnusTaiSyntymapaiva() {
      if (this.vaadiTunnus) return true;

      this.tunnusErrors = [];
      const sp = this.syntymapaiva;

      if (
        this.toimeksianto.velallinen.tunnus &&
        (sp.vuosi || sp.kuukausi || sp.paiva)
      ) {
        this.tunnusErrors.push(
          "Anna henkilövelalliselle joko henkilötunnus tai syntymäpäivä."
        );

        return false;
      }

      if (!this.toimeksianto.paamies.vaadi_hetu) return true;

      if (
        !this.toimeksianto.velallinen.tunnus &&
        !(sp.vuosi && sp.kuukausi && sp.paiva)
      ) {
        this.tunnusErrors.push(
          "Henkilövelallisella joko henkilötunnus tai syntymäpäivä vaaditaan."
        );

        return false;
      }

      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.hoverBackground {
  background-color: #e6510021;
}

.v-input {
  padding-top: 4px;
}

.col-icon {
  display: flex;
  justify-content: flex-end;
}
</style>
