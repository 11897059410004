var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "login__bottom px-6", style: _vm.footerStyle },
    [
      _c(
        "div",
        { staticClass: "login__bottomCol login__bottomCol--left pa-6" },
        [
          _vm.footerText
            ? _c("p", [_vm._v(" " + _vm._s(_vm.footerText) + " ")])
            : _vm._e(),
          _c("p", [
            _vm.email
              ? _c("a", { attrs: { href: "mailto:" + _vm.email } }, [
                  _vm._v(_vm._s(_vm.email)),
                ])
              : _vm._e(),
            _vm.email && _vm.tel ? _c("span", [_vm._v("–")]) : _vm._e(),
            _vm.tel ? _c("span", [_vm._v(_vm._s(_vm.tel))]) : _vm._e(),
            _c("br"),
            _c("span", { attrs: { id: "versionumero" } }, [
              _vm._v("v" + _vm._s(_vm.versio)),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "login__bottomCol login__bottomCol--right pa-6" },
        [
          _vm.copyrightText
            ? _c("p", [_vm._v(" " + _vm._s(_vm.copyrightText) + " ")])
            : _vm._e(),
          _vm.address || _vm.postal || _vm.locality
            ? _c(
                "p",
                [
                  _vm.address
                    ? _c("span", [_vm._v(_vm._s(_vm.address))])
                    : _vm._e(),
                  _vm.address && (_vm.postal || _vm.locality)
                    ? _c("span", [_vm._v(",")])
                    : _vm._e(),
                  _vm.postal
                    ? _c("span", [_vm._v(_vm._s(_vm.postal))])
                    : _vm._e(),
                  _vm.locality
                    ? _c("span", [_vm._v(_vm._s(_vm.locality))])
                    : _vm._e(),
                  _vm.businessId
                    ? [
                        _c("br"),
                        _vm._v(" Y-tunnus: " + _vm._s(_vm.businessId) + " "),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }