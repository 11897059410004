<template>
  <v-data-table
    :items="items"
    :headers="tableHeadersLaskuliite"
    :hide-default-footer="items.length <= 10"
    :dense="tiivisNakyma"
  >
    <template #item="{ item }">
      <TableRow :key="`${item.id}-liiterivi`">
        <!-- Liite tai (osto)laskun kuva -->
        <template v-if="item.nimi">
          <td>
            <strong>
              {{ item.tyyppi === "laskun kuva" ? "Laskun kuva" : "Liite" }}
            </strong>
          </td>
          <td>
            <a @click="haeLiite(item)">{{ item.nimi }}</a>
          </td>
          <td class="text-no-wrap">
            {{ $dateFnsFormat(item.luotu) }}
          </td>
          <td></td>
          <td class="text-right">
            <v-tooltip v-if="salliPoisto && item.tyyppi !== 'laskun kuva'" top>
              <template #activator="{ on }">
                <v-btn
                  text
                  icon
                  :small="tiivisNakyma"
                  v-on="on"
                  @click="avaaDialogPoistaTiedosto(item)"
                >
                  <v-icon :small="tiivisNakyma">delete</v-icon>
                </v-btn>
              </template>
              <template #default>
                <span>Poista liite</span>
              </template>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  text
                  icon
                  :small="tiivisNakyma"
                  v-on="on"
                  @click="haeLiite(item)"
                >
                  <v-icon :small="tiivisNakyma">
                    anitta-icon-file-download
                  </v-icon>
                </v-btn>
              </template>
              <template #default>
                <span>Lataa liite</span>
              </template>
            </v-tooltip>
          </td>
        </template>
        <!-- Laskukirjeet -->
        <template v-else-if="item.kirjepohja">
          <td>
            <strong>
              {{ item.kirjepohja.tyyppi.includes("-sms-") ? "SMS" : "Kirje" }}:
              {{ item.kirjepohja.nimi }}
            </strong>
          </td>
          <td>
            <a @click="haeLaskukopio(item)">
              {{ laskukopionTiedostonNimi(item) }}
            </a>
          </td>
          <td class="text-no-wrap">
            {{ $dateFnsFormat(item.luotu) }}
          </td>
          <td>
            <template v-if="item.lahetysaika">
              {{
                item.kuitattu_lahetetyksi
                  ? "Kuitattu lähetetyksi:"
                  : "Lähetetty:"
              }}
              {{ $dateFnsFormat(item.kuitattu_lahetetyksi, "d.M.yyyy HH:mm") }}
              <br />
            </template>
            <template v-else-if="item.haluttu_lahetyspaiva">
              Lähdössä {{ $dateFnsFormat(item.haluttu_lahetyspaiva) }}
              <br />
            </template>
            <template v-if="item.lahetystila">
              {{ item.lahetystila }}
              <br />
            </template>
            {{ `Lähetystapa: ${item.lahetystapa_ui}` }}
          </td>
          <td class="text-right">
            <v-tooltip
              v-if="
                !item.lahetysaika &&
                $permission.checkSinglePerm(
                  'onlinerestapi.onlinerestapi_lasku_kirje_kuittaa'
                ) &&
                sallitaankoSMSToimenpide(item)
              "
              top
            >
              <template #activator="{ on }">
                <v-btn
                  text
                  icon
                  :small="tiivisNakyma"
                  v-on="on"
                  @click="kuittaaKirjeLahetetyksi(item)"
                >
                  <v-icon :small="tiivisNakyma">mark_email_read</v-icon>
                </v-btn>
              </template>
              <template #default>
                <span>Kuittaa lähetetyksi</span>
              </template>
            </v-tooltip>
            <v-tooltip
              v-if="
                salliPoisto &&
                tyyppi === laskutyypit.LASKU &&
                $permission.checkSinglePerm(
                  'onlinerestapi.onlinerestapi_lasku_laskukopio_poista'
                ) &&
                item.lahetysaika === null &&
                sallitaankoSMSToimenpide(item)
              "
              top
            >
              <template #activator="{ on }">
                <v-btn
                  text
                  icon
                  :small="tiivisNakyma"
                  v-on="on"
                  @click="avaaDialogPoistaLaskukopio(item)"
                >
                  <v-icon :small="tiivisNakyma">delete</v-icon>
                </v-btn>
              </template>
              <template #default>
                <span>Poista laskukirje</span>
              </template>
            </v-tooltip>
            <v-tooltip
              v-else-if="item.kuitattu_lahetetyksi"
              :key="`${item.id}-kuitattu`"
              top
            >
              <template #activator="{ on }">
                <div
                  class="v-btn v-btn--icon v-btn--round v-btn--text margin-r-6px"
                  v-on="on"
                >
                  <v-icon :small="tiivisNakyma" disabled>check</v-icon>
                </div>
              </template>
              <template #default>
                <span>
                  Kuitattu lähetetyksi
                  {{
                    $dateFnsFormat(item.kuitattu_lahetetyksi, "d.M.yyyy HH:mm")
                  }}
                </span>
              </template>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  text
                  icon
                  :small="tiivisNakyma"
                  v-on="on"
                  @click="haeLaskukopio(item)"
                >
                  <v-icon :small="tiivisNakyma">
                    anitta-icon-file-download
                  </v-icon>
                </v-btn>
              </template>
              <template #default>
                <span>Lataa</span>
              </template>
            </v-tooltip>
          </td>
        </template>
      </TableRow>
    </template>
  </v-data-table>
</template>

<script>
import { haeTiedostoFetchilla } from "@/utils/misc";
import { TableHeadersLaskuliite } from "@/utils/tableHeaders";
import { Laskutyypit } from "@/utils/constants";
import TiivisNakymaMixin from "@/mixins/TiivisNakymaMixin";

export default {
  name: "DataTableLaskuliite",
  mixins: [TiivisNakymaMixin],
  props: {
    items: {
      type: Array,
      required: true,
    },
    lasku: {
      type: Object,
      required: true,
    },
    tyyppi: {
      type: String,
      required: false,
      default() {
        return Laskutyypit.LASKU;
      },
      validator: (arvo) => {
        return [Laskutyypit.LASKU, Laskutyypit.OSTOLASKU].includes(arvo);
      },
    },
    salliPoisto: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tableHeadersLaskuliite: TableHeadersLaskuliite,
    };
  },
  computed: {
    laskutyypit() {
      return Laskutyypit;
    },
  },
  methods: {
    laskukopionTiedostonNimi(item) {
      let tiedostopaate = "pdf";
      if (item.kirjepohja.tyyppi.includes("-sms-")) tiedostopaate = "txt";
      return `lasku-${this.lasku.nro}_${this.lasku.laskupaiva}.${tiedostopaate}`;
    },

    sallitaankoSMSToimenpide(item) {
      if (!item.kirjepohja.tyyppi.includes("-sms-")) return true;
      if (
        item.kirjepohja.tyyppi.includes("-sms-") &&
        this.$permission.checkSinglePerm(
          "onlinerestapi.onlinerestapi_lasku_sms_muistutus"
        )
      ) {
        return true;
      }
      return false;
    },

    async avaaDialogPoistaTiedosto(item) {
      const result = await this.$swal({
        text: "Haluatko varmasti poistaa tiedoston?",
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: "Poista tiedosto",
        cancelButtonText: "Peruuta",
      });

      if (!result.value) return;

      this.poistaTiedosto(item);
    },

    async poistaTiedosto(poistettavaTiedosto) {
      let apiName;
      if (this.tyyppi === Laskutyypit.LASKU) {
        apiName = "Laskut";
      } else if (this.tyyppi === Laskutyypit.OSTOLASKU) {
        apiName = "Ostolaskut";
      }

      try {
        const request = await this.$doRequestWithTimeout(
          this.$api[apiName],
          {
            url: `toiminnot/poista_liite/${poistettavaTiedosto.id}/`,
            method: "delete",
          },
          "doSingleRequest"
        );

        if (!request.success)
          throw new this.$HttpError(
            request,
            "Tiedoston poistaminen epäonnistui."
          );

        this.$naytaOnnistumisilmoitus("Tiedosto poistettu.");
        this.$emit("tiedosto-poistettu", poistettavaTiedosto);
      } catch (e) {
        this.$oletusVirheenkasittely(e);
      }
    },

    async haeLaskukopio(item) {
      try {
        const url =
          process.env.VUE_APP_API_ROOT +
          `/lasku/${this.lasku.id}/toiminnot/hae_laskukopio/${item.id}/`;
        const result = await haeTiedostoFetchilla(url);

        this.$naytaTiedostonlataamisdialog({
          blob: await result.blob(),
          headers: {
            "content-disposition": result.headers.get("content-disposition"),
            "content-type": result.headers.get("content-type"),
          },
        });
      } catch (e) {
        this.$oletusVirheenkasittely(e);
      }
    },

    async avaaDialogPoistaLaskukopio(item) {
      const result = await this.$swal({
        text: "Haluatko varmasti poistaa laskukirjeen?",
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: "Poista laskukirje",
        cancelButtonText: "Peruuta",
      });

      if (!result.value) return;

      this.poistaLaskukopio(item);
    },

    async poistaLaskukopio(poistettavaKirje) {
      try {
        const request = await this.$doRequestWithTimeout(
          this.$api.Laskut,
          {
            url: `${this.lasku.id}/toiminnot/kirje/${poistettavaKirje.id}/poista/`,
            method: "delete",
          },
          "doSingleRequest"
        );

        if (!request.success)
          throw new this.$HttpError(
            request,
            "Laskukirjeen poistaminen epäonnistui."
          );

        this.$naytaOnnistumisilmoitus("Laskukirje poistettu.");
        this.$emit("tiedosto-poistettu", poistettavaKirje);
      } catch (e) {
        this.$oletusVirheenkasittely(e);
      }
    },

    async haeLiite(item) {
      const { id, nimi } = item;
      try {
        let request;
        let apiName;
        const options = {
          parser: "arrayBuffer",
          url: `toiminnot/hae_liite/${id}/`,
        };
        if (this.tyyppi === Laskutyypit.LASKU) {
          apiName = "Laskut";
        } else if (this.tyyppi === Laskutyypit.OSTOLASKU) {
          apiName = "Ostolaskut";
        }

        request = await this.$doRequestWithTimeout(this.$api[apiName], options);

        if (request && !request.success) throw new this.$HttpError(request);

        this.$naytaTiedostonlataamisdialog({
          fileData: request.result.body,
          filename: nimi,
          headers: request.result.headers,
        });
      } catch (e) {
        this.$oletusVirheenkasittely(e);
      }
    },

    async kuittaaKirjeLahetetyksi(item) {
      const result = await this.$swal({
        title: "Haluatko varmasti kuitata kirjeen lähetetyksi?",
        text: "Lasku asetetaan avoimeksi, eikä kuitattua kirjettä lähetetä.",
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: "Kuittaa lähetetyksi",
        cancelButtonText: "Peruuta",
      });

      if (!result.value) return;

      try {
        const request = await this.$doRequestWithTimeout(this.$api.Laskut, {
          method: "POST",
          url: `${this.lasku.id}/toiminnot/kirje/${item.id}/kuittaa/`,
        });

        if (request && !request.success) throw new this.$HttpError(request);

        this.$emit("lasku-paivitetty", request.result.body);
        this.$naytaOnnistumisilmoitus("Kirje kuitattu lähetetyksi.");
      } catch (e) {
        this.$oletusVirheenkasittely(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.margin-r-6px {
  margin-right: 6px;
}
</style>
