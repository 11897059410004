<template>
  <v-form v-model="valid" @submit.prevent="submit">
    <v-text-field
      v-model="value.email"
      label="Sähköpostiosoite"
      type="email"
      :rules="[$validationRules.required, $validationRules.email]"
      :solo="variant === 'solo'"
      :outlined="variant === 'outlined'"
      required
      class="required"
    />

    <v-btn
      type="submit"
      :disabled="!valid || loading"
      :loading="loading"
      block
      large
      color="action"
      class="ml-0"
    >
      Lähetä
    </v-btn>
  </v-form>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";
// Refaktoroidaan variant vue3:sen päivityksen yhteydessä

export default {
  name: "FormSalasananUusimispyynto",
  mixins: [FormMixin],
  props: {
    variant: {
      type: String,
      default: "outlined",
    },
  },
};
</script>

<style lang="scss" scoped></style>
