<template>
  <v-form v-model="valid" @submit.prevent="submit">
    <v-menu
      v-model="maksupaivaMenu"
      :close-on-content-click="false"
      max-width="290"
      nudge-bottom="40"
    >
      <template #activator="{ on }">
        <v-text-field
          :label="$t('common:paymentDate')"
          :value="$dateFnsFormat(value.maksupaiva)"
          readonly
          :placeholder="$t('today')"
          persistent-placeholder
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="value.maksupaiva"
        :max="maxDate"
        locale="fi-FI"
        first-day-of-week="1"
        no-title
        @change="maksupaivaMenu = false"
      />
    </v-menu>
    <v-text-field
      v-model="value.selite"
      type="text"
      :label="$t('common:definition')"
    />
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-checkbox
            v-model="value.hyvityslasku"
            :disabled="disabloidaankoHyvityslaskunLuonti"
            :label="$t('common:formLaskuHyvita.createCreditInvoice')"
          ></v-checkbox>
        </div>
      </template>
      <template #default>
        <span v-if="disabloidaankoHyvityslaskunLuonti">
          {{
            $t("common:formLaskuHyvita.canNotCreateCreditInvoiceIfNoAddress")
          }}
        </span>
      </template>
    </v-tooltip>
    <v-text-field
      v-model="value.summa"
      type="text"
      :label="$t('common:sum')"
      :placeholder="summaPlaceholder()"
      persistent-placeholder
      :rules="[
        $validationRules.maxDecimalPlacesOrEmpty(value.summa, 2),
        $validationRules.numberOrEmptyWithDecimal,
      ]"
      suffix="€"
    />
    <v-checkbox
      v-if="onkoLahteviaKirjeita"
      v-model="value.poista_lahtevat_kirjeet"
      :label="$t('common:formLaskuHyvita.cancelInvoiceSend')"
    ></v-checkbox>
    <PeruutaHyvaksyPainikerivi
      :submit-disabled="!valid || loading"
      :submit-loading="loading"
      @close="$emit('close')"
    />
  </v-form>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";
import formatSumToFixed2 from "@/utils/filters/formatSumToFixed2";

export default {
  name: "FormLaskuHyvita",
  mixins: [FormMixin],
  props: {
    lasku: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      maksupaivaMenu: false,
    };
  },
  computed: {
    maxDate() {
      return new Date().toISOString().substr(0, 10);
    },
    onkoLahteviaKirjeita() {
      return this.lasku.kirje_set.some((kirje) => kirje.lahetysaika === null);
    },
    disabloidaankoHyvityslaskunLuonti() {
      return this.lasku.postiosoite === null;
    },
  },
  methods: {
    summaPlaceholder() {
      return `Koko lasku (${formatSumToFixed2(this.lasku.avoinna)})`;
    },
  },
};
</script>

<style lang="scss" scoped></style>
