var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    { staticClass: "nav-main" },
    [
      _vm.navItems.length
        ? _c(
            "ul",
            { staticClass: "nav-main__list" },
            [
              _vm._l(_vm.navItems, function (item) {
                return _c(
                  "li",
                  { key: item.index, staticClass: "nav-main__item" },
                  [
                    _c(
                      "router-link",
                      {
                        class: item.disabled
                          ? _vm.linkClassDisabled
                          : _vm.linkClass,
                        attrs: {
                          to: item.disabled
                            ? { name: "dummy" }
                            : { name: item.name },
                        },
                      },
                      [
                        _c("v-tooltip", {
                          attrs: {
                            right: "",
                            disabled: _vm.showTitles && !item.disabled,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "span",
                                      _vm._g(
                                        { staticClass: "nav-main__iconWrap" },
                                        on
                                      ),
                                      [
                                        item.meta.icon
                                          ? _c("span", {
                                              staticClass: "nav-main__icon",
                                              class: item.meta.icon,
                                            })
                                          : _vm._e(),
                                        _c("Badge", {
                                          model: {
                                            value: item.meta.badge,
                                            callback: function ($$v) {
                                              _vm.$set(item.meta, "badge", $$v)
                                            },
                                            expression: "item.meta.badge",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.disabled
                                            ? item.disabledInfo
                                              ? item.disabledInfo
                                              : _vm.$t("notAvailable")
                                            : item.meta.title
                                        ) +
                                        " "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _c("v-tooltip", {
                          attrs: { right: "", disabled: !item.disabled },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "span",
                                      _vm._g(
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.showTitles,
                                              expression: "showTitles",
                                            },
                                          ],
                                          staticClass: "nav-main__title",
                                        },
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.meta.title) + " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.disabledInfo
                                            ? item.disabledInfo
                                            : _vm.$t("notAvailable")
                                        ) +
                                        " "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _vm.$permission.checkSinglePerm(
                "onlinerestapi.onlinerestapi_luottotieto"
              ) && _vm.luottotietoModuuli
                ? _c(
                    "li",
                    {
                      staticClass: "nav-main__item",
                      on: {
                        click: function ($event) {
                          return _vm.$refs.luottotietoHaku.avaaLuottotiedot()
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { class: _vm.linkClass },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { right: "", disabled: _vm.showTitles },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "span",
                                          _vm._g(
                                            {
                                              staticClass: "nav-main__iconWrap",
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "nav-main__icon" },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { color: "icons" } },
                                                  [_vm._v("mdi-chart-box")]
                                                ),
                                                !_vm.showTitles
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "nav-main__dialog-tooltip",
                                                        attrs: {
                                                          color: "icons",
                                                          size: "small",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " mdi-arrow-top-right "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "nav-main__dialog" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.showTitles,
                                                    expression: "showTitles",
                                                  },
                                                ],
                                                staticClass: "nav-main__title",
                                              },
                                              [_vm._v(" Luottotietohaku ")]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.showTitles,
                                                    expression: "showTitles",
                                                  },
                                                ],
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      color: "icons",
                                                      size: "small",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " mdi-arrow-top-right "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3550801066
                              ),
                            },
                            [_c("span", [_vm._v("Luottotietohaku")])]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm.$permission.checkSinglePerm(
        "onlinerestapi.onlinerestapi_luottotieto"
      ) && _vm.luottotietoModuuli
        ? _c("LuottotietoHaku", {
            ref: "luottotietoHaku",
            attrs: { btn: false },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }