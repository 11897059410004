<template>
  <li class="defList__item" v-on="eventListener">
    <span
      class="defList__label"
      :class="
        tiivisNakyma ? labelClass + ' defList__label--tiivis' : labelClass
      "
    >
      <slot name="label" />
    </span>
    <span
      class="defList__value"
      :class="
        tiivisNakyma ? valueClass + ' defList__value--tiivis' : valueClass
      "
    >
      <slot name="value">-</slot>
    </span>
  </li>
</template>

<script>
import TiivisNakymaMixin from "@/mixins/TiivisNakymaMixin";

export default {
  name: "DefListItem",
  mixins: [TiivisNakymaMixin],
  props: {
    labelClass: {
      type: String,
      required: false,
      default() {
        return "";
      },
    },
    valueClass: {
      type: String,
      required: false,
      default() {
        return "";
      },
    },
    eventListener: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
};
</script>
