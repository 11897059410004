<template>
  <v-form v-model="valid" @submit.prevent="submit">
    <v-text-field
      v-model="value.tunniste"
      :rules="[$validationRules.required]"
      label="Tunniste"
      autocomplete="off"
      autofocus
    ></v-text-field>
    <v-btn type="submit" :disabled="!valid" :loading="loading" color="action">
      Lähetä
    </v-btn>
  </v-form>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";

export default {
  name: "FormMFA",
  mixins: [FormMixin],
};
</script>
