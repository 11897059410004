<template>
  <v-form ref="formTuote" v-model="valid">
    <v-row align="center">
      <v-col>
        <v-text-field
          v-if="yleinen.muokattavaTuote.id"
          v-model="yleinen.muokattavaTuote.paamies.nimi"
          :disabled="true"
          class="required"
        />
        <AutocompletePaamies
          v-else-if="!$store.state.user.vainYksiPaamies"
          ref="autocompletePaamies"
          v-model="yleinen.muokattavaTuote.paamies"
          :label="$t('common:creditor')"
          tarkistettava-oikeus="onlinerestapi.onlinerestapi_tuoterekisteri"
          @autocomplete-input="tarkistaKoodi"
        />
        <div>
          <v-text-field
            v-show="false"
            v-model="yleinen.muokattavaTuote.tuoteryhma.nimi"
            :rules="[$validationRules.required]"
            class="required"
          />
          <v-text-field
            v-if="yleinen.muokattavaTuote.tuoteryhma.id"
            v-model="yleinen.muokattavaTuote.tuoteryhma.nimi"
            :label="$t('common:productGroup.productGroupHeader')"
            clearable
            :readonly="true"
            :rules="[$validationRules.required]"
            class="required"
            @input="tyhjennaTuoteryhma"
          >
            <template #append>
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    @click="uusiTuoteryhma"
                    v-on="on"
                  >
                    add
                  </v-icon>
                </template>
                <span>Lisää tuoteryhmä</span>
              </v-tooltip>
            </template>
          </v-text-field>
          <AutocompleteTuoteryhma
            v-else
            ref="autocompleteTuoteryhma"
            reference="autocompleteTuoteryhma"
            :tuote="yleinen.muokattavaTuote"
            append-icon="add"
            :rules="[$validationRules.required]"
            required="required"
            @uusiTuoteryhma="uusiTuoteryhma"
          />
        </div>
        <v-text-field
          v-model="yleinen.muokattavaTuote.koodi"
          :label="$t('common:code')"
          :rules="[$validationRules.required]"
          class="required"
          :error-messages="koodiErrors"
          @input="tarkistaKoodiDebounce"
        />
        <v-text-field
          v-model="yleinen.muokattavaTuote.selite"
          :label="$t('common:definition')"
        />
        <v-text-field
          v-model="yleinen.muokattavaTuote.vapaateksti"
          :label="$t('common:freeText')"
        />
        <div class="py-4"></div>
        <v-text-field
          v-model="yleinen.muokattavaTuote.ahinta"
          :label="$t('common:unitPrice')"
          type="number"
          :rules="[
            $validationRules.required,
            $validationRules.maxDecimalPlaces(
              yleinen.muokattavaTuote.ahinta,
              2
            ),
          ]"
          class="required"
        />
        <v-switch
          v-model="yleinen.muokattavaTuote.sisalv"
          class="ml-4 mt-0"
          :label="$t('common:general.ifPriceIncludesVat')"
        />
        <v-text-field
          v-model="yleinen.muokattavaTuote.laatu"
          :label="$t('common:quantityUnit')"
        />
        <v-autocomplete
          ref="refAlvtunnusId"
          v-model="yleinen.muokattavaTuote.alvtunnus_id"
          type="text"
          :items="yleinen.ALVTunnusItems"
          :label="$t('common:vatId')"
          item-text="tunnus"
          item-value="id"
          :rules="[$validationRules.required]"
          class="required"
        ></v-autocomplete>
        <v-text-field
          v-model="yleinen.muokattavaTuote.hankintahinta"
          type="number"
          :label="$t('common:purchasePrice')"
          :rules="[
            $validationRules.maxDecimalPlaces(
              yleinen.muokattavaTuote.hankintahinta,
              2
            ),
          ]"
        />
        <v-text-field
          v-model="yleinen.muokattavaTuote.varastotilanne"
          :label="$t('common:stockStatus')"
          type="text"
          :rules="[$validationRules.numberOrEmpty]"
        />
      </v-col>
    </v-row>
    <v-row class="mt-4 pa-3" justify="end">
      <v-btn large class="mr-4" @click="$emit('close')">
        {{ $t("common:cancel") }}
      </v-btn>
      <v-btn
        large
        color="action"
        :disabled="!valid || loading"
        @click="tallenna"
      >
        {{ $t("common:save") }}
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import { Tuote } from "@/class/Tuoterekisteri";
import { AutocompletePaamies, AutocompleteTuoteryhma } from "@/components";
import FormMixin from "@/mixins/FormMixin";
import { MaksimiTuoteId } from "@/utils/constants";

export default {
  name: "FormTuote",
  components: {
    AutocompletePaamies,
    AutocompleteTuoteryhma,
  },
  mixins: [FormMixin],
  props: {
    laskurivi: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      koodiErrors: [],
      tuoteryhmaItems: [],
      debounceDelay: 300,
      debounceTimeout: "",
    };
  },
  computed: {
    ...mapState(["yleinen"]),
    ...mapState({
      user: (state) => state.user,
      ALVTunnusItems: (state) => state.yleinen.ALVTunnusItems,
    }),
  },
  watch: {
    onAuki: {
      immediate: true,
      handler(val) {
        // Suoritetaan toimenpiteet ainoastaan, jos lomake avataan
        if (!val) return;

        this.$nextTick().then(() => {
          this.$refs.formTuote.resetValidation();
        });
        if (!this.yleinen.muokattavaTuote.id) {
          this.yleinen.muokattavaTuote = new Tuote();
          this.yleinen.muokattavaTuote.paamies =
            this.$store.state.user.valittuPaamies;
          // Tyhjennyksellä saadaan aikaan päämiehen tuoteryhmien haku
          this.tyhjennaTuoteryhma();
        } else {
          if (this.yleinen.muokattavaTuote.alvtunnus) {
            this.yleinen.muokattavaTuote.alvtunnus_id =
              this.yleinen.muokattavaTuote.alvtunnus.id;
          }
        }

        this.tarkistaKoodiDebounce();

        if (!_.isEmpty(this.laskurivi)) {
          this.yleinen.muokattavaTuote = new Tuote(this.laskurivi);
          if (this.laskurivi.alvtunnus.id)
            this.yleinen.muokattavaTuote.alvtunnus_id =
              this.laskurivi.alvtunnus.id;
        }
      },
    },
    "yleinen.muokattavaTuote.id": {
      immediate: true,
      handler(id) {
        if (id) {
          if (this.yleinen.muokattavaTuote.alvtunnus) {
            this.yleinen.muokattavaTuote.alvtunnus_id =
              this.yleinen.muokattavaTuote.alvtunnus.id;
          }
        }
      },
    },
  },
  methods: {
    async uusiTuoteryhma() {
      if (
        this.$refs.autocompleteTuoteryhma &&
        this.$refs.autocompleteTuoteryhma.$refs &&
        this.$refs.autocompleteTuoteryhma.$refs.autocompleteTuoteryhma
      ) {
        // tyhjennetään tuoteryhmän valikko
        this.$refs.autocompleteTuoteryhma.$refs.autocompleteTuoteryhma.blur();
      }
      this.$emit("uusiTuoteryhma");
    },
    async tallenna() {
      if (!this.$refs.formTuote.validate()) return;
      try {
        this.tyhjennaKopioidunTuotteenId();
        const httpMethod = this.yleinen.muokattavaTuote.id ? "PUT" : "POST";
        const url = this.yleinen.muokattavaTuote.id
          ? `${this.yleinen.muokattavaTuote.id}/`
          : "";
        let tmpTuote = new Tuote(this.yleinen.muokattavaTuote);

        const request = await this.$doRequestWithTimeout(
          this.$api.Tuotteet,
          {
            method: httpMethod,
            url: url,
            body: tmpTuote.getPostData(),
          },
          "doSingleRequest"
        );
        if (!request.success) throw new this.$HttpError(request);

        this.$emit("tuoteTallennettu", request.result.body);
        this.$emit("close");
      } catch (e) {
        this.$oletusVirheenkasittely(
          e,
          this.$t("common:product.saveProductFailed")
        );
      }
    },
    tarkistaKoodiDebounce() {
      clearTimeout(this.debounceTimeout);

      this.debounceTimeout = setTimeout(async () => {
        await this.tarkistaKoodi();
      }, this.debounceDelay);
    },
    async tarkistaKoodi() {
      if (
        !this.yleinen.muokattavaTuote.koodi ||
        !this.yleinen.muokattavaTuote.paamies ||
        !this.yleinen.muokattavaTuote.paamies.id
      ) {
        this.koodiErrors = [];
        return;
      }

      const query = new URLSearchParams();
      query.append("method", "tuotekooditarkistus");
      query.append("koodi", this.yleinen.muokattavaTuote.koodi);
      query.append("paamies_id", this.yleinen.muokattavaTuote.paamies.id);
      if (this.yleinen.muokattavaTuote.id)
        query.append("tuote_id", this.yleinen.muokattavaTuote.id);
      this.koodiErrors = [];

      const request = await this.$doRequestWithTimeout(
        this.$api.Tuotteet,
        {
          url: "toiminnot/tarkista/",
          query: query.toString(),
        },
        "pushRequest"
      );

      const koodiVapaana = request.result.body;
      if (!koodiVapaana && this.yleinen.muokattavaTuote.koodi) {
        this.koodiErrors = [this.$t("common:product.productCodeAlreadyUsed")];
      } else {
        this.koodiErrors = [];
      }
      return request;
    },
    tyhjennaTuoteryhma() {
      this.yleinen.muokattavaTuote.tuoteryhma = {};
      this.$nextTick().then(() => {
        if (this.$refs.autocompleteTuoteryhma) {
          this.$refs.autocompleteTuoteryhma.haeTuoteryhmat();
        }
      });
    },
    tyhjennaKopioidunTuotteenId() {
      if (this.yleinen.muokattavaTuote.id >= MaksimiTuoteId) {
        this.yleinen.muokattavaTuote.id = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-text-field,
.v-select {
  padding-top: 0px;
  margin-top: 4px;
}

.hidden {
  visibility: hidden;
}
</style>
