<template>
  <div class="pp-container">
    <v-dialog v-model="palvelutasoVertailu" class="dialog-palvelutasot">
      <v-card class="dialog-card">
        <div class="dialog-lists">
          <div class="dialog-list">
            <h1>Ominaisuudet</h1>
            <v-list>
              <v-list-item
                v-for="ominaisuus in valittavatPalvelusopimukset[0]
                  ?.ominaisuudet.ominaisuudet_palvelutaso"
                :key="ominaisuus.teksti"
                class="feature-yes list-item-left"
              >
                <h2>{{ ominaisuus.teksti }}</h2>
              </v-list-item>
            </v-list>
          </div>
          <div
            v-for="palvelusopimus in valittavatPalvelusopimukset"
            :key="palvelusopimus.nimi"
            class="dialog-list"
          >
            <h1>{{ palvelusopimus.nimi }}</h1>
            <v-list>
              <v-list-item
                v-for="ominaisuus in palvelusopimus.ominaisuudet
                  .ominaisuudet_palvelutaso"
                :key="ominaisuus.teksti"
              >
                <div v-if="ominaisuus.hinta" class="feature-yes">
                  <h4>
                    {{ ominaisuus.hinta.toFixed(2) }}€
                    <span v-if="ominaisuus.hinta_extra">
                      ({{ ominaisuus.hinta_extra.toFixed(2) }}€*)
                    </span>
                  </h4>
                </div>
                <div
                  v-else-if="ominaisuus.sisaltyy_sopimukseen === true"
                  class="feature-yes"
                >
                  <v-icon class="check">mdi-check</v-icon>
                </div>
                <div
                  v-else-if="ominaisuus.sisaltyy_sopimukseen === false"
                  class="feature-no"
                >
                  <v-icon class="close">mdi-close</v-icon>
                </div>
              </v-list-item>
            </v-list>
          </div>
          <h4 class="feature-yes">
            {{ $t("common:serviceApplication.extraPrice") }}
          </h4>
        </div>
        <div class="dialog-close">
          <v-btn color="primary" @click="palvelutasoVertailu = false">
            {{ $t("common:close") }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <!--Komponentti Alkaa-->
    <div class="pp-subheader1">
      {{ $t("common:testForFreeNoCommitment") }}
    </div>
    <div class="pp-subheader2">
      {{ $t("common:openBillsWillBeTakenCareOf") }}
    </div>

    <div class="pp-billing">
      <div class="billing-switch">
        <div
          class="switch-left"
          :class="{ switchOn: laskutusjaksoOnKuukausi }"
          @click="valitseLaskutusjaksoKuukausi"
        >
          <h2>{{ $t("monthUpperCase") }}</h2>
        </div>
        <div
          class="switch-right"
          :class="{ switchOn: laskutusjaksoOnVuosi }"
          @click="valitseLaskutusjaksoVuosi"
        >
          <h2>{{ $t("yearUpperCase") }}</h2>
        </div>
      </div>
    </div>
    <div class="pp-service">
      <div
        v-for="palvelusopimus in valittavatPalvelusopimukset"
        :key="palvelusopimus.id"
        class="service-level"
      >
        <div class="level-header">
          <div class="header-top">
            <v-chip
              v-if="laskutusjakso === laskutusjaksoVuosi"
              class="save-chip"
              color="success"
            >
              <span
                v-if="palvelusopimus.id === valittavatPalvelusopimukset[0].id"
              >
                {{ saastaBasicYear }}
              </span>
              <span
                v-if="palvelusopimus.id === valittavatPalvelusopimukset[1].id"
              >
                {{ saastaProYear }}
              </span>
            </v-chip>
            <h1>{{ palvelusopimus.nimi }}</h1>
          </div>
          <h1 v-if="laskutusjakso === laskutusjaksoKuukausi">
            {{ palvelusopimus.kkhinta }} €/{{ $t("common:monthShorthand") }}
          </h1>
          <h1 v-if="laskutusjakso === laskutusjaksoVuosi">
            {{ palvelusopimus.vuosihinta }} €/{{ $t("common:yearLowerCase") }}
          </h1>
        </div>
        <div class="level-features">
          <div
            v-for="ominaisuus in ominaisuudetTarkeat(
              palvelusopimus.ominaisuudet.ominaisuudet_palvelutaso
            )"
            :key="ominaisuus.teksti"
            class="features-feature"
          >
            <v-icon v-if="ominaisuus.sisaltyy_sopimukseen" class="check">
              mdi-check
            </v-icon>
            <v-icon v-else class="close">mdi-close</v-icon>
            <h2 v-if="ominaisuus.sisaltyy_sopimukseen">
              {{ ominaisuus.teksti }}
            </h2>
            <h2 v-else class="feature-no">
              {{ ominaisuus.teksti }}
            </h2>
          </div>
        </div>
        <div>
          <v-btn class="btn-active" @click="valitsePalvelutaso(palvelusopimus)">
            {{ $t("common:serviceApplication:select") }}
          </v-btn>
        </div>
      </div>
    </div>
    <div class="compare" @click="palvelutasoVertailu = true">
      {{ $t("common:serviceApplication:seeAllContractFeatures") }}
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { PaamiesPalveluhakemus } from "@/class/Paamies";
export default {
  name: "PalvelusopimusPalvelutaso",
  data() {
    return {
      laskutusjaksoKuukausi: PaamiesPalveluhakemus.LASKUTUSJAKSO_KUUKAUSI,
      laskutusjaksoVuosi: PaamiesPalveluhakemus.LASKUTUSJAKSO_VUOSI,
      laskutusjakso: PaamiesPalveluhakemus.LASKUTUSJAKSO_KUUKAUSI,
      laskutusjaksot: [
        PaamiesPalveluhakemus.LASKUTUSJAKSO_KUUKAUSI,
        PaamiesPalveluhakemus.LASKUTUSJAKSO_VUOSI,
      ],
      palvelutasoVertailu: false,
      valittavatPalvelusopimukset0: { ominaisuudet: [] },
      valittavatPalvelusopimukset1: { ominaisuudet: [] },
    };
  },
  computed: {
    ...mapState({
      palvelusopimus: (state) => state.palveluhakemus.paamies.palvelusopimus,
      kayttaja: (state) => state.palveluhakemus.kayttaja,
      valittavatPalvelusopimukset: (state) =>
        state.palveluhakemus.valittavatPalvelusopimukset,
    }),
    laskutusjaksoOnKuukausi() {
      if (this.laskutusjakso === this.laskutusjaksoKuukausi) {
        return true;
      } else {
        return false;
      }
    },
    laskutusjaksoOnVuosi() {
      if (this.laskutusjakso === this.laskutusjaksoVuosi) {
        return true;
      } else {
        return false;
      }
    },
    saastaBasicYear() {
      try {
        const fullPrice = this.valittavatPalvelusopimukset0.kkhinta * 12;
        const thisPrice = this.valittavatPalvelusopimukset0.vuosihinta;
        const save = ((fullPrice - thisPrice) / fullPrice) * 100;
        return this.$t("serviceApplication.saveOnYearlyPayment", {
          savePercent: save.toFixed(2),
        });
      } catch (error) {
        this.$oletusVirheenkasittely(error);
      }
      return "";
    },
    saastaProYear() {
      try {
        const fullPrice = this.valittavatPalvelusopimukset1.kkhinta * 12;
        const thisPrice = this.valittavatPalvelusopimukset1.vuosihinta;
        const save = ((fullPrice - thisPrice) / fullPrice) * 100;
        return this.$t("serviceApplication.saveOnYearlyPayment", {
          savePercent: save.toFixed(2),
        });
      } catch (error) {
        this.$oletusVirheenkasittely(error);
      }
      return "";
    },
  },
  mounted() {
    setTimeout(() => {
      this.valittavatPalvelusopimukset0 = this.valittavatPalvelusopimukset[0];
      this.valittavatPalvelusopimukset1 = this.valittavatPalvelusopimukset[1];
    }, 1000);
    this.valitseLaskutusjaksoKuukausi();
  },
  methods: {
    valitsePalvelutaso(palvelutasoObject) {
      this.$store.commit(
        "palveluhakemus/setPaamiesPalvelusopimus",
        palvelutasoObject
      );
      this.$emit("nextStep", "suggestEmail");
    },
    valitseLaskutusjaksoKuukausi() {
      this.laskutusjakso = this.laskutusjaksoKuukausi;
      this.$store.commit("palveluhakemus/setLaskutusjakso", this.laskutusjakso);
    },
    valitseLaskutusjaksoVuosi() {
      this.laskutusjakso = this.laskutusjaksoVuosi;
      this.$store.commit("palveluhakemus/setLaskutusjakso", this.laskutusjakso);
    },
    ominaisuudetTarkeat(ominaisuudet) {
      const tarkeat = ominaisuudet.filter((e) => e.tarkea === true);
      tarkeat.sort(function (a, b) {
        return b.sisaltyy_sopimukseen - a.sisaltyy_sopimukseen;
      });
      return tarkeat;
    },
  },
};
</script>

<style lang="scss" scoped>
.pp-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.pp-billing {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  color: var(--v-primary-base);
}
.billing-switch {
  display: flex;
  flex-direction: row;
  border: 1px solid var(--v-primary-base);
  border-radius: 15px;
  width: 30%;
}
.switch-left {
  width: 50%;
  border-radius: 15px;
  padding: 0.25rem;
  margin: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.switch-right {
  width: 50%;
  border-radius: 15px;
  padding: 0.25rem;
  margin: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.switchOn {
  background-color: var(--v-primary-base) !important;
  color: var(--v-white-base);
}
.pp-service {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  min-width: 100%;
  justify-content: center;
}
.service-level {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 33%;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  border: 2px solid var(--v-primary-base);
  color: var(--v-primary-base);
  padding: 1rem;
  gap: 1rem;
  position: relative;
}
.level-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  color: var(--v-black-base);
}
.header-top {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  color: var(--v-black-base);
}
.level-features {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.features-feature {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.feature-yes {
  color: var(--v-primary-base);
}
.feature-no {
  color: grey !important;
}
.check {
  align-self: center;
  border-radius: 50%;
  width: fit-content;
  background-color: var(--v-primary-base);
  color: var(--v-white-base);
  z-index: 2;
  padding: 2px;
}
.close {
  align-self: center;
  border-radius: 50%;
  width: fit-content;
  background-color: grey;
  color: var(--v-white-base);
}
.btn-active {
  background-color: var(--v-action-base) !important;
}
.dialog-palvelutasot {
  max-height: 90vh;
}
.dialog-card {
  display: flex;
  flex-direction: column;
  max-height: 90vh;
}
.dialog-lists {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  overflow-y: scroll;
  padding: 1rem;
}
.dialog-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dialog-close {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background: var(--v-primary-base);
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--v-accent-base);
}
.save-chip {
  justify-self: center;
  font-size: 0.75rem;
  position: absolute;
  top: 5px;
  right: 5px;
}
.compare {
  cursor: pointer;
  color: var(--v-primary-base);
  text-decoration: underline;
}
.v-list {
  width: 100%;
}
.v-list-item {
  display: flex;
  justify-content: center;
  text-wrap: nowrap;
}
.v-list-item:nth-child(even) {
  background: var(--v-background-base);
}

.v-list-item:nth-child(odd) {
  background: #fff;
}
.list-item-left {
  justify-content: start;
}
</style>
