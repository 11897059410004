<template>
  <DefList :bordered="true">
    <DefListItem v-if="value.tuoteryhma">
      <template #label>
        {{ $t("common:productGroup.productGroupHeader") }}
      </template>
      <template #value>
        {{ value.tuoteryhma.nimi }}
      </template>
    </DefListItem>
    <DefListItem>
      <template #label>
        {{ $t("common:code") }}
      </template>
      <template #value>
        {{ value.koodi }}
      </template>
    </DefListItem>
    <DefListItem v-if="value.paamies">
      <template #label>
        {{ $t("common:creditor") }}
      </template>
      <template #value>
        {{ value.paamies.nimi }} ({{ value.paamies.id }})
      </template>
    </DefListItem>
    <DefListItem v-if="value.selite">
      <template #label>
        {{ $t("common:definition") }}
      </template>
      <template #value>
        {{ value.selite }}
      </template>
    </DefListItem>
    <DefListItem v-if="value.vapaateksti">
      <template #label>
        {{ $t("common:freeText") }}
      </template>
      <template #value>
        {{ value.vapaateksti }}
      </template>
    </DefListItem>
    <DefListItem>
      <template #label>
        {{ $sovellusIdOtsake }}
      </template>
      <template #value>
        {{ value.id }}
      </template>
    </DefListItem>
    <DefListItem>
      <template #label>
        {{ $t("common:uPrice") }}
      </template>
      <template #value>
        {{ value.ahinta | formatSumToFixed2 }}
      </template>
    </DefListItem>
    <DefListItem v-if="value.laatu">
      <template #label>
        {{ $t("common:quantityUnit") }}
      </template>
      <template #value>
        {{ value.laatu }}
      </template>
    </DefListItem>
    <DefListItem v-if="value.alvtunnus">
      <template #label>
        {{ $t("common:vatId") }}
      </template>
      <template #value>
        {{ value.alvtunnus.tunnus }}
      </template>
    </DefListItem>
    <DefListItem>
      <template #label>
        {{ $t("common:currency") }}
      </template>
      <template #value>
        {{ value.valuutta }}
      </template>
    </DefListItem>
    <DefListItem v-if="value.nykyinen_varastotilanne">
      <template #label>
        {{ $t("common:stockStatus") }}
      </template>
      <template #value>
        {{ value.nykyinen_varastotilanne }}
      </template>
    </DefListItem>
    <DefListItem v-if="value.hankintahinta">
      <template #label>
        {{ $t("common:purchasePrice") }}
      </template>
      <template #value>
        {{ value.hankintahinta | formatSumToFixed2 }}
      </template>
    </DefListItem>
  </DefList>
</template>

<script>
export default {
  name: "OffCanvasDetailsTuote",
  props: {
    value: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
