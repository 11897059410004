import { render, staticRenderFns } from "./FormLaskuHyvita.vue?vue&type=template&id=59d7c7d3&scoped=true"
import script from "./FormLaskuHyvita.vue?vue&type=script&lang=js"
export * from "./FormLaskuHyvita.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59d7c7d3",
  null
  
)

export default component.exports