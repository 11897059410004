import colors from "vuetify/lib/util/colors";
// TODO When updated to vuetify 3, check if we can use HEXA for alpha channel and if so switch background colors based on that for consistency.

const themes = [
  {
    id: "creditvisor",
    colors: {
      primary: "#f7a600",
      secondary: "#f9f9f9",
      accent: "#f9f9f9",
      action: colors.green.darken1,
      background: "#ffcb61", // Lighter version of primary color
      warning: colors.yellow.darken3,
      info: colors.cyan.lighten1,
      success: colors.green.darken1,
      error: colors.deepOrange.darken3,
    },
  },
  {
    id: "uuva",
    colors: {
      primary: "#7851a9",
      secondary: "#9c89ff",
      accent: "#9c89ff",
      action: "#ffdf00",
      background: "#ddd3e9", // Simulates primary color with less alpha
      warning: colors.yellow.darken3,
      info: colors.cyan.lighten1,
      successdark: "#102A43",
      success: colors.green.darken1,
      successlight: "#A6F2DC",
      errordark: "#610316",
      error: colors.deepOrange.darken3,
      errorlight: "#FFADAF",
      white: "#FFFFFF",
      black: "#000000",
      icons: "#444444", // anitta-icons colors
    },
  },
  {
    id: "onebyte",
    colors: {
      primary: "#14394b",
      secondary: "#e3e8eb",
      accent: "#8494a4",
      action: "#ffdf00",
      background: "#899ca5", // Simulates primary color with less alpha
      warning: colors.yellow.darken3,
      info: colors.cyan.lighten1,
      successdark: "#102A43",
      success: colors.green.darken1,
      successlight: "#A6F2DC",
      errordark: "#610316",
      error: colors.deepOrange.darken3,
      errorlight: "#FFADAF",
      white: "#FFFFFF",
      black: "#000000",
    },
  },
  {
    id: "talenom",
    colors: {
      primary: "#3f3759",
      secondary: colors.deepPurple.lighten3,
      accent: colors.deepPurple.lighten3,
      action: colors.green.darken1,
      background: "#cfcdd5", // Simulates primary color with less alpha
      warning: colors.yellow.darken3,
      info: colors.cyan.lighten1,
      successdark: "#102A43",
      success: colors.green.darken1,
      successlight: "#A6F2DC",
      errordark: "#610316",
      error: colors.deepOrange.darken3,
      errorlight: "#FFADAF",
    },
  },
  {
    id: "ijcapital",
    colors: {
      primary: "#14394b",
      secondary: "#e3e8eb",
      accent: "#8494a4",
      action: "#ffdf00",
      background: "#899ca5", // Simulates primary color with less alpha
      warning: colors.yellow.darken3,
      info: colors.cyan.lighten1,
      successdark: "#102A43",
      success: colors.green.darken1,
      successlight: "#A6F2DC",
      errordark: "#610316",
      error: colors.deepOrange.darken3,
      errorlight: "#FFADAF",
      white: "#FFFFFF",
      black: "#000000",
    },
  },
];

const getTheme = (id) => {
  let teemaId = id;
  const teema = themes.find((theme) => theme.id === teemaId);

  if (!teema) throw new Error("Teemaa ei löydy");
  return teema.colors;
};

export { themes, getTheme };
