var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.palvelusopimusId
    ? _c("div", [
        _c(
          "div",
          { staticClass: "sub-container" },
          [
            _c("CardTitle", {
              attrs: {
                color: "black--text",
                text: _vm.$t("common:settings.subscription"),
                center: false,
              },
            }),
            _c(
              "v-card",
              { staticClass: "sub-card", attrs: { flat: "", outlined: "" } },
              [
                _c(
                  "v-radio-group",
                  {
                    staticClass: "radio",
                    attrs: {
                      "hide-details": "",
                      items: _vm.palvelusopimusItems,
                      disabled: !_vm.onkoVoimassaolevaTilausAktiivinen,
                    },
                    model: {
                      value: _vm.palvelusopimusId,
                      callback: function ($$v) {
                        _vm.palvelusopimusId = $$v
                      },
                      expression: "palvelusopimusId",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "subscription" },
                      [
                        _c("v-radio", {
                          attrs: { value: _vm.palvelusopimukset[0].id },
                        }),
                        _c("div", { staticClass: "sub-info" }, [
                          _c("div", [
                            _vm._v(_vm._s(_vm.palvelusopimukset[0].nimi)),
                          ]),
                          _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("formatSumToFixed2")(
                                    _vm.palvelusopimukset[0].kkhinta
                                  )
                                ) +
                                " / " +
                                _vm._s(_vm.$t("common:monthShorthand")) +
                                " (" +
                                _vm._s(
                                  _vm._f("formatSumToFixed2")(
                                    _vm.palvelusopimukset[0].vuosihinta
                                  )
                                ) +
                                " / " +
                                _vm._s(_vm.$t("common:yearLowerCase")) +
                                ") "
                            ),
                          ]),
                          _c("div", [
                            _vm._v(_vm._s(_vm.palvelusopimus0Selite)),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c("v-divider", { attrs: { width: "100%" } }),
                    _c(
                      "div",
                      { staticClass: "subscription" },
                      [
                        _c("v-radio", {
                          attrs: { value: _vm.palvelusopimukset[1].id },
                        }),
                        _c("div", { staticClass: "sub-info" }, [
                          _c("div", [
                            _vm._v(_vm._s(_vm.palvelusopimukset[1].nimi)),
                          ]),
                          _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("formatSumToFixed2")(
                                    _vm.palvelusopimukset[1].kkhinta
                                  )
                                ) +
                                " / " +
                                _vm._s(_vm.$t("common:monthShorthand")) +
                                " (" +
                                _vm._s(
                                  _vm._f("formatSumToFixed2")(
                                    _vm.palvelusopimukset[1].vuosihinta
                                  )
                                ) +
                                " / " +
                                _vm._s(_vm.$t("common:yearLowerCase")) +
                                ") "
                            ),
                          ]),
                          _c("div", [
                            _vm._v(_vm._s(_vm.palvelusopimus1Selite)),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c("v-divider", { attrs: { width: "100%" } }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "sub-btn" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "action width330px",
                    attrs: { disabled: !_vm.onkoTallennaTilausSallittu },
                    on: {
                      click: function ($event) {
                        return _vm.vaihdaSopimus()
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("common:settings.saveSubscription")) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "billing-container" },
          [
            _c("CardTitle", {
              attrs: {
                color: "black--text",
                text: _vm.$t("common:settings.changeInvoicingPeriod"),
                center: false,
              },
            }),
            _c("div", { staticClass: "switch" }, [
              _c(
                "div",
                { staticClass: "switch-box" },
                [
                  _c("v-switch", {
                    attrs: {
                      "true-value": "VUOSI",
                      "false-value": "KUUKAUSI",
                      disabled: !_vm.onkoVoimassaolevaTilausAktiivinen,
                    },
                    model: {
                      value: _vm.palvelusopimusLaskutusjakso,
                      callback: function ($$v) {
                        _vm.palvelusopimusLaskutusjakso = $$v
                      },
                      expression: "palvelusopimusLaskutusjakso",
                    },
                  }),
                  _c("div", [
                    _vm._v(_vm._s(_vm.$t("common:settings.yearlyBilling"))),
                  ]),
                  _c("div", [
                    _vm._v(
                      "(" +
                        _vm._s(_vm.$t("common:settings.saveTenPercent")) +
                        ")"
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "switch-btn" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "action width330px",
                      attrs: {
                        disabled: !(
                          _vm.onkoVoimassaolevaTilausAktiivinen &&
                          _vm.onkoVaihdettuLaskutusjaksovalintaa
                        ),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.vaihdaLaskutusjakso()
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("common:settings.saveInvoicingPeriod")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "history-container" },
          [
            _c("CardTitle", {
              attrs: {
                color: "black--text",
                text: _vm.$t("common:settings.history"),
                center: false,
              },
            }),
            _c(
              "v-tabs",
              {
                attrs: {
                  "background-color": "primary",
                  color: "grey lighten-4",
                  "slider-color": "transparent",
                  "show-arrows": "mobile",
                  centered: "",
                },
                model: {
                  value: _vm.selectedTab,
                  callback: function ($$v) {
                    _vm.selectedTab = $$v
                  },
                  expression: "selectedTab",
                },
              },
              _vm._l(_vm.tabs, function (item) {
                return _c(
                  "v-tab",
                  {
                    key: item.index,
                    attrs: { href: `#${item.id}`, ripple: false },
                  },
                  [_c("p", [_vm._v(_vm._s(item.label))])]
                )
              }),
              1
            ),
            _c(
              "v-tabs-items",
              {
                model: {
                  value: _vm.selectedTab,
                  callback: function ($$v) {
                    _vm.selectedTab = $$v
                  },
                  expression: "selectedTab",
                },
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.selectedTab === "tilaushistoria",
                        expression: "selectedTab === 'tilaushistoria'",
                      },
                    ],
                    staticClass: "pb-6",
                  },
                  [
                    _c("v-data-table", {
                      staticClass: "elevation-1",
                      attrs: {
                        headers: _vm.tilausHeaders,
                        items: _vm.tilaukset,
                        "item-key": "id",
                        "sort-by": "alkamispaiva",
                        dense: _vm.tiivisNakyma,
                        "hide-default-footer":
                          _vm.asetukset.tilauksetKokonaisLkm <= 3,
                        "server-items-length":
                          _vm.asetukset.tilauksetKokonaisLkm,
                        page: _vm.page,
                        "items-per-page": _vm.itemsPerPage,
                        "footer-props": {
                          "items-per-page-options": [_vm.itemsPerPage],
                          "items-per-page-text": "Rivejä per sivu:",
                          "items-per-page-all-text": "Kaikki",
                        },
                      },
                      on: {
                        "update:page": function ($event) {
                          _vm.page = $event
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "item",
                            fn: function ({ item }) {
                              return [
                                _c("TableRow", { attrs: { data: item } }, [
                                  _c(
                                    "td",
                                    { staticClass: "text-no-wrap text-left" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.laskutusjakso) + " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "text-no-wrap text-left" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$dateFnsFormat(
                                              item.alkamispaiva
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "text-no-wrap text-left" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$dateFnsFormat(
                                              item.paattymispaiva
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "text-no-wrap text-left" },
                                    [_vm._v(" " + _vm._s(item.selite) + " ")]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "text-no-wrap text-left" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$dateFnsFormat(
                                              item.seuraava_laskutuspaiva
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "text-no-wrap text-left" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.getTilausTila(item.tila)) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2125830359
                      ),
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.selectedTab === "maksuhistoria",
                        expression: "selectedTab === 'maksuhistoria'",
                      },
                    ],
                    staticClass: "pb-4",
                  },
                  [
                    _c("v-data-table", {
                      staticClass: "elevation-1",
                      attrs: {
                        headers: _vm.paamiesLaskuHeaders,
                        items: _vm.asetukset.paamieslaskut,
                        "item-key": "id",
                        "sort-by": "erapaiva",
                        dense: _vm.tiivisNakyma,
                        "hide-default-footer":
                          _vm.asetukset.paamieslaskutKokonaisLkm <= 3,
                        "server-items-length":
                          _vm.asetukset.paamieslaskutKokonaisLkm,
                        page: _vm.laskuPage,
                        "items-per-page": _vm.itemsPerPage,
                        "footer-props": {
                          "items-per-page-options": [_vm.itemsPerPage],
                          "items-per-page-text": "Rivejä per sivu:",
                          "items-per-page-all-text": "Kaikki",
                        },
                      },
                      on: {
                        "update:page": function ($event) {
                          _vm.laskuPage = $event
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "item",
                            fn: function ({ item }) {
                              return [
                                _c("TableRow", { attrs: { data: item } }, [
                                  _c(
                                    "td",
                                    { staticClass: "text-no-wrap text-left" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$dateFnsFormat(item.laskupaiva)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "text-no-wrap text-left" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$dateFnsFormat(item.erapaiva)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "text-no-wrap text-left" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.selite) +
                                          " - " +
                                          _vm._s(item.laskutusjakso) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "text-no-wrap text-left" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("formatSumToFixed2")(
                                              item.summa
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "text-no-wrap text-left" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("formatSumToFixed2")(
                                              item.avoinna
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "text-no-wrap text-left" },
                                    [
                                      item.tila === "A"
                                        ? _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("common:open")) +
                                                " "
                                            ),
                                          ])
                                        : _vm._e(),
                                      item.tila === "M"
                                        ? _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("common:paid")) +
                                                " "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "text-no-wrap text-left" },
                                    [
                                      _c("v-tooltip", {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          text: "",
                                                          icon: "",
                                                          small:
                                                            _vm.tiivisNakyma,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.haeLaskukopio(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            small:
                                                              _vm.tiivisNakyma,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " anitta-icon-file-download "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "default",
                                              fn: function () {
                                                return [
                                                  _c("span", [
                                                    _vm._v("Lataa laskukopio"),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1841568475
                      ),
                    }),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "cancel-container" }, [
          _vm.onkoVoimassaolevaTilausAktiivinen
            ? _c("div", { staticClass: "cancel-sub" }, [
                _c(
                  "div",
                  [
                    _c("CardTitle", {
                      attrs: {
                        color: "black--text",
                        text: _vm.$t("common:settings.cancelSubscription"),
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "cs-content" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("common:settings.youCanCancelAnytime")) +
                      " "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "cs-btn" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "grey",
                        on: {
                          click: function ($event) {
                            return _vm.peruutaTilaus()
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("common:settings.cancelSubscription")
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.onkoVoimassaolevaTilausPeruutettu
            ? _c("div", { staticClass: "cancel-sub" }, [
                _c(
                  "div",
                  [
                    _c("CardTitle", {
                      attrs: {
                        color: "black--text",
                        text: _vm.$t(
                          "common:settings.continueCanceledSubscription"
                        ),
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "cs-content" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("common:settings.youCanContinueAnytime")) +
                      " "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "cs-btn" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "grey",
                        on: {
                          click: function ($event) {
                            return _vm.aktivoiTilaus()
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "common:settings.continueCanceledSubscription"
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]),
      ])
    : _c("div", { staticClass: "else" }, [
        _c("h2", [
          _vm._v(
            " " +
              _vm._s(_vm.$t("common:settings.creditorLacksServiceAgreement")) +
              " "
          ),
        ]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }