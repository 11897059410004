var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { class: _vm.cardClass, attrs: { color: "info", width: "480" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "pa-0 d-flex justify-center align-center",
              attrs: { cols: "2" },
            },
            [
              _c("v-icon", { attrs: { dark: "", "x-large": "" } }, [
                _vm._v("sentiment_dissatisfied"),
              ]),
            ],
            1
          ),
          _c("v-col", { staticClass: "pa-0", attrs: { cols: "10" } }, [
            _c(
              "span",
              { staticClass: "text-subtitle-1", class: _vm.tekstiClass },
              [_vm._v(_vm._s(_vm.teksti))]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }