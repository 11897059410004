import Vue from "vue";
import i18next from "i18next";
import I18NextVue from "i18next-vue";
import translationResources from "../../public/locales/index.js";
import * as kaannokset from "@/locales";

/** Käännöksiä ei ole tehty, joten käytetään toistaiseksi oletuksena suomea. */
const fallbackLngI18next = "fi";

i18next.init({
  initImmediate: false,
  ns: ["common"],
  defaultNS: "common",
  fallbackLng: fallbackLngI18next,
  lng: localStorage.getItem("lngI18next"),
  resources: {
    ...translationResources,
  },
  whitelist: ["fi", "en"],
});
Vue.use(I18NextVue, { i18next });

export function t(str) {
  return i18next.t(str) || str;
}

// TODO tarkista ja dokumentoi missä kuuluu käyttää formaattia 'fi' ja missä 'fi-FI'
export function getLanguageLocale() {
  return localStorage.getItem("lngLocaleI18next")
    ? localStorage.getItem("lngLocaleI18next")
    : "fi-FI";
}

export function getLanguage() {
  return localStorage.getItem("lngI18next")
    ? localStorage.getItem("lngI18next")
    : "fi";
}

export function getFlexmonsterKaannokset() {
  return kaannokset["default"]["kaannokset_" + getLanguage()];
}
