<template>
  <div class="raportti">
    <div v-if="data.type === '41'" class="r-henkilo">
      <div class="h-header">
        <h2>Yksityishenkilön luottotietoraportti</h2>
      </div>
      <div class="h-perustiedot">
        <h3>Perustiedot</h3>
        <div class="data-groups">
          <div class="p-group">
            <div>
              <b>Päivämäärä:</b>
            </div>
            <div>
              <b>Kyselyn Tarkoitus:</b>
            </div>
            <div><b>Henkilötunnus:</b></div>
            <div><b>Nimi:</b></div>
            <div><b>Osoite:</b></div>
            <div><b>Yritysyhteyksiä:</b></div>
            <div><b>Liiketoimintakiellossa:</b></div>
            <div><b>Edunvalvonnassa:</b></div>
          </div>
          <div class="p-group">
            <div>{{ $dateFnsFormat(data.pvm) || "-" }}</div>
            <div>{{ data.tarkoitus || "-" }}</div>
            <div>{{ data.hetu || "-" }}</div>
            <div>{{ data.nimi || "-" }}</div>
            <div>
              <div v-if="data.osoite">
                {{
                  `${data.osoite?.street}, ${data.osoite?.zip} ${data.osoite?.town}`
                }}
              </div>
              <div v-else>Osoitetietoja ei löydy</div>
            </div>
            <div>
              <div v-if="data.yritysyhteyksia">Kyllä</div>
              <div v-else>Ei</div>
            </div>
            <div>
              <div v-if="data.liiketoimintakielto">Kyllä</div>
              <div v-else>Ei</div>
            </div>
            <div>
              <div v-if="data.edunvalvonta">Kyllä</div>
              <div v-else>Ei</div>
            </div>
          </div>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="raportti-merkinnat">
        <h3>Merkinnät</h3>
        <div v-for="(m, i) in data.merkinnat" :key="i" class="merkinnat-card">
          <div class="mcard-header">
            <div class="custom-chip">
              {{ m.creditInformationEntryCode || "-" }}
            </div>
            <div>
              <b>{{ m.creditInformationEntryText || "-" }}</b>
            </div>
            <div v-if="m.amountOfPaymentDefault">
              ({{ m.amountOfPaymentDefault || "-" }} {{ data.valuutta || "-" }})
            </div>
            <v-spacer></v-spacer>
          </div>
          <div class="mcard-content">
            <div>
              <div class="mini-header">Rekisteröinti pvm</div>
              <div class="mini-content">
                {{ $dateFnsFormat(m.creditInformationEntryDate) || "-" }}
              </div>
            </div>
            <div>
              <div class="mini-header">Merkintä poistuu</div>
              <div class="mini-content">
                {{ $dateFnsFormat(m.creditInformationEntryExpires) || "-" }}
              </div>
            </div>
            <div>
              <div class="mini-header">Selite</div>
              <div class="mini-content">
                {{ m.debtTypeText || "-" }}
              </div>
            </div>
            <div>
              <div class="mini-header">Ref</div>
              <div class="mini-content">
                {{ m.ref === "0" || null ? "-" : m.ref }}
              </div>
            </div>
            <div>
              <div class="mini-header">Velkoja</div>
              <div class="mini-content">{{ m.creditor || "-" }}</div>
            </div>
            <div>
              <div class="mini-header">Lähde</div>
              <div class="mini-content">
                {{ m.sourceOfInformation || "-" }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="raportti-yritysyhteydet">
        <h3>Yritysyhteydet</h3>
        <div
          v-for="(y, i) in data.yritysyhteydet"
          :key="i"
          class="yritysyhteydet-card"
        >
          <div class="ycard-header">
            <div>
              <b>{{ y.companyIdentificationData.name || "-" }}</b>
            </div>
          </div>
          <div class="ycard-content">
            <div>
              <div>Rooli</div>
              <div>{{ y.positionInCompany.positionText || "-" }}</div>
            </div>
            <div>
              <div>Yhtiömuoto</div>
              <div>{{ y.companyIdentificationData.companyForm || "-" }}</div>
            </div>
            <div>
              <div>Yrityksen tila</div>
              <div>
                <div v-if="y.positionInCompany.endDate">Ei Aktiivinen</div>
                <div v-else>Aktiivinen</div>
              </div>
            </div>
            <div>
              <div>Viimeisin merkintä</div>
              <div>{{ y.companyPaymentDefaults.latestExecution || "-" }}</div>
            </div>
            <div>
              <div>Päiväys</div>
              <div>
                {{
                  $dateFnsFormat(y.companyPaymentDefaults.executionDate) || "-"
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="data.type === 'Q057'" class="r-yritys">
      <div class="y-header">
        <h2>Yrityksen luottotietoraportti</h2>
      </div>
      <div class="y-yksilointi">
        <h3>Yksilöintitiedot</h3>
        <div class="data-groups">
          <div class="key-group">
            <div>Nimi:</div>
            <div>Yritysmuoto:</div>
            <div>Kotipaikka:</div>
            <div>Kieli:</div>
            <div>Päätoimiala:</div>
            <div>Käyntiosoite:</div>
            <div>Postiosoite:</div>
            <div>Sähköposti:</div>
            <div>Puhelin:</div>
            <div>Faksi:</div>
            <div>Nettisivut:</div>
          </div>
          <div>
            <div>{{ data.yksilointi.nimi || "-" }}</div>
            <div>{{ data.yksilointi.yritysmuoto || "-" }}</div>
            <div>{{ data.yksilointi.kotipaikka || "-" }}</div>
            <div>{{ data.yksilointi.kieli || "-" }}</div>
            <!-- Erikoistoiminta jos FI -->
            <div>
              {{ data.yksilointi.paatoimiala.koodi || "-" }}
              {{ data.yksilointi.paatoimiala.teksti || "-" }}
            </div>
            <div>
              {{ data.yksilointi.kayntiosoite.katu || "-" }},
              {{ data.yksilointi.kayntiosoite.zip || "-" }}
              {{ data.yksilointi.kayntiosoite.kaupunki || "-" }}
            </div>
            <div>
              {{ data.yksilointi.postiosoite.katu || "-" }},
              {{ data.yksilointi.postiosoite.zip || "-" }}
              {{ data.yksilointi.postiosoite.kaupunki || "-" }}
            </div>
            <div>{{ data.yksilointi.yhteystiedot.email || "-" }}</div>
            <div>{{ data.yksilointi.yhteystiedot.puhelin || "-" }}</div>
            <div>{{ data.yksilointi.yhteystiedot.fax || "-" }}</div>
            <div>{{ data.yksilointi.yhteystiedot.www || "-" }}</div>
          </div>
        </div>
      </div>
      <div class="y-perus">
        <h3>Perustiedot</h3>
        <div class="data-groups">
          <div class="key-group">
            <div>Sektoriluokitus:</div>
            <div>Liikevaihtoluokka:</div>
            <div>Henkilöstöluokka:</div>
            <div>Osakepääoma:</div>
            <div>Osakkeiden lkm:</div>
            <div>Tuontitoimintaa:</div>
            <div>Vientitoimintaa:</div>
            <div>Omistustyyppi:</div>
            <div>Perustettu:</div>
          </div>
          <div>
            <div>{{ data.perus.sektoriuokitus || "-" }}</div>
            <div>
              {{
                combineData(
                  [
                    data.perus.liikevaihtoluokka.min,
                    "000",
                    "-",
                    data.perus.liikevaihtoluokka.max,
                    "000",
                    data.perus.valuutta,
                  ],
                  " "
                )
              }}
            </div>
            <div>
              {{
                combineData(
                  [
                    data.perus.henkilostoluokka.min,
                    "-",
                    data.perus.henkilostoluokka.max,
                  ],
                  " "
                )
              }}
            </div>
            <div>
              {{ data.perus.osakepaaoma || "-" }}
              {{ data.perus.valuutta || "-" }}
            </div>
            <div>{{ data.perus.osakkeidenLkm || "-" }}</div>
            <div>{{ data.perus.tuontitoimintaa || "-" }}</div>
            <div>{{ data.perus.vientitoimintaa || "-" }}</div>
            <div>{{ data.perus.omistustyyppi || "-" }}</div>
            <div>{{ $dateFnsFormat(data.perus.pvmAloitus) || "-" }}</div>
          </div>
        </div>
      </div>
      <div class="rekisteri">
        <h3>Rekisteritiedot</h3>
        <v-simple-table>
          <template #default>
            <thead>
              <tr>
                <th>Rekisteri</th>
                <th>Tila</th>
                <th>Pvm</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in data.rekisteri" :key="item.nimi">
                <td>{{ item.nimi || "-" }}</td>
                <td>{{ item.tila == "1" ? "Aktiivinen" : "Ei Aktiivinen" }}</td>
                <td>{{ $dateFnsFormat(item.pvm) || "-" }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <div class="y-maksuhairiot">
        <h3>Maksuhäiriöt</h3>
        <v-simple-table>
          <template #default>
            <thead>
              <tr>
                <th>Pvm</th>
                <th>Koodi</th>
                <th>Summa</th>
                <th>Velkoja</th>
                <th>Selite</th>
                <th>Ref</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in data.luottohairio" :key="item.skey">
                <td>{{ $dateFnsFormat(item.paymentDefaultDate) || "-" }}</td>
                <td>{{ item.paymentDefaultCode || "-" }}</td>
                <td>{{ item.amountOfPaymentDefault || "-" }}</td>
                <td>{{ item.creditor || "-" }}</td>
                <td>{{ item.paymentDefaultText || "-" }}</td>
                <td>{{ item.ref || "-" }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>

      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LuottotietoHakuRaportti",
  components: {},
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    combineData(inputArray, separator) {
      if (inputArray.some((e) => e === null)) {
        return "-";
      } else {
        return inputArray.join(separator);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.raportti {
  height: 100%;
  width: 100%;
  min-height: 80vh;
}
.data-groups {
  width: 50%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.key-group {
  font-weight: bold;
}
.r-henkilo {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .raportti-merkinnat,
  .raportti-yritysyhteydet {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  .mcard-header,
  .ycard-header {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 1rem;
    background-color: hsl(267, 35%, 49%, 0.25);

    align-items: center;
  }
  .ycard-content,
  .mcard-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    padding: 1rem;
    background-color: hsl(267, 35%, 49%, 0.15);
  }
  .mini-header {
    font-size: 1em;
  }
  .mini-content {
    font-size: 1.2em;
  }
  .ycard-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    padding: 1rem;
    background-color: hsl(267, 35%, 49%, 0.15);
  }

  .custom-chip {
    //Follows most v-chip styles
    padding: 0.5rem;
    border: 1px solid black;
    height: 32px;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 5px;
  }
}
.r-yritys {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.info-pair {
  max-width: max-content;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
