var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c("h3", { staticClass: "my-4" }, [_vm._v("Yhteystiedot")]),
      _c("v-text-field", {
        staticClass: "required",
        attrs: {
          label: "Yrityksen nimi",
          rules: [_vm.$validationRules.required],
          outlined: "",
          required: "",
        },
        model: {
          value: _vm.nimi,
          callback: function ($$v) {
            _vm.nimi = $$v
          },
          expression: "nimi",
        },
      }),
      _c("v-text-field", {
        staticClass: "required",
        attrs: {
          label: "Y-tunnus",
          rules: [
            _vm.$validationRules.required,
            _vm.$validationRules.onkoYtunnus,
          ],
          outlined: "",
          "error-messages": _vm.tunnusErrors,
        },
        on: {
          blur: function ($event) {
            return _vm.tarkistaTunnuksenOikeellisuus(_vm.tunnus)
          },
        },
        model: {
          value: _vm.tunnus,
          callback: function ($$v) {
            _vm.tunnus = $$v
          },
          expression: "tunnus",
        },
      }),
      _c("v-text-field", {
        staticClass: "required",
        attrs: {
          label: "Lähisoite",
          rules: [_vm.$validationRules.required],
          outlined: "",
        },
        model: {
          value: _vm.osoite,
          callback: function ($$v) {
            _vm.osoite = $$v
          },
          expression: "osoite",
        },
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "py-1", attrs: { md: "5" } },
            [
              _c("v-text-field", {
                staticClass: "required",
                attrs: {
                  label: "Postinumero",
                  rules: [_vm.$validationRules.required],
                  outlined: "",
                },
                model: {
                  value: _vm.postinumero,
                  callback: function ($$v) {
                    _vm.postinumero = $$v
                  },
                  expression: "postinumero",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "py-1", attrs: { md: "7" } },
            [
              _c("v-text-field", {
                staticClass: "required",
                attrs: {
                  label: "Postitoimipaikka",
                  rules: [_vm.$validationRules.required],
                  outlined: "",
                },
                model: {
                  value: _vm.toimipaikka,
                  callback: function ($$v) {
                    _vm.toimipaikka = $$v
                  },
                  expression: "toimipaikka",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("InputPuhelinNumero", {
        attrs: { required: false, outlined: true },
        model: {
          value: _vm.puhelin,
          callback: function ($$v) {
            _vm.puhelin = $$v
          },
          expression: "puhelin",
        },
      }),
      _c("v-text-field", {
        staticClass: "required",
        attrs: {
          "append-icon": "",
          label: "Sähköpostiosoite",
          type: "text",
          rules: [_vm.$validationRules.required, _vm.$validationRules.email],
          outlined: "",
        },
        model: {
          value: _vm.email,
          callback: function ($$v) {
            _vm.email = $$v
          },
          expression: "email",
        },
      }),
      _c("v-select", {
        staticClass: "required",
        attrs: {
          label: "Maa",
          type: "text",
          items: _vm.maat,
          "item-value": "value",
          "item-text": "selite",
          "menu-props": { zIndex: "999", auto: true },
          rules: [_vm.$validationRules.required],
          outlined: "",
        },
        model: {
          value: _vm.maa,
          callback: function ($$v) {
            _vm.maa = $$v
          },
          expression: "maa",
        },
      }),
      _c("v-text-field", {
        attrs: { label: "WWW-osoite", outlined: "" },
        model: {
          value: _vm.www,
          callback: function ($$v) {
            _vm.www = $$v
          },
          expression: "www",
        },
      }),
      _c("h3", { staticClass: "my-4" }, [_vm._v("Laskutustiedot")]),
      _c("p", { staticClass: "text-subtitle-1 my-4" }, [
        _vm._v(
          " Valitse sinulle mieluisin laskutustapa. Voit myöhemmin vaihtaa nämä Asetuksista. "
        ),
      ]),
      _c("v-select", {
        staticClass: "required",
        attrs: {
          label: "Laskutustapa",
          outlined: "",
          items: _vm.lahetystavat,
          "item-value": "value",
          "item-text": "label",
          rules: [_vm.$validationRules.required],
        },
        model: {
          value: _vm.lahetystapa,
          callback: function ($$v) {
            _vm.lahetystapa = $$v
          },
          expression: "lahetystapa",
        },
      }),
      _vm.lahetystapa === "verkkolasku"
        ? [
            _c("v-text-field", {
              attrs: {
                label: "Verkkolaskuosoite",
                placeholder: _vm.verkkolaskuosoitePlaceholder,
                "persistent-placeholder": "",
                disabled: "",
                outlined: "",
              },
            }),
            _c("v-autocomplete", {
              staticClass: "py-1 required",
              attrs: {
                clearable: "",
                items: _vm.verkkolaskuoperaattorit,
                filter: _vm.verkkolaskuoperaattoriFilter,
                "item-text": "autocomplete_selite",
                "item-value": "id",
                label: "Verkkolaskuoperaattori",
                outlined: "",
                rules: [_vm.$validationRules.required],
              },
              model: {
                value: _vm.verkkolaskuoperaattori,
                callback: function ($$v) {
                  _vm.verkkolaskuoperaattori = $$v
                },
                expression: "verkkolaskuoperaattori",
              },
            }),
          ]
        : _vm.lahetystapa === "email"
        ? _c("v-text-field", {
            attrs: {
              label: "Laskutuksen sähköpostiosoite",
              type: "text",
              placeholder: _vm.email,
              "persistent-placeholder": "",
              "persistent-hint": "",
              hint: "Tyhjäksi jätettäessä käytetään yhteystietojen sähköpostia",
              rules: [_vm.$validationRules.email],
              outlined: "",
            },
            model: {
              value: _vm.laskutus_email,
              callback: function ($$v) {
                _vm.laskutus_email = $$v
              },
              expression: "laskutus_email",
            },
          })
        : _vm._e(),
      _c("h3", { staticClass: "my-4" }, [_vm._v("Pankkitili")]),
      _c("v-text-field", {
        staticClass: "required",
        attrs: {
          label: "Tilinumero (IBAN)",
          outlined: "",
          rules: [
            _vm.$validationRules.required,
            _vm.$validationRules.isValidIBAN,
          ],
        },
        on: { change: _vm.selvitaIBANistaBIC },
        model: {
          value: _vm.iban,
          callback: function ($$v) {
            _vm.iban = $$v
          },
          expression: "iban",
        },
      }),
      _c("v-select", {
        staticClass: "required",
        attrs: {
          label: "Pankki (BIC)",
          outlined: "",
          items: _vm.bicItems,
          "item-value": "value",
          "item-text": "label",
          hint: _vm.paamies.bic ? _vm.paamies.bic : "",
          rules: [_vm.$validationRules.required],
        },
        model: {
          value: _vm.bic,
          callback: function ($$v) {
            _vm.bic = $$v
          },
          expression: "bic",
        },
      }),
      _c(
        "v-row",
        { staticClass: "mt-4 pa-3", attrs: { justify: "center" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "mx-4",
              attrs: {
                block: "",
                large: "",
                color: "action",
                disabled: !_vm.valid,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("seuraava")
                },
              },
            },
            [_vm._v(" Seuraava ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }