var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-tooltip", {
    attrs: { top: "", disabled: !_vm.infoteksti },
    scopedSlots: _vm._u(
      [
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    class: _vm.kaikkiLuokat,
                    attrs: { color: _vm.taustavari, to: _vm.to },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("click")
                      },
                    },
                  },
                  on
                ),
                [
                  _c("v-icon", { attrs: { color: "ikoniVari" } }, [
                    _vm._v(" " + _vm._s(_vm.ikoni) + " "),
                  ]),
                  _vm._t("default", function () {
                    return [_vm._v(" " + _vm._s(_vm.teksti) + " ")]
                  }),
                ],
                2
              ),
            ]
          },
        },
        {
          key: "default",
          fn: function () {
            return [_vm._v(" " + _vm._s(_vm.infoteksti) + " ")]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }