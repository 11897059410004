<template>
  <li v-if="value" class="inlineList__item">
    <router-link v-if="to" :to="to" class="inlineList__link">
      {{ value }}
    </router-link>
    <template v-else>
      {{ value }}
    </template>
  </li>
</template>

<script>
export default {
  name: "InlineListItem",
  props: {
    value: {
      type: [Number, String],
      required: false,
      default() {
        return "";
      },
    },
    to: {
      type: Object,
      required: false,
      default() {
        return null;
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
