<template>
  <v-form v-model="valid" @submit.prevent="submit">
    <v-text-field
      v-model="value.username"
      label="Käyttäjätunnus"
      :rules="[$validationRules.required]"
      :outlined="variant === 'outlined'"
      :solo="variant === 'solo'"
      required
      autofocus
    />
    <v-text-field
      v-model="value.password"
      label="Salasana"
      :append-icon="showPassword ? 'visibility_off' : 'visibility'"
      :type="showPassword ? 'text' : 'password'"
      :rules="[$validationRules.required]"
      :outlined="variant === 'outlined'"
      :solo="variant === 'solo'"
      required
      @click:append="showPassword = !showPassword"
    />

    <v-btn
      type="submit"
      :disabled="!valid || loading"
      :loading="loading"
      block
      large
      color="primary"
      class="ml-0"
    >
      Kirjaudu
    </v-btn>
  </v-form>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";
// Refaktoroidaan variant vue3:sen päivityksen yhteydessä

export default {
  name: "FormLogin",
  mixins: [FormMixin],
  props: {
    variant: {
      type: String,
      default: "outlined",
    },
  },
  data() {
    return {
      showPassword: false,
    };
  },
};
</script>

<style lang="scss" scoped></style>
