var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "login" },
    [
      _c("div", { staticClass: "login__top" }, [
        _c("div", { staticClass: "login__wrap" }, [
          _c(
            "div",
            { staticClass: "login__col login__col--left pr-6" },
            [
              _c("Logo", {
                staticClass: "mx-0",
                attrs: { "logo-key": _vm.logoKey, "enable-link": false },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "login__col login__col--right pa-6" },
            [
              _c(
                "v-card",
                { staticClass: "pa-12" },
                [
                  _c("h2", { staticClass: "text-h5 mb-4" }, [
                    _vm._v("Salasanan uusiminen"),
                  ]),
                  _vm.loading ? _c("Spinner") : _vm._e(),
                  _vm.formUusiSalasanaData.token === "invalid"
                    ? _c("p", [
                        _vm._v(" Salasanan uusimispyyntö ei ole voimassa. "),
                      ])
                    : !_vm.loading
                    ? [
                        _c("p", [
                          _vm._v(
                            "Kirjoita uusi salasanasi alla olevaan kenttään."
                          ),
                        ]),
                        _c("FormUusiSalasana", {
                          attrs: { loading: _vm.loading },
                          on: { submit: _vm.asetaUusiSalasana },
                          model: {
                            value: _vm.formUusiSalasanaData,
                            callback: function ($$v) {
                              _vm.formUusiSalasanaData = $$v
                            },
                            expression: "formUusiSalasanaData",
                          },
                        }),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ]),
        _vm._m(0),
      ]),
      _c("BusinessFooter"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "login__wrap" }, [
      _c("div", { staticClass: "login__col login__col--left pa-6" }),
      _c(
        "div",
        { staticClass: "login__col login__col--right pa-6 loginHelp pt-6" },
        [
          _c("p", [
            _vm._v("Palvelu on käytössä vain toimeksiantaja-asiakkaillemme."),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }