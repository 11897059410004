import { render, staticRenderFns } from "./OffCanvasDetailsToimittaja.vue?vue&type=template&id=30e30e78&scoped=true"
import script from "./OffCanvasDetailsToimittaja.vue?vue&type=script&lang=js"
export * from "./OffCanvasDetailsToimittaja.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30e30e78",
  null
  
)

export default component.exports