import BaseSelaa from "@/store/modules/baseSelaa";
import { TabsOstolasku } from "@/utils/tabs";
import { HttpError } from "@/utils/errors";
import {
  doRequestWithTimeout,
  oletusVirheenkasittely,
  sentryCaptureCustom,
} from "@/utils/misc";

export default class OstolaskuSelaa extends BaseSelaa {
  state() {
    return {
      ...super.state(),
      apiName: "Ostolaskut",
      currentTab: "avoinna",
      maksuunVietavatOstolaskut: [],
      maksuunViedytEpaonnistuneetOstolaskut: [],
      maksuunViedytOstolaskutLkm: 0,
      maksuunVietavienPaamies: {},
      maksuunVietavienPaamiehenOstotiliItems: [],
      tabs: TabsOstolasku || [],
      vieMaksuunFormData: {},
    };
  }

  mutations() {
    return {
      ...super.mutations(),
      setMaksuunVietavatOstolaskut(state, payload) {
        state.maksuunVietavatOstolaskut = payload;
      },
      setMaksuunVietavienPaamies(state, payload) {
        state.maksuunVietavienPaamies = payload;
      },
      setMaksuunVietavienPaamiehenOstotiliItems(state, payload) {
        state.maksuunVietavienPaamiehenOstotiliItems = payload;
      },
      setVieMaksuunFormData(state, payload) {
        state.vieMaksuunFormData = payload;
      },
      pushEpaonnistunutMaksuunViety(state, payload) {
        state.maksuunViedytEpaonnistuneetOstolaskut.push(payload);
      },
      kasvataMaksuunViedytOstolaskutLkm(state) {
        state.maksuunViedytOstolaskutLkm += 1;
      },
      palautaMaksuunVientiMuuttujatAlkutilaan(state) {
        state.maksuunVietavatOstolaskut = [];
        state.maksuunVietavienPaamiehenOstotiliItems = [];
        state.maksuunVietavienPaamies = {};
        state.maksuunViedytOstolaskutLkm = 0;
        state.maksuunViedytEpaonnistuneetOstolaskut = [];
        state.vieMaksuunFormData = {};
      },
    };
  }

  actions() {
    return {
      ...super.actions(),
      async hyvaksyMaksuun({ dispatch }, hyvaksyttavienIdt) {
        return dispatch("teeMassatoimintopyynto", {
          idt: hyvaksyttavienIdt,
          url: "hyvaksy_maksuun",
        });
      },
      async teeMassatoimintopyynto({ getters }, { idt, url }) {
        const request = await doRequestWithTimeout(
          getters.api,
          {
            method: "POST",
            url: `toiminnot/${url}/`,
            body: {
              ostolaskut: idt,
            },
          },
          "doSingleRequest"
        );

        if (!request.success) throw new this.$HttpError(request);

        const kasiteltujenLkm = request.result.body.ostolaskut.length;
        return kasiteltujenLkm;
      },
      async hylkaa({ dispatch }, hyvaksyttavienIdt) {
        return dispatch("teeMassatoimintopyynto", {
          idt: hyvaksyttavienIdt,
          url: "hylkaa",
        });
      },
      async palautaAvoimeksi({ dispatch }, hyvaksyttavienIdt) {
        return dispatch("teeMassatoimintopyynto", {
          idt: hyvaksyttavienIdt,
          url: "avoimeksi",
        });
      },
      async vieOstolaskutMaksuun({ state, dispatch }) {
        await Promise.all(
          state.maksuunVietavatOstolaskut.map(async (ostolasku) => {
            return await dispatch("vieMaksuun", ostolasku);
          })
        );

        return {
          onnistuneetLkm: state.maksuunViedytOstolaskutLkm,
          epaonnistuneet: state.maksuunViedytEpaonnistuneetOstolaskut,
        };
      },
      async vieMaksuun({ commit, state, getters }, ostolasku) {
        const body = {
          ...state.vieMaksuunFormData,
          summa: ostolasku.avoinna,
        };

        const request = await doRequestWithTimeout(
          getters.apiModule.getInstance("Ostolasku"),
          {
            method: "POST",
            url: "toiminnot/maksa/",
            body: body,
            params: {
              id: ostolasku.id,
            },
          },
          "pushRequest"
        );

        if (!request.success) {
          commit("pushEpaonnistunutMaksuunViety", ostolasku);
          sentryCaptureCustom(new HttpError(request));
        } else {
          commit("kasvataMaksuunViedytOstolaskutLkm");
        }
      },
      async tilitaOstolaskut({ state, getters }) {
        const body = {
          tili_id: state.vieMaksuunFormData.tili_id,
          paamies_id: state.maksuunVietavienPaamies.id,
        };

        try {
          const request = await doRequestWithTimeout(
            getters.apiModule.getInstance("OstolaskuTilita"),
            {
              method: "POST",
              url: "",
              body: body,
            },
            "doSingleRequest"
          );

          if (!request.success) throw new HttpError(request);
        } catch (e) {
          oletusVirheenkasittely(e, "Ostolaskujen tilitys epäonnistui");
        }
      },
    };
  }
}
