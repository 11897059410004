<template>
  <DefList :bordered="true">
    <DefListItem>
      <template #label>
        {{ $t("common:status") }}
      </template>
      <template v-if="value.tila && value.ikoni_ja_vari" #value>
        <StatusText
          :text="value.tila.selite"
          :color="value.ikoni_ja_vari.vari"
        />
      </template>
    </DefListItem>
    <DefListItem>
      <template #label>
        {{ $t("common:creditor") }}
      </template>
      <template v-if="value.paamies" #value>
        {{ value.paamies.nimi }} ({{ value.paamies.id }})
      </template>
    </DefListItem>
    <DefListItem v-if="value.toimeksiantovelallinen_set">
      <template #label>
        <span v-if="value.toimeksiantovelallinen_set.length > 1">
          Velalliset
        </span>
        <span v-else>{{ $t("common:debtor") }}</span>
      </template>
      <template v-if="value.toimeksiantovelallinen_set" #value>
        <span v-for="taVe in value.toimeksiantovelallinen_set" :key="taVe.id">
          <template v-if="eiOikeuttaNahdaAsiakastaValueVelallinen(value, taVe)">
            <span :key="taVe.id" class="font-italic">
              {{ $t("common:notAbleToShowRestriction") }}
            </span>
          </template>
          <template v-else>
            {{ taVe.velallinen.nimi }}
            <span v-if="taVe.vastuuprosentti">
              ({{ taVe.vastuuprosentti }} %)
            </span>
            <br />
          </template>
        </span>
      </template>
    </DefListItem>
    <DefListItem>
      <template #label>
        {{ $t("common:caseNo") }}
      </template>
      <template #value>
        {{ value.id }}
      </template>
    </DefListItem>
    <DefListItem>
      <template #label>
        {{ $t("common:invoiceNos") }}
      </template>
      <template #value>
        <InlineList class="pl-0">
          <InlineListItem
            v-for="lasku in value.saatavarivi_set"
            :key="lasku.id"
            :value="lasku.laskunumero"
            :to="
              lasku.lasku_id
                ? { name: 'laskutus-nayta', params: { id: lasku.lasku_id } }
                : null
            "
          />
        </InlineList>
      </template>
    </DefListItem>
    <DefListItem>
      <template #label>
        {{ $t("common:dueDate") }}
      </template>
      <template v-if="value.saatavarivi_set" #value>
        <template v-if="value.saatavarivi_set.length">
          {{ $dateFnsFormat(value.saatavarivi_set[0].erapaiva) }}
        </template>
      </template>
    </DefListItem>
    <DefListItem>
      <template #label>
        {{ $t("common:open") }}
      </template>
      <template #value>
        <strong>{{ value.avoinna | formatSumToFixed2 }}</strong>
      </template>
    </DefListItem>
  </DefList>
</template>

<script>
import StatusText from "@/components/StatusText";
import TrafiLupaMixin from "@/mixins/TrafiLupaMixin";

export default {
  name: "OffCanvasDetailsToimeksianto",
  components: {
    StatusText,
  },
  mixins: [TrafiLupaMixin],
  props: {
    value: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
