<template>
  <div class="tyopoyta">
    <div class="t-header sticky">
      <v-toolbar color="primary" class="px-2" flat tabs>
        <v-toolbar-title v-if="$route.meta.title">
          Tervetuloa {{ user.naytettavaNimi }}
        </v-toolbar-title>

        <v-spacer />
      </v-toolbar>
    </div>
    <div v-if="!user.paamiehet || !user.paamiehet.length" class="t-spinner">
      <v-row>
        <v-col class="d-flex justify-center align-center">
          <Spinner
            v-if="!user.paamiehet || !user.paamiehet.length"
            div-class="text-center mt-12"
            justify="center"
          />
        </v-col>
      </v-row>
    </div>
    <div
      v-else
      :class="{
        tcontent:
          laskutusKaytossa &&
          $permission.checkSinglePerm('onlinerestapi.onlinerestapi_lasku'),
        tcontent2: !(
          laskutusKaytossa &&
          $permission.checkSinglePerm('onlinerestapi.onlinerestapi_lasku')
        ),
      }"
    >
      <div id="tc-filter">
        <!-- :value="Boolean(palvelusopimusMaksamatta)" -->
        <v-alert
          v-if="Boolean(palvelusopimustrialPaattyy)"
          type="info"
          class="ma-4"
        >
          Kokeilujakso päättyy {{ $dateFnsFormat(palvelusopimustrialPaattyy) }}
        </v-alert>
        <v-alert
          v-if="Boolean(palvelusopimusMaksamatta)"
          type="error"
          class="ma-4"
        >
          Cloud-tilisi lasku on maksamatta. Tilisi suljetaan
          {{ $dateFnsFormat(palvelusopimusMaksamatta) }} ellei laskua makseta.
          Sulkemisen jälkeen et voi enää luoda uusia laskuja ja toimeksiantoja.
        </v-alert>
        <v-alert
          v-if="Boolean(palvelusopimusPaattyy)"
          type="warning"
          class="ma-4"
        >
          Cloud-tilisi tilaus on loppumassa
          {{ $dateFnsFormat(palvelusopimusPaattyy) }}. Loppumisen jälkeen et voi
          enää luoda uusia laskuja ja toimeksiantoja.
        </v-alert>
        <v-alert
          v-else-if="Boolean(palvelusopimusPaattynyt)"
          type="error"
          class="ma-4"
        >
          Cloud-tilisi tilaus on päättynyt
          {{ $dateFnsFormat(palvelusopimusPaattynyt) }}. Et voi enää luoda uusia
          laskuja ja toimeksiantoja.
        </v-alert>
        <v-row v-if="!user.vainYksiPaamies" class="pb-1">
          <v-col class="d-flex flex-column">
            <v-card
              class="paamies-card pa-6 pt-8 d-flex flex-column flex-grow-1 tyopoyta__gradient"
            >
              <CardTitle text="Päämies" :center="true" />
              <AutocompletePaamies
                v-if="!user.vainYksiPaamies"
                v-model="user.valittuPaamies"
                :outlined="true"
                :dense="true"
                :emit-empty="false"
                label=""
                @autocomplete-input="haePaamiehentiedot(user.valittuPaamies.id)"
              />
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div id="tc-btns">
        <div
          v-if="
            laskutusKaytossa &&
            $permission.checkSinglePerm('onlinerestapi.onlinerestapi_lasku') &&
            $permission.checkSinglePerm(
              'onlinerestapi.onlinerestapi_lasku_uusi'
            )
          "
          id="tc-btns-inner"
        >
          <TyopoytaPikanappain
            text="Uusi lasku"
            icon="invoice-add"
            :to="{ name: 'laskutus-uusi' }"
          />
          <TyopoytaPikanappain
            text="Uusi asiakas"
            icon="customer-person"
            :to="{
              name: 'laskutus-asiakas-selaa',
              params: {
                uusiAsiakas: true,
              },
            }"
          />
        </div>
      </div>

      <div
        v-if="
          laskutusKaytossa &&
          $permission.checkSinglePerm('onlinerestapi.onlinerestapi_lasku')
        "
        id="tc-myynti"
      >
        <TyopoytaMyynninKuvaaja :lasku-myynti-data="yleinen.laskuMyyntiData" />
      </div>

      <div class="b-combo" @load="initializeTabs">
        <v-card class="l-container">
          <v-tabs grow>
            <v-tab
              v-if="
                laskutusKaytossa &&
                $permission.checkSinglePerm('onlinerestapi.onlinerestapi_lasku')
              "
              class="primary--text"
              @click="selectedTab = 'laskutus'"
            >
              {{ $t("invoicing") }}
            </v-tab>
            <v-tab
              v-if="
                perintaKaytossa &&
                $permission.checkSinglePerm(
                  'onlinerestapi.onlinerestapi_toimeksianto'
                )
              "
              class="primary--text"
              @click="selectedTab = 'perinta'"
            >
              {{ $t("collection") }}
            </v-tab>
            <v-tab
              v-if="
                rahoitusKaytossa &&
                $permission.checkSinglePerm(
                  'onlinerestapi.onlinerestapi_rahoitus'
                )
              "
              class="primary--text"
              @click="selectedTab = 'rahoitus'"
            >
              {{ $t("financing") }}
            </v-tab>
          </v-tabs>
          <div class="tab-content">
            <div v-if="selectedTab === 'laskutus'" class="tab-laskutus">
              <v-lazy class="lazy">
                <div class="chart-container">
                  <Spinner
                    v-if="ladataanLaskutilastoja"
                    justify="center"
                    teksti="Ladataan tilastoja"
                  />
                  <div
                    v-else-if="paamiehellaEiLaskutilastoDataa"
                    class="font-no-data text-center pa-12"
                  >
                    Ei ole dataa.
                  </div>
                  <ChartPie
                    v-else
                    :chart-data="laskutilastoData"
                    :height="400"
                    :width="600"
                  />
                </div>
              </v-lazy>
            </div>
            <div v-if="selectedTab === 'perinta'" class="tab-perinta">
              <div class="chart-container">
                <Spinner
                  v-if="ladataanPerintatilastoja"
                  justify="center"
                  teksti="Ladataan tilastoja"
                />
                <div
                  v-else-if="paamiehellaEiPerintatilastoDataa"
                  class="font-no-data text-center pa-12"
                >
                  Ei ole dataa.
                </div>
                <ChartPie
                  v-else
                  :chart-data="perintatilastoData"
                  :height="400"
                  :width="600"
                />
              </div>
            </div>
            <div v-if="selectedTab === 'rahoitus'" class="tab-rahoitus">
              <v-lazy class="lazy">
                <Spinner
                  v-if="ladataanRahoitettavanYhteenvetoa"
                  justify="center"
                  teksti="Ladataan rahoituksen yhteenvetoa"
                  class="rahoitus-object"
                />
                <div
                  v-else-if="paamiehellaEiRahoitustilastoDataa"
                  class="font-no-data text-center pa-12 rahoitus-object"
                >
                  Ei ole dataa.
                </div>
                <RahoitettavanYhteenveto
                  v-else
                  :rahoitusdata="rahoitettavanYhteenveto[0].limiitti"
                />
              </v-lazy>
            </div>
          </div>
        </v-card>
      </div>
    </div>
    <!-- Näytetään versionumero piilotettuna, koska joidenkin tietoturvaihmisten mielestä sitä ei
     saisi näyttää
     -->
    <div v-if="user.isSuperuser" :id="versio"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  ChartPie,
  RahoitettavanYhteenveto,
  TyopoytaMyynninKuvaaja,
  TyopoytaPikanappain,
  AutocompletePaamies,
} from "@/components";
import { onkoModuuliKaytossa } from "@/utils/misc";
import formatSumToFixed2 from "@/utils/filters/formatSumToFixed2";

export default {
  name: "Tyopoyta",
  components: {
    ChartPie,
    RahoitettavanYhteenveto,
    TyopoytaMyynninKuvaaja,
    TyopoytaPikanappain,
    AutocompletePaamies,
  },
  data() {
    return {
      selectedTab: null,
      laskuMyyntiData: [],
      laskutilastoData: {},
      perintatilastoData: {},
      ladataanRahoitettavanYhteenvetoa: false,
      ladataanLaskutilastoja: false,
      ladataanPerintatilastoja: false,
      perinnanOnnistumisprosenttiInfo:
        "Perinnän tilassa toimeksiantojen lukumäärä jaetaan maksettuihin (perintä täysin onnistunut), avoinna oleviin (perintä vielä kesken) ja lopetettuihin toimeksiantoihin. Laskelmissa ei ole mukana toimeksiantajan perumat toimeksiannot eivätkä sellaiset toimeksiannot, jotka on lopetettu toiseen toimeksiantoon yhdistämisen johdosta.",
      accessToken: "",
      rahoitettavanYhteenveto: [],
      paamiesLogoFormData: {},
      paamiesLogoFormLoading: false,
      paamiesLogoDialogAuki: false,
      paamiesLogoOlemassa: false,
    };
  },
  computed: {
    ...mapState(["yleinen"]),
    ...mapState({
      user: (state) => state.user,
    }),
    valittuPaamies() {
      return this.user.valittuPaamies;
    },
    palvelusopimustrialPaattyy() {
      return this.user.valittuPaamies.palvelusopimustrial_paattyy;
    },
    palvelusopimusMaksamatta() {
      return this.user.valittuPaamies.palvelusopimus_maksamatta;
    },
    palvelusopimusPaattyy() {
      return this.user.valittuPaamies.palvelusopimus_paattyy;
    },
    palvelusopimusPaattynyt() {
      return this.user.valittuPaamies.palvelusopimus_paattynyt;
    },
    laskutusKaytossa() {
      return onkoModuuliKaytossa("laskutus");
    },
    paamiehellaEiLaskutilastoDataa() {
      return !(
        this.laskutilastoData &&
        this.laskutilastoData.datasets &&
        (this.laskutilastoData.datasets[0].data[0] ||
          this.laskutilastoData.datasets[0].data[1])
      );
    },
    perintaKaytossa() {
      return onkoModuuliKaytossa("perinta");
    },
    paamiehellaEiPerintatilastoDataa() {
      return !(
        this.perintatilastoData &&
        this.perintatilastoData.datasets &&
        (this.perintatilastoData.datasets[0].data[0] ||
          this.perintatilastoData.datasets[0].data[1] ||
          this.perintatilastoData.datasets[0].data[2])
      );
    },
    rahoitusKaytossa() {
      return onkoModuuliKaytossa("rahoitus");
    },
    paamiehellaEiRahoitustilastoDataa() {
      return !this.rahoitettavanYhteenveto.length;
    },
    versio() {
      return process.env.VUE_APP_PACKAGE_VERSION;
    },
  },
  mounted() {
    this.initializeTabs();
    this.haePaamiehentiedot(this.user.valittuPaamies.id);
  },

  methods: {
    async haePaamiehentiedot(paamiesId) {
      this.yleinen.laskuMyyntiData = [];
      this.$store.commit(
        "yleinen/setLaskuMyyntiData",
        this.yleinen.laskuMyyntiData
      );

      // Tehdään kaikki pyynnöt kerralla
      const p_list = [];
      if (
        this.$permission.checkSinglePerm(
          "onlinerestapi.onlinerestapi_toimeksianto"
        )
      ) {
        p_list.push(this.lataaPaamiesPerintatilasto(paamiesId));
      }

      if (
        onkoModuuliKaytossa("rahoitus") &&
        this.$permission.checkSinglePerm("onlinerestapi.onlinerestapi_rahoitus")
      ) {
        p_list.push(this.haeRahoitettavanYhteenveto(paamiesId));
      }

      if (
        this.$permission.checkSinglePerm("onlinerestapi.onlinerestapi_lasku")
      ) {
        p_list.push(this.lataaPaamiesLaskutilasto(paamiesId));
        p_list.push(this.haeLaskuMyyntiData(paamiesId));
      }

      await Promise.all(p_list);
    },
    async lataaPaamiesPerintatilasto(paamiesId = null) {
      try {
        if (!paamiesId) return;

        this.ladataanPerintatilastoja = true;

        // FIXME Pitäisi olla doRequestWithTimeout, mutta se aiheuttaa turhan virheen, kun istunto
        // vanhenee. Voidaan muuttaa, kun apicase korvataan
        const request = await this.$api.UserPaamiesPerintatilasto.doRequest({
          url: `${paamiesId}/`,
          method: "GET",
        });

        if (!request.success) throw new this.$HttpError(request);

        const { tilastot } = request.result.body;

        this.perintatilastoData = {
          labels: [
            `Maksettu: ${tilastot.maksettu.pros.toLocaleString()}% (${
              tilastot.maksettu.kpl
            } kpl)`,
            `Avoinna: ${tilastot.avoinna.pros.toLocaleString()}% (${
              tilastot.avoinna.kpl
            } kpl)`,
            `Lopetettu: ${tilastot.lopetettu.pros.toLocaleString()}% (${
              tilastot.lopetettu.kpl
            } kpl)`,
          ],
          datasets: [
            {
              backgroundColor: ["#ffdf00", "#9c89ff", "#7851a9"],
              data: [
                tilastot.maksettu.kpl,
                tilastot.avoinna.kpl,
                tilastot.lopetettu.kpl,
              ],
            },
          ],
        };
        this.ladataanPerintatilastoja = false;
      } catch (e) {
        this.$oletusVirheenkasittely(
          "Perintätilaston lataaminen ei onnistunut! Yritä hetken kuluttua uudelleen."
        );
        this.ladataanPerintatilastoja = false;
      }
    },
    async lataaPaamiesLaskutilasto(paamiesId = null) {
      try {
        if (!paamiesId) return;

        this.ladataanLaskutilastoja = true;

        // FIXME Pitäisi olla doRequestWithTimeout, mutta se aiheuttaa turhan virheen, kun istunto
        // vanhenee. Voidaan muuttaa, kun apicase korvataan
        const request = await this.$api.UserPaamiesLaskutilasto.doRequest({
          url: `${paamiesId}/`,
          method: "GET",
        });

        if (!request.success) throw new this.$HttpError(request);

        const { tilastot } = request.result.body;

        this.laskutilastoData = {
          labels: [
            `Avoinna: ${tilastot.avoinna.kpl} kpl (${formatSumToFixed2(
              tilastot.avoinna.summa
            )})`,
            `Myöhässä: ${tilastot.myohassa.kpl} kpl (${formatSumToFixed2(
              tilastot.myohassa.summa
            )})`,
          ],
          datasets: [
            {
              backgroundColor: ["#9c89ff", "#7851a9"],
              data: [tilastot.avoinna.kpl, tilastot.myohassa.kpl],
            },
          ],
        };
        this.ladataanLaskutilastoja = false;
      } catch (e) {
        this.$oletusVirheenkasittely(
          "Laskutilaston lataaminen ei onnistunut! Yritä hetken kuluttua uudelleen."
        );
        this.ladataanLaskutilastoja = false;
      }
    },
    async haeRahoitettavanYhteenveto(paamiesId = null) {
      try {
        if (!paamiesId) return;

        this.ladataanRahoitettavanYhteenvetoa = true;
        const query = new URLSearchParams();
        query.append("paamies_id", paamiesId);

        // FIXME Pitäisi olla doRequestWithTimeout, mutta se aiheuttaa turhan virheen, kun istunto
        // vanhenee. Voidaan muuttaa, kun apicase korvataan
        const request = await this.$api.WithAuthService.doRequest({
          url: "rahoitus_limiitti/",
          method: "GET",
          query: query.toString(),
        });

        if (!request.success) throw new this.$HttpError(request);

        this.rahoitettavanYhteenveto = request.result.body || [];

        this.ladataanRahoitettavanYhteenvetoa = false;
      } catch (e) {
        this.$oletusVirheenkasittely(
          "Rahoitusyhteenvedon lataaminen ei onnistunut! Yritä hetken kuluttua uudelleen."
        );
        this.ladataanRahoitettavanYhteenvetoa = false;
      }
    },
    async haeLaskuMyyntiData(paamiesId) {
      if (!paamiesId) return;
      try {
        const queryParams = new URLSearchParams();
        queryParams.append("paamies_id", paamiesId);

        // FIXME Pitäisi olla doRequestWithTimeout, mutta se aiheuttaa turhan virheen, kun istunto
        // vanhenee. Voidaan muuttaa, kun apicase korvataan
        const request = await this.$api.WithAuthService.doRequest({
          url: "lasku_myynti/",
          query: queryParams.toString(),
        });
        if (!request.success) throw new this.$HttpError(request);

        this.yleinen.laskuMyyntiData = request.result.body;
        this.$store.commit(
          "yleinen/setLaskuMyyntiData",
          this.yleinen.laskuMyyntiData
        );
      } catch (e) {
        this.$sentryCaptureCustom(e);
      }
      // Tän alapuolelle chart jutut

      this.laskuMyyntiData = {
        labels: ["Avoinna", "Myohassa"],
        datasets: [
          {
            backgroundColor: ["#9c89ff", "#7851a9"],
            data: [this.yleinen.laskuMyyntiData],
          },
        ],
      };
    },
    initializeTabs() {
      if (
        this.laskutusKaytossa &&
        this.$permission.checkSinglePerm("onlinerestapi.onlinerestapi_lasku")
      ) {
        this.selectedTab = "laskutus";
      } else if (
        this.perintaKaytossa &&
        this.$permission.checkSinglePerm(
          "onlinerestapi.onlinerestapi_toimeksianto"
        )
      ) {
        this.selectedTab = "perinta";
      } else {
        this.selectedTab = "rahoitus";
      }
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen and (min-width: 2500px) {
  .tcontent {
    grid-template-rows: auto auto 675px !important;
  }
}
.tyopoyta {
  height: 100%;
}

.tyopoyta__gradient {
  background-image: linear-gradient(
    to bottom right,
    wjote,
    var(--v-secondary-lighten3)
  );
}
.paamies-card {
  width: 60%;
  display: flex;
  justify-self: center;
  align-self: center;
}

.tcontent {
  height: calc(100vh - 64px);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto 500px;
  grid-template-areas:
    "filtering filtering"
    "btns btns"
    "combo myynti";
  row-gap: 1rem;
  column-gap: 1rem;
  //Top-Right-Bottom-Left
  padding: 1rem 1rem 1rem 1rem;
}
.tcontent2 {
  height: calc(100vh - 64px);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "filtering"
    "combo";
  row-gap: 1rem;
  column-gap: 1rem;
  //Top-Right-Bottom-Left
  padding: 1rem 1rem 1rem 1rem;
}

#tc-filter {
  grid-area: filtering;
}

#tc-btns {
  grid-area: btns;
  display: flex;
  align-items: center;
  justify-content: center;

  #tc-btns-inner {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
}

#tc-myynti {
  grid-area: myynti;
}

.b-combo {
  height: 100%;
  grid-area: combo;
}

.l-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
}

.tab-content {
  height: 100%;
}
.tab-laskutus,
.tab-perinta,
.tab-rahoitus {
  height: 100%;
  padding: 1rem;
}

.chart-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rahoitus-object {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lazy {
  height: 100%;
  width: 100%;
}
</style>
