var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-toolbar",
    { staticClass: "px-1", attrs: { color: "primary", flat: "" } },
    [
      _c(
        "v-btn",
        {
          attrs: { dark: true, icon: "" },
          on: {
            click: function ($event) {
              return _vm.$emit("close-button-click")
            },
          },
        },
        [_c("v-icon", [_vm._v("clear")])],
        1
      ),
      _c("v-toolbar-title", [_vm._v(" " + _vm._s(_vm.toolbarTitle) + " ")]),
      _c("v-spacer"),
      _vm.toName && _vm.toId && _vm.tooltipTitle
        ? _c("v-tooltip", {
            attrs: { left: "" },
            scopedSlots: _vm._u(
              [
                {
                  key: "activator",
                  fn: function ({ on }) {
                    return [
                      typeof _vm.toId != "undefined"
                        ? _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass:
                                  "white v-btn--has-bg primary--text",
                                attrs: {
                                  dark: true,
                                  icon: "",
                                  to: {
                                    name: _vm.toName,
                                    params: {
                                      id: _vm.toId,
                                    },
                                  },
                                },
                              },
                              on
                            ),
                            [_c("v-icon", [_vm._v("open_in_new")])],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "default",
                  fn: function () {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("common:openSomething")) +
                            " " +
                            _vm._s(_vm.tooltipTitle)
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3986353958
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }