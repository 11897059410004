<template>
  <span v-if="value" class="badge">
    {{ value }}
  </span>
</template>

<script>
export default {
  name: "Badge",
  props: {
    value: {
      type: [String, Number],
      required: false,
      default() {
        return null;
      },
    },
  },
  watch: {
    value(val) {
      this.$emit("input", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.badge {
  position: absolute;
  right: 0;
  top: 0;
  display: inline-block;
  padding: 0.2em 0.3em;
  color: #fff;
  line-height: 1;
  font-weight: bold;
  font-size: 0.8em;
  background: var(--v-action-base); //#c2185b previously
  color: var(--v-black-base);
  border-radius: 10em;
  transform: translateY(-55%) translateX(45%);
}
</style>
