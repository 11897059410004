import { render, staticRenderFns } from "./OffCanvasTuote.vue?vue&type=template&id=7035fb68&scoped=true"
import script from "./OffCanvasTuote.vue?vue&type=script&lang=js"
export * from "./OffCanvasTuote.vue?vue&type=script&lang=js"
import style0 from "./OffCanvasTuote.vue?vue&type=style&index=0&id=7035fb68&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7035fb68",
  null
  
)

export default component.exports