<template>
  <div class="offCanvasToimittaja">
    <v-toolbar color="primary" flat tabs>
      <v-btn :dark="true" icon @click="$emit('close-button-click')">
        <v-icon>clear</v-icon>
      </v-btn>

      <v-toolbar-title>Toimittajan tiedot</v-toolbar-title>

      <v-spacer />

      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            v-if="typeof value.id != 'undefined'"
            :dark="true"
            icon
            :to="{
              name: 'ostolasku-toimittaja-nayta',
              params: {
                id: value.id,
              },
            }"
            v-on="on"
          >
            <v-icon>open_in_new</v-icon>
          </v-btn>
        </template>
        <template #default>
          <span>Avaa toimittaja</span>
        </template>
      </v-tooltip>
    </v-toolbar>
    <v-container class="grey lighten-5 px-8">
      <OffCanvasDetailsToimittaja v-model="value" />
    </v-container>
    <v-container>
      <ToimittajaLaskutuksenYhteenveto :toimittaja="value" class="mt-4 mx-5" />
    </v-container>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import ToimittajaLaskutuksenYhteenveto from "@/components/ToimittajaLaskutuksenYhteenveto";
import OffCanvasDetailsToimittaja from "@/components/OffCanvasDetailsToimittaja";

export default {
  name: "OffCanvasToimittaja",
  directives: {
    ClickOutside,
  },
  components: {
    ToimittajaLaskutuksenYhteenveto,
    OffCanvasDetailsToimittaja,
  },
  props: {
    value: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
