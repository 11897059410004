var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "formRaportti",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "px-6 pt-6", attrs: { md: "6" } },
            [
              _c("span", { staticClass: "paper__title hidden-md-and-up" }, [
                _vm._v("Raportti"),
              ]),
              !_vm.user.vainYksiPaamies
                ? _c("v-hover", {
                    attrs: { "open-delay": "50" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ hover }) {
                            return [
                              !_vm.user.vainYksiPaamies
                                ? _c(
                                    "v-card",
                                    {
                                      staticClass:
                                        "pa-2 show-hidden-child-on-hover",
                                      class:
                                        hover &&
                                        _vm.$store.getters[
                                          "user/salliPaamiehenTyhjennys"
                                        ]
                                          ? "elevation-2 hoverBackground"
                                          : "elevation-0",
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c("AutocompletePaamies", {
                                            attrs: {
                                              "valintojen-maksimi-lkm":
                                                _vm.paamiesValintojenMaksimiLkm,
                                              label: _vm.$t("common:creditors"),
                                              multiple: true,
                                              "tarkistettava-oikeus":
                                                "onlinerestapi.onlinerestapi_raportti",
                                            },
                                            model: {
                                              value: _vm.raporttilomake.paamies,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.raporttilomake,
                                                  "paamies",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "raporttilomake.paamies",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1963455085
                    ),
                  })
                : _vm._e(),
              _vm.raporttilomake.paamies.length
                ? _c(
                    "div",
                    { staticClass: "pa-2" },
                    [
                      _c(
                        "h3",
                        {
                          staticClass: "mb-8",
                          class: !_vm.user.vainYksiPaamies ? "mt-8" : "",
                        },
                        [_vm._v(" Raportin luomisen asetukset ")]
                      ),
                      _c("v-autocomplete", {
                        staticClass: "required",
                        attrs: {
                          label: "Raportti",
                          items: _vm.valittavatRaportitItems || [],
                          "item-value": "id",
                          "item-text": "nimi",
                          "return-object": "",
                          "menu-props": { zIndex: "999" },
                          rules: [_vm.$validationRules.requiredObject],
                        },
                        model: {
                          value: _vm.raportti,
                          callback: function ($$v) {
                            _vm.raportti = $$v
                          },
                          expression: "raportti",
                        },
                      }),
                    ],
                    1
                  )
                : [
                    _c("p", { staticClass: "ma-2 font-no-data" }, [
                      _vm._v("Valitse ensin ainakin yksi päämies."),
                    ]),
                  ],
            ],
            2
          ),
          _c(
            "v-col",
            { staticClass: "px-6 pt-6", attrs: { md: "6" } },
            [
              _c("v-col", { staticClass: "pa-2 pt-0" }, [
                _c("span", { staticClass: "paper__title hidden-sm-and-down" }, [
                  _vm._v("Raportti"),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.valitunRaportinAsetuksetKomponentti
        ? _c(_vm.valitunRaportinAsetuksetKomponentti, { tag: "component" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }