var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "formToimeksianto",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pa-6", attrs: { md: "6" } },
            [
              _c("span", { staticClass: "paper__title hidden-md-and-up" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("common:collectionAssignment")) + " "
                ),
              ]),
              _c("v-hover", {
                attrs: { "open-delay": "50" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ hover }) {
                      return [
                        _c(
                          "v-card",
                          {
                            staticClass: "pa-2 show-hidden-child-on-hover",
                            class:
                              hover &&
                              _vm.$store.getters["user/salliPaamiehenTyhjennys"]
                                ? "elevation-2 hoverBackground"
                                : "elevation-0",
                          },
                          [
                            _c(
                              "div",
                              [
                                _vm.toimeksianto.paamies.id
                                  ? [
                                      _c(
                                        "v-row",
                                        { attrs: { justify: "space-between" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "py-0",
                                              attrs: { cols: "6" },
                                            },
                                            [
                                              _c("h3", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("common:mandator")
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _vm.$store.getters[
                                            "user/salliPaamiehenTyhjennys"
                                          ]
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "col-icon py-0",
                                                  attrs: { cols: "3" },
                                                },
                                                [
                                                  _c("LomakenappiIkonilla", {
                                                    attrs: {
                                                      ikoni: "clear",
                                                      infoteksti:
                                                        _vm.$t("clear"),
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.tyhjennaPaamies,
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c("OsoiteKappale", {
                                        attrs: {
                                          "renderoi-tyhja": true,
                                          "nayta-nimi": true,
                                          nimi: _vm.toimeksianto.paamies.nimi,
                                          postiosoite: _vm.toimeksianto.paamies
                                            .postiosoite
                                            ? _vm.toimeksianto.paamies
                                                .postiosoite.postiosoite
                                            : null,
                                          postinumero: _vm.toimeksianto.paamies
                                            .postiosoite
                                            ? _vm.toimeksianto.paamies
                                                .postiosoite.postinumero
                                            : null,
                                          postitoimipaikka: _vm.toimeksianto
                                            .paamies.postiosoite
                                            ? _vm.toimeksianto.paamies
                                                .postiosoite.postitoimipaikka
                                            : null,
                                        },
                                      }),
                                    ]
                                  : [
                                      _c("AutocompletePaamies", {
                                        ref: "autocompletePaamies",
                                        attrs: {
                                          label: _vm.$t("common:mandator"),
                                          "tarkista-toimeksianto-esto": true,
                                          "tarkistettava-oikeus":
                                            "onlinerestapi.onlinerestapi_toimeksianto_uusi",
                                          "required-class": false,
                                        },
                                        model: {
                                          value: _vm.toimeksianto.paamies,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.toimeksianto,
                                              "paamies",
                                              $$v
                                            )
                                          },
                                          expression: "toimeksianto.paamies",
                                        },
                                      }),
                                    ],
                              ],
                              2
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm.toimeksianto.paamies.id
                ? _c(
                    "div",
                    { staticClass: "pa-2" },
                    [
                      _c("h3", { staticClass: "my-8" }, [
                        _vm._v(" " + _vm._s(_vm.$t("common:debtorData")) + " "),
                      ]),
                      _c("v-text-field", {
                        class: _vm.vaadiTunnus ? "required" : "",
                        attrs: {
                          label: _vm.$t("common:companyIdOrSocialId"),
                          "error-messages": _vm.tunnusErrors,
                          rules: _vm.vaadiTunnus
                            ? [_vm.$validationRules.required]
                            : [],
                        },
                        on: {
                          input: function ($event) {
                            return _vm.tarkistaTunnus()
                          },
                        },
                        model: {
                          value: _vm.toimeksianto.velallinen.tunnus,
                          callback: function ($$v) {
                            _vm.$set(_vm.toimeksianto.velallinen, "tunnus", $$v)
                          },
                          expression: "toimeksianto.velallinen.tunnus",
                        },
                      }),
                      _c("FormPvm", {
                        attrs: {
                          pvm: _vm.syntymapaiva,
                          disabled: _vm.vaadiTunnus,
                          "vuosi-otsikko": _vm.$t("Syntymäpäivä / Vuosi"),
                          infoteksti: _vm.syntymapaivaInfo,
                        },
                      }),
                      _c("v-select", {
                        staticClass: "required",
                        attrs: {
                          label: _vm.$t("common:type"),
                          type: "text",
                          items: _vm.vastapuolenTyypit,
                          "item-value": "value",
                          "item-text": "selite",
                          "menu-props": { zIndex: "999" },
                          rules: [_vm.$validationRules.required],
                        },
                        on: {
                          input: function ($event) {
                            return _vm.tarkistaTunnus()
                          },
                        },
                        model: {
                          value: _vm.toimeksianto.velallinen.tyyppi,
                          callback: function ($$v) {
                            _vm.$set(_vm.toimeksianto.velallinen, "tyyppi", $$v)
                          },
                          expression: "toimeksianto.velallinen.tyyppi",
                        },
                      }),
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("common:formToimeksianto.companyForm"),
                          disabled: _vm.toimeksianto.velallinen.tyyppi === "H",
                        },
                        model: {
                          value: _vm.toimeksianto.velallinen.yhtiomuoto,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.toimeksianto.velallinen,
                              "yhtiomuoto",
                              $$v
                            )
                          },
                          expression: "toimeksianto.velallinen.yhtiomuoto",
                        },
                      }),
                      _c("v-select", {
                        staticClass: "required",
                        attrs: {
                          label: _vm.$t("common:language"),
                          type: "text",
                          items: _vm.kielet || [],
                          value: _vm.kielet ? _vm.kielet[0] : "",
                          "item-value": "value",
                          "item-text": "selite",
                          "menu-props": { zIndex: "999" },
                          rules: [_vm.$validationRules.required],
                        },
                        model: {
                          value: _vm.toimeksianto.velallinen.kieli,
                          callback: function ($$v) {
                            _vm.$set(_vm.toimeksianto.velallinen, "kieli", $$v)
                          },
                          expression: "toimeksianto.velallinen.kieli",
                        },
                      }),
                      _c("h3", { staticClass: "my-8" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("common:addressData")) + " "
                        ),
                      ]),
                      _vm.toimeksianto.velallinen.tyyppi === "H"
                        ? _c(
                            "div",
                            { staticClass: "comboField" },
                            [
                              _c("v-text-field", {
                                staticClass: "required",
                                attrs: {
                                  label: _vm.$t("common:firstName"),
                                  rules: [_vm.$validationRules.required],
                                  hint: _vm.$t(
                                    "common:formToimeksianto.giveOneOrMoreMsg"
                                  ),
                                },
                                model: {
                                  value: _vm.toimeksianto.velallinen.etunimi,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.toimeksianto.velallinen,
                                      "etunimi",
                                      $$v
                                    )
                                  },
                                  expression: "toimeksianto.velallinen.etunimi",
                                },
                              }),
                              _c("span", {
                                staticClass: "comboField__separator",
                              }),
                              _c("v-text-field", {
                                staticClass: "required",
                                attrs: {
                                  label: _vm.$t("common:lastName"),
                                  rules: [_vm.$validationRules.required],
                                },
                                model: {
                                  value: _vm.toimeksianto.velallinen.sukunimi,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.toimeksianto.velallinen,
                                      "sukunimi",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "toimeksianto.velallinen.sukunimi",
                                },
                              }),
                            ],
                            1
                          )
                        : _c("v-text-field", {
                            staticClass: "required",
                            attrs: {
                              label: _vm.$t("common:name"),
                              rules: [_vm.$validationRules.required],
                            },
                            model: {
                              value: _vm.toimeksianto.velallinen.nimi,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.toimeksianto.velallinen,
                                  "nimi",
                                  $$v
                                )
                              },
                              expression: "toimeksianto.velallinen.nimi",
                            },
                          }),
                      _c("v-text-field", {
                        attrs: { label: _vm.$t("common:recipient") },
                        model: {
                          value:
                            _vm.toimeksianto.velallinen.postiosoite
                              .vastaanottaja,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.toimeksianto.velallinen.postiosoite,
                              "vastaanottaja",
                              $$v
                            )
                          },
                          expression:
                            "toimeksianto.velallinen.postiosoite.vastaanottaja",
                        },
                      }),
                      _c("v-text-field", {
                        staticClass: "required",
                        attrs: {
                          label: _vm.$t("common:address"),
                          rules: [_vm.$validationRules.required],
                        },
                        model: {
                          value: _vm.toimeksianto.velallinen.postiosoite.osoite,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.toimeksianto.velallinen.postiosoite,
                              "osoite",
                              $$v
                            )
                          },
                          expression:
                            "toimeksianto.velallinen.postiosoite.osoite",
                        },
                      }),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "py-1", attrs: { md: "5" } },
                            [
                              _c("v-text-field", {
                                staticClass: "required",
                                attrs: {
                                  label: _vm.$t("common:zipCode"),
                                  rules: [_vm.$validationRules.required],
                                },
                                model: {
                                  value:
                                    _vm.toimeksianto.velallinen.postiosoite
                                      .numero,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.toimeksianto.velallinen.postiosoite,
                                      "numero",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "toimeksianto.velallinen.postiosoite.numero",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "py-1", attrs: { md: "7" } },
                            [
                              _c("v-text-field", {
                                staticClass: "required",
                                attrs: {
                                  label: _vm.$t("common:postalDistrict"),
                                  rules: [_vm.$validationRules.required],
                                },
                                model: {
                                  value:
                                    _vm.toimeksianto.velallinen.postiosoite
                                      .toimipaikka,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.toimeksianto.velallinen.postiosoite,
                                      "toimipaikka",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "toimeksianto.velallinen.postiosoite.toimipaikka",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-select", {
                        staticClass: "required",
                        attrs: {
                          label: _vm.$t("common:country"),
                          type: "text",
                          items: _vm.maat,
                          "item-value": "value",
                          "item-text": "selite",
                          "menu-props": { zIndex: "999", auto: true },
                          rules: [_vm.$validationRules.required],
                        },
                        model: {
                          value: _vm.toimeksianto.velallinen.postiosoite.maa,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.toimeksianto.velallinen.postiosoite,
                              "maa",
                              $$v
                            )
                          },
                          expression: "toimeksianto.velallinen.postiosoite.maa",
                        },
                      }),
                    ],
                    1
                  )
                : [
                    _c("p", { staticClass: "ma-2 font-no-data" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "common:formToimeksianto.firstSelectMandator"
                            )
                          ) +
                          ". "
                      ),
                    ]),
                  ],
            ],
            2
          ),
          _c(
            "v-col",
            { staticClass: "pa-6", attrs: { md: "6" } },
            [
              _c(
                "v-col",
                { staticClass: "pa-2 pt-0" },
                [
                  _c(
                    "span",
                    { staticClass: "paper__title hidden-sm-and-down" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("common:collectionAssignment")) +
                          " "
                      ),
                    ]
                  ),
                  _vm.toimeksianto.paamies.id
                    ? [
                        _c("div", { staticClass: "py-12" }),
                        _c("AutocompleteYTJHaku", {
                          attrs: {
                            "input-hint": _vm.$t(
                              "common:formToimeksianto.searchCorporateDebtorMsg"
                            ),
                          },
                          on: {
                            "autocomplete-input":
                              _vm.asetaYrityksenTiedotLomakkeelle,
                          },
                        }),
                        _c("h3", { staticClass: "mt-8 mb-8" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("common:contactInformation")) +
                              " "
                          ),
                        ]),
                        _c("v-text-field", {
                          attrs: { label: _vm.$t("common:emailAddress") },
                          model: {
                            value:
                              _vm.toimeksianto.velallinen.emailosoite.email,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.toimeksianto.velallinen.emailosoite,
                                "email",
                                $$v
                              )
                            },
                            expression:
                              "toimeksianto.velallinen.emailosoite.email",
                          },
                        }),
                        _c("InputPuhelinNumero", {
                          attrs: { required: false, outlined: false },
                          model: {
                            value: _vm.toimeksianto.velallinen.puhelin.numero,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.toimeksianto.velallinen.puhelin,
                                "numero",
                                $$v
                              )
                            },
                            expression:
                              "toimeksianto.velallinen.puhelin.numero",
                          },
                        }),
                        _c("h3", { staticClass: "my-8" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("common:formToimeksianto.otherInfo")
                              ) +
                              " "
                          ),
                        ]),
                        _c("v-text-field", {
                          attrs: { label: _vm.$t("common:customerNo") },
                          model: {
                            value: _vm.toimeksianto.pm_asiakasnumero,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.toimeksianto,
                                "pm_asiakasnumero",
                                $$v
                              )
                            },
                            expression: "toimeksianto.pm_asiakasnumero",
                          },
                        }),
                        _c("v-text-field", {
                          attrs: {
                            label: _vm.$t(
                              "common:formToimeksianto.receivableType"
                            ),
                          },
                          model: {
                            value: _vm.toimeksianto.saatavalaji,
                            callback: function ($$v) {
                              _vm.$set(_vm.toimeksianto, "saatavalaji", $$v)
                            },
                            expression: "toimeksianto.saatavalaji",
                          },
                        }),
                        _c("h3", { staticClass: "my-8" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("common:attachments")) + " "
                          ),
                        ]),
                        _c("v-file-input", {
                          attrs: {
                            chips: "",
                            counter: "5",
                            multiple: "",
                            "error-messages": _vm.liiteErrors,
                            rules: [
                              _vm.$validationRules.smallerThanOrEqualTo(5, 5),
                            ],
                            title: "title",
                            placeholder: _vm.$t("common:attachmentAddDots"),
                            "persistent-placeholder": "",
                            "validate-on-blur": true,
                          },
                          on: {
                            "click:clear": function ($event) {
                              _vm.liiteErrors = []
                            },
                          },
                          model: {
                            value: _vm.toimeksianto.tiedostot,
                            callback: function ($$v) {
                              _vm.$set(_vm.toimeksianto, "tiedostot", $$v)
                            },
                            expression: "toimeksianto.tiedostot",
                          },
                        }),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.toimeksianto.paamies.id
        ? _c(
            "v-row",
            { staticClass: "px-8" },
            [
              _c("v-checkbox", {
                staticClass: "mr-8",
                attrs: {
                  label: _vm.$t("common:formToimeksianto.debtorSentReminder"),
                },
                model: {
                  value: _vm.toimeksianto.muistutettu,
                  callback: function ($$v) {
                    _vm.$set(_vm.toimeksianto, "muistutettu", $$v)
                  },
                  expression: "toimeksianto.muistutettu",
                },
              }),
              _vm.toimeksianto.muistutettu
                ? _c("v-checkbox", {
                    staticClass: "mr-8",
                    attrs: {
                      label: _vm.$t(
                        "common:formToimeksianto.collectionAgencyReminder"
                      ),
                    },
                    model: {
                      value: _vm.toimeksianto.muistutetaanko,
                      callback: function ($$v) {
                        _vm.$set(_vm.toimeksianto, "muistutetaanko", $$v)
                      },
                      expression: "toimeksianto.muistutetaanko",
                    },
                  })
                : _vm._e(),
              !_vm.toimeksianto.id
                ? _c("v-checkbox", {
                    attrs: {
                      label: _vm.$t(
                        "common:formToimeksianto.assuranceClaimUndisputed"
                      ),
                      rules: [_vm.$validationRules.required],
                    },
                    model: {
                      value: _vm.riidaton,
                      callback: function ($$v) {
                        _vm.riidaton = $$v
                      },
                      expression: "riidaton",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.toimeksianto.paamies.id
        ? _c(
            "div",
            { staticClass: "pa-6" },
            [
              _c("h2", { staticClass: "mb-4" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("common:formToimeksianto.receivableRows")) +
                    " "
                ),
              ]),
              _c("SaatavariviTable", {
                ref: "saatavariviTable",
                attrs: { "cell-size": "small" },
                model: {
                  value: _vm.toimeksianto,
                  callback: function ($$v) {
                    _vm.toimeksianto = $$v
                  },
                  expression: "toimeksianto",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }