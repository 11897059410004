var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rah-container" },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "7" } },
                [
                  _c("v-simple-table", {
                    staticClass: "tyopoyta__gradient",
                    attrs: { dense: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c("tbody", [
                              _c("tr", [
                                _c("td", [_vm._v("Limiitti")]),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatSumToFixed2")(
                                          _vm.rahoitusdata.rahoitettu_limiitti
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _c(
                                "tr",
                                { class: _vm.limiitistaJaljellaRiviClass },
                                [
                                  _c("td", [_vm._v("Limiitistä jäljellä")]),
                                  _c("td", { staticClass: "text-right" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("formatSumToFixed2")(
                                            _vm.rahoitusdata
                                              .rahoitettu_limiitti_vara
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                              _c("tr", [
                                _c("td", [_vm._v("Avoinna")]),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatSumToFixed2")(
                                          _vm.rahoitusdata.rahoitettu_avoinna
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("Suoritettu yhteensä")]),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatSumToFixed2")(
                                          _vm.rahoitusdata.rahoitettu_suoritettu
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("Hyvitetty yhteensä")]),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatSumToFixed2")(
                                          _vm.rahoitusdata.rahoitettu_hyvitys
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("Rahoitettu yhteensä")]),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatSumToFixed2")(
                                          _vm.rahoitusdata.rahoitettu_lasku
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { ref: "gaugeCol", attrs: { cols: "5" } },
                [
                  !_vm.paivittaaDataa
                    ? _c("vue-gauge", {
                        attrs: {
                          refid:
                            "vue-gauge-rahoitettavan-yhteenveto" +
                            Math.floor(Math.random() * 100),
                          options: _vm.gaugeOptions,
                        },
                      })
                    : _vm._e(),
                  _c("h3", { staticClass: "mt-n4 text-center" }, [
                    _vm.prosenttiaLimiitistaKaytetty === undefined
                      ? _c("span", [_vm._v(" Ei limiittiä ")])
                      : _c("span", [
                          _vm._v(
                            " Limiitistä käytetty: " +
                              _vm._s(_vm.prosenttiaLimiitistaKaytetty) +
                              " % "
                          ),
                        ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }