var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c("v-select", {
        staticClass: "required",
        attrs: {
          label: "Lähetystapa",
          type: "text",
          items: _vm.lahetystapaSuodatettuItems,
          "item-value": "value",
          "item-text": "label",
          "menu-props": { zIndex: "999" },
        },
        model: {
          value: _vm.value.lahetystapa,
          callback: function ($$v) {
            _vm.$set(_vm.value, "lahetystapa", $$v)
          },
          expression: "value.lahetystapa",
        },
      }),
      _c("v-select", {
        staticClass: "required mt-1 pt-1",
        attrs: {
          label: "Laskupohja",
          type: "text",
          items: _vm.kirjepohjat,
          "item-text": "nimi",
          "item-value": "id",
          "no-data-text": "Ei laskupohjia",
        },
        model: {
          value: _vm.value.kirjepohja,
          callback: function ($$v) {
            _vm.$set(_vm.value, "kirjepohja", $$v)
          },
          expression: "value.kirjepohja",
        },
      }),
      _c("v-text-field", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.value.lahetystapa === "email",
            expression: "value.lahetystapa === 'email'",
          },
        ],
        class: _vm.onkoEmailKenttaPakollinen ? "required" : "",
        attrs: {
          type: "text",
          label: "Email",
          placeholder: _vm.emailPlaceholder,
          "persistent-placeholder": "",
          rules: _vm.emailValidationRules,
        },
        model: {
          value: _vm.value.email,
          callback: function ($$v) {
            _vm.$set(_vm.value, "email", $$v)
          },
          expression: "value.email",
        },
      }),
      _c("v-textarea", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.vapaatekstiSallittu,
            expression: "vapaatekstiSallittu",
          },
        ],
        attrs: { type: "text", label: "Vapaateksti", maxlength: 2047 },
        model: {
          value: _vm.value.vapaateksti,
          callback: function ($$v) {
            _vm.$set(_vm.value, "vapaateksti", $$v)
          },
          expression: "value.vapaateksti",
        },
      }),
      _c("PeruutaHyvaksyPainikerivi", {
        attrs: {
          "submit-disabled": !_vm.valid || _vm.loading,
          "submit-loading": _vm.loading,
          "submit-teksti": "Lähetä",
        },
        on: {
          close: function ($event) {
            return _vm.$emit("close")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }