<template>
  <DefList :bordered="true">
    <DefListItem v-if="value.nimi">
      <template #label>Nimi</template>
      <template #value>
        {{ value.nimi }}
      </template>
    </DefListItem>
    <DefListItem v-if="value.paamies">
      <template #label>Päämies</template>
      <template #value>
        {{ value.paamies.nimi }} ({{ value.paamies.id }})
      </template>
    </DefListItem>
    <DefListItem>
      <template #label>
        {{ $sovellusIdOtsake }}
      </template>
      <template #value>
        {{ value.id }}
      </template>
    </DefListItem>
  </DefList>
</template>

<script>
export default {
  name: "OffCanvasDetailsTuoteryhma",
  props: {
    value: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
