var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn-toggle",
    {
      attrs: {
        dense: "",
        dark: _vm.color ? true : false,
        "background-color": _vm.bgColor,
      },
      model: {
        value: _vm.tiivisNakyma,
        callback: function ($$v) {
          _vm.tiivisNakyma = $$v
        },
        expression: "tiivisNakyma",
      },
    },
    [
      _c(
        "v-btn",
        { attrs: { color: _vm.color, value: true } },
        [_c("v-icon", [_vm._v("view_headline")])],
        1
      ),
      _c(
        "v-btn",
        { attrs: { color: _vm.color, value: false } },
        [_c("v-icon", [_vm._v("view_list")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }