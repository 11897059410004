var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "formSopimus",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "paper px-6 py-12 mb-12" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pa-6", attrs: { md: "6" } },
                [
                  _c("span", { staticClass: "paper__title hidden-md-and-up" }, [
                    _vm._v("Toistuva lasku"),
                  ]),
                  _c("v-hover", {
                    attrs: { "open-delay": "50" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ hover }) {
                          return [
                            _c(
                              "v-card",
                              {
                                staticClass: "pa-2 show-hidden-child-on-hover",
                                class:
                                  hover &&
                                  !_vm.muokkaamassaSopimusta &&
                                  _vm.$store.getters[
                                    "user/salliPaamiehenTyhjennys"
                                  ]
                                    ? "elevation-2 hoverBackground"
                                    : "elevation-0",
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _vm.sopimus.paamies.id
                                      ? [
                                          _c(
                                            "v-row",
                                            {
                                              attrs: {
                                                justify: "space-between",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "py-0",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c("h3", [
                                                    _vm._v("Laskuttaja"),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "col-icon py-0",
                                                  attrs: { cols: "3" },
                                                },
                                                [
                                                  !_vm.muokkaamassaSopimusta &&
                                                  _vm.$store.getters[
                                                    "user/salliPaamiehenTyhjennys"
                                                  ]
                                                    ? [
                                                        _c(
                                                          "LomakenappiIkonilla",
                                                          {
                                                            attrs: {
                                                              ikoni: "clear",
                                                              infoteksti:
                                                                _vm.$t("clear"),
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.tyhjennaPaamies,
                                                            },
                                                          }
                                                        ),
                                                        _vm.$permission.checkSinglePerm(
                                                          "onlinerestapi.onlinerestapi_paamieshallinta"
                                                        )
                                                          ? _c(
                                                              "LomakenappiIkonilla",
                                                              {
                                                                attrs: {
                                                                  ikoni: "edit",
                                                                  infoteksti:
                                                                    _vm.$t(
                                                                      "editInvoicer"
                                                                    ),
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.$emit(
                                                                        "avaa-dialog-paamies"
                                                                      )
                                                                    },
                                                                },
                                                              }
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    : _vm._e(),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("OsoiteKappale", {
                                            attrs: {
                                              "renderoi-tyhja": true,
                                              "nayta-nimi": true,
                                              nimi: _vm.sopimus.paamies.nimi,
                                              postiosoite: _vm.sopimus.paamies
                                                .postiosoite
                                                ? _vm.sopimus.paamies
                                                    .postiosoite.postiosoite
                                                : null,
                                              postinumero: _vm.sopimus.paamies
                                                .postiosoite
                                                ? _vm.sopimus.paamies
                                                    .postiosoite.postinumero
                                                : null,
                                              postitoimipaikka: _vm.sopimus
                                                .paamies.postiosoite
                                                ? _vm.sopimus.paamies
                                                    .postiosoite
                                                    .postitoimipaikka
                                                : null,
                                            },
                                          }),
                                        ]
                                      : [
                                          _c("AutocompletePaamies", {
                                            ref: "autocompletePaamies",
                                            attrs: {
                                              paamieshallinta: true,
                                              "tarkistettava-oikeus":
                                                "onlinerestapi.onlinerestapi_sopimus",
                                            },
                                            on: {
                                              "open-dialog-paamies": function (
                                                $event
                                              ) {
                                                return _vm.$emit(
                                                  "avaa-dialog-paamies"
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.sopimus.paamies,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.sopimus,
                                                  "paamies",
                                                  $$v
                                                )
                                              },
                                              expression: "sopimus.paamies",
                                            },
                                          }),
                                        ],
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm.sopimus.paamies && _vm.sopimus.paamies.id
                    ? [
                        _c("div", {
                          class: {
                            "py-3": _vm.$vuetify.breakpoint.smAndDown,
                            "py-5": _vm.$vuetify.breakpoint.mdAndUp,
                          },
                        }),
                        _vm._l(
                          _vm.sopimus.sopimusasiakas_set,
                          function (sopimusasiakas, index) {
                            return _c("v-hover", {
                              key: index,
                              attrs: { "open-delay": "50" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ hover }) {
                                      return [
                                        _c(
                                          "v-card",
                                          {
                                            staticClass:
                                              "pa-2 show-hidden-child-on-hover",
                                            class: hover
                                              ? "elevation-2 hoverBackground"
                                              : "elevation-0",
                                          },
                                          [
                                            _c("SopimusAsiakasKappale", {
                                              attrs: {
                                                "asiakas-items-exclude":
                                                  _vm.asiakasIds,
                                                paamies: _vm.sopimus.paamies,
                                                "muokkaamassa-sopimusta":
                                                  _vm.muokkaamassaSopimusta,
                                                rooliselite:
                                                  _vm.sopimusrooli2Selite(
                                                    sopimusasiakas
                                                  ),
                                                "rooli-info":
                                                  _vm.rooliInfo(sopimusasiakas),
                                              },
                                              on: {
                                                "avaa-dialog-asiakas":
                                                  function ($event) {
                                                    return _vm.$emit(
                                                      "avaa-dialog-asiakas",
                                                      sopimusasiakas
                                                    )
                                                  },
                                                "avaa-dialog-asiakas-muistiinpanot":
                                                  function ($event) {
                                                    return _vm.$emit(
                                                      "avaa-dialog-asiakas-muistiinpanot",
                                                      sopimusasiakas
                                                    )
                                                  },
                                              },
                                              model: {
                                                value: sopimusasiakas.asiakas,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    sopimusasiakas,
                                                    "asiakas",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "sopimusasiakas.asiakas",
                                              },
                                            }),
                                            _vm.sopimus.sopimusasiakas_set
                                              .length > 1
                                              ? _c(
                                                  "v-card-actions",
                                                  [
                                                    !_vm.muokkaamassaSopimusta
                                                      ? _c(
                                                          "LomakenappiIkonilla",
                                                          {
                                                            attrs: {
                                                              ikoni: "delete",
                                                              infoteksti:
                                                                _vm.$t(
                                                                  "common:contractSave.deleteRecipient"
                                                                ),
                                                              "nayta-aina": "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.sopimus.poistaAsiakas(
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                    !_vm.muokkaamassaSopimusta &&
                                                    _vm.sopimus
                                                      .sopimusasiakas_set[index]
                                                      .rooli_id !==
                                                      _vm.rooliLaskutusasiakasId &&
                                                    _vm.sopimus
                                                      .sopimusasiakas_set[index]
                                                      .asiakas.id
                                                      ? _c(
                                                          "LomakenappiIkonilla",
                                                          {
                                                            attrs: {
                                                              ikoni:
                                                                "supervisor_account",
                                                              infoteksti:
                                                                _vm.$t(
                                                                  "common:contractSave.setInvoicingCustomer"
                                                                ),
                                                              "nayta-aina": "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.sopimus.asetaLaskutusasiakas(
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            })
                          }
                        ),
                        !_vm.muokkaamassaSopimusta &&
                        _vm.$permission.checkSinglePerm(
                          "onlinerestapi.onlinerestapi_sopimus_usea_asiakas"
                        )
                          ? _c(
                              "v-btn",
                              {
                                attrs: { color: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.sopimus.lisaaAsiakas()
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { left: "", icon: "" } },
                                  [_vm._v("library_add")]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("common:contractSave.newRecipient")
                                    ) +
                                    " "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "v-col",
                { staticClass: "pa-6", attrs: { md: "6" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-2 pt-0" },
                    [
                      _c(
                        "span",
                        { staticClass: "paper__title hidden-sm-and-down" },
                        [_vm._v(" Toistuva lasku / sopimus ")]
                      ),
                      _c("v-select", {
                        staticClass: "mt-1 pt-1 required",
                        attrs: {
                          label: "Laskupohja",
                          type: "text",
                          items: _vm.laskupohjatItems,
                          placeholder: !_vm.sopimus.paamies.id
                            ? "Valitse ensin laskuttaja"
                            : "",
                          "persistent-placeholder": "",
                          "item-text": "selite",
                          "item-value": "value",
                          "no-data-text": "Ei laskupohjia",
                          rules: [_vm.$validationRules.required],
                        },
                        model: {
                          value: _vm.sopimus.kirjepohja_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.sopimus, "kirjepohja_id", $$v)
                          },
                          expression: "sopimus.kirjepohja_id",
                        },
                      }),
                      _c("v-text-field", {
                        staticClass: "mt-1 pt-1",
                        attrs: {
                          label: "Viitteemme",
                          type: "text",
                          counter:
                            _vm.sopimus.viitteemme.length > 255
                              ? 255
                              : undefined,
                          rules: [
                            _vm.$validationRules.tooManyCharacters(
                              _vm.sopimus.viitteemme.length,
                              255
                            ),
                          ],
                        },
                        model: {
                          value: _vm.sopimus.viitteemme,
                          callback: function ($$v) {
                            _vm.$set(_vm.sopimus, "viitteemme", $$v)
                          },
                          expression: "sopimus.viitteemme",
                        },
                      }),
                      _c("v-text-field", {
                        staticClass: "mt-1 pt-1",
                        attrs: {
                          label: "Viitteenne",
                          type: "text",
                          counter:
                            _vm.sopimus.viitteenne.length > 255
                              ? 255
                              : undefined,
                          rules: [
                            _vm.$validationRules.tooManyCharacters(
                              _vm.sopimus.viitteenne.length,
                              255
                            ),
                          ],
                        },
                        model: {
                          value: _vm.sopimus.viitteenne,
                          callback: function ($$v) {
                            _vm.$set(_vm.sopimus, "viitteenne", $$v)
                          },
                          expression: "sopimus.viitteenne",
                        },
                      }),
                      _c("v-textarea", {
                        staticClass: "mt-1 pt-1",
                        attrs: {
                          label: "Laskulla näkyvä teksti",
                          type: "text",
                          rows: "2",
                          counter:
                            _vm.sopimus.laskunteksti.length > 4098
                              ? 4098
                              : undefined,
                          rules: [
                            _vm.$validationRules.tooManyCharacters(
                              _vm.sopimus.laskunteksti.length,
                              4098
                            ),
                          ],
                        },
                        model: {
                          value: _vm.sopimus.laskunteksti,
                          callback: function ($$v) {
                            _vm.$set(_vm.sopimus, "laskunteksti", $$v)
                          },
                          expression: "sopimus.laskunteksti",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.odotusaikaYlitetty &&
                  _vm.sopimus.paamies.id &&
                  !_vm.sopimus.kirjepohja_id
                    ? _c(
                        "v-card",
                        { staticClass: "pa-4", attrs: { color: "warning" } },
                        [
                          _c("span", { staticClass: "text-subtitle-1" }, [
                            _vm._v(
                              " Huom.! Toistuvalle laskulle ei ole määritetty laskupohjaa. Sitä ei voida tallentaa. "
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", [
                _c(
                  "div",
                  { staticClass: "pa-6" },
                  [
                    _c("h2", { staticClass: "mb-4" }, [_vm._v("Laskurivit")]),
                    !_vm.piilotaLaskuriviTable
                      ? _c(
                          "v-form",
                          { attrs: { disabled: _vm.muokkaamassaSopimusta } },
                          [
                            _c("LaskuriviTable", {
                              ref: "laskuriviTable",
                              attrs: {
                                tyyppi: "sopimus",
                                "cell-size": "small",
                                "salli-poistaminen": !_vm.muokkaamassaSopimusta,
                                "nayta-uusi-rivi-btn":
                                  !_vm.muokkaamassaSopimusta,
                                tuoterekisteri: !_vm.muokkaamassaSopimusta,
                                disabled: _vm.muokkaamassaSopimusta,
                              },
                              on: {
                                kayttajasyote: function ($event) {
                                  return _vm.$emit(
                                    "laskurivitableKayttajasyote"
                                  )
                                },
                              },
                              model: {
                                value: _vm.sopimus,
                                callback: function ($$v) {
                                  _vm.sopimus = $$v
                                },
                                expression: "sopimus",
                              },
                            }),
                            !_vm.muokkaamassaSopimusta
                              ? _c(
                                  "v-row",
                                  { staticClass: "mt-8" },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "8" } },
                                      [
                                        _c("AutocompleteLaskurivi", {
                                          attrs: {
                                            lasku: _vm.sopimus,
                                            tyyppi: "sopimus",
                                          },
                                          on: {
                                            "autocomplete-input":
                                              _vm.kasitteleAutocompleteLaskuriviInput,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "v-card",
                                  {
                                    staticClass:
                                      "mt-8 elevation-2 pa-4 bg-color-secondary-lighten2",
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "font-weight-bold text-subtitle-1 mb-0",
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-2",
                                            attrs: { color: "white" },
                                          },
                                          [_vm._v("info")]
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            " Voit muokata laskurivejä ohjaustietojen muutosten tallentamisen jälkeen. "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                          ],
                          1
                        )
                      : _c("p", { staticClass: "font-no-data" }, [
                          _vm._v("Ei laskurivejä"),
                        ]),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "paper px-6 py-6 mb-12" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pa-6", attrs: { md: "6" } },
                [
                  _c("h2", { staticClass: "text-h5 mb-8" }, [
                    _vm._v("Laskujen lähetyksen määrittely"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "comboField" },
                    [
                      _c("v-menu", {
                        attrs: {
                          "close-on-content-click": false,
                          "max-width": "290",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-text-field",
                                  _vm._g(
                                    {
                                      staticClass: "required",
                                      attrs: {
                                        label: "Alkamispäivä",
                                        value: _vm.$dateFnsFormat(
                                          _vm.sopimus.alkamispaiva
                                        ),
                                        readonly: "",
                                        rules: [_vm.$validationRules.required],
                                        disabled: _vm.muokkaamassaSopimusta,
                                      },
                                    },
                                    on
                                  )
                                ),
                              ]
                            },
                          },
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _c("v-date-picker", {
                                  ref: "alkamispaivaDatePicker",
                                  attrs: {
                                    locale: "fi-FI",
                                    "first-day-of-week": "1",
                                    "no-title": "",
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.alkamispaivaMenu = false
                                    },
                                  },
                                  model: {
                                    value: _vm.sopimus.alkamispaiva,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.sopimus, "alkamispaiva", $$v)
                                    },
                                    expression: "sopimus.alkamispaiva",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.alkamispaivaMenu,
                          callback: function ($$v) {
                            _vm.alkamispaivaMenu = $$v
                          },
                          expression: "alkamispaivaMenu",
                        },
                      }),
                      _c("TooltipInfo", {
                        attrs: {
                          "icon-class": "align-self-start ml-2",
                          "icon-color": "primary",
                          text: _vm.$t(
                            "common:infotekstit.sopimusInfo.startingDate"
                          ),
                        },
                      }),
                      _c("span", { staticClass: "comboField__separator" }, [
                        _vm._v("–"),
                      ]),
                      _c("v-menu", {
                        attrs: {
                          "close-on-content-click": false,
                          "max-width": "290",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-text-field",
                                  _vm._g(
                                    {
                                      attrs: {
                                        label: "Päättymispäivä",
                                        value: _vm.$dateFnsFormat(
                                          _vm.sopimus.loppumispaiva
                                        ),
                                        readonly: "",
                                        rules: [_vm.validoiPaattymispaiva],
                                        placeholder: "Voimassa toistaiseksi",
                                        "persistent-placeholder": "",
                                        clearable: "",
                                      },
                                      on: {
                                        "click:clear": function ($event) {
                                          _vm.sopimus.loppumispaiva = null
                                        },
                                      },
                                    },
                                    on
                                  )
                                ),
                              ]
                            },
                          },
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _c("v-date-picker", {
                                  ref: "loppumispaivaDatePicker",
                                  attrs: {
                                    locale: "fi-FI",
                                    "first-day-of-week": "1",
                                    "no-title": "",
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.paattymispaivaMenu = false
                                    },
                                  },
                                  model: {
                                    value: _vm.sopimus.loppumispaiva,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.sopimus,
                                        "loppumispaiva",
                                        $$v
                                      )
                                    },
                                    expression: "sopimus.loppumispaiva",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.paattymispaivaMenu,
                          callback: function ($$v) {
                            _vm.paattymispaivaMenu = $$v
                          },
                          expression: "paattymispaivaMenu",
                        },
                      }),
                      _c("TooltipInfo", {
                        attrs: {
                          "icon-class": "align-self-start ml-2",
                          "icon-color": "primary",
                          text: _vm.$t(
                            "common:infotekstit.sopimusInfo.endingDate"
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-checkbox", {
                    staticClass: "mt-1 pt-1",
                    attrs: {
                      disabled: _vm.muokkaamassaSopimusta,
                      label: "Aloita laskutus kuukautta aiemmin",
                    },
                    model: {
                      value: _vm.sopimus.kuukautta_aikaisemmin,
                      callback: function ($$v) {
                        _vm.$set(_vm.sopimus, "kuukautta_aikaisemmin", $$v)
                      },
                      expression: "sopimus.kuukautta_aikaisemmin",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "comboField" },
                    [
                      _c("v-select", {
                        staticClass: "mt-1 pt-1",
                        attrs: {
                          label: "Laskutusjakso",
                          type: "text",
                          items: _vm.laskutusjaksoItems,
                          "item-value": "value",
                          "item-text": "selite",
                        },
                        model: {
                          value: _vm.sopimus.laskutusjakso,
                          callback: function ($$v) {
                            _vm.$set(_vm.sopimus, "laskutusjakso", $$v)
                          },
                          expression: "sopimus.laskutusjakso",
                        },
                      }),
                      _c("TooltipInfo", {
                        attrs: {
                          "icon-class": "align-self-start ml-2",
                          "icon-color": "primary",
                          text: _vm.$t(
                            "common:infotekstit.sopimusInfo.invoicePeriod"
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "comboField" },
                    [
                      _c("v-select", {
                        staticClass: "mt-1 pt-1",
                        attrs: {
                          label: "Lähetyspäivän peruste",
                          type: "text",
                          items: _vm.lahetyspaivanPerusteItems,
                          "item-value": "value",
                          "item-text": "selite",
                        },
                        model: {
                          value: _vm.sopimus.lahetyspaivanPeruste,
                          callback: function ($$v) {
                            _vm.$set(_vm.sopimus, "lahetyspaivanPeruste", $$v)
                          },
                          expression: "sopimus.lahetyspaivanPeruste",
                        },
                      }),
                      _c("TooltipInfo", {
                        attrs: {
                          "icon-class": "align-self-start ml-2",
                          "icon-color": "primary",
                          text: _vm.$t(
                            "common:infotekstit.sopimusInfo.sendDateBase"
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "comboField" },
                    [
                      _c("v-menu", {
                        attrs: {
                          "close-on-content-click": false,
                          disabled: _vm.laskutusjaksoOnKuukaudenVP,
                          "max-width": "290",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-text-field",
                                  _vm._g(
                                    {
                                      staticClass: "mt-1 pt-1",
                                      class: _vm.laskutuspaivaClassArr,
                                      attrs: {
                                        label:
                                          _vm.sopimus.lahetyspaivanPeruste ===
                                          "laskutuspaiva"
                                            ? "Laskutuspäivä"
                                            : "Eräpäivä",
                                        value: _vm.laskutusjaksoOnKuukaudenVP
                                          ? " "
                                          : _vm.sopimus.paivanumero,
                                        disabled:
                                          _vm.laskutusjaksoOnKuukaudenVP,
                                        readonly: "",
                                        rules: [_vm.$validationRules.required],
                                        prefix: _vm.paivanValintaPrefix,
                                        placeholder: "X",
                                        "persistent-placeholder": "",
                                        suffix: _vm.laskutusjaksoOnKuukaudenVP
                                          ? ""
                                          : ". päivä",
                                      },
                                    },
                                    on
                                  )
                                ),
                              ]
                            },
                          },
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _c("v-date-picker", {
                                  ref: "paivanValintaPaivamaaraDatePicker",
                                  staticClass: "mt-1 pt-1 paivan-valitsin",
                                  attrs: {
                                    locale: "fi-FI",
                                    "first-day-of-week": "1",
                                    "no-title": "",
                                    "allowed-dates": _vm.paivanValintaItems,
                                    "show-current": false,
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.paivanumeroMenu = false
                                    },
                                  },
                                  model: {
                                    value: _vm.paivanValintaPaivamaara,
                                    callback: function ($$v) {
                                      _vm.paivanValintaPaivamaara = $$v
                                    },
                                    expression: "paivanValintaPaivamaara",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.paivanumeroMenu,
                          callback: function ($$v) {
                            _vm.paivanumeroMenu = $$v
                          },
                          expression: "paivanumeroMenu",
                        },
                      }),
                      _c("TooltipInfo", {
                        attrs: {
                          "icon-class": "align-self-start ml-2",
                          "icon-color": "primary",
                          text:
                            _vm.sopimus.lahetyspaivanPeruste === "laskutuspaiva"
                              ? _vm.$t(
                                  "common:infotekstit.sopimusInfo.invoiceDate"
                                )
                              : _vm.$t(
                                  "common:infotekstit.sopimusInfo.dueDate"
                                ),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "comboField" },
                    [
                      _c("v-text-field", {
                        staticClass: "mt-1 pt-1",
                        class: _vm.maksuehtoClassArr,
                        attrs: {
                          type: "number",
                          label: "Maksuehto",
                          rules: [_vm.$validationRules.biggerThanZero],
                          suffix: "pv netto",
                          "full-width": false,
                        },
                        model: {
                          value: _vm.sopimus.maksuehto,
                          callback: function ($$v) {
                            _vm.$set(_vm.sopimus, "maksuehto", $$v)
                          },
                          expression: "sopimus.maksuehto",
                        },
                      }),
                      _c("TooltipInfo", {
                        attrs: {
                          "icon-class": "align-self-start ml-2",
                          "icon-color": "primary",
                          text: _vm.$t(
                            "common:infotekstit.sopimusInfo.paymentTerm"
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "pa-6 laskujen-lahetys__oikea-sarake",
                  attrs: { md: "6" },
                },
                [
                  !_vm.sopimus.laskutettu
                    ? _c(
                        "v-card",
                        {
                          staticClass:
                            "mt-4 elevation-2 pa-4 bg-color-secondary-lighten2",
                          class: [_vm.tehdyillaValinnoillaClassArr],
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-weight-bold text-subtitle-1 mb-0",
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-2",
                                  attrs: { color: "white" },
                                },
                                [_vm._v("info")]
                              ),
                              _vm.tehdyillaValinnoillaInfoteksti
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.tehdyillaValinnoillaInfoteksti
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      " Aseta " +
                                        _vm._s(
                                          _vm.sopimus.lahetyspaivanPeruste ===
                                            "laskutuspaiva"
                                            ? "laskutuspäivä"
                                            : "eräpäivä"
                                        ) +
                                        " laskeaksesi ensimmäisen laskun lähetyspäivä "
                                    ),
                                  ]),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.naytaLaskutustietojaMuokattuTeksti
                    ? _c(
                        "v-card",
                        {
                          staticClass:
                            "mt-4 elevation-2 pa-4 bg-color-secondary-lighten2",
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-weight-bold text-subtitle-1 mb-0",
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-2",
                                  attrs: { color: "white" },
                                },
                                [_vm._v("info")]
                              ),
                              _c("span", [
                                _vm._v(
                                  " Huom.! Tarkista ja tarvittaessa päivitä määrittelytietojen muutosten tallennuksen jälkeen laskutusjaksot ja -aiheet. "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.muokkaamassaSopimusta
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "comboField laskujen-lahetys__oikea-sarake__seuraava-erapaiva",
                        },
                        [
                          _c("v-menu", {
                            attrs: {
                              "close-on-content-click": false,
                              "max-width": "290",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          {
                                            staticClass: "mt-1 pt-1",
                                            class: _vm.laskutuspaivaClassArr,
                                            attrs: {
                                              value: _vm.$dateFnsFormat(
                                                _vm.sopimus
                                                  .seuraava_laskutuspaiva
                                              ),
                                              label:
                                                "Seuraava " +
                                                (_vm.sopimus
                                                  .lahetyspaivanPeruste ===
                                                "laskutuspaiva"
                                                  ? "laskutuspäivä"
                                                  : "eräpäivä"),
                                              readonly: "",
                                              rules: [
                                                _vm.$validationRules.required,
                                              ],
                                            },
                                          },
                                          on
                                        )
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "default",
                                  fn: function () {
                                    return [
                                      _c("v-date-picker", {
                                        ref: "seuraavaLaskutuspaivaDatePicker",
                                        staticClass: "mt-1 pt-1",
                                        attrs: {
                                          locale: "fi-FI",
                                          "first-day-of-week": "1",
                                          "no-title": "",
                                        },
                                        on: {
                                          change: function ($event) {
                                            _vm.seuraavaLaskutuspaivaMenu = false
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.sopimus.seuraava_laskutuspaiva,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.sopimus,
                                              "seuraava_laskutuspaiva",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "sopimus.seuraava_laskutuspaiva",
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              865550452
                            ),
                            model: {
                              value: _vm.seuraavaLaskutuspaivaMenu,
                              callback: function ($$v) {
                                _vm.seuraavaLaskutuspaivaMenu = $$v
                              },
                              expression: "seuraavaLaskutuspaivaMenu",
                            },
                          }),
                          _c("TooltipInfo", {
                            attrs: {
                              "icon-class": "align-self-start ml-2",
                              "icon-color": "primary",
                              text: _vm.$t(
                                "common:infotekstit.sopimusInfo.nextInvoiceDate"
                              ),
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "paper px-6 py-6 mb-12" },
        [
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "pa-6", attrs: { md: "6" } }, [
                _c("h2", { staticClass: "text-h5 mb-8" }, [
                  _vm._v("Sopimuksen lisätiedot"),
                ]),
                _c(
                  "div",
                  { staticClass: "comboField" },
                  [
                    _c("v-select", {
                      staticClass: "mt-1 pt-1",
                      attrs: {
                        label: "Laskun lähetys",
                        type: "text",
                        items: _vm.laskunLahetysItems,
                        "item-value": "value",
                        "item-text": "selite",
                      },
                      model: {
                        value: _vm.sopimus.laskunlahetys,
                        callback: function ($$v) {
                          _vm.$set(_vm.sopimus, "laskunlahetys", $$v)
                        },
                        expression: "sopimus.laskunlahetys",
                      },
                    }),
                    _c("TooltipInfo", {
                      attrs: {
                        "icon-class": "align-self-start ml-2",
                        "icon-color": "primary",
                        text: _vm.$t(
                          "common:infotekstit.sopimusInfo.invoiceSending"
                        ),
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "comboField" },
                  [
                    _vm.laskutusasiakas.asiakas.tyyppi === "Y"
                      ? _c("v-text-field", {
                          staticClass: "mt-1 pt-1",
                          attrs: {
                            type: "text",
                            label: "Viivästyskorko",
                            suffix: "%",
                            rules: [
                              _vm.$validationRules.maxDecimalPlacesOrEmpty(
                                _vm.sopimus.viivastyskorko,
                                2
                              ),
                              _vm.$validationRules.numberOrEmptyWithDecimal,
                              _vm.$validationRules.zeroOrBiggerWithDecimal,
                              _vm.$validationRules.smallerThanOrEqualToWithDecimal(
                                _vm.sopimus.viivastyskorko,
                                100
                              ),
                            ],
                            placeholder: "Korkolain mukainen",
                            "persistent-placeholder": "",
                            "validate-on-blur": true,
                          },
                          model: {
                            value: _vm.sopimus.viivastyskorko,
                            callback: function ($$v) {
                              _vm.$set(_vm.sopimus, "viivastyskorko", $$v)
                            },
                            expression: "sopimus.viivastyskorko",
                          },
                        })
                      : _c("v-select", {
                          staticClass: "mt-1 pt-1",
                          attrs: {
                            label: "Viivästyskorko",
                            items: _vm.viivastyskorkoItems,
                          },
                          model: {
                            value: _vm.sopimus.viivastyskorko,
                            callback: function ($$v) {
                              _vm.$set(_vm.sopimus, "viivastyskorko", $$v)
                            },
                            expression: "sopimus.viivastyskorko",
                          },
                        }),
                    _c("TooltipInfo", {
                      attrs: {
                        "icon-class": "align-self-start ml-2",
                        "icon-color": "primary",
                        text: _vm.$t(
                          "common:infotekstit.sopimusInfo.latePaymentInterest"
                        ),
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "comboField" },
                  [
                    _c("v-text-field", {
                      staticClass: "mt-1 pt-1",
                      attrs: { label: "Sopimuslaji", type: "text" },
                      model: {
                        value: _vm.sopimus.laji,
                        callback: function ($$v) {
                          _vm.$set(_vm.sopimus, "laji", $$v)
                        },
                        expression: "sopimus.laji",
                      },
                    }),
                    _c("TooltipInfo", {
                      attrs: {
                        "icon-class": "align-self-start ml-2",
                        "icon-color": "primary",
                        text: _vm.$t("common:infotekstit.sopimusInfo.genre"),
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("v-col", { staticClass: "pa-6", attrs: { md: "6" } }, [
                _c("h2", { staticClass: "text-h5 mb-8 visibility-hidden" }, [
                  _vm._v("Sopimuksen lisätiedot"),
                ]),
                _c(
                  "div",
                  { staticClass: "comboField" },
                  [
                    _c("v-text-field", {
                      staticClass: "mt-1 pt-1",
                      attrs: {
                        label: "Kassa-alennusprosentti",
                        suffix: "%",
                        type: "number",
                        rules: [
                          _vm.$validationRules.zeroOrBigger,
                          _vm.$validationRules.smallerThanOrEqualTo(
                            _vm.sopimus.kassaalennus_prosentti,
                            100
                          ),
                          _vm.$validationRules.maxDecimalPlaces(
                            _vm.sopimus.kassaalennus_prosentti,
                            2
                          ),
                        ],
                      },
                      model: {
                        value: _vm.sopimus.kassaalennus_prosentti,
                        callback: function ($$v) {
                          _vm.$set(_vm.sopimus, "kassaalennus_prosentti", $$v)
                        },
                        expression: "sopimus.kassaalennus_prosentti",
                      },
                    }),
                    _c("TooltipInfo", {
                      attrs: {
                        "icon-class": "align-self-start ml-2",
                        "icon-color": "primary",
                        text: _vm.$t(
                          "common:infotekstit.sopimusInfo.cashDiscountPercent"
                        ),
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "comboField" },
                  [
                    _c("v-text-field", {
                      staticClass: "mt-1 pt-1",
                      attrs: {
                        label: "Kassa-alennuksen maksuaika",
                        type: "number",
                        rules: [_vm.$validationRules.zeroOrBigger],
                      },
                      model: {
                        value: _vm.sopimus.kassaalennus_maksuaika,
                        callback: function ($$v) {
                          _vm.$set(_vm.sopimus, "kassaalennus_maksuaika", $$v)
                        },
                        expression: "sopimus.kassaalennus_maksuaika",
                      },
                    }),
                    _c("TooltipInfo", {
                      attrs: {
                        "icon-class": "align-self-start ml-2",
                        "icon-color": "primary",
                        text: _vm.$t(
                          "common:infotekstit.sopimusInfo.cashDiscountPaymentPeriod"
                        ),
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }