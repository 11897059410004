import BaseOptimizedCountSelaa from "@/store/modules/baseOptimizedCountSelaa";

export default class RaportitSelaa extends BaseOptimizedCountSelaa {
  state() {
    return {
      ...super.state(),
      apiName: "Raportit",
      searchTerms: { paamies: [] },
    };
  }

  mutations() {
    return {
      ...super.mutations(),
      resetViewViewKohtainen(state) {
        state.searchTerms = { paamies: [] };
      },
    };
  }
}
