import { render, staticRenderFns } from "./FormSopimusPaamiesSiirra.vue?vue&type=template&id=2a35008e&scoped=true"
import script from "./FormSopimusPaamiesSiirra.vue?vue&type=script&lang=js"
export * from "./FormSopimusPaamiesSiirra.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a35008e",
  null
  
)

export default component.exports