var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-tooltip", {
    attrs: { "content-class": "body-1 pa-4", "max-width": "400", bottom: "" },
    scopedSlots: _vm._u([
      {
        key: "activator",
        fn: function ({ on }) {
          return [
            _c(
              "v-icon",
              _vm._g(
                {
                  staticClass: "tooltip-info__icon-hover",
                  class: _vm.iconClass,
                  attrs: { color: _vm.iconColor },
                },
                on
              ),
              [_vm._v(" info ")]
            ),
          ]
        },
      },
      {
        key: "default",
        fn: function () {
          return [_c("span", [_vm._v(_vm._s(_vm.text))])]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }