/*
https://stackoverflow.com/questions/48327261/way-to-make-inheritance-in-vuex-modules/56790942#56790942
*/

import api from "@/plugins/api";
import {
  HttpError,
  SallittuKokoYlitettyError,
  ValidointiError,
} from "@/utils/errors";
import {
  doRequestWithTimeout,
  paatteleTiedostonMimetype,
  suodataTyhjanMerkkijononSisaltavatKentat,
} from "@/utils/misc";
import BaseModule from "@/store/modules/baseModule";
import { MaksimiUploadTiedostoKoko } from "@/utils/constants";
import { Lasku } from "@/class/Lasku";

export default class LaskutusNayta extends BaseModule {
  state() {
    return {
      ...super.state(),
      lasku: {
        aikajana: [],
        avoinna: null,
        esta_perinta_tarkistukset: [],
        ikoni_ja_vari: {
          ikoni: null,
          vari: null,
        },
        kirje_set: [],
        laskuliite_set: [],
        onko_erapaivan_siirto_mahdollinen: false,
        paamies: {
          nimi: "",
        },
        postiosoite: null,
        tapahtuma_set_laskukirjauksilla: [],
        tila: {},
      },
      erapaivanSiirtoFormData: {},
      hyvitaFormData: {},
      lahetaLaskukopioFormData: {},
      lahetaLaskukopioLaskupohjat: [],
      liitteetFormData: {},
      mitatoiFormData: {},
      ohisuoritusFormData: {},
      perinnanEstoFormData: {},
      poistettavaTiedosto: {},
      uusiMuistiinpanoFormData: {},
      uusiViestiFormData: {},
    };
  }

  mutations() {
    return {
      setLasku(state, payload) {
        state.lasku = payload;
      },
      setErapaivanSiirtoFormData(state, payload) {
        state.erapaivanSiirtoFormData = payload;
      },
      setHyvitaFormData(state, payload) {
        state.hyvitaFormData = payload;
      },
      setLahetaLaskukopioFormData(state, payload) {
        state.lahetaLaskukopioFormData = payload;
      },
      setLahetaLaskukopioLaskupohjat(state, payload) {
        state.lahetaLaskukopioLaskupohjat = payload;
      },
      setLiitteetFormData(state, payload) {
        state.liitteetFormData = payload;
      },
      setMitatoiFormData(state, payload) {
        state.mitatoiFormData = payload;
      },
      setOhisuoritusFormData(state, payload) {
        state.ohisuoritusFormData = payload;
      },
      setPerinnanEstoFormData(state, payload) {
        state.perinnanEstoFormData = payload;
      },
      setPoistettavaTiedosto(state, payload) {
        state.poistettavaTiedosto = payload;
      },
      setUusiMuistiinpanoFormData(state, payload) {
        state.uusiMuistiinpanoFormData = payload;
      },
      setUusiViestiFormData(state, payload) {
        state.uusiViestiFormData = payload;
      },
    };
  }

  actions() {
    return {
      async clearDetails({ commit }) {
        commit("setLasku", {});
        commit("setErapaivanSiirtoFormData", {});
        commit("setHyvitaFormData", {});
        commit("setLahetaLaskukopioFormData", {});
        commit("setLahetaLaskukopioLaskupohjat", {});
        commit("setLiitteetFormData", {});
        commit("setMitatoiFormData", {});
        commit("setOhisuoritusFormData", {});
        commit("setPerinnanEstoFormData", {});
        commit("setPoistettavaTiedosto", {});
        commit("setUusiMuistiinpanoFormData", {});
        commit("setUusiViestiFormData", {});
      },
      async loadItem({ commit }, sroute) {
        const request = await doRequestWithTimeout(
          api.Lasku,
          {
            method: "GET",
            url: "",
            params: {
              id: sroute.params.id,
            },
          },
          "pushRequest"
        );
        if (!request.success) throw new HttpError(request);

        commit("setLasku", request.result.body);
      },
      alustaLaskukopioFormData({ commit, state }) {
        const lahetaLaskukopioFormData = {
          email: "",
          lahetystapa: null,
          vapaateksti: "",
        };

        if (state.lasku.asiakas.lahetystapa) {
          lahetaLaskukopioFormData.lahetystapa =
            state.lasku.asiakas.lahetystapa;
        } else {
          lahetaLaskukopioFormData.lahetystapa = Lasku.LAHETYSTAPA_POSTI;
        }
        commit("setLahetaLaskukopioFormData", lahetaLaskukopioFormData);
      },
      async haeLaskukopionLahetyksenLaskupohjat({ commit }, sroute) {
        const request = await doRequestWithTimeout(
          api.Lasku,
          {
            method: "GET",
            params: {
              id: sroute.params.id,
            },
            url: "toiminnot/hae_laskukopio_laskupohjat/",
          },
          "pushRequest"
        );
        if (!request.success) throw new HttpError(request);
        commit("setLahetaLaskukopioLaskupohjat", request.result.body);
      },
      async lahetaLaskukopioSubmit({ commit, state }) {
        const request = await doRequestWithTimeout(
          api.Lasku,
          {
            method: "POST",
            url: "toiminnot/laheta_laskukopio/",
            body: state.lahetaLaskukopioFormData,
            params: {
              id: state.lasku.id,
            },
          },
          "doSingleRequest"
        );
        if (!request.success) throw new HttpError(request);
        commit("setLasku", request.result.body);
      },
      async liitteetSubmit({ commit, state }) {
        const formData = new FormData();

        let liitteetLkm = 0;
        for (var tiedosto of state.liitteetFormData.tiedostot) {
          const mimeType = await paatteleTiedostonMimetype(tiedosto);
          if (
            mimeType !== "tuntematon" &&
            ![
              "image/jpeg",
              "image/gif",
              "image/png",
              "application/pdf",
            ].includes(mimeType)
          ) {
            throw new ValidointiError(
              "Tiedoston tulee olla tyyppiä jpeg, gif, png tai pdf"
            );
          }

          if (tiedosto.size > MaksimiUploadTiedostoKoko)
            throw new SallittuKokoYlitettyError(
              SallittuKokoYlitettyError.SALLITTU_KOKO_YLITETTY_VIESTI,
              tiedosto.name
            );
          liitteetLkm++;
          formData.append(tiedosto.name, tiedosto);
        }

        const request = await doRequestWithTimeout(
          api.Lasku,
          {
            method: "POST",
            url: "toiminnot/tuo_liite/",
            body: formData,
            params: {
              id: state.lasku.id,
            },
          },
          "doSingleRequest"
        );

        if (!request.success) throw new HttpError(request);

        commit("setLasku", request.result.body);
        return liitteetLkm;
      },
      async aineistolataajaSubmit({ state }, aineistoLomake) {
        const formData = new FormData();
        formData.set("attachment_file", aineistoLomake.csv);
        const aineistolataaja_id = aineistoLomake.aineistolataaja.id
          ? aineistoLomake.aineistolataaja.id
          : aineistoLomake.aineistolataaja;
        const paamies_id = aineistoLomake.paamies.id
          ? aineistoLomake.paamies.id
          : aineistoLomake.paamies;
        const request = await doRequestWithTimeout(
          api.AineistolataajaTuo,
          {
            method: "POST",
            body: formData,
            params: {
              aineistolataaja_id: aineistolataaja_id,
              paamies_id: paamies_id,
            },
          },
          "doSingleRequest"
        );
        if (!request.success) throw new HttpError(request);
        return request.result.body;
      },
      async ohisuoritusSubmit({ commit, state }) {
        const requestBody = {
          maksupaiva: state.ohisuoritusFormData.maksupaiva,
          summa: state.ohisuoritusFormData.summa,
        };

        const request = await doRequestWithTimeout(
          api.Lasku,
          {
            method: "POST",
            url: "toiminnot/ohisuoritus/",
            body: requestBody,
            params: {
              id: state.lasku.id,
            },
          },
          "doSingleRequest"
        );

        if (!request.success) throw new HttpError(request);

        commit("setLasku", request.result.body);
      },

      async viestiSubmit({ commit, state }) {
        const requestBody = {
          sisalto: state.uusiViestiFormData.message,
        };

        const request = await doRequestWithTimeout(
          api.Lasku,
          {
            method: "POST",
            url: "toiminnot/viesti/",
            body: requestBody,
            params: {
              id: state.lasku.id,
            },
          },
          "doSingleRequest"
        );

        if (!request.success) throw new HttpError(request);

        commit("setLasku", request.result.body);
        commit("setUusiViestiFormData", {});
      },

      async muistiinpanoSubmit({ commit, state }) {
        const requestBody = {
          sisalto: state.uusiMuistiinpanoFormData.message,
        };

        const request = await doRequestWithTimeout(
          api.Lasku,
          {
            method: "POST",
            url: "toiminnot/muistiinpano/",
            body: requestBody,
            params: {
              id: state.lasku.id,
            },
          },
          "doSingleRequest"
        );

        if (!request.success) throw new HttpError(request);

        commit("setLasku", request.result.body);
        commit("setUusiMuistiinpanoFormData", {});
      },

      siirryLaskunMuokkaukseen({ state }, router) {
        router.push({
          name: "laskutus-muokkaa",
          params: {
            muokattavaLasku: state.lasku,
          },
        });
      },

      async hyvita({ dispatch, commit, state }) {
        if (state.hyvitaFormData && state.hyvitaFormData.summa) {
          state.hyvitaFormData.summa = state.hyvitaFormData.summa.replace(
            ",",
            "."
          );
        }
        commit(
          "setHyvitaFormData",
          suodataTyhjanMerkkijononSisaltavatKentat(state.hyvitaFormData)
        );
        const request = await doRequestWithTimeout(
          api.Lasku,
          {
            method: "POST",
            url: "toiminnot/hyvita/",
            body: state.hyvitaFormData,
            params: {
              id: state.lasku.id,
            },
          },
          "doSingleRequest"
        );

        if (!request.success) throw new HttpError(request);

        commit("setLasku", request.result.body);
        dispatch("alustaHyvitaFormData");
      },

      alustaHyvitaFormData({ commit, state }) {
        const hyvitaFormData = {
          hyvityslasku: state.lasku.postiosoite !== null,
        };
        commit("setHyvitaFormData", hyvitaFormData);
      },

      async alustaMitatoiFormData({ commit }) {
        const mitatoiFormData = {
          poista_sopimukselta: false,
        };
        commit("setMitatoiFormData", mitatoiFormData);
      },

      async mitatoi({ dispatch, commit, state }) {
        commit(
          "setHyvitaFormData",
          suodataTyhjanMerkkijononSisaltavatKentat(state.mitatoiFormData)
        );

        const request = await doRequestWithTimeout(
          api.Lasku,
          {
            method: "POST",
            url: "toiminnot/mitatoi/",
            body: state.mitatoiFormData,
            params: {
              id: state.lasku.id,
            },
          },
          "doSingleRequest"
        );

        if (!request.success) throw new HttpError(request);

        commit("setLasku", request.result.body);
        dispatch("alustaMitatoiFormData");
      },

      async perinnanEstoSubmit({ commit, state }) {
        commit(
          "setPerinnanEstoFormData",
          suodataTyhjanMerkkijononSisaltavatKentat(state.perinnanEstoFormData)
        );

        const request = await doRequestWithTimeout(
          api.Lasku,
          {
            method: "POST",
            url: "toiminnot/esta_perinta/",
            body: state.perinnanEstoFormData,
            params: {
              id: state.lasku.id,
            },
          },
          "doSingleRequest"
        );

        if (!request.success) throw new HttpError(request);

        commit("setLasku", request.result.body);
      },

      async salliPerinta({ commit, state }) {
        const request = await doRequestWithTimeout(
          api.Lasku,
          {
            method: "POST",
            url: "toiminnot/salli_perinta/",
            params: {
              id: state.lasku.id,
            },
          },
          "doSingleRequest"
        );

        if (!request.success) throw new HttpError(request);

        commit("setLasku", request.result.body);
      },

      async erapaivanSiirtoSubmit({ commit, state }) {
        const request = await doRequestWithTimeout(
          api.Lasku,
          {
            method: "POST",
            url: "toiminnot/erapaivan_siirto/",
            body: state.erapaivanSiirtoFormData,
            params: {
              id: state.lasku.id,
            },
          },
          "doSingleRequest"
        );

        if (!request.success) throw new HttpError(request);

        commit("setLasku", request.result.body);
      },

      async luoToimeksianto({ commit, state }) {
        const request = await doRequestWithTimeout(
          api.Lasku,
          {
            method: "POST",
            url: "toiminnot/luo_toimeksianto/",
            params: {
              id: state.lasku.id,
            },
          },
          "doSingleRequest"
        );

        if (!request.success) throw new HttpError(request);

        commit("setLasku", request.result.body);
      },

      alustaDialogOhisuoritus({ commit, state }) {
        // Prepare form
        const ohisuoritusFormData = {
          maksupaiva: new Date().toISOString().substr(0, 10),
          summa: state.lasku.avoinna,
        };
        commit("setOhisuoritusFormData", ohisuoritusFormData);
      },
    };
  }
}
