import Vue from "vue";
import Toast, { POSITION } from "vue-toastification";

import "vue-toastification/dist/index.css";
import "@/assets/scss/vueToastificationCustomStyles.scss";

const options = {
  timeout: 4000,
  position: POSITION.TOP_CENTER,
  transition: "Vue-Toastification__fade",
  hideProgressBar: true,
};

Vue.use(Toast, options);
