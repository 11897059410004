<template>
  <v-card ref="wrap" class="card-dialog">
    <div v-if="title || showCloseButton" class="card-dialog__header">
      <v-row align="center" :class="titleClass">
        <v-col class="px-4">
          <h2 v-if="title" class="text-h5 modal-drag kasi">
            {{ title }}
            <TooltipInfo
              v-if="tooltipInfoTeksti"
              :text="tooltipInfoTeksti"
              icon-color="primary"
            />
          </h2>
        </v-col>
        <v-col class="px-4 shrink">
          <v-btn
            v-if="showCloseButton"
            icon
            class="ma-0 nuoli"
            @click="$emit('close')"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div
      v-if="$slots['default']"
      ref="content"
      class="card-dialog__content modal-content"
      :class="contentClass"
    >
      <slot />
    </div>
    <div v-if="$slots['footer']" class="card-dialog__footer pa-4">
      <div :class="footerClass">
        <slot name="footer" />
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "CardDialogModal",
  props: {
    dialogAuki: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    width: {
      type: String,
      required: false,
      default() {
        return "500";
      },
    },
    title: {
      type: String,
      required: false,
      default() {
        return "";
      },
    },
    showCloseButton: {
      type: Boolean,
      required: false,
      default() {
        return true;
      },
    },
    scrollToBottom: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    contentClass: {
      type: String,
      required: false,
      default() {
        return "px-4 pb-4";
      },
    },
    titleClass: {
      type: String,
      required: false,
      default() {
        return "pa-4 mx-0";
      },
    },
    footerClass: {
      type: String,
      required: false,
      default() {
        return "pa-4";
      },
    },
    tooltipInfoTeksti: {
      type: String,
      required: false,
      default() {
        return "";
      },
    },
  },
  watch: {
    dialogAuki: {
      handler(arvo) {
        if (arvo) {
          this.$nextTick().then(() => {
            this.$refs["wrap"].$el.parentElement.scrollTop = 0;
          });
        }
      },
    },
  },
  updated() {
    // Vieritä näkymä alas, kun dialogissa on tehty jokin korkeutta kasvattava toiminto,
    // kuten esim. viestin lähettäminen
    this.$nextTick().then(() => {
      if (this.scrollToBottom && this.$refs["content"] && this.$refs["wrap"]) {
        this.$refs["wrap"].$el.parentElement.scrollTop =
          this.$refs["content"].clientHeight;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.kasi:hover {
  cursor: pointer;
}
.nuoli:hover {
  cursor: default;
}
.card-dialog {
  overflow: auto;

  &__wrap {
    position: relative;
  }

  &__header,
  &__footer {
    position: sticky;
    z-index: 5;
    background: #fff;
    overflow-x: hidden;
  }

  &__header {
    top: 0;
  }

  &__footer {
    bottom: 0;
  }
}
</style>
