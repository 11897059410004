<template>
  <v-snackbar
    v-if="maintenanceMessage"
    v-model="snackbar"
    class="maintenance"
    :timeout="-1"
    absolute
    top
    color="grey"
    elevation="24"
  >
    <div>{{ maintenanceMessage }}</div>
    <template #action="{ attrs }">
      <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "MaintenanceMessage",
  data() {
    return {
      snackbar: true,
    };
  },
  computed: {
    maintenanceMessage() {
      return process.env.VUE_APP_MAINTENANCE_MESSAGE;
    },
  },
};
</script>

<style lang="scss" scoped>
.maintenance {
  z-index: 9999;
}
</style>
