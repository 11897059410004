<template>
  <v-row :class="rowClass" :justify="rowJustify">
    <v-btn :large="large" @click="$emit('close')">
      {{ peruutaTeksti }}
    </v-btn>
    <v-btn
      class="ml-4"
      type="submit"
      color="action"
      :disabled="submitDisabled"
      :large="large"
      :loading="submitLoading"
    >
      {{ submitTeksti }}
    </v-btn>
  </v-row>
</template>

<script>
export default {
  name: "PeruutaHyvaksyPainikerivi",
  props: {
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
    rowClass: {
      type: String,
      required: false,
      default() {
        return "mt-4 px-3";
      },
    },
    rowJustify: {
      type: String,
      required: false,
      default() {
        return "end";
      },
    },
    peruutaTeksti: {
      type: String,
      required: false,
      default() {
        return this.$t("common:cancel");
      },
    },
    prop: {
      type: Boolean,
      required: false,
      default: false,
    },
    submitTeksti: {
      type: String,
      required: false,
      default() {
        return this.$t("common:accept");
      },
    },
    submitDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    submitLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style></style>
