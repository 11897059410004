<template>
  <span v-if="text" :class="classObj">
    {{ text }}
  </span>
</template>

<script>
export default {
  name: "StatusText",
  props: {
    text: {
      type: String,
      required: false,
      default() {
        return "";
      },
    },
    size: {
      type: String,
      required: false,
      default() {
        return "small";
      },
    },
    color: {
      type: String,
      required: false,
      default() {
        return "black";
      },
    },
  },
  computed: {
    classObj() {
      return [
        "statusText",
        this.size ? "statusText--" + this.size : "",
        this.color ? this.color : "",
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.statusText {
  display: inline-block;
  padding: 0.25em 1em;
  border-radius: 4px;
  font-weight: bold;
  color: #fff;
}
</style>
