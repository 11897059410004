var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "mr-6 shrink" },
            [
              _c("StatusIcon", {
                staticClass: "status-icon--rounded",
                attrs: {
                  type: "timeline",
                  size: "small",
                  icon: _vm.icon,
                  "bg-color": "green",
                  pointer: false,
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "grow" },
            [
              _c("v-textarea", {
                ref: "messageInput",
                staticClass: "pt-0 mt-0",
                attrs: {
                  rows: "1",
                  label: "",
                  rules: [_vm.$validationRules.required],
                  "hide-details": true,
                  required: "",
                  "auto-grow": "",
                  color: "action",
                  placeholder: _vm.msgInputPlaceholder,
                  "persistent-placeholder": "",
                  "validate-on-blur": false,
                },
                model: {
                  value: _vm.value.message,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "message", $$v)
                  },
                  expression: "value.message",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "shrink" },
            [
              _c("v-tooltip", {
                attrs: { top: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ on }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticClass: "ma-0 ml-4 success--text",
                              attrs: {
                                type: "submit",
                                icon: "",
                                disabled: !_vm.valid || _vm.loading,
                                loading: _vm.loading,
                              },
                            },
                            on
                          ),
                          [_c("v-icon", [_vm._v("send")])],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _vm._v(" " + _vm._s(_vm.submitBtnTooltipText) + " "),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.painikkeet
        ? _c("PeruutaHyvaksyPainikerivi", {
            attrs: {
              "submit-disabled": !_vm.valid || _vm.loading,
              "submit-loading": _vm.loading,
              "peruuta-teksti": _vm.peruutaTeksti,
              "submit-teksti": _vm.submitTeksti,
            },
            on: {
              close: function ($event) {
                return _vm.$emit("close")
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }