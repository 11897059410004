var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "formTuoteCSV",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "px-4" },
            [
              !_vm.$store.state.user.vainYksiPaamies
                ? _c("AutocompletePaamies", {
                    ref: "autocompletePaamies",
                    attrs: {
                      "validointi-saannot": [
                        _vm.$validationRules.requiredObject,
                      ],
                      "tarkistettava-oikeus":
                        "onlinerestapi.onlinerestapi_tuoterekisteri",
                    },
                    on: { "autocomplete-input": _vm.paivitaTuoteryhmat },
                    model: {
                      value: _vm.csvLomake.paamies,
                      callback: function ($$v) {
                        _vm.$set(_vm.csvLomake, "paamies", $$v)
                      },
                      expression: "csvLomake.paamies",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "px-4" },
            [
              _c("v-file-input", {
                staticClass: "required",
                attrs: {
                  accept: ".csv",
                  label: _vm.$t("common:fileHandling.csvFile"),
                  rules: [_vm.$validationRules.required],
                },
                model: {
                  value: _vm.csvLomake.csv,
                  callback: function ($$v) {
                    _vm.$set(_vm.csvLomake, "csv", $$v)
                  },
                  expression: "csvLomake.csv",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-4 py-3 px-4", attrs: { justify: "end" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "mr-4",
              attrs: { large: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("common:cancel")) + " ")]
          ),
          _c(
            "v-btn",
            {
              attrs: {
                large: "",
                color: "action",
                disabled: !_vm.valid || _vm.loading,
              },
              on: { click: _vm.luoTuotteetTiedostosta },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("common:fileHandling.bringFromFile")) + " "
              ),
            ]
          ),
        ],
        1
      ),
      _c("v-divider", { staticClass: "my-8 mx-1" }),
      _c(
        "v-row",
        { staticClass: "mt-4 py-3 px-4", attrs: { justify: "end" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "mr-4",
              attrs: { small: "", color: "primary" },
              on: {
                click: function ($event) {
                  _vm.naytaVaihtoehdot = !_vm.naytaVaihtoehdot
                },
              },
            },
            [
              _vm._v(" " + _vm._s(_vm.vaihtoehdotTeksti) + " "),
              _c("v-icon", { attrs: { right: "", small: "" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.naytaVaihtoehdot
                        ? "keyboard_arrow_up"
                        : "keyboard_arrow_down"
                    ) +
                    " "
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: {
                href: "tuote-esimerkki.csv",
                download: "",
                small: "",
                color: "primary",
              },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("common:fileHandling.loadSampleCsv")) + " "
              ),
              _c("v-icon", { attrs: { right: "", small: "" } }, [
                _vm._v("download"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.naytaVaihtoehdot
        ? _c(
            "div",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "px-4" },
                    [
                      _c(
                        "v-card",
                        { staticClass: "mb-4 pa-4", attrs: { color: "info" } },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-2", attrs: { dark: "" } },
                            [_vm._v("info")]
                          ),
                          _c(
                            "span",
                            { staticClass: "text-subtitle-2 white--text" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "common:fileHandling.browseTipOfLoadingData"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "px-4" },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          label: "tuoteryhma",
                          items: _vm.tuoteryhmat,
                          "item-text": "nimi",
                          dense: "",
                          "error-messages": _vm.paamiehellaEiTuoteryhmia
                            ? _vm.$t(
                                "common:fileHandling.creditorHasNoProductGroups"
                              )
                            : "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "px-4" },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          label: "alvtunnus",
                          items: _vm.ALVTunnukset,
                          "item-text": "tunnus",
                          dense: "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }