var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "login" },
    [
      _c("div", { staticClass: "login__top" }, [
        _c("div", { staticClass: "login__wrap" }, [
          _c(
            "div",
            { staticClass: "login__col login__col--left pr-6" },
            [
              _c("Logo", {
                staticClass: "mx-0",
                attrs: { "logo-key": _vm.logoKey, "enable-link": false },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "login__col login__col--right pa-6" },
            [
              _c(
                "v-card",
                { staticClass: "pa-10 text-center" },
                [
                  _c("p", { staticClass: "text-subtitle-1" }, [
                    _vm._v("Uudelleenohjataan..."),
                  ]),
                  _c("Spinner", { attrs: { loading: _vm.loading } }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("BusinessFooter"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }