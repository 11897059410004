<template>
  <v-form v-model="valid" @submit.prevent="submit">
    <v-text-field
      ref="emailField"
      v-model="kayttaja.email"
      :label="$t('email')"
      class="required"
      :rules="[$validationRules.required, $validationRules.email]"
      :error-messages="emailErrors"
      outlined
      required
    />
    <v-text-field
      ref="tunnusField"
      v-model="tunnus"
      :label="$t('companyId')"
      class="required"
      :rules="[$validationRules.required, $validationRules.onkoYtunnus]"
      :error-messages="tunnusErrors"
      outlined
      @blur="tarkistaTunnuksenOikeellisuus()"
    />
    <v-text-field
      v-model="salasana"
      :label="$t('password')"
      class="required"
      :append-icon="naytaSalasana ? 'visibility_off' : 'visibility'"
      :type="naytaSalasana ? 'text' : 'password'"
      :rules="[$validationRules.required]"
      outlined
      required
      :error-messages="salasanaErrors"
      @click:append="naytaSalasana = !naytaSalasana"
    />
    <v-text-field
      v-model="salasanaUudelleen"
      :label="$t('serviceApplication.repeatPassword')"
      class="required"
      :append-icon="naytaSalasana ? 'visibility_off' : 'visibility'"
      :type="naytaSalasana ? 'text' : 'password'"
      :rules="[$validationRules.required]"
      outlined
      required
      :error-messages="salasanaUudelleenErrors"
      @click:append="naytaSalasana = !naytaSalasana"
    />
    <v-checkbox
      v-model="palveluehdotHyvaksytty"
      dense
      :rules="[$validationRules.required]"
    >
      <template #label>
        <span>
          {{ $t("serviceApplication.IAccept") }}&nbsp;
          <a
            class="font-weight-bold"
            href="https://www.uuva.fi/uuva-cloud/yleiset-sopimusehdot/"
            target="_blank"
            @click.stop
          >
            {{ $t("serviceApplication.termsofService") }}
          </a>
          *
        </span>
      </template>
    </v-checkbox>
    <v-checkbox
      v-model="tietosuojaselosteHyvaksytty"
      dense
      :rules="[$validationRules.required]"
    >
      <template #label>
        <span>
          {{ $t("serviceApplication.IAccept") }}&nbsp;
          <a
            class="font-weight-bold"
            href="https://www.uuva.fi/uuva-cloud-tietosuojaseloste"
            target="_blank"
            @click.stop
          >
            {{ $t("serviceApplication.thePrivacyPolicy") }}
          </a>
          *
        </span>
      </template>
    </v-checkbox>
    <v-checkbox
      v-model="markkinointiViestitHyvaksytty"
      dense
      :label="$t('serviceApplication.sendMeMarketingEmail')"
    ></v-checkbox>
    <v-row class="mt-4 pa-3" justify="center">
      <v-btn
        block
        class="mx-4"
        large
        color="action"
        :disabled="!valid"
        @click="nextPage()"
      >
        Seuraava
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";
import { mapState } from "vuex";

export default {
  name: "FormPalveluhakemusPerustiedot",
  components: {},
  mixins: [FormMixin],
  data() {
    return {
      debounceTimeout: "",
      naytaSalasana: false,
      palveluehdotAuki: false,
      salasanaErrors: [],
      salasanaUudelleenErrors: [],
      salasanaRegex: /^(?=.*[A-Za-z])(?=.*[@$!%*#?&_,.-]).{12,}$/,
      tunnusErrors: [],
      emailErrors: [],
    };
  },
  computed: {
    ...mapState({
      kayttaja: (state) => state.palveluhakemus.kayttaja,
      paamies: (state) => state.palveluhakemus.paamies,
    }),
    tunnus: {
      get() {
        return this.$store.state.palveluhakemus.paamies.tunnus;
      },
      set(val) {
        this.$store.commit("palveluhakemus/setPaamiesTunnus", val);
      },
    },
    salasana: {
      get() {
        return this.$store.state.palveluhakemus.kayttaja.salasana;
      },
      set(val) {
        this.$store.commit("palveluhakemus/setKayttajaSalasana", val);
      },
    },
    salasanaUudelleen: {
      get() {
        return this.$store.state.palveluhakemus.kayttaja.salasanaUudelleen;
      },
      set(val) {
        this.$store.commit("palveluhakemus/setKayttajaSalasanaUudelleen", val);
      },
    },
    palveluehdotHyvaksytty: {
      get() {
        return this.$store.state.palveluhakemus.kayttaja.palveluehdotHyvaksytty;
      },
      set(val) {
        this.$store.commit(
          "palveluhakemus/setKayttajaPalveluehdotHyvaksytty",
          val
        );
      },
    },
    tietosuojaselosteHyvaksytty: {
      get() {
        return this.$store.state.palveluhakemus.kayttaja
          .tietosuojaselosteHyvaksytty;
      },
      set(val) {
        this.$store.commit(
          "palveluhakemus/setKayttajaTietosuojaselosteHyvaksytty",
          val
        );
      },
    },
    markkinointiViestitHyvaksytty: {
      get() {
        return this.$store.state.palveluhakemus.kayttaja
          .markkinointiViestitHyvaksytty;
      },
      set(val) {
        this.$store.commit(
          "palveluhakemus/setKayttajaMarkkinointiViestitHyvaksytty",
          val
        );
      },
    },
  },
  watch: {
    "kayttaja.email": {
      handler() {
        this.emailErrors = [];
      },
    },
    "paamies.tunnus": {
      handler(val) {
        this.tunnusErrors = [];
        if (!val) return;

        this.debounceTimeout = setTimeout(async () => {
          if (val.length < 9) return;

          await this.tarkistaTunnuksenOikeellisuus(val);
          if (this.tunnusErrors.length) return;

          try {
            this.$store.dispatch("palveluhakemus/haeYTJsta", val);
          } catch (e) {
            this.$sentryCaptureCustom(e);
          }
        }, 300);
      },
    },
    "kayttaja.salasana": {
      handler(val) {
        if (
          !this.kayttaja.salasana ||
          !this.kayttaja.salasana.match(this.salasanaRegex)
        ) {
          this.salasanaErrors = [
            this.$t("serviceApplication.passwordRequirements"),
          ];
        } else {
          this.salasanaErrors = [];
        }

        if (
          this.kayttaja.salasanaUudelleen &&
          this.kayttaja.salasanaUudelleen !== val
        ) {
          this.salasanaUudelleenErrors = [
            this.$t("serviceApplication.passwordsDontMatch"),
          ];
        }
      },
    },
    "kayttaja.salasanaUudelleen": {
      handler(val) {
        if (val && val !== this.kayttaja.salasana) {
          this.salasanaUudelleenErrors = [
            this.$t("serviceApplication.passwordsDontMatch"),
          ];
        } else {
          this.salasanaUudelleenErrors = [];
        }
      },
    },
  },
  methods: {
    async nextPage() {
      const emailTaken = await this.checkIsEmailInUse();
      if (!emailTaken && this.valid) {
        this.$emit("seuraava");
      }
    },

    async tarkistaTunnuksenOikeellisuus() {
      if (!this.$refs["tunnusField"].valid) {
        return;
      }
      try {
        const request = await this.$store.dispatch(
          "palveluhakemus/tarkistaYTunnus"
        );
        if (!request.success && this.paamies.tunnus)
          throw new this.$HttpError(request);
      } catch (e) {
        this.tunnusErrors = [this.$t("checkId")];
        this.$sentryCaptureCustom(e);
      }
    },
    async checkIsEmailInUse() {
      if (!this.$refs["emailField"].valid) {
        return false;
      }
      try {
        const queryParams = new URLSearchParams();
        queryParams.append("email", this.kayttaja.email);
        const request = await this.$store.dispatch(
          "palveluhakemus/onVapaaSahkopostiosoite",
          queryParams
        );

        if (request.result.status === 500 && this.kayttaja.email) {
          throw new this.$HttpError(request);
        }
        if (request.result.status === 400) {
          // Annetulla sähköpostiosoitteella on jo tunnus palveluun. Näytetään epäselvä viesti, jotta ei suoraan kerrota sähköpostilla olevan jo tunnus.
          this.$naytaVaroitusilmoitus(
            "Olemme lähettäneet sinulle sähköpostia. Tarkista sähköpostisi jatkaaksesi tunnusten luomista.",
            { timeout: 10000 }
          );
          this.emailErrors = [
            "Tarkista sähköpostisi jatkaaksesi tunnusten luomista",
          ];
        }
      } catch (e) {
        this.emailFieldError = this.emailErrors = [this.$t("checkEmail")];
        this.$oletusVirheenkasittely(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
