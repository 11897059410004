<template>
  <v-form v-model="valid" @submit.prevent="submit">
    <h3 class="my-4">Yhteystiedot</h3>
    <v-text-field
      v-model="nimi"
      label="Yrityksen nimi"
      :rules="[$validationRules.required]"
      class="required"
      outlined
      required
    />
    <v-text-field
      v-model="tunnus"
      label="Y-tunnus"
      class="required"
      :rules="[$validationRules.required, $validationRules.onkoYtunnus]"
      outlined
      :error-messages="tunnusErrors"
      @blur="tarkistaTunnuksenOikeellisuus(tunnus)"
    />
    <!-- <v-text-field
      v-model="paamies.saaja"
      label="Saaja / Tarkenne"
    /> -->
    <v-text-field
      v-model="osoite"
      label="Lähisoite"
      :rules="[$validationRules.required]"
      class="required"
      outlined
    />
    <v-row>
      <v-col md="5" class="py-1">
        <v-text-field
          v-model="postinumero"
          label="Postinumero"
          :rules="[$validationRules.required]"
          class="required"
          outlined
        />
      </v-col>
      <v-col md="7" class="py-1">
        <v-text-field
          v-model="toimipaikka"
          label="Postitoimipaikka"
          :rules="[$validationRules.required]"
          class="required"
          outlined
        />
      </v-col>
    </v-row>
    <InputPuhelinNumero
      v-model:puh="puhelin"
      :required="false"
      :outlined="true"
    />
    <v-text-field
      v-model="email"
      append-icon=""
      label="Sähköpostiosoite"
      type="text"
      class="required"
      :rules="[$validationRules.required, $validationRules.email]"
      outlined
    />
    <v-select
      v-model="maa"
      label="Maa"
      type="text"
      :items="maat"
      item-value="value"
      item-text="selite"
      :menu-props="{ zIndex: '999', auto: true }"
      :rules="[$validationRules.required]"
      class="required"
      outlined
    />
    <v-text-field v-model="www" label="WWW-osoite" outlined />
    <h3 class="my-4">Laskutustiedot</h3>
    <p class="text-subtitle-1 my-4">
      Valitse sinulle mieluisin laskutustapa. Voit myöhemmin vaihtaa nämä
      Asetuksista.
    </p>
    <v-select
      v-model="lahetystapa"
      class="required"
      label="Laskutustapa"
      outlined
      :items="lahetystavat"
      item-value="value"
      item-text="label"
      :rules="[$validationRules.required]"
    />
    <template v-if="lahetystapa === 'verkkolasku'">
      <v-text-field
        label="Verkkolaskuosoite"
        :placeholder="verkkolaskuosoitePlaceholder"
        persistent-placeholder
        disabled
        outlined
      />
      <v-autocomplete
        v-model="verkkolaskuoperaattori"
        class="py-1 required"
        clearable
        :items="verkkolaskuoperaattorit"
        :filter="verkkolaskuoperaattoriFilter"
        item-text="autocomplete_selite"
        item-value="id"
        label="Verkkolaskuoperaattori"
        outlined
        :rules="[$validationRules.required]"
      ></v-autocomplete>
    </template>
    <v-text-field
      v-else-if="lahetystapa === 'email'"
      v-model="laskutus_email"
      label="Laskutuksen sähköpostiosoite"
      type="text"
      :placeholder="email"
      persistent-placeholder
      persistent-hint
      hint="Tyhjäksi jätettäessä käytetään yhteystietojen sähköpostia"
      :rules="[$validationRules.email]"
      outlined
    />
    <h3 class="my-4">Pankkitili</h3>
    <v-text-field
      v-model="iban"
      label="Tilinumero (IBAN)"
      outlined
      class="required"
      :rules="[$validationRules.required, $validationRules.isValidIBAN]"
      @change="selvitaIBANistaBIC"
    />
    <v-select
      v-model="bic"
      label="Pankki (BIC)"
      outlined
      :items="bicItems"
      item-value="value"
      item-text="label"
      :hint="paamies.bic ? paamies.bic : ''"
      :rules="[$validationRules.required]"
      class="required"
    />
    <v-row class="mt-4 pa-3" justify="center">
      <v-btn
        block
        class="mx-4"
        large
        color="action"
        :disabled="!valid"
        @click="$emit('seuraava')"
      >
        Seuraava
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";
import { mapState } from "vuex";
import { PaamiesPalveluhakemus } from "@/class/Paamies";
import InputPuhelinNumero from "@/components/InputPuhelinNumero";

export default {
  name: "FormPalveluhakemusYritystiedot",
  components: {
    InputPuhelinNumero,
  },
  mixins: [FormMixin],
  data() {
    return {
      naytaSalasana: false,
      lahetystavat: PaamiesPalveluhakemus.LAHETYSTAVAT,
      bicItems: [
        { value: "HELSFIHH", label: "Aktia | HELSFIHH" },
        { value: "BIGKFIH1", label: "Bigbank | BIGKFIH1" },
        { value: "CITIFIHX", label: "Citibank | CITIFIHX" },
        { value: "DABAFIHH", label: "Danske Bank | DABAFIHH" },
        { value: "DNBAFIHX", label: "DnB NOR Bank | DABAFIHX" },
        { value: "HANDFIHH", label: "Handelsbanken | HANDFIHH" },
        { value: "HOLVFIHH", label: "Holvi | HOLVFIHH" },
        { value: "NDEAFIHH", label: "Nordea | NDEAFIHH" },
        { value: "OKOYFIHH", label: "Osuuspankki | OKOYFIHH" },
        { value: "POPFFI22", label: "POP Pankki | POPFFI22" },
        { value: "SBANFIHH", label: "S-Pankki | SBANFIHH" },
        { value: "ESSEFIHX", label: "SEB | ESSEFIHX" },
        { value: "SWEDFIHH", label: "Swedbank | SWEDFIHH" },
        { value: "ITELFIHH", label: "Säästöpankki | ITELFIHH" },
        { value: "AABAFI22", label: "Ålandsbanken | AABAFI22" },
        { value: "EVSEFIHH", label: "Fellow Pankki | EVSEFIHH" },
        { value: "NARYFIH2", label: "Narvi Payments | NARYFIH2" },
        { value: "VPAYFIH2", label: "Viva Payment Services | NARYFIH2" },
        { value: "REVOLT21", label: "Revolut Bank | REVOLT21" },
      ],
      debounceTimeout: null,
      tunnusErrors: [],
    };
  },
  computed: {
    ...mapState({
      kayttaja: (state) => state.palveluhakemus.kayttaja,
      paamies: (state) => state.palveluhakemus.paamies,
      valittuPalvelusopimus: (state) =>
        state.palveluhakemus.paamies.palvelusopimus,
    }),
    maat() {
      return this.$store.state.palveluhakemus.maat;
    },
    nimi: {
      get() {
        return this.$store.state.palveluhakemus.paamies.nimi;
      },
      set(val) {
        this.$store.commit("palveluhakemus/setPaamiesNimi", val);
      },
    },
    tunnus: {
      get() {
        return this.$store.state.palveluhakemus.paamies.tunnus;
      },
      set(val) {
        this.$store.commit("palveluhakemus/setPaamiesTunnus", val);
      },
    },
    osoite: {
      get() {
        return this.$store.state.palveluhakemus.paamies.osoite;
      },
      set(val) {
        this.$store.commit("palveluhakemus/setPaamiesOsoite", val);
      },
    },
    postinumero: {
      get() {
        return this.$store.state.palveluhakemus.paamies.postinumero;
      },
      set(val) {
        this.$store.commit("palveluhakemus/setPaamiesPostinumero", val);
      },
    },
    toimipaikka: {
      get() {
        return this.$store.state.palveluhakemus.paamies.toimipaikka;
      },
      set(val) {
        this.$store.commit("palveluhakemus/setPaamiesToimipaikka", val);
      },
    },
    maa: {
      get() {
        return this.$store.state.palveluhakemus.paamies.maa;
      },
      set(val) {
        this.$store.commit("palveluhakemus/setPaamiesMaa", val);
      },
    },
    puhelin: {
      get() {
        return this.$store.state.palveluhakemus.paamies.puhelin;
      },
      set(val) {
        this.$store.commit("palveluhakemus/setPaamiesPuhelin", val);
      },
    },
    email: {
      get() {
        return this.$store.state.palveluhakemus.paamies.email;
      },
      set(val) {
        this.$store.commit("palveluhakemus/setPaamiesEmail", val);
      },
    },
    lahetystapa: {
      get() {
        return this.$store.state.palveluhakemus.paamies.lahetystapa;
      },
      set(val) {
        this.$store.commit("palveluhakemus/setPaamiesLahetystapa", val);
      },
    },
    verkkolaskuoperaattori: {
      get() {
        return this.$store.state.palveluhakemus.paamies.verkkolasku_operaattori;
      },
      set(val) {
        this.$store.commit(
          "palveluhakemus/setPaamiesVerkkolaskuoperaattori",
          val
        );
      },
    },
    verkkolaskuoperaattorit() {
      return this.$store.state.palveluhakemus.verkkolaskuoperaattorit;
    },
    www: {
      get() {
        return this.$store.state.palveluhakemus.paamies.www;
      },
      set(val) {
        this.$store.commit("palveluhakemus/setPaamiesWww", val);
      },
    },
    iban: {
      get() {
        return this.$store.state.palveluhakemus.paamies.iban;
      },
      set(val) {
        this.$store.commit("palveluhakemus/setPaamiesIban", val);
      },
    },
    bic: {
      get() {
        return this.$store.state.palveluhakemus.paamies.bic;
      },
      set(val) {
        this.$store.commit("palveluhakemus/setPaamiesBic", val);
      },
    },
    laskutus_email: {
      get() {
        return this.$store.state.palveluhakemus.paamies.laskutus_email;
      },
      set(val) {
        this.$store.commit("palveluhakemus/setPaamiesLaskutusEmail", val);
      },
    },
    laskutusjakso: {
      get() {
        return this.$store.state.palveluhakemus.paamies.palvelusopimus
          .laskutusjakso;
      },
      set(val) {
        this.$store.commit("palveluhakemus/setLaskutusjakso", val);
      },
    },
    laskutusjaksoItems() {
      return [
        {
          value: PaamiesPalveluhakemus.LASKUTUSJAKSO_KUUKAUSI,
          label:
            this.$formatSum(this.valittuPalvelusopimus.kkhinta) + "/ Kuukausi",
        },
        {
          value: PaamiesPalveluhakemus.LASKUTUSJAKSO_VUOSI,
          label:
            this.$formatSum(this.valittuPalvelusopimus.vuosihinta) + "/ Vuosi",
        },
      ];
    },
    verkkolaskuosoitePlaceholder() {
      return `Luodaan automaattisesti (0037${this.tunnus.replace("-", "")})`;
    },
  },
  watch: {
    "paamies.tunnus": {
      handler(val) {
        if (!val) return;

        this.debounceTimeout = setTimeout(async () => {
          if (val.length < 9) return;
          await this.tarkistaTunnuksenOikeellisuus(val);
        }, 300);
      },
    },
    lahetystapa(newValue, oldValue) {
      if (newValue === "verkkolasku") {
        const verkkolaskuosoite = "0037" + this.tunnus.replace("-", "");
        this.$store.commit(
          "palveluhakemus/setPaamiesVerkkolaskuosoite",
          verkkolaskuosoite
        );
      } else {
        this.$store.commit("palveluhakemus/setPaamiesVerkkolaskuosoite", "");
      }
    },
  },
  created() {},
  methods: {
    verkkolaskuoperaattoriFilter(item, hakusana) {
      const id = item.id.toLowerCase();
      const nimi = item.nimi.toLowerCase();
      const hakuteksti = hakusana.toLowerCase();

      return id.indexOf(hakuteksti) > -1 || nimi.indexOf(hakuteksti) > -1;
    },
    async tarkistaTunnuksenOikeellisuus(tunnus) {
      if (!tunnus) return;

      this.tunnusErrors = [];

      try {
        const request = await this.$store.dispatch(
          "palveluhakemus/tarkistaYTunnus",
          tunnus
        );
        if (!request.success) throw new this.$HttpError(request);

        const tunnusOnOikeellinen = request.result.body;
        if (!tunnusOnOikeellinen && this.paamies.tunnus) {
          this.tunnusErrors = ["Tarkista tunnus"];
        } else {
          this.tunnusErrors = [];
        }
      } catch (e) {
        this.$sentryCaptureCustom(e);
      }
    },
    async selvitaIBANistaBIC() {
      try {
        if (this.iban && this.iban.length > 10) {
          const bic = await this.$store.dispatch(
            "palveluhakemus/selvitaIBANistaBIC",
            this.iban
          );
          if (bic && bic.length > 7) {
            this.bic = bic;
          }
        }
      } catch (e) {
        this.$oletusVirheenkasittely(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
