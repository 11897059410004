<template>
  <v-form v-model="valid" @submit.prevent="submit">
    <div class="comboField">
      <v-menu
        v-model="alkamispaivaMenu"
        :close-on-content-click="false"
        max-width="290"
      >
        <template #activator="{ on }">
          <v-text-field
            :label="$t('common:startingDate')"
            :value="$dateFnsFormat(value.alkamispaiva)"
            readonly
            v-on="on"
          />
        </template>
        <template #default>
          <v-date-picker
            v-model="value.alkamispaiva"
            :locale="$languageLocale()"
            first-day-of-week="1"
            no-title
            @change="alkamispaivaMenu = false"
          />
        </template>
      </v-menu>
      <span class="comboField__separator">&ndash;</span>
      <v-menu
        v-model="paattymispaivaMenu"
        :close-on-content-click="false"
        max-width="290"
      >
        <template #activator="{ on }">
          <v-text-field
            :label="$t('common:endingDate')"
            :value="$dateFnsFormat(value.loppumispaiva)"
            readonly
            :rules="[validoiPaattymispaiva]"
            :placeholder="$t('common:validForNow')"
            persistent-placeholder
            clearable
            @click:clear="value.loppumispaiva = null"
            v-on="on"
          />
        </template>
        <template #default>
          <v-date-picker
            v-model="value.loppumispaiva"
            :locale="$languageLocale()"
            first-day-of-week="1"
            no-title
            @change="paattymispaivaMenu = false"
          />
        </template>
      </v-menu>
    </div>
    <v-text-field
      v-model="value.selite"
      :label="$t('common:definition')"
      class="required"
      :rules="[$validationRules.required]"
    />
    <PeruutaHyvaksyPainikerivi
      :submit-disabled="!valid || loading"
      :submit-loading="loading"
      :submit-teksti="$t('common:saveChanges')"
      @close="$emit('close')"
    />
  </v-form>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";

export default {
  name: "FormSopimusLaskutusjaksoMuokkaa",
  mixins: [FormMixin],
  data() {
    return {
      alkamispaivaMenu: false,
      paattymispaivaMenu: false,
    };
  },
  methods: {
    validoiPaattymispaiva() {
      const alkamispaiva = this.value.alkamispaiva
        ? new Date(this.value.alkamispaiva)
        : null;
      const loppumispaiva = this.value.loppumispaiva
        ? new Date(this.value.loppumispaiva)
        : null;
      if (loppumispaiva && loppumispaiva < alkamispaiva) {
        return this.$t("common:lasku.endDateNotBeforeStartDate");
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
