var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "formOstolasku",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pa-6", attrs: { md: "6" } },
            [
              _c("span", { staticClass: "paper__title hidden-md-and-up" }, [
                _vm._v("Ostolasku"),
              ]),
              _c("v-hover", {
                attrs: { "open-delay": "50" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ hover }) {
                      return [
                        _c(
                          "v-card",
                          {
                            staticClass: "pa-2 show-hidden-child-on-hover",
                            class: hover
                              ? "elevation-2 hoverBackground"
                              : "elevation-0",
                          },
                          [
                            _c(
                              "div",
                              [
                                _vm.ostolasku.toimittaja.id
                                  ? [
                                      _c(
                                        "v-row",
                                        { attrs: { justify: "space-between" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "py-0",
                                              attrs: { cols: "6" },
                                            },
                                            [
                                              _c("h3", {}, [
                                                _vm._v("Toimittaja"),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "col-icon py-0",
                                              attrs: { cols: "3" },
                                            },
                                            [
                                              _c("LomakenappiIkonilla", {
                                                attrs: {
                                                  ikoni: "clear",
                                                  infoteksti: _vm.$t("clear"),
                                                },
                                                on: {
                                                  click: _vm.tyhjennaToimittaja,
                                                },
                                              }),
                                              _c("LomakenappiIkonilla", {
                                                attrs: {
                                                  ikoni: "edit",
                                                  infoteksti:
                                                    _vm.$t("editSupplier"),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$emit(
                                                      "open-dialog-toimittaja"
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("OsoiteKappale", {
                                        attrs: {
                                          nimi: _vm.ostolasku.toimittaja.nimi,
                                          "renderoi-tyhja": true,
                                          "nayta-nimi": true,
                                          saaja: _vm.ostolasku.toimittaja
                                            .postiosoite.saaja
                                            ? _vm.ostolasku.toimittaja
                                                .postiosoite.saaja
                                            : null,
                                          postiosoite: _vm.ostolasku.toimittaja
                                            .postiosoite.osoite
                                            ? _vm.ostolasku.toimittaja
                                                .postiosoite.osoite
                                            : null,
                                          postinumero: _vm.ostolasku.toimittaja
                                            .postiosoite.numero
                                            ? _vm.ostolasku.toimittaja
                                                .postiosoite.numero
                                            : null,
                                          postitoimipaikka: _vm.ostolasku
                                            .toimittaja.postiosoite.toimipaikka
                                            ? _vm.ostolasku.toimittaja
                                                .postiosoite.toimipaikka
                                            : null,
                                        },
                                      }),
                                    ]
                                  : [
                                      _c("v-autocomplete", {
                                        ref: "autocompleteToimittaja",
                                        staticClass: "required",
                                        attrs: {
                                          items: _vm.toimittajaItems,
                                          loading: _vm.toimittajaLoading,
                                          "search-input":
                                            _vm.toimittajaSearchQuery,
                                          "hide-no-data":
                                            !_vm.toimittajaSearchQuery ||
                                            _vm.toimittajaLoading,
                                          "item-text": "nimi",
                                          "item-value": "id",
                                          label: "Toimittaja",
                                          placeholder: _vm.ostolasku.paamies.id
                                            ? "Hae toimittajan nimellä"
                                            : "Valitse ensin vastaanottaja",
                                          "persistent-placeholder": "",
                                          disabled: !_vm.ostolasku.paamies.id,
                                          "return-object": "",
                                          rules: [_vm.validoiToimittaja],
                                          "validate-on-blur": true,
                                        },
                                        on: {
                                          "update:searchInput": function (
                                            $event
                                          ) {
                                            _vm.toimittajaSearchQuery = $event
                                          },
                                          "update:search-input": function (
                                            $event
                                          ) {
                                            _vm.toimittajaSearchQuery = $event
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "item",
                                              fn: function ({ item }) {
                                                return [
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          _vm._s(item.nimi)
                                                        ),
                                                      ]),
                                                      item.postiosoite
                                                        ? _c(
                                                            "v-list-item-subtitle",
                                                            [
                                                              item.postiosoite
                                                                .osoite
                                                                ? [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          item
                                                                            .postiosoite
                                                                            .osoite
                                                                        ) +
                                                                        ", "
                                                                    ),
                                                                  ]
                                                                : _vm._e(),
                                                              item.postiosoite
                                                                .numero
                                                                ? [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          item
                                                                            .postiosoite
                                                                            .numero
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                : _vm._e(),
                                                              item.postiosoite
                                                                .toimipaikka
                                                                ? [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          item
                                                                            .postiosoite
                                                                            .toimipaikka
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                : _vm._e(),
                                                            ],
                                                            2
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "no-data",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "p",
                                                    { staticClass: "pa-4" },
                                                    [
                                                      _vm._v(
                                                        "Haulla ei löytynyt yhtäkään toimittajaa."
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            !_vm.ostolasku.toimittaja.id
                                              ? {
                                                  key: "append-outer",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "LomakenappiIkonilla",
                                                        {
                                                          attrs: {
                                                            ikoni: "person_add",
                                                            infoteksti:
                                                              _vm.$t(
                                                                "createSupplier"
                                                              ),
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.$emit(
                                                                "open-dialog-toimittaja"
                                                              )
                                                            },
                                                          },
                                                        }
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                }
                                              : null,
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.ostolasku.toimittaja,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ostolasku,
                                              "toimittaja",
                                              $$v
                                            )
                                          },
                                          expression: "ostolasku.toimittaja",
                                        },
                                      }),
                                    ],
                              ],
                              2
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("div", {
                class: {
                  "py-3": _vm.$vuetify.breakpoint.smAndDown,
                  "py-5": _vm.$vuetify.breakpoint.mdAndUp,
                },
              }),
              _c("v-hover", {
                attrs: { "open-delay": "50" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ hover }) {
                      return [
                        _c(
                          "v-card",
                          {
                            staticClass: "pa-2 show-hidden-child-on-hover",
                            class:
                              hover &&
                              _vm.$store.getters["user/salliPaamiehenTyhjennys"]
                                ? "elevation-2 hoverBackground"
                                : "elevation-0",
                          },
                          [
                            _c(
                              "div",
                              [
                                _vm.ostolasku.paamies.id
                                  ? [
                                      _c(
                                        "v-row",
                                        { attrs: { justify: "space-between" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "py-0",
                                              attrs: { cols: "6" },
                                            },
                                            [
                                              _c("h3", [
                                                _vm._v("Vastaanottaja"),
                                              ]),
                                            ]
                                          ),
                                          !_vm.muokkaamassaLaskua &&
                                          _vm.$store.getters[
                                            "user/salliPaamiehenTyhjennys"
                                          ]
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "col-icon py-0",
                                                  attrs: { cols: "3" },
                                                },
                                                [
                                                  _c("LomakenappiIkonilla", {
                                                    attrs: {
                                                      ikoni: "clear",
                                                      infoteksti:
                                                        _vm.$t("clear"),
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.tyhjennaPaamies,
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c("OsoiteKappale", {
                                        attrs: {
                                          "renderoi-tyhja": true,
                                          "nayta-nimi": true,
                                          nimi: _vm.ostolasku.paamies.nimi,
                                          postiosoite: _vm.ostolasku.paamies
                                            .postiosoite
                                            ? _vm.ostolasku.paamies.postiosoite
                                                .postiosoite
                                            : null,
                                          postinumero: _vm.ostolasku.paamies
                                            .postiosoite
                                            ? _vm.ostolasku.paamies.postiosoite
                                                .postinumero
                                            : null,
                                          postitoimipaikka: _vm.ostolasku
                                            .paamies.postiosoite
                                            ? _vm.ostolasku.paamies.postiosoite
                                                .postitoimipaikka
                                            : null,
                                        },
                                      }),
                                    ]
                                  : [
                                      _c("AutocompletePaamies", {
                                        ref: "autocompletePaamies",
                                        attrs: {
                                          label: _vm.$t("common:recipient"),
                                          "tarkistettava-oikeus":
                                            "onlinerestapi.onlinerestapi_ostolasku",
                                        },
                                        model: {
                                          value: _vm.ostolasku.paamies,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ostolasku,
                                              "paamies",
                                              $$v
                                            )
                                          },
                                          expression: "ostolasku.paamies",
                                        },
                                      }),
                                    ],
                              ],
                              2
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pa-6", attrs: { md: "6" } },
            [
              _c(
                "v-col",
                { staticClass: "pa-2 pt-0" },
                [
                  _c(
                    "span",
                    { staticClass: "paper__title hidden-sm-and-down" },
                    [_vm._v("Ostolasku")]
                  ),
                  _c("v-text-field", {
                    ref: "laskunumero",
                    staticClass: "mt-1 pt-1 required",
                    attrs: {
                      rules: [_vm.$validationRules.required],
                      label: "Laskunumero",
                    },
                    model: {
                      value: _vm.ostolasku.laskunumero,
                      callback: function ($$v) {
                        _vm.$set(_vm.ostolasku, "laskunumero", $$v)
                      },
                      expression: "ostolasku.laskunumero",
                    },
                  }),
                  _c("v-menu", {
                    attrs: {
                      "close-on-content-click": false,
                      "max-width": "290",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function ({ on }) {
                          return [
                            _c(
                              "v-text-field",
                              _vm._g(
                                {
                                  staticClass: "mt-1 pt-1",
                                  class: _vm.laskupaivaClassArr,
                                  attrs: {
                                    label: "Laskupäivä",
                                    value: _vm.$dateFnsFormat(
                                      _vm.ostolasku.laskupaiva
                                    ),
                                    readonly: "",
                                    rules: [_vm.$validationRules.required],
                                  },
                                },
                                on
                              )
                            ),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c("v-date-picker", {
                              staticClass: "mt-1 pt-1",
                              attrs: {
                                locale: "fi-FI",
                                "first-day-of-week": "1",
                                "no-title": "",
                              },
                              on: {
                                change: function ($event) {
                                  _vm.laskupaivaMenu = false
                                },
                              },
                              model: {
                                value: _vm.ostolasku.laskupaiva,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ostolasku, "laskupaiva", $$v)
                                },
                                expression: "ostolasku.laskupaiva",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.laskupaivaMenu,
                      callback: function ($$v) {
                        _vm.laskupaivaMenu = $$v
                      },
                      expression: "laskupaivaMenu",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass: "mt-1 pt-1",
                    class: _vm.maksuehtoClassArr,
                    attrs: {
                      type: "number",
                      label: "Maksuehto",
                      rules: [_vm.$validationRules.zeroOrBigger],
                      suffix: "pv netto",
                      "full-width": false,
                    },
                    model: {
                      value: _vm.ostolasku.maksuehto,
                      callback: function ($$v) {
                        _vm.$set(_vm.ostolasku, "maksuehto", $$v)
                      },
                      expression: "ostolasku.maksuehto",
                    },
                  }),
                  _c("v-menu", {
                    attrs: {
                      "close-on-content-click": false,
                      "max-width": "290",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function ({ on }) {
                          return [
                            _c(
                              "v-text-field",
                              _vm._g(
                                {
                                  staticClass: "mt-1 pt-1",
                                  class: _vm.erapaivaClassArr,
                                  attrs: {
                                    label: "Eräpäivä",
                                    value: _vm.$dateFnsFormat(
                                      _vm.ostolasku.erapaiva
                                    ),
                                    readonly: "",
                                    rules: [_vm.$validationRules.required],
                                  },
                                },
                                on
                              )
                            ),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c("v-date-picker", {
                              attrs: {
                                locale: "fi-FI",
                                "first-day-of-week": "1",
                                "no-title": "",
                              },
                              on: {
                                change: function ($event) {
                                  _vm.erapaivaMenu = false
                                },
                              },
                              model: {
                                value: _vm.ostolasku.erapaiva,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ostolasku, "erapaiva", $$v)
                                },
                                expression: "ostolasku.erapaiva",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.erapaivaMenu,
                      callback: function ($$v) {
                        _vm.erapaivaMenu = $$v
                      },
                      expression: "erapaivaMenu",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass: "mt-1 pt-1",
                    attrs: {
                      type: "text",
                      label: "Viivästyskorko",
                      suffix: "%",
                      rules: [
                        _vm.$validationRules.maxDecimalPlacesOrEmpty(
                          _vm.ostolasku.viivastyskorko,
                          2
                        ),
                        _vm.$validationRules.zeroOrBiggerWithDecimal,
                      ],
                      "validate-on-blur": true,
                    },
                    model: {
                      value: _vm.ostolasku.viivastyskorko,
                      callback: function ($$v) {
                        _vm.$set(_vm.ostolasku, "viivastyskorko", $$v)
                      },
                      expression: "ostolasku.viivastyskorko",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass: "mt-1 pt-1 required",
                    attrs: {
                      label: "Viite",
                      type: "text",
                      rules: [_vm.$validationRules.required],
                    },
                    model: {
                      value: _vm.ostolasku.viite,
                      callback: function ($$v) {
                        _vm.$set(_vm.ostolasku, "viite", $$v)
                      },
                      expression: "ostolasku.viite",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass: "mt-1 pt-1 required",
                    attrs: {
                      label: "IBAN",
                      type: "text",
                      rules: [
                        _vm.$validationRules.required,
                        _vm.$validationRules.isValidIBAN,
                      ],
                      "validate-on-blur": true,
                    },
                    model: {
                      value: _vm.ostolasku.epitieto.iban,
                      callback: function ($$v) {
                        _vm.$set(_vm.ostolasku.epitieto, "iban", $$v)
                      },
                      expression: "ostolasku.epitieto.iban",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass: "mt-1 pt-1 required",
                    attrs: {
                      label: "BIC",
                      type: "text",
                      rules: [
                        _vm.$validationRules.required,
                        _vm.$validationRules.isValidBIC,
                      ],
                      "validate-on-blur": true,
                    },
                    model: {
                      value: _vm.ostolasku.epitieto.bic,
                      callback: function ($$v) {
                        _vm.$set(_vm.ostolasku.epitieto, "bic", $$v)
                      },
                      expression: "ostolasku.epitieto.bic",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass: "mt-1 pt-1",
                    attrs: { label: "Viitteemme", type: "text" },
                    model: {
                      value: _vm.ostolasku.viitteemme,
                      callback: function ($$v) {
                        _vm.$set(_vm.ostolasku, "viitteemme", $$v)
                      },
                      expression: "ostolasku.viitteemme",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass: "mt-1 pt-1",
                    attrs: { label: "Viitteenne", type: "text" },
                    model: {
                      value: _vm.ostolasku.viitteenne,
                      callback: function ($$v) {
                        _vm.$set(_vm.ostolasku, "viitteenne", $$v)
                      },
                      expression: "ostolasku.viitteenne",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pa-6" },
        [
          _c("h2", { staticClass: "mb-4" }, [_vm._v("Laskurivit")]),
          _c("LaskuriviTable", {
            ref: "laskuriviTable",
            attrs: {
              "cell-size": "small",
              tyyppi: "ostolasku",
              tuoterekisteri: false,
            },
            model: {
              value: _vm.ostolasku,
              callback: function ($$v) {
                _vm.ostolasku = $$v
              },
              expression: "ostolasku",
            },
          }),
          _c(
            "v-row",
            { staticClass: "mt-8" },
            [
              _c(
                "v-col",
                { attrs: { cols: "8" } },
                [
                  _c("AutocompleteLaskurivi", {
                    attrs: { lasku: _vm.ostolasku, tyyppi: "ostolasku" },
                    on: {
                      "autocomplete-input": _vm.poistaTyhjaEnsimmainenRivi,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }