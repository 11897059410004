import BaseSelaa from "@/store/modules/baseSelaa";
import { doRequestWithTimeout, oletusVirheenkasittely } from "@/utils/misc";
import { HttpError } from "@/utils/errors";
import api from "@/plugins/api";
import { naytaTiedostonlataamisdialog } from "@/utils/misc";

export default class BaseOptimizedCountSelaa extends BaseSelaa {
  actions() {
    return {
      ...super.actions(),
      async setSummary({ commit, getters, state }, { query }) {
        try {
          commit("setLoadingSummary", true);

          const api = getters.countApi;
          for (const pendingRequest of api.queue) {
            pendingRequest.cancelled = true;
          }

          const request = await doRequestWithTimeout(
            api,
            {
              method: "GET",
              url: "",
              query: query,
            },
            "doRequest",
            300000 // 5 min
          );

          if (!request.success && !request.cancelled)
            throw new HttpError(request);

          const { result } = request;

          if (!request.cancelled) {
            commit("setItemsTotal", result.body.count);
            commit(
              "setPagesTotal",
              Math.ceil(result.body.count / state.pagination.itemsPerPage)
            );
            commit("setLoadingSummary", false);
          }
        } catch (e) {
          oletusVirheenkasittely(e);
        }
      },
      async haeQueryCsvNa({ getters }, { query }) {
        const api = getters.csvApi;
        for (const pendingRequest of api.queue) {
          pendingRequest.cancelled = true;
        }

        const request = await doRequestWithTimeout(
          api,
          {
            method: "GET",
            url: "",
            query: query,
          },
          "doRequest",
          300000 // 5 min
        );
        if (!request.success && !request.cancelled)
          throw new HttpError(request);
        const tiedostoNimi = naytaTiedostonlataamisdialog({
          fileData: request.result.body,
          filename: "laskut",
          headers: request.result.headers,
        });
        return tiedostoNimi;
      },
      async setItems(
        { commit, dispatch, getters, state },
        { query, silent = false, toggleLoadingSummary = true }
      ) {
        try {
          if (!silent) commit("setLoading", true);

          const api = getters.api;
          for (const pendingRequest of api.queue) {
            pendingRequest.cancelled = true;
          }

          if (toggleLoadingSummary) {
            dispatch("setSummary", { query: query });
          }

          const request = await doRequestWithTimeout(
            api,
            {
              method: "GET",
              url: "",
              query: query,
            },
            "doRequest",
            300000 // 5 min
          );

          if (!request.success && !request.cancelled)
            throw new HttpError(request);

          const { result } = request;

          if (!request.cancelled) {
            commit("setItems", result.body.results);
            // Jos tulosten lukumäärää ei ole vielä saatu, asetetaan pyynnön mukana tuleva vakio
            // 999 999 999, jotta datatable näyttää tulokset.
            if (state.loadingSummary) {
              commit("setItemsTotal", result.body.count);
            } else {
              commit(
                "setPagesTotal",
                Math.ceil(state.itemsTotal / state.pagination.itemsPerPage)
              );
            }
          }
        } catch (e) {
          oletusVirheenkasittely(e);
        } finally {
          commit("setLoading", false);
        }
      },
      async haeLaskuLisatietoAvaimet({ state, commit, rootState }) {
        const request = await doRequestWithTimeout(api.LaskulisatietoAvaimet, {
          method: "GET",
          url: "",
        });
        if (!request.success) throw new HttpError(request);
        rootState.yleinen.laskulisatietoAvaimet = {
          keys: request.result.body,
        };
        commit(
          "yleinen/setLaskulisatietoAvaimet",
          rootState.yleinen.laskulisatietoAvaimet,
          { root: true }
        );
        return true;
      },
    };
  }
}
