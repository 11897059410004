var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.links, function (item) {
        return _c(
          "div",
          { key: item.tCode, staticClass: "ohjeet-link" },
          [
            _c(
              "v-btn",
              {
                attrs: { outlined: "", large: "", width: "50%" },
                on: {
                  click: function ($event) {
                    return _vm.dialoginAukaisu(item.tCode, item.link)
                  },
                },
              },
              [
                _c("h3", [_vm._v(_vm._s(_vm.$t(item.tCode)))]),
                _c("v-icon", { attrs: { right: "" } }, [
                  _vm._v("mdi-open-in-new"),
                ]),
              ],
              1
            ),
          ],
          1
        )
      }),
      _vm.$permission.checkSinglePerm("onlinerestapi.onlinerestapi_beta")
        ? _c(
            "div",
            [
              _c("v-divider", { staticClass: "my-6" }),
              _c("h2", { staticClass: "my-4" }, [
                _vm._v("Ohjevideot (2. vaihe)"),
              ]),
              _c("v-skeleton-loader", {
                attrs: { boilerplate: "", height: "200", type: "image" },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "" },
          model: {
            value: _vm.dialogAuki,
            callback: function ($$v) {
              _vm.dialogAuki = $$v
            },
            expression: "dialogAuki",
          },
        },
        [
          _c("v-card", { staticClass: "dialog-card" }, [
            _c(
              "div",
              { staticClass: "header" },
              [
                _c("h2", [_vm._v(_vm._s(_vm.$t(_vm.dialogTCode)))]),
                _c("v-spacer"),
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "" },
                    on: {
                      click: function ($event) {
                        _vm.dialogAuki = false
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("mdi-close")])],
                  1
                ),
              ],
              1
            ),
            _c("iframe", {
              staticClass: "iframe",
              attrs: {
                src: _vm.dialogLink,
                width: "100%",
                height: "100%",
                frameborder: "0",
                sandbox: "",
                referrerpolicy: "no-referrer",
              },
            }),
          ]),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }