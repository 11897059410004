import translationCommonEN from "./en/common.json";
import translationCommonFI from "./fi/common.json";

const translationResources = {
  en: {
    common: translationCommonEN,
  },
  fi: {
    common: translationCommonFI,
  },
};

export default translationResources;
