var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-tooltip", {
    attrs: { "open-delay": "500", bottom: "" },
    scopedSlots: _vm._u([
      {
        key: "activator",
        fn: function ({ on }) {
          return [
            _c("DefListItem", {
              attrs: {
                "label-class": _vm.labelClass,
                "value-class": _vm.valueClass,
                "event-listener": on,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "label",
                    fn: function () {
                      return [_vm._t("label")]
                    },
                    proxy: true,
                  },
                  {
                    key: "value",
                    fn: function () {
                      return [_vm._t("value")]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            }),
          ]
        },
      },
      {
        key: "default",
        fn: function () {
          return [
            _c("p", { staticClass: "tooltip-hover" }, [
              _vm._v(" " + _vm._s(_vm.tooltip) + " "),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }