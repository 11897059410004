<template>
  <div>
    <table class="riviTable">
      <thead>
        <tr>
          <th
            class="riviTable__cell riviTable__cell--header riviTable__cell--min-width-large"
            scope="col"
          >
            {{ $t("common:invoicingPeriod") }}
          </th>
          <th
            class="riviTable__cell riviTable__cell--header riviTable__cell--min-width-large, riviTable__cell--required"
            scope="col"
          >
            {{ $t("common:invoicingDate") }}
          </th>
          <th class="riviTable__cell riviTable__cell--header" scope="col">
            {{ $t("common:code") }}
          </th>
          <th
            class="riviTable__cell riviTable__cell--header riviTable__cell--min-width-medium riviTable__cell--required"
            scope="col"
          >
            {{ $t("common:definition") }}
          </th>
          <th :class="[...thClassArr, 'riviTable__cell--required']" scope="col">
            {{ $t("common:amount") }}
          </th>
          <th :class="thClassArr" scope="col">
            {{ $t("common:quantityUnit") }}
          </th>
          <th :class="[...thClassArr, 'riviTable__cell--required']" scope="col">
            {{ $t("common:uPrice") }}
          </th>
          <th :class="[...thClassArr, 'riviTable__cell--required']" scope="col">
            {{ $t("common:discountPercentShort") }}
          </th>
          <th
            class="riviTable__cell riviTable__cell--header text-right"
            scope="col"
          >
            {{ $t("common:inclVAT") }}
          </th>
          <th :class="[...thClassArr, 'riviTable__cell--required']" scope="col">
            {{ $t("common:vatPercent") }}
          </th>
          <th :class="thClassArr" scope="col">
            {{ $t("common:taxFree") }}
          </th>
          <th :class="thClassArr" scope="col">
            {{ $t("common:tax") }}
          </th>
          <th :class="thClassArr" scope="col">
            {{ $t("common:taxable") }}
          </th>
          <th
            class="riviTable__cell riviTable__cell--header text-right"
            scope="col"
          ></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td :class="[...tdClassArr, 'riviTable__cell--min-width-large']">
            <v-select
              v-model="laskutusaihe.laskutusjakso"
              type="text"
              hide-details
              item-text="laskutusjakso"
              item-value="id"
              :items="laskutusjaksot"
            />
          </td>
          <td :class="tdClassArr">
            <v-menu
              v-model="laskutuspaivaMenu"
              :close-on-content-click="false"
              max-width="290"
            >
              <template #activator="{ on }">
                <v-text-field
                  :value="$dateFnsFormat(laskutusaihe.laskutuspaiva)"
                  readonly
                  :rules="[$validationRules.required]"
                  @click:clear="laskutusaihe.laskutuspaiva = null"
                  v-on="on"
                />
              </template>
              <template #default>
                <v-date-picker
                  v-model="laskutusaihe.laskutuspaiva"
                  :rules="[$validationRules.required]"
                  locale="fi-FI"
                  first-day-of-week="1"
                  no-title
                  @change="laskutuspaivaMenu = false"
                />
              </template>
            </v-menu>
          </td>
          <td :class="tdClassArr">
            <v-text-field
              v-model="laskutusaihe.koodi"
              type="text"
              hide-details
            />
          </td>
          <td :class="tdClassArr">
            <v-text-field
              v-model="laskutusaihe.selite"
              type="text"
              :rules="[$validationRules.required]"
              hide-details
            />
          </td>
          <td :class="tdClassArr">
            <v-text-field
              v-model="laskutusaihe.maara"
              type="number"
              :rules="[$validationRules.required]"
              required
              reverse
              hide-details
            />
          </td>
          <td :class="tdClassArr">
            <v-text-field
              v-model="laskutusaihe.laatu"
              type="text"
              reverse
              hide-details
            />
          </td>
          <td :class="tdClassArr">
            <v-text-field
              v-model="laskutusaihe.ahinta"
              type="number"
              :rules="[
                $validationRules.required,
                $validationRules.positiveUnitPrice,
              ]"
              required
              reverse
            />
          </td>
          <td :class="tdClassArr">
            <v-text-field
              v-model="laskutusaihe.alennusp"
              type="number"
              reverse
              hide-details
              :rules="[
                $validationRules.zeroOrBigger,
                $validationRules.smallerThanOrEqualTo(
                  laskutusaihe.alennusp,
                  100
                ),
                $validationRules.requiredLoose,
                $validationRules.maxDecimalPlaces(laskutusaihe.alennusp, 2),
              ]"
            />
          </td>
          <td :class="tdClassArr">
            <v-checkbox
              v-model="laskutusaihe.sisalv"
              class="riviTable__cell--checkbox"
              hide-details
            />
          </td>
          <td :class="tdClassArr">
            <v-select
              v-model="laskutusaihe.alvtunnus"
              type="number"
              hide-details
              item-text="tunnus"
              item-value="id"
              :items="ALVTunnusItems"
              reverse
              return-object
              required
              :rules="[$validationRules.requiredObject]"
              @input="paivitaALVPros($event, laskutusaihe)"
            />
          </td>
          <td :class="tdClassArr">
            <v-text-field
              v-model="laskutusaihe.veroton"
              type="number"
              disabled
              reverse
            />
          </td>
          <td :class="tdClassArr">
            <v-text-field
              v-model="laskutusaihe.vero"
              type="number"
              disabled
              reverse
            />
          </td>
          <td :class="tdClassArr">
            <v-text-field
              v-model="laskutusaihe.verollinen"
              type="number"
              disabled
              reverse
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "SopimuslaskutusaiheTable",
  model: {
    prop: "laskutusaihe",
  },
  props: {
    laskutusaihe: {
      type: Object,
      required: true,
    },
    laskutusjaksot: {
      type: Array,
      required: true,
    },
    cellSize: {
      type: String,
      required: false,
      default() {
        return "medium";
      },
    },
  },
  data() {
    return {
      laskutuspaivaMenu: false,
    };
  },
  computed: {
    ALVTunnusItems() {
      return this.$store.state.yleinen.ALVTunnusItems;
    },
    thClassArr() {
      return [
        "riviTable__cell",
        "riviTable__cell--header",
        "text-right",
        this.cellSize === "small"
          ? "riviTable__cell--small"
          : "riviTable__cell--medium",
      ];
    },
    tdClassArr() {
      return [
        "riviTable__cell",
        this.cellSize === "small"
          ? "riviTable__cell--small"
          : "riviTable__cell--medium",
      ];
    },
  },
  watch: {
    laskutusaihe: {
      deep: true,
      handler() {
        this.laskutusaihe.calculateTotals();
      },
    },
  },
  methods: {
    paivitaALVPros(alvtunnus, rivi) {
      rivi.alvp = alvtunnus.alvp;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/riviTableStyles.scss";
</style>
