<template>
  <div>
    <div v-for="item in links" :key="item.tCode" class="ohjeet-link">
      <v-btn
        outlined
        large
        width="50%"
        @click="dialoginAukaisu(item.tCode, item.link)"
      >
        <h3>{{ $t(item.tCode) }}</h3>
        <v-icon right>mdi-open-in-new</v-icon>
      </v-btn>
    </div>
    <div v-if="$permission.checkSinglePerm('onlinerestapi.onlinerestapi_beta')">
      <v-divider class="my-6" />
      <h2 class="my-4">Ohjevideot (2. vaihe)</h2>
      <v-skeleton-loader boilerplate height="200" type="image" />
    </div>
    <v-dialog v-model="dialogAuki" persistent>
      <v-card class="dialog-card">
        <div class="header">
          <h2>{{ $t(dialogTCode) }}</h2>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogAuki = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <iframe
          :src="dialogLink"
          width="100%"
          height="100%"
          class="iframe"
          frameborder="0"
          sandbox
          referrerpolicy="no-referrer"
        ></iframe>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "AsetuksetOhjeet",
  components: {},
  data() {
    return {
      ukkAuki: false,
      tietosuojaselosteAuki: false,
      palveluehdotAuki: false,
      dialogAuki: false,
      dialogTCode: "",
      dialogLink: "",
      links: {
        ukk: {
          tCode: "frequentlyAskedQuestions",
          link: "https://www.uuva.fi/cloud/ohjeet",
        },
        tietosuoja: {
          tCode: "privacyPolicy",
          link: "https://www.uuva.fi/uuva-cloud-tietosuojaseloste",
        },
        palveluehdot: {
          tCode: "serviceApplication.termsofService",
          link: "https://www.uuva.fi/uuva-cloud/yleiset-sopimusehdot/",
        },
      },
    };
  },
  methods: {
    dialoginAukaisu(tCode, link) {
      this.dialogTCode = tCode;
      this.dialogLink = link;
      this.dialogAuki = true;
      return;
    },
  },
};
</script>
<style scoped lang="scss">
.ohjeet-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
.dialog-card {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 90vh;
}
.header {
  display: flex;
  flex-direction: row;
  margin: 0.5rem 1rem;
  text-transform: capitalize;
}

::-webkit-scrollbar {
  width: 20px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
</style>
