<template>
  <ul v-if="hasDefaultSlot" class="inlineList">
    <slot />
  </ul>
</template>

<script>
export default {
  name: "InlineList",
  computed: {
    hasDefaultSlot() {
      return !!this.$slots.default;
    },
  },
};
</script>

<style lang="scss">
$inlineListItemOffset: 0.5em;

.inlineList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
  margin-left: -$inlineListItemOffset;
  margin-right: -$inlineListItemOffset;

  &__item {
    margin: 0 $inlineListItemOffset;
  }
}

.inlineList__link {
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
</style>
