var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-menu", {
    attrs: { transition: "slide-y-transition", "nudge-bottom": "40" },
    scopedSlots: _vm._u([
      {
        key: "activator",
        fn: function ({ on }) {
          return [
            _c(
              "div",
              _vm._g(
                {
                  staticClass: "loggedInUser__wrap my-6",
                  class: _vm.miniVariant ? "px-0" : "px-2",
                },
                on
              ),
              [
                _c(
                  "div",
                  {
                    staticClass: "loggedInUser__col loggedInUser__col--avatar",
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "loggedInUser__iconWrap" },
                      [
                        _c("v-icon", { staticClass: "loggedInUser__icon" }, [
                          _vm._v("person"),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
                _vm.showUsername
                  ? [
                      _c(
                        "span",
                        {
                          staticClass:
                            "loggedInUser__col loggedInUser__col--content loggedInUser__col--fill",
                        },
                        [_vm._v(" " + _vm._s(_vm.user.naytettavaNimi) + " ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "loggedInUser__col loggedInUser__col--actions",
                        },
                        [_c("v-icon", [_vm._v("arrow_drop_down")])],
                        1
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]
        },
      },
      {
        key: "default",
        fn: function () {
          return [
            _c(
              "v-list",
              [
                !_vm.tekeytyminenKaynnissa &&
                _vm.onlineUrl &&
                _vm.$permission.checkSinglePerm("anitta_user.onlinepalvelu")
                  ? _c(
                      "v-list-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.$emit("siirry-onlineen")
                          },
                        },
                      },
                      [
                        _c(
                          "v-list-item-avatar",
                          [_c("v-icon", [_vm._v("east")])],
                          1
                        ),
                        _c("v-list-item-title", [_vm._v("Online")]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.$permission.checkSinglePerm(
                  "onlinerestapi.onlinerestapi_asetukset"
                )
                  ? _c(
                      "v-list-item",
                      { attrs: { to: { name: "asetukset" } } },
                      [
                        _c(
                          "v-list-item-avatar",
                          [_c("v-icon", [_vm._v("settings")])],
                          1
                        ),
                        _c("v-list-item-title", [_vm._v("Asetukset")]),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.tekeytyminenKaynnissa
                  ? _c(
                      "v-list-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.$emit("logOut")
                          },
                        },
                      },
                      [
                        _c(
                          "v-list-item-avatar",
                          [_c("v-icon", [_vm._v("logout")])],
                          1
                        ),
                        _c("v-list-item-title", [_vm._v("Kirjaudu ulos")]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.user.isSuperuser
                  ? _c("div", [_c("LanguageSwitch")], 1)
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }