var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.paamiesosoite
    ? _c(
        "div",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { md: "12" } },
                [
                  _c("InputPuhelinNumero", {
                    attrs: { required: false, outlined: true },
                    model: {
                      value: _vm.paamiesosoite.puhelin,
                      callback: function ($$v) {
                        _vm.$set(_vm.paamiesosoite, "puhelin", $$v)
                      },
                      expression: "paamiesosoite.puhelin",
                    },
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("common:emailAddress"),
                      rules: [_vm.$validationRules.email],
                      outlined: "",
                    },
                    model: {
                      value: _vm.paamiesosoite.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.paamiesosoite, "email", $$v)
                      },
                      expression: "paamiesosoite.email",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass: "required",
                    attrs: {
                      label: _vm.$t("common:recipient"),
                      rules: [_vm.$validationRules.required],
                      outlined: "",
                    },
                    model: {
                      value: _vm.paamiesosoite.vastaanottaja,
                      callback: function ($$v) {
                        _vm.$set(_vm.paamiesosoite, "vastaanottaja", $$v)
                      },
                      expression: "paamiesosoite.vastaanottaja",
                    },
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("common:receiverOrSpecifier"),
                      outlined: "",
                    },
                    model: {
                      value: _vm.paamiesosoite.saaja,
                      callback: function ($$v) {
                        _vm.$set(_vm.paamiesosoite, "saaja", $$v)
                      },
                      expression: "paamiesosoite.saaja",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass: "required",
                    attrs: {
                      label: _vm.$t("common:address"),
                      rules: [_vm.$validationRules.required],
                      outlined: "",
                    },
                    model: {
                      value: _vm.paamiesosoite.postiosoite,
                      callback: function ($$v) {
                        _vm.$set(_vm.paamiesosoite, "postiosoite", $$v)
                      },
                      expression: "paamiesosoite.postiosoite",
                    },
                  }),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-1", attrs: { md: "5" } },
                        [
                          _c("v-text-field", {
                            staticClass: "required",
                            attrs: {
                              label: _vm.$t("common:zipCode"),
                              rules: [_vm.$validationRules.required],
                              outlined: "",
                            },
                            model: {
                              value: _vm.paamiesosoite.postinumero,
                              callback: function ($$v) {
                                _vm.$set(_vm.paamiesosoite, "postinumero", $$v)
                              },
                              expression: "paamiesosoite.postinumero",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "py-1", attrs: { md: "7" } },
                        [
                          _c("v-text-field", {
                            staticClass: "required",
                            attrs: {
                              label: _vm.$t("common:postalDistrict"),
                              rules: [_vm.$validationRules.required],
                              outlined: "",
                            },
                            model: {
                              value: _vm.paamiesosoite.postitoimipaikka,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.paamiesosoite,
                                  "postitoimipaikka",
                                  $$v
                                )
                              },
                              expression: "paamiesosoite.postitoimipaikka",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-select", {
                    staticClass: "required",
                    attrs: {
                      label: _vm.$t("common:country"),
                      type: "text",
                      items: _vm.maat,
                      "item-text": "selite",
                      "item-value": "value",
                      "menu-props": { zIndex: "999", auto: true },
                      rules: [_vm.$validationRules.required],
                      outlined: "",
                    },
                    model: {
                      value: _vm.paamiesosoite.maa,
                      callback: function ($$v) {
                        _vm.$set(_vm.paamiesosoite, "maa", $$v)
                      },
                      expression: "paamiesosoite.maa",
                    },
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("common:eInvoiceAddress"),
                      "error-messages": _vm.verkkolaskuosoiteErrors,
                      outlined: "",
                    },
                    model: {
                      value: _vm.paamiesosoite.verkkolasku_osoite,
                      callback: function ($$v) {
                        _vm.$set(_vm.paamiesosoite, "verkkolasku_osoite", $$v)
                      },
                      expression: "paamiesosoite.verkkolasku_osoite",
                    },
                  }),
                  _c("v-autocomplete", {
                    staticClass: "py-1",
                    attrs: {
                      clearable: "",
                      items: _vm.verkkolaskuoperaattorit,
                      filter: _vm.verkkolaskuoperaattoriFilter,
                      "item-text": "autocomplete_selite",
                      "item-value": "id",
                      label: _vm.$t("common:eInvoiceOperator"),
                      "error-messages": _vm.verkkolaskuoperaattoriErrors,
                      outlined: "",
                    },
                    model: {
                      value: _vm.paamiesosoite.verkkolasku_operaattori,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.paamiesosoite,
                          "verkkolasku_operaattori",
                          $$v
                        )
                      },
                      expression: "paamiesosoite.verkkolasku_operaattori",
                    },
                  }),
                  _vm.salliPoisto
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "info", small: "" },
                          on: { click: _vm.poistaOsoite },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("common:settings.useContactAddress")
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "div",
        [
          _c("p", { staticClass: "text-subtitle-1" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("common:settings.usingContactAddress")) + " "
            ),
          ]),
          _c(
            "v-btn",
            {
              attrs: { color: "primary", small: "" },
              on: { click: _vm.lisaaOsoite },
            },
            [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }