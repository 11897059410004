import { HttpError } from "@/utils/errors";
import {
  doRequestWithTimeout,
  oletusVirheenkasittely,
  sentryCaptureCustom,
} from "@/utils/misc";
import apiUlkoinen from "@/plugins/apiUlkoinen";
import api from "@/plugins/api";
import apiInse from "@/plugins/apiInse";
import { PaamiesPalveluhakemus } from "@/class/Paamies";

function reverseSortObjectByNumberKeys(a, b) {
  var keyA = Number(a[0]),
    keyB = Number(b[0]);
  if (keyA < keyB) return 1;
  if (keyA > keyB) return -1;
  return 0;
}

export default {
  namespaced: true,
  state: {
    loading: false,
    maat: [],
    verkkolaskuoperaattorit: [],
    kayttaja: {
      email: "",
      salasana: "",
      salasanaUudelleen: "",
      palveluehdotHyvaksytty: false,
      tietosuojaselosteHyvaksytty: false,
      markkinointiViestitHyvaksytty: false,
    },
    paamies: new PaamiesPalveluhakemus(),
    valittavatPalvelusopimukset: [],
    suomifiJwt: null,
    accessToken: "",
    refreshToken: "",
  },

  getters: {
    kasitteleYTJTulokset(state, getters) {
      return (tulokset) => {
        return tulokset.map((result) => {
          return getters.kasitteleYTJTulos(result);
        });
      };
    },
    kasitteleYTJTulos() {
      return (result) => {
        const yritys = {
          nimi: result.name,
        };
        if (result.addresses && result.addresses.length) {
          let address = null;
          for (const addr of result.addresses) {
            if (addr.type == 2) {
              if (!address) {
                address = addr;
              } else if (address.registrationDate <= addr.registrationDate) {
                address = addr;
              }
            }
          }
          yritys.osoite = address.street;
          yritys.postinumero = address.postCode;
          yritys.toimipaikka = address.city;
        }

        if (result.contactDetails && result.contactDetails.length) {
          let www = null;
          for (const cD of result.contactDetails) {
            if (cD.type === "Kotisivun www-osoite") {
              if (!www || www.registrationDate <= cD.registrationDate) {
                www = cD.value;
              }
            }
          }
          if (www) yritys.www = www;
        }

        return yritys;
      };
    },
    getKayttajaPostData(state) {
      return {
        email: state.kayttaja.email,
        password: state.kayttaja.salasana,
      };
    },
  },
  mutations: {
    resetData(state) {
      state.loading = false;
      state.maat = [];
      state.verkkolaskuoperaattorit = [];
      state.kayttaja = {
        email: "",
        salasana: "",
        salasanaUudelleen: "",
        palveluehdotHyvaksytty: false,
        tietosuojaselosteHyvaksytty: false,
        markkinointiViestitHyvaksytty: false,
      };
      state.paamies = new PaamiesPalveluhakemus();
      state.valittavatPalvelusopimukset = [];
      state.suomifiJwt = null;
      state.accessToken = "";
      state.refreshToken = "";
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setMaat(state, payload) {
      state.maat = payload;
    },
    setVerkkolaskuoperaattorit(state, payload) {
      state.verkkolaskuoperaattorit = payload;
    },
    setKayttaja(state, payload) {
      state.kayttaja = payload;
    },
    updateKayttaja(state, payload) {
      state.kayttaja = Object.assign(state.kayttaja, payload);
    },
    setKayttajaEmail(state, payload) {
      state.kayttaja.email = Object.assign(state.kayttaja, payload);
    },
    setPaamies(state, payload) {
      state.paamies = new PaamiesPalveluhakemus(payload);
    },
    updatePaamies(state, payload) {
      state.paamies = Object.assign(state.paamies, payload);
    },
    setPaamiesTunnus(state, payload) {
      state.paamies.tunnus = payload;
    },
    setPaamiesNimi(state, payload) {
      state.paamies.nimi = payload;
    },
    setPaamiesOsoite(state, payload) {
      state.paamies.osoite = payload;
    },
    setPaamiesPostinumero(state, payload) {
      state.paamies.postinumero = payload;
    },
    setPaamiesToimipaikka(state, payload) {
      state.paamies.toimipaikka = payload;
    },
    setPaamiesMaa(state, payload) {
      state.paamies.maa = payload;
    },
    setPaamiesPuhelin(state, payload) {
      state.paamies.puhelin = payload;
    },
    setPaamiesEmail(state, payload) {
      state.paamies.email = payload;
    },
    setPaamiesLaskutusEmail(state, payload) {
      state.paamies.laskutus_email = payload;
    },
    setPaamiesLahetystapa(state, payload) {
      state.paamies.lahetystapa = payload;
    },
    setPaamiesVerkkolaskuosoite(state, payload) {
      state.paamies.verkkolasku_osoite = payload;
    },
    setPaamiesVerkkolaskuoperaattori(state, payload) {
      state.paamies.verkkolasku_operaattori = payload;
    },
    setPaamiesWww(state, payload) {
      state.paamies.www = payload;
    },
    setPaamiesIban(state, payload) {
      state.paamies.iban = payload;
    },
    setPaamiesBic(state, payload) {
      state.paamies.bic = payload;
    },
    setPaamiesPalvelusopimus(state, payload) {
      state.paamies.palvelusopimus = payload;
    },
    setLaskutusjakso(state, payload) {
      state.paamies.laskutusjakso = payload;
    },
    setKayttajaSalasana(state, payload) {
      state.kayttaja.salasana = payload;
    },
    setKayttajaSalasanaUudelleen(state, payload) {
      state.kayttaja.salasanaUudelleen = payload;
    },
    setKayttajaPalveluehdotHyvaksytty(state, payload) {
      state.kayttaja.palveluehdotHyvaksytty = payload;
    },
    setKayttajaTietosuojaselosteHyvaksytty(state, payload) {
      state.kayttaja.tietosuojaselosteHyvaksytty = payload;
    },
    setKayttajaMarkkinointiViestitHyvaksytty(state, payload) {
      state.kayttaja.markkinointiViestitHyvaksytty = payload;
    },
    setValittavatPalvelusopimukset(state, payload) {
      state.valittavatPalvelusopimukset = payload;
    },
    setSuomifiJwt(state, payload) {
      state.suomifiJwt = payload;
    },
    setAccessToken(state, payload) {
      state.accessToken = payload;
    },
    setRefreshToken(state, payload) {
      state.refreshToken = payload;
    },
  },

  actions: {
    async haeEsitiedot({ commit }) {
      try {
        const request = await doRequestWithTimeout(
          api.Palveluhakemus,
          {
            url: "esitiedot/",
          },
          "pushRequest"
        );
        if (!request.success) throw new HttpError(request);
        commit(
          "setValittavatPalvelusopimukset",
          request.result.body.palvelusopimukset
        );
        commit("setMaat", request.result.body.maat);
      } catch (e) {
        // TODO virheilmoitus, ohjaa käyttäjä uudelleen yrittämään
        // oletusVirheenkasittely(e, 'Maavaihtoehtojen haku epäonnistui! Koeta päivittää sivu.')
      }
    },
    async haeVerkkolaskuoperaattorit({ commit }) {
      try {
        const request = await doRequestWithTimeout(
          api.Palveluhakemus,
          {
            url: "finvoice_operaattori_nimi/",
          },
          "doSingleRequest"
        );

        if (!request.success) throw new HttpError(request);

        commit("setVerkkolaskuoperaattorit", request.result.body);
      } catch (e) {
        oletusVirheenkasittely(
          e,
          "Verkkolaskuoperaattoreiden haku epäonnistui!"
        );
      }
    },
    async haeYTJsta({ commit, getters }, hakusana) {
      const query = new URLSearchParams();
      const regex = new RegExp("[0-9]{7}-[0-9]{1}", "g");
      hakusana = hakusana.trim();
      if (hakusana.match(regex)) {
        query.append("businessId", hakusana);
      }
      const request = await doRequestWithTimeout(
        apiUlkoinen.YTJ,
        {
          method: "GET",
          query: query.toString(),
        },
        "pushRequest"
      );

      if (!request.success && request.result.status !== 404) {
        throw new HttpError(request);
      }

      const tulokset = getters.kasitteleYTJTulokset(
        request.result.body.results
      );
      // Asetetaan ensimmäinen tulos päämieheksi
      if (tulokset && tulokset.length) commit("updatePaamies", tulokset[0]);
    },
    onVapaaSahkopostiosoite(_, queryParams) {
      return doRequestWithTimeout(
        api.Palveluhakemus,
        {
          url: "email/",
          query: queryParams.toString(),
        },
        "doSingleRequest"
      );
    },
    tarkistaYTunnus({ state }) {
      const queryParams = new URLSearchParams();
      queryParams.append("tunnus", state.paamies.tunnus);

      return doRequestWithTimeout(
        api.Palveluhakemus,
        {
          url: "tunnus/",
          query: queryParams.toString(),
        },
        "doSingleRequest"
      );
    },
    tarkistaPaamies({ state }) {
      const queryParams = new URLSearchParams();
      queryParams.append("tunnus", state.paamies.tunnus);

      return doRequestWithTimeout(
        api.Palveluhakemus,
        {
          url: "paamies/",
          query: queryParams.toString(),
        },
        "doSingleRequest"
      );
    },
    haeSuomiFiAuthUrl({ state }) {
      return doRequestWithTimeout(
        apiInse.Palveluhakemus,
        {
          url: "suomifi_valtuudet/url/",
          headers: {
            Authorization: "Token " + state.accessToken,
          },
        },
        "doSingleRequest"
      );
    },
    async luoTili({ state, getters }, redirectResponse) {
      // Redirectin jälkeen paamies haetaan localStoragesta, joten luokka täytyy hakea uudelleen
      const paamies = new PaamiesPalveluhakemus(state.paamies);
      const body = {
        kayttaja: getters.getKayttajaPostData,
        paamies: paamies.getPostData(),
        redirect_response: redirectResponse,
        jwt: state.suomifiJwt,
      };

      const request = await doRequestWithTimeout(
        apiInse.Palveluhakemus,
        {
          method: "POST",
          body: body,
          url: "luo_tili/",
          headers: {
            Authorization: "Token " + state.accessToken,
          },
        },
        "doSingleRequest"
      );

      if (!request.success) throw new HttpError(request);

      return request;
    },
    async lisaaEmailPostituslistalle({ state }) {
      // Hoidetaan poikkeusten käsittely actionin sisällä, jotta
      // postituslistalle lisäämisen onnistuminen ei vaikuta tilin luomiseen.
      try {
        const body = {
          email: state.kayttaja.email,
        };

        const request = await doRequestWithTimeout(
          api.Palveluhakemus,
          {
            method: "POST",
            body: body,
            url: "sahkopostilista/",
          },
          "doSingleRequest"
        );
        if (!request.success) throw new HttpError(request);

        return request;
      } catch (e) {
        sentryCaptureCustom(e);
      }
    },
    async selvitaIBANistaBIC({ state }, IBANtilinumero) {
      const BICMap = {
        FI: {
          405: "HELSFIHH",
          497: "HELSFIHH",
          470: "POPFFI22",
          471: "POPFFI22",
          472: "POPFFI22",
          473: "POPFFI22",
          474: "POPFFI22",
          475: "POPFFI22",
          476: "POPFFI22",
          477: "POPFFI22",
          478: "POPFFI22",
          479: "POPFFI22",
          400: "ITELFIHH",
          402: "ITELFIHH",
          403: "ITELFIHH",
          406: "ITELFIHH",
          407: "ITELFIHH",
          408: "ITELFIHH",
          410: "ITELFIHH",
          411: "ITELFIHH",
          412: "ITELFIHH",
          414: "ITELFIHH",
          415: "ITELFIHH",
          416: "ITELFIHH",
          417: "ITELFIHH",
          418: "ITELFIHH",
          419: "ITELFIHH",
          420: "ITELFIHH",
          421: "ITELFIHH",
          423: "ITELFIHH",
          424: "ITELFIHH",
          425: "ITELFIHH",
          426: "ITELFIHH",
          427: "ITELFIHH",
          428: "ITELFIHH",
          429: "ITELFIHH",
          430: "ITELFIHH",
          431: "ITELFIHH",
          432: "ITELFIHH",
          435: "ITELFIHH",
          436: "ITELFIHH",
          437: "ITELFIHH",
          438: "ITELFIHH",
          439: "ITELFIHH",
          440: "ITELFIHH",
          441: "ITELFIHH",
          442: "ITELFIHH",
          443: "ITELFIHH",
          444: "ITELFIHH",
          445: "ITELFIHH",
          446: "ITELFIHH",
          447: "ITELFIHH",
          448: "ITELFIHH",
          449: "ITELFIHH",
          450: "ITELFIHH",
          451: "ITELFIHH",
          452: "ITELFIHH",
          454: "ITELFIHH",
          455: "ITELFIHH",
          456: "ITELFIHH",
          457: "ITELFIHH",
          458: "ITELFIHH",
          459: "ITELFIHH",
          460: "ITELFIHH",
          461: "ITELFIHH",
          462: "ITELFIHH",
          463: "ITELFIHH",
          464: "ITELFIHH",
          483: "ITELFIHH",
          484: "ITELFIHH",
          485: "ITELFIHH",
          486: "ITELFIHH",
          487: "ITELFIHH",
          488: "ITELFIHH",
          489: "ITELFIHH",
          490: "ITELFIHH",
          491: "ITELFIHH",
          492: "ITELFIHH",
          493: "ITELFIHH",
          495: "ITELFIHH",
          496: "ITELFIHH",
          711: "BSUIFIHH",
          713: "CITIFIHX",
          714: "EVSEFIHH",
          715: "ITELFIHH",
          717: "BIGKFIH1",
          796: "NARYFIH2",
          798: "VPAYFIH2",
          799: "HOLVFIHH",
          34: "DABAFIHX",
          37: "DNBAFIHX",
          31: "HANDFIHH",
          33: "ESSEFIHH",
          36: "SBANFIHH",
          39: "SBANFIHH",
          38: "SWEDFIHH",
          6: "AABAFI22",
          4: "HELSFIHH",
          8: "DABAFIHH",
          1: "NDEAFIHH",
          2: "NDEAFIHH",
          5: "OKOYFIHH",
        },
      };
      if (!IBANtilinumero) return false;
      IBANtilinumero = IBANtilinumero.trim().replace(" ", "");
      const country = IBANtilinumero.substring(0, 2);
      const nro = IBANtilinumero.substring(4);
      let BICMapCountry = BICMap;
      if (country in BICMapCountry) {
        let BICMapArray = Object.entries(BICMapCountry[country]).sort(
          reverseSortObjectByNumberKeys
        );
        for (const [tunnus, bic] of BICMapArray) {
          if (nro.startsWith(tunnus)) {
            return bic;
          }
        }
      }
      return "";
    },
  },
};
