import BaseSelaa from "@/store/modules/baseSelaa";

export default class PaamieshallintaSelaa extends BaseSelaa {
  state() {
    return {
      ...super.state(),
      apiName: "Paamies",
      pagination: {
        page: 1,
        itemsPerPage: 50,
        sortBy: ["nimi"],
        sortDesc: [false],
      },
    };
  }
}
