var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "wrap", staticClass: "offCanvasMessage" },
    [
      _c(
        "div",
        [
          _c(
            "v-toolbar",
            { staticClass: "px-1", attrs: { color: "primary", flat: "" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { dark: true, icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close-button-click")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("clear")])],
                1
              ),
              _c("v-toolbar-title", [
                _vm._v(" " + _vm._s(_vm.viestinTiedotTeksti) + " "),
              ]),
              _c("v-spacer"),
              _c("v-tooltip", {
                attrs: { left: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ on }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.linkTooltip,
                                  expression: "linkTooltip",
                                },
                              ],
                              staticClass: "white v-btn--has-bg primary--text",
                              attrs: {
                                dark: true,
                                icon: "",
                                to: _vm.linkRoute,
                              },
                            },
                            on
                          ),
                          [_c("v-icon", [_vm._v("open_in_new")])],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function () {
                      return [_c("span", [_vm._v(_vm._s(_vm.linkTooltip))])]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "grey lighten-5 px-8" },
            [
              _vm.value.lasku
                ? _c("OffCanvasDetailsLasku", {
                    model: {
                      value: _vm.value.tapausData,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "tapausData", $$v)
                      },
                      expression: "value.tapausData",
                    },
                  })
                : _vm.value.ostolasku
                ? _c("OffCanvasDetailsOstolasku", {
                    model: {
                      value: _vm.value.tapausData,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "tapausData", $$v)
                      },
                      expression: "value.tapausData",
                    },
                  })
                : _vm.value.toimeksianto
                ? _c("OffCanvasDetailsToimeksianto", {
                    model: {
                      value: _vm.value.tapausData,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "tapausData", $$v)
                      },
                      expression: "value.tapausData",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        { ref: "content", staticClass: "px-8" },
        [
          _c("Timeline", {
            attrs: {
              title: "Viestihistoria",
              items: _vm.messages,
              "show-title": false,
              "enable-direction-toggle": false,
              "show-item-titles": false,
            },
            on: {
              "msg-mark-as-unread": function ($event) {
                return _vm.$emit("msg-mark-as-unread")
              },
              "msg-mark-as-read": function ($event) {
                return _vm.$emit("msg-mark-as-unread")
              },
            },
          }),
        ],
        1
      ),
      _vm.$permission.checkSinglePerm(
        "onlinerestapi.onlinerestapi_viesti_lahetys"
      )
        ? _c(
            "div",
            { staticClass: "sticky sticky--bottom" },
            [
              _c(
                "v-container",
                { staticClass: "white px-9" },
                [
                  _c("FormUusiViesti", {
                    attrs: { loading: _vm.uusiViestiFormLoading },
                    on: { submit: _vm.postMessage },
                    model: {
                      value: _vm.uusiViestiFormData,
                      callback: function ($$v) {
                        _vm.uusiViestiFormData = $$v
                      },
                      expression: "uusiViestiFormData",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }