<template>
  <v-toolbar color="primary" flat class="px-1">
    <v-btn :dark="true" icon @click="$emit('close-button-click')">
      <v-icon>clear</v-icon>
    </v-btn>

    <v-toolbar-title>
      {{ toolbarTitle }}
    </v-toolbar-title>

    <v-spacer />

    <v-tooltip v-if="toName && toId && tooltipTitle" left>
      <template #activator="{ on }">
        <v-btn
          v-if="typeof toId != 'undefined'"
          :dark="true"
          class="white v-btn--has-bg primary--text"
          icon
          :to="{
            name: toName,
            params: {
              id: toId,
            },
          }"
          v-on="on"
        >
          <v-icon>open_in_new</v-icon>
        </v-btn>
      </template>
      <template #default>
        <span>{{ $t("common:openSomething") }} {{ tooltipTitle }}</span>
      </template>
    </v-tooltip>
  </v-toolbar>
</template>

<script>
export default {
  props: {
    toId: {
      type: Number,
      required: false,
      default() {
        return null;
      },
    },
    toName: {
      type: String,
      required: false,
      default() {
        return "";
      },
    },
    toolbarTitle: {
      type: String,
      required: false,
      default() {
        return "";
      },
    },
    tooltipTitle: {
      type: String,
      required: false,
      default() {
        return "";
      },
    },
  },
};
</script>

<style></style>
