export const Kirjoitusmoodit = Object.freeze({
  LUOMINEN: "luominen",
  MUOKKAUS: "muokkaus",
});

export const Laskutyypit = Object.freeze({
  SOPIMUS: "sopimus",
  LASKU: "lasku",
  OSTOLASKU: "ostolasku",
});

// Palvelusopimustilaukset mahdolliset tilat
export const Tilaustilat = Object.freeze({
  AKTIIVINEN: "A",
  PERUUTETTU: "X",
  PAATTYNYT: "P",
});

// 25MB
export const MaksimiUploadTiedostoKoko = 26214400;

export const MaksimiTuoteId = 9999999999;

// mitä kenttiä Asiakkaalle voi antaa csv-tiedostossa
export const asiakasKentat = [
  "nimi",
  "emailosoite",
  "kieli",
  "lahetystapa",
  "nro",
  "bic",
  "iban",
  "puhelin",
  "tunnus",
  "tyyppi",
  "verkkolaskuosoite",
  "operaattori",
  "kotipaikka",
  "oletusmaksuehto",
  "oletustoimitustapa",
  "oletusvapaateksti",
  "oletusviitteemme",
  "oletusviitteenne",
  /* postiosoitteelle */
  "osoite",
  "postinumero",
  "toimipaikka",
  "maa",
];

// mitä kenttiä Tuotteelle voi antaa csv-tiedostossa
export const tuoteKentat = [
  "koodi",
  "paamies",
  "paamies_id",
  "tuoteryhma",
  "selite",
  "vapaateksti",
  "ahinta",
  "sisalv",
  "laatu",
  "alvtunnus",
  "valuutta",
  "hankintahinta",
  "varastotilanne",
];
