<template>
  <label class="formTable__label">
    <slot />
  </label>
</template>

<script>
export default {
  name: "FormTableLabel",
};
</script>

<style lang="scss" scoped>
.formTable__label {
  // text-transform: uppercase;
  font-weight: bold;
}
</style>
