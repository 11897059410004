import _ from "lodash";
import * as Sentry from "@sentry/vue";
import store from "@/store";
import { TYPE as TOAST_TYPE } from "vue-toastification";
import { HttpError, TimeoutError } from "@/utils/errors";
import { t } from "./lang";
import { format, parseISO } from "date-fns";
import { fi } from "date-fns/locale";
import { parsePhoneNumber, isValidPhoneNumber } from "libphonenumber-js";
import { Lasku } from "@/class/Lasku";
import formatSum from "@/utils/filters/formatSum";

export const suodataTyhjanMerkkijononSisaltavatKentat = function (obj) {
  return _.pickBy(obj, function (value) {
    return value !== "";
  });
};

export const oletusVirheenkasittely = (e, extraData = OLETUSVIRHEVIESTI) => {
  if (!onkoUiVirhe(e)) {
    if (typeof extraData === "string") {
      const virheviesti = extraData;
      sentryCaptureCustom(e, { virheviesti });
      naytaVirheilmoitus(virheviesti);
    } else if (typeof extraData === "object") {
      sentryCaptureCustom(e, extraData);
      if (extraData.virheviesti) naytaVirheilmoitus(extraData.virheviesti);
    } else {
      throw new Error(
        t("common:errors.otherParameterMustBeEitherStringOrObjectError")
      );
    }
  }
};

export const OLETUSVIRHEVIESTI = t("common:errors.functionFailedTryAgain");

export const onkoUiVirhe = (e) => {
  return (
    e.name === HttpError.name &&
    e.request.result &&
    e.request.result.body.ui_virhe
  );
};

/** Mukautettu poikkeus, jolla saadaan lisäinfoa poikkeuksiin. Koska extra-objektin maksimikoko
 * on Sentryssä 200kB, on objektiin lisättävän datan määrää rajoitettu.
 * */
export const sentryCaptureCustom = function (e, extraData = {}, tags = {}) {
  try {
    extraData.username = store.state.user.username;

    if (e.name === HttpError.name || e.request) {
      const request = e.request;
      [extraData, tags] = kasitteleHttpError(extraData, tags, request);
    } else if (e.name === TimeoutError.name) {
      extraData = kasitteleTimeoutError(extraData, e.promise);
    }

    Sentry.captureException(e, {
      extra: extraData,
      tags,
    });
  } catch (err) {
    console.log("!!! sentryCaptureCustom: virhe poikkeuksen luonnissa");

    const alkuperainenVirhe = korvaaErrorObjektilla(e);
    e = err;
    extraData = {
      huom: "sentryCaptureCustom epäonnistui",
      alkuperainenVirhe,
    };

    Sentry.captureException(e, {
      extra: extraData,
    });
  } finally {
    console.log("SentryException:", e, "extraData:", extraData, "tags:", tags);
  }
};

const kasitteleHttpError = (extraData, tags, request) => {
  tags.requestUrl = request.payload.url;
  tags.requestMethod = request.payload.method;
  tags.responseStatus = request.result.status;

  let responseDataSize = 0;
  let requestContentSize = 0;

  try {
    responseDataSize = roughSizeOfObject(request.result.body);
    // extra-objektin maksimikoko Sentryssä on 200KB
    if (responseDataSize < 200000) {
      extraData.responseData = request.result.body;
    }
  } catch (error) {
    console.log(
      "!!! sentryCaptureCustom: response_datan käsittely epäonnistui"
    );
    console.log(error);
  }

  try {
    requestContentSize = roughSizeOfObject(request.payload.body);

    if (responseDataSize + requestContentSize < 200000) {
      extraData.requestContent = request.payload.body;
    }
  } catch (error) {
    console.log(
      "!!! sentryCaptureCustom: request contentin käsittely epäonnistui"
    );
    console.log(error);
  }

  return [extraData, tags];
};

const kasitteleTimeoutError = (extraData, aikakatkaistuPromise) => {
  if (aikakatkaistuPromise.payload) {
    extraData.headers = aikakatkaistuPromise.payload.headers;
    extraData.url = aikakatkaistuPromise.payload.url;
    extraData.method = aikakatkaistuPromise.payload.method;
    extraData.body = aikakatkaistuPromise.payload.body;
  }

  return extraData;
};

// https://stackoverflow.com/questions/1248302/how-to-get-the-size-of-a-javascript-object
export const roughSizeOfObject = (object) => {
  var objectList = [];
  var stack = [object];
  var bytes = 0;

  while (stack.length) {
    var value = stack.pop();

    if (typeof value === "boolean") {
      bytes += 4;
    } else if (typeof value === "string") {
      bytes += value.length * 2;
    } else if (typeof value === "number") {
      bytes += 8;
    } else if (typeof value === "object" && objectList.indexOf(value) === -1) {
      objectList.push(value);

      for (var i in value) {
        stack.push(value[i]);
      }
    }
  }
  return bytes;
};

export const korvaaErrorObjektilla = function (arvo) {
  if (arvo instanceof Error) {
    var errorObj = {};

    Object.getOwnPropertyNames(arvo).forEach(function (avain) {
      errorObj[avain] = arvo[avain];
    });

    // Pudotetaan funktiot pois propertyista
    return JSON.parse(JSON.stringify(errorObj));
  }

  return arvo;
};

export const naytaInfoilmoitus = (viesti, config = {}) => {
  const defaultConfig = {
    type: TOAST_TYPE.INFO,
    position: "top-center",
  };

  store.commit("notifications/naytaIlmoitus", {
    text: viesti,
    config: Object.assign(defaultConfig, config),
  });
};

export const naytaOnnistumisilmoitus = (viesti, config = {}) => {
  const defaultConfig = {
    type: TOAST_TYPE.SUCCESS,
    position: "top-center",
  };

  store.commit("notifications/naytaIlmoitus", {
    text: viesti,
    config: Object.assign(defaultConfig, config),
  });
};

export const naytaVaroitusilmoitus = (viesti, config = {}) => {
  const defaultConfig = {
    type: TOAST_TYPE.WARNING,
    position: "top-center",
  };

  store.commit("notifications/naytaIlmoitus", {
    text: viesti,
    config: Object.assign(defaultConfig, config),
  });
};

export const naytaVirheilmoitus = (virheviesti, config = {}) => {
  const defaultConfig = {
    type: TOAST_TYPE.ERROR,
    position: "top-center",
  };

  store.commit("notifications/naytaIlmoitus", {
    text: virheviesti,
    config: Object.assign(defaultConfig, config),
  });
};

export const naytaDjangonVirheet = (response) => {
  const virheviesti = palautaDjangonVirheet(response);
  naytaVirheilmoitus(virheviesti);
};

export const palautaDjangonVirheet = (response) => {
  // ottaa sisään responsen ja etsii ensimmäisen objektin jonka luomisessa oli virhe ja näyttää virheet
  let virhe;

  if (Array.isArray(response.result.body)) {
    virhe = response.result.body.find((rivi) => Object.keys(rivi).length > 0);
  } else {
    virhe = response.result.body;
  }

  if (Object.keys(virhe).includes("non_field_errors")) {
    // non_field_errors näytetään jo
    return;
  }

  const virheviesti = Object.entries(virhe).reduce((edellinen, seuraava) => {
    const kentta = seuraava[0];
    const virhe = Array.isArray(seuraava[1]) ? seuraava[1][0] : "Virhe";
    return `${edellinen}${kentta}: ${virhe}\n`;
  }, "");
  return virheviesti;
};

export const doRequestWithTimeout = async (
  api,
  options = {},
  func = "doRequest",
  timeout = 45000
) => {
  let completedRequest = null;
  try {
    completedRequest = await promiseTimeout({
      timeout,
      promise: api[func](options),
    });

    return completedRequest;
  } catch (e) {
    // Apicasen requestin cancel-metodi on rikki ja heittää poikkeuksen. Tyhjennetään jono käsin, koska
    // muuten apicase jää odottamaan pyyntöä joka ei koskaan tule valmiiksi
    if (e.message === TimeoutError.TIMEOUT_VIESTI) api.queue = [];

    throw e;
  }
};

/**
 * Ratkaisun lähde: https://italonascimento.github.io/applying-a-timeout-to-your-promises/
 * */
export const promiseTimeout = function (
  config = {
    timeout: 30000,
    promise: null,
  }
) {
  // Create a promise that rejects in <config.timeout> milliseconds
  const timeout = new Promise((resolve, reject) => {
    const id = setTimeout(() => {
      clearTimeout(id);
      reject(new TimeoutError(config.promise));
    }, config.timeout);
  });

  // Returns a race between our timeout and the passed in promise
  return Promise.race([config.promise, timeout]);
};

export const onkoLahetystapaDisabloitu = (lahetystapa, asiakas) => {
  let disabled = false;

  switch (lahetystapa) {
    case "verkkolasku":
    case "verkkolasku_b2c":
      if (
        !asiakas.verkkolaskuosoite ||
        !asiakas.verkkolaskuosoite.verkkolaskuosoite ||
        lahetystapa.disabled
      ) {
        disabled = true;
      }
      break;
    case "email":
      if (
        !(asiakas.emailosoite && asiakas.emailosoite.email) &&
        !(
          asiakas.emailosoite_set &&
          asiakas.emailosoite_set.length &&
          asiakas.emailosoite_set[0].email
        )
      ) {
        disabled = true;
      }
      break;
    default:
      disabled = false;
  }

  return disabled;
};

export const rivitaEmail = (email) => {
  if (email.length < 24) return email;

  const emailPuolet = email.split("@");
  const alunOsat = splitStrIntoEqualParts(emailPuolet[0]);
  let alkupuoli = "";
  for (const osa of alunOsat) {
    alkupuoli += osa + "\n";
  }
  const rivitettyEmail = alkupuoli + "@" + emailPuolet[1];

  return rivitettyEmail;
};

export const splitStrIntoEqualParts = (string, threshold = 24) => {
  const divider = Math.ceil(string.length / threshold);
  const partLength = Math.ceil(string.length / divider);
  const regex = new RegExp(".{1," + partLength + "}", "g");

  return string.match(regex);
};

/**
 * config = {
 *  fileData: <http-vastauksen body, joka muutetaan blobiksi>
 *  blob: <tiedosto blobina>
 *  filename: <annettu tiedoston nimi>,
 *  headers: <http-vastauksen otsakkeet, joista päätellään tiedoston nimi,
 *    kun Content-Disposition on saatavilla>,
 * }
 */
export const naytaTiedostonlataamisdialog = (config = {}) => {
  let disposition = null;
  let contentType = null;
  if (config.headers) {
    disposition =
      config.headers["content-disposition"] ||
      config.headers["Content-Disposition"];
    contentType =
      config.headers["content-type"] || config.headers["Content-Type"];
  }
  const filenameDispositionista =
    parsiTiedostonNimiContentDispositionista(disposition);

  if (filenameDispositionista) {
    config.filename = filenameDispositionista;
  } else if (config.filename && !onkoNimessaTiedostopaate(config.filename)) {
    config.filename = paatteleJaLisaaTiedostopaate(config.filename);
  } else {
    config.filename = "Tiedosto";
  }

  let blob = null;
  if (config.blob) {
    blob = config.blob;
  } else if (contentType) {
    blob = new Blob([config.fileData], { type: contentType });
  } else {
    blob = new Blob([config.fileData]);
  }
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = config.filename;
  link.click();
  URL.revokeObjectURL(link.href);
  return config.filename;
};

export const onkoNimessaTiedostopaate = (nimi) => {
  const tiedostopaateRegex = /\.([a-zA-Z]){2,4}$/;
  return tiedostopaateRegex.exec(nimi);
};

export const paatteleJaLisaaTiedostopaate = (filename) => {
  if (filename.toLowerCase().includes("csv")) {
    filename += ".csv";
  } else if (filename.toLowerCase().includes("pdf")) {
    filename += ".pdf";
  } else if (filename.toLowerCase().includes("excel")) {
    filename += ".xls";
  } else if (filename.toLowerCase().includes("xml")) {
    filename += ".xml";
  } else if (filename.toLowerCase().includes("jpg")) {
    filename += ".jpg";
  } else if (filename.toLowerCase().includes("jpeg")) {
    filename += ".jpeg";
  } else if (filename.toLowerCase().includes("png")) {
    filename += ".png";
  }

  return filename;
};

export const parsiTiedostonNimiContentDispositionista = (disposition) => {
  if (!disposition) return null;
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(disposition);
  if (matches !== null && matches[1]) {
    return matches[1].replace(/['"]/g, "");
  } else {
    return null;
  }
};

/*
Image-tyyppisten tiedostojen enkoodaus menee ilmeisesti Apicasen käsittelyn takia pieleen.
Ongelman voi kiertää käyttämällä fetchia. Palauttaa Promisen.
*/
export const haeTiedostoFetchilla = (url, options = {}) => {
  const defaultOptions = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    },
  };
  options = Object.assign(defaultOptions, options);
  return fetch(url, options);
};

export const laskeDesimaalipaikat = (arvo) => {
  if (arvo && typeof arvo === "string") {
    arvo = Number(arvo.replace(",", "."));
  }
  if (arvo === null || arvo === undefined || Math.floor(arvo) === arvo)
    return 0;

  const str = arvo.toString();
  if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
    const itseisarvo = str.split("-")[1];
    return itseisarvo.split(".")[1].length || 0;
  } else if (str.indexOf(".") !== -1) {
    return str.split(".")[1].length || 0;
  } else {
    return 0;
  }
};

export function muotoileDesimaaliluku(arvo, pakotaDesimaalipaikat = false) {
  const kasiteltyArvo =
    typeof arvo === "string" ? arvo.replace(",", ".") : arvo;
  const liukuluku = parseFloat(kasiteltyArvo);

  if (isNaN(liukuluku)) {
    return ""; // Oletetaan että arvo on string
  } else if (pakotaDesimaalipaikat) {
    return liukuluku.toLocaleString(undefined, { minimumFractionDigits: 2 });
  } else {
    return liukuluku.toLocaleString();
  }
}

/**
 * Ratkaisussa sovellettu useita vastauksia:
 * https://stackoverflow.com/questions/8903854/check-image-width-and-height-before-upload-with-javascript
 */
export const haeKuvatiedostonUlottuvuudet = (tiedosto) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(tiedosto);
    reader.onload = function (e) {
      const image = new Image();

      // Set the Base64 string returned from FileReader as source.
      image.src = e.target.result;

      image.onload = function () {
        // Natural size is the actual image size regardless of rendering.
        // The 'normal' `width`/`height` are for the **rendered** size.
        const width = image.naturalWidth;
        const height = image.naturalHeight;
        resolve({ width, height });
      };

      image.onerror = reject;
    };

    reader.onerror = reject;
  });
};

export const isNumeric = (value) => {
  if (value === 0) return true;
  if (!value) return false;
  if (Array.isArray(value)) return false;
  return !isNaN(value);
};

// Perustuu vastaukseen https://stackoverflow.com/a/29672957/10207153
export const paatteleTiedostonMimetype = async (blob) => {
  let type = "";
  if (window.Blob && window.Blob.prototype.arrayBuffer) {
    const buffer = await blob.arrayBuffer();
    var arr = new Uint8Array(buffer).subarray(0, 4);
    var header = "";
    for (var i = 0; i < arr.length; i++) {
      header += arr[i].toString(16);
    }

    // Check the file signature against known types
    switch (header) {
      case "89504e47":
        type = "image/png";
        break;
      case "47494638":
        type = "image/gif";
        break;
      case "ffd8ffe0":
      case "ffd8ffe1":
      case "ffd8ffe2":
      case "ffd8ffe3":
      case "ffd8ffe8":
        type = "image/jpeg";
        break;
      default:
        type = blob.type;
        break;
    }

    return type;
  } else {
    // Ei pystytä päättelemään
    return "tuntematon";
  }
};

export function onkoModuuliKaytossa(moduulinNimi) {
  const kaytossaOlevatRoutet = process.env.VUE_APP_ROUTES.split(",");
  return (
    kaytossaOlevatRoutet.includes("*") ||
    kaytossaOlevatRoutet.includes(moduulinNimi)
  );
}

// https://date-fns.org/v2.30.0/docs/I18n
const locales = { fi };
export const dateFnsFormat = (date, formatStr = "d.M.yyyy") => {
  if (!date) {
    return "";
  }
  if (typeof date === "string" || date instanceof String) {
    date = parseISO(date);
  }
  return format(date, formatStr, {
    locale: locales[localStorage.getItem("lngI18next") || fi],
  });
};

export function showPhoneNumber(phoneNumber) {
  if (isValidPhoneNumber(phoneNumber)) {
    return parsePhoneNumber(phoneNumber).formatInternational();
  } else {
    return "-";
  }
}

export function lahetystavatHintoineen(paamies, maa = "FI") {
  if (!paamies.porrashinnasto_set?.length) {
    // Ei aseteta hintoja jos hinnastoa ei löydy
    return Lasku.LAHETYSTAVAT;
  }
  const lahetystavatHintoineen = [];

  Lasku.LAHETYSTAVAT.map((lahetystapa) => {
    const lt = Object.assign({}, lahetystapa);
    lt.label = t("common:invoiceSend.sendMethods." + lahetystapa.value);

    let hinnasto = null;
    // Tarkistetaan onko ulkomaan tai kotimaan osoitteelle olemassa eri hinta
    if (maa !== "FI" && lahetystapa.ulkomaahinnastokoodi) {
      hinnasto = _.find(paamies.porrashinnasto_set, [
        "koodi",
        lahetystapa.ulkomaahinnastokoodi,
      ]);
    } else if (lahetystapa.kotimaahinnastokoodi) {
      hinnasto = _.find(paamies.porrashinnasto_set, [
        "koodi",
        lahetystapa.kotimaahinnastokoodi,
      ]);
    }
    if (!hinnasto) {
      hinnasto = _.find(paamies.porrashinnasto_set, [
        "koodi",
        lahetystapa.hinnastokoodi,
      ]);
    }

    const hinta = hinnasto ? formatSum(hinnasto.ahinta) : formatSum(0);
    lt.label = lahetystapa.label + " " + hinta;
    lahetystavatHintoineen.push(lt);
  });
  return lahetystavatHintoineen;
}
