var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "formToimitusosoite",
      on: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _c("v-text-field", {
        staticClass: "mt-4",
        attrs: { label: "Saaja" },
        model: {
          value: _vm.toimitusosoite.saaja,
          callback: function ($$v) {
            _vm.$set(_vm.toimitusosoite, "saaja", $$v)
          },
          expression: "toimitusosoite.saaja",
        },
      }),
      _c("v-text-field", {
        attrs: {
          label: "Lähiosoite",
          rules: [_vm.toimitusosoiteKenttienPakollisuus],
        },
        model: {
          value: _vm.toimitusosoite.osoite,
          callback: function ($$v) {
            _vm.$set(_vm.toimitusosoite, "osoite", $$v)
          },
          expression: "toimitusosoite.osoite",
        },
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { md: "5" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Postinumero",
                  rules: [_vm.toimitusosoiteKenttienPakollisuus],
                },
                model: {
                  value: _vm.toimitusosoite.numero,
                  callback: function ($$v) {
                    _vm.$set(_vm.toimitusosoite, "numero", $$v)
                  },
                  expression: "toimitusosoite.numero",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { md: "7" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Toimipaikka",
                  rules: [_vm.toimitusosoiteKenttienPakollisuus],
                },
                model: {
                  value: _vm.toimitusosoite.toimipaikka,
                  callback: function ($$v) {
                    _vm.$set(_vm.toimitusosoite, "toimipaikka", $$v)
                  },
                  expression: "toimitusosoite.toimipaikka",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-select", {
        attrs: {
          label: "Maa",
          items: _vm.maat,
          "item-value": "value",
          "item-text": "selite",
          "menu-props": { zIndex: "999", auto: true },
        },
        model: {
          value: _vm.toimitusosoite.maa,
          callback: function ($$v) {
            _vm.$set(_vm.toimitusosoite, "maa", $$v)
          },
          expression: "toimitusosoite.maa",
        },
      }),
      _c(
        "v-row",
        { staticClass: "mt-4 pa-3", attrs: { justify: "end" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "mr-4",
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v("Peruuta")]
          ),
          _c(
            "v-btn",
            {
              attrs: { color: "action" },
              on: { click: _vm.lisaaToimitusosoiteLaskulle },
            },
            [_vm._v(" Lisää laskulle ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }