var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c("v-select", {
        staticClass: "required",
        attrs: {
          rules: [_vm.$validationRules.required],
          items: _vm.siirtoVaihtoehdot,
          label: "Maksuajan lisäys päivissä",
          "item-value": "value",
          "item-text": "selite",
          "menu-props": { nudgeBottom: "40" },
          "z-index": "999",
        },
        model: {
          value: _vm.value.maksuaika,
          callback: function ($$v) {
            _vm.$set(_vm.value, "maksuaika", $$v)
          },
          expression: "value.maksuaika",
        },
      }),
      _c("PeruutaHyvaksyPainikerivi", {
        attrs: {
          "submit-disabled": !_vm.valid || _vm.loading,
          "submit-loading": _vm.loading,
        },
        on: {
          close: function ($event) {
            return _vm.$emit("close")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }