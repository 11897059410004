var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-toolbar",
    { staticClass: "grey lighten-4 my-4 px-2", attrs: { flat: "" } },
    [
      _vm._t("toolbar-vasen"),
      _vm.hakutulokset
        ? [
            _c("DenseToggleButton", { staticClass: "mr-2" }),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.loadingSummary,
                    expression: "!loadingSummary",
                  },
                ],
                staticClass: "ma-0",
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("searchFoundTotal")) +
                    " " +
                    _vm._s(_vm.itemsTotal) +
                    " " +
                    _vm._s(_vm.itemPartitiivi) +
                    ". "
                ),
              ]
            ),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.loadingSummary,
                    expression: "loadingSummary",
                  },
                ],
                staticClass: "ma-0",
              },
              [_vm._v(" " + _vm._s(_vm.$t("loadingSummary")) + " ")]
            ),
            _c("v-spacer"),
            _c("Pagination", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.loadingSummary,
                  expression: "!loadingSummary",
                },
              ],
              attrs: {
                length: _vm.pagesTotal,
                disabled: _vm.loading,
                "total-visible": _vm.$vuetify.breakpoint.mdAndDown ? 3 : 7,
              },
              model: {
                value: _vm.pagination.page,
                callback: function ($$v) {
                  _vm.$set(_vm.pagination, "page", $$v)
                },
                expression: "pagination.page",
              },
            }),
            _c("PaginationHyppaaAutocomplete", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.pagesTotal > 10,
                  expression: "pagesTotal > 10",
                },
              ],
              staticClass: "m-2",
              attrs: { pages: _vm.pagesTotal },
              on: { "autocomplete-input": _vm.hyppaaSivulle },
            }),
          ]
        : _vm._e(),
      _c("v-spacer"),
      _vm._t("btn-vasen"),
      _vm._t("default", function () {
        return [
          _vm._t("tuo-aineistoa-btn", function () {
            return [
              _vm.tuoAineistoaBtnConfig
                ? _c(
                    "ViewSelaaToolbarBtn",
                    {
                      attrs: {
                        icon: "save_alt",
                        color: "primary",
                        click: _vm.tuoAineistoaBtnConfig.click,
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.tuoAineistoaBtnConfig.text) + " ")]
                  )
                : _vm._e(),
            ]
          }),
          _vm._t("lataa-csv-btn", function () {
            return [
              _vm.psizeKaikkiCsvHaku > 0
                ? _c(
                    "ViewSelaaToolbarBtn",
                    {
                      attrs: {
                        icon: "download",
                        click: _vm.haeCsvKaikkiRivit,
                        disabled: _vm.liikaaRiveja,
                        loading: _vm.loadingKaikkiRivit,
                        "tooltip-teksti": _vm.csvKaikkiTooltipTeksti,
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("loadCsv")) + " ")]
                  )
                : _vm._e(),
            ]
          }),
          _vm._t("alt-selaa-btn", function () {
            return [
              _vm.altSelaaBtnConfig && _vm.altSelaaBtnPermission
                ? _c(
                    "ViewSelaaToolbarBtn",
                    {
                      attrs: {
                        to: { name: _vm.altSelaaBtnConfig.toName },
                        icon: "view_list",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("browse")) +
                          " " +
                          _vm._s(_vm.altSelaaBtnConfig.itemPartitiivi) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          }),
          _vm._t("btn-keskella"),
          _vm._t("uusi-btn", function () {
            return [
              _vm.uusiBtnConfig && _vm.uusiBtnPermission
                ? _c(
                    "ViewSelaaToolbarBtn",
                    {
                      attrs: {
                        to: _vm.uusiBtnConfig.toName
                          ? { name: _vm.uusiBtnConfig.toName }
                          : null,
                        color: "action",
                        icon: "library_add",
                        click: _vm.uusiBtnConfig.click,
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("new")) +
                          " " +
                          _vm._s(_vm.uusiBtnConfig.itemName) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          }),
        ]
      }),
      _vm._t("btn-oikea"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }