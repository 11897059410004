import api from "@/plugins/api";
import { HttpError } from "@/utils/errors";
import { doRequestWithTimeout, sentryCaptureCustom } from "@/utils/misc";
import { Tuote } from "@/class/Tuoterekisteri";
import permission from "@/plugins/permission";

export default {
  namespaced: true,
  state: {
    ALVTunnusItems: [],
    kielet: [],
    vastapuolenTyypit: [],
    maat: [],
    verkkolaskuoperaattorit: [],
    // Asetetaan oletuksena ostotiliItemsin sisällöksi null, jonka perusteella päätellään onko
    // käyttäjän päämiehiin liittyviä ostotilejä haettu. Tällä vältetään turhat kyselyt jos tilejä on 0.
    ostotiliItems: [null],
    tiliointitiliItems: [],
    muokattavaTuote: new Tuote(),
    laskulisatietoAvaimet: {
      keys: [],
    },
    laskuMyyntiData: [],
  },
  getters: {
    tiliointitiliItems: (state) => {
      return state.tiliointitiliItems;
    },
    laskuMyyntiData: (state) => {
      return state.laskuMyyntiData;
    },
    haeTiliointitili: (state) => (id) => {
      return state.tiliointitiliItems.find((tili) => tili.id === id);
    },
    getMuokattavaTuote: (state) => {
      return state.muokattavaTuote;
    },
  },
  mutations: {
    setALVTunnusItems(state, payload) {
      state.ALVTunnusItems = payload;
    },
    setKielet(state, payload) {
      state.kielet = payload;
    },
    setVastapuolenTyypit(state, payload) {
      state.vastapuolenTyypit = payload;
    },
    setMaat(state, payload) {
      state.maat = payload;
    },
    setVerkkolaskuoperaattorit(state, payload) {
      state.verkkolaskuoperaattorit = payload;
    },
    setOstotiliItems(state, payload) {
      state.ostotiliItems = payload;
    },
    setTiliointitiliItems(state, payload) {
      state.tiliointitiliItems = payload;
    },
    setLaskuMyyntiData(state, payload) {
      state.laskuMyyntiData = payload;
    },
    setMuokattavaTuote(state, payload) {
      state.muokattavaTuote = payload;
    },
    setLaskulisatietoAvaimet(state, payload) {
      state.laskulisatietoAvaimet = payload;
    },
  },
  actions: {
    async haeALVTunnukset({ commit }) {
      try {
        const request = await doRequestWithTimeout(api.ALVTunnus, { url: "" });
        if (!request.success) throw new HttpError(request);
        const ALVTunnusItems = request.result.body;
        commit("setALVTunnusItems", ALVTunnusItems);
      } catch (e) {
        sentryCaptureCustom(e);
        return [];
      }
    },
    async haeVastapuolenKenttienValinnat({ commit }) {
      try {
        const request = await doRequestWithTimeout(api.Yleinen, {
          url: "vastapuoli/kenttien_valinnat/",
        });
        if (!request.success) throw new HttpError(request);
        const kielet = request.result.body.kielet;

        const vastapuolenTyypit = request.result.body.tyypit;
        // Laitetaan tyypit kivaan järjestykseen
        let tyyppiItems = [];
        if (vastapuolenTyypit) {
          tyyppiItems = [...vastapuolenTyypit].sort((a, b) => {
            if (a.selite < b.selite) {
              return -1;
            } else if (a.selite > b.selite) {
              return 1;
            } else {
              return 0;
            }
          });
        }

        const maat = request.result.body.maat;

        commit("setKielet", kielet);
        commit("setVastapuolenTyypit", tyyppiItems);
        commit("setMaat", maat);
      } catch (e) {
        sentryCaptureCustom(e);
        return [];
      }
    },
    async haeVerkkolaskuoperaattorit({ commit }) {
      try {
        const request = await doRequestWithTimeout(api.Finvoice, {
          url: "finvoice_operaattori_nimi/",
        });
        if (!request.success) throw new HttpError(request);
        const verkkolaskuoperaattorit = request.result.body;
        commit("setVerkkolaskuoperaattorit", verkkolaskuoperaattorit);
      } catch (e) {
        sentryCaptureCustom(e);
        return [];
      }
    },
    async haeOstotilit({ commit }) {
      try {
        const request = await doRequestWithTimeout(api.Ostotili, { url: "" });
        if (!request.success) throw new HttpError(request);
        const ostotiliItems = request.result.body.results;
        commit("setOstotiliItems", ostotiliItems);
      } catch (e) {
        sentryCaptureCustom(e);
        return [];
      }
    },
    async haeTiliointitilit({ commit }) {
      try {
        const query = new URLSearchParams();
        query.append("psize", 99999);
        const request = await doRequestWithTimeout(api.Tiliointitilit, {
          query: query.toString(),
          url: "",
        });
        if (!request.success) throw new HttpError(request);
        const tiliointitiliItems = request.result.body.results;
        commit("setTiliointitiliItems", tiliointitiliItems);
      } catch (e) {
        sentryCaptureCustom(e);
        return [];
      }
    },
    async haeAlustustiedot({ dispatch }) {
      const requests = [
        dispatch("haeALVTunnukset"),
        dispatch("haeVerkkolaskuoperaattorit"),
        dispatch("haeVastapuolenKenttienValinnat"),
      ];
      if (permission.checkSinglePerm("ostolasku.onlinerestapi_ostolasku")) {
        requests.push(dispatch("haeOstotilit"));
        requests.push(dispatch("haeTiliointitilit"));
      }
      await Promise.allSettled(requests);
    },
  },
};
