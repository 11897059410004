import Vue from "vue";

const ibantools = require("ibantools");
import { laskeDesimaalipaikat } from "@/utils/misc";
import { isValid } from "date-fns";

const validationRules = {
  required: (v) => !!v || "Pakollinen tieto puuttuu",
  requiredLoose: (v) =>
    (v !== null && v !== undefined && v !== "") || "Pakollinen tieto puuttuu",
  email: (v) =>
    /.+@.+\..{2,}/.test(v) ||
    v === "" ||
    "Tarkista sähköpostiosoitteen muotoilu",
  emailArray: (v) =>
    (Array.isArray(v) ? v.every((i) => /.+@.+\..{2,}/.test(i)) : v === "") ||
    "Tarkista sähköpostiosoitteen muotoilu",
  zeroOrBigger: (v) => v >= 0 || "Arvon tulee olla 0 tai suurempi",
  biggerThanZero: (v) => v > 0 || "Arvon tulee olla suurempi kuin 0",
  smallerThanOrEqualTo: (v, threshold) =>
    v <= threshold || `Arvo ei voi olla suurempi kuin ${threshold}`,
  requiredLength: (v, threshold = 1) =>
    v.length >= threshold || `Syötä vähintään ${threshold} merkkiä`,
  requiredArrayLength: (v, threshold = 1) =>
    (Array.isArray(v) && v.length >= threshold) ||
    `Syötä vähintään ${threshold}`,
  requiredObject: (v) =>
    (v && Object.keys(v).length !== 0) || "Pakollinen tieto puuttuu",
  tooManyCharacters: (v, threshold) =>
    v <= threshold || `Sallittu merkkimäärä (${threshold}) ylitetty`,
  numberOrEmpty: (v) =>
    v === undefined || !isNaN(v) || "Syötä numero tai jätä tyhjäksi",
  isInteger: (v) =>
    Number.isInteger(Number(v)) || !v || "Arvon tulee olla kokonaisluku",
  isValidIBAN: (v) =>
    ibantools.isValidIBAN(typeof v === "string" ? v.replace(/\s+/g, "") : v) ||
    "Epäkelpo IBAN",
  isValidBIC: (v) => ibantools.isValidBIC(v) || "Epäkelpo BIC",
  maxDecimalPlaces: (v, paikat) => {
    return (
      laskeDesimaalipaikat(v) <= paikat ||
      `Anna korkeintaan ${paikat} desimaalipaikkaa`
    );
  },
  maxDecimalPlacesOrEmpty: (v, paikat) => {
    return (
      v === undefined ||
      v === null ||
      laskeDesimaalipaikat(v) <= paikat ||
      `Anna korkeintaan ${paikat} desimaalipaikkaa`
    );
  },
  numberOrEmptyWithDecimal: (v) =>
    v === undefined ||
    v === null ||
    (typeof v === "string" && !isNaN(v.replace(",", "."))) ||
    "Syötä numero tai jätä tyhjäksi",
  zeroOrBiggerWithDecimal: (v) =>
    v === undefined ||
    v === null ||
    (typeof v === "string" && v.replace(",", ".") >= 0) ||
    (typeof v !== "string" && v >= 0) ||
    `Arvon ${v} tulee olla 0 tai suurempi`,
  smallerThanOrEqualToWithDecimal: (v, threshold) =>
    v === undefined ||
    v === null ||
    (typeof v === "string" && v.replace(",", ".") <= threshold) ||
    `Arvo ei voi olla suurempi kuin ${threshold}`,
  isNumber: (v) => typeof v === "string" || "Anna numeerinen arvo.",
  notIsNaN: (v) => !isNaN(v) || "Anna numeerinen arvo.",
  onkoYtunnus: (v) =>
    /^[\d]{7}-[\d]$/.test(v) || v === "" || "Tarkista Y-tunnus",
  onkoHtunnus: (v) => {
    if (v === null || v.length < 11) {
      return "Tarkista Henkilötunnus";
    } else {
      // Check for Date
      const d = Number(v.slice(0, 2));
      const m = Number(v.slice(2, 4));
      const y = Number(v.slice(4, 6));
      const matchDate = isValid(new Date(y, m, d));

      // Check for X
      const X = v.slice(6, 7);
      const possibleX18 = ["+"];
      const possibleX19 = ["-", "Y", "X", "W", "V", "U"];
      const possibleX20 = ["A", "B", "C", "D", "E", "F"];
      const matchX =
        possibleX18.includes(X) ||
        possibleX19.includes(X) ||
        possibleX20.includes(X);

      // Check for N
      const N = Number(v.slice(7, 10));
      const matchN = N >= 2 && N <= 899;

      // Check for T
      const T = v.slice(10, 11);
      const regex = /([0-9]|(?![GIOQ])[A-Y])/;
      const matchT = regex.test(T);

      // Check for full match
      if (matchDate && matchX && matchN && matchT) {
        return true;
      } else {
        return "Tarkista Henkilötunnus";
      }
    }
  },
  positiveUnitPrice: (v) =>
    v >= 0 ||
    "À-hinta ei voi olla negatiivinen. Jos haluat tehdä hyvitysrivin, käytä negatiivista määrää ja positiivista à-hintaa.",
};

// Create Vue plugin
const plugin = {
  install() {
    Vue.validationRules = validationRules;
    Vue.prototype.$validationRules = validationRules;
  },
};

Vue.use(plugin);

export default validationRules;
