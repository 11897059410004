var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-toolbar",
    {
      staticClass: "sticky",
      attrs: { color: "primary", flat: "", tabs: "", "pl-6": "" },
    },
    [
      _c(
        "v-btn",
        {
          attrs: { dark: true, icon: "" },
          on: { click: _vm.palaaEdelliselleSivulle },
        },
        [_c("v-icon", [_vm._v("arrow_back")])],
        1
      ),
      _vm.title
        ? _c("v-toolbar-title", [_vm._v(" " + _vm._s(_vm.title) + " ")])
        : _vm._e(),
      _c("v-spacer"),
      _vm.denseToggleButton
        ? _c("DenseToggleButton", {
            staticClass: "mr-2",
            attrs: { "bg-color": "secondary", color: "primary" },
          })
        : _vm._e(),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }