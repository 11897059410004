var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "formSopimusLaskutusjaksoLisaa",
      attrs: { outlined: "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "px-4 pt-2", attrs: { cols: "11" } },
            [
              _c(
                "v-menu",
                {
                  attrs: {
                    "close-on-content-click": false,
                    "max-width": "290",
                    "nudge-bottom": "40",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "v-text-field",
                            _vm._g(
                              {
                                staticClass: "required",
                                attrs: {
                                  label: _vm.$t(
                                    "common:lasku.firstInvoicePeriodStartDate"
                                  ),
                                  value: _vm.$dateFnsFormat(
                                    _vm.value.laskutusjakso_alkamispaiva
                                  ),
                                  outlined: "",
                                  rules: [_vm.$validationRules.required],
                                },
                              },
                              on
                            )
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.alkamispaivaMenuAuki,
                    callback: function ($$v) {
                      _vm.alkamispaivaMenuAuki = $$v
                    },
                    expression: "alkamispaivaMenuAuki",
                  },
                },
                [
                  _c("v-date-picker", {
                    attrs: {
                      locale: _vm.$languageLocale(),
                      "first-day-of-week": "1",
                      "no-title": "",
                    },
                    on: {
                      change: function ($event) {
                        _vm.alkamispaivaMenuAuki = false
                      },
                    },
                    model: {
                      value: _vm.value.laskutusjakso_alkamispaiva,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "laskutusjakso_alkamispaiva", $$v)
                      },
                      expression: "value.laskutusjakso_alkamispaiva",
                    },
                  }),
                ],
                1
              ),
              _c("v-text-field", {
                attrs: {
                  type: "number",
                  label: _vm.$t("common:lasku.invoicePeriodsAmount"),
                  outlined: "",
                  rules: [_vm.$validationRules.zeroOrBigger],
                },
                model: {
                  value: _vm.value.maara,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "maara", $$v)
                  },
                  expression: "value.maara",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "ml-n3 pa-0", attrs: { cols: "1" } },
            [
              _c("div", { staticClass: "py-10" }),
              _c(
                "v-btn",
                {
                  staticClass: "margin-t-6px",
                  attrs: { icon: "" },
                  on: { click: _vm.laskutusjaksotKasvata },
                },
                [
                  _c("v-icon", { attrs: { color: "primary" } }, [
                    _vm._v("add_circle"),
                  ]),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: { click: _vm.laskutusjaksotVahenna },
                },
                [
                  _c("v-icon", { attrs: { color: "primary" } }, [
                    _vm._v("remove_circle"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("PeruutaHyvaksyPainikerivi", {
        attrs: {
          "submit-disabled": !_vm.valid || _vm.loading,
          "submit-loading": _vm.loading,
        },
        on: {
          close: function ($event) {
            return _vm.$emit("close")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }