var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    { ref: "formToimittaja" },
    [
      !_vm.$store.state.user.vainYksiPaamies && !_vm.kaareObjekti
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pl-4 pr-12", attrs: { md: "6" } },
                [
                  _c("AutocompletePaamies", {
                    ref: "autocompletePaamies",
                    attrs: {
                      "tarkistettava-oikeus":
                        "onlinerestapi.onlinerestapi_ostolasku",
                    },
                    model: {
                      value: _vm.toimittaja.paamies,
                      callback: function ($$v) {
                        _vm.$set(_vm.toimittaja, "paamies", $$v)
                      },
                      expression: "toimittaja.paamies",
                    },
                  }),
                ],
                1
              ),
              _c("v-col", { staticClass: "pl-12 pr-4", attrs: { md: "6" } }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pl-4 pr-12", attrs: { md: "6" } },
            [
              _c("h3", { staticClass: "my-8" }, [_vm._v("Toimittajatiedot")]),
              _c("v-text-field", {
                attrs: {
                  label: "Toimittajanumero",
                  "error-messages": _vm.toimittajanroErrors,
                },
                on: {
                  input: function ($event) {
                    return _vm.tarkistaToimittajanro($event)
                  },
                },
                model: {
                  value: _vm.toimittaja.nro,
                  callback: function ($$v) {
                    _vm.$set(_vm.toimittaja, "nro", $$v)
                  },
                  expression: "toimittaja.nro",
                },
              }),
              _c("v-select", {
                staticClass: "required",
                attrs: {
                  label: "Tyyppi",
                  type: "text",
                  items: _vm.vastapuolenTyypit,
                  "item-value": "value",
                  "item-text": "selite",
                  "menu-props": { zIndex: "999" },
                  rules: [_vm.$validationRules.required],
                },
                model: {
                  value: _vm.toimittaja.tyyppi,
                  callback: function ($$v) {
                    _vm.$set(_vm.toimittaja, "tyyppi", $$v)
                  },
                  expression: "toimittaja.tyyppi",
                },
              }),
              _c("v-text-field", {
                attrs: {
                  label: "Y-tunnus / Hetu",
                  "error-messages": _vm.tunnusErrors,
                },
                on: {
                  input: function ($event) {
                    return _vm.tarkistaTunnus($event)
                  },
                },
                model: {
                  value: _vm.toimittaja.tunnus,
                  callback: function ($$v) {
                    _vm.$set(_vm.toimittaja, "tunnus", $$v)
                  },
                  expression: "toimittaja.tunnus",
                },
              }),
              _c("v-select", {
                staticClass: "required",
                attrs: {
                  label: "Kieli",
                  type: "text",
                  items: _vm.kielet || [],
                  value: _vm.kielet ? _vm.kielet[0] : "",
                  "item-value": "value",
                  "item-text": "selite",
                  "menu-props": { zIndex: "999" },
                  rules: [_vm.$validationRules.required],
                },
                model: {
                  value: _vm.toimittaja.kieli,
                  callback: function ($$v) {
                    _vm.$set(_vm.toimittaja, "kieli", $$v)
                  },
                  expression: "toimittaja.kieli",
                },
              }),
              _c("h3", { staticClass: "my-8" }, [_vm._v("Osoitetiedot")]),
              _vm.toimittaja.tyyppi === "H"
                ? _c(
                    "div",
                    { staticClass: "comboField" },
                    [
                      _c("v-text-field", {
                        staticClass: "required",
                        attrs: {
                          label: "Etunimi",
                          rules: [_vm.$validationRules.required],
                          hint: "Anna yksi tai useampi",
                        },
                        model: {
                          value: _vm.toimittaja.etunimi,
                          callback: function ($$v) {
                            _vm.$set(_vm.toimittaja, "etunimi", $$v)
                          },
                          expression: "toimittaja.etunimi",
                        },
                      }),
                      _c("span", { staticClass: "comboField__separator" }),
                      _c("v-text-field", {
                        staticClass: "required",
                        attrs: {
                          label: "Sukunimi",
                          rules: [_vm.$validationRules.required],
                        },
                        model: {
                          value: _vm.toimittaja.sukunimi,
                          callback: function ($$v) {
                            _vm.$set(_vm.toimittaja, "sukunimi", $$v)
                          },
                          expression: "toimittaja.sukunimi",
                        },
                      }),
                    ],
                    1
                  )
                : _c("v-text-field", {
                    staticClass: "required",
                    attrs: {
                      label: "Nimi",
                      rules: [_vm.$validationRules.required],
                      hint:
                        _vm.toimittaja.tyyppi !== "Y"
                          ? "Anna henkilön nimi muodossa <sukunimi> <etunimet>"
                          : "",
                    },
                    model: {
                      value: _vm.toimittaja.nimi,
                      callback: function ($$v) {
                        _vm.$set(_vm.toimittaja, "nimi", $$v)
                      },
                      expression: "toimittaja.nimi",
                    },
                  }),
              _c("v-text-field", {
                attrs: { label: "Saaja / Tarkenne" },
                model: {
                  value: _vm.toimittaja.postiosoite.saaja,
                  callback: function ($$v) {
                    _vm.$set(_vm.toimittaja.postiosoite, "saaja", $$v)
                  },
                  expression: "toimittaja.postiosoite.saaja",
                },
              }),
              _c("v-text-field", {
                staticClass: "required",
                attrs: {
                  label: "Lähisoite",
                  rules: [_vm.$validationRules.required],
                },
                model: {
                  value: _vm.toimittaja.postiosoite.osoite,
                  callback: function ($$v) {
                    _vm.$set(_vm.toimittaja.postiosoite, "osoite", $$v)
                  },
                  expression: "toimittaja.postiosoite.osoite",
                },
              }),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "py-1", attrs: { md: "5" } },
                    [
                      _c("v-text-field", {
                        staticClass: "required",
                        attrs: {
                          label: "Postinumero",
                          rules: [_vm.$validationRules.required],
                        },
                        model: {
                          value: _vm.toimittaja.postiosoite.numero,
                          callback: function ($$v) {
                            _vm.$set(_vm.toimittaja.postiosoite, "numero", $$v)
                          },
                          expression: "toimittaja.postiosoite.numero",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-1", attrs: { md: "7" } },
                    [
                      _c("v-text-field", {
                        staticClass: "required",
                        attrs: {
                          label: "Postitoimipaikka",
                          rules: [_vm.$validationRules.required],
                        },
                        model: {
                          value: _vm.toimittaja.postiosoite.toimipaikka,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.toimittaja.postiosoite,
                              "toimipaikka",
                              $$v
                            )
                          },
                          expression: "toimittaja.postiosoite.toimipaikka",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-select", {
                staticClass: "required",
                attrs: {
                  label: "Maa",
                  type: "text",
                  items: _vm.maat,
                  "item-value": "value",
                  "item-text": "selite",
                  "menu-props": { zIndex: "999", auto: true },
                  rules: [_vm.$validationRules.required],
                },
                model: {
                  value: _vm.toimittaja.postiosoite.maa,
                  callback: function ($$v) {
                    _vm.$set(_vm.toimittaja.postiosoite, "maa", $$v)
                  },
                  expression: "toimittaja.postiosoite.maa",
                },
              }),
              _vm.toimittaja.id
                ? _c("v-select", {
                    ref: "vanhaOsoite",
                    attrs: {
                      label: "Hae vanhoista osoitteista",
                      "item-text": "osoite",
                      items: _vm.toimittajaPostiosoiteItems,
                      "return-object": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item",
                          fn: function ({ item }) {
                            return [
                              _c("span", [
                                item.saaja
                                  ? _c("span", [
                                      _vm._v(_vm._s(item.saaja) + ","),
                                    ])
                                  : _vm._e(),
                                _vm._v(
                                  " " +
                                    _vm._s(item.osoite) +
                                    ", " +
                                    _vm._s(item.numero) +
                                    ", " +
                                    _vm._s(item.toimipaikka) +
                                    " "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      204386074
                    ),
                    model: {
                      value: _vm.vanhaOsoite,
                      callback: function ($$v) {
                        _vm.vanhaOsoite = $$v
                      },
                      expression: "vanhaOsoite",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pl-12 pr-4", attrs: { md: "6" } },
            [
              _c("h3", { staticClass: "my-8" }, [_vm._v("Yhteystiedot")]),
              _c("InputPuhelinNumero", {
                attrs: { required: false, outlined: false },
                model: {
                  value: _vm.toimittaja.puhelin.numero,
                  callback: function ($$v) {
                    _vm.$set(_vm.toimittaja.puhelin, "numero", $$v)
                  },
                  expression: "toimittaja.puhelin.numero",
                },
              }),
              _c("v-text-field", {
                attrs: {
                  label: "Sähköpostiosoite",
                  rules: [_vm.$validationRules.email],
                },
                model: {
                  value: _vm.toimittaja.emailosoite.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.toimittaja.emailosoite, "email", $$v)
                  },
                  expression: "toimittaja.emailosoite.email",
                },
              }),
              _c("v-text-field", {
                attrs: {
                  label: "Verkkolaskuosoite",
                  disabled: _vm.toimittaja.tyyppi !== "Y",
                  "error-messages": _vm.verkkolaskuosoiteErrors,
                },
                model: {
                  value: _vm.toimittaja.verkkolaskuosoite.verkkolaskuosoite,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.toimittaja.verkkolaskuosoite,
                      "verkkolaskuosoite",
                      $$v
                    )
                  },
                  expression: "toimittaja.verkkolaskuosoite.verkkolaskuosoite",
                },
              }),
              _c("v-autocomplete", {
                staticClass: "py-1",
                attrs: {
                  clearable: "",
                  disabled: _vm.toimittaja.tyyppi !== "Y",
                  items: _vm.verkkolaskuoperaattorit,
                  filter: _vm.verkkolaskuoperaattoriFilter,
                  "item-text": "autocomplete_selite",
                  "item-value": "id",
                  label: "Verkkolaskuoperaattori",
                  "error-messages": _vm.verkkolaskuoperaattoriErrors,
                },
                model: {
                  value: _vm.toimittaja.verkkolaskuosoite.operaattori,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.toimittaja.verkkolaskuosoite,
                      "operaattori",
                      $$v
                    )
                  },
                  expression: "toimittaja.verkkolaskuosoite.operaattori",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-4 pa-3", attrs: { justify: "end" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "mr-4",
              attrs: { large: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v("Peruuta")]
          ),
          _c(
            "v-btn",
            {
              attrs: { large: "", color: "action" },
              on: { click: _vm.tallenna },
            },
            [_vm._v("Tallenna")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }