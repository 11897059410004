var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-row", { staticClass: "pa-6", attrs: { justify: "center" } }, [
    _c("span", { staticClass: "text-h6" }, [
      _vm._v(" " + _vm._s(_vm.otsikko) + " "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }