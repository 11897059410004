<template>
  <v-form v-model="valid" @submit.prevent="submit">
    <v-text-field
      v-model="value.nimi"
      :label="$t('common:statistics.reportName')"
      class="required"
      :rules="[$validationRules.required]"
    ></v-text-field>

    <v-textarea
      v-model="value.kuvaus"
      :label="$t('common:statistics.descriptionReport')"
      class="required"
      :rules="kuvausSaannot"
      rows="2"
    ></v-textarea>

    <v-checkbox
      v-if="naytaNakyvyysvalinnat"
      v-model="value.paamiehille"
      class="mb-n4"
      :label="$t('common:statistics.reportToCreditors')"
      :error="molemmatValittuVirhe"
    ></v-checkbox>

    <v-checkbox
      v-if="naytaNakyvyysvalinnat"
      v-model="value.paakayttajille"
      class="mb-n2"
      :label="$t('common:statistics.reportToAdmins')"
      :error="molemmatValittuVirhe"
      :error-messages="
        molemmatValittuVirhe ? [$t('common:statistics.reportToAdmins')] : []
      "
    ></v-checkbox>
    <v-btn
      class="mt-5"
      type="submit"
      color="action"
      :disabled="!valid"
      :loading="loading"
    >
      {{ submitTeksti }}
    </v-btn>
  </v-form>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";

export default {
  name: "FormTallennaTilastoRaportti",
  mixins: [FormMixin],
  props: {
    submitTeksti: {
      type: String,
      required: true,
    },
    naytaNakyvyysvalinnat: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      molemmatValittuVirhe: false,
      kuvausSaannot: [
        this.$validationRules.required,
        (k) =>
          k.length <= 500 || this.$t("common:statistics.maxDescriptionLength"),
      ],
    };
  },

  watch: {
    "value.paamiehille"(paamiehille) {
      this.molemmatValittuVirhe = !!(paamiehille & this.value.paakayttajille);
    },

    "value.paakayttajille"(paakayttajille) {
      this.molemmatValittuVirhe = !!(paakayttajille & this.value.paamiehille);
    },
  },
};
</script>

<style lang="scss" scoped></style>
