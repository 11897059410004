var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-content-click": false,
            "max-width": "290",
            "nudge-bottom": "40",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-text-field",
                    _vm._g(
                      {
                        attrs: {
                          label: _vm.$t("common:paymentDate"),
                          value: _vm.$dateFnsFormat(_vm.value.maksupaiva),
                          readonly: "",
                          placeholder: _vm.$t("today"),
                          "persistent-placeholder": "",
                        },
                      },
                      on
                    )
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.maksupaivaMenu,
            callback: function ($$v) {
              _vm.maksupaivaMenu = $$v
            },
            expression: "maksupaivaMenu",
          },
        },
        [
          _c("v-date-picker", {
            attrs: {
              max: _vm.maxDate,
              locale: "fi-FI",
              "first-day-of-week": "1",
              "no-title": "",
            },
            on: {
              change: function ($event) {
                _vm.maksupaivaMenu = false
              },
            },
            model: {
              value: _vm.value.maksupaiva,
              callback: function ($$v) {
                _vm.$set(_vm.value, "maksupaiva", $$v)
              },
              expression: "value.maksupaiva",
            },
          }),
        ],
        1
      ),
      _c("v-text-field", {
        attrs: { type: "text", label: _vm.$t("common:definition") },
        model: {
          value: _vm.value.selite,
          callback: function ($$v) {
            _vm.$set(_vm.value, "selite", $$v)
          },
          expression: "value.selite",
        },
      }),
      _c("v-tooltip", {
        attrs: { top: "" },
        scopedSlots: _vm._u([
          {
            key: "activator",
            fn: function ({ on, attrs }) {
              return [
                _c(
                  "div",
                  _vm._g(_vm._b({}, "div", attrs, false), on),
                  [
                    _c("v-checkbox", {
                      attrs: {
                        disabled: _vm.disabloidaankoHyvityslaskunLuonti,
                        label: _vm.$t(
                          "common:formLaskuHyvita.createCreditInvoice"
                        ),
                      },
                      model: {
                        value: _vm.value.hyvityslasku,
                        callback: function ($$v) {
                          _vm.$set(_vm.value, "hyvityslasku", $$v)
                        },
                        expression: "value.hyvityslasku",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "default",
            fn: function () {
              return [
                _vm.disabloidaankoHyvityslaskunLuonti
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "common:formLaskuHyvita.canNotCreateCreditInvoiceIfNoAddress"
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("v-text-field", {
        attrs: {
          type: "text",
          label: _vm.$t("common:sum"),
          placeholder: _vm.summaPlaceholder(),
          "persistent-placeholder": "",
          rules: [
            _vm.$validationRules.maxDecimalPlacesOrEmpty(_vm.value.summa, 2),
            _vm.$validationRules.numberOrEmptyWithDecimal,
          ],
          suffix: "€",
        },
        model: {
          value: _vm.value.summa,
          callback: function ($$v) {
            _vm.$set(_vm.value, "summa", $$v)
          },
          expression: "value.summa",
        },
      }),
      _vm.onkoLahteviaKirjeita
        ? _c("v-checkbox", {
            attrs: {
              label: _vm.$t("common:formLaskuHyvita.cancelInvoiceSend"),
            },
            model: {
              value: _vm.value.poista_lahtevat_kirjeet,
              callback: function ($$v) {
                _vm.$set(_vm.value, "poista_lahtevat_kirjeet", $$v)
              },
              expression: "value.poista_lahtevat_kirjeet",
            },
          })
        : _vm._e(),
      _c("PeruutaHyvaksyPainikerivi", {
        attrs: {
          "submit-disabled": !_vm.valid || _vm.loading,
          "submit-loading": _vm.loading,
        },
        on: {
          close: function ($event) {
            return _vm.$emit("close")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }