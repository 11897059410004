<template>
  <v-tooltip top :disabled="!infoteksti">
    <template #activator="{ on }">
      <v-btn
        :class="kaikkiLuokat"
        :color="taustavari"
        :to="to"
        v-on="on"
        @click="$emit('click')"
      >
        <v-icon color="ikoniVari">
          {{ ikoni }}
        </v-icon>
        <slot>
          {{ teksti }}
        </slot>
      </v-btn>
    </template>
    <template #default>
      {{ infoteksti }}
    </template>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    ikoni: {
      type: String,
      required: true,
    },
    taustavari: {
      type: String,
      required: false,
      default: "primary",
    },
    ikoniVari: {
      type: String,
      required: false,
      default: "white",
    },
    luokat: {
      type: Array,
      required: false,
      default: () => ["ma-0", "ml-2"],
    },
    otsikko: {
      type: String,
      required: false,
      default: "",
    },
    teksti: {
      type: String,
      required: false,
      default: "",
    },
    infoteksti: {
      type: String,
      required: false,
      default: "",
    },
    naytaAina: {
      type: Boolean,
      required: false,
      default: false,
    },
    to: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  computed: {
    kaikkiLuokat() {
      const luokat = this.luokat;
      if (!this.naytaAina) luokat.push("hidden-child");
      return luokat;
    },
  },
};
</script>
