import BaseOptimizedCountSelaa from "@/store/modules/baseOptimizedCountSelaa";
import { TabsAsiakas } from "@/utils/tabs";

export default class LaskutusAsiakasSelaa extends BaseOptimizedCountSelaa {
  state() {
    return {
      ...super.state(),
      apiName: "Asiakkaat",
      csvApiName: "AsiakkaatCsv",
      tabs: TabsAsiakas || [],
    };
  }
}
