<template>
  <div v-if="logoUrl" class="logo">
    <router-link
      v-if="enableLink"
      :to="{ name: 'tyopoyta' }"
      class="logo__wrap"
    >
      <img
        class="logo__img"
        :style="maxWidth ? { maxWidth: maxWidth } : {}"
        :src="logoUrl"
        alt="Logo"
      />
    </router-link>
    <div v-else class="logo__wrap">
      <img
        class="logo__img"
        :style="maxWidth ? { maxWidth: maxWidth } : {}"
        :src="logoUrl"
        alt="Logo"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Logo",

  props: {
    logoKey: {
      type: String,
      required: false,
      default() {
        return process.env.VUE_APP_LOGO;
      },
    },
    enableLink: {
      type: Boolean,
      required: false,
      default() {
        return true;
      },
    },
    small: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    maxWidth: {
      type: String,
      required: false,
      default() {
        return "";
      },
    },
  },
  computed: {
    ...mapState({
      palvelusopimus: (state) => state.user.valittuPaamies.palvelusopimus,
    }),
    logoUrl() {
      if (!this.logoKey) return null;
      let newLogoKey = this.logoKey;

      if (this.logoKey === "uuva-cloud") {
        if (this.palvelusopimus?.ominaisuudet?.logo_key) {
          newLogoKey = `uuva-cloud-${this.palvelusopimus.ominaisuudet.logo_key}-dark`;
        } else {
          newLogoKey = "uuva-cloud-plain-dark";
        }
      }

      let file = "logo-" + newLogoKey + ".png";

      return require("@/assets/logos/" + file);
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  display: block;

  &__wrap {
    display: block;
  }

  &__img {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: auto;
  }
}
</style>
