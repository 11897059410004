<template>
  <v-form>
    <v-row>
      <v-col class="mt-n1 mb-1" cols="6">
        <v-text-field
          v-model="pvm.vuosi"
          :label="vuosiOtsikko"
          :disabled="disabled"
          :error-messages="virheet"
          :hint="infoteksti"
          :rules="[$validationRules.isInteger]"
          persistent-hint
        />
      </v-col>
      <v-col class="mt-n1 mb-1" cols="3">
        <v-text-field
          v-model="pvm.kuukausi"
          :label="$t('Kuukausi')"
          :disabled="disabled"
          :error="!!virheet.length"
          :rules="[$validationRules.isInteger, tarkistaKuukausi]"
        />
      </v-col>
      <v-col class="mt-n1 mb-1" cols="3">
        <v-text-field
          v-model="pvm.paiva"
          :label="$t('Päivä')"
          :disabled="disabled"
          :error="!!virheet.length"
          :rules="[$validationRules.isInteger, tarkistaPaiva]"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: "FormPvm",
  props: {
    pvm: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    vuosiOtsikko: {
      type: String,
      required: false,
      default: () => "Vuosi",
    },
    infoteksti: {
      type: String,
      required: false,
      default: () => "",
    },
  },
  data() {
    return {
      virheet: [],
    };
  },
  watch: {
    pvm: {
      deep: true,
      handler(val) {
        this.tarkistaPvm(val);
      },
    },
  },
  methods: {
    tarkistaKuukausi(kk) {
      const luku = parseInt(kk);

      if (luku < 1 || luku > 12) return "Kelvoton kuukausi";

      return true;
    },
    tarkistaPaiva(pv) {
      const luku = parseInt(pv);

      if (luku < 1 || luku > 31) return "Kelvoton päivä";

      return true;
    },
    tarkistaPvm(pvm) {
      this.virheet = [];

      if (!(pvm.vuosi && pvm.kuukausi && pvm.paiva)) return;

      const testiPvm = new Date(pvm.vuosi, pvm.kuukausi - 1, pvm.paiva);

      // jos palauttaa samat arvot mitä on annettu päivämäärä on olemassa
      if (
        parseInt(pvm.vuosi) === testiPvm.getFullYear() &&
        parseInt(pvm.kuukausi) === testiPvm.getMonth() + 1 &&
        parseInt(pvm.paiva) === testiPvm.getDate()
      ) {
        return;
      }

      this.virheet.push("Päivämäärää ei ole olemassa!");
    },
  },
};
</script>
