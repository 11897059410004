var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { md: "12" } },
            [
              _c("v-text-field", {
                staticClass: "mt-1 pt-1 required",
                attrs: {
                  label: "IBAN",
                  type: "text",
                  rules: [
                    _vm.$validationRules.required,
                    _vm.$validationRules.isValidIBAN,
                  ],
                  "validate-on-blur": true,
                  outlined: "",
                },
                model: {
                  value: _vm.value.iban,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "iban", $$v)
                  },
                  expression: "value.iban",
                },
              }),
              _c("v-text-field", {
                staticClass: "mt-1 pt-1 required",
                attrs: {
                  label: "BIC",
                  type: "text",
                  rules: [
                    _vm.$validationRules.required,
                    _vm.$validationRules.isValidBIC,
                  ],
                  "validate-on-blur": true,
                  outlined: "",
                },
                model: {
                  value: _vm.value.bic,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "bic", $$v)
                  },
                  expression: "value.bic",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-4 pa-3", attrs: { justify: "end" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "ml-4",
              attrs: {
                large: "",
                color: "action",
                type: "submit",
                disabled: !_vm.valid,
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("common:save")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }