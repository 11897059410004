<template>
  <v-form ref="form" v-model="valid" class="white--text" @submit.prevent>
    <v-row>
      <v-col class="px-8 py-4" md="4">
        <FormTable>
          <FormTableRow>
            <FormTableCell :header="true">
              <FormTableLabel for="nimi">Nimi</FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-text-field
                id="nimi"
                v-model="value.nimi"
                type="text"
                label=""
                color="white"
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
          <FormTableRow>
            <FormTableCell :header="true">
              <FormTableLabel for="nro">
                {{ nroOtsake }}
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-text-field
                id="nro"
                v-model="value.nro"
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
          <FormTableRow>
            <FormTableCell :header="true">
              <FormTableLabel for="puh">Puhelinnumero</FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-text-field
                id="puh"
                v-model="value.puh"
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
        </FormTable>
      </v-col>
      <v-col class="px-8 py-4" md="4">
        <FormTable>
          <FormTableRow>
            <FormTableCell :header="true">
              <FormTableLabel for="paamies">Päämies</FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-text-field
                id="paamies"
                v-model="value.paamies"
                color="white"
                label="Nimi tai numero"
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
          <FormTableRow>
            <FormTableCell :header="true">
              <FormTableLabel for="vastapuoliTyyppi">
                {{ tyyppiOtsake }}
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-select
                id="vastapuoliTyyppi"
                v-model="value.tyyppi"
                :items="asiakasTyypit"
                item-text="label"
                item-value="terms"
                multiple
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
        </FormTable>
      </v-col>
      <v-col class="px-8 py-4" md="4">
        <FormTable>
          <FormTableRow>
            <FormTableCell :header="true">
              <FormTableLabel for="tunnus">Tunnus</FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-text-field
                id="tunnus"
                v-model="value.tunnus"
                color="white"
                label="Y-tunnus / Hetu"
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
          <FormTableRow>
            <FormTableCell :header="true">
              <FormTableLabel for="email">Sähköposti</FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-text-field
                id="email"
                v-model="value.email"
                color="white"
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
        </FormTable>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";
import { SelectablesAsiakasTyypit } from "@/utils/selectables";
import FormTable from "@/components/FormTable";
import FormTableRow from "@/components/FormTableRow";
import FormTableCell from "@/components/FormTableCell";
import FormTableLabel from "@/components/FormTableLabel";

export default {
  name: "FormSearchLasku",
  components: {
    FormTable,
    FormTableRow,
    FormTableCell,
    FormTableLabel,
  },
  mixins: [FormMixin],
  props: {
    vastapuoliRooli: {
      type: String,
      required: false,
      default() {
        return "asiakas";
      },
      validator: (arvo) => ["asiakas", "toimittaja"].includes(arvo),
    },
  },
  data() {
    return {
      asiakasTyypit: SelectablesAsiakasTyypit,
    };
  },
  computed: {
    nroOtsake() {
      return this.vastapuoliRooli === "asiakas"
        ? "Asiakasnumero"
        : "Toimittajanumero";
    },
    tyyppiOtsake() {
      return this.vastapuoliRooli === "asiakas"
        ? "Asiakkaan tyyppi"
        : "Toimittajan tyyppi";
    },
  },
};
</script>

<style lang="scss" scoped></style>
