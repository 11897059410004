import { HttpError } from "@/utils/errors";
import { doRequestWithTimeout } from "@/utils/misc";
import BaseModule from "@/store/modules/baseModule";
import { Toimeksianto } from "@/class/Toimeksianto";

export default class PerintaUusi extends BaseModule {
  state() {
    return {
      ...super.state(),
      apiName: "Toimeksiannot",
      luotuToimeksianto: {},
      toimeksianto: new Toimeksianto(true),
    };
  }

  getters() {
    return {
      ...super.getters(),
    };
  }

  mutations() {
    return {
      setLuotuToimeksianto(state, payload) {
        state.luotuToimeksianto = payload;
      },
      setToimeksianto(state, payload) {
        state.toimeksianto = payload;
      },
    };
  }

  actions() {
    return {
      async luoToimeksianto({ commit, state, getters }) {
        const request = await doRequestWithTimeout(
          getters.api,
          {
            method: "POST",
            url: "",
            body: state.toimeksianto.getPostData(),
          },
          "doSingleRequest"
        );

        if (!request.success) throw new HttpError(request);

        commit("setLuotuToimeksianto", request.result.body);
      },
      lahetaLiitteet({ state, dispatch }) {
        return Promise.all(
          state.toimeksianto.tiedostot.map(async (tiedosto) => {
            return await dispatch("lahetaAsiakirja", tiedosto);
          })
        );
      },
      /**
       * Actionin käytön edellytyksenä on se, että toimeksianto on luotu.
       */
      async lahetaAsiakirja({ state, getters }, tiedosto) {
        const formData = new FormData();
        formData.set("attachment_file", tiedosto);

        const request = await doRequestWithTimeout(
          getters.apiModule.getInstance("Toimeksianto"),
          {
            method: "POST",
            url: "toiminnot/asiakirja/",
            body: formData,
            params: {
              id: state.luotuToimeksianto.id,
            },
          },
          "doRequest"
        );

        if (!request.success) throw new HttpError(request);

        return request;
      },
    };
  }
}
