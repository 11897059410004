var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c("v-text-field", {
        attrs: {
          label: "Käyttäjätunnus",
          rules: [_vm.$validationRules.required],
          outlined: _vm.variant === "outlined",
          solo: _vm.variant === "solo",
          required: "",
          autofocus: "",
        },
        model: {
          value: _vm.value.username,
          callback: function ($$v) {
            _vm.$set(_vm.value, "username", $$v)
          },
          expression: "value.username",
        },
      }),
      _c("v-text-field", {
        attrs: {
          label: "Salasana",
          "append-icon": _vm.showPassword ? "visibility_off" : "visibility",
          type: _vm.showPassword ? "text" : "password",
          rules: [_vm.$validationRules.required],
          outlined: _vm.variant === "outlined",
          solo: _vm.variant === "solo",
          required: "",
        },
        on: {
          "click:append": function ($event) {
            _vm.showPassword = !_vm.showPassword
          },
        },
        model: {
          value: _vm.value.password,
          callback: function ($$v) {
            _vm.$set(_vm.value, "password", $$v)
          },
          expression: "value.password",
        },
      }),
      _c(
        "v-btn",
        {
          staticClass: "ml-0",
          attrs: {
            type: "submit",
            disabled: !_vm.valid || _vm.loading,
            loading: _vm.loading,
            block: "",
            large: "",
            color: "primary",
          },
        },
        [_vm._v(" Kirjaudu ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }