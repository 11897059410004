<template>
  <div
    v-if="
      $permission.checkSinglePerm('onlinerestapi.onlinerestapi_luottotieto') &&
      luottotietoModuuli
    "
  >
    <CardTitle
      color="black--text"
      :text="$t('common:creditRating.creditRatingContract')"
      :center="false"
    />
    <LuottotietoSopimus />
  </div>
</template>
<script>
import { LuottotietoSopimus } from "@/components";
import { onkoModuuliKaytossa } from "@/utils/misc";

export default {
  name: "AsetuksetSopimukset",
  components: { LuottotietoSopimus },
  data() {
    return {};
  },
  computed: {
    luottotietoModuuli() {
      return onkoModuuliKaytossa("luottotietosopimus");
    },
  },
  methods: {},
};
</script>
<style scoped lang="scss"></style>
