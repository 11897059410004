<template>
  <div>
    <div class="paper px-6 py-12 mb-12">
      <!-- Laskun tiedot -->
      <v-form ref="formLasku" @submit.prevent="submit">
        <!-- Top section -->
        <v-row>
          <!-- Left column -->
          <v-col class="pa-6" md="6">
            <span class="paper__title hidden-md-and-up">Lasku</span>

            <!-- Päämies -->
            <v-hover v-slot="{ hover }" open-delay="50">
              <v-card
                class="pa-2 show-hidden-child-on-hover"
                :class="
                  hover && $store.getters['user/salliPaamiehenTyhjennys']
                    ? 'elevation-2 hoverBackground'
                    : 'elevation-0'
                "
              >
                <div>
                  <template v-if="lasku.paamies.id">
                    <v-row justify="space-between">
                      <v-col cols="6" class="py-0">
                        <h3>{{ $t("invoicer") }}</h3>
                      </v-col>
                      <v-col
                        v-if="
                          !muokkaamassaLaskua &&
                          $store.getters['user/salliPaamiehenTyhjennys']
                        "
                        cols="3"
                        class="col-icon py-0"
                      >
                        <LomakenappiIkonilla
                          ikoni="clear"
                          :infoteksti="$t('clear')"
                          @click="tyhjennaPaamies"
                        />
                        <!-- <v-tooltip top> // Toteutetaan monipäämieshallinnan yhteydessä
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              class="ma-0 bg-laskutus--primary"
                              @click="$emit('avaaDialogPaamies')"
                              v-on="on"
                            >
                              <v-icon color="white">edit</v-icon>
                            </v-btn>
                          </template>
                          <template v-slot:default>
                            Muokkaa laskuttajaa
                          </template>
                        </v-tooltip> -->
                      </v-col>
                    </v-row>
                    <OsoiteKappale
                      :renderoi-tyhja="true"
                      :nayta-nimi="true"
                      :nimi="lasku.paamies.nimi"
                      :postiosoite="
                        lasku.paamies.postiosoite
                          ? lasku.paamies.postiosoite.postiosoite
                          : null
                      "
                      :postinumero="
                        lasku.paamies.postiosoite
                          ? lasku.paamies.postiosoite.postinumero
                          : null
                      "
                      :postitoimipaikka="
                        lasku.paamies.postiosoite
                          ? lasku.paamies.postiosoite.postitoimipaikka
                          : null
                      "
                    />
                  </template>
                  <template v-else>
                    <AutocompletePaamies
                      ref="autocompletePaamies"
                      v-model="lasku.paamies"
                      :label="$t('common:invoicer')"
                      :tarkista-lasku-esto="true"
                      :tarkistettava-oikeus="
                        muokkaamassaLaskua
                          ? 'onlinerestapi.onlinerestapi_lasku_muokkaa'
                          : 'onlinerestapi.onlinerestapi_lasku'
                      "
                      @autocomplete-input="paamiesKasitteleValinta"
                    />
                  </template>
                </div>
              </v-card>
            </v-hover>

            <!-- Asiakas -->
            <template v-if="lasku.paamies.id">
              <div
                :class="{
                  'py-3': $vuetify.breakpoint.smAndDown,
                  'py-5': $vuetify.breakpoint.mdAndUp,
                }"
              ></div>
              <v-hover v-slot="{ hover }" open-delay="50">
                <v-card
                  class="pa-2 show-hidden-child-on-hover"
                  :class="hover ? 'elevation-2 hoverBackground' : 'elevation-0'"
                >
                  <div>
                    <template v-if="lasku.asiakas.id">
                      <v-row justify="space-between">
                        <v-col cols="6" class="py-0">
                          <h3 class="">
                            {{ $t("recipient") }}
                          </h3>
                        </v-col>
                        <v-col cols="3" class="col-icon py-0">
                          <LomakenappiIkonilla
                            v-if="
                              lasku.asiakas.muistiinpano_set &&
                              lasku.asiakas.muistiinpano_set.length
                            "
                            ikoni="anitta-icon-note"
                            :infoteksti="$t('customerNotes')"
                            @click="$emit('avaa-dialog-asiakas-muistiinpanot')"
                          />
                          <LomakenappiIkonilla
                            ikoni="clear"
                            :infoteksti="$t('clear')"
                            @click="tyhjennaAsiakas"
                          />
                          <LomakenappiIkonilla
                            ikoni="edit"
                            :infoteksti="$t('editCustomer')"
                            @click="$emit('avaa-dialog-asiakas')"
                          />
                        </v-col>
                      </v-row>

                      <OsoiteKappale
                        :nimi="lasku.asiakas.nimi"
                        :renderoi-tyhja="true"
                        :nayta-nimi="true"
                        :saaja="lasku.asiakas.postiosoite.saaja"
                        :postiosoite="lasku.asiakas.postiosoite.osoite"
                        :postinumero="lasku.asiakas.postiosoite.numero"
                        :postitoimipaikka="
                          lasku.asiakas.postiosoite.toimipaikka
                        "
                      />
                    </template>
                    <template v-else>
                      <v-autocomplete
                        ref="autocompleteAsiakas"
                        v-model="lasku.asiakas"
                        :items="asiakasItems"
                        :loading="asiakasLoading"
                        :search-input.sync="asiakasSearchQuery"
                        :hide-no-data="!asiakasSearchQuery || asiakasLoading"
                        item-text="nimi"
                        item-value="id"
                        :label="$t('recipient')"
                        :placeholder="
                          lasku.paamies.id
                            ? 'Hae asiakkaan nimellä'
                            : 'Valitse ensin laskuttaja'
                        "
                        persistent-placeholder
                        :disabled="!lasku.paamies.id"
                        return-object
                        class="required"
                        :rules="[validoiAsiakas]"
                        :validate-on-blur="true"
                      >
                        <template #item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ item.nimi }}
                            </v-list-item-title>
                            <v-list-item-subtitle v-if="item.postiosoite">
                              <template v-if="item.postiosoite.osoite">
                                {{ item.postiosoite.osoite }},
                              </template>
                              <template v-if="item.postiosoite.numero">
                                {{ item.postiosoite.numero }}
                              </template>
                              <template v-if="item.postiosoite.toimipaikka">
                                {{ item.postiosoite.toimipaikka }}
                              </template>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                        <template #no-data>
                          <p class="pa-4">
                            <v-btn @click="$emit('avaa-dialog-asiakas')">
                              {{ $t("lasku.searchFoundNoCustomers") }}
                            </v-btn>
                          </p>
                        </template>
                        <template v-if="!lasku.asiakas.id" #append-outer>
                          <LomakenappiIkonilla
                            ikoni="person_add"
                            :infoteksti="$t('createCustomer')"
                            @click="$emit('avaa-dialog-asiakas')"
                          />
                        </template>
                      </v-autocomplete>
                    </template>
                  </div>
                </v-card>
              </v-hover>
            </template>

            <!-- Toimitusosoite -->
            <template v-if="lasku.asiakas.id">
              <div
                :class="{
                  'py-2': $vuetify.breakpoint.smAndDown,
                  'py-3': $vuetify.breakpoint.mdAndUp,
                }"
              ></div>
              <v-hover v-slot="{ hover }" open-delay="50">
                <v-card
                  class="pa-2 show-hidden-child-on-hover"
                  :class="hover ? 'elevation-2 hoverBackground' : 'elevation-0'"
                >
                  <div>
                    <template v-if="lasku.toimitusosoite.osoite">
                      <v-row class="pt-4" justify="space-between">
                        <v-col cols="6" class="py-0">
                          <h3>{{ $t("deliveryAddress") }}</h3>
                        </v-col>
                        <v-col cols="3" class="col-icon py-0">
                          <LomakenappiIkonilla
                            ikoni="clear"
                            :infoteksti="$t('clear')"
                            @click="tyhjennaToimitusosoite"
                          />
                          <LomakenappiIkonilla
                            ikoni="edit"
                            :infoteksti="$t('editDeliveryAddress')"
                            @click="$emit('avaa-dialog-toimitusosoite')"
                          />
                        </v-col>
                      </v-row>

                      <OsoiteKappale
                        :nayta-nimi="false"
                        :saaja="lasku.toimitusosoite.saaja"
                        :postiosoite="lasku.toimitusosoite.osoite"
                        :postinumero="lasku.toimitusosoite.numero"
                        :postitoimipaikka="lasku.toimitusosoite.toimipaikka"
                        class="pb-2"
                      />

                      <v-text-field
                        v-model="lasku.toimitustapa"
                        label="Toimitustapa"
                        class="pr-12 mt-2"
                      ></v-text-field>
                    </template>

                    <template v-else>
                      <v-select
                        v-model="lasku.toimitusosoite"
                        :items="toimitusosoiteItems"
                        item-text="osoite"
                        label="Toimitusosoite"
                        no-data-text="Ei olemassaolevia toimitusosoitteita"
                        :disabled="!lasku.asiakas.id"
                        return-object
                        item-value="id"
                      >
                        <!-- Tähän tarvitaan item-valueksi id, koska muuten v-select karsii pois yhtä lukuunottamatta muut itemit joilla on sama 'osoite'-kentän arvo -->

                        <template #item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ item.osoite }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <template v-if="item.saaja">
                                {{ item.saaja }},
                              </template>
                              <template v-if="item.osoite">
                                {{ item.osoite }},
                              </template>
                              <template v-if="item.numero">
                                {{ item.numero }}
                              </template>
                              <template v-if="item.toimipaikka">
                                {{ item.toimipaikka }}
                              </template>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>

                        <template #append-outer>
                          <LomakenappiIkonilla
                            ikoni="add"
                            :infoteksti="$t('addDeliveryAddress')"
                            nayta-aina
                            @click="$emit('avaa-dialog-toimitusosoite')"
                          />
                        </template>
                      </v-select>
                    </template>
                  </div>
                </v-card>
              </v-hover>
            </template>
          </v-col>

          <!-- Right Column -->
          <v-col class="pa-6" md="6">
            <v-col class="pa-2 pt-0">
              <span class="paper__title hidden-sm-and-down">
                {{ $t("invoice") }}
              </span>

              <v-text-field
                ref="laskunumero"
                v-model="lasku.laskunumero"
                label="Laskunumero"
                :disabled="lasku.laskunumeroDisabloitu"
                class="mt-1 pt-1"
              />

              <v-menu
                v-model="laskupaivaMenu"
                :close-on-content-click="false"
                max-width="290"
              >
                <template #activator="{ on }">
                  <v-text-field
                    label="Laskupäivä"
                    :value="$dateFnsFormat(lasku.laskupaiva)"
                    readonly
                    :rules="[$validationRules.required]"
                    :class="laskupaivaClassArr"
                    class="mt-1 pt-1"
                    v-on="on"
                  />
                </template>
                <template #default>
                  <v-date-picker
                    v-model="lasku.laskupaiva"
                    locale="fi-FI"
                    first-day-of-week="1"
                    no-title
                    class="mt-1 pt-1"
                    @change="laskupaivaMenu = false"
                  />
                </template>
              </v-menu>
              <v-text-field
                v-model="lasku.maksuehto"
                label="Maksuehto"
                :rules="[
                  $validationRules.notIsNaN,
                  $validationRules.zeroOrBigger,
                ]"
                :class="maksuehtoClassArr"
                suffix="pv netto"
                :full-width="false"
                class="mt-1 pt-1"
              />

              <v-menu
                v-model="erapaivaMenu"
                :close-on-content-click="false"
                max-width="290"
              >
                <template #activator="{ on }">
                  <v-text-field
                    label="Eräpäivä"
                    :value="$dateFnsFormat(lasku.erapaiva)"
                    readonly
                    :rules="[$validationRules.required]"
                    :class="erapaivaClassArr"
                    class="mt-1 pt-1"
                    v-on="on"
                  />
                </template>
                <template #default>
                  <v-date-picker
                    v-model="lasku.erapaiva"
                    locale="fi-FI"
                    first-day-of-week="1"
                    no-title
                    @change="erapaivaMenu = false"
                  />
                </template>
              </v-menu>

              <v-text-field
                v-if="lasku.asiakas.tyyppi === 'Y'"
                v-model="lasku.viivastyskorko"
                type="text"
                label="Viivästyskorko"
                suffix="%"
                :rules="[
                  $validationRules.maxDecimalPlacesOrEmpty(
                    lasku.viivastyskorko,
                    2
                  ),
                  $validationRules.numberOrEmptyWithDecimal,
                  $validationRules.zeroOrBiggerWithDecimal,
                  $validationRules.smallerThanOrEqualToWithDecimal(
                    lasku.viivastyskorko,
                    100
                  ),
                ]"
                class="mt-1 pt-1"
                :placeholder="$t('common:accordingToKorkolaki')"
                persistent-placeholder
                :validate-on-blur="true"
              />
              <v-select
                v-else
                v-model="lasku.viivastyskorko"
                label="Viivästyskorko"
                :items="viivastyskorkoItems"
                class="mt-1 pt-1"
              />
              <v-text-field
                v-if="muokkaamassaLaskua"
                v-model="lasku.pm_viite"
                label="PM-viite"
                type="text"
                class="mt-1 pt-1"
              />
              <v-text-field
                v-model="lasku.viitteemme"
                label="Viitteemme"
                type="text"
                class="mt-1 pt-1"
              />
              <v-text-field
                v-model="lasku.viitteenne"
                label="Viitteenne"
                type="text"
                class="mt-1 pt-1"
              />
              <v-select
                v-model="lasku.kirjepohja_id"
                label="Laskupohja"
                type="text"
                :items="laskupohjatItems"
                item-text="str"
                item-value="id"
                no-data-text="Ei laskupohjia"
                class="mt-1 pt-1"
              />
              <v-textarea
                v-model="lasku.vapaateksti"
                label="Laskulla näkyvä teksti"
                type="text"
                rows="2"
                :counter="lasku.vapaateksti.length > 1023 ? 1023 : undefined"
                :rules="[
                  $validationRules.tooManyCharacters(
                    lasku.vapaateksti.length,
                    1023
                  ),
                ]"
                class="mt-1 pt-1"
              />
              <v-select
                v-model="lasku.myyja_id"
                label="Myyjä"
                type="text"
                clearable
                :items="myyjatItems"
                item-text="str"
                item-value="id"
                class="mt-1 pt-1"
              />
            </v-col>
          </v-col>
        </v-row>

        <!-- Laskurivit -->
        <div class="pa-6">
          <h2 class="mb-4">Laskurivit</h2>
          <LaskuriviTable
            ref="laskuriviTable"
            v-model="lasku"
            cell-size="small"
          />
          <v-row class="mt-8">
            <v-col cols="8">
              <AutocompleteLaskurivi
                :lasku="lasku"
                @autocomplete-input="poistaTyhjaEnsimmainenRivi"
              />
            </v-col>
          </v-row>
        </div>
      </v-form>
    </div>

    <!-- Lähetys / Maksutapa -->
    <div class="paper px-9 py-3 mb-6">
      <v-row>
        <v-col md="6" class="pa-6">
          <template v-if="naytaMaksutapa">
            <h2 class="text-h5 mb-6">Maksutapa</h2>
            <v-radio-group
              v-model="lasku.maksutapa"
              row
              class="mt-0"
              :error-messages="maksutapaErrors"
            >
              <v-radio
                v-for="maksutapa in maksutavat"
                :key="maksutapa.index"
                :label="maksutapa.label"
                :value="maksutapa.value"
              />
            </v-radio-group>
          </template>
          <template v-else>
            <h2 class="text-h5 mb-6">Lähetys</h2>
            <h3 class="text-subtitle-1">Lähetystapa</h3>
            <v-switch
              v-model="lasku.luonnos"
              label="Tallenna laskuluonnos (ei lähetystä)"
              :disabled="onLahetelasku"
            />
            <div v-if="!lasku.luonnos">
              <v-radio-group
                v-model="lasku.lahetystapa"
                row
                class="mt-0"
                :error-messages="lahetystapaErrors"
              >
                <v-radio
                  v-for="lahetystapa in lahetystavatHintoineen"
                  :key="lahetystapa.index"
                  :label="lahetystapa.label"
                  :value="lahetystapa.value"
                  :disabled="
                    onkoLahetystapaDisabloitu(lahetystapa.value, lasku.asiakas)
                  "
                />
              </v-radio-group>

              <h3 class="text-subtitle-1">Lähetyspäivä</h3>
              <v-menu
                v-model="lahetyspaivaMenu"
                :close-on-content-click="false"
                max-width="290"
              >
                <template #activator="{ on }">
                  <v-text-field
                    :value="$dateFnsFormat(lasku.haluttu_lahetyspaiva)"
                    placeholder="Tänään"
                    persistent-placeholder
                    readonly
                    :disabled="!lasku.kirjepohja_id"
                    v-on="on"
                  />
                </template>
                <template #default>
                  <v-date-picker
                    v-model="lasku.haluttu_lahetyspaiva"
                    locale="fi-FI"
                    first-day-of-week="1"
                    no-title
                    :disabled="!lasku.kirjepohja_id"
                    @change="lahetyspaivaMenu = false"
                  />
                </template>
              </v-menu>
              <v-card
                v-if="lasku.paamies.id && !lasku.kirjepohja_id"
                class="pa-4"
                color="warning"
              >
                <span class="text-subtitle-1">
                  Huom.! Laskulle ei ole määritetty laskupohjaa. Sitä ei
                  lähetetä.
                </span>
              </v-card>
            </div>
          </template>
        </v-col>
        <v-col md="6" class="pa-6">
          <template
            v-if="
              $permission.checkSinglePerm(
                'onlinerestapi.onlinerestapi_lasku_sms_muistutus'
              )
            "
          >
            <h2 class="text-h5 mb-6 ml-0">Tekstiviestimuistutus</h2>
            <v-text-field
              v-model="lasku.sms_muistutus_viive"
              type="number"
              suffix="päivää eräpäivästä"
              :rules="[$validationRules.zeroOrBigger]"
              :disabled="!lasku.asiakas.puhelin.numero"
            />
          </template>

          <h2 class="text-h5 mb-6 ml-0">Liitteet</h2>

          <DataTableLaskuliite
            v-if="lasku.laskuliite_set.length"
            :items="lasku.laskuliite_set"
            :lasku="lasku"
            :salli-poisto="false"
          />
          <v-file-input
            v-model="lasku.tiedostot"
            chips
            counter="5"
            multiple
            :error-messages="liiteErrors"
            :rules="[
              $validationRules.required,
              $validationRules.smallerThanOrEqualTo(5, 5),
            ]"
            title="title"
            placeholder="Lisää liitteitä..."
            hint="Huom.! Tiedoston tulee olla tyyppiä jpeg, gif, png tai pdf. Kuvatiedostot muunnetaan pdf:ksi."
            persistent-placeholder
            :validate-on-blur="true"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import OsoiteKappale from "@/components/OsoiteKappale";
import AutocompleteLaskurivi from "@/components/AutocompleteLaskurivi";
import AutocompletePaamies from "@/components/AutocompletePaamies";
import LaskuriviTable from "@/components/LaskuriviTable";
import LomakenappiIkonilla from "@/components/LomakenappiIkonilla";
import { Asiakas, AsiakasPostiosoite } from "@/class/Asiakas";
import {
  lahetystavatHintoineen,
  onkoLahetystapaDisabloitu,
  paatteleTiedostonMimetype,
} from "@/utils/misc";
import { MaksimiUploadTiedostoKoko } from "@/utils/constants";
import { Lasku } from "@/class";

export default {
  name: "FormLasku",
  components: {
    OsoiteKappale,
    AutocompleteLaskurivi,
    AutocompletePaamies,
    LaskuriviTable,
    LomakenappiIkonilla,
  },
  model: {
    prop: "lasku",
    event: "update:lasku",
  },
  props: {
    lasku: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    muokkaamassaLaskua: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
  },
  emits: ["update:lasku"],
  data() {
    return {
      asiakasItems: [],
      asiakasLoading: false,
      asiakasSearchQuery: "",
      debounceDelay: 300,
      debounceTimeout: "",
      erapaivaClassArr: ["required", "mt-1", "pt-1"],
      erapaivaMenu: false,
      kuittipohjat: [],
      lahetepohjat: [],
      lahetyspaivaMenu: false,
      lahetystapaErrors: [],
      lahetystavatHintoineen: [],
      laskupaivaClassArr: ["required", "mt-1", "pt-1"],
      laskupaivaMenu: false,
      laskupohjatItems: [],
      liiteErrors: [],
      maksutavat: [
        {
          value: "kateinen",
          label: "Käteinen",
        },
        {
          value: "pankkikortti",
          label: "Pankkikortti",
        },
      ],
      maksuehtoClassArr: ["required", "mt-1", "pt-1"],
      myyjatItems: [],
      toimitusosoiteItems: [],
      viivastyskorkoItems: [
        {
          value: null,
          text: this.$t("common:accordingToKorkolaki"),
        },
        {
          value: "0.00",
          text: "0 %",
        },
      ],
    };
  },
  computed: {
    naytaMaksutapa() {
      return this.kuittipohjat.includes(this.lasku.kirjepohja_id);
    },
    onLahetelasku() {
      return this.lahetepohjat.includes(this.lasku.kirjepohja_id);
    },
  },
  watch: {
    lasku: {
      deep: true,
      handler() {
        this.$emit("update:lasku", this.lasku); // this.$emit('muokkaus')
      },
    },
    "lasku.paamies": {
      handler(paamies) {
        if (!this.muokkaamassaLaskua) {
          this.lasku.asiakas = new Asiakas({
            paamies_id: this.lasku.paamies.id,
          });
          this.lasku.kirjepohja_id = null;
          this.lasku.laskunumero = null;
        }

        this.lasku.laskunumeroDisabloitu = false;
        this.laskupohjatItems = [];
        this.myyjatItems = [];
        this.toimitusosoiteItems = [];

        if (paamies.id) {
          this.$nextTick(() => {
            this.$refs.autocompleteAsiakas.resetValidation();
          });
          this.haePaamiehenEsitiedot();
        }
      },
    },
    "lasku.rivit": {
      deep: true,
      handler() {
        this.lasku.calculateTotals();
      },
    },
    "lasku.laskupaiva": {
      handler() {
        this.lasku.calculateErapaiva();
        this.valayta(this.laskupaivaClassArr);
        this.valayta(this.erapaivaClassArr);
      },
    },
    "lasku.maksuehto": {
      handler() {
        this.lasku.calculateErapaiva();
        if (this.lasku.maksuehto) {
          this.valayta(this.maksuehtoClassArr);
          this.valayta(this.erapaivaClassArr);
        }
      },
    },
    "lasku.erapaiva": {
      handler() {
        this.lasku.calculateMaksuehto();
      },
    },
    "lasku.asiakas.id": {
      handler() {
        this.paivitaAsiakkaanOletusvalinnat();
      },
    },
    "lasku.asiakas.postiosoite.maa": {
      handler() {
        this.alustaLahetystavatHintoineen();
      },
    },
    "lasku.asiakas.postiosoite_set": {
      handler(postiosoite_set) {
        this.toimitusosoiteItems = postiosoite_set.map((postiosoite) => {
          return new AsiakasPostiosoite(postiosoite);
        });
      },
    },
    "lasku.luonnos": {
      handler() {
        if (this.lasku.luonnos) {
          this.lasku.lahetystapa = "luonnos";
        } else {
          this.lasku.lahetystapa = "";
        }
      },
    },
    "lasku.lahetystapa": {
      handler(val) {
        if (val) {
          this.lahetystapaErrors = [];
        }
      },
    },
    "lasku.kirjepohja_id": {
      handler() {
        if (this.onLahetelasku) {
          this.lasku.luonnos = true;
        }
      },
    },
    "lasku.tiedostot": {
      handler(val) {
        if (val) {
          this.liiteErrors = [];
        }
      },
    },
    async asiakasSearchQuery(val) {
      if (!this.lasku.paamies.id) return;

      this.asiakasItems = [];

      if (!val) {
        this.lasku.asiakas = new Asiakas({ paamies_id: this.lasku.paamies.id });
        return;
      }

      this.asiakasLoading = true;
      clearTimeout(this.debounceTimeout);

      this.debounceTimeout = setTimeout(async () => {
        try {
          const query = new URLSearchParams();
          query.append("paamies", this.lasku.paamies.id);
          query.append("nimi", val);

          const request = await this.$doRequestWithTimeout(
            this.$api.AsiakasLaskulle,
            {
              method: "GET",
              query: query.toString(),
            },
            "pushRequest"
          );

          if (!request.success) throw new this.$HttpError(request);

          const { results } = request.result.body;

          this.asiakasItems = results.map((asiakas) => {
            return new Asiakas(asiakas);
          });
        } catch (e) {
          this.$sentryCaptureCustom(e);
        } finally {
          this.asiakasLoading = false;
        }
      }, this.debounceDelay);
    },
  },
  mounted() {
    if (this.$store.state.user.valittuPaamies.id) {
      this.lasku.paamies = this.$store.state.user.valittuPaamies;
    }

    this.alustaLahetystavatHintoineen();
  },
  methods: {
    alustaLahetystavatHintoineen() {
      try {
        this.lahetystavatHintoineen = Object.assign(
          [],
          lahetystavatHintoineen(
            this.lasku.paamies,
            this.lasku.asiakas.postiosoite.maa
          )
        );
      } catch (e) {
        this.$oletusVirheenkasittely(
          e,
          "Laskun lähetystapojen alustus epäonnistui"
        );
      }
    },
    onkoLahetystapaDisabloitu(lahetystapa, asiakas) {
      return onkoLahetystapaDisabloitu(lahetystapa, asiakas);
    },
    async haePaamiehenEsitiedot() {
      try {
        const query = new URLSearchParams();
        query.append("paamies_id", this.lasku.paamies.id);

        const request = await this.$doRequestWithTimeout(
          this.$api.Laskut,
          {
            method: "GET",
            url: "uusi/esitiedot/",
            query: query.toString(),
          },
          "pushRequest"
        );

        if (!request.success)
          throw new this.$HttpError(
            request,
            "Laskuttajan tietojen haku epäonnistui."
          );

        const esitiedot = request.result.body;
        // Jos päämiehellä on jo automaattinen laskunumerointi käytössä, ei sallita laskunumeron muokkausta.
        this.lasku.laskunumeroDisabloitu = esitiedot.nro ? true : false;

        const laskupohjat = esitiedot.laskupohjat;
        this.laskupohjatItems = laskupohjat;
        this.myyjatItems = esitiedot.myyjat;

        if (!this.muokkaamassaLaskua) {
          this.lasku.laskunumero = esitiedot.nro;
          if (laskupohjat[0]) this.lasku.kirjepohja_id = laskupohjat[0].id;

          if (this.lasku.paamies.viestikirjeeseen) {
            this.lasku.vapaateksti = this.lasku.paamies.viestikirjeeseen;
          }
        }

        if (esitiedot.kuittipohjat.length || esitiedot.lahetepohjat.length) {
          this.kuittipohjat = esitiedot.kuittipohjat;
          this.lahetepohjat = esitiedot.lahetepohjat;
        }
      } catch (e) {
        this.$oletusVirheenkasittely(e);
      }
    },
    poistaTyhjaEnsimmainenRivi() {
      if (this.lasku.rivit.length > 0 && this.lasku.rivit[0].isEmpty()) {
        this.lasku.rivit.splice(0, 1);
      }
    },
    paamiesKasitteleValinta() {
      this.alustaLahetystavatHintoineen();
    },
    submit() {
      this.$emit("submit", this.lasku);
    },
    tyhjennaPaamies() {
      this.lasku.paamies = {};
    },
    tyhjennaAsiakas() {
      this.lasku.asiakas = new Asiakas({ paamies_id: this.lasku.paamies.id });
      this.lasku.viivastyskorko = null;
    },
    tyhjennaToimitusosoite() {
      this.lasku.toimitusosoite = new AsiakasPostiosoite();
    },
    valayta(classArr) {
      for (var i = 0; i < classArr.length; i++) {
        if (classArr[i] === "taustavalahdys") {
          classArr.splice(i, 1);
          i--;
        }
      }
      setTimeout(() => {
        classArr.push("taustavalahdys");
      }, 100);
    },
    async validate() {
      let lomakeValidi =
        this.$refs.formLasku.validate() && this.$refs.laskuriviTable.validate();

      if (
        !this.lasku.lahetystapa &&
        !this.naytaMaksutapa &&
        !this.onLahetelasku
      ) {
        this.lahetystapaErrors = [
          "Valitse lähetystapa tai luonnoksena tallentaminen!",
        ];
        lomakeValidi = false;
      }

      if (this.naytaMaksutapa && !this.lasku.maksutapa) {
        this.maksutapaErrors = ["Valitse maksutapa!"];
        lomakeValidi = false;
      }

      if (this.lasku.tiedostot.length > 5) {
        this.liiteErrors = [this.$t("common:attachmentTooMany")];
        lomakeValidi = false;
      }

      let liianSuurenTiedostonNimi = "";
      const sallittuTiedostoKokoYlitetty = this.lasku.tiedostot.some(
        (tiedosto) => {
          liianSuurenTiedostonNimi = tiedosto.name;
          return tiedosto.size > MaksimiUploadTiedostoKoko;
        }
      );
      if (sallittuTiedostoKokoYlitetty) {
        this.liiteErrors = [
          this.$t("common:attachmentSizeExceeded", {
            filename: liianSuurenTiedostonNimi,
          }),
        ];
        lomakeValidi = false;
      }

      for (const tiedosto of this.lasku.tiedostot) {
        const mimeType = await paatteleTiedostonMimetype(tiedosto);
        if (
          mimeType !== "tuntematon" &&
          !["image/jpeg", "image/gif", "image/png", "application/pdf"].includes(
            mimeType
          )
        ) {
          this.liiteErrors = [
            "Tiedoston tulee olla tyyppiä jpeg, gif, png tai pdf",
          ];
          lomakeValidi = false;
        }
      }

      return lomakeValidi;
    },
    validoiAsiakas() {
      if (!this.lasku.asiakas.nimi) {
        return "Pakollinen tieto puuttuu";
      } else {
        return true;
      }
    },
    paivitaAsiakkaanOletusvalinnat() {
      if (!this.lasku.asiakas.id) {
        return;
      }

      const oletusKentatAsiakkaltaLaskulle = [
        "maksuehto",
        "toimitustapa",
        "vapaateksti",
        "viitteemme",
        "viitteenne",
      ];

      if (!this.muokkaamassaLaskua) {
        for (const kentta of oletusKentatAsiakkaltaLaskulle) {
          const oletuskentta = this.lasku.asiakas[`oletus${kentta}`];
          // Ei ylikirjoiteta, jos oletusarvo on tyhjä
          if (oletuskentta) {
            this.lasku[kentta] = oletuskentta;
          }
        }
      }

      if (this.lasku.asiakas.lahetystapa) {
        this.lasku.lahetystapa = this.lasku.asiakas.lahetystapa;
      } else {
        this.lasku.lahetystapa = Lasku.LAHETYSTAPA_POSTI;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.osoiteKappale {
  font-size: 1.1em;
}

.hoverBackground {
  background-color: rgba(0, 188, 212, 0.1);
}

.v-input {
  padding-top: 4px;
}

.col-icon {
  display: flex;
  justify-content: flex-end;
}

@keyframes valayta {
  0% {
    background-color: initial;
  }
  50% {
    background-color: rgba(0, 188, 212, 0.1);
    border-radius: 5px;
  }
  // 50%  { background-color: var(--v-secondary-base); border-radius: 5px; }
  100% {
    background-color: initial;
  }
}

.taustavalahdys {
  animation-name: valayta;
  animation-duration: 0.4s;
}
</style>
