var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c("v-select", {
        staticClass: "required",
        attrs: {
          label: _vm.$t("quality"),
          type: "text",
          items: _vm.laadut,
          "item-value": "id",
          "item-text": "label",
          "menu-props": { zIndex: "999" },
          rules: [_vm.$validationRules.required],
        },
        model: {
          value: _vm.value.laatu,
          callback: function ($$v) {
            _vm.$set(_vm.value, "laatu", $$v)
          },
          expression: "value.laatu",
        },
      }),
      _c(
        "div",
        { staticClass: "comboField" },
        [
          _c("v-menu", {
            attrs: { "close-on-content-click": false, "max-width": "290" },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function ({ on }) {
                  return [
                    _c(
                      "v-text-field",
                      _vm._g(
                        {
                          staticClass: "required",
                          attrs: {
                            label: _vm.$t("eventDate"),
                            value: _vm.$dateFnsFormat(_vm.value.tapahtumaPvm),
                            readonly: "",
                            rules: [_vm.$validationRules.required],
                          },
                        },
                        on
                      )
                    ),
                  ]
                },
              },
              {
                key: "default",
                fn: function () {
                  return [
                    _c("v-date-picker", {
                      ref: "alkamispaivaDatePicker",
                      attrs: {
                        locale: "fi-FI",
                        "first-day-of-week": "1",
                        "no-title": "",
                      },
                      on: {
                        change: function ($event) {
                          _vm.tapahtumaPvmMenu = false
                        },
                      },
                      model: {
                        value: _vm.value.tapahtumaPvm,
                        callback: function ($$v) {
                          _vm.$set(_vm.value, "tapahtumaPvm", $$v)
                        },
                        expression: "value.tapahtumaPvm",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.tapahtumaPvmMenu,
              callback: function ($$v) {
                _vm.tapahtumaPvmMenu = $$v
              },
              expression: "tapahtumaPvmMenu",
            },
          }),
          _c("TooltipInfo", {
            attrs: {
              "icon-class": "align-self-start ml-2",
              "icon-color": "primary",
              text: _vm.$t("eventDateInfo"),
            },
          }),
        ],
        1
      ),
      _c("v-text-field", {
        attrs: {
          type: "text",
          label: _vm.$t("paidMoney"),
          rules: [
            _vm.$validationRules.required,
            _vm.$validationRules.maxDecimalPlacesOrEmpty(_vm.value.summa, 2),
            _vm.$validationRules.numberOrEmptyWithDecimal,
          ],
          suffix: "€",
        },
        model: {
          value: _vm.value.maksettuRahamaara,
          callback: function ($$v) {
            _vm.$set(_vm.value, "maksettuRahamaara", $$v)
          },
          expression: "value.maksettuRahamaara",
        },
      }),
      _c("v-text-field", {
        staticClass: "required",
        attrs: {
          type: "text",
          label: _vm.$t("changeDescription"),
          rules: [_vm.$validationRules.required],
        },
        model: {
          value: _vm.value.muutoksenSelite,
          callback: function ($$v) {
            _vm.$set(_vm.value, "muutoksenSelite", $$v)
          },
          expression: "value.muutoksenSelite",
        },
      }),
      _c("PeruutaHyvaksyPainikerivi", {
        attrs: {
          "submit-disabled": !_vm.valid || _vm.loading,
          "submit-loading": _vm.loading,
          "submit-teksti": _vm.$t("common:collectionShow.cancelDistraint"),
          "peruuta-teksti": _vm.$t("common:close"),
        },
        on: {
          close: function ($event) {
            return _vm.$emit("close")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }