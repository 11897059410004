var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c("v-text-field", {
        ref: "emailField",
        staticClass: "required",
        attrs: {
          label: _vm.$t("email"),
          rules: [_vm.$validationRules.required, _vm.$validationRules.email],
          "error-messages": _vm.emailErrors,
          outlined: "",
          required: "",
        },
        model: {
          value: _vm.kayttaja.email,
          callback: function ($$v) {
            _vm.$set(_vm.kayttaja, "email", $$v)
          },
          expression: "kayttaja.email",
        },
      }),
      _c("v-text-field", {
        ref: "tunnusField",
        staticClass: "required",
        attrs: {
          label: _vm.$t("companyId"),
          rules: [
            _vm.$validationRules.required,
            _vm.$validationRules.onkoYtunnus,
          ],
          "error-messages": _vm.tunnusErrors,
          outlined: "",
        },
        on: {
          blur: function ($event) {
            return _vm.tarkistaTunnuksenOikeellisuus()
          },
        },
        model: {
          value: _vm.tunnus,
          callback: function ($$v) {
            _vm.tunnus = $$v
          },
          expression: "tunnus",
        },
      }),
      _c("v-text-field", {
        staticClass: "required",
        attrs: {
          label: _vm.$t("password"),
          "append-icon": _vm.naytaSalasana ? "visibility_off" : "visibility",
          type: _vm.naytaSalasana ? "text" : "password",
          rules: [_vm.$validationRules.required],
          outlined: "",
          required: "",
          "error-messages": _vm.salasanaErrors,
        },
        on: {
          "click:append": function ($event) {
            _vm.naytaSalasana = !_vm.naytaSalasana
          },
        },
        model: {
          value: _vm.salasana,
          callback: function ($$v) {
            _vm.salasana = $$v
          },
          expression: "salasana",
        },
      }),
      _c("v-text-field", {
        staticClass: "required",
        attrs: {
          label: _vm.$t("serviceApplication.repeatPassword"),
          "append-icon": _vm.naytaSalasana ? "visibility_off" : "visibility",
          type: _vm.naytaSalasana ? "text" : "password",
          rules: [_vm.$validationRules.required],
          outlined: "",
          required: "",
          "error-messages": _vm.salasanaUudelleenErrors,
        },
        on: {
          "click:append": function ($event) {
            _vm.naytaSalasana = !_vm.naytaSalasana
          },
        },
        model: {
          value: _vm.salasanaUudelleen,
          callback: function ($$v) {
            _vm.salasanaUudelleen = $$v
          },
          expression: "salasanaUudelleen",
        },
      }),
      _c("v-checkbox", {
        attrs: { dense: "", rules: [_vm.$validationRules.required] },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [
                _c("span", [
                  _vm._v(
                    " " + _vm._s(_vm.$t("serviceApplication.IAccept")) + "  "
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "font-weight-bold",
                      attrs: {
                        href: "https://www.uuva.fi/uuva-cloud/yleiset-sopimusehdot/",
                        target: "_blank",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("serviceApplication.termsofService")) +
                          " "
                      ),
                    ]
                  ),
                  _vm._v(" * "),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.palveluehdotHyvaksytty,
          callback: function ($$v) {
            _vm.palveluehdotHyvaksytty = $$v
          },
          expression: "palveluehdotHyvaksytty",
        },
      }),
      _c("v-checkbox", {
        attrs: { dense: "", rules: [_vm.$validationRules.required] },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [
                _c("span", [
                  _vm._v(
                    " " + _vm._s(_vm.$t("serviceApplication.IAccept")) + "  "
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "font-weight-bold",
                      attrs: {
                        href: "https://www.uuva.fi/uuva-cloud-tietosuojaseloste",
                        target: "_blank",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("serviceApplication.thePrivacyPolicy")
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _vm._v(" * "),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.tietosuojaselosteHyvaksytty,
          callback: function ($$v) {
            _vm.tietosuojaselosteHyvaksytty = $$v
          },
          expression: "tietosuojaselosteHyvaksytty",
        },
      }),
      _c("v-checkbox", {
        attrs: {
          dense: "",
          label: _vm.$t("serviceApplication.sendMeMarketingEmail"),
        },
        model: {
          value: _vm.markkinointiViestitHyvaksytty,
          callback: function ($$v) {
            _vm.markkinointiViestitHyvaksytty = $$v
          },
          expression: "markkinointiViestitHyvaksytty",
        },
      }),
      _c(
        "v-row",
        { staticClass: "mt-4 pa-3", attrs: { justify: "center" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "mx-4",
              attrs: {
                block: "",
                large: "",
                color: "action",
                disabled: !_vm.valid,
              },
              on: {
                click: function ($event) {
                  return _vm.nextPage()
                },
              },
            },
            [_vm._v(" Seuraava ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }