import { render, staticRenderFns } from "./Blank.vue?vue&type=template&id=1ed06725&scoped=true"
import script from "./Blank.vue?vue&type=script&lang=js"
export * from "./Blank.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ed06725",
  null
  
)

export default component.exports