<template>
  <Pie :data="chartData" :options="options" />
</template>

<script>
import { Pie } from "vue-chartjs";
import { Chart as ChartJS, ArcElement } from "chart.js";

ChartJS.register(ArcElement);

export default {
  name: "ChartPie",
  components: { Pie },
  props: {
    chartData: {
      type: Object,

      required: false,
      default() {
        return {};
      },
    },
  },

  computed: {
    options() {
      return {
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              let thisLabel = data.labels[tooltipItem.index];

              return thisLabel;
            },
          },
        },
        legend: {
          position: "left",
        },
        responsive: false,
        maintainAspectRatio: false,
      };
    },
  },
};
</script>
