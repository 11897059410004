<template>
  <div>
    <v-form ref="saatavariviTable">
      <table class="riviTable">
        <thead>
          <tr>
            <th
              :class="[...thClassArr, 'riviTable__cell--required']"
              scope="col"
            >
              {{ $t("common:invoiceNo") }}
            </th>
            <th
              :class="[...thClassArr, 'riviTable__cell--required']"
              scope="col"
            >
              {{ $t("common:invoiceDate") }}
            </th>
            <th
              :class="[...thClassArr, 'riviTable__cell--required']"
              scope="col"
            >
              {{ $t("common:dueDate") }}
            </th>
            <template v-if="toimeksianto.muistutettu">
              <th
                :class="
                  saatavakohtainenMuistutus
                    ? [...thClassArr]
                    : [...thClassArr, 'riviTable__cell--required']
                "
                scope="col"
              >
                {{ $t("common:reminderDate") }}
              </th>
              <th
                :class="
                  saatavakohtainenMuistutus
                    ? [...thClassArr]
                    : [...thClassArr, 'riviTable__cell--required']
                "
                scope="col"
              >
                {{ $t("common:reminderDueDate") }}
              </th>

              <th
                :class="
                  saatavakohtainenMuistutus
                    ? [...thClassArr]
                    : [...thClassArr, 'riviTable__cell--required']
                "
                scope="col"
              >
                {{ $t("common:reminderFee") }}
              </th>
            </template>
            <th
              :class="[...thClassArr, 'riviTable__cell--required']"
              scope="col"
            >
              {{ $t("common:invoiceRef") }}
            </th>
            <th
              :class="[
                ...thClassArr,
                'riviTable__cell--required',
                'text-right',
              ]"
              scope="col"
            >
              {{ $t("common:capital") }}
            </th>
            <th :class="thClassArr" scope="col">
              {{ $t("common:definition") }}
            </th>
            <th :class="thClassArr" scope="col">
              {{ $t("common:lateInterest") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in toimeksianto.rivit"
            :key="item.index"
            @input="lahetaEventKayttajasyote"
          >
            <td :class="[...tdClassArr, 'riviTable__cell--min-width-medium']">
              <v-text-field
                v-model="item.laskunumero"
                type="text"
                :rules="[$validationRules.required]"
                @keydown.enter="toimeksianto.lisaaRivi(index)"
              />
            </td>
            <td :class="tdClassArr">
              <v-menu
                v-model="item.laskupaivaMenu"
                :close-on-content-click="false"
                max-width="290"
              >
                <template #activator="{ on }">
                  <v-text-field
                    :value="$dateFnsFormat(item.laskupaiva)"
                    readonly
                    :rules="[$validationRules.required]"
                    v-on="on"
                  />
                </template>
                <template #default>
                  <v-date-picker
                    v-model="item.laskupaiva"
                    :locale="$languageLocale()"
                    first-day-of-week="1"
                    no-title
                    @change="item.laskupaivaMenu = false"
                  />
                </template>
              </v-menu>
            </td>
            <td :class="tdClassArr">
              <v-menu
                v-model="item.erapaivaMenu"
                :close-on-content-click="false"
                max-width="290"
              >
                <template #activator="{ on }">
                  <v-text-field
                    :value="$dateFnsFormat(item.erapaiva)"
                    readonly
                    :rules="[$validationRules.required]"
                    v-on="on"
                  />
                </template>
                <template #default>
                  <v-date-picker
                    v-model="item.erapaiva"
                    :locale="$languageLocale()"
                    first-day-of-week="1"
                    no-title
                    @change="item.erapaivaMenu = false"
                  />
                </template>
              </v-menu>
            </td>
            <template v-if="toimeksianto.muistutettu">
              <td :class="tdClassArr">
                <v-menu
                  v-model="item.muistutuspaivaMenu"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template #activator="{ on }">
                    <v-text-field
                      :value="$dateFnsFormat(item.muistutuspaiva)"
                      readonly
                      :rules="
                        toimeksianto.muistutettu && !saatavakohtainenMuistutus
                          ? [$validationRules.required]
                          : []
                      "
                      v-on="on"
                    />
                  </template>
                  <template #default>
                    <v-date-picker
                      v-model="item.muistutuspaiva"
                      :locale="$languageLocale()"
                      first-day-of-week="1"
                      no-title
                      @change="item.muistutuspaivaMenu = false"
                    />
                  </template>
                </v-menu>
              </td>
              <td :class="tdClassArr">
                <v-menu
                  v-model="item.muistutuserapaivaMenu"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template #activator="{ on }">
                    <v-text-field
                      :value="$dateFnsFormat(item.muistutuserapaiva)"
                      readonly
                      :rules="
                        toimeksianto.muistutettu && !saatavakohtainenMuistutus
                          ? [$validationRules.required]
                          : []
                      "
                      v-on="on"
                    />
                  </template>
                  <template #default>
                    <v-date-picker
                      v-model="item.muistutuserapaiva"
                      :locale="$languageLocale()"
                      first-day-of-week="1"
                      no-title
                      @change="item.muistutuserapaivaMenu = false"
                    />
                  </template>
                </v-menu>
              </td>
              <td :class="tdClassArr">
                <v-text-field
                  v-model="item.muistutuskulu"
                  type="number"
                  :rules="
                    toimeksianto.muistutettu && !saatavakohtainenMuistutus
                      ? [
                          $validationRules.required,
                          $validationRules.maxDecimalPlacesOrEmpty(
                            item.muistutuskulu,
                            2
                          ),
                          $validationRules.zeroOrBigger,
                        ]
                      : [
                          $validationRules.maxDecimalPlacesOrEmpty(
                            item.muistutuskulu,
                            2
                          ),
                          $validationRules.zeroOrBiggerWithDecimal,
                        ]
                  "
                  required
                  reverse
                  @keydown.enter="toimeksianto.lisaaRivi(index)"
                />
              </td>
            </template>
            <td :class="tdClassArr">
              <v-text-field
                v-model="item.pm_viite"
                type="text"
                reverse
                @keydown.enter="toimeksianto.lisaaRivi(index)"
              />
            </td>
            <td :class="tdClassArr">
              <v-text-field
                v-model="item.paaoma"
                type="number"
                :rules="[
                  $validationRules.required,
                  $validationRules.maxDecimalPlacesOrEmpty(item.paaoma, 2),
                  $validationRules.zeroOrBigger,
                ]"
                required
                reverse
                @keydown.enter="toimeksianto.lisaaRivi(index)"
              />
            </td>
            <td :class="tdClassArr">
              <v-text-field
                v-model="item.selite"
                type="text"
                reverse
                @keydown.enter="toimeksianto.lisaaRivi(index)"
              />
            </td>
            <td :class="tdClassArr">
              <v-text-field
                v-if="toimeksianto.velallinen.tyyppi === 'Y'"
                v-model="item.viivastyskorko"
                type="number"
                :rules="[
                  $validationRules.maxDecimalPlacesOrEmpty(
                    item.viivastyskorko,
                    2
                  ),
                  $validationRules.numberOrEmptyWithDecimal,
                  $validationRules.zeroOrBiggerWithDecimal,
                  $validationRules.smallerThanOrEqualToWithDecimal(
                    item.viivastyskorko,
                    100
                  ),
                ]"
                suffix="%"
                :placeholder="$t('common:accordingToKorkolaki')"
                @keydown.enter="toimeksianto.lisaaRivi(index)"
              />
              <v-select
                v-else
                v-model="item.viivastyskorko"
                :items="viivastyskorkoItems"
              />
            </td>
            <td class="riviTable__cell--btn">
              <LomakenappiIkonilla
                v-if="salliPoistaminen"
                ikoni="delete"
                :infoteksti="$t('deleteRow')"
                nayta-aina
                @click="poistaRivi(index)"
              />
            </td>
          </tr>
        </tbody>
        <tfoot v-if="naytaYhteenveto" class="riviTable__footer">
          <div class="mt-5"></div>
          <tr>
            <td>
              <v-btn color="primary" @click="toimeksianto.lisaaRivi()">
                <v-icon left icon>library_add</v-icon>
                {{ $t("common:newRow") }}
              </v-btn>
            </td>
            <th
              class="riviTable__cell riviTable__cell--footer"
              colspan="3"
              scope="row"
            >
              {{ $t("common:total") }}
            </th>
            <td class="riviTable__cell riviTable__cell--footer">
              {{ paaomaYhteensa | formatSumToFixed2 }}
            </td>
            <td class="riviTable__cell riviTable__cell--footer"></td>
            <td class="riviTable__cell riviTable__cell--footer"></td>
          </tr>
        </tfoot>
      </table>
    </v-form>
  </div>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";
import LomakenappiIkonilla from "@/components/LomakenappiIkonilla";

export default {
  name: "SaatavariviTable",
  components: {
    LomakenappiIkonilla,
  },
  mixins: [FormMixin],
  model: {
    prop: "toimeksianto",
  },
  props: {
    toimeksianto: {
      type: Object,
      required: true,
    },
    salliPoistaminen: {
      type: Boolean,
      required: false,
      default() {
        return true;
      },
    },
    naytaYhteenveto: {
      type: Boolean,
      required: false,
      default() {
        return true;
      },
    },
    cellSize: {
      type: String,
      required: false,
      validator: (arvo) => ["small", "medium"].includes(arvo),
      default() {
        return "medium";
      },
    },
  },
  data() {
    return {
      viivastyskorkoItems: [
        {
          value: null,
          text: this.$t("common:accordingToKorkolaki"),
        },
        {
          value: 0.00,
          text: "0 %",
        },
      ],
    }
  },
  computed: {
    thClassArr() {
      return [
        "riviTable__cell",
        "riviTable__cell--header",
        this.cellSize === "small"
          ? "riviTable__cell--small"
          : "riviTable__cell--medium",
      ];
    },
    tdClassArr() {
      return [
        "riviTable__cell",
        this.cellSize === "small"
          ? "riviTable__cell--small"
          : "riviTable__cell--medium",
      ];
    },
    paaomaYhteensa() {
      let summa = 0;

      for (const sr of this.toimeksianto.rivit) {
        summa += parseFloat(sr.paaoma) || 0;
      }

      return summa;
    },
    saatavakohtainenMuistutus() {
      return this.$permission.checkSinglePerm(
        "onlinerestapi.onlinerestapi_toimeksianto_saatavakohtainen_muistutus"
      );
    },
  },
  methods: {
    poistaRivi(rowIndex) {
      if (!this.toimeksianto.rivit[rowIndex].isEmpty()) {
        this.$swal({
          text: this.$t("common:saatavariviTable.sureReceivablesRowDeleted"),
          showCancelButton: true,
          focusCancel: true,
          confirmButtonText: this.$t(
            "common:saatavariviTable.deleteReceivablesRow"
          ),
          cancelButtonText: this.$t("common:saatavariviTable.cancel"),
        }).then((result) => {
          if (!result.value) return;

          this.toimeksianto.poistaRivi(rowIndex);
          this.lahetaEventKayttajasyote();
        });
      } else {
        this.toimeksianto.poistaRivi(rowIndex);
        this.lahetaEventKayttajasyote();
      }
    },
    lahetaEventKayttajasyote() {
      this.$emit("kayttajasyote");
    },
    validate() {
      return this.$refs.saatavariviTable.validate();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/riviTableStyles.scss";
</style>
