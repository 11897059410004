var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "pa-6", class: _vm.cardClass },
    [
      _c(
        "v-row",
        { staticClass: "mx-0" },
        [
          _c(
            "v-col",
            { staticClass: "pa-0 pr-4", attrs: { md: "3" } },
            [
              _vm._t("col1", function () {
                return [
                  _vm.paamies
                    ? _c("OsapuoltenTiedotKappale", {
                        attrs: {
                          paamies: _vm.paamies,
                          vastapuoli: _vm.vastapuoli,
                          "ei-vastapuoli-linkkia": _vm.eiVastapuoliLinkkia,
                          "vastapuoli-rooli": _vm.vastapuoliRooli,
                          "vastapuoli-route": _vm.vastapuoliRoute,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "vastaanottaja",
                              fn: function () {
                                return [_vm._t("vastaanottaja")]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
          _c(
            "v-col",
            { staticClass: "py-0 pl-0 pr-6", attrs: { md: "3" } },
            [_vm._t("col2")],
            2
          ),
          _c(
            "v-col",
            { staticClass: "py-0 px-3", attrs: { md: "3" } },
            [_vm._t("col3")],
            2
          ),
          _c(
            "v-col",
            { staticClass: "py-0 pl-6 pr-0", attrs: { md: "3" } },
            [_vm._t("col4")],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }