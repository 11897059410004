import Vue from "vue";
import store from "@/store";

const getRoutePermissions = (route) => {
  // Get permissions for this route
  const routePermissions =
    route.meta && route.meta.permissions ? route.meta.permissions : [];
  let parentPermissions = [];

  // Inherit permissions from the parent routes
  if (route.matched) {
    for (const matchedRoute of route.matched) {
      if (matchedRoute.meta && matchedRoute.meta.permissions) {
        parentPermissions = [
          ...parentPermissions,
          ...matchedRoute.meta.permissions,
        ];
      }
    }
  }

  // Set removes duplicates
  return [...new Set([...routePermissions, ...parentPermissions])];
};

const getUserPerms = (userPermissions = []) => {
  return userPermissions.length
    ? userPermissions
    : store.state.user.permissions;
};

const match = (route, tarkistaPaamieskohtaisetOikeudet = true) => {
  const userPermissions = getUserPerms();

  try {
    const requiredPermissions = getRoutePermissions(route);

    if (!requiredPermissions.length) return true;

    for (const permission of requiredPermissions) {
      // console.log('Matching for: ' + permission)

      if (!userPermissions.includes(permission))
        throw new Error("Permission check failed: " + permission);

      if (
        store.getters["user/tarkistetaankoPaamieskohtaisetOikeudet"] &&
        tarkistaPaamieskohtaisetOikeudet
      ) {
        const paamiesPerms = store.getters["user/getValittuPaamiesPerms"];
        // Tarkistetaan käyttäjän päämieskohtaiset oikeudet mikäli käyttäjä ei ole henkilökuntaa
        if (!paamiesPerms.includes(permission))
          throw new Error("Permission check failed: " + permission);
      }
    }

    return true;
  } catch (e) {
    // console.warn(e);

    return false;
  }
};

const checkSinglePerm = (permToCheck, paamiesId = null) => {
  const userPermissions = getUserPerms();
  if (!userPermissions.includes(permToCheck)) return false;
  // Tarkistetaan käyttäjän päämieskohtaiset oikeudet mikäli käyttäjä ei ole henkilökuntaa
  if (store.getters["user/tarkistetaankoPaamieskohtaisetOikeudet"]) {
    let paamiesPerms = [];
    if (paamiesId) {
      paamiesPerms = store.getters["user/getPaamiesPerms"](paamiesId);
    } else {
      paamiesPerms = store.getters["user/getValittuPaamiesPerms"];
    }
    if (!paamiesPerms.includes(permToCheck)) return false;
  }

  return true;
};

const permissionModule = {
  match,
  checkSinglePerm,
};

Vue.prototype.$permission = permissionModule;

export default permissionModule;
