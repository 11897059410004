<template>
  <div class="error404">
    <div class="sticky">
      <v-toolbar color="primary" flat tabs>
        <v-toolbar-title v-if="$route.meta.title">
          {{ $route.meta.title }}
        </v-toolbar-title>
      </v-toolbar>
    </div>

    <v-container fluid>
      <p>
        <strong>
          Sinulla ei ole tarvittavia käyttöoikeuksia pyydetyn toiminnon
          suorittamiseksi!
        </strong>
      </p>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Error404",
};
</script>

<style lang="scss" scoped></style>
