<template>
  <v-form ref="form" v-model="valid" class="white--text" @submit.prevent>
    <v-row>
      <v-col v-if="!$store.state.user.vainYksiPaamies" class="px-8 py-4" md="4">
        <FormTable>
          <FormTableRow>
            <FormTableCell :header="true">
              <FormTableLabel for="paamies">
                {{ $t("common:creditor") }}
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-theme-provider dark>
                <AutocompletePaamies
                  v-model="value.paamies"
                  label=""
                  tarkistettava-oikeus="onlinerestapi.onlinerestapi_tilitys"
                />
              </v-theme-provider>
            </FormTableCell>
          </FormTableRow>
        </FormTable>
      </v-col>
      <v-col class="px-8 py-4" md="4">
        <FormTable>
          <FormTableRow>
            <FormTableCell :header="true">
              <FormTableLabel for="luotu">
                {{ $t("common:settlementDate") }}
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <div class="comboField">
                <InputfieldDatePicker
                  id="luotuAlkaen"
                  v-model="value.luotuAlkaen"
                  :label="$t('common:starting')"
                  color="white"
                  :dark="true"
                />
                <span class="comboField__separator">&ndash;</span>
                <InputfieldDatePicker
                  id="luotuPaattyen"
                  v-model="value.luotuPaattyen"
                  :label="$t('common:ending')"
                  color="white"
                  :dark="true"
                />
              </div>
            </FormTableCell>
          </FormTableRow>
        </FormTable>
      </v-col>
      <v-col class="px-8 py-4" md="4">
        <FormTable>
          <FormTableRow>
            <FormTableCell :header="true">
              <FormTableLabel for="tyyppi">
                {{ $t("common:type") }}
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-select
                id="tila"
                v-model="value.tyyppi"
                :items="tyypit"
                item-text="label"
                item-value="terms"
                multiple
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
        </FormTable>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";
import AutocompletePaamies from "@/components/AutocompletePaamies";
import FormTable from "@/components/FormTable";
import FormTableRow from "@/components/FormTableRow";
import FormTableCell from "@/components/FormTableCell";
import FormTableLabel from "@/components/FormTableLabel";
import InputfieldDatePicker from "@/components/InputfieldDatePicker";
import { SelectablesTilitysTyypit } from "@/utils/selectables";

export default {
  name: "FormSearchTilitys",
  components: {
    AutocompletePaamies,
    FormTable,
    FormTableRow,
    FormTableCell,
    FormTableLabel,
    InputfieldDatePicker,
  },
  mixins: [FormMixin],
  data() {
    return {
      tyypit: SelectablesTilitysTyypit,
    };
  },
};
</script>

<style lang="scss" scoped></style>
