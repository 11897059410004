var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "form",
      staticClass: "white--text",
      on: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "px-8 py-4", attrs: { md: "4" } },
            [
              _c(
                "FormTable",
                [
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c(
                            "FormTableLabel",
                            { attrs: { for: "laskunumeroAlkaen" } },
                            [_vm._v(" Laskunumero ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "laskunumero",
                              type: "number",
                              label: "",
                              color: "white",
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.laskunumero,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "laskunumero", $$v)
                              },
                              expression: "value.laskunumero",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c("FormTableLabel", { attrs: { for: "sopimus" } }, [
                            _vm._v("Sopimus"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "sopimus",
                              type: "number",
                              color: "white",
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.sopimus,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "sopimus", $$v)
                              },
                              expression: "value.sopimus",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _vm.ostolasku
                            ? [
                                _c(
                                  "FormTableLabel",
                                  { attrs: { for: "toimittaja" } },
                                  [_vm._v("Toimittaja")]
                                ),
                              ]
                            : [
                                _c(
                                  "FormTableLabel",
                                  { attrs: { for: "asiakas" } },
                                  [_vm._v("Asiakas")]
                                ),
                              ],
                        ],
                        2
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _vm.ostolasku
                            ? [
                                _c("v-text-field", {
                                  attrs: {
                                    id: "toimittaja",
                                    color: "white",
                                    label: "Nimi, tunnus tai toimittajanumero",
                                    clearable: "",
                                    dark: true,
                                  },
                                  model: {
                                    value: _vm.value.toimittaja,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.value, "toimittaja", $$v)
                                    },
                                    expression: "value.toimittaja",
                                  },
                                }),
                              ]
                            : [
                                _c("v-text-field", {
                                  attrs: {
                                    id: "asiakas",
                                    color: "white",
                                    label: "Nimi, tunnus tai asiakasnumero",
                                    clearable: "",
                                    dark: true,
                                  },
                                  model: {
                                    value: _vm.value.asiakas,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.value, "asiakas", $$v)
                                    },
                                    expression: "value.asiakas",
                                  },
                                }),
                              ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _vm.ostolasku
                            ? [
                                _c(
                                  "FormTableLabel",
                                  { attrs: { for: "toimittajaTyyppi" } },
                                  [_vm._v(" Toimittajan tyyppi ")]
                                ),
                              ]
                            : [
                                _c(
                                  "FormTableLabel",
                                  { attrs: { for: "asiakasTyyppi" } },
                                  [_vm._v(" Asiakkaan tyyppi ")]
                                ),
                              ],
                        ],
                        2
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _vm.ostolasku
                            ? [
                                _c("v-select", {
                                  attrs: {
                                    id: "toimittajaTyyppi",
                                    items: _vm.toimittajaTyypit,
                                    "item-text": "label",
                                    "item-value": "terms",
                                    multiple: "",
                                    clearable: "",
                                    dark: true,
                                  },
                                  model: {
                                    value: _vm.value.toimittajaTyyppi,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.value,
                                        "toimittajaTyyppi",
                                        $$v
                                      )
                                    },
                                    expression: "value.toimittajaTyyppi",
                                  },
                                }),
                              ]
                            : [
                                _c("v-select", {
                                  attrs: {
                                    id: "asiakasTyyppi",
                                    items: _vm.asiakasTyypit,
                                    "item-text": "label",
                                    "item-value": "terms",
                                    multiple: "",
                                    clearable: "",
                                    dark: true,
                                  },
                                  model: {
                                    value: _vm.value.asiakasTyyppi,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.value, "asiakasTyyppi", $$v)
                                    },
                                    expression: "value.asiakasTyyppi",
                                  },
                                }),
                              ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c(
                            "FormTableLabel",
                            { attrs: { for: "laskuLisaTietoAvain" } },
                            [_vm._v(" Lisätietoavain ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _vm.onkoLisatietoAvaimia
                            ? [
                                _c("v-select", {
                                  attrs: {
                                    id: "laskuLisaTietoAvain",
                                    items:
                                      _vm.yleinen.laskulisatietoAvaimet.keys,
                                    "item-text": "Lisätietoavain",
                                    clearable: "",
                                    multiple: "",
                                    dark: true,
                                  },
                                  model: {
                                    value: _vm.value.lisaTietoAvain,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.value, "lisaTietoAvain", $$v)
                                    },
                                    expression: "value.lisaTietoAvain",
                                  },
                                }),
                              ]
                            : [
                                _c("v-text-field", {
                                  attrs: {
                                    id: "laskuLisaTietoAvainText",
                                    color: "white",
                                    label: "Lisätietoavain",
                                    clearable: "",
                                    dark: true,
                                  },
                                  model: {
                                    value: _vm.value.lisaTietoAvain,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.value, "lisaTietoAvain", $$v)
                                    },
                                    expression: "value.lisaTietoAvain",
                                  },
                                }),
                              ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "px-8 py-4", attrs: { md: "4" } },
            [
              _c(
                "FormTable",
                [
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c("FormTableLabel", { attrs: { for: "tila" } }, [
                            _vm._v("Tila"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-select", {
                            attrs: {
                              id: "tila",
                              items: _vm.tilat,
                              "item-text": "label",
                              "item-value": "terms",
                              multiple: "",
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.tila,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "tila", $$v)
                              },
                              expression: "value.tila",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c("FormTableLabel", { attrs: { for: "paamies" } }, [
                            _vm._v("Päämies"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "paamies",
                              color: "white",
                              label: "Nimi tai numero",
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.paamies,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "paamies", $$v)
                              },
                              expression: "value.paamies",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c(
                            "FormTableLabel",
                            { attrs: { for: "laskupaivaAlkaen" } },
                            [_vm._v("Laskupäivä")]
                          ),
                        ],
                        1
                      ),
                      _c("FormTableCell", [
                        _c(
                          "div",
                          { staticClass: "comboField" },
                          [
                            _c("InputfieldDatePicker", {
                              attrs: {
                                id: "laskupaivaAlkaen",
                                label: "Alkaen",
                                color: "white",
                                dark: true,
                              },
                              model: {
                                value: _vm.value.laskupaivaAlkaen,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "laskupaivaAlkaen", $$v)
                                },
                                expression: "value.laskupaivaAlkaen",
                              },
                            }),
                            _c(
                              "span",
                              { staticClass: "comboField__separator" },
                              [_vm._v("–")]
                            ),
                            _c("InputfieldDatePicker", {
                              attrs: {
                                id: "laskupaivaPaattyen",
                                label: "Päättyen",
                                color: "white",
                                dark: true,
                              },
                              model: {
                                value: _vm.value.laskupaivaPaattyen,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "laskupaivaPaattyen", $$v)
                                },
                                expression: "value.laskupaivaPaattyen",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c(
                            "FormTableLabel",
                            { attrs: { for: "erapaivaAlkaen" } },
                            [_vm._v("Eräpäivä")]
                          ),
                        ],
                        1
                      ),
                      _c("FormTableCell", [
                        _c(
                          "div",
                          { staticClass: "comboField" },
                          [
                            _c("InputfieldDatePicker", {
                              attrs: {
                                id: "erapaivaAlkaen",
                                label: "Alkaen",
                                color: "white",
                                dark: true,
                              },
                              model: {
                                value: _vm.value.erapaivaAlkaen,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "erapaivaAlkaen", $$v)
                                },
                                expression: "value.erapaivaAlkaen",
                              },
                            }),
                            _c(
                              "span",
                              { staticClass: "comboField__separator" },
                              [_vm._v("–")]
                            ),
                            _c("InputfieldDatePicker", {
                              attrs: {
                                id: "erapaivaPaattyen",
                                label: "Päättyen",
                                color: "white",
                                dark: true,
                              },
                              model: {
                                value: _vm.value.erapaivaPaattyen,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "erapaivaPaattyen", $$v)
                                },
                                expression: "value.erapaivaPaattyen",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c(
                            "FormTableLabel",
                            { attrs: { for: "laskuLisaTietoArvo" } },
                            [_vm._v(" Lisätietoarvo ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "laskuLisaTietoArvo",
                              color: "white",
                              label: "Lisätietoarvo",
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.lisaTietoArvo,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "lisaTietoArvo", $$v)
                              },
                              expression: "value.lisaTietoArvo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "px-8 py-4", attrs: { md: "4" } },
            [
              _c(
                "FormTable",
                [
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c("FormTableLabel", { attrs: { for: "viite" } }, [
                            _vm._v("Viite"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "viite",
                              type: "text",
                              color: "white",
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.viite,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "viite", $$v)
                              },
                              expression: "value.viite",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c(
                            "FormTableLabel",
                            { attrs: { for: "viitteemme" } },
                            [_vm._v("Viitteemme")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "viitteemme",
                              type: "text",
                              color: "white",
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.viitteemme,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "viitteemme", $$v)
                              },
                              expression: "value.viitteemme",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c(
                            "FormTableLabel",
                            { attrs: { for: "viitteenne" } },
                            [_vm._v("Viitteenne")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "viitteenne",
                              type: "text",
                              color: "white",
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.viitteenne,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "viitteenne", $$v)
                              },
                              expression: "value.viitteenne",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c("FormTableLabel", { attrs: { for: "pmViite" } }, [
                            _vm._v("PM-viite"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "pmViite",
                              type: "text",
                              color: "white",
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.pmViite,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "pmViite", $$v)
                              },
                              expression: "value.pmViite",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _vm.rahoitusKaytossa
                        ? _c(
                            "FormTableCell",
                            { attrs: { header: true } },
                            [
                              _c(
                                "FormTableLabel",
                                {
                                  staticClass: "mt-n3",
                                  attrs: { for: "rahoitus" },
                                },
                                [_vm._v(" Rahoitettu ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.rahoitusKaytossa
                        ? _c(
                            "FormTableCell",
                            [
                              _c("v-checkbox", {
                                staticClass: "mt-n1",
                                attrs: {
                                  id: "rahoitus",
                                  color: "white",
                                  dark: true,
                                },
                                model: {
                                  value: _vm.value.rahoitus,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.value, "rahoitus", $$v)
                                  },
                                  expression: "value.rahoitus",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }