<template>
  <div v-if="text" class="versionRibbon">
    <span class="versionRibbon__text">
      {{ text }}
    </span>
  </div>
</template>

<script>
export default {
  name: "VersionRibbon",
  props: {
    text: {
      type: [String, Number],
      required: false,
      default() {
        return null;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.versionRibbon {
  display: block;
  position: fixed;
  left: -4em;
  top: 0;
  width: 10em;
  color: #fff;
  font-size: 1.8em;
  font-weight: bold;
  text-transform: uppercase;
  background: rgba(0, 0, 0, 0.75);
  z-index: 99999;
  transform: translateX(1em) translateY(1em) rotate(-45deg);
  transform-origin: center center;

  &__text {
    display: block;
    text-align: center;
    line-height: 1;
    padding: 0.25em 0;
    margin: 0.25em 0;
    border-top: solid 0.075em #fff;
    border-bottom: solid 0.075em #fff;
  }
}
</style>
