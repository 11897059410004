var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-card", { ref: "wrap", staticClass: "card-dialog" }, [
    _vm.title || _vm.showCloseButton
      ? _c(
          "div",
          { staticClass: "card-dialog__header" },
          [
            _c(
              "v-row",
              { class: _vm.titleClass, attrs: { align: "center" } },
              [
                _c("v-col", { staticClass: "px-4" }, [
                  _vm.title
                    ? _c(
                        "h2",
                        { staticClass: "text-h5 modal-drag kasi" },
                        [
                          _vm._v(" " + _vm._s(_vm.title) + " "),
                          _vm.tooltipInfoTeksti
                            ? _c("TooltipInfo", {
                                attrs: {
                                  text: _vm.tooltipInfoTeksti,
                                  "icon-color": "primary",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _c(
                  "v-col",
                  { staticClass: "px-4 shrink" },
                  [
                    _vm.showCloseButton
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "ma-0 nuoli",
                            attrs: { icon: "" },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("close")
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v("close")])],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.$slots["default"]
      ? _c(
          "div",
          {
            ref: "content",
            staticClass: "card-dialog__content modal-content",
            class: _vm.contentClass,
          },
          [_vm._t("default")],
          2
        )
      : _vm._e(),
    _vm.$slots["footer"]
      ? _c("div", { staticClass: "card-dialog__footer pa-4" }, [
          _c("div", { class: _vm.footerClass }, [_vm._t("footer")], 2),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }