import BaseSelaa from "@/store/modules/baseSelaa";

export default class OstolaskuToimittajaSelaa extends BaseSelaa {
  state() {
    return {
      ...super.state(),
      apiName: "Toimittajat",
    };
  }
}
