<template>
  <v-tooltip class="tooltip" :disabled="!tooltipTeksti || !disabled" top>
    <template #activator="{ on }">
      <div v-on="on">
        <v-btn
          :ref="reference"
          :color="color"
          :disabled="disabled"
          :loading="loading"
          class="ml-2"
          :to="to"
          @click="click"
        >
          <v-icon v-if="icon" left>
            {{ icon }}
          </v-icon>
          <slot>
            {{ $t("common:button") }}
          </slot>
        </v-btn>
      </div>
    </template>
    <template v-if="tooltipTeksti && disabled" #default>
      {{ tooltipTeksti }}
    </template>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: false,
      default() {
        return "primary";
      },
    },
    click: {
      type: Function,
      required: false,
      default() {
        return null;
      },
    },
    icon: {
      type: String,
      required: false,
      default() {
        return "";
      },
    },
    to: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    reference: {
      type: String,
      required: false,
      default() {
        return "refBtn";
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    loading: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    tooltipTeksti: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>

<style></style>
