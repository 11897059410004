<template>
  <v-form ref="formSopimus" @submit.prevent="submit">
    <div class="paper px-6 py-12 mb-12">
      <v-row>
        <!-- Vasen sarake -->
        <v-col class="pa-6" md="6">
          <span class="paper__title hidden-md-and-up">Toistuva lasku</span>

          <!-- Päämies -->
          <v-hover v-slot="{ hover }" open-delay="50">
            <v-card
              class="pa-2 show-hidden-child-on-hover"
              :class="
                hover &&
                !muokkaamassaSopimusta &&
                $store.getters['user/salliPaamiehenTyhjennys']
                  ? 'elevation-2 hoverBackground'
                  : 'elevation-0'
              "
            >
              <div>
                <template v-if="sopimus.paamies.id">
                  <v-row justify="space-between">
                    <v-col cols="6" class="py-0">
                      <h3>Laskuttaja</h3>
                    </v-col>
                    <v-col cols="3" class="col-icon py-0">
                      <template
                        v-if="
                          !muokkaamassaSopimusta &&
                          $store.getters['user/salliPaamiehenTyhjennys']
                        "
                      >
                        <LomakenappiIkonilla
                          ikoni="clear"
                          :infoteksti="$t('clear')"
                          @click="tyhjennaPaamies"
                        />
                        <LomakenappiIkonilla
                          v-if="
                            $permission.checkSinglePerm(
                              'onlinerestapi.onlinerestapi_paamieshallinta'
                            )
                          "
                          ikoni="edit"
                          :infoteksti="$t('editInvoicer')"
                          @click="$emit('avaa-dialog-paamies')"
                        />
                      </template>
                    </v-col>
                  </v-row>
                  <OsoiteKappale
                    :renderoi-tyhja="true"
                    :nayta-nimi="true"
                    :nimi="sopimus.paamies.nimi"
                    :postiosoite="
                      sopimus.paamies.postiosoite
                        ? sopimus.paamies.postiosoite.postiosoite
                        : null
                    "
                    :postinumero="
                      sopimus.paamies.postiosoite
                        ? sopimus.paamies.postiosoite.postinumero
                        : null
                    "
                    :postitoimipaikka="
                      sopimus.paamies.postiosoite
                        ? sopimus.paamies.postiosoite.postitoimipaikka
                        : null
                    "
                  />
                </template>
                <template v-else>
                  <AutocompletePaamies
                    ref="autocompletePaamies"
                    v-model="sopimus.paamies"
                    :paamieshallinta="true"
                    tarkistettava-oikeus="onlinerestapi.onlinerestapi_sopimus"
                    @open-dialog-paamies="$emit('avaa-dialog-paamies')"
                  />
                </template>
              </div>
            </v-card>
          </v-hover>

          <!-- Asiakkaat -->
          <template v-if="sopimus.paamies && sopimus.paamies.id">
            <div
              :class="{
                'py-3': $vuetify.breakpoint.smAndDown,
                'py-5': $vuetify.breakpoint.mdAndUp,
              }"
            ></div>
            <v-hover
              v-for="(sopimusasiakas, index) in sopimus.sopimusasiakas_set"
              :key="index"
              v-slot="{ hover }"
              open-delay="50"
            >
              <v-card
                class="pa-2 show-hidden-child-on-hover"
                :class="hover ? 'elevation-2 hoverBackground' : 'elevation-0'"
              >
                <SopimusAsiakasKappale
                  v-model="sopimusasiakas.asiakas"
                  :asiakas-items-exclude="asiakasIds"
                  :paamies="sopimus.paamies"
                  :muokkaamassa-sopimusta="muokkaamassaSopimusta"
                  :rooliselite="sopimusrooli2Selite(sopimusasiakas)"
                  :rooli-info="rooliInfo(sopimusasiakas)"
                  @avaa-dialog-asiakas="
                    $emit('avaa-dialog-asiakas', sopimusasiakas)
                  "
                  @avaa-dialog-asiakas-muistiinpanot="
                    $emit('avaa-dialog-asiakas-muistiinpanot', sopimusasiakas)
                  "
                />
                <v-card-actions v-if="sopimus.sopimusasiakas_set.length > 1">
                  <LomakenappiIkonilla
                    v-if="!muokkaamassaSopimusta"
                    ikoni="delete"
                    :infoteksti="$t('common:contractSave.deleteRecipient')"
                    nayta-aina
                    @click="sopimus.poistaAsiakas(index)"
                  />
                  <LomakenappiIkonilla
                    v-if="
                      !muokkaamassaSopimusta &&
                      sopimus.sopimusasiakas_set[index].rooli_id !==
                        rooliLaskutusasiakasId &&
                      sopimus.sopimusasiakas_set[index].asiakas.id
                    "
                    ikoni="supervisor_account"
                    :infoteksti="$t('common:contractSave.setInvoicingCustomer')"
                    nayta-aina
                    @click="sopimus.asetaLaskutusasiakas(index)"
                  />
                </v-card-actions>
              </v-card>
            </v-hover>
            <v-btn
              v-if="
                !muokkaamassaSopimusta &&
                $permission.checkSinglePerm(
                  'onlinerestapi.onlinerestapi_sopimus_usea_asiakas'
                )
              "
              color="primary"
              @click="sopimus.lisaaAsiakas()"
            >
              <v-icon left icon>library_add</v-icon>
              {{ $t("common:contractSave.newRecipient") }}
            </v-btn>
          </template>
        </v-col>

        <!-- Oikea sarake -->
        <v-col class="pa-6" md="6">
          <v-col class="pa-2 pt-0">
            <span class="paper__title hidden-sm-and-down">
              Toistuva lasku / sopimus
            </span>
            <v-select
              v-model="sopimus.kirjepohja_id"
              label="Laskupohja"
              type="text"
              :items="laskupohjatItems"
              :placeholder="
                !sopimus.paamies.id ? 'Valitse ensin laskuttaja' : ''
              "
              persistent-placeholder
              item-text="selite"
              item-value="value"
              no-data-text="Ei laskupohjia"
              class="mt-1 pt-1 required"
              :rules="[$validationRules.required]"
            />
            <v-text-field
              v-model="sopimus.viitteemme"
              label="Viitteemme"
              type="text"
              :counter="sopimus.viitteemme.length > 255 ? 255 : undefined"
              :rules="[
                $validationRules.tooManyCharacters(
                  sopimus.viitteemme.length,
                  255
                ),
              ]"
              class="mt-1 pt-1"
            />
            <v-text-field
              v-model="sopimus.viitteenne"
              label="Viitteenne"
              type="text"
              :counter="sopimus.viitteenne.length > 255 ? 255 : undefined"
              :rules="[
                $validationRules.tooManyCharacters(
                  sopimus.viitteenne.length,
                  255
                ),
              ]"
              class="mt-1 pt-1"
            />
            <v-textarea
              v-model="sopimus.laskunteksti"
              label="Laskulla näkyvä teksti"
              type="text"
              rows="2"
              :counter="sopimus.laskunteksti.length > 4098 ? 4098 : undefined"
              :rules="[
                $validationRules.tooManyCharacters(
                  sopimus.laskunteksti.length,
                  4098
                ),
              ]"
              class="mt-1 pt-1"
            />
          </v-col>
          <v-card
            v-if="
              odotusaikaYlitetty && sopimus.paamies.id && !sopimus.kirjepohja_id
            "
            class="pa-4"
            color="warning"
          >
            <span class="text-subtitle-1">
              Huom.! Toistuvalle laskulle ei ole määritetty laskupohjaa. Sitä ei
              voida tallentaa.
            </span>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <!-- Laskurivit -->
          <div class="pa-6">
            <h2 class="mb-4">Laskurivit</h2>
            <v-form
              v-if="!piilotaLaskuriviTable"
              :disabled="muokkaamassaSopimusta"
            >
              <LaskuriviTable
                ref="laskuriviTable"
                v-model="sopimus"
                tyyppi="sopimus"
                cell-size="small"
                :salli-poistaminen="!muokkaamassaSopimusta"
                :nayta-uusi-rivi-btn="!muokkaamassaSopimusta"
                :tuoterekisteri="!muokkaamassaSopimusta"
                :disabled="muokkaamassaSopimusta"
                @kayttajasyote="$emit('laskurivitableKayttajasyote')"
              />
              <v-row v-if="!muokkaamassaSopimusta" class="mt-8">
                <v-col cols="8">
                  <AutocompleteLaskurivi
                    :lasku="sopimus"
                    tyyppi="sopimus"
                    @autocomplete-input="kasitteleAutocompleteLaskuriviInput"
                  />
                </v-col>
              </v-row>
              <v-card
                v-else
                class="mt-8 elevation-2 pa-4 bg-color-secondary-lighten2"
              >
                <p class="font-weight-bold text-subtitle-1 mb-0">
                  <v-icon class="mr-2" color="white">info</v-icon>
                  <span>
                    Voit muokata laskurivejä ohjaustietojen muutosten
                    tallentamisen jälkeen.
                  </span>
                </p>
              </v-card>
            </v-form>
            <p v-else class="font-no-data">Ei laskurivejä</p>
          </div>
        </v-col>
      </v-row>
    </div>

    <div class="paper px-6 py-6 mb-12">
      <v-row>
        <v-col class="pa-6" md="6">
          <h2 class="text-h5 mb-8">Laskujen lähetyksen määrittely</h2>
          <div class="comboField">
            <v-menu
              v-model="alkamispaivaMenu"
              :close-on-content-click="false"
              max-width="290"
            >
              <template #activator="{ on }">
                <v-text-field
                  label="Alkamispäivä"
                  :value="$dateFnsFormat(sopimus.alkamispaiva)"
                  readonly
                  :rules="[$validationRules.required]"
                  :disabled="muokkaamassaSopimusta"
                  class="required"
                  v-on="on"
                />
              </template>
              <template #default>
                <v-date-picker
                  ref="alkamispaivaDatePicker"
                  v-model="sopimus.alkamispaiva"
                  locale="fi-FI"
                  first-day-of-week="1"
                  no-title
                  @change="alkamispaivaMenu = false"
                />
              </template>
            </v-menu>
            <TooltipInfo
              icon-class="align-self-start ml-2"
              icon-color="primary"
              :text="$t('common:infotekstit.sopimusInfo.startingDate')"
            />
            <span class="comboField__separator">&ndash;</span>
            <v-menu
              v-model="paattymispaivaMenu"
              :close-on-content-click="false"
              max-width="290"
            >
              <template #activator="{ on }">
                <v-text-field
                  label="Päättymispäivä"
                  :value="$dateFnsFormat(sopimus.loppumispaiva)"
                  readonly
                  :rules="[validoiPaattymispaiva]"
                  placeholder="Voimassa toistaiseksi"
                  persistent-placeholder
                  clearable
                  @click:clear="sopimus.loppumispaiva = null"
                  v-on="on"
                />
              </template>
              <template #default>
                <v-date-picker
                  ref="loppumispaivaDatePicker"
                  v-model="sopimus.loppumispaiva"
                  locale="fi-FI"
                  first-day-of-week="1"
                  no-title
                  @change="paattymispaivaMenu = false"
                />
              </template>
            </v-menu>
            <TooltipInfo
              icon-class="align-self-start ml-2"
              icon-color="primary"
              :text="$t('common:infotekstit.sopimusInfo.endingDate')"
            />
          </div>
          <v-checkbox
            v-model="sopimus.kuukautta_aikaisemmin"
            :disabled="muokkaamassaSopimusta"
            label="Aloita laskutus kuukautta aiemmin"
            class="mt-1 pt-1"
          />
          <div class="comboField">
            <v-select
              v-model="sopimus.laskutusjakso"
              label="Laskutusjakso"
              type="text"
              :items="laskutusjaksoItems"
              item-value="value"
              item-text="selite"
              class="mt-1 pt-1"
            />
            <TooltipInfo
              icon-class="align-self-start ml-2"
              icon-color="primary"
              :text="$t('common:infotekstit.sopimusInfo.invoicePeriod')"
            />
          </div>
          <div class="comboField">
            <v-select
              v-model="sopimus.lahetyspaivanPeruste"
              label="Lähetyspäivän peruste"
              type="text"
              :items="lahetyspaivanPerusteItems"
              item-value="value"
              item-text="selite"
              class="mt-1 pt-1"
            />
            <TooltipInfo
              icon-class="align-self-start ml-2"
              icon-color="primary"
              :text="$t('common:infotekstit.sopimusInfo.sendDateBase')"
            />
          </div>
          <div class="comboField">
            <v-menu
              v-model="paivanumeroMenu"
              :close-on-content-click="false"
              :disabled="laskutusjaksoOnKuukaudenVP"
              max-width="290"
            >
              <template #activator="{ on }">
                <v-text-field
                  :label="
                    sopimus.lahetyspaivanPeruste === 'laskutuspaiva'
                      ? 'Laskutuspäivä'
                      : 'Eräpäivä'
                  "
                  :value="
                    laskutusjaksoOnKuukaudenVP ? ' ' : sopimus.paivanumero
                  "
                  :disabled="laskutusjaksoOnKuukaudenVP"
                  readonly
                  :rules="[$validationRules.required]"
                  :class="laskutuspaivaClassArr"
                  class="mt-1 pt-1"
                  :prefix="paivanValintaPrefix"
                  placeholder="X"
                  persistent-placeholder
                  :suffix="laskutusjaksoOnKuukaudenVP ? '' : '. päivä'"
                  v-on="on"
                />
              </template>
              <template #default>
                <v-date-picker
                  ref="paivanValintaPaivamaaraDatePicker"
                  v-model="paivanValintaPaivamaara"
                  locale="fi-FI"
                  first-day-of-week="1"
                  no-title
                  class="mt-1 pt-1 paivan-valitsin"
                  :allowed-dates="paivanValintaItems"
                  :show-current="false"
                  @change="paivanumeroMenu = false"
                />
              </template>
            </v-menu>
            <TooltipInfo
              icon-class="align-self-start ml-2"
              icon-color="primary"
              :text="
                sopimus.lahetyspaivanPeruste === 'laskutuspaiva'
                  ? $t('common:infotekstit.sopimusInfo.invoiceDate')
                  : $t('common:infotekstit.sopimusInfo.dueDate')
              "
            />
          </div>
          <div class="comboField">
            <v-text-field
              v-model="sopimus.maksuehto"
              type="number"
              label="Maksuehto"
              :rules="[$validationRules.biggerThanZero]"
              :class="maksuehtoClassArr"
              suffix="pv netto"
              :full-width="false"
              class="mt-1 pt-1"
            />
            <TooltipInfo
              icon-class="align-self-start ml-2"
              icon-color="primary"
              :text="$t('common:infotekstit.sopimusInfo.paymentTerm')"
            />
          </div>
        </v-col>
        <v-col class="pa-6 laskujen-lahetys__oikea-sarake" md="6">
          <v-card
            v-if="!sopimus.laskutettu"
            class="mt-4 elevation-2 pa-4 bg-color-secondary-lighten2"
            :class="[tehdyillaValinnoillaClassArr]"
          >
            <p class="font-weight-bold text-subtitle-1 mb-0">
              <v-icon class="mr-2" color="white">info</v-icon>
              <span v-if="tehdyillaValinnoillaInfoteksti">
                {{ tehdyillaValinnoillaInfoteksti }}
              </span>
              <span v-else>
                Aseta
                {{
                  sopimus.lahetyspaivanPeruste === "laskutuspaiva"
                    ? "laskutuspäivä"
                    : "eräpäivä"
                }}
                laskeaksesi ensimmäisen laskun lähetyspäivä
              </span>
            </p>
          </v-card>
          <v-card
            v-if="naytaLaskutustietojaMuokattuTeksti"
            class="mt-4 elevation-2 pa-4 bg-color-secondary-lighten2"
          >
            <p class="font-weight-bold text-subtitle-1 mb-0">
              <v-icon class="mr-2" color="white">info</v-icon>
              <span>
                Huom.! Tarkista ja tarvittaessa päivitä määrittelytietojen
                muutosten tallennuksen jälkeen laskutusjaksot ja -aiheet.
              </span>
            </p>
          </v-card>
          <div
            v-if="muokkaamassaSopimusta"
            class="comboField laskujen-lahetys__oikea-sarake__seuraava-erapaiva"
          >
            <v-menu
              v-model="seuraavaLaskutuspaivaMenu"
              :close-on-content-click="false"
              max-width="290"
            >
              <template #activator="{ on }">
                <v-text-field
                  :value="$dateFnsFormat(sopimus.seuraava_laskutuspaiva)"
                  :label="
                    'Seuraava ' +
                    (sopimus.lahetyspaivanPeruste === 'laskutuspaiva'
                      ? 'laskutuspäivä'
                      : 'eräpäivä')
                  "
                  readonly
                  :rules="[$validationRules.required]"
                  :class="laskutuspaivaClassArr"
                  class="mt-1 pt-1"
                  v-on="on"
                />
              </template>
              <template #default>
                <v-date-picker
                  ref="seuraavaLaskutuspaivaDatePicker"
                  v-model="sopimus.seuraava_laskutuspaiva"
                  locale="fi-FI"
                  first-day-of-week="1"
                  no-title
                  class="mt-1 pt-1"
                  @change="seuraavaLaskutuspaivaMenu = false"
                />
              </template>
            </v-menu>
            <TooltipInfo
              icon-class="align-self-start ml-2"
              icon-color="primary"
              :text="$t('common:infotekstit.sopimusInfo.nextInvoiceDate')"
            />
          </div>
        </v-col>
      </v-row>
    </div>

    <div class="paper px-6 py-6 mb-12">
      <v-row>
        <v-col class="pa-6" md="6">
          <h2 class="text-h5 mb-8">Sopimuksen lisätiedot</h2>
          <div class="comboField">
            <v-select
              v-model="sopimus.laskunlahetys"
              label="Laskun lähetys"
              type="text"
              :items="laskunLahetysItems"
              item-value="value"
              item-text="selite"
              class="mt-1 pt-1"
            />
            <TooltipInfo
              icon-class="align-self-start ml-2"
              icon-color="primary"
              :text="$t('common:infotekstit.sopimusInfo.invoiceSending')"
            />
          </div>
          <div class="comboField">
            <v-text-field
              v-if="laskutusasiakas.asiakas.tyyppi === 'Y'"
              v-model="sopimus.viivastyskorko"
              type="text"
              label="Viivästyskorko"
              suffix="%"
              :rules="[
                $validationRules.maxDecimalPlacesOrEmpty(
                  sopimus.viivastyskorko,
                  2
                ),
                $validationRules.numberOrEmptyWithDecimal,
                $validationRules.zeroOrBiggerWithDecimal,
                $validationRules.smallerThanOrEqualToWithDecimal(
                  sopimus.viivastyskorko,
                  100
                ),
              ]"
              class="mt-1 pt-1"
              placeholder="Korkolain mukainen"
              persistent-placeholder
              :validate-on-blur="true"
            />
            <v-select
              v-else
              v-model="sopimus.viivastyskorko"
              label="Viivästyskorko"
              :items="viivastyskorkoItems"
              class="mt-1 pt-1"
            />
            <TooltipInfo
              icon-class="align-self-start ml-2"
              icon-color="primary"
              :text="$t('common:infotekstit.sopimusInfo.latePaymentInterest')"
            />
          </div>
          <div class="comboField">
            <v-text-field
              v-model="sopimus.laji"
              label="Sopimuslaji"
              type="text"
              class="mt-1 pt-1"
            />
            <TooltipInfo
              icon-class="align-self-start ml-2"
              icon-color="primary"
              :text="$t('common:infotekstit.sopimusInfo.genre')"
            />
          </div>
        </v-col>
        <v-col class="pa-6" md="6">
          <h2 class="text-h5 mb-8 visibility-hidden">Sopimuksen lisätiedot</h2>
          <div class="comboField">
            <v-text-field
              v-model="sopimus.kassaalennus_prosentti"
              label="Kassa-alennusprosentti"
              suffix="%"
              type="number"
              class="mt-1 pt-1"
              :rules="[
                $validationRules.zeroOrBigger,
                $validationRules.smallerThanOrEqualTo(
                  sopimus.kassaalennus_prosentti,
                  100
                ),
                $validationRules.maxDecimalPlaces(
                  sopimus.kassaalennus_prosentti,
                  2
                ),
              ]"
            />
            <TooltipInfo
              icon-class="align-self-start ml-2"
              icon-color="primary"
              :text="$t('common:infotekstit.sopimusInfo.cashDiscountPercent')"
            />
          </div>
          <div class="comboField">
            <v-text-field
              v-model="sopimus.kassaalennus_maksuaika"
              label="Kassa-alennuksen maksuaika"
              type="number"
              class="mt-1 pt-1"
              :rules="[$validationRules.zeroOrBigger]"
            />
            <TooltipInfo
              icon-class="align-self-start ml-2"
              icon-color="primary"
              :text="
                $t('common:infotekstit.sopimusInfo.cashDiscountPaymentPeriod')
              "
            />
          </div>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>

<script>
import _ from "lodash";
import OsoiteKappale from "@/components/OsoiteKappale";
import AutocompleteLaskurivi from "@/components/AutocompleteLaskurivi";
import AutocompletePaamies from "@/components/AutocompletePaamies";
import LomakenappiIkonilla from "@/components/LomakenappiIkonilla";
import LaskuriviTable from "@/components/LaskuriviTable";
import SopimusAsiakasKappale from "@/components/SopimusAsiakasKappale.vue";
import Sopimus from "@/class/Sopimus";
import { SopimusAsiakas, ROOLI_LASKUTUS } from "@/class/Sopimus";

export default {
  name: "FormSopimus",
  components: {
    OsoiteKappale,
    AutocompleteLaskurivi,
    AutocompletePaamies,
    LaskuriviTable,
    LomakenappiIkonilla,
    SopimusAsiakasKappale,
  },
  model: {
    prop: "sopimus",
  },
  props: {
    sopimus: {
      type: Object,
      required: true,
    },
    muokkaamassaSopimusta: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      alkamispaivaMenu: false,
      paattymispaivaMenu: false,
      asiakasItems: [],
      asiakasLoading: false,
      asiakasSearchQuery: "",
      debounceDelay: 300,
      debounceTimeout: "",
      tehdyillaValinnoillaClassArr: [],
      tehdyillaValinnoillaInfoteksti: "",
      erapaivaClassArr: ["required", "mt-1", "pt-1"],
      erapaivaMenu: false,
      lahetyspaivanPerusteItems: Sopimus.LAHETYSPAIVAN_PERUSTEET,
      laskunLahetysItems: Sopimus.LASKUN_LAHETYS,
      laskutuspaivaClassArr: ["required", "mt-1", "pt-1"],
      naytaLaskutustietojaMuokattuTeksti: false,
      odotusaikaYlitetty: false,
      paivanumeroMenu: false,
      laskupohjatItems: [],
      maksuehtoClassArr: ["required", "mt-1", "pt-1"],
      paivanValintaPaivamaara: null,
      sopimusjaksoItems: Sopimus.SOPIMUSJAKSOT,
      seuraavaLaskutuspaivaMenu: false,
      seuraavaLaskutuspaivaVaroitus: "",
      rooliLaskutusasiakasId: ROOLI_LASKUTUS,
      viivastyskorkoItems: [
        {
          value: null,
          text: this.$t("common:accordingToKorkolaki"),
        },
        {
          value: "0.00",
          text: "0 %",
        },
      ],
    };
  },
  computed: {
    ensimmaisenLaskutuspaivanTarkistuksenMuuttujat() {
      return `${this.sopimus.alkamispaiva}|${this.sopimus.kuukautta_aikaisemmin}|${this.sopimus.laskutusjakso}|${this.sopimus.maksuehto}|${this.sopimus.lahetyspaivanPeruste}|${this.sopimus.paivanumero}`;
    },
    laskutusjaksoOnKuukaudenVP() {
      return this.sopimus.laskutusjakso === "VP";
    },
    paivanValintaPrefix() {
      return _.find(Sopimus.LASKUTUSJAKSOT, [
        "value",
        this.sopimus.laskutusjakso,
      ]).paivanValintaSelite;
    },
    piilotaLaskuriviTable() {
      return !this.sopimus.rivit || !this.sopimus.rivit.length;
    },
    laskutusjaksoItems() {
      return Sopimus.LASKUTUSJAKSOT;
    },
    asiakasIds() {
      return this.sopimus.sopimusasiakas_set
        .filter((sa) => sa.asiakas.id)
        .map((sa) => sa.asiakas.id);
    },
    laskutusasiakas() {
      return this.sopimus.sopimusasiakas_set.find(
        (sa) => sa.rooli_id === ROOLI_LASKUTUS
      );
    },
  },
  watch: {
    sopimus: {
      deep: true,
      handler() {
        if (this.odotusaikaYlitetty) this.$emit("muokkaus");
      },
    },
    "sopimus.paamies": {
      deep: true,
      handler(paamies) {
        // Ei haluta tyhjentää kirjepohjaa kun ollaan navigoitu sopimuksen muokkaukseen.
        if (!this.muokkaamassaSopimusta || this.odotusaikaYlitetty)
          this.sopimus.kirjepohja_id = null;
        this.odotusaikaYlitetty = false;
        this.laskupohjatItems = [];

        if (paamies.id) {
          this.haeSopimuksenEsitiedot();
        }

        setTimeout(() => {
          this.odotusaikaYlitetty = true;
        }, 2000);
      },
    },
    "sopimus.rivit": {
      deep: true,
      handler(rivit) {
        if (rivit && rivit.length) this.sopimus.laskeYhteissummat();
      },
    },
    paivanValintaPaivamaara(arvo) {
      const paiva = parseInt(arvo.split("-")[2], 10);
      this.sopimus.paivanumero = paiva;
    },
    async ensimmaisenLaskutuspaivanTarkistuksenMuuttujat(arvo) {
      const [
        alkamispaiva,
        kuukautta_aikaisemmin,
        laskutusjakso,
        maksuehto,
        lahetyspaivanPeruste,
        paivanumero,
      ] = arvo.split("|");
      const paivavalinta = lahetyspaivanPeruste === "erapaiva" ? true : false;

      if (
        this.$validationRules.biggerThanZero(maksuehto) !== true ||
        paivanumero === "null"
      )
        return;

      try {
        if (!this.sopimus.laskutettu) {
          const query = new URLSearchParams();
          query.append("method", "laske_eka_laskupaiva");
          query.append("alkamispaiva", alkamispaiva);
          query.append("kuukautta_aikaisemmin", kuukautta_aikaisemmin);
          query.append("laskutusjakso", laskutusjakso);
          query.append("maksuehto", maksuehto);
          query.append("paivavalinta", paivavalinta);
          query.append("paivanumero", laskutusjakso === "VP" ? 1 : paivanumero);

          const request = await this.$doRequestWithTimeout(
            this.$api.Sopimukset,
            {
              method: "GET",
              url: "toiminnot/kysely/",
              query: query.toString(),
            },
            "pushRequest"
          );

          if (!request.success) throw new this.$HttpError(request);

          this.tehdyillaValinnoillaInfoteksti = request.result.body + ".";
        }

        if (this.muokkaamassaSopimusta && this.odotusaikaYlitetty)
          this.naytaLaskutustietojaMuokattuTeksti = true;
      } catch (e) {
        this.$sentryCaptureCustom(e);
      }
    },
    tehdyillaValinnoillaInfoteksti() {
      this.valayta(this.tehdyillaValinnoillaClassArr);
    },
    "sopimus.seuraava_laskutuspaiva": {
      handler() {
        if (this.muokkaamassaSopimusta && this.odotusaikaYlitetty)
          this.naytaLaskutustietojaMuokattuTeksti = true;
      },
    },
  },
  mounted() {
    if (this.$store.state.user.valittuPaamies.id) {
      this.sopimus.paamies = this.$store.state.user.valittuPaamies;
    }
    setTimeout(() => {
      // Oletetaan että dataan muutoksia tekevät pyynnöt on saatu ladattua ja tiedot päivitettyä
      // kahdessa sekunnissa
      this.odotusaikaYlitetty = true;
    }, 2000);
  },
  methods: {
    async haeSopimuksenEsitiedot() {
      const query = new URLSearchParams();
      if (this.sopimus.paamies.id)
        query.append("paamies_id", this.sopimus.paamies.id);

      const request = await this.$doRequestWithTimeout(
        this.$api.Sopimukset,
        {
          method: "GET",
          url: "uusi/esitiedot/",
          query: query.toString(),
        },
        "pushRequest"
      );

      const esitiedot = request.result.body;

      this.laskupohjatItems = esitiedot.laskupohjat;
      if (this.laskupohjatItems[0] && !this.muokkaamassaSopimusta) {
        this.sopimus.kirjepohja_id = this.laskupohjatItems[0].value;
      }
    },
    paivanValintaItems(arvo) {
      const paiva = parseInt(arvo.split("-")[2], 10);
      return 1 <= paiva && paiva <= 28;
    },
    kasitteleAutocompleteLaskuriviInput() {
      this.poistaTyhjaEnsimmainenRivi();
      this.$nextTick(() => {
        const lisatynRivinIndeksi = this.sopimus.rivit.length - 1;
        const lisattyRivi = this.sopimus.rivit[lisatynRivinIndeksi];
        if (lisattyRivi.alennusp === null || lisattyRivi.alennusp === undefined)
          lisattyRivi.alennusp = 0;
      });
      this.$emit("laskurivitableKayttajasyote");
    },
    poistaTyhjaEnsimmainenRivi() {
      if (this.sopimus.rivit.length > 0 && this.sopimus.rivit[0].isEmpty()) {
        this.sopimus.rivit.splice(0, 1);
      }
    },
    submit() {
      this.$emit("submit", this.sopimus);
    },
    tyhjennaPaamies() {
      this.sopimus.paamies = {};
    },
    valayta(classArr) {
      for (var i = 0; i < classArr.length; i++) {
        if (classArr[i] === "taustavalahdys") {
          classArr.splice(i, 1);
          i--;
        }
      }
      setTimeout(() => {
        classArr.push("taustavalahdys");
      }, 50);
    },
    validate() {
      return (
        this.$refs.formSopimus.validate() &&
        this.$refs.laskuriviTable.validate()
      );
    },
    validoiPaattymispaiva() {
      const alkamispaiva = this.sopimus.alkamispaiva
        ? new Date(this.sopimus.alkamispaiva)
        : null;
      const loppumispaiva = this.sopimus.loppumispaiva
        ? new Date(this.sopimus.loppumispaiva)
        : null;
      if (loppumispaiva && loppumispaiva < alkamispaiva) {
        return "Päättymispäivä ei voi olla ennen alkamispäivää";
      } else {
        return true;
      }
    },
    sopimusrooli2Selite(sopimusasiakas) {
      if (this.sopimus.sopimusasiakas_set.length > 1) {
        return SopimusAsiakas.SOPIMUSROOLI2SELITE[sopimusasiakas.rooli_id];
      }
      return "";
    },
    rooliInfo(sopimusasiakas) {
      if (
        this.sopimus.sopimusasiakas_set.length > 1 &&
        sopimusasiakas.rooli_id === ROOLI_LASKUTUS
      ) {
        return this.$t("common:infotekstit.sopimusInfo.recipientRoleInvoicing");
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
.osoiteKappale {
  font-size: 1.1em;
}

.hoverBackground {
  background-color: var(--v-secondary-lighten2);
}

.v-input {
  padding-top: 4px;
}

.col-icon {
  display: flex;
  justify-content: flex-end;
}

.laskujen-lahetys {
  &__oikea-sarake {
    margin-top: 60px;
    position: relative; // jotta absoluuttisesti positioitu div.seuraava-erapaiva näkyy oikein

    &__seuraava-erapaiva {
      position: absolute;
      bottom: 24px;
      width: 100%;
      padding-right: 48px;
    }
  }
}

.bg-color-secondary-lighten2 {
  background-color: var(--v-secondary-lighten2);
}

@keyframes valayta {
  0% {
    background-color: initial !important;
  }
  50% {
    background-color: var(--v-secondary-lighten1);
    border-radius: 5px !important;
  }
  100% {
    background-color: initial !important;
  }
}

.taustavalahdys {
  animation-name: valayta;
  animation-duration: 0.3s;
}
</style>
