<template>
  <v-form ref="formOstolasku" @submit.prevent="submit">
    <!-- Top section -->
    <v-row>
      <!-- Left column -->
      <v-col class="pa-6" md="6">
        <span class="paper__title hidden-md-and-up">Ostolasku</span>

        <!-- Toimittaja -->
        <v-hover v-slot="{ hover }" open-delay="50">
          <v-card
            class="pa-2 show-hidden-child-on-hover"
            :class="hover ? 'elevation-2 hoverBackground' : 'elevation-0'"
          >
            <div>
              <template v-if="ostolasku.toimittaja.id">
                <v-row justify="space-between">
                  <v-col cols="6" class="py-0">
                    <h3 class="">Toimittaja</h3>
                  </v-col>
                  <v-col cols="3" class="col-icon py-0">
                    <LomakenappiIkonilla
                      ikoni="clear"
                      :infoteksti="$t('clear')"
                      @click="tyhjennaToimittaja"
                    />
                    <LomakenappiIkonilla
                      ikoni="edit"
                      :infoteksti="$t('editSupplier')"
                      @click="$emit('open-dialog-toimittaja')"
                    />
                  </v-col>
                </v-row>

                <OsoiteKappale
                  :nimi="ostolasku.toimittaja.nimi"
                  :renderoi-tyhja="true"
                  :nayta-nimi="true"
                  :saaja="
                    ostolasku.toimittaja.postiosoite.saaja
                      ? ostolasku.toimittaja.postiosoite.saaja
                      : null
                  "
                  :postiosoite="
                    ostolasku.toimittaja.postiosoite.osoite
                      ? ostolasku.toimittaja.postiosoite.osoite
                      : null
                  "
                  :postinumero="
                    ostolasku.toimittaja.postiosoite.numero
                      ? ostolasku.toimittaja.postiosoite.numero
                      : null
                  "
                  :postitoimipaikka="
                    ostolasku.toimittaja.postiosoite.toimipaikka
                      ? ostolasku.toimittaja.postiosoite.toimipaikka
                      : null
                  "
                />
              </template>
              <template v-else>
                <v-autocomplete
                  ref="autocompleteToimittaja"
                  v-model="ostolasku.toimittaja"
                  :items="toimittajaItems"
                  :loading="toimittajaLoading"
                  :search-input.sync="toimittajaSearchQuery"
                  :hide-no-data="!toimittajaSearchQuery || toimittajaLoading"
                  item-text="nimi"
                  item-value="id"
                  label="Toimittaja"
                  :placeholder="
                    ostolasku.paamies.id
                      ? 'Hae toimittajan nimellä'
                      : 'Valitse ensin vastaanottaja'
                  "
                  persistent-placeholder
                  :disabled="!ostolasku.paamies.id"
                  return-object
                  class="required"
                  :rules="[validoiToimittaja]"
                  :validate-on-blur="true"
                >
                  <template #item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.nimi }}</v-list-item-title>
                      <v-list-item-subtitle v-if="item.postiosoite">
                        <template v-if="item.postiosoite.osoite">
                          {{ item.postiosoite.osoite }},
                        </template>
                        <template v-if="item.postiosoite.numero">
                          {{ item.postiosoite.numero }}
                        </template>
                        <template v-if="item.postiosoite.toimipaikka">
                          {{ item.postiosoite.toimipaikka }}
                        </template>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                  <template #no-data>
                    <p class="pa-4">Haulla ei löytynyt yhtäkään toimittajaa.</p>
                  </template>
                  <template v-if="!ostolasku.toimittaja.id" #append-outer>
                    <LomakenappiIkonilla
                      ikoni="person_add"
                      :infoteksti="$t('createSupplier')"
                      @click="$emit('open-dialog-toimittaja')"
                    />
                  </template>
                </v-autocomplete>
              </template>
            </div>
          </v-card>
        </v-hover>

        <!-- Päämies -->
        <div
          :class="{
            'py-3': $vuetify.breakpoint.smAndDown,
            'py-5': $vuetify.breakpoint.mdAndUp,
          }"
        ></div>
        <v-hover v-slot="{ hover }" open-delay="50">
          <v-card
            class="pa-2 show-hidden-child-on-hover"
            :class="
              hover && $store.getters['user/salliPaamiehenTyhjennys']
                ? 'elevation-2 hoverBackground'
                : 'elevation-0'
            "
          >
            <div>
              <template v-if="ostolasku.paamies.id">
                <v-row justify="space-between">
                  <v-col cols="6" class="py-0">
                    <h3>Vastaanottaja</h3>
                  </v-col>
                  <v-col
                    v-if="
                      !muokkaamassaLaskua &&
                      $store.getters['user/salliPaamiehenTyhjennys']
                    "
                    cols="3"
                    class="col-icon py-0"
                  >
                    <LomakenappiIkonilla
                      ikoni="clear"
                      :infoteksti="$t('clear')"
                      @click="tyhjennaPaamies"
                    />
                    <!-- <v-tooltip top> // Toteutetaan monipäämieshallinnan yhteydessä
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          class="ma-0 bg-ostolasku--primary"
                          @click="$emit('avaaDialogPaamies')"
                          v-on="on"
                        >
                          <v-icon color="white">edit</v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:default>
                        Muokkaa laskuttajaa
                      </template>
                    </v-tooltip> -->
                  </v-col>
                </v-row>
                <OsoiteKappale
                  :renderoi-tyhja="true"
                  :nayta-nimi="true"
                  :nimi="ostolasku.paamies.nimi"
                  :postiosoite="
                    ostolasku.paamies.postiosoite
                      ? ostolasku.paamies.postiosoite.postiosoite
                      : null
                  "
                  :postinumero="
                    ostolasku.paamies.postiosoite
                      ? ostolasku.paamies.postiosoite.postinumero
                      : null
                  "
                  :postitoimipaikka="
                    ostolasku.paamies.postiosoite
                      ? ostolasku.paamies.postiosoite.postitoimipaikka
                      : null
                  "
                />
              </template>
              <template v-else>
                <AutocompletePaamies
                  ref="autocompletePaamies"
                  v-model="ostolasku.paamies"
                  :label="$t('common:recipient')"
                  tarkistettava-oikeus="onlinerestapi.onlinerestapi_ostolasku"
                />
              </template>
            </div>
          </v-card>
        </v-hover>
      </v-col>

      <!-- Right Column -->
      <v-col class="pa-6" md="6">
        <v-col class="pa-2 pt-0">
          <span class="paper__title hidden-sm-and-down">Ostolasku</span>

          <v-text-field
            ref="laskunumero"
            v-model="ostolasku.laskunumero"
            :rules="[$validationRules.required]"
            label="Laskunumero"
            class="mt-1 pt-1 required"
          />

          <v-menu
            v-model="laskupaivaMenu"
            :close-on-content-click="false"
            max-width="290"
          >
            <template #activator="{ on }">
              <v-text-field
                label="Laskupäivä"
                :value="$dateFnsFormat(ostolasku.laskupaiva)"
                readonly
                :rules="[$validationRules.required]"
                :class="laskupaivaClassArr"
                class="mt-1 pt-1"
                v-on="on"
              />
            </template>
            <template #default>
              <v-date-picker
                v-model="ostolasku.laskupaiva"
                locale="fi-FI"
                first-day-of-week="1"
                no-title
                class="mt-1 pt-1"
                @change="laskupaivaMenu = false"
              />
            </template>
          </v-menu>

          <v-text-field
            v-model="ostolasku.maksuehto"
            type="number"
            label="Maksuehto"
            :rules="[$validationRules.zeroOrBigger]"
            :class="maksuehtoClassArr"
            suffix="pv netto"
            :full-width="false"
            class="mt-1 pt-1"
          />

          <v-menu
            v-model="erapaivaMenu"
            :close-on-content-click="false"
            max-width="290"
          >
            <template #activator="{ on }">
              <v-text-field
                label="Eräpäivä"
                :value="$dateFnsFormat(ostolasku.erapaiva)"
                readonly
                :rules="[$validationRules.required]"
                :class="erapaivaClassArr"
                class="mt-1 pt-1"
                v-on="on"
              />
            </template>
            <template #default>
              <v-date-picker
                v-model="ostolasku.erapaiva"
                locale="fi-FI"
                first-day-of-week="1"
                no-title
                @change="erapaivaMenu = false"
              />
            </template>
          </v-menu>

          <v-text-field
            v-model="ostolasku.viivastyskorko"
            type="text"
            label="Viivästyskorko"
            suffix="%"
            :rules="[
              $validationRules.maxDecimalPlacesOrEmpty(
                ostolasku.viivastyskorko,
                2
              ),
              $validationRules.zeroOrBiggerWithDecimal,
            ]"
            class="mt-1 pt-1"
            :validate-on-blur="true"
          />
          <v-text-field
            v-model="ostolasku.viite"
            label="Viite"
            type="text"
            class="mt-1 pt-1 required"
            :rules="[$validationRules.required]"
          />
          <v-text-field
            v-model="ostolasku.epitieto.iban"
            label="IBAN"
            type="text"
            class="mt-1 pt-1 required"
            :rules="[$validationRules.required, $validationRules.isValidIBAN]"
            :validate-on-blur="true"
          />
          <v-text-field
            v-model="ostolasku.epitieto.bic"
            label="BIC"
            type="text"
            class="mt-1 pt-1 required"
            :rules="[$validationRules.required, $validationRules.isValidBIC]"
            :validate-on-blur="true"
          />
          <v-text-field
            v-model="ostolasku.viitteemme"
            label="Viitteemme"
            type="text"
            class="mt-1 pt-1"
          />
          <v-text-field
            v-model="ostolasku.viitteenne"
            label="Viitteenne"
            type="text"
            class="mt-1 pt-1"
          />
        </v-col>
      </v-col>
    </v-row>

    <!-- Laskurivit -->
    <div class="pa-6">
      <h2 class="mb-4">Laskurivit</h2>
      <LaskuriviTable
        ref="laskuriviTable"
        v-model="ostolasku"
        cell-size="small"
        tyyppi="ostolasku"
        :tuoterekisteri="false"
      />
      <v-row class="mt-8">
        <v-col cols="8">
          <AutocompleteLaskurivi
            :lasku="ostolasku"
            tyyppi="ostolasku"
            @autocomplete-input="poistaTyhjaEnsimmainenRivi"
          />
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>

<script>
import OsoiteKappale from "@/components/OsoiteKappale";
import AutocompleteLaskurivi from "@/components/AutocompleteLaskurivi";
import AutocompletePaamies from "@/components/AutocompletePaamies";
import LaskuriviTable from "@/components/LaskuriviTable";
import LomakenappiIkonilla from "@/components/LomakenappiIkonilla";
import { AsiakasPostiosoite } from "@/class/Asiakas";
import { Toimittaja } from "@/class/Toimittaja";

export default {
  name: "FormOstolasku",
  components: {
    OsoiteKappale,
    AutocompleteLaskurivi,
    AutocompletePaamies,
    LaskuriviTable,
    LomakenappiIkonilla,
  },
  model: {
    prop: "ostolasku",
  },
  props: {
    ostolasku: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    muokkaamassaLaskua: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      toimittajaItems: [],
      toimittajaLoading: false,
      toimittajaSearchQuery: "",
      debounceDelay: 300,
      debounceTimeout: "",
      erapaivaClassArr: ["required", "mt-1", "pt-1"],
      erapaivaMenu: false,
      laskupaivaClassArr: ["required", "mt-1", "pt-1"],
      laskupaivaMenu: false,
      maksuehtoClassArr: ["required", "mt-1", "pt-1"],
      odotusaikaYlitetty: false,
    };
  },
  watch: {
    ostolasku: {
      deep: true,
      handler() {
        if (this.odotusaikaYlitetty) this.$emit("muokkaus");
      },
    },
    "ostolasku.paamies": {
      deep: true,
      handler(paamies) {
        this.ostolasku.toimittaja = new Toimittaja({
          paamies_id: this.ostolasku.paamies.id,
        });

        if (paamies.id) {
          this.$nextTick(() => {
            this.$refs.autocompleteToimittaja.resetValidation();
          });
        }
      },
    },
    "ostolasku.rivit": {
      deep: true,
      handler() {
        this.ostolasku.calculateTotals();
      },
    },
    "ostolasku.laskupaiva": {
      handler() {
        this.ostolasku.calculateErapaiva();
        this.valayta(this.laskupaivaClassArr);
        this.valayta(this.erapaivaClassArr);
      },
    },
    "ostolasku.maksuehto": {
      handler() {
        this.ostolasku.calculateErapaiva();
        if (this.ostolasku.maksuehto) {
          this.valayta(this.maksuehtoClassArr);
          this.valayta(this.erapaivaClassArr);
        }
      },
    },
    "ostolasku.erapaiva": {
      handler() {
        this.ostolasku.calculateMaksuehto();
      },
    },
    async toimittajaSearchQuery(val) {
      if (!this.ostolasku.paamies.id) return;

      this.toimittajaItems = [];

      if (!val) {
        this.ostolasku.toimittaja = new Toimittaja({
          paamies_id: this.ostolasku.paamies.id,
        });
        return;
      }

      this.toimittajaLoading = true;
      clearTimeout(this.debounceTimeout);

      this.debounceTimeout = setTimeout(async () => {
        try {
          const query = new URLSearchParams();
          query.append("paamies", this.ostolasku.paamies.id);
          query.append("nimi", val);
          query.append("order", "nimi");

          const request = await this.$doRequestWithTimeout(
            this.$api.Toimittajat,
            {
              method: "GET",
              url: "ostolaskulle",
              query: query.toString(),
            },
            "pushRequest"
          );

          if (!request.success) throw new this.$HttpError(request);

          const results = request.result.body;

          this.toimittajaItems = results.map((toimittaja) => {
            return new Toimittaja(toimittaja);
          });
        } catch (e) {
          this.$sentryCaptureCustom(e);
        } finally {
          this.toimittajaLoading = false;
        }
      }, this.debounceDelay);
    },
  },
  mounted() {
    setTimeout(() => {
      this.odotusaikaYlitetty = true;
    }, 2000);

    if (this.$store.state.user.valittuPaamies.id) {
      this.ostolasku.paamies = this.$store.state.user.valittuPaamies;
    }
  },
  methods: {
    poistaTyhjaEnsimmainenRivi() {
      if (
        this.ostolasku.rivit.length > 0 &&
        this.ostolasku.rivit[0].isEmpty()
      ) {
        this.ostolasku.rivit.splice(0, 1);
      }
    },
    submit() {
      this.$emit("submit", this.ostolasku);
    },
    tyhjennaPaamies() {
      this.ostolasku.paamies = {};
    },
    tyhjennaToimittaja() {
      this.ostolasku.toimittaja = new Toimittaja({
        paamies_id: this.ostolasku.paamies.id,
      });
    },
    tyhjennaToimitusosoite() {
      this.ostolasku.toimitusosoite = new AsiakasPostiosoite();
    },
    valayta(classArr) {
      for (var i = 0; i < classArr.length; i++) {
        if (classArr[i] === "taustavalahdys") {
          classArr.splice(i, 1);
          i--;
        }
      }
      setTimeout(() => {
        classArr.push("taustavalahdys");
      }, 100);
    },
    validate() {
      return (
        this.$refs.formOstolasku.validate() &&
        this.$refs.laskuriviTable.validate()
      );
    },
    validoiToimittaja() {
      if (!this.ostolasku.toimittaja.nimi) {
        return "Pakollinen tieto puuttuu";
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.osoiteKappale {
  font-size: 1.1em;
}

.hoverBackground {
  background-color: #eceff1;
}

.v-input {
  padding-top: 4px;
}

.col-icon {
  display: flex;
  justify-content: flex-end;
}

@keyframes valayta {
  0% {
    background-color: initial;
  }
  50% {
    background-color: #eceff1;
    border-radius: 5px;
  }
  // 50%  { background-color: var(--v-secondary-base); border-radius: 5px; }
  100% {
    background-color: initial;
  }
}

.taustavalahdys {
  animation-name: valayta;
  animation-duration: 0.4s;
}
</style>
