<template>
  <div :style="footerStyle" class="login__bottom px-6">
    <div class="login__bottomCol login__bottomCol--left pa-6">
      <p v-if="footerText">
        {{ footerText }}
      </p>
      <p>
        <a v-if="email" :href="'mailto:' + email">{{ email }}</a>
        <span v-if="email && tel">&ndash;</span>
        <span v-if="tel">{{ tel }}</span>
        <br />
        <!-- Versionumero näkyy vain teksti maalattuna -->
        <span id="versionumero">v{{ versio }}</span>
      </p>
    </div>
    <div class="login__bottomCol login__bottomCol--right pa-6">
      <p v-if="copyrightText">
        {{ copyrightText }}
      </p>
      <p v-if="address || postal || locality">
        <span v-if="address">{{ address }}</span>
        <span v-if="address && (postal || locality)">,</span>
        <span v-if="postal">{{ postal }}</span>
        <span v-if="locality">{{ locality }}</span>
        <template v-if="businessId">
          <br />
          Y-tunnus: {{ businessId }}
        </template>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      tel: "",
      copyrightText: "",
      address: "",
      postal: "",
      locality: "",
      businessId: "",
      footerText: "",
      footerTextColor: "#fff",
      footerBgColor: "rgba(0,0,0,.30)",
    };
  },
  computed: {
    footerStyle() {
      return {
        color: this.footerTextColor,
        backgroundColor: this.footerBgColor,
      };
    },
    versio() {
      return process.env.VUE_APP_PACKAGE_VERSION;
    },
  },
  created() {
    this.email = process.env.VUE_APP_EMAIL;
    this.tel = process.env.VUE_APP_TEL;
    this.copyrightText = process.env.VUE_APP_COPYRIGHT_TEXT;
    this.address = process.env.VUE_APP_ADDRESS;
    this.postal = process.env.VUE_APP_POSTAL;
    this.locality = process.env.VUE_APP_LOCALITY;
    this.businessId = process.env.VUE_APP_BUSINESS_ID;
    this.footerBgColor = process.env.VUE_APP_LOGIN_FOOTER_BACKGROUND_COLOR
      ? process.env.VUE_APP_LOGIN_FOOTER_BACKGROUND_COLOR
      : "rgba(0,0,0,.30)";
    this.footerText = process.env.VUE_APP_LOGIN_FOOTER_TEXT;
    this.footerTextColor = process.env.VUE_APP_LOGIN_FOOTER_TEXT_COLOR
      ? process.env.VUE_APP_LOGIN_FOOTER_TEXT_COLOR
      : "#fff";
  },
};
</script>

<style scoped lang="scss">
#versionumero {
  color: rgba(0, 0, 0, 0);
}
a:link,
a:visited {
  text-decoration: none;
  color: var(--footerTextColor);
}
</style>
