<template>
  <div class="ilmoitussisalto-raportti-valmis">
    <div role="alert" class="Vue-Toastification__toast-body">
      Raportti valmis: {{ nimi }} (id: {{ raporttiId }})
    </div>
    <v-btn
      class="ilmoitussisalto-raportti-valmis__btn"
      color="#26c6da"
      dark
      @click="lataaRaportti"
    >
      Lataa
    </v-btn>
  </div>
</template>

<script>
import { haeTiedostoFetchilla } from "@/utils/misc";

export default {
  props: {
    raporttiId: {
      type: [Number, String],
      required: true,
    },
    nimi: {
      type: String,
      required: true,
    },
    luotu: {
      type: String,
      required: true,
    },
  },
  methods: {
    async lataaRaportti() {
      try {
        const url =
          process.env.VUE_APP_API_ROOT + `/raportti/${this.raporttiId}/`;
        // Apicasen kautta ei voi käsitellä tiedostoja oikeaoppisesti, joten tehdään kysely fetchilla
        const result = await haeTiedostoFetchilla(url);

        this.$naytaTiedostonlataamisdialog({
          blob: await result.blob(),
          headers: {
            "content-disposition": result.headers.get("content-disposition"),
            "content-type": result.headers.get("content-type"),
          },
        });
      } catch (e) {
        this.$oletusVirheenkasittely(e);
      }
    },
  },
};
</script>

<style lang="scss">
.ilmoitussisalto-raportti-valmis {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__btn {
    margin-top: 8px;
    color: white;
  }
}
</style>
