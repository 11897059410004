var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.vastapuoli
    ? _c(
        "div",
        [
          _vm._t("vastapuolityyppi", function () {
            return [
              _vm.vastapuolityyppi
                ? _c(
                    "p",
                    {
                      staticClass: "mb-0 font-weight-bold grey--text darken-2",
                    },
                    [_vm._v(" " + _vm._s(_vm.vastapuolityyppi) + " ")]
                  )
                : _vm._e(),
            ]
          }),
          _c(
            "h3",
            [
              _vm.eiVastapuoliLinkkia
                ? [
                    _vm._v(" " + _vm._s(_vm.vastapuoli.nimi) + " "),
                    _vm.vastapuoli.nro
                      ? _c("span", [
                          _vm._v("(" + _vm._s(_vm.vastapuoli.nro) + ")"),
                        ])
                      : _vm._e(),
                  ]
                : _c(
                    "router-link",
                    {
                      staticClass: "inlineList__link",
                      attrs: {
                        to: {
                          name: _vm.vastapuoliRoute,
                          params: { id: _vm.vastapuoli.id },
                        },
                      },
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.vastapuoli.nimi) + " "),
                      _vm.vastapuoli.nro
                        ? _c("span", [
                            _vm._v("(" + _vm._s(_vm.vastapuoli.nro) + ")"),
                          ])
                        : _vm._e(),
                    ]
                  ),
            ],
            2
          ),
          _vm.vastapuoli.postiosoite
            ? _c("OsoiteKappale", {
                class: _vm.tiivisNakyma ? "mb-0" : "mb-2",
                attrs: {
                  saaja: _vm.vastapuoli.postiosoite.saaja
                    ? _vm.vastapuoli.postiosoite.saaja
                    : "",
                  postiosoite: _vm.vastapuoli.postiosoite.osoite,
                  postinumero: _vm.vastapuoli.postiosoite.numero,
                  postitoimipaikka: _vm.vastapuoli.postiosoite.toimipaikka,
                },
              })
            : _vm._e(),
          _c("div", [
            _vm.vastapuoli.tunnus
              ? _c("p", { staticClass: "mb-0" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.tunnustyyppi) +
                      ": " +
                      _vm._s(_vm.vastapuoli.tunnus) +
                      " "
                  ),
                ])
              : _vm._e(),
            _vm.vastapuoli.emailosoite && _vm.vastapuoli.emailosoite.email
              ? _c("p", { staticClass: "mb-0" }, [
                  _vm._v(" " + _vm._s(_vm.vastapuoli.emailosoite.email) + " "),
                ])
              : _vm._e(),
            _vm.vastapuoli.verkkolaskuosoite &&
            _vm.vastapuoli.verkkolaskuosoite.verkkolaskuosoite
              ? _c("p", { staticClass: "mb-0" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("common:eInvoiceAddress")) +
                      " " +
                      _vm._s(
                        _vm.vastapuoli.verkkolaskuosoite.verkkolaskuosoite
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
          ]),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }