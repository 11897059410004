// import { doRequestWithTimeout } from "@/utils/misc";
// import api from "@/plugins/api";

import {
  AsiakasEmail,
  AsiakasPostiosoite,
  AsiakasPuhelin,
  AsiakasVerkkolaskuosoite,
} from "./Asiakas";

export const Toimittaja = class {
  constructor(preset = {}) {
    this.emailosoite = new AsiakasEmail();
    this.etunimi = "";
    this.id = null;
    this.kieli = "fi-fi";
    this.muistiinpano_set = []; // Read-only
    this.nimi = "";
    this.nro = "";
    this.paamies_id = null;
    this.postiosoite = new AsiakasPostiosoite();
    this.puhelin = new AsiakasPuhelin();
    this.postiosoite_set = []; // Read-only
    this.sukunimi = "";
    this.tunnus = "";
    this.tyyppi = "";
    this.verkkolaskuosoite = new AsiakasVerkkolaskuosoite();

    Object.assign(this, preset);
    if (this.tyyppi === "H") {
      const nimet = this.nimi.split(" ");
      this.sukunimi = nimet.shift();
      this.etunimi = nimet.join(" ");
    }

    this.emailosoite = new AsiakasEmail(preset.emailosoite);
    this.postiosoite = new AsiakasPostiosoite(preset.postiosoite);
    this.puhelin = new AsiakasPuhelin(preset.puhelin);
    this.verkkolaskuosoite = new AsiakasVerkkolaskuosoite(
      preset.verkkolaskuosoite
    );
  }

  getPostData() {
    const postData = JSON.parse(JSON.stringify(this));

    // Read-only-muuttujat
    delete postData.muistiinpano_set;
    delete postData.postiosoite_set;
    delete postData.id;
    delete postData.etunimi;
    delete postData.sukunimi;

    if (this.tyyppi === "H") {
      postData.nimi = this.sukunimi.trim() + " " + this.etunimi.trim();
    }

    postData.postiosoite = this.postiosoite.getPostData();

    if (!this.emailosoite.email) {
      delete postData.emailosoite;
    } else {
      postData.emailosoite = this.emailosoite.getPostData();
    }

    if (!this.puhelin.numero) {
      delete postData.puhelin;
    } else {
      postData.puhelin = this.puhelin.getPostData();
    }

    if (!this.verkkolaskuosoite.verkkolaskuosoite) {
      delete postData.verkkolaskuosoite;
    } else {
      postData.verkkolaskuosoite = this.verkkolaskuosoite.getPostData();
    }

    return postData;
  }
};
