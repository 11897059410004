<template>
  <v-form ref="formTuoteCSV" v-model="valid" @submit.prevent="submit">
    <v-row>
      <v-col class="px-4">
        <AutocompletePaamies
          v-if="!$store.state.user.vainYksiPaamies"
          ref="autocompletePaamies"
          v-model="csvLomake.paamies"
          :validointi-saannot="[$validationRules.requiredObject]"
          tarkistettava-oikeus="onlinerestapi.onlinerestapi_tuoterekisteri"
          @autocomplete-input="paivitaTuoteryhmat"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="px-4">
        <v-file-input
          v-model="csvLomake.csv"
          accept=".csv"
          :label="$t('common:fileHandling.csvFile')"
          :rules="[$validationRules.required]"
          class="required"
        ></v-file-input>
      </v-col>
    </v-row>
    <v-row class="mt-4 py-3 px-4" justify="end">
      <v-btn large class="mr-4" @click="$emit('close')">
        {{ $t("common:cancel") }}
      </v-btn>
      <v-btn
        large
        color="action"
        :disabled="!valid || loading"
        @click="luoTuotteetTiedostosta"
      >
        {{ $t("common:fileHandling.bringFromFile") }}
      </v-btn>
    </v-row>
    <v-divider class="my-8 mx-1" />
    <v-row class="mt-4 py-3 px-4" justify="end">
      <v-btn
        small
        class="mr-4"
        color="primary"
        @click="naytaVaihtoehdot = !naytaVaihtoehdot"
      >
        {{ vaihtoehdotTeksti }}
        <v-icon right small>
          {{ naytaVaihtoehdot ? "keyboard_arrow_up" : "keyboard_arrow_down" }}
        </v-icon>
      </v-btn>
      <v-btn href="tuote-esimerkki.csv" download small color="primary">
        {{ $t("common:fileHandling.loadSampleCsv") }}
        <v-icon right small>download</v-icon>
      </v-btn>
    </v-row>
    <div v-if="naytaVaihtoehdot">
      <v-row>
        <v-col class="px-4">
          <v-card class="mb-4 pa-4" color="info">
            <v-icon dark class="mr-2">info</v-icon>
            <span class="text-subtitle-2 white--text">
              {{ $t("common:fileHandling.browseTipOfLoadingData") }}
            </span>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="px-4">
          <v-autocomplete
            label="tuoteryhma"
            :items="tuoteryhmat"
            item-text="nimi"
            dense
            :error-messages="
              paamiehellaEiTuoteryhmia
                ? $t('common:fileHandling.creditorHasNoProductGroups')
                : ''
            "
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="px-4">
          <v-autocomplete
            label="alvtunnus"
            :items="ALVTunnukset"
            item-text="tunnus"
            dense
          ></v-autocomplete>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";
import { AutocompletePaamies } from "@/components";
import { mapState } from "vuex";
import Papa from "papaparse";
import { tuoteKentat } from "@/utils/constants";
import { Tuote } from "@/class/Tuoterekisteri";
import { Tuoteryhma } from "@/class/Tuoterekisteri";

export default {
  name: "FormTuoteCSV",
  components: {
    AutocompletePaamies,
  },
  mixins: [FormMixin],
  data() {
    return {
      csvLomake: { paamies: this.$store.state.user.valittuPaamies, csv: null },
      lataaTiedostoa: false,
      tuoteryhmat: [],
      paamiehellaEiTuoteryhmia: false,
      naytaVaihtoehdot: false,
      koodiErrors: [],
    };
  },
  computed: {
    ...mapState({
      ALVTunnukset: (state) => state.yleinen.ALVTunnusItems,
    }),

    vaihtoehdotTeksti() {
      if (this.naytaVaihtoehdot)
        return this.$t("common:general.hideAlternatives");

      return this.$t("common:general.showAlternatives");
    },
  },
  watch: {
    onAuki: {
      immediate: true,
      handler(arvo) {
        if (arvo) {
          this.$nextTick().then(() => {
            this.$refs.formTuoteCSV.resetValidation();
          });
          this.paivitaTuoteryhmat(this.csvLomake.paamies);
        }
      },
    },
  },
  methods: {
    async luoTuotteetTiedostosta() {
      let koodiTaulukko = [],
        paivitetaanVanhat = true,
        keskeytetaan = false;
      let paivitettyTuotteita = 0;
      let onnistumisilmoitus = "",
        vali = " ",
        virheIlmoitus = "";
      this.lataaTiedostoa = true;
      if (!this.csvLomake.paamies && this.$store.state.user.valittuPaamies) {
        this.csvLomake.paamies = this.$store.state.user.valittuPaamies;
      }
      if (!this.csvLomake.paamies) {
        this.$naytaVirheilmoitus(
          "Päämiestä ei ole valittuna. Valitse ensin päämies."
        );
        return;
      }

      let tuotteet = Papa.parse(await this.csvLomake.csv.text(), {
        header: true,
        transformHeader: (h) => {
          // poistetaan pakollisten kenttien *
          return h.replace(/\*/g, "");
        },
        skipEmptyLines: "greedy", // skippaa tyhjät rivit
        transform: (arvo) => {
          return arvo.trim();
        },
      }).data;

      let kentat;
      try {
        kentat = Object.keys(tuotteet[0]).filter(
          (kentta) => !tuoteKentat.includes(kentta)
        );
      } catch (e) {
        this.$naytaVirheilmoitus(
          this.$t("common:fileHandling.csvFileIsFaulty")
        );
        this.lataaTiedostoa = false;
        return;
      }

      try {
        // tarkistetaan että on vain oikeita kenttiä
        if (kentat.length > 0) {
          throw new Error(
            this.$t("common:fileHandling.csvFileHasExtraFields") +
              `: ${kentat.join(", ")}`
          );
        }

        // vaihdetaan tuoteryhma, alvtunnus ja paamies id-muotoon
        let virheet = [];
        let tuoteryhmatPostData = [];
        tuotteet.forEach((t) => {
          const tuoteryhma = this.tuoteryhmat.filter(
            (tr) => tr.nimi === t.tuoteryhma
          )[0];
          const alvtunnus = this.ALVTunnukset.filter(
            (at) => at.tunnus == t.alvtunnus
          )[0];
          if (!alvtunnus) {
            virheet.push(
              this.$t("common:general.vatWithNameNotFound", {
                alvtunnus: t.alvtunnus,
              })
            );
            this.$t("common:general.vatWithNameNotFound", {
              alvtunnus: t.alvtunnus,
            });
          }
          if (!tuoteryhma) {
            let luotavaTuoteryhma = new Tuoteryhma();
            luotavaTuoteryhma.nimi = t.tuoteryhma;
            luotavaTuoteryhma.paamies = this.csvLomake.paamies;
            const validateVirheet = luotavaTuoteryhma.validate();
            if (validateVirheet && validateVirheet.length > 0) {
              virheet.push(validateVirheet);
            }
            tuoteryhmatPostData.push(luotavaTuoteryhma.getPostData());
          }
          koodiTaulukko.push({ koodi: t.koodi });
        });
        if (virheet.length)
          throw new Error(
            this.$t("common:general.followingFieldsHadError", {
              virheet: virheet.join(", "),
            })
          );

        let tallennetutTuotteet = await this.haeTuotteet(koodiTaulukko);
        if (tallennetutTuotteet && tallennetutTuotteet.length > 0) {
          const result = await this.$swal({
            text: "Tiedostossa on mukana tuotteita, jotka ovat jo järjestelmässä. Uudet tuotteet lisätään järjestelmään. Päivitetäänkö vanhat tuotteet tiedoston mukaisesti vai ohitetaanko jo järjestelmässä olevat vanhat tuotteet? Tämän dialogin oikean yläkulman X-kohdasta tiedoston latauksen käsittely keskeytetään.",
            showCloseButton: true,
            showCancelButton: true,
            showConfirmButton: true,
            focusCancel: true,
            confirmButtonText: "Lisätään uudet ja päivitetään vanhat",
            cancelButtonText: "Lisätään uudet ja ohitetaan olemassa olevat",
          });
          if (result.value) {
            paivitetaanVanhat = true;
          } else if (result.dismiss == "cancel") {
            paivitetaanVanhat = false;
          } else if (result.dismiss == "close") {
            keskeytetaan = true;
          } else {
            keskeytetaan = true;
          }
        }
        if (keskeytetaan) {
          this.$naytaOnnistumisilmoitus(
            this.$t("common:fileHandling.csvFileTerminated")
          );
          return;
        }

        if (tuoteryhmatPostData.length > 0) {
          const trResponse = await this.$doRequestWithTimeout(
            this.$api.Tuoteryhmat,
            {
              method: "POST",
              url: "",
              body: tuoteryhmatPostData,
            }
          );
          if (trResponse.success) {
            await this.paivitaTuoteryhmat(this.csvLomake.paamies);
          } else {
            throw new Error(
              this.$t("common:productGroup.productGroupNotFoundCreateError")
            );
          }
        }

        tuotteet.forEach(async (t) => {
          const tuoteryhma = this.tuoteryhmat.filter(
            (tr) => tr.nimi === t.tuoteryhma
          )[0];
          const alvtunnus = this.ALVTunnukset.filter(
            (at) => at.tunnus == t.alvtunnus
          )[0];
          if (!tuoteryhma)
            throw new Error(
              this.$t("common:productGroup.productGroupNotFoundCreateNew", {
                tuoteryhma: t.tuoteryhma,
              })
            );

          t.tuoteryhma = tuoteryhma;
          t.alvtunnus_id = alvtunnus.id;
          t.paamies = this.csvLomake.paamies;
          if (tallennetutTuotteet && tallennetutTuotteet.length > 0) {
            const tallennettuTuote = tallennetutTuotteet.find(
              (item) => item.koodi === t.koodi
            );
            if (tallennettuTuote && tallennettuTuote.id) {
              t.id = tallennettuTuote.id;
            }
          }

          return t;
        });

        let tuotteetPostData = [];
        let tuotteetPutData = [];
        for (const tuote of tuotteet) {
          const t = new Tuote(tuote);
          const virheet = t.validate();

          if (virheet.length)
            throw new Error(
              this.$t("common:general.followingFieldsHadError", {
                virheet: virheet.join(", "),
              })
            );
          if (tuote.id && paivitetaanVanhat) {
            t.id = tuote.id;
            tuotteetPutData.push(t.getPostData());
          } else if (!tuote.id) {
            tuotteetPostData.push(t.getPostData());
          }
        }

        let postResponse = {},
          putResponse = {};
        if (tuotteetPostData && tuotteetPostData.length > 0) {
          postResponse = await this.$doRequestWithTimeout(this.$api.Tuotteet, {
            method: "POST",
            url: "",
            body: tuotteetPostData,
          });
          if (postResponse.success) {
            onnistumisilmoitus = this.$t("common:general.createdNewProducts", {
              products: postResponse.result.body.length,
            });
          } else {
            virheIlmoitus =
              virheIlmoitus + vali + this.$palautaDjangonVirheet(postResponse);
          }
        }
        if (tuotteetPutData && tuotteetPutData.length > 0) {
          let url = "";
          for (const tuotePutData of tuotteetPutData) {
            url = `${tuotePutData.id}/`;
            putResponse = await this.$doRequestWithTimeout(
              this.$api.Tuotteet,
              {
                method: "PUT",
                url: url,
                body: tuotePutData,
              },
              "doSingleRequest"
            );
            if (putResponse.success) {
              paivitettyTuotteita++;
            } else {
              virheIlmoitus =
                virheIlmoitus + vali + this.$palautaDjangonVirheet(putResponse);
            }
          }
        }

        if (paivitettyTuotteita > 0) {
          onnistumisilmoitus =
            onnistumisilmoitus +
            vali +
            this.$t("common:general.updatedProducts", {
              products: paivitettyTuotteita,
            });
        }
        if (onnistumisilmoitus && onnistumisilmoitus.length > 0) {
          this.$naytaOnnistumisilmoitus(onnistumisilmoitus);
        }
        if (!virheIlmoitus || virheIlmoitus.length == 0) {
          if (
            (!tuotteetPostData || tuotteetPostData.length == 0) &&
            (!tuotteetPutData || tuotteetPutData.length == 0)
          ) {
            this.$naytaOnnistumisilmoitus(
              this.$t("common:general.noInsertedOrUpdatedProducts")
            );
          }
          this.csvLomake.csv = null;
          this.$emit("tuotteita-tallennettu");
          this.$emit("close");
        } else {
          this.$naytaVirheilmoitus(virheIlmoitus);
        }
      } catch (e) {
        this.$naytaVirheilmoitus(e.message);
      }

      this.lataaTiedostoa = false;
    },
    async haeTuotteet(koodiTaulukko) {
      if (
        !koodiTaulukko ||
        koodiTaulukko.length == 0 ||
        !this.csvLomake.paamies ||
        !this.csvLomake.paamies.id
      )
        return;

      try {
        const request = await this.$doRequestWithTimeout(
          this.$api.TuotteetHaku,
          {
            method: "POST",
            url: "",
            body: {
              koodit: koodiTaulukko,
              paamies_id: this.csvLomake.paamies.id,
            },
          }
        );

        if (!request.success) throw new this.$HttpError(request);

        return request.result.body;
      } catch (e) {
        this.$oletusVirheenkasittely(e);
      }
    },
    async paivitaTuoteryhmat(paamies) {
      if (!paamies || !paamies.id) {
        this.tuoteryhmat = [];
        this.paamiehellaEiTuoteryhmia = false;
        return;
      }

      try {
        const query = new URLSearchParams();
        query.append("paamies", this.csvLomake.paamies.id);
        query.append("psize", 100000000);

        const response = await this.$doRequestWithTimeout(
          this.$api.Tuoteryhmat,
          {
            method: "GET",
            query: query.toString(),
          }
        );

        if (!response.success) throw new this.$HttpError(response);

        this.tuoteryhmat = response.result.body.results;
      } catch (e) {
        this.$sentryCaptureCustom(e);
      }

      this.paamiehellaEiTuoteryhmia = !this.tuoteryhmat.length;
    },
  },
};
</script>

<style lang="scss" scoped></style>
