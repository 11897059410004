<template>
  <div :class="classObj">
    <v-card class="card__wrap" :to="to" @click="clickHandler">
      <header class="card__header pa-4">
        <h2 v-if="data.toimeksiantovelallinen_set.length" class="card__title">
          {{ data.toimeksiantovelallinen_set[0].velallinen.nimi }}
        </h2>

        <v-tooltip top>
          <template #activator="{ on }">
            <StatusIcon
              class="card__icon"
              size="large"
              :icon="data.ikoni_ja_vari.ikoni"
              :hover-icon="'open-in-new'"
              :event-listener="on"
              :bg-color="data.ikoni_ja_vari.vari"
              @click="
                $router.push({ name: 'perinta-nayta', params: { id: data.id } })
              "
            />
          </template>
          <template #default>
            <span>Avaa toimeksianto</span>
          </template>
        </v-tooltip>
      </header>
      <v-card-text class="card__content">
        <v-row class="card__details">
          <v-col cols="6">
            <span class="grey--text">Asianumero</span>
            <br />
            <span v-if="data.id">{{ data.id }}</span>
          </v-col>
          <template v-if="data.tila && data.vaihe">
            <v-col
              v-if="
                data.tila.value != 'XX' &&
                data.tila.value != 'PE' &&
                data.tila.value != 'MY'
              "
              cols="6"
              class="text-right"
            >
              <span class="grey--text">Vaihe</span>
              <br />
              <span v-if="data.vaihe">{{ data.vaihe.nimi }}</span>
            </v-col>
          </template>
        </v-row>
        <div class="my-2"></div>
        <v-row class="card__details">
          <v-col cols="8" class="">
            <span class="grey--text">Päämies</span>
            <br />
            <span v-if="data.paamies">{{ data.paamies.nimi }}</span>
          </v-col>
          <v-col cols="4" class=""></v-col>
          <v-col cols="12" class="text-right align-self-end">
            <span class="grey--text">Avoinna</span>
            <br />
            <span class="orange--text text-subtitle-1">
              {{ data.avoinna | formatSumToFixed2 }}
            </span>
            <br />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <template v-if="data.stacked">
      <v-card class="card__stackItem"></v-card>
      <v-card class="card__stackItem card__stackItem--second"></v-card>
    </template>
  </div>
</template>

<script>
export default {
  name: "CardToimeksianto",
  props: {
    data: {
      type: Object,
      required: true,
    },
    to: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    selectedItem: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  computed: {
    classObj() {
      return ["card", this.fade ? "card--fade" : null];
    },
    fade() {
      if (!this.selectedItem.id) return false;

      if (this.selectedItem.id != this.data.id) return true;

      return false;
    },
  },
  methods: {
    clickHandler() {
      this.$emit("click", this);
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  z-index: 1;
  position: relative;
  height: 100%;
  overflow: visible;
  box-shadow: none;

  &--fade {
    opacity: 0.25;
  }

  &__wrap {
    z-index: 10;
    height: 100%;
    display: grid;
    flex-direction: column;
    justify-content: stretch;
  }

  &__header {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &__title {
    flex: 1 1 auto;
    font-size: 1.3em;
    font-weight: normal;
    margin: 0;
    padding-right: 4.2em;
    line-height: 1.2;
  }

  &__content {
    padding-top: 0;
    display: flex;
    flex-direction: column;
  }

  &__icon {
    position: absolute;
    right: 1em;
    top: -1.8em;
  }

  &__stackItem {
    z-index: 2;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transform: translateY(4px) translateX(4px);

    &--second {
      z-index: 1;
      transform: translateY(8px) translateX(8px);
    }
  }
}
</style>
