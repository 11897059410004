var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "formKuukausiraportti",
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _vm.muokattavaKuukausiraporttiLataamassa
        ? _c("Spinner")
        : [
            !_vm.$store.state.user.vainYksiPaamies
              ? _c(
                  "v-row",
                  { attrs: { align: "center" } },
                  [
                    _c(
                      "v-col",
                      [
                        !_vm.kuukausiraportti.id
                          ? _c("AutocompletePaamies", {
                              ref: "autocompletePaamies",
                              attrs: {
                                "tarkistettava-oikeus":
                                  "onlinerestapi.onlinerestapi_raporttilahetys",
                              },
                              model: {
                                value: _vm.kuukausiraportti.paamies,
                                callback: function ($$v) {
                                  _vm.$set(_vm.kuukausiraportti, "paamies", $$v)
                                },
                                expression: "kuukausiraportti.paamies",
                              },
                            })
                          : _c("v-text-field", {
                              staticClass: "required",
                              attrs: {
                                label: _vm.$t("common:creditor"),
                                disabled: true,
                              },
                              model: {
                                value: _vm.kuukausiraportti.paamies.nimi,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.kuukausiraportti.paamies,
                                    "nimi",
                                    $$v
                                  )
                                },
                                expression: "kuukausiraportti.paamies.nimi",
                              },
                            }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  [
                    _c("h3", { staticClass: "mb-6" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("common:formKuukausiraportti.reportSettings")
                          ) +
                          " "
                      ),
                    ]),
                    _c("v-select", {
                      staticClass: "required mb-4",
                      attrs: {
                        label: _vm.$t("common:report"),
                        type: "text",
                        items: _vm.raporttiItems,
                        "item-value": "id",
                        "item-text": "nimi",
                        "return-object": "",
                        "menu-props": { zIndex: "999" },
                        rules: [_vm.$validationRules.required],
                        loading: _vm.esitiedotLataamassa,
                        "no-data-text": _vm.$t(
                          "common:formKuukausiraportti.chooseCreditorMsg"
                        ),
                      },
                      model: {
                        value: _vm.kuukausiraportti.raportti,
                        callback: function ($$v) {
                          _vm.$set(_vm.kuukausiraportti, "raportti", $$v)
                        },
                        expression: "kuukausiraportti.raportti",
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "comboField" },
                      [
                        _c("v-text-field", {
                          staticClass:
                            "comboField__field required v-input--text-right",
                          attrs: {
                            label: _vm.$t("common:reportDays"),
                            type: "number",
                            prefix: _vm.$t("common:prefixMonth"),
                            suffix: ".",
                            rules: [
                              _vm.$validationRules.required,
                              _vm.$validationRules.isInteger,
                              _vm.validoiPaiva,
                            ],
                            hint: !_vm.raportinPaivatKattaaKokoKuukauden
                              ? _vm.$t(
                                  "common:formKuukausiraportti.daysNotWholeMonthMsg"
                                )
                              : "",
                          },
                          model: {
                            value: _vm.kuukausiraportti.alkamispaiva,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.kuukausiraportti,
                                "alkamispaiva",
                                $$v
                              )
                            },
                            expression: "kuukausiraportti.alkamispaiva",
                          },
                        }),
                        _c("span", { staticClass: "comboField__separator" }, [
                          _vm._v("–"),
                        ]),
                        _c("v-text-field", {
                          staticClass:
                            "comboField__field required v-input--text-right",
                          attrs: {
                            type: "number",
                            suffix: _vm.$t("common:suffixDay"),
                            rules: [
                              _vm.$validationRules.required,
                              _vm.$validationRules.isInteger,
                              _vm.validoiPaiva,
                            ],
                            hint: !_vm.raportinPaivatKattaaKokoKuukauden
                              ? _vm.$t(
                                  "common:formKuukausiraportti.daysNotWholeMonthMsg"
                                )
                              : "",
                          },
                          model: {
                            value: _vm.kuukausiraportti.loppumispaiva,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.kuukausiraportti,
                                "loppumispaiva",
                                $$v
                              )
                            },
                            expression: "kuukausiraportti.loppumispaiva",
                          },
                        }),
                        _c("TooltipInfo", {
                          attrs: {
                            "icon-class": "align-self-start ml-2",
                            "icon-color": "primary",
                            text: _vm.$t(
                              "common:infotekstit.kuukausiraporttiInfo.startAndEndDate"
                            ),
                          },
                        }),
                      ],
                      1
                    ),
                    _c("h3", { staticClass: "mt-6 mb-4" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("common:formKuukausiraportti.createReport")
                          ) +
                          " "
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "comboField" },
                      [
                        _c("v-text-field", {
                          staticClass:
                            "comboField__field required v-input--text-right",
                          attrs: {
                            label: _vm.$t("common:day"),
                            type: "number",
                            placeholder: "1",
                            "persistent-placeholder": "",
                            prefix: _vm.$t("common:prefixMonth"),
                            suffix: _vm.$t("common:suffixDay"),
                            rules: [
                              _vm.$validationRules.required,
                              _vm.$validationRules.isInteger,
                              _vm.validoiPaiva,
                            ],
                          },
                          model: {
                            value: _vm.kuukausiraportti.ajopaiva,
                            callback: function ($$v) {
                              _vm.$set(_vm.kuukausiraportti, "ajopaiva", $$v)
                            },
                            expression: "kuukausiraportti.ajopaiva",
                          },
                        }),
                        _c("span", {
                          staticClass: "comboField__separator required",
                        }),
                        _c("v-text-field", {
                          staticClass: "comboField__field required",
                          attrs: {
                            label: _vm.$t("common:hour"),
                            type: "number",
                            prefix: _vm.$t("common:prefixTime"),
                            placeholder: "8",
                            "persistent-placeholder": "",
                            rules: [
                              _vm.$validationRules.required,
                              _vm.$validationRules.isInteger,
                              _vm.validoiAjotunti,
                            ],
                          },
                          model: {
                            value: _vm.kuukausiraportti.ajotunti,
                            callback: function ($$v) {
                              _vm.$set(_vm.kuukausiraportti, "ajotunti", $$v)
                            },
                            expression: "kuukausiraportti.ajotunti",
                          },
                        }),
                        _c("TooltipInfo", {
                          attrs: {
                            "icon-class": "align-self-start ml-2",
                            "icon-color": "primary",
                            text: _vm.$t(
                              "common:infotekstit.kuukausiraporttiInfo.runDayAndHour"
                            ),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "comboField" },
                      [
                        _c("v-checkbox", {
                          staticClass: "mt-1",
                          attrs: { label: _vm.$t("common:onlyWeekdays") },
                          model: {
                            value: _vm.kuukausiraportti.vain_arkena,
                            callback: function ($$v) {
                              _vm.$set(_vm.kuukausiraportti, "vain_arkena", $$v)
                            },
                            expression: "kuukausiraportti.vain_arkena",
                          },
                        }),
                        _c("TooltipInfo", {
                          attrs: {
                            "icon-class": "align-self-start ml-2",
                            "icon-color": "primary",
                            text: _vm.$t(
                              "common:infotekstit.kuukausiraporttiInfo.onlyWeekdays"
                            ),
                          },
                        }),
                      ],
                      1
                    ),
                    _c("v-combobox", {
                      staticClass: "required",
                      attrs: {
                        items: [],
                        delimiters: [",", ";", " "],
                        chips: "",
                        "append-icon": "",
                        multiple: "",
                        "deletable-chips": "",
                        label: _vm.$t("common:emailTo"),
                        placeholder: _vm.$t(
                          "common:formKuukausiraportti.giveOneOrMoreEmailMsg"
                        ),
                        "persistent-placeholder": "",
                        type: "text",
                        rules: [
                          _vm.$validationRules.emailArray,
                          _vm.$validationRules.requiredArrayLength,
                        ],
                      },
                      model: {
                        value: _vm.kuukausiraportti.emailOsoitteet,
                        callback: function ($$v) {
                          _vm.$set(_vm.kuukausiraportti, "emailOsoitteet", $$v)
                        },
                        expression: "kuukausiraportti.emailOsoitteet",
                      },
                    }),
                    _c("v-menu", {
                      attrs: {
                        "close-on-content-click": false,
                        "max-width": "290",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "div",
                                { staticClass: "comboField" },
                                [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      {
                                        staticClass: "mt-1 pt-1 required",
                                        class: _vm.seuraavaRaporttiClassArr,
                                        attrs: {
                                          label: _vm.$t("common:nextReport"),
                                          value: _vm.$dateFnsFormat(
                                            _vm.kuukausiraportti
                                              .seuraava_ajopaiva
                                          ),
                                          readonly: "",
                                          rules: [
                                            _vm.$validationRules.required,
                                          ],
                                        },
                                      },
                                      on
                                    )
                                  ),
                                  _c("TooltipInfo", {
                                    attrs: {
                                      "icon-class": "align-self-start ml-2",
                                      "icon-color": "primary",
                                      text: _vm.$t(
                                        "common:infotekstit.kuukausiraporttiInfo.nextRunDate"
                                      ),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "default",
                          fn: function () {
                            return [
                              _c("v-date-picker", {
                                staticClass: "mt-1 pt-1",
                                attrs: {
                                  locale: _vm.$languageLocale(),
                                  "first-day-of-week": "1",
                                  "no-title": "",
                                  hint: _vm.$t(
                                    "common:infotekstit.kuukausiraporttiInfo.nextRunDate"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.seuraavaAjopaivaMenu = false
                                  },
                                },
                                model: {
                                  value: _vm.kuukausiraportti.seuraava_ajopaiva,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kuukausiraportti,
                                      "seuraava_ajopaiva",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "kuukausiraportti.seuraava_ajopaiva",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.seuraavaAjopaivaMenu,
                        callback: function ($$v) {
                          _vm.seuraavaAjopaivaMenu = $$v
                        },
                        expression: "seuraavaAjopaivaMenu",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              { staticClass: "mt-4 pa-3", attrs: { justify: "end" } },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-4",
                    attrs: { large: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("close")
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("common:cancel")) + " ")]
                ),
                _c(
                  "v-btn",
                  {
                    attrs: {
                      large: "",
                      color: "action",
                      disabled: !_vm.valid || _vm.loading,
                    },
                    on: { click: _vm.tallenna },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("common:save")) + " ")]
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }