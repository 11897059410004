/**
 * https://stackoverflow.com/questions/48327261/way-to-make-inheritance-in-vuex-modules/56790942#56790942
 */

import api from "@/plugins/api";
import { HttpError } from "@/utils/errors";
import { doRequestWithTimeout } from "@/utils/misc";
import BaseModule from "@/store/modules/baseModule";
import { Paamiesosoite } from "@/class/Paamies";

export default class Asetukset extends BaseModule {
  state() {
    return {
      ...super.state(),
      yhteydenottoosoite: new Paamiesosoite(),
      laskutusosoite: null,
      kirjeosoite: null,
      voimassaOlevanTilauksenTila: null,
      voimassaOlevanTilauksenLaskutusjakso: null,
      tilaukset: [],
      tilauksetKokonaisLkm: 0,
      paamieslaskut: [],
      paamieslaskutKokonaisLkm: 0,
      paamieslogo: null,
    };
  }

  mutations() {
    return {
      setYhteydenottoosoite(state, payload) {
        state.yhteydenottoosoite = payload;
      },
      setLaskutusosoite(state, payload) {
        state.laskutusosoite = payload;
      },
      setKirjeosoite(state, payload) {
        state.kirjeosoite = payload;
      },
      setVoimassaOlevanTilauksenTila(state, payload) {
        state.voimassaOlevanTilauksenTila = payload;
      },
      setVoimassaOlevanTilauksenLaskutusjakso(state, payload) {
        state.voimassaOlevanTilauksenLaskutusjakso = payload;
      },
      setTilaukset(state, payload) {
        state.tilaukset = payload;
      },
      setTilauksetKokonaisLkm(state, payload) {
        state.tilauksetKokonaisLkm = payload;
      },
      setPaamieslaskut(state, payload) {
        state.paamieslaskut = payload;
      },
      setPaamieslaskutKokonaisLkm(state, payload) {
        state.paamieslaskutKokonaisLkm = payload;
      },
      setPaamieslogo(state, payload) {
        state.paamieslogo = payload;
      },
    };
  }

  actions() {
    return {
      async haePaamiesosoitteet({ commit, rootState }) {
        const request = await doRequestWithTimeout(api.Paamies, {
          method: "GET",
          url: `${rootState.user.valittuPaamies.id}/paamiesosoitteet/`,
        });
        if (!request.success) throw new HttpError(request);

        // Yhteydenotto-osoite palautetaan aina
        commit(
          "setYhteydenottoosoite",
          new Paamiesosoite(request.result.body.yhteydenottoosoite)
        );

        // Jos laskutusosoite tai kirjeosoite on sama kuin yhteydenotto-osoite palautuu null
        const laskutusosoite = request.result.body.laskutusosoite
          ? new Paamiesosoite(request.result.body.laskutusosoite)
          : request.result.body.laskutusosoite;

        commit("setLaskutusosoite", laskutusosoite);

        const kirjeosoite = request.result.body.kirjeosoite
          ? new Paamiesosoite(request.result.body.kirjeosoite)
          : request.result.body.kirjeosoite;

        commit("setKirjeosoite", kirjeosoite);
      },

      async paamiesosoitteetSubmit({ state, rootState }) {
        const body = {
          yhteydenottoosoite: state.yhteydenottoosoite.getPostData(),
          // Jos osoite on null, lähetetään null-arvo
          kirjeosoite:
            state.kirjeosoite === null ? null : state.kirjeosoite.getPostData(),
          laskutusosoite:
            state.laskutusosoite === null
              ? null
              : state.laskutusosoite.getPostData(),
        };

        const request = await doRequestWithTimeout(
          api.Paamies,
          {
            method: "PUT",
            url: `${rootState.user.valittuPaamies.id}/paamiesosoitteet/`,
            body: body,
          },
          "doSingleRequest"
        );
        if (!request.success) throw new HttpError(request);
      },

      async haeTilaukset({ dispatch, commit, state, rootState }, page = 1) {
        const query = new URLSearchParams();
        query.append("paamies_id", rootState.user.valittuPaamies.id);
        query.append("order", "-paattymispaiva");
        query.append("page", page);

        const request = await doRequestWithTimeout(
          api.Palvelusopimustilaus,
          { query: query.toString() },
          "doSingleRequest"
        );

        if (!request.success) throw new this.$HttpError(request);

        commit("setTilaukset", request.result.body.results);
        commit("setTilauksetKokonaisLkm", request.result.body.count);
        if (page === 1) {
          dispatch("setVoimassaOlevanTilauksenTilaJaLaskutusjakso");
        }
      },

      async setVoimassaOlevanTilauksenTilaJaLaskutusjakso({ commit, state }) {
        let tila = null,
          laskutusjakso = null;
        if (state.tilaukset && state.tilaukset.length > 0) {
          tila = state.tilaukset[0].tila;
          laskutusjakso = state.tilaukset[0].laskutusjakso;
          commit("setVoimassaOlevanTilauksenTila", tila);
          commit("setVoimassaOlevanTilauksenLaskutusjakso", laskutusjakso);
        }
      },

      async haePaamieslaskut({ commit, rootState }, page = 1) {
        const query = new URLSearchParams();
        query.append("page", page);

        const request = await doRequestWithTimeout(
          api.Paamies,
          {
            method: "GET",
            url: `${rootState.user.valittuPaamies.id}/palvelusopimuspaamieslaskut/`,
            query: query.toString(),
          },
          "pushRequest"
        );
        if (!request.success) throw new this.$HttpError(request);

        commit("setPaamieslaskut", request.result.body.results);
        commit("setPaamieslaskutKokonaisLkm", request.result.body.count);
      },

      async haeTalletettuPaamieslogo({ commit, rootState }) {
        commit("setPaamieslogo", null);
        const queryParams = new URLSearchParams();
        let mimeType = "image/png";

        const request = await doRequestWithTimeout(api.WithAuthService, {
          url: `paamies_logo/${rootState.user.valittuPaamies.id}/`,
          query: queryParams.toString(),
        });

        if (!request.success && request.result.status !== 404)
          throw new this.$HttpError(request);

        if (!request.result || !request.result.body) return;
        if (request.result.headers && request.result.headers["content-type"]) {
          mimeType = request.result.headers["content-type"];
        }

        const paamieslogo = new Image();
        const byteCharacters = atob(request.result.body);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const file = new Blob([byteArray], { type: mimeType + ";base64" });
        paamieslogo.src = URL.createObjectURL(file);

        commit("setPaamieslogo", paamieslogo);
      },
    };
  }
}
