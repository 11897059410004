export default class BaseModule {
  getModule() {
    return {
      namespaced: true,
      state: this.state(),
      getters: this.getters(),
      mutations: this.mutations(),
      actions: this.actions(),
    };
  }

  state() {
    return {
      apiModule: apiModule,
      apiName: "", // Määrittele perivässä luokassa
      csvApiName: "", // Määrittele perivässä luokassa
    };
  }

  getters() {
    return {
      api: (state) => {
        return state.apiModule.getInstance(state.apiName);
      },
      csvApi: (state) => {
        return state.apiModule.getInstance(state.csvApiName);
      },
      countApi: (state) => {
        return state.apiModule.getInstance(state.apiName.concat("Count"));
      },
      apiModule: (state) => {
        return state.apiModule;
      },
    };
  }
}

// Kierretään circular import käyttämällää eräänlaista pseudosingletonia.
// Instanssi luodaan uudelleen kun vaihdetaan rajapintaa.
const apiModule = (function () {
  let instance;

  function createInstance(apiName) {
    const api = require("@/plugins/api");
    const instance = api.default[apiName];
    instance.apiName = apiName;
    return api.default[apiName];
  }

  return {
    getInstance: function (apiName) {
      if (!instance || instance.apiName !== apiName) {
        instance = createInstance(apiName);
      }
      return instance;
    },
  };
})();
