var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { ref: "wrap", attrs: { width: _vm.width, height: _vm.width } },
    [
      _vm.title
        ? _c(
            "div",
            [
              _c(
                "v-row",
                { class: _vm.titleClass, attrs: { align: "center" } },
                [
                  _c("v-col", { staticClass: "px-4" }, [
                    _vm.title
                      ? _c(
                          "h2",
                          [
                            _vm._v(" " + _vm._s(_vm.title) + " "),
                            _vm.tooltipInfoTeksti
                              ? _c("TooltipInfo", {
                                  attrs: {
                                    text: _vm.tooltipInfoTeksti,
                                    "icon-color": "primary",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.$slots["default"]
        ? _c(
            "div",
            { ref: "content", class: _vm.contentClass },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }