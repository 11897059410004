<template>
  <div>
    <template v-if="asiakas.id">
      <v-row justify="space-between">
        <v-col cols="7" class="py-0">
          <h3 class="">
            {{ $t("common:recipient") }}
            <template v-if="rooliselite">({{ rooliselite }})</template>
            <TooltipInfo
              v-if="rooliInfo"
              icon-color="primary"
              :text="rooliInfo"
            />
          </h3>
        </v-col>
        <v-col cols="4" class="col-icon py-0 text-right">
          <LomakenappiIkonilla
            v-if="asiakas.muistiinpano_set && asiakas.muistiinpano_set.length"
            ikoni="anitta-icon-note"
            :infoteksti="$t('customerNotes')"
            @click="$emit('avaa-dialog-asiakas-muistiinpanot')"
          />
          <LomakenappiIkonilla
            v-if="!muokkaamassaSopimusta"
            ikoni="clear"
            :infoteksti="$t('clear')"
            @click="tyhjennaAsiakas"
          />
          <LomakenappiIkonilla
            ikoni="edit"
            :infoteksti="$t('editCustomer')"
            @click="$emit('avaa-dialog-asiakas')"
          />
        </v-col>
      </v-row>

      <OsoiteKappale
        :nimi="asiakas.nimi"
        :renderoi-tyhja="true"
        :nayta-nimi="true"
        :saaja="asiakas.postiosoite.saaja ? asiakas.postiosoite.saaja : null"
        :postiosoite="
          asiakas.postiosoite.osoite ? asiakas.postiosoite.osoite : null
        "
        :postinumero="
          asiakas.postiosoite.numero ? asiakas.postiosoite.numero : null
        "
        :postitoimipaikka="
          asiakas.postiosoite.toimipaikka
            ? asiakas.postiosoite.toimipaikka
            : null
        "
      />
    </template>
    <template v-else>
      <v-autocomplete
        ref="autocompleteAsiakas"
        v-model="asiakas"
        :items="asiakasItems"
        :loading="asiakasLoading"
        :search-input.sync="asiakasSearchQuery"
        :hide-no-data="!asiakasSearchQuery || asiakasLoading"
        item-text="nimi"
        item-value="id"
        label="Vastaanottaja"
        :placeholder="
          paamies.id ? 'Hae asiakkaan nimellä' : 'Valitse ensin laskuttaja'
        "
        persistent-placeholder
        :disabled="!paamies.id"
        return-object
        class="required"
        :rules="[validoiAsiakas]"
        :validate-on-blur="true"
      >
        <template #item="{ item }">
          <v-list-item-content>
            <v-list-item-title>{{ item.nimi }}</v-list-item-title>
            <v-list-item-subtitle v-if="item.postiosoite">
              <template v-if="item.postiosoite.osoite">
                {{ item.postiosoite.osoite }},
              </template>
              <template v-if="item.postiosoite.numero">
                {{ item.postiosoite.numero }}
              </template>
              <template v-if="item.postiosoite.toimipaikka">
                {{ item.postiosoite.toimipaikka }}
              </template>
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
        <template #no-data>
          <p class="pa-4">Haulla ei löytynyt yhtäkään asiakasta.</p>
        </template>
        <template v-if="!asiakas.id" #append-outer>
          <LomakenappiIkonilla
            ikoni="person_add"
            :infoteksti="$t('createCustomer')"
            @click="$emit('avaa-dialog-asiakas')"
          />
        </template>
      </v-autocomplete>
    </template>
  </div>
</template>

<script>
import OsoiteKappale from "@/components/OsoiteKappale";
import LomakenappiIkonilla from "@/components/LomakenappiIkonilla";
import { Asiakas } from "@/class/Asiakas";
import Sopimus from "@/class/Sopimus";

export default {
  name: "SopimusAsiakasKappale",
  components: {
    OsoiteKappale,
    LomakenappiIkonilla,
  },
  props: {
    value: {
      type: Asiakas,
      default: new Asiakas(),
    },
    asiakasItemsExclude: {
      type: Array,
      required: true,
    },
    paamies: {
      type: Object,
      required: true,
    },
    muokkaamassaSopimusta: {
      type: Boolean,
      required: true,
    },
    rooliselite: {
      type: String,
      required: true,
    },
    rooliInfo: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      asiakasItems: [],
      asiakasLoading: false,
      asiakasSearchQuery: "",
      debounceDelay: 300,
      debounceTimeout: "",
      odotusaikaYlitetty: false,
      paivanumeroMenu: false,
      laskupohjatItems: [],
      maksuehtoClassArr: ["required", "mt-1", "pt-1"],
      paivanValintaPaivamaara: null,
      sopimusjaksoItems: Sopimus.SOPIMUSJAKSOT,
      seuraavaLaskutuspaivaMenu: false,
      seuraavaLaskutuspaivaVaroitus: "",
    };
  },
  computed: {
    asiakas: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    paamies: {
      handler(paamies) {
        this.tyhjennaAsiakas();

        if (paamies.id) {
          this.asiakas.paamies_id = paamies.id;
          this.$nextTick(() => {
            this.$refs.autocompleteAsiakas.resetValidation();
          });
        }
      },
    },
    async asiakasSearchQuery(val) {
      if (!this.paamies.id) return;

      this.asiakasItems = [];

      if (!val) {
        this.asiakas = new Asiakas({ paamies_id: this.paamies.id });
        return;
      }

      this.asiakasLoading = true;
      clearTimeout(this.debounceTimeout);

      this.debounceTimeout = setTimeout(async () => {
        try {
          const query = new URLSearchParams();
          query.append("paamies", this.paamies.id);
          query.append("nimi", val);
          query.append("order", "nimi");
          // Ei näytetä hakutuloksissa jo sopimuksella olevia asiakkaita
          if (this.asiakasItemsExclude.length) {
            // Backend haluaa pilkulla erotetut arvot
            query.append("exclude__id__in", this.asiakasItemsExclude.join(","));
          }

          const request = await this.$doRequestWithTimeout(
            this.$api.AsiakasLaskulle,
            {
              method: "GET",
              query: query.toString(),
            },
            "pushRequest"
          );

          if (!request.success) throw new this.$HttpError(request);

          const { results } = request.result.body;

          this.asiakasItems = results.map((asiakas) => {
            return new Asiakas(asiakas);
          });
        } catch (e) {
          this.$sentryCaptureCustom(e);
        } finally {
          this.asiakasLoading = false;
        }
      }, this.debounceDelay);
    },
  },
  methods: {
    tyhjennaAsiakas() {
      this.asiakas = new Asiakas({ paamies_id: this.paamies.id });
    },
    validoiAsiakas() {
      if (!this.asiakas.nimi) {
        return "Pakollinen tieto puuttuu";
      } else {
        return true;
      }
    },
  },
};
</script>
