var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-form", { ref: "saatavariviTable" }, [
        _c("table", { staticClass: "riviTable" }, [
          _c("thead", [
            _c(
              "tr",
              [
                _c(
                  "th",
                  {
                    class: [..._vm.thClassArr, "riviTable__cell--required"],
                    attrs: { scope: "col" },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("common:invoiceNo")) + " ")]
                ),
                _c(
                  "th",
                  {
                    class: [..._vm.thClassArr, "riviTable__cell--required"],
                    attrs: { scope: "col" },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("common:invoiceDate")) + " ")]
                ),
                _c(
                  "th",
                  {
                    class: [..._vm.thClassArr, "riviTable__cell--required"],
                    attrs: { scope: "col" },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("common:dueDate")) + " ")]
                ),
                _vm.toimeksianto.muistutettu
                  ? [
                      _c(
                        "th",
                        {
                          class: _vm.saatavakohtainenMuistutus
                            ? [..._vm.thClassArr]
                            : [..._vm.thClassArr, "riviTable__cell--required"],
                          attrs: { scope: "col" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("common:reminderDate")) + " "
                          ),
                        ]
                      ),
                      _c(
                        "th",
                        {
                          class: _vm.saatavakohtainenMuistutus
                            ? [..._vm.thClassArr]
                            : [..._vm.thClassArr, "riviTable__cell--required"],
                          attrs: { scope: "col" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("common:reminderDueDate")) + " "
                          ),
                        ]
                      ),
                      _c(
                        "th",
                        {
                          class: _vm.saatavakohtainenMuistutus
                            ? [..._vm.thClassArr]
                            : [..._vm.thClassArr, "riviTable__cell--required"],
                          attrs: { scope: "col" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("common:reminderFee")) + " "
                          ),
                        ]
                      ),
                    ]
                  : _vm._e(),
                _c(
                  "th",
                  {
                    class: [..._vm.thClassArr, "riviTable__cell--required"],
                    attrs: { scope: "col" },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("common:invoiceRef")) + " ")]
                ),
                _c(
                  "th",
                  {
                    class: [
                      ..._vm.thClassArr,
                      "riviTable__cell--required",
                      "text-right",
                    ],
                    attrs: { scope: "col" },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("common:capital")) + " ")]
                ),
                _c("th", { class: _vm.thClassArr, attrs: { scope: "col" } }, [
                  _vm._v(" " + _vm._s(_vm.$t("common:definition")) + " "),
                ]),
                _c("th", { class: _vm.thClassArr, attrs: { scope: "col" } }, [
                  _vm._v(" " + _vm._s(_vm.$t("common:lateInterest")) + " "),
                ]),
              ],
              2
            ),
          ]),
          _c(
            "tbody",
            _vm._l(_vm.toimeksianto.rivit, function (item, index) {
              return _c(
                "tr",
                {
                  key: item.index,
                  on: { input: _vm.lahetaEventKayttajasyote },
                },
                [
                  _c(
                    "td",
                    {
                      class: [
                        ..._vm.tdClassArr,
                        "riviTable__cell--min-width-medium",
                      ],
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          type: "text",
                          rules: [_vm.$validationRules.required],
                        },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.toimeksianto.lisaaRivi(index)
                          },
                        },
                        model: {
                          value: item.laskunumero,
                          callback: function ($$v) {
                            _vm.$set(item, "laskunumero", $$v)
                          },
                          expression: "item.laskunumero",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { class: _vm.tdClassArr },
                    [
                      _c("v-menu", {
                        attrs: {
                          "close-on-content-click": false,
                          "max-width": "290",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      {
                                        attrs: {
                                          value: _vm.$dateFnsFormat(
                                            item.laskupaiva
                                          ),
                                          readonly: "",
                                          rules: [
                                            _vm.$validationRules.required,
                                          ],
                                        },
                                      },
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                            {
                              key: "default",
                              fn: function () {
                                return [
                                  _c("v-date-picker", {
                                    attrs: {
                                      locale: _vm.$languageLocale(),
                                      "first-day-of-week": "1",
                                      "no-title": "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        item.laskupaivaMenu = false
                                      },
                                    },
                                    model: {
                                      value: item.laskupaiva,
                                      callback: function ($$v) {
                                        _vm.$set(item, "laskupaiva", $$v)
                                      },
                                      expression: "item.laskupaiva",
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: item.laskupaivaMenu,
                          callback: function ($$v) {
                            _vm.$set(item, "laskupaivaMenu", $$v)
                          },
                          expression: "item.laskupaivaMenu",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { class: _vm.tdClassArr },
                    [
                      _c("v-menu", {
                        attrs: {
                          "close-on-content-click": false,
                          "max-width": "290",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      {
                                        attrs: {
                                          value: _vm.$dateFnsFormat(
                                            item.erapaiva
                                          ),
                                          readonly: "",
                                          rules: [
                                            _vm.$validationRules.required,
                                          ],
                                        },
                                      },
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                            {
                              key: "default",
                              fn: function () {
                                return [
                                  _c("v-date-picker", {
                                    attrs: {
                                      locale: _vm.$languageLocale(),
                                      "first-day-of-week": "1",
                                      "no-title": "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        item.erapaivaMenu = false
                                      },
                                    },
                                    model: {
                                      value: item.erapaiva,
                                      callback: function ($$v) {
                                        _vm.$set(item, "erapaiva", $$v)
                                      },
                                      expression: "item.erapaiva",
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: item.erapaivaMenu,
                          callback: function ($$v) {
                            _vm.$set(item, "erapaivaMenu", $$v)
                          },
                          expression: "item.erapaivaMenu",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.toimeksianto.muistutettu
                    ? [
                        _c(
                          "td",
                          { class: _vm.tdClassArr },
                          [
                            _c("v-menu", {
                              attrs: {
                                "close-on-content-click": false,
                                "max-width": "290",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            {
                                              attrs: {
                                                value: _vm.$dateFnsFormat(
                                                  item.muistutuspaiva
                                                ),
                                                readonly: "",
                                                rules:
                                                  _vm.toimeksianto
                                                    .muistutettu &&
                                                  !_vm.saatavakohtainenMuistutus
                                                    ? [
                                                        _vm.$validationRules
                                                          .required,
                                                      ]
                                                    : [],
                                              },
                                            },
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "default",
                                    fn: function () {
                                      return [
                                        _c("v-date-picker", {
                                          attrs: {
                                            locale: _vm.$languageLocale(),
                                            "first-day-of-week": "1",
                                            "no-title": "",
                                          },
                                          on: {
                                            change: function ($event) {
                                              item.muistutuspaivaMenu = false
                                            },
                                          },
                                          model: {
                                            value: item.muistutuspaiva,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "muistutuspaiva",
                                                $$v
                                              )
                                            },
                                            expression: "item.muistutuspaiva",
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: item.muistutuspaivaMenu,
                                callback: function ($$v) {
                                  _vm.$set(item, "muistutuspaivaMenu", $$v)
                                },
                                expression: "item.muistutuspaivaMenu",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { class: _vm.tdClassArr },
                          [
                            _c("v-menu", {
                              attrs: {
                                "close-on-content-click": false,
                                "max-width": "290",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            {
                                              attrs: {
                                                value: _vm.$dateFnsFormat(
                                                  item.muistutuserapaiva
                                                ),
                                                readonly: "",
                                                rules:
                                                  _vm.toimeksianto
                                                    .muistutettu &&
                                                  !_vm.saatavakohtainenMuistutus
                                                    ? [
                                                        _vm.$validationRules
                                                          .required,
                                                      ]
                                                    : [],
                                              },
                                            },
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "default",
                                    fn: function () {
                                      return [
                                        _c("v-date-picker", {
                                          attrs: {
                                            locale: _vm.$languageLocale(),
                                            "first-day-of-week": "1",
                                            "no-title": "",
                                          },
                                          on: {
                                            change: function ($event) {
                                              item.muistutuserapaivaMenu = false
                                            },
                                          },
                                          model: {
                                            value: item.muistutuserapaiva,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "muistutuserapaiva",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.muistutuserapaiva",
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: item.muistutuserapaivaMenu,
                                callback: function ($$v) {
                                  _vm.$set(item, "muistutuserapaivaMenu", $$v)
                                },
                                expression: "item.muistutuserapaivaMenu",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { class: _vm.tdClassArr },
                          [
                            _c("v-text-field", {
                              attrs: {
                                type: "number",
                                rules:
                                  _vm.toimeksianto.muistutettu &&
                                  !_vm.saatavakohtainenMuistutus
                                    ? [
                                        _vm.$validationRules.required,
                                        _vm.$validationRules.maxDecimalPlacesOrEmpty(
                                          item.muistutuskulu,
                                          2
                                        ),
                                        _vm.$validationRules.zeroOrBigger,
                                      ]
                                    : [
                                        _vm.$validationRules.maxDecimalPlacesOrEmpty(
                                          item.muistutuskulu,
                                          2
                                        ),
                                        _vm.$validationRules
                                          .zeroOrBiggerWithDecimal,
                                      ],
                                required: "",
                                reverse: "",
                              },
                              on: {
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.toimeksianto.lisaaRivi(index)
                                },
                              },
                              model: {
                                value: item.muistutuskulu,
                                callback: function ($$v) {
                                  _vm.$set(item, "muistutuskulu", $$v)
                                },
                                expression: "item.muistutuskulu",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "td",
                    { class: _vm.tdClassArr },
                    [
                      _c("v-text-field", {
                        attrs: { type: "text", reverse: "" },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.toimeksianto.lisaaRivi(index)
                          },
                        },
                        model: {
                          value: item.pm_viite,
                          callback: function ($$v) {
                            _vm.$set(item, "pm_viite", $$v)
                          },
                          expression: "item.pm_viite",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { class: _vm.tdClassArr },
                    [
                      _c("v-text-field", {
                        attrs: {
                          type: "number",
                          rules: [
                            _vm.$validationRules.required,
                            _vm.$validationRules.maxDecimalPlacesOrEmpty(
                              item.paaoma,
                              2
                            ),
                            _vm.$validationRules.zeroOrBigger,
                          ],
                          required: "",
                          reverse: "",
                        },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.toimeksianto.lisaaRivi(index)
                          },
                        },
                        model: {
                          value: item.paaoma,
                          callback: function ($$v) {
                            _vm.$set(item, "paaoma", $$v)
                          },
                          expression: "item.paaoma",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { class: _vm.tdClassArr },
                    [
                      _c("v-text-field", {
                        attrs: { type: "text", reverse: "" },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.toimeksianto.lisaaRivi(index)
                          },
                        },
                        model: {
                          value: item.selite,
                          callback: function ($$v) {
                            _vm.$set(item, "selite", $$v)
                          },
                          expression: "item.selite",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { class: _vm.tdClassArr },
                    [
                      _vm.toimeksianto.velallinen.tyyppi === "Y"
                        ? _c("v-text-field", {
                            attrs: {
                              type: "number",
                              rules: [
                                _vm.$validationRules.maxDecimalPlacesOrEmpty(
                                  item.viivastyskorko,
                                  2
                                ),
                                _vm.$validationRules.numberOrEmptyWithDecimal,
                                _vm.$validationRules.zeroOrBiggerWithDecimal,
                                _vm.$validationRules.smallerThanOrEqualToWithDecimal(
                                  item.viivastyskorko,
                                  100
                                ),
                              ],
                              suffix: "%",
                              placeholder: _vm.$t(
                                "common:accordingToKorkolaki"
                              ),
                            },
                            on: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.toimeksianto.lisaaRivi(index)
                              },
                            },
                            model: {
                              value: item.viivastyskorko,
                              callback: function ($$v) {
                                _vm.$set(item, "viivastyskorko", $$v)
                              },
                              expression: "item.viivastyskorko",
                            },
                          })
                        : _c("v-select", {
                            attrs: { items: _vm.viivastyskorkoItems },
                            model: {
                              value: item.viivastyskorko,
                              callback: function ($$v) {
                                _vm.$set(item, "viivastyskorko", $$v)
                              },
                              expression: "item.viivastyskorko",
                            },
                          }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { staticClass: "riviTable__cell--btn" },
                    [
                      _vm.salliPoistaminen
                        ? _c("LomakenappiIkonilla", {
                            attrs: {
                              ikoni: "delete",
                              infoteksti: _vm.$t("deleteRow"),
                              "nayta-aina": "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.poistaRivi(index)
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                2
              )
            }),
            0
          ),
          _vm.naytaYhteenveto
            ? _c("tfoot", { staticClass: "riviTable__footer" }, [
                _c("div", { staticClass: "mt-5" }),
                _c("tr", [
                  _c(
                    "td",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.toimeksianto.lisaaRivi()
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "", icon: "" } }, [
                            _vm._v("library_add"),
                          ]),
                          _vm._v(" " + _vm._s(_vm.$t("common:newRow")) + " "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "th",
                    {
                      staticClass: "riviTable__cell riviTable__cell--footer",
                      attrs: { colspan: "3", scope: "row" },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("common:total")) + " ")]
                  ),
                  _c(
                    "td",
                    { staticClass: "riviTable__cell riviTable__cell--footer" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("formatSumToFixed2")(_vm.paaomaYhteensa)
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("td", {
                    staticClass: "riviTable__cell riviTable__cell--footer",
                  }),
                  _c("td", {
                    staticClass: "riviTable__cell riviTable__cell--footer",
                  }),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }