var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefList",
    { attrs: { bordered: true } },
    [
      _vm.value.tuoteryhma
        ? _c("DefListItem", {
            scopedSlots: _vm._u(
              [
                {
                  key: "label",
                  fn: function () {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("common:productGroup.productGroupHeader")
                          ) +
                          " "
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "value",
                  fn: function () {
                    return [
                      _vm._v(" " + _vm._s(_vm.value.tuoteryhma.nimi) + " "),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3554299371
            ),
          })
        : _vm._e(),
      _c("DefListItem", {
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [_vm._v(" " + _vm._s(_vm.$t("common:code")) + " ")]
            },
            proxy: true,
          },
          {
            key: "value",
            fn: function () {
              return [_vm._v(" " + _vm._s(_vm.value.koodi) + " ")]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.value.paamies
        ? _c("DefListItem", {
            scopedSlots: _vm._u(
              [
                {
                  key: "label",
                  fn: function () {
                    return [
                      _vm._v(" " + _vm._s(_vm.$t("common:creditor")) + " "),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "value",
                  fn: function () {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.value.paamies.nimi) +
                          " (" +
                          _vm._s(_vm.value.paamies.id) +
                          ") "
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              4248925776
            ),
          })
        : _vm._e(),
      _vm.value.selite
        ? _c("DefListItem", {
            scopedSlots: _vm._u(
              [
                {
                  key: "label",
                  fn: function () {
                    return [
                      _vm._v(" " + _vm._s(_vm.$t("common:definition")) + " "),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "value",
                  fn: function () {
                    return [_vm._v(" " + _vm._s(_vm.value.selite) + " ")]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2361918384
            ),
          })
        : _vm._e(),
      _vm.value.vapaateksti
        ? _c("DefListItem", {
            scopedSlots: _vm._u(
              [
                {
                  key: "label",
                  fn: function () {
                    return [
                      _vm._v(" " + _vm._s(_vm.$t("common:freeText")) + " "),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "value",
                  fn: function () {
                    return [_vm._v(" " + _vm._s(_vm.value.vapaateksti) + " ")]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2965937053
            ),
          })
        : _vm._e(),
      _c("DefListItem", {
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [_vm._v(" " + _vm._s(_vm.$sovellusIdOtsake) + " ")]
            },
            proxy: true,
          },
          {
            key: "value",
            fn: function () {
              return [_vm._v(" " + _vm._s(_vm.value.id) + " ")]
            },
            proxy: true,
          },
        ]),
      }),
      _c("DefListItem", {
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [_vm._v(" " + _vm._s(_vm.$t("common:uPrice")) + " ")]
            },
            proxy: true,
          },
          {
            key: "value",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("formatSumToFixed2")(_vm.value.ahinta)) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.value.laatu
        ? _c("DefListItem", {
            scopedSlots: _vm._u(
              [
                {
                  key: "label",
                  fn: function () {
                    return [
                      _vm._v(" " + _vm._s(_vm.$t("common:quantityUnit")) + " "),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "value",
                  fn: function () {
                    return [_vm._v(" " + _vm._s(_vm.value.laatu) + " ")]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1233536695
            ),
          })
        : _vm._e(),
      _vm.value.alvtunnus
        ? _c("DefListItem", {
            scopedSlots: _vm._u(
              [
                {
                  key: "label",
                  fn: function () {
                    return [_vm._v(" " + _vm._s(_vm.$t("common:vatId")) + " ")]
                  },
                  proxy: true,
                },
                {
                  key: "value",
                  fn: function () {
                    return [
                      _vm._v(" " + _vm._s(_vm.value.alvtunnus.tunnus) + " "),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2602152252
            ),
          })
        : _vm._e(),
      _c("DefListItem", {
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [_vm._v(" " + _vm._s(_vm.$t("common:currency")) + " ")]
            },
            proxy: true,
          },
          {
            key: "value",
            fn: function () {
              return [_vm._v(" " + _vm._s(_vm.value.valuutta) + " ")]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.value.nykyinen_varastotilanne
        ? _c("DefListItem", {
            scopedSlots: _vm._u(
              [
                {
                  key: "label",
                  fn: function () {
                    return [
                      _vm._v(" " + _vm._s(_vm.$t("common:stockStatus")) + " "),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "value",
                  fn: function () {
                    return [
                      _vm._v(
                        " " + _vm._s(_vm.value.nykyinen_varastotilanne) + " "
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              4206713212
            ),
          })
        : _vm._e(),
      _vm.value.hankintahinta
        ? _c("DefListItem", {
            scopedSlots: _vm._u(
              [
                {
                  key: "label",
                  fn: function () {
                    return [
                      _vm._v(
                        " " + _vm._s(_vm.$t("common:purchasePrice")) + " "
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "value",
                  fn: function () {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("formatSumToFixed2")(_vm.value.hankintahinta)
                          ) +
                          " "
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              254561547
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }