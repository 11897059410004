<template>
  <v-form ref="formVieMaksuun" v-model="valid" @submit.prevent="submit">
    <v-menu
      v-model="maksupaivaMenu"
      :close-on-content-click="false"
      max-width="290"
      nudge-bottom="40"
    >
      <template #activator="{ on }">
        <v-text-field
          label="Maksupäivä"
          :value="$dateFnsFormat(value.maksupaiva)"
          readonly
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="value.maksupaiva"
        locale="fi-FI"
        first-day-of-week="1"
        no-title
        @change="maksupaivaMenu = false"
      />
    </v-menu>
    <v-select
      v-model="value.tili_id"
      label="Ostotili"
      :items="ostotiliItems"
      :item-text="muotoileTiliItem"
      item-value="id"
      class="required"
      :rules="[$validationRules.required]"
    />
    <v-text-field
      v-if="!ostolaskut.length"
      v-model="value.summa"
      type="text"
      label="Summa"
      :rules="[$validationRules.numberOrEmpty]"
      suffix="€"
    />
    <v-text-field
      v-else
      v-model="ostolaskutAvoinnaYht"
      disabled
      type="text"
      label="Summa"
      suffix="€"
    />
    <PeruutaHyvaksyPainikerivi
      :submit-disabled="!valid || loading"
      :submit-loading="loading"
      @close="$emit('close')"
    />
  </v-form>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";
import formatSumToFixed2 from "@/utils/filters/formatSumToFixed2";

export default {
  name: "FormVieMaksuun",
  mixins: [FormMixin],
  props: {
    /* Yksittäisen ostolaskun maksuun vienti */
    ostolasku: {
      type: Object,
      default: () => {},
    },
    /* Usean ostolaskun maksuun vienti kerrallaan */
    ostolaskut: {
      type: Array,
      default: () => [],
    },
    paamies: {
      type: Object,
      required: true,
    },
    ostotiliItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      ostolaskutAvoinnaYht: 0,
      maksupaivaMenu: false,
    };
  },
  watch: {
    onAuki: {
      immediate: true,
      handler(val) {
        if (val) {
          this.value.maksupaiva = new Date().toISOString().substr(0, 10);
          if (this.ostotiliItems.length)
            this.value.tili_id = this.ostotiliItems[0].id;
          this.$nextTick(() => {
            if (this.$refs.formVieMaksuun)
              this.$refs.formVieMaksuun.resetValidation();
          });

          if (this.ostolaskut.length) {
            this.ostolaskutAvoinnaYht = 0;
            for (const o of this.ostolaskut) {
              this.ostolaskutAvoinnaYht += o.avoinna;
            }
            this.ostolaskutAvoinnaYht = this.$muotoileDesimaaliluku(
              this.ostolaskutAvoinnaYht,
              true
            );
          } else {
            this.value.summa = this.ostolasku.avoinna;
          }
        }
      },
    },
  },
  methods: {
    muotoileTiliItem(tili) {
      return `${tili.iban} | ${tili.bic}`;
    },
    summaPlaceholder() {
      return `Koko lasku (${formatSumToFixed2(this.ostolasku.avoinna)})`;
    },
  },
};
</script>

<style lang="scss" scoped></style>
