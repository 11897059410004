<template>
  <div>
    <v-btn v-if="btn" color="action" @click="avaaSopimusDialog()">
      {{ $t("common:openContract") }}
    </v-btn>
    <v-dialog
      v-model="luottotietoSopimusAuki"
      persistent
      :fullscreen="wSize.sizeClass.value === 'small'"
    >
      <v-card class="dialog-card">
        <div class="dialog-header">
          <h1>{{ $t("common:creditRating.creditRatingContract") }}</h1>
          <v-chip color="action">Beta</v-chip>
          <v-spacer></v-spacer>
          <v-btn icon @click="suljeSopimusDialog()">
            <v-icon>close</v-icon>
          </v-btn>
        </div>
        <div v-if="currentStep === 'loading'" class="loading-container">
          <v-progress-circular indeterminate color="primary" />
        </div>
        <div
          v-else-if="currentStep === 'error'"
          class="container-denied content-container"
        >
          <div class="content-denied">
            <div>
              {{ error?.text }}
            </div>
            <v-btn
              v-if="error?.btnText"
              color="action"
              @click="error?.btnClick()"
            >
              {{ error?.btnText }}
            </v-btn>
          </div>
        </div>
        <div
          v-else-if="currentStep === 'aloitus'"
          class="contentainer-sign content-container"
        >
          <div class="content-sign">
            <div>
              {{ $t("common:creditRating.creditRatingContractIsNeeded") }}
            </div>
            <div>
              {{ $t("common:creditRating.contractAuthorizesAccess") }}
            </div>
            <div>
              {{ $t("common:creditRating.startContractCreation") }}
            </div>

            <v-btn
              color="action"
              :loading="btnLoading"
              @click="
                siirryTunnistautumaanVahvasti();
                btnLoading = true;
              "
            >
              {{ $t("common:authenticate") }}
            </v-btn>
          </div>
        </div>

        <div
          v-else-if="currentStep === 'vahvista'"
          class="container-check content-container"
        >
          <div class="content-check">
            <div>
              {{ $t("common:creditRating.weHaveConstructedContract") }}
            </div>
            <div>
              {{ $t("common:creditRating.uponSuccessfulConfirmation") }}
            </div>
            <div>
              {{
                $t("common:creditRating.personalCreditInformationInquiriesNeed")
              }}
            </div>
            <v-btn color="action" @click="vahvistaAllekirjoitus()">
              {{ $t("common:creditRating.confirmSignature") }}
            </v-btn>
          </div>
        </div>

        <div
          v-else-if="currentStep === 'sopimus'"
          class="container-contract content-container"
        >
          <div class="paamies">
            <div class="heading">{{ $t("common:creditor") }}</div>
            <div>{{ valittuPaamies.nimi }} ({{ valittuPaamies.id }})</div>
          </div>
          <div class="tila">
            <div class="heading">
              {{ $t("common:creditRating.contractStatus") }}
            </div>
            <div>
              {{ luottotiedot.sopimus.tila ? "Voimassa" : "Ei Voimassa" }}
            </div>
          </div>
          <div class="yritys">
            <div class="heading">
              {{ $t("common:creditRating.businessCreditInformationSearch") }}
            </div>
            <div>
              {{ luottotiedot.sopimus.yritys ? "Käytössä" : "Ei Käytössä" }}
            </div>
          </div>
          <div class="henkilo">
            <div class="heading">
              {{ $t("common:creditRating.personalCreditInformationSearch") }}
            </div>
            <div>
              {{ luottotiedot.sopimus.henkilo ? "Käytössä" : "Ei Käytössä" }}
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { useWindowSize } from "@/composables/windowSize";
import { mapState } from "vuex";
import {
  LuottotietoSopimusTila,
  LuottotietoSopimusAllekirjoitus,
} from "../enums";

export default {
  name: "Luottotiedot",
  props: {
    btn: {
      type: Boolean,
      required: false,
      default() {
        return true;
      },
    },
  },
  data() {
    return {
      currentStep: null,
      error: null,
      tunnus: null,
      luottotietoSopimusAuki: false,
      btnLoading: false,
    };
  },
  computed: {
    ...mapState({
      luottotiedot: (state) => state.luottotiedot,
      user: (state) => state.user,
    }),
    wSize() {
      return useWindowSize();
    },
    valittuPaamies() {
      return this.user.valittuPaamies;
    },
  },
  methods: {
    async avaaSopimusDialog() {
      this.currentStep = "loading";
      this.luottotietoSopimusAuki = true;
      const paamies_id = this.valittuPaamies.id;
      await this.$store.dispatch("luottotiedot/haeSopimuksenTila", paamies_id);
      if (this.luottotiedot.sopimus?.tila) {
        if (this.luottotiedot.sopimus.tila === LuottotietoSopimusTila.OK) {
          this.currentStep = "sopimus";
        } else if (
          this.luottotiedot.sopimus.tila ===
          LuottotietoSopimusTila.ODOTTAA_ALLEKIRJOITUSTA
        ) {
          this.currentStep = "vahvista";
        } else if (
          this.luottotiedot.sopimus.tila === LuottotietoSopimusTila.EI_OLE
        ) {
          this.currentStep = "aloitus";
        } else {
          this.setError("Sopimuksen tila ei vastannut mitään odotetuista");
        }
      } else {
        this.setError("Sopimuksen tilaa ei löytynyt");
      }
    },
    suljeSopimusDialog() {
      this.currentStep = null;
      this.tunnus = null;
      this.luottotietoSopimusAuki = false;
    },

    async vahvistaAllekirjoitus() {
      const paamies_id = this.valittuPaamies.id;
      await this.$store
        .dispatch("luottotiedot/haeAllekirjoituksenTila", paamies_id)
        .then((payload) => {
          if (payload.tila) {
            switch (payload.tila) {
              case LuottotietoSopimusAllekirjoitus.EI_OLE_ALLEKIRJOITETTAVAA:
                this.setError(
                  "Sopimuksen luonnissa on tapahtunut virhe.",
                  "Siirry luomaan sopimus",
                  this.aloitus
                );
                break;
              case LuottotietoSopimusAllekirjoitus.NEW:
                this.setError(
                  "Tietojemme mukaan sopimuksesi on nyt luotu ja odottaa allekirjoitusta visma signissa."
                );
                break;
              case LuottotietoSopimusAllekirjoitus.PENDING:
                this.setError(
                  "Tietojemme mukaan sopimuksesi on luotu ja odottaa allekirjoitusta visma signissa."
                );
                break;
              case LuottotietoSopimusAllekirjoitus.DELETED:
                this.setError(
                  "Sopimus on poistettu, voit siirtyä alla olevasta painikkeesta luomaan uuden sopimuksen",
                  "Siirry luomaan sopimus",
                  this.aloitus
                );
                break;
              case LuottotietoSopimusAllekirjoitus.CANCELLED:
                this.setError(
                  "Sopimus on peruttu, voit siirtyä alla olevasta painikkeesta luomaan uuden sopimuksen",
                  "Siirry luomaan sopimus",
                  this.aloitus
                );
                break;
              case LuottotietoSopimusAllekirjoitus.SIGNED:
                this.currentStep = "sopimus";
                break;
              default:
            }
          } else {
            this.setError("Jotain meni pieleen");
          }
        })
        .catch((error) => {
          this.setError(error.message);
        });
    },
    siirryTunnistautumaanVahvasti() {
      const url =
        process.env.VUE_APP_INSE_ANITTA_LOGIN_URL +
        "?next=/luottotietosopimus/redirect/";

      // const url =process.env.VUE_APP_INSE_ANITTA_LOGIN_URL + ("?next=/luottotietosopimus/redirect/");
      // const url = process.env.VUE_APP_INSE_ANITTA_LOGIN_URL + '?next=/luottotietosopimus/redirect/localhost/8080/' (DEMOUSER1)

      window.location = url;
    },
    setError(text = null, btnText = null, btnClick = null) {
      this.error = {
        text: text,
        btnText: btnText,
        btnClick: btnClick,
      };
      this.currentStep = "error";
    },
    aloitus() {
      this.currentStep = "aloitus";
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-card {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  overflow-y: auto;
  height: 90vh;
}
.dialog-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.content-container,
.loading-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content-sign,
.content-check,
.content-success,
.content-denied {
  height: 50%;
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  text-align: center;
}
.paamies {
  grid-area: paamies;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tila {
  grid-area: tila;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.yritys {
  grid-area: yritys;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.henkilo {
  grid-area: henkilo;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container-contract {
  display: grid;
  grid-template-areas: "paamies . ." "tila yritys henkilo";
  grid-template-columns: 1fr 1fr 1fr;

  .heading {
    font-weight: bold;
    font-size: large;
    margin-bottom: 0.5rem;
  }
}
</style>
