var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.asiakas.id
        ? [
            _c(
              "v-row",
              { attrs: { justify: "space-between" } },
              [
                _c("v-col", { staticClass: "py-0", attrs: { cols: "7" } }, [
                  _c(
                    "h3",
                    {},
                    [
                      _vm._v(" " + _vm._s(_vm.$t("common:recipient")) + " "),
                      _vm.rooliselite
                        ? [_vm._v("(" + _vm._s(_vm.rooliselite) + ")")]
                        : _vm._e(),
                      _vm.rooliInfo
                        ? _c("TooltipInfo", {
                            attrs: {
                              "icon-color": "primary",
                              text: _vm.rooliInfo,
                            },
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
                _c(
                  "v-col",
                  {
                    staticClass: "col-icon py-0 text-right",
                    attrs: { cols: "4" },
                  },
                  [
                    _vm.asiakas.muistiinpano_set &&
                    _vm.asiakas.muistiinpano_set.length
                      ? _c("LomakenappiIkonilla", {
                          attrs: {
                            ikoni: "anitta-icon-note",
                            infoteksti: _vm.$t("customerNotes"),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit(
                                "avaa-dialog-asiakas-muistiinpanot"
                              )
                            },
                          },
                        })
                      : _vm._e(),
                    !_vm.muokkaamassaSopimusta
                      ? _c("LomakenappiIkonilla", {
                          attrs: {
                            ikoni: "clear",
                            infoteksti: _vm.$t("clear"),
                          },
                          on: { click: _vm.tyhjennaAsiakas },
                        })
                      : _vm._e(),
                    _c("LomakenappiIkonilla", {
                      attrs: {
                        ikoni: "edit",
                        infoteksti: _vm.$t("editCustomer"),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("avaa-dialog-asiakas")
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("OsoiteKappale", {
              attrs: {
                nimi: _vm.asiakas.nimi,
                "renderoi-tyhja": true,
                "nayta-nimi": true,
                saaja: _vm.asiakas.postiosoite.saaja
                  ? _vm.asiakas.postiosoite.saaja
                  : null,
                postiosoite: _vm.asiakas.postiosoite.osoite
                  ? _vm.asiakas.postiosoite.osoite
                  : null,
                postinumero: _vm.asiakas.postiosoite.numero
                  ? _vm.asiakas.postiosoite.numero
                  : null,
                postitoimipaikka: _vm.asiakas.postiosoite.toimipaikka
                  ? _vm.asiakas.postiosoite.toimipaikka
                  : null,
              },
            }),
          ]
        : [
            _c("v-autocomplete", {
              ref: "autocompleteAsiakas",
              staticClass: "required",
              attrs: {
                items: _vm.asiakasItems,
                loading: _vm.asiakasLoading,
                "search-input": _vm.asiakasSearchQuery,
                "hide-no-data": !_vm.asiakasSearchQuery || _vm.asiakasLoading,
                "item-text": "nimi",
                "item-value": "id",
                label: "Vastaanottaja",
                placeholder: _vm.paamies.id
                  ? "Hae asiakkaan nimellä"
                  : "Valitse ensin laskuttaja",
                "persistent-placeholder": "",
                disabled: !_vm.paamies.id,
                "return-object": "",
                rules: [_vm.validoiAsiakas],
                "validate-on-blur": true,
              },
              on: {
                "update:searchInput": function ($event) {
                  _vm.asiakasSearchQuery = $event
                },
                "update:search-input": function ($event) {
                  _vm.asiakasSearchQuery = $event
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "item",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-title", [
                              _vm._v(_vm._s(item.nimi)),
                            ]),
                            item.postiosoite
                              ? _c(
                                  "v-list-item-subtitle",
                                  [
                                    item.postiosoite.osoite
                                      ? [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.postiosoite.osoite) +
                                              ", "
                                          ),
                                        ]
                                      : _vm._e(),
                                    item.postiosoite.numero
                                      ? [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.postiosoite.numero) +
                                              " "
                                          ),
                                        ]
                                      : _vm._e(),
                                    item.postiosoite.toimipaikka
                                      ? [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.postiosoite.toimipaikka
                                              ) +
                                              " "
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "no-data",
                    fn: function () {
                      return [
                        _c("p", { staticClass: "pa-4" }, [
                          _vm._v("Haulla ei löytynyt yhtäkään asiakasta."),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  !_vm.asiakas.id
                    ? {
                        key: "append-outer",
                        fn: function () {
                          return [
                            _c("LomakenappiIkonilla", {
                              attrs: {
                                ikoni: "person_add",
                                infoteksti: _vm.$t("createCustomer"),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("avaa-dialog-asiakas")
                                },
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                ],
                null,
                true
              ),
              model: {
                value: _vm.asiakas,
                callback: function ($$v) {
                  _vm.asiakas = $$v
                },
                expression: "asiakas",
              },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }