var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-form", { ref: "laskuriviTable" }, [
        _c("table", { staticClass: "riviTable" }, [
          _c("thead", [
            _c("tr", [
              _c(
                "th",
                {
                  staticClass: "riviTable__cell riviTable__cell--header",
                  attrs: { scope: "col" },
                },
                [_vm._v(" " + _vm._s(_vm.$t("common:code")) + " ")]
              ),
              _c(
                "th",
                {
                  staticClass:
                    "riviTable__cell riviTable__cell--header riviTable__cell--fill riviTable__cell--required",
                  attrs: { scope: "col" },
                },
                [_vm._v(" " + _vm._s(_vm.$t("common:definition")) + " ")]
              ),
              _c(
                "th",
                {
                  class: [..._vm.thClassArr, "riviTable__cell--required"],
                  attrs: { scope: "col" },
                },
                [_vm._v(" " + _vm._s(_vm.$t("common:amount")) + " ")]
              ),
              _c("th", { class: _vm.thClassArr, attrs: { scope: "col" } }, [
                _vm._v(" " + _vm._s(_vm.$t("common:quantityUnit")) + " "),
              ]),
              _c(
                "th",
                {
                  class: [..._vm.thClassArr, "riviTable__cell--required"],
                  attrs: { scope: "col" },
                },
                [_vm._v(" " + _vm._s(_vm.$t("common:uPrice")) + " ")]
              ),
              _c("th", { class: _vm.thClassArr, attrs: { scope: "col" } }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("common:discountPercentShort")) + " "
                ),
              ]),
              _c(
                "th",
                {
                  staticClass:
                    "riviTable__cell riviTable__cell--header text-right",
                  attrs: { scope: "col" },
                },
                [_vm._v(" " + _vm._s(_vm.$t("common:inclVAT")) + " ")]
              ),
              _c(
                "th",
                {
                  class: [
                    ..._vm.thClassArr,
                    _vm.tyyppi === _vm.laskutyypit.SOPIMUS
                      ? "riviTable__cell--required"
                      : "",
                  ],
                  attrs: { scope: "col" },
                },
                [_vm._v(" " + _vm._s(_vm.$t("common:vatId")) + " ")]
              ),
              _c("th", { class: _vm.thClassArr, attrs: { scope: "col" } }, [
                _vm._v(" " + _vm._s(_vm.$t("common:taxFree")) + " "),
              ]),
              _c("th", { class: _vm.thClassArr, attrs: { scope: "col" } }, [
                _vm._v(" " + _vm._s(_vm.$t("common:tax")) + " "),
              ]),
              _c("th", { class: _vm.thClassArr, attrs: { scope: "col" } }, [
                _vm._v(" " + _vm._s(_vm.$t("common:taxable")) + " "),
              ]),
              _c("th", {
                staticClass:
                  "riviTable__cell riviTable__cell--header text-right",
                attrs: { scope: "col" },
              }),
            ]),
          ]),
          _c(
            "tbody",
            _vm._l(_vm.lasku.rivit, function (item, index) {
              return _c(
                "tr",
                {
                  key: item.index,
                  on: { input: _vm.lahetaEventKayttajasyote },
                },
                [
                  _c(
                    "td",
                    {
                      class: [
                        ..._vm.tdClassArr,
                        "riviTable__cell--min-width-medium",
                      ],
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          type: "text",
                          "hide-details": "",
                          counter: item.koodi.length > 255 ? 255 : undefined,
                          rules: [
                            _vm.$validationRules.tooManyCharacters(
                              item.koodi.length,
                              255
                            ),
                          ],
                          disabled: _vm.disabled,
                        },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.lasku.lisaaRivi(index)
                          },
                        },
                        model: {
                          value: item.koodi,
                          callback: function ($$v) {
                            _vm.$set(item, "koodi", $$v)
                          },
                          expression: "item.koodi",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { class: _vm.tdClassArr },
                    [
                      _c("v-text-field", {
                        staticClass: "required",
                        attrs: {
                          type: "text",
                          counter: item.selite.length > 1023 ? 1023 : undefined,
                          rules: [
                            _vm.$validationRules.required,
                            _vm.$validationRules.tooManyCharacters(
                              item.selite.length,
                              1023
                            ),
                          ],
                          required: "",
                          "hide-details": "",
                          disabled: _vm.disabled,
                        },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.lasku.lisaaRivi(index)
                          },
                        },
                        model: {
                          value: item.selite,
                          callback: function ($$v) {
                            _vm.$set(item, "selite", $$v)
                          },
                          expression: "item.selite",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { class: _vm.tdClassArr },
                    [
                      _c("v-text-field", {
                        attrs: {
                          type: "number",
                          rules: [_vm.$validationRules.required],
                          required: "",
                          reverse: "",
                          "hide-details": "",
                          disabled: _vm.disabled,
                        },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.lasku.lisaaRivi(index)
                          },
                        },
                        model: {
                          value: item.maara,
                          callback: function ($$v) {
                            _vm.$set(item, "maara", $$v)
                          },
                          expression: "item.maara",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { class: _vm.tdClassArr },
                    [
                      _c("v-text-field", {
                        attrs: {
                          type: "text",
                          reverse: "",
                          "hide-details": "",
                          disabled: _vm.disabled,
                        },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.lasku.lisaaRivi(index)
                          },
                        },
                        model: {
                          value: item.laatu,
                          callback: function ($$v) {
                            _vm.$set(item, "laatu", $$v)
                          },
                          expression: "item.laatu",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { class: _vm.tdClassArrMinWidth },
                    [
                      _c("v-text-field", {
                        attrs: {
                          type: "number",
                          rules:
                            _vm.tyyppi === _vm.laskutyypit.SOPIMUS
                              ? [
                                  _vm.$validationRules.required,
                                  _vm.$validationRules.positiveUnitPrice,
                                  _vm.$validationRules.maxDecimalPlaces(
                                    item.ahinta,
                                    2
                                  ),
                                ]
                              : [
                                  _vm.$validationRules.required,
                                  _vm.$validationRules.positiveUnitPrice,
                                ],
                          required: "",
                          reverse: "",
                          disabled: _vm.disabled,
                        },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.lasku.lisaaRivi(index)
                          },
                        },
                        model: {
                          value: item.ahinta,
                          callback: function ($$v) {
                            _vm.$set(item, "ahinta", $$v)
                          },
                          expression: "item.ahinta",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { class: _vm.tdClassArr },
                    [
                      _c("v-text-field", {
                        attrs: {
                          type: "number",
                          reverse: "",
                          "hide-details": "",
                          rules: [
                            _vm.$validationRules.zeroOrBigger,
                            _vm.$validationRules.smallerThanOrEqualTo(
                              item.alennusp,
                              100
                            ),
                            _vm.$validationRules.maxDecimalPlaces(
                              item.alennusp,
                              2
                            ),
                          ],
                          disabled: _vm.disabled,
                        },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.lasku.lisaaRivi(index)
                          },
                        },
                        model: {
                          value: item.alennusp,
                          callback: function ($$v) {
                            _vm.$set(item, "alennusp", $$v)
                          },
                          expression: "item.alennusp",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { class: _vm.tdClassArr },
                    [
                      _c("v-checkbox", {
                        staticClass: "riviTable__cell--checkbox",
                        attrs: { "hide-details": "", disabled: _vm.disabled },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.lasku.lisaaRivi(index)
                          },
                        },
                        model: {
                          value: item.sisalv,
                          callback: function ($$v) {
                            _vm.$set(item, "sisalv", $$v)
                          },
                          expression: "item.sisalv",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { class: _vm.tdClassArr },
                    [
                      _c("v-select", {
                        attrs: {
                          "hide-details": "",
                          "item-text": "tunnus",
                          "item-value": "id",
                          items: _vm.ALVTunnusItems,
                          reverse: "",
                          "return-object": "",
                          rules:
                            _vm.tyyppi === _vm.laskutyypit.SOPIMUS
                              ? [_vm.$validationRules.requiredObject]
                              : [],
                          disabled: _vm.disabled,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.paivitaALVPros($event, item)
                          },
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.lasku.lisaaRivi(index)
                          },
                        },
                        model: {
                          value: item.alvtunnus,
                          callback: function ($$v) {
                            _vm.$set(item, "alvtunnus", $$v)
                          },
                          expression: "item.alvtunnus",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { class: _vm.tdClassArr },
                    [
                      _c("v-text-field", {
                        attrs: { type: "text", disabled: "", reverse: "" },
                        model: {
                          value: item.verotonMuotoiltu,
                          callback: function ($$v) {
                            _vm.$set(item, "verotonMuotoiltu", $$v)
                          },
                          expression: "item.verotonMuotoiltu",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { class: _vm.tdClassArr },
                    [
                      _c("v-text-field", {
                        attrs: { type: "text", disabled: "", reverse: "" },
                        model: {
                          value: item.veroMuotoiltu,
                          callback: function ($$v) {
                            _vm.$set(item, "veroMuotoiltu", $$v)
                          },
                          expression: "item.veroMuotoiltu",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { class: _vm.tdClassArr },
                    [
                      _c("v-text-field", {
                        attrs: { type: "text", disabled: "", reverse: "" },
                        model: {
                          value: item.verollinenMuotoiltu,
                          callback: function ($$v) {
                            _vm.$set(item, "verollinenMuotoiltu", $$v)
                          },
                          expression: "item.verollinenMuotoiltu",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { staticClass: "riviTable__cell--icon" },
                    [
                      _vm.salliPoistaminen
                        ? _c("LomakenappiIkonilla", {
                            attrs: {
                              ikoni: "delete",
                              infoteksti: _vm.$t("lasku.deleteRow"),
                              "nayta-aina": "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.poistaRivi(index)
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { staticClass: "riviTable__cell--icon" },
                    [
                      _vm.tuoterekisteri &&
                      _vm.$permission.checkSinglePerm(
                        "onlinerestapi.onlinerestapi_tuoterekisteri"
                      )
                        ? _c("LomakenappiIkonilla", {
                            attrs: {
                              ikoni: "anitta-icon-product-add-filled",
                              infoteksti: _vm.$t("lasku.addToProducts"),
                              "nayta-aina": "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.avaaUudenTuotteenLuominen(item)
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              )
            }),
            0
          ),
          _vm.naytaYhteenveto
            ? _c("tfoot", { staticClass: "riviTable__footer" }, [
                _c("tr", [
                  _c(
                    "td",
                    [
                      _vm.naytaUusiRiviBtn
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.lasku.lisaaRivi()
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { left: "", icon: "" } }, [
                                _vm._v("library_add"),
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("common:lasku.newRow")) +
                                  " "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "th",
                    {
                      staticClass: "riviTable__cell riviTable__cell--footer",
                      attrs: { colspan: "7", scope: "row" },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("common:total")) + " ")]
                  ),
                  _c(
                    "td",
                    { staticClass: "riviTable__cell riviTable__cell--footer" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("formatSumToFixed2")(_vm.lasku.veroton)
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "td",
                    { staticClass: "riviTable__cell riviTable__cell--footer" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("formatSumToFixed2")(_vm.lasku.vero)) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "td",
                    { staticClass: "riviTable__cell riviTable__cell--footer" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("formatSumToFixed2")(_vm.lasku.verollinen)
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("td", {
                    staticClass: "riviTable__cell riviTable__cell--footer",
                  }),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
      _c(
        "CardDialog",
        {
          attrs: {
            title: _vm.$t("product.newProduct"),
            "dialog-auki": _vm.tuoteDialogAuki,
            "title-class": "px-12 py-6",
            "content-class": "px-12 py-4",
            width: "600",
          },
          on: {
            close: function ($event) {
              _vm.tuoteDialogAuki = false
            },
          },
        },
        [
          _c("FormTuote", {
            attrs: {
              "on-auki": _vm.tuoteDialogAuki,
              laskurivi: _vm.laskuriviTuoteLomakkeelle,
            },
            on: {
              uusiTuoteryhma: _vm.avaaTuoteryhmanMuokkaus,
              close: function ($event) {
                _vm.tuoteDialogAuki = false
              },
              tuoteTallennettu: _vm.naytaTuotteenTallennuksenIlmoitus,
            },
          }),
        ],
        1
      ),
      _c(
        "CardDialog",
        {
          attrs: {
            title: _vm.tuoteryhmaTitle,
            "dialog-auki": _vm.tuoteryhmaDialogAuki,
            "title-class": "px-12 py-6",
            "content-class": "px-12 py-4",
            width: "600",
          },
          on: {
            close: function ($event) {
              _vm.tuoteryhmaDialogAuki = false
            },
          },
        },
        [
          _c("FormTuoteryhma", {
            attrs: {
              "on-auki": _vm.tuoteryhmaDialogAuki,
              "muokattava-tuoteryhma": _vm.muokattavaTuoteryhma,
            },
            on: {
              close: function ($event) {
                _vm.tuoteryhmaDialogAuki = false
              },
              tuoteryhmaTallennettu: _vm.kasitteleTuoteryhmanTallennus,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }