var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "formLiite",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _vm._t("default", null, { form: _vm.value }),
      _c("v-file-input", {
        attrs: {
          rules: [_vm.$validationRules.required],
          "error-messages": _vm.validoinninVirheilmoitukset,
        },
        model: {
          value: _vm.value.tiedosto,
          callback: function ($$v) {
            _vm.$set(_vm.value, "tiedosto", $$v)
          },
          expression: "value.tiedosto",
        },
      }),
      _c("PeruutaHyvaksyPainikerivi", {
        attrs: {
          "submit-disabled": _vm.submitDisabloitu,
          "submit-loading": _vm.loading,
        },
        on: {
          close: function ($event) {
            return _vm.$emit("close")
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }