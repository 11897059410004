var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "formTuote",
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            [
              _vm.yleinen.muokattavaTuote.id
                ? _c("v-text-field", {
                    staticClass: "required",
                    attrs: { disabled: true },
                    model: {
                      value: _vm.yleinen.muokattavaTuote.paamies.nimi,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.yleinen.muokattavaTuote.paamies,
                          "nimi",
                          $$v
                        )
                      },
                      expression: "yleinen.muokattavaTuote.paamies.nimi",
                    },
                  })
                : !_vm.$store.state.user.vainYksiPaamies
                ? _c("AutocompletePaamies", {
                    ref: "autocompletePaamies",
                    attrs: {
                      label: _vm.$t("common:creditor"),
                      "tarkistettava-oikeus":
                        "onlinerestapi.onlinerestapi_tuoterekisteri",
                    },
                    on: { "autocomplete-input": _vm.tarkistaKoodi },
                    model: {
                      value: _vm.yleinen.muokattavaTuote.paamies,
                      callback: function ($$v) {
                        _vm.$set(_vm.yleinen.muokattavaTuote, "paamies", $$v)
                      },
                      expression: "yleinen.muokattavaTuote.paamies",
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                [
                  _c("v-text-field", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false",
                      },
                    ],
                    staticClass: "required",
                    attrs: { rules: [_vm.$validationRules.required] },
                    model: {
                      value: _vm.yleinen.muokattavaTuote.tuoteryhma.nimi,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.yleinen.muokattavaTuote.tuoteryhma,
                          "nimi",
                          $$v
                        )
                      },
                      expression: "yleinen.muokattavaTuote.tuoteryhma.nimi",
                    },
                  }),
                  _vm.yleinen.muokattavaTuote.tuoteryhma.id
                    ? _c("v-text-field", {
                        staticClass: "required",
                        attrs: {
                          label: _vm.$t(
                            "common:productGroup.productGroupHeader"
                          ),
                          clearable: "",
                          readonly: true,
                          rules: [_vm.$validationRules.required],
                        },
                        on: { input: _vm.tyhjennaTuoteryhma },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "append",
                              fn: function () {
                                return [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          color: "primary",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.uusiTuoteryhma,
                                                        },
                                                      },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [_vm._v(" add ")]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3888704390
                                      ),
                                    },
                                    [_c("span", [_vm._v("Lisää tuoteryhmä")])]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2972175452
                        ),
                        model: {
                          value: _vm.yleinen.muokattavaTuote.tuoteryhma.nimi,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.yleinen.muokattavaTuote.tuoteryhma,
                              "nimi",
                              $$v
                            )
                          },
                          expression: "yleinen.muokattavaTuote.tuoteryhma.nimi",
                        },
                      })
                    : _c("AutocompleteTuoteryhma", {
                        ref: "autocompleteTuoteryhma",
                        attrs: {
                          reference: "autocompleteTuoteryhma",
                          tuote: _vm.yleinen.muokattavaTuote,
                          "append-icon": "add",
                          rules: [_vm.$validationRules.required],
                          required: "required",
                        },
                        on: { uusiTuoteryhma: _vm.uusiTuoteryhma },
                      }),
                ],
                1
              ),
              _c("v-text-field", {
                staticClass: "required",
                attrs: {
                  label: _vm.$t("common:code"),
                  rules: [_vm.$validationRules.required],
                  "error-messages": _vm.koodiErrors,
                },
                on: { input: _vm.tarkistaKoodiDebounce },
                model: {
                  value: _vm.yleinen.muokattavaTuote.koodi,
                  callback: function ($$v) {
                    _vm.$set(_vm.yleinen.muokattavaTuote, "koodi", $$v)
                  },
                  expression: "yleinen.muokattavaTuote.koodi",
                },
              }),
              _c("v-text-field", {
                attrs: { label: _vm.$t("common:definition") },
                model: {
                  value: _vm.yleinen.muokattavaTuote.selite,
                  callback: function ($$v) {
                    _vm.$set(_vm.yleinen.muokattavaTuote, "selite", $$v)
                  },
                  expression: "yleinen.muokattavaTuote.selite",
                },
              }),
              _c("v-text-field", {
                attrs: { label: _vm.$t("common:freeText") },
                model: {
                  value: _vm.yleinen.muokattavaTuote.vapaateksti,
                  callback: function ($$v) {
                    _vm.$set(_vm.yleinen.muokattavaTuote, "vapaateksti", $$v)
                  },
                  expression: "yleinen.muokattavaTuote.vapaateksti",
                },
              }),
              _c("div", { staticClass: "py-4" }),
              _c("v-text-field", {
                staticClass: "required",
                attrs: {
                  label: _vm.$t("common:unitPrice"),
                  type: "number",
                  rules: [
                    _vm.$validationRules.required,
                    _vm.$validationRules.maxDecimalPlaces(
                      _vm.yleinen.muokattavaTuote.ahinta,
                      2
                    ),
                  ],
                },
                model: {
                  value: _vm.yleinen.muokattavaTuote.ahinta,
                  callback: function ($$v) {
                    _vm.$set(_vm.yleinen.muokattavaTuote, "ahinta", $$v)
                  },
                  expression: "yleinen.muokattavaTuote.ahinta",
                },
              }),
              _c("v-switch", {
                staticClass: "ml-4 mt-0",
                attrs: { label: _vm.$t("common:general.ifPriceIncludesVat") },
                model: {
                  value: _vm.yleinen.muokattavaTuote.sisalv,
                  callback: function ($$v) {
                    _vm.$set(_vm.yleinen.muokattavaTuote, "sisalv", $$v)
                  },
                  expression: "yleinen.muokattavaTuote.sisalv",
                },
              }),
              _c("v-text-field", {
                attrs: { label: _vm.$t("common:quantityUnit") },
                model: {
                  value: _vm.yleinen.muokattavaTuote.laatu,
                  callback: function ($$v) {
                    _vm.$set(_vm.yleinen.muokattavaTuote, "laatu", $$v)
                  },
                  expression: "yleinen.muokattavaTuote.laatu",
                },
              }),
              _c("v-autocomplete", {
                ref: "refAlvtunnusId",
                staticClass: "required",
                attrs: {
                  type: "text",
                  items: _vm.yleinen.ALVTunnusItems,
                  label: _vm.$t("common:vatId"),
                  "item-text": "tunnus",
                  "item-value": "id",
                  rules: [_vm.$validationRules.required],
                },
                model: {
                  value: _vm.yleinen.muokattavaTuote.alvtunnus_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.yleinen.muokattavaTuote, "alvtunnus_id", $$v)
                  },
                  expression: "yleinen.muokattavaTuote.alvtunnus_id",
                },
              }),
              _c("v-text-field", {
                attrs: {
                  type: "number",
                  label: _vm.$t("common:purchasePrice"),
                  rules: [
                    _vm.$validationRules.maxDecimalPlaces(
                      _vm.yleinen.muokattavaTuote.hankintahinta,
                      2
                    ),
                  ],
                },
                model: {
                  value: _vm.yleinen.muokattavaTuote.hankintahinta,
                  callback: function ($$v) {
                    _vm.$set(_vm.yleinen.muokattavaTuote, "hankintahinta", $$v)
                  },
                  expression: "yleinen.muokattavaTuote.hankintahinta",
                },
              }),
              _c("v-text-field", {
                attrs: {
                  label: _vm.$t("common:stockStatus"),
                  type: "text",
                  rules: [_vm.$validationRules.numberOrEmpty],
                },
                model: {
                  value: _vm.yleinen.muokattavaTuote.varastotilanne,
                  callback: function ($$v) {
                    _vm.$set(_vm.yleinen.muokattavaTuote, "varastotilanne", $$v)
                  },
                  expression: "yleinen.muokattavaTuote.varastotilanne",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-4 pa-3", attrs: { justify: "end" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "mr-4",
              attrs: { large: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("common:cancel")) + " ")]
          ),
          _c(
            "v-btn",
            {
              attrs: {
                large: "",
                color: "action",
                disabled: !_vm.valid || _vm.loading,
              },
              on: { click: _vm.tallenna },
            },
            [_vm._v(" " + _vm._s(_vm.$t("common:save")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }