var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.logoUrl
    ? _c(
        "div",
        { staticClass: "logo" },
        [
          _vm.enableLink
            ? _c(
                "router-link",
                {
                  staticClass: "logo__wrap",
                  attrs: { to: { name: "tyopoyta" } },
                },
                [
                  _c("img", {
                    staticClass: "logo__img",
                    style: _vm.maxWidth ? { maxWidth: _vm.maxWidth } : {},
                    attrs: { src: _vm.logoUrl, alt: "Logo" },
                  }),
                ]
              )
            : _c("div", { staticClass: "logo__wrap" }, [
                _c("img", {
                  staticClass: "logo__img",
                  style: _vm.maxWidth ? { maxWidth: _vm.maxWidth } : {},
                  attrs: { src: _vm.logoUrl, alt: "Logo" },
                }),
              ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }