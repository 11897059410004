import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
// eslint-disable-next-line
import {
  getLanguageLocale,
  getLanguage,
  getFlexmonsterKaannokset,
} from "./utils/lang";

import "./plugins/api";
import "./plugins/apiUlkoinen";
import "./plugins/permission";
import "./plugins/validationRules";
import "./plugins/sweetalert";
import "./plugins/toastification";
import router from "./utils/router";
import store from "./store/";
import Dinero from "dinero.js";
import "./utils/filters/formatSum";
import formatSum from "@/utils/filters/formatSum";
import "./utils/filters/formatSumToFixed2";
import formatSumToFixed2 from "@/utils/filters/formatSumToFixed2";
import "./utils/filters/cleanLineBreaks";
import * as Sentry from "@sentry/vue";
import VueSocketIOExt from "vue-socket.io-extended";
import { io } from "socket.io-client";
import {
  AuthenticationError,
  HttpError,
  PasswordResetExpiredError,
  SallittuKokoYlitettyError,
  ValidointiError,
} from "@/utils/errors";
import {
  doRequestWithTimeout,
  muotoileDesimaaliluku,
  naytaDjangonVirheet,
  palautaDjangonVirheet,
  naytaInfoilmoitus,
  naytaOnnistumisilmoitus,
  naytaTiedostonlataamisdialog,
  naytaVaroitusilmoitus,
  naytaVirheilmoitus,
  oletusVirheenkasittely,
  onkoUiVirhe,
  rivitaEmail,
  sentryCaptureCustom,
  dateFnsFormat,
} from "@/utils/misc";
// Global components
import CardDialog from "@/components/CardDialog";
import CardDialogModal from "@/components/CardDialogModal";
import CardDialogNotModal from "@/components/CardDialogNotModal";
import CardPahoittelu from "@/components/CardPahoittelu";
import CardTitle from "@/components/CardTitle";
import CardVaroitus from "@/components/CardVaroitus";
import DataTableLaskuliite from "@/components/DataTableLaskuliite";
import DefList from "@/components/DefList";
import DefListItem from "@/components/DefListItem";
import DefListTooltipItem from "@/components/DefListTooltipItem";
import DenseToggleButton from "@/components/DenseToggleButton";
import InlineList from "@/components/InlineList";
import InlineListItem from "@/components/InlineListItem";
import MenuContext from "@/components/MenuContext";
import PerustietoTooltip from "@/components/PerustietoTooltip";
import PeruutaHyvaksyPainikerivi from "@/components/PeruutaHyvaksyPainikerivi";
import RouterLinkEhdollinen from "@/components/RouterLinkEhdollinen";
import Spinner from "@/components/Spinner";
import StatusIcon from "@/components/StatusIcon";
import TableRow from "@/components/TableRow";
import TooltipInfo from "@/components/TooltipInfo";
import TransitionSlidePanel from "@/components/TransitionSlidePanel";
import ViewTopBarBase from "@/components/ViewTopBarBase";

Vue.component("CardDialog", CardDialog);
Vue.component("CardDialogModal", CardDialogModal);
Vue.component("CardDialogNotModal", CardDialogNotModal);
Vue.component("CardPahoittelu", CardPahoittelu);
Vue.component("CardTitle", CardTitle);
Vue.component("CardVaroitus", CardVaroitus);
Vue.component("DataTableLaskuliite", DataTableLaskuliite);
Vue.component("DefList", DefList);
Vue.component("DefListItem", DefListItem);
Vue.component("DefListTooltipItem", DefListTooltipItem);
Vue.component("DenseToggleButton", DenseToggleButton);
Vue.component("InlineList", InlineList);
Vue.component("InlineListItem", InlineListItem);
Vue.component("MenuContext", MenuContext);
Vue.component("PeruutaHyvaksyPainikerivi", PeruutaHyvaksyPainikerivi);
Vue.component("PerustietoTooltip", PerustietoTooltip);
Vue.component("RouterLinkEhdollinen", RouterLinkEhdollinen);
Vue.component("Spinner", Spinner);
Vue.component("StatusIcon", StatusIcon);
Vue.component("TableRow", TableRow);
Vue.component("TooltipInfo", TooltipInfo);
Vue.component("TransitionSlidePanel", TransitionSlidePanel);
Vue.component("ViewTopBarBase", ViewTopBarBase);

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_ENV,
    // Raportoidaan 100% virheistä
    sampleRate: 1.0,
    attachStacktrace: true,
    attachProps: true,
    logErrors: true,
  });
}

// Globaalit poikkeuskäsittelijät
Vue.config.errorHandler = function (error, vm, info) {
  console.log("main.js: Vue.config.errorHandler");
  const tags = {
    vueInfo: info,
    vueComponent: vm ? vm.$vnode.tag : null,
    handler: "Vue.config.errorHandler",
  };
  sentryCaptureCustom(error, {}, tags);
};

window.onerror = function (msg, url, line, col, error) {
  console.log("main.js: window.onerror");
  const tags = {
    handler: "window.onerror",
  };
  sentryCaptureCustom(error, {}, tags);
};

window.onunhandledrejection = function (event) {
  console.log("main.js: unhandledrejection");
  console.log("event", event);
  const tags = {
    handler: "unhandledrejection",
  };

  sentryCaptureCustom(event.reason, {}, tags);
  event.preventDefault();
};

// Kustomoidut poikkeukset
Vue.prototype.$AuthenticationError = AuthenticationError;
Vue.prototype.$HttpError = HttpError;
Vue.prototype.$PasswordResetExpiredError = PasswordResetExpiredError;
Vue.prototype.$SallittuKokoYlitettyError = SallittuKokoYlitettyError;
Vue.prototype.$ValidointiError = ValidointiError;

// Globaalit funktiot
Vue.prototype.$dateFnsFormat = dateFnsFormat;
Vue.prototype.$doRequestWithTimeout = doRequestWithTimeout;
Vue.prototype.$formatSum = formatSum;
Vue.prototype.$formatSumToFixed2 = formatSumToFixed2;
Vue.prototype.$muotoileDesimaaliluku = muotoileDesimaaliluku;
Vue.prototype.$naytaInfoilmoitus = naytaInfoilmoitus;
Vue.prototype.$naytaOnnistumisilmoitus = naytaOnnistumisilmoitus;
Vue.prototype.$naytaTiedostonlataamisdialog = naytaTiedostonlataamisdialog;
Vue.prototype.$naytaVaroitusilmoitus = naytaVaroitusilmoitus;
Vue.prototype.$naytaVirheilmoitus = naytaVirheilmoitus;
Vue.prototype.$rivitaEmail = rivitaEmail;
Vue.prototype.$oletusVirheenkasittely = oletusVirheenkasittely;
Vue.prototype.$onkoUiVirhe = onkoUiVirhe;
Vue.prototype.$sentry = Sentry;
Vue.prototype.$sentryCaptureCustom = sentryCaptureCustom;
Vue.prototype.$naytaDjangonVirheet = naytaDjangonVirheet;
Vue.prototype.$palautaDjangonVirheet = palautaDjangonVirheet;
Vue.prototype.$languageLocale = getLanguageLocale;
Vue.prototype.$language = getLanguage;
Vue.prototype.$flexmonsterKaannokset = getFlexmonsterKaannokset;

// Globaalit muuttujat
Vue.prototype.$sovellusIdOtsake = `${process.env.VUE_APP_INSTANCE_TITLE}-ID`;

// Socket.io
const VUE_APP_SOCKETIO_URL = process.env.VUE_APP_SOCKETIO_URL;
if (VUE_APP_SOCKETIO_URL) {
  const socket = io(VUE_APP_SOCKETIO_URL);
  socket.onerror((error) => {
    console.log("socket error", error);
    sentryCaptureCustom(error, {}, { handler: "socket.onerror" });
  });
  Vue.use(VueSocketIOExt, socket, { store });
}

// Dinero config
Dinero.globalLocale = "fi-FI";
Dinero.defaultCurrency = "EUR";

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

Vue.config.devtools = process.env.VUE_APP_ENV === "development" ? true : false;
