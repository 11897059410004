<template>
  <v-form v-model="valid" @submit.prevent="submit">
    <v-text-field
      v-model="value.selite"
      class="required"
      type="text"
      label="Selite"
      placeholder="Anna mitätöinnin peruste"
      persistent-placeholder
      :rules="[$validationRules.required]"
    />
    <v-checkbox
      v-if="lasku.sopimus"
      v-model="value.poista_sopimukselta"
      class="mx-2"
      label="Poista lasku sopimukselta"
    ></v-checkbox>
    <PeruutaHyvaksyPainikerivi
      :submit-disabled="!valid || loading"
      :submit-loading="loading"
      submit-teksti="Mitätöi"
      @close="$emit('close')"
    />
  </v-form>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";

export default {
  name: "FormLaskuMitatoi",
  mixins: [FormMixin],
  props: {
    lasku: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
