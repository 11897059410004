var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefList",
    { attrs: { bordered: true } },
    [
      _c("DefListItem", {
        scopedSlots: _vm._u(
          [
            {
              key: "label",
              fn: function () {
                return [_vm._v("Tila")]
              },
              proxy: true,
            },
            _vm.value.tila && _vm.value.ikoni_ja_vari
              ? {
                  key: "value",
                  fn: function () {
                    return [
                      _c("StatusText", {
                        attrs: {
                          text: _vm.value.tila.selite,
                          color: _vm.value.ikoni_ja_vari.vari,
                        },
                      }),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _c("DefListItem", {
        scopedSlots: _vm._u(
          [
            {
              key: "label",
              fn: function () {
                return [_vm._v("Laskunumero")]
              },
              proxy: true,
            },
            _vm.value.nro
              ? {
                  key: "value",
                  fn: function () {
                    return [_vm._v(" " + _vm._s(_vm.value.nro) + " ")]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _c("DefListItem", {
        scopedSlots: _vm._u(
          [
            {
              key: "label",
              fn: function () {
                return [_vm._v("Asiakas")]
              },
              proxy: true,
            },
            _vm.value.asiakas
              ? {
                  key: "value",
                  fn: function () {
                    return [
                      _vm.eiOikeuttaNahdaAsiakastaValue(_vm.value)
                        ? [
                            _c("span", { staticClass: "font-italic" }, [
                              _vm._v("Ei voida näyttää tietosuojasyistä"),
                            ]),
                          ]
                        : [
                            _vm._v(" " + _vm._s(_vm.value.asiakas.nimi) + " "),
                            _vm.value.asiakas.nro
                              ? [
                                  _vm._v(
                                    " (" + _vm._s(_vm.value.asiakas.nro) + ") "
                                  ),
                                ]
                              : _vm._e(),
                          ],
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _c("DefListItem", {
        scopedSlots: _vm._u(
          [
            {
              key: "label",
              fn: function () {
                return [_vm._v("Päämies")]
              },
              proxy: true,
            },
            _vm.value.paamies
              ? {
                  key: "value",
                  fn: function () {
                    return [
                      _vm._v(" " + _vm._s(_vm.value.paamies.nimi) + " "),
                      _vm.value.paamies.id
                        ? [_vm._v("(" + _vm._s(_vm.value.paamies.id) + ")")]
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _c("DefListItem", {
        scopedSlots: _vm._u(
          [
            {
              key: "label",
              fn: function () {
                return [_vm._v("Laskupäivä")]
              },
              proxy: true,
            },
            _vm.value.laskupaiva
              ? {
                  key: "value",
                  fn: function () {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$dateFnsFormat(_vm.value.laskupaiva)) +
                          " "
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _c("DefListItem", {
        scopedSlots: _vm._u(
          [
            {
              key: "label",
              fn: function () {
                return [_vm._v("Viite")]
              },
              proxy: true,
            },
            _vm.value.viite
              ? {
                  key: "value",
                  fn: function () {
                    return [_vm._v(" " + _vm._s(_vm.value.viite) + " ")]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _vm.value.pm_viite
        ? _c("DefListItem", {
            scopedSlots: _vm._u(
              [
                {
                  key: "label",
                  fn: function () {
                    return [_vm._v("PM-viite")]
                  },
                  proxy: true,
                },
                {
                  key: "value",
                  fn: function () {
                    return [_vm._v(" " + _vm._s(_vm.value.pm_viite) + " ")]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2776804894
            ),
          })
        : _vm._e(),
      _vm.value.viitteemme
        ? _c("DefListItem", {
            scopedSlots: _vm._u(
              [
                {
                  key: "label",
                  fn: function () {
                    return [_vm._v("Viitteemme")]
                  },
                  proxy: true,
                },
                {
                  key: "value",
                  fn: function () {
                    return [_vm._v(" " + _vm._s(_vm.value.viitteemme) + " ")]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              748416908
            ),
          })
        : _vm._e(),
      _vm.value.viitteenne
        ? _c("DefListItem", {
            scopedSlots: _vm._u(
              [
                {
                  key: "label",
                  fn: function () {
                    return [_vm._v("Viitteenne")]
                  },
                  proxy: true,
                },
                {
                  key: "value",
                  fn: function () {
                    return [_vm._v(" " + _vm._s(_vm.value.viitteenne) + " ")]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              4022082636
            ),
          })
        : _vm._e(),
      _c("DefListItem", {
        scopedSlots: _vm._u(
          [
            {
              key: "label",
              fn: function () {
                return [_vm._v("Eräpäivä")]
              },
              proxy: true,
            },
            _vm.value.erapaiva
              ? {
                  key: "value",
                  fn: function () {
                    return [
                      _vm.value.onko_myohassa
                        ? _c("strong", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$dateFnsFormat(_vm.value.erapaiva)) +
                                " (myöhässä) "
                            ),
                          ])
                        : [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$dateFnsFormat(_vm.value.erapaiva)) +
                                " "
                            ),
                          ],
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _c("DefListItem", {
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [_vm._v("Summa")]
            },
            proxy: true,
          },
          {
            key: "value",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("formatSumToFixed2")(_vm.value.summa)) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("DefListItem", {
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [_vm._v("Veroton")]
            },
            proxy: true,
          },
          {
            key: "value",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("formatSumToFixed2")(_vm.value.veroton)) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("DefListItem", {
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [_vm._v("Vero")]
            },
            proxy: true,
          },
          {
            key: "value",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("formatSumToFixed2")(_vm.value.vero)) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("DefListItem", {
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [_vm._v("Avoinna")]
            },
            proxy: true,
          },
          {
            key: "value",
            fn: function () {
              return [
                _c("strong", [
                  _vm._v(
                    _vm._s(_vm._f("formatSumToFixed2")(_vm.value.avoinna))
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }