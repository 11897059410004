import { Lasku } from "@/class/Lasku";
import { t } from "./lang";
import { onkoModuuliKaytossa } from "@/utils/misc";

export const TabsLaskutus = [
  {
    id: "kaikki",
    label: t("tabs.allInvoices"),
    terms: [],
  },
  {
    id: "avoinna",
    label: t("open"),
    terms: [
      {
        key: "tila",
        value: Lasku.TILA_AVOINNA,
      },
    ],
  },
  {
    id: "myohassa",
    label: t("tabs.late"),
    terms: [
      {
        key: "myohassa",
        value: Lasku.TILA_MYOHASSA,
      },
    ],
  },
  {
    id: "perinnassa",
    label: t("inCollection"),
    terms: [
      {
        key: "tila",
        value: Lasku.TILA_PERINNASSA,
      },
    ],
  },
  {
    id: "maksettu",
    label: t("paid"),
    terms: [
      {
        key: "tila",
        value: Lasku.TILA_MAKSETTU,
      },
      {
        key: "tila",
        value: Lasku.TILA_MYYTY,
      },
    ],
  },
  ...(onkoModuuliKaytossa("rahoitus")
    ? [
        {
          id: "rahoitetut",
          label: t("tabs.funded"),
          terms: [
            {
              key: "rahoitus",
              value: true,
            },
          ],
        },
      ]
    : []),
  {
    id: "luonnokset",
    label: t("tabs.drafts"),
    terms: [
      {
        key: "tila",
        value: Lasku.TILA_LUONNOS,
      },
    ],
  },
  {
    id: "jonossa",
    label: t("tabs.inSendQueue"),
    terms: [
      {
        key: "tila",
        value: Lasku.TILA_LAHETTAMATTA,
      },
      {
        key: "tila",
        value: Lasku.TILA_KUITTAUKSESSA,
      },
    ],
  },
  {
    id: "kasittelyssa",
    label: t("tabs.processing"),
    terms: [
      {
        key: "tila",
        value: Lasku.TILA_KESKEYTETTY,
      },
    ],
  },
  {
    id: "peruttu",
    label: t("canceled"),
    terms: [
      {
        key: "tila",
        value: Lasku.TILA_PERUTTU,
      },
    ],
  },
];

export const TabsOstolasku = [
  {
    id: "kaikki",
    label: t("tabs.allInvoices"),
    terms: [],
  },
  {
    id: "avoinna",
    label: t("open"),
    terms: [
      {
        key: "tila",
        value: Lasku.TILA_AVOINNA,
      },
    ],
  },
  {
    id: "myohassa",
    label: t("tabs.late"),
    terms: [
      {
        key: "myohassa",
        value: Lasku.TILA_MYOHASSA,
      },
    ],
  },
  {
    id: "hyvaksyttyMaksuun",
    label: t("acceptedForPayment"),
    terms: [
      {
        key: "tila",
        value: Lasku.TILA_HYVAKSYTTY_MAKSUUN,
      },
    ],
  },
  {
    id: "maksettu",
    label: t("paid"),
    terms: [
      {
        key: "tila",
        value: Lasku.TILA_MAKSETTU,
      },
    ],
  },
  {
    id: "peruttu",
    label: t("canceled"),
    terms: [
      {
        key: "tila",
        value: Lasku.TILA_PERUTTU,
      },
    ],
  },
];

export const TabsAsiakas = [
  {
    id: "kaikki",
    label: t("tabs.allCustomers"),
    terms: [],
  },
  {
    id: "yritykset",
    label: t("tabs.companies"),
    terms: [
      {
        key: "tyyppi",
        value: "Y",
      },
    ],
  },
  {
    id: "henkilot",
    label: t("tabs.persons"),
    terms: [
      {
        key: "tyyppi",
        value: "H",
      },
    ],
  },
];

export const TabsPerinta = [
  {
    id: "kaikki",
    label: t("tabs.allAssignments"),
    terms: [],
  },
  {
    id: "perinnassa",
    label: t("inCollection"),
    terms: [
      {
        key: "tila",
        value: "VP",
      },
    ],
  },
  {
    id: "oikeudellisessa_perinnassa",
    label: t("inLegalRecovery"),
    terms: [
      {
        key: "tila",
        value: "OP",
      },
    ],
  },
  {
    id: "jalkiperinnassa",
    label: t("tabs.inRecovery"),
    terms: [
      {
        key: "tyyppi",
        value: "J",
      },
      {
        key: "tila",
        value: "VP",
      },
      {
        key: "tila",
        value: "OP",
      },
      {
        key: "tila",
        value: "UO",
      },
    ],
  },
  {
    id: "maksettu",
    label: t("paid"),
    terms: [
      {
        key: "tila",
        value: "LO",
      },
      {
        key: "tila",
        value: "MY",
      },
    ],
  },
  {
    id: "peruttu",
    label: t("canceled"),
    terms: [
      {
        key: "tila",
        value: "XX",
      },
      {
        key: "tila",
        value: "PE",
      },
    ],
  },
  {
    id: "kasittelyssa",
    label: t("tabs.processing"),
    terms: [
      {
        key: "vaihe",
        value: "KESKEYTYS_MUUSYY",
      },
      {
        key: "tila",
        value: "VP",
      },
      {
        key: "tila",
        value: "OP",
      },
      {
        key: "tila",
        value: "UO",
      },
    ],
  },
  {
    id: "ulosotossa",
    label: t("inExecution"),
    terms: [
      {
        key: "tila",
        value: "UO",
      },
    ],
  },
];

export const TabsViestit = [
  {
    id: "saapuneet",
    label: t("tabs.arrived"),
    terms: [
      {
        key: "lahettaja__is_staff",
        value: true,
      },
    ],
  },
  {
    id: "kasittelemattomat",
    label: t("tabs.unProcessed"),
    terms: [
      {
        key: "lukemattomat",
        value: 1,
      },
    ],
  },
  {
    id: "kasitellyt",
    label: t("tabs.processed"),
    terms: [
      {
        key: "luetut",
        value: 1,
      },
    ],
  },
  {
    id: "lahetetyt",
    label: t("tabs.sent"),
    terms: [
      {
        key: "lahettaja__is_staff",
        value: false,
      },
    ],
  },
];

export const TabsTuoterekisteri = [
  {
    id: "kaikki",
    label: t("tabs.allProducts"),
    terms: [],
  },
];

export const TabsSopimus = [
  {
    id: "kaikki",
    label: t("all"),
    terms: [],
  },
  {
    id: "aktiivinen",
    label: t("active2"),
    terms: [
      {
        key: "tila",
        value: "A",
      },
    ],
  },
  {
    id: "myohassa",
    label: t("tabs.late"),
    terms: [
      {
        key: "tila",
        value: "A",
      },
      {
        key: "myohassa",
        value: "1",
      },
    ],
  },
  {
    id: "perinnassa",
    label: t("inCollection"),
    terms: [
      {
        key: "tila",
        value: "A",
      },
      {
        key: "perinnassa",
        value: "1",
      },
    ],
  },
  {
    id: "maksettu",
    label: t("paid"),
    terms: [
      {
        key: "tila",
        value: "M",
      },
      {
        key: "tila",
        value: "P",
      },
    ],
  },
  {
    id: "eraannytetty",
    label: t("selectables.overdue"),
    terms: [
      {
        key: "tila",
        value: "E",
      },
    ],
  },
];
