<template>
  <div class="stepperFooter px-4">
    <div class="stepperFooter__col stepperFooter__col--left pa-2">
      <slot name="left" />
    </div>
    <div class="stepperFooter__col stepperFooter__col--center pa-2">
      <slot name="center" />
    </div>
    <div class="stepperFooter__col stepperFooter__col--right pa-2">
      <slot name="right" />
    </div>
    <Slot></Slot>
  </div>
</template>

<script>
export default {
  name: "StepperFooter",
};
</script>

<style lang="scss" scoped>
.stepperFooter {
  position: sticky;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  bottom: 0;
  background: #fff;
  border-top: solid 1px lighten(#000, 85);

  &__col {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    &--center {
      flex: 1 1 auto;
    }
  }
}
</style>
