var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.maintenanceMessage
    ? _c(
        "v-snackbar",
        {
          staticClass: "maintenance",
          attrs: {
            timeout: -1,
            absolute: "",
            top: "",
            color: "grey",
            elevation: "24",
          },
          scopedSlots: _vm._u(
            [
              {
                key: "action",
                fn: function ({ attrs }) {
                  return [
                    _c(
                      "v-btn",
                      _vm._b(
                        {
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              _vm.snackbar = false
                            },
                          },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      [_vm._v("Close")]
                    ),
                  ]
                },
              },
            ],
            null,
            false,
            974461977
          ),
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [_c("div", [_vm._v(_vm._s(_vm.maintenanceMessage))])]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }