// import api from "@/plugins/api";
// import { HttpError } from "@/utils/errors";
// import { TYPE as TOAST_TYPE } from "vue-toastification";
import { sentryCaptureCustom } from "@/utils/misc";
import { IlmoitusSisaltoRaporttiValmis } from "@/components";

export default {
  namespaced: true,
  state: {
    clientId: null,
  },
  getters: {},
  mutations: {
    SOCKET_REKISTEROI_CLIENT(state, payload) {
      console.log("rekisteroi client", payload);
      state.clientId = payload;
    },
  },
  actions: {
    socket_ilmoitaValmistuneestaRaportista({ commit }, data) {
      commit(
        "raportitUusi/setLuotuRaportti",
        {
          id: data.raporttiId,
          nimi: data.nimi,
          luotu: data.luotu,
          valmis: true,
        },
        {
          root: true,
        }
      );

      commit(
        "notifications/naytaIlmoitus",
        {
          content: {
            component: IlmoitusSisaltoRaporttiValmis,
            props: data,
          },
        },
        {
          root: true,
        }
      );
    },
    async socket_socketServerPoikkeus({ commit }, error) {
      try {
        console.log("error :>> ", error);
        sentryCaptureCustom(error);
      } catch (e) {
        sentryCaptureCustom(e);
      }
    },
  },
};
