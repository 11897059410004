import { suodataTyhjanMerkkijononSisaltavatKentat } from "@/utils/misc";

export const Velallinen = class {
  constructor(preset = {}) {
    this.id = null;
    this.emailosoite = new VelallinenEmail();
    this.etunimi = ""; // vain henkilövelalliset
    this.kieli = "fi-fi";
    this.nimi = "";
    this.paamies_id = null;
    this.postiosoite = new VelallinenPostiosoite();
    this.puhelin = new VelallinenPuhelin();
    this.sukunimi = ""; // vain henkilövelalliset
    this.tunnus = "";
    this.tyyppi = "";
    this._syntymapaiva = null; // vain henkilövelalliset

    Object.assign(this, preset);

    this.emailosoite = new VelallinenEmail(preset.emailosoite);
    this.postiosoite = new VelallinenPostiosoite(preset.postiosoite);
    this.puhelin = new VelallinenPuhelin(preset.puhelin);
  }

  set syntymapaiva(pvm) {
    if (!pvm) {
      this._syntymapaiva = null;
    } else if (!(pvm instanceof Date)) {
      throw new Error("Syntymäpäivän tulee olla Date-objekti");
    }

    this._syntymapaiva = pvm;
  }

  get syntymapaiva() {
    return this._syntymapaiva;
  }

  getPostData() {
    const postData = JSON.parse(JSON.stringify(this));

    delete postData.id;
    delete postData.etunimi;
    delete postData.sukunimi;

    if (this.tyyppi === "H") {
      postData.nimi = this.sukunimi.trim() + " " + this.etunimi.trim();
    }

    // Lähetetään data ToimeksiantoRajapinnan vaatimassa muodossa
    postData.velallinenpostiosoite_set = [this.postiosoite.getPostData()];
    delete postData.postiosoite;

    if (this.emailosoite.email) {
      postData.velallinenemail_set = [this.emailosoite.getPostData()];
    }
    delete postData.emailosoite;

    if (this.puhelin.numero) {
      postData.velallinenpuhelin_set = [this.puhelin.getPostData()];
    }
    delete postData.puhelin;

    if (this.tyyppi === "H" && this.syntymapaiva) {
      postData.syntymapaiva = this.syntymapaiva.toISOString().slice(0, 10);
    } else {
      delete postData.syntymapaiva;
    }
    delete postData._syntymapaiva;

    return postData;
  }
};

export const VelallinenOsoite = class {
  constructor(preset = {}) {
    this.id = null;
    this.velallinen_id = null;

    Object.assign(this, preset);
  }

  getPostData(ilmanVelallinenId = true) {
    const postData = this;
    if (ilmanVelallinenId) delete postData.velallinen_id;
    return suodataTyhjanMerkkijononSisaltavatKentat(this);
  }
};

export const VelallinenPostiosoite = class extends VelallinenOsoite {
  constructor(preset = {}) {
    super({});
    this.maa = "FI";
    this.nimi = "";
    this.numero = "";
    this.osoite = "";
    this.toimipaikka = "";
    this.vastaanottaja = "";
    this.yhtiomuoto = "";

    Object.assign(this, preset);
  }
};

export const VelallinenEmail = class extends VelallinenOsoite {
  constructor(preset = {}) {
    super({});
    this.email = "";

    Object.assign(this, preset);
  }
};

export const VelallinenPuhelin = class extends VelallinenOsoite {
  constructor(preset = {}) {
    super({});
    this.numero = "";

    Object.assign(this, preset);
  }
};
