import Vue from "vue";
import Vuetify from "vuetify/lib";
import { getTheme } from "@/utils/color-themes";
import en from "@/locales/vuetify/en";
import fi from "@/locales/vuetify/fi";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "md",
  },
  theme: {
    themes: {
      light: getTheme(process.env.VUE_APP_CLASS),
    },
    options: {
      customProperties: true,
    },
  },
  lang: {
    locales: { fi, en },
    current: "fi",
  },
});
