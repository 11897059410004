<template>
  <v-icon
    v-if="miniVariant"
    class="ml-n2 my-5"
    color="error"
    large
    @click="lopetaTekeytyminen"
  >
    mdi-account-multiple-minus-outline
  </v-icon>
  <v-card v-else class="mb-3" color="warning">
    <v-card-text class="font-weight-bold">
      Tekeytyminen käynnissä käyttäjälle {{ kayttajanimi }}.
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn class="black white--text" small @click="lopetaTekeytyminen">
        Lopeta tekeytyminen
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "TekeytyminenAlert",
  props: {
    miniVariant: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    kayttajanimi() {
      return this.$store.state.user.username;
    },
  },
  methods: {
    async lopetaTekeytyminen() {
      try {
        await this.$store.dispatch("user/lopetaTekeytyminen", {
          siirrySivulle: "tyopoyta",
        });
      } catch (e) {
        this.$oletusVirheenkasittely(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
