var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-autocomplete", {
    staticClass: "pagination-hyppaa-autocomplete",
    attrs: {
      "auto-select-first": "",
      dense: "",
      outlined: "",
      items: _vm.autocompleteItems,
      "hide-details": "",
      "hide-no-data": true,
      placeholder: _vm.$t("common:jump"),
      "append-icon": "redo",
      "menu-props": { maxHeight: 54 },
    },
    on: { input: _vm.kasitteleSivunValinta },
    model: {
      value: _vm.autocompleteValue,
      callback: function ($$v) {
        _vm.autocompleteValue = $$v
      },
      expression: "autocompleteValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }