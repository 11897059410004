<template>
  <LineChartGenerator :data="chartData" :options="options" />
</template>

<script>
import { Line as LineChartGenerator } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "LineChart",
  components: { LineChartGenerator },
  props: {
    chartData: {
      type: Object,

      required: false,
      default() {
        return {
          labels: ["January", "February", "March"],
          datasets: [{ data: [40, 20, 12] }],
        };
      },
    },
  },

  computed: {
    options() {
      return {
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              let thisLabel = data.labels[tooltipItem.index];

              return thisLabel;
            },
          },
        },
        legend: {
          position: "left",
        },
        responsive: false,
      };
    },
  },
};
</script>
