var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tabs",
    { attrs: { "hide-slider": "", centered: _vm.center } },
    [
      _vm.text
        ? _c(
            "v-tab",
            { class: ["cardTitle", _vm.color], attrs: { disabled: "" } },
            [
              _vm._v(" " + _vm._s(_vm.text) + " "),
              _vm.tooltipInfoTeksti
                ? _c("TooltipInfo", {
                    attrs: {
                      "icon-color": "primary",
                      text: _vm.tooltipInfoTeksti,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }