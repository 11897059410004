<template>
  <v-form v-model="valid" @submit.prevent="submit">
    <v-select
      v-model="value.maksuaika"
      :rules="[$validationRules.required]"
      :items="siirtoVaihtoehdot"
      label="Maksuajan lisäys päivissä"
      item-value="value"
      item-text="selite"
      class="required"
      :menu-props="{ nudgeBottom: '40' }"
      z-index="999"
    />
    <PeruutaHyvaksyPainikerivi
      :submit-disabled="!valid || loading"
      :submit-loading="loading"
      @close="$emit('close')"
    />
  </v-form>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";

export default {
  name: "FormErapaivanSiirto",
  mixins: [FormMixin],
  data() {
    return {
      siirtoVaihtoehdot: [],
    };
  },
  created() {
    this.haeErapaivanSiirtoVaihtoehdot();
  },
  methods: {
    async haeErapaivanSiirtoVaihtoehdot() {
      try {
        const request = await this.$doRequestWithTimeout(
          this.$api.Laskut,
          {
            url: "toiminnot/erapaivan_siirto/vaihtoehdot",
          },
          "doRequest"
        );
        this.siirtoVaihtoehdot = request.result.body;
      } catch (e) {
        this.$oletusVirheenkasittely(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
