var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("LuottotietoSopimus", {
        ref: "luottotietoSopimus",
        attrs: { btn: false },
      }),
      _vm.btn
        ? _c(
            "v-btn",
            {
              attrs: { color: "primary" },
              on: {
                click: function ($event) {
                  return _vm.avaaLuottotiedot()
                },
              },
            },
            [_vm._v(" Hae Luottotiedot ")]
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: {
            persistent: "",
            fullscreen: _vm.wSize.sizeClass.value === "small",
          },
          model: {
            value: _vm.lsAuki,
            callback: function ($$v) {
              _vm.lsAuki = $$v
            },
            expression: "lsAuki",
          },
        },
        [
          _c("v-card", { staticClass: "dialog-card" }, [
            _c(
              "div",
              { staticClass: "dialog-header" },
              [
                _c("h1", [_vm._v("Luottotietojen Haku")]),
                _c("v-chip", { attrs: { color: "action" } }, [_vm._v("Beta")]),
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "div",
                              _vm._g(_vm._b({}, "div", attrs, false), on),
                              [
                                _c("div", { staticClass: "background-mirror" }),
                                _c(
                                  "v-icon",
                                  { attrs: { color: "action", large: "" } },
                                  [_vm._v("mdi-information")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c("div", [
                      _vm._v(" Yritysluottotietokysely: "),
                      _c("ul", [
                        _c("li", [_vm._v("Yksilöintitiedot")]),
                        _c("li", [_vm._v("Perustiedot")]),
                        _c("li", [_vm._v("Rekisteritiedot")]),
                        _c("li", [_vm._v("Maksuhäiriöt ja ref-merkintä")]),
                      ]),
                      _vm._v(" Henkilöluottotietokysely: "),
                      _c("ul", [
                        _c("li", [
                          _vm._v("Henkilöluottotiedot, sis. yritysyhteydet"),
                        ]),
                      ]),
                    ]),
                  ]
                ),
                _c("v-spacer"),
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "" },
                    on: {
                      click: function ($event) {
                        return _vm.suljeLuottotietoDialog()
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("close")])],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "dialog-content-container" }, [
              _vm.currentStep === "loading"
                ? _c(
                    "div",
                    { staticClass: "loading-container" },
                    [
                      _c("v-progress-circular", {
                        attrs: { indeterminate: "", color: "primary" },
                      }),
                    ],
                    1
                  )
                : _vm.currentStep === "error"
                ? _c(
                    "div",
                    { staticClass: "content-error content" },
                    [
                      !_vm.$store.state.user.vainYksiPaamies
                        ? _c(
                            "div",
                            { staticClass: "valitse-paamies" },
                            [
                              _c("CardTitle", {
                                attrs: {
                                  text: "Päämies",
                                  center: true,
                                  color: "black--text",
                                },
                              }),
                              _c("AutocompletePaamies", {
                                attrs: {
                                  dense: true,
                                  outlined: true,
                                  "emit-empty": false,
                                  label: "",
                                },
                                model: {
                                  value: _vm.user.valittuPaamies,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.user, "valittuPaamies", $$v)
                                  },
                                  expression: "user.valittuPaamies",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("div", [_vm._v(" " + _vm._s(_vm.error?.text) + " ")]),
                      _vm.error?.btnText
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "action" },
                              on: {
                                click: function ($event) {
                                  _vm.error?.btnClick()
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.error?.btnText) + " ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm.currentStep === "haku"
                ? _c("div", { staticClass: "content-perustiedot content" }, [
                    _c("div", { staticClass: "form-perustiedot" }, [
                      _c(
                        "div",
                        [
                          _c(
                            "v-form",
                            { ref: "form" },
                            [
                              !_vm.$store.state.user.vainYksiPaamies
                                ? _c("AutocompletePaamies", {
                                    attrs: { dense: true, outlined: true },
                                    model: {
                                      value: _vm.user.valittuPaamies,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.user,
                                          "valittuPaamies",
                                          $$v
                                        )
                                      },
                                      expression: "user.valittuPaamies",
                                    },
                                  })
                                : _vm._e(),
                              _vm.naytaTyyppi
                                ? _c("v-select", {
                                    attrs: {
                                      label: "Haun tyyppi",
                                      outlined: "",
                                      items: _vm.tyyppiOptions,
                                      "item-text": "teksti",
                                      "item-value": "arvo",
                                      required: "",
                                      rules: [_vm.$validationRules.required],
                                    },
                                    model: {
                                      value: _vm.haku.tyyppi,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.haku, "tyyppi", $$v)
                                      },
                                      expression: "haku.tyyppi",
                                    },
                                  })
                                : _vm._e(),
                              _vm.haku.tyyppi === "H"
                                ? _c("v-select", {
                                    attrs: {
                                      label: "Käyttötarkoitus",
                                      outlined: "",
                                      items: _vm.kayttotarkoitusOptionsArray,
                                      "item-text": "teksti",
                                      "item-value": "arvo",
                                      required: "",
                                      rules: [_vm.$validationRules.required],
                                    },
                                    model: {
                                      value: _vm.haku.kayttotarkoitus,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.haku,
                                          "kayttotarkoitus",
                                          $$v
                                        )
                                      },
                                      expression: "haku.kayttotarkoitus",
                                    },
                                  })
                                : _vm._e(),
                              _vm.haku.tyyppi
                                ? _c("v-text-field", {
                                    attrs: {
                                      label: _vm.tunnusLabel,
                                      outlined: "",
                                      required: "",
                                      rules: _vm.tunnusRules,
                                    },
                                    model: {
                                      value: _vm.haku.tunnus,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.haku, "tunnus", $$v)
                                      },
                                      expression: "haku.tunnus",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm.haku.tyyppi
                      ? _c(
                          "div",
                          { staticClass: "footer" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "action",
                                  disabled:
                                    !_vm.luottotiedotHaettavissa ||
                                    !_vm.valittuHinta,
                                },
                                on: { click: _vm.haeLuottotiedot },
                              },
                              [
                                _vm._v(" Hae Luottotiedot "),
                                _vm.valittuHinta
                                  ? _c("span", [
                                      _vm._v(
                                        "(" + _vm._s(_vm.valittuHinta) + "€)"
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" " + _vm._s(_vm.disabledMsg) + " "),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                : _vm.currentStep === "raportti"
                ? _c(
                    "div",
                    { staticClass: "content-raportti content" },
                    [
                      _vm.raporttiData
                        ? _c("LuottotietoHakuRaportti", {
                            attrs: { data: _vm.raporttiData },
                          })
                        : _c(
                            "div",
                            [
                              _c("v-progress-circular", {
                                attrs: {
                                  size: 50,
                                  color: "primary",
                                  indeterminate: "",
                                },
                              }),
                            ],
                            1
                          ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }