/**
 * https://stackoverflow.com/questions/48327261/way-to-make-inheritance-in-vuex-modules/56790942#56790942
 */

import api from "@/plugins/api";
import { HttpError, SallittuKokoYlitettyError } from "@/utils/errors";
import SopimusLaskutusaihe from "@/class/SopimusLaskutusaihe";
import Sopimus from "@/class/Sopimus";
import Laskurivi from "@/class/Laskurivi";
import { doRequestWithTimeout } from "@/utils/misc";
import BaseModule from "@/store/modules/baseModule";
import { Kirjoitusmoodit, MaksimiUploadTiedostoKoko } from "@/utils/constants";

export default class SopimusNayta extends BaseModule {
  state() {
    return {
      ...super.state(),
      sopimus: {
        ...new Sopimus(),
        avoinna: null,
        esta_perinta_tarkistukset: [],
        ikoni_ja_vari: {
          ikoni: null,
          vari: null,
        },
        kirje_set: [],
        laskuliite_set: [],
        onko_erapaivan_siirto_mahdollinen: false,
        paamies: {
          nimi: "",
        },
        tila: {},
      },
      asiakirjaFormData: {},
      erapaivanSiirtoFormData: {},
      hyvitaSopimustaFormData: {},
      laskusisaltoFormData: { rivit: [new Laskurivi()] },
      laskutusaiheFormData: {},
      laskutusjaksoLisaaFormData: { maara: 0 },
      laskutusjaksoMuokkaaFormData: {},
      laskutusjaksonLiiteFormData: {},
      paamiesSiirraFormData: {},
      perinnanEstoFormData: {},
      poistettavaTiedosto: {},
      laskusisaltoKirjoitusmoodi: Kirjoitusmoodit.LUOMINEN,
      laskutusaiheKirjoitusmoodi: Kirjoitusmoodit.LUOMINEN,
      laskusisaltoRiviId: null,
      menuContextLaskutusjaksonLiitteenAvausNakyvissa: false,
      menuContextLaskutusjaksonLiitteenAvausVaihtoehdot: [],
      menuContextLaskutusjaksonLiitteenAvausX: 0,
      menuContextLaskutusjaksonLiitteenAvausY: 0,
    };
  }

  mutations() {
    return {
      setSopimus(state, payload) {
        // Halutaan saada Sopimus-luokan metodit käyttöön
        state.sopimus = Object.assign(new Sopimus(), payload);
      },
      setAsiakirjaFormData(state, payload) {
        state.asiakirjaFormData = payload;
      },
      setErapaivanSiirtoFormData(state, payload) {
        state.erapaivanSiirtoFormData = payload;
      },
      setHyvitaSopimustaFormData(state, payload) {
        state.hyvitaSopimustaFormData = payload;
      },
      setLaskusisaltoFormData(state, payload) {
        state.laskusisaltoFormData = payload;
      },
      setLaskutusaiheFormData(state, payload) {
        state.laskutusaiheFormData = payload;
      },
      setLaskutusjaksoLisaaFormData(state, payload) {
        state.laskutusjaksoLisaaFormData = payload;
      },
      setLaskutusjaksoMuokkaaFormData(state, payload) {
        state.laskutusjaksoMuokkaaFormData = payload;
      },
      setLaskutusjaksonLiiteFormData(state, payload) {
        state.laskutusjaksonLiiteFormData = payload;
      },
      setPaamiesSiirraFormData(state, payload) {
        state.paamiesSiirraFormData = payload;
      },
      setPerinnanEstoFormData(state, payload) {
        state.perinnanEstoFormData = payload;
      },
      setPoistettavaTiedosto(state, payload) {
        state.poistettavaTiedosto = payload;
      },
      setLaskusisaltoKirjoitusmoodi(state, payload) {
        state.laskusisaltoKirjoitusmoodi = payload;
      },
      setLaskutusaiheKirjoitusmoodi(state, payload) {
        state.laskutusaiheKirjoitusmoodi = payload;
      },
      setLaskusisaltoRiviId(state, payload) {
        state.laskusisaltoRiviId = payload;
      },
      setMenuContextLaskutusjaksonLiitteenAvausNakyvissa(state, payload) {
        state.menuContextLaskutusjaksonLiitteenAvausNakyvissa = payload;
      },
      setMenuContextLaskutusjaksonLiitteenAvausVaihtoehdot(state, payload) {
        state.menuContextLaskutusjaksonLiitteenAvausVaihtoehdot = payload;
      },
      setMenuContextLaskutusjaksonLiitteenAvausX(state, payload) {
        state.menuContextLaskutusjaksonLiitteenAvausX = payload;
      },
      setMenuContextLaskutusjaksonLiitteenAvausY(state, payload) {
        state.menuContextLaskutusjaksonLiitteenAvausY = payload;
      },
    };
  }

  actions() {
    return {
      async clearDetails({ commit }) {
        commit("setSopimus", {});
        commit("setAsiakirjaFormData", {});
        commit("setErapaivanSiirtoFormData", {});
        commit("setHyvitaSopimustaFormData", {});
        commit("setLaskusisaltoFormData", {});
        commit("setLaskutusaiheFormData", {});
        commit("setLaskutusjaksoLisaaFormData", {});
        commit("setLaskutusjaksoMuokkaaFormData", {});
        commit("setLaskutusjaksonLiiteFormData", {});
        commit("setPaamiesSiirraFormData", {});
        commit("setPerinnanEstoFormData", {});
        commit("setPoistettavaTiedosto", {});
        commit("setLaskusisaltoKirjoitusmoodi", {});
        commit("setLaskusisaltoRiviId", null);
        commit("setMenuContextLaskutusjaksonLiitteenAvausNakyvissa", {});
        commit("setMenuContextLaskutusjaksonLiitteenAvausVaihtoehdot", {});
        commit("setMenuContextLaskutusjaksonLiitteenAvausX", {});
        commit("setMenuContextLaskutusjaksonLiitteenAvausY", {});
      },
      async loadItem({ commit, state }, sroute) {
        const request = await doRequestWithTimeout(
          api.Sopimus,
          {
            method: "GET",
            url: "",
            params: {
              id: sroute.params.id,
            },
          },
          "pushRequest",
          90000 // 1,5 min
        );
        if (!request.success) throw new HttpError(request);

        const sopimus = request.result.body;
        commit("setSopimus", sopimus);
        commit("user/setValittuPaamies", state.sopimus.paamies, { root: true });
      },
      async asetaLaskutusjaksotaulukonSivutus({ state }) {
        let nykyPaivaaLahinnaOlevanIndeksi = null;
        let nykypaiva = new Date();
        let erotus = null;
        let lahimmanErotus = null;
        const laskutusjaksot = state.sopimus.sopimuslaskutusjakso_set;

        for (let i = 0; i < laskutusjaksot.length; i++) {
          const alkamispaiva = new Date(laskutusjaksot[i].alkamispaiva);
          if (nykypaiva > alkamispaiva) {
            erotus = nykypaiva - alkamispaiva;
          } else {
            erotus = alkamispaiva - nykypaiva;
          }
          if (erotus < lahimmanErotus || lahimmanErotus === null) {
            lahimmanErotus = erotus;
            nykyPaivaaLahinnaOlevanIndeksi = i;
          }
        }
        return Math.ceil(nykyPaivaaLahinnaOlevanIndeksi / 3);
      },
      async asiakirjaSubmit({ commit, state }) {
        const formData = new FormData();

        let liitteetLkm = 0;
        for (var tiedosto of state.asiakirjaFormData.tiedostot) {
          if (tiedosto.size > MaksimiUploadTiedostoKoko)
            throw new SallittuKokoYlitettyError(
              SallittuKokoYlitettyError.SALLITTU_KOKO_YLITETTY_VIESTI,
              tiedosto.name
            );
          liitteetLkm++;
          formData.append(tiedosto.name, tiedosto);
        }

        const request = await doRequestWithTimeout(
          api.Sopimus,
          {
            method: "POST",
            url: "toiminnot/asiakirja/tuo/",
            body: formData,
            params: {
              id: state.sopimus.id,
            },
          },
          "doSingleRequest"
        );

        if (!request.success) throw new HttpError(request);

        commit("setSopimus", request.result.body);
        return liitteetLkm === 1
          ? "Liite onnistuneesti lisätty."
          : "Liitteet onnistuneesti lisätty.";
      },
      async hyvitaSopimustaSubmit({ commit, state }) {
        const request = await doRequestWithTimeout(
          api.Sopimus,
          {
            method: "PUT",
            url: `toiminnot/hyvita/`,
            body: state.hyvitaSopimustaFormData,
            params: {
              id: state.sopimus.id,
            },
          },
          "doSingleRequest"
        );

        if (!request.success) throw new HttpError(request);

        commit("setSopimus", request.result.body);
      },
      async paataSopimusSubmit({ commit, state }) {
        const request = await doRequestWithTimeout(
          api.Sopimus,
          {
            method: "PUT",
            url: `toiminnot/paata/`,
            params: {
              id: state.sopimus.id,
            },
          },
          "doSingleRequest"
        );

        if (!request.success) throw new HttpError(request);

        commit("setSopimus", request.result.body);
      },
      async aktivoiSopimusSubmit({ commit, state }) {
        const request = await doRequestWithTimeout(
          api.Sopimus,
          {
            method: "PUT",
            url: `toiminnot/aktivoi/`,
            params: {
              id: state.sopimus.id,
            },
          },
          "doSingleRequest"
        );

        if (!request.success) throw new HttpError(request);

        commit("setSopimus", request.result.body);
      },
      avaaMenuContextLaskutusjaksoLiite({ commit }, { event, laskutusjakso }) {
        const menuContextLaskutusjaksonLiitteenAvausVaihtoehdot = [];
        for (const tiedosto of laskutusjakso.tiedosto_set) {
          menuContextLaskutusjaksonLiitteenAvausVaihtoehdot.push({
            icon: "anitta-icon-file-download",
            laskutusjaksoId: laskutusjakso.id,
            liiteId: tiedosto.id,
            title: tiedosto.nimi,
            event: "avaaLiite",
          });
        }
        commit(
          "setMenuContextLaskutusjaksonLiitteenAvausVaihtoehdot",
          menuContextLaskutusjaksonLiitteenAvausVaihtoehdot
        );
        commit("setMenuContextLaskutusjaksonLiitteenAvausX", event.clientX);
        commit("setMenuContextLaskutusjaksonLiitteenAvausY", event.clientY);
        commit("setMenuContextLaskutusjaksonLiitteenAvausNakyvissa", true);
      },
      async avaaLaskutusjaksonLiite({ state }, vaihtoehto) {
        const { laskutusjaksoId, liiteId } = vaihtoehto;

        const request = await doRequestWithTimeout(
          api.Sopimus,
          {
            method: "GET",
            url: `toiminnot/laskutusjakso/${laskutusjaksoId}/liite/${liiteId}`,
            parser: "arrayBuffer",
            params: {
              id: state.sopimus.id,
            },
          },
          "doSingleRequest"
        );

        if (!request.success) throw new HttpError(request);
        return request;
      },

      async laskutusjaksonLiiteSubmit({ commit, state }) {
        const formData = new FormData();
        const laskutusjaksoId =
          state.laskutusjaksonLiiteFormData.laskutusjaksoId;
        if (
          state.laskutusjaksonLiiteFormData.tiedosto.size >
          MaksimiUploadTiedostoKoko
        )
          throw new SallittuKokoYlitettyError();
        formData.set(
          "attachment_file",
          state.laskutusjaksonLiiteFormData.tiedosto
        );

        const request = await doRequestWithTimeout(
          api.Sopimus,
          {
            method: "POST",
            url: `toiminnot/laskutusjakso/${laskutusjaksoId}/liite/`,
            body: formData,
            params: {
              id: state.sopimus.id,
            },
          },
          "doSingleRequest"
        );

        if (!request.success) throw new HttpError(request);

        commit("setSopimus", request.result.body);
      },

      siirrySopimuksenMuokkaukseen({ commit, state }, router) {
        if (state.sopimus.kirjepohja) {
          // olisi liian monimutkaista luoda uutta sopimus muuttujaa, niin muutetaan state:n ja kommitoidaan
          state.sopimus.kirjepohja_id = state.sopimus.kirjepohja.id;
          commit("setSopimus", state.sopimus);
        }
        router.push({
          name: "sopimus-tallenna",
          params: {
            muokattavaSopimus: state.sopimus,
          },
        });
      },

      muokkaaLaskutusjaksoa({ commit }, laskutusjakso) {
        const laskutusjaksoMuokkaaFormData = {
          id: laskutusjakso.id,
          alkamispaiva: laskutusjakso.alkamispaiva,
          loppumispaiva: laskutusjakso.loppumispaiva,
          selite: laskutusjakso.selite,
        };
        commit("setLaskutusjaksoMuokkaaFormData", laskutusjaksoMuokkaaFormData);
      },

      async laskutusjaksoMuokkaaSubmit({ commit, state }) {
        const laskutusjaksoId = state.laskutusjaksoMuokkaaFormData.id;
        const postData = state.laskutusjaksoMuokkaaFormData;
        delete postData.id;

        const request = await doRequestWithTimeout(
          api.Sopimus,
          {
            method: "PUT",
            url: `toiminnot/laskutusjakso/${laskutusjaksoId}/muokkaa/`,
            body: postData,
            params: {
              id: state.sopimus.id,
            },
          },
          "doSingleRequest"
        );

        if (!request.success) throw new HttpError(request);

        commit("setSopimus", request.result.body);
      },

      async laskutaLaskutusjakso({ commit, state }, id) {
        const request = await doRequestWithTimeout(
          api.Sopimus,
          {
            method: "PUT",
            url: `toiminnot/laskutusjakso/${id}/laskuta/`,
            params: {
              id: state.sopimus.id,
            },
          },
          "doSingleRequest"
        );

        if (!request.success) throw new HttpError(request);

        commit("setSopimus", request.result.body);
      },

      async laskutusjaksoLisaaSubmit({ commit, state }) {
        const request = await doRequestWithTimeout(
          api.Sopimus,
          {
            method: "POST",
            url: "toiminnot/laskutusjakso/",
            body: state.laskutusjaksoLisaaFormData,
            params: {
              id: state.sopimus.id,
            },
          },
          "doSingleRequest"
        );

        if (!request.success) throw new HttpError(request);

        commit("setSopimus", request.result.body);
      },

      lisaaLaskusisalto({ commit, state }) {
        commit("setLaskusisaltoKirjoitusmoodi", Kirjoitusmoodit.LUOMINEN);
        const laskusisaltoFormData = {
          paamies: state.sopimus.paamies,
          rivit: [new Laskurivi(Laskurivi.oletusPreset)],
        };
        commit("setLaskusisaltoFormData", laskusisaltoFormData);
      },

      muokkaaLaskusisaltoa({ commit, state }, laskusisalto) {
        commit("setLaskusisaltoKirjoitusmoodi", Kirjoitusmoodit.MUOKKAUS);
        commit("setLaskusisaltoRiviId", laskusisalto.id);
        const laskusisaltoFormData = {
          paamies: state.sopimus.paamies,
          rivit: [new Laskurivi(laskusisalto)],
        };
        commit("setLaskusisaltoFormData", laskusisaltoFormData);
      },

      async laskusisaltoSubmit({ commit, state }) {
        const method =
          state.laskusisaltoKirjoitusmoodi === Kirjoitusmoodit.MUOKKAUS
            ? "PUT"
            : "POST";
        const postData = state.laskusisaltoFormData.rivit[0].getPostData({
          lahetaId: method === "PUT",
        });
        postData.sopimus_id = state.sopimus.id;
        if (method === "PUT" && !postData.id) {
          postData.id = state.laskusisaltoRiviId;
        }
        const requestConfig = {
          method,
          url: "toiminnot/laskusisalto/",
          body: postData,
          params: {
            id: state.sopimus.id,
          },
        };

        if (state.laskusisaltoKirjoitusmoodi === Kirjoitusmoodit.MUOKKAUS) {
          const laskusisaltoId = postData.id;
          requestConfig.url += `${laskusisaltoId}/`;
        }

        const request = await doRequestWithTimeout(
          api.Sopimus,
          requestConfig,
          "doSingleRequest"
        );

        if (!request.success) throw new HttpError(request);

        commit("setSopimus", request.result.body);
      },

      async laskutusisaltoPoista({ commit, state }, id = null) {
        const request = await doRequestWithTimeout(
          api.Sopimus,
          {
            method: "DELETE",
            url: `toiminnot/laskusisalto/${id}`,
            params: {
              id: state.sopimus.id,
            },
          },
          "doSingleRequest"
        );

        if (!request.success) throw new HttpError(request);

        commit("setSopimus", request.result.body);
      },

      lisaaLaskutusaihe({ commit }, laskutusjakso) {
        commit("setLaskutusaiheKirjoitusmoodi", Kirjoitusmoodit.LUOMINEN);

        let laskutuspaiva = null;
        const jaksonAiheetKpl = laskutusjakso.subRows.length;
        if (jaksonAiheetKpl) {
          const jaksonViimeisinLaskutusaihe =
            laskutusjakso.subRows[jaksonAiheetKpl - 1];
          laskutuspaiva = jaksonViimeisinLaskutusaihe.laskutuspaiva;
        }
        const preset = laskutusjakso
          ? {
              laskutusjakso: laskutusjakso.id,
              laskutuspaiva,
            }
          : {};

        commit("setLaskutusaiheFormData", new SopimusLaskutusaihe(preset));
      },

      muokkaaLaskutusaihetta({ commit }, laskutusaihe) {
        commit("setLaskutusaiheKirjoitusmoodi", Kirjoitusmoodit.MUOKKAUS);
        commit(
          "setLaskutusaiheFormData",
          new SopimusLaskutusaihe(laskutusaihe)
        );
      },

      async laskutusaiheSubmit({ commit, state }) {
        const postData = state.laskutusaiheFormData.getPostData();

        const requestConfig = {
          method: "POST",
          url: "toiminnot/laskutusaihe/",
          body: postData,
          params: {
            id: state.sopimus.id,
          },
        };

        if (state.laskutusaiheKirjoitusmoodi === Kirjoitusmoodit.MUOKKAUS) {
          requestConfig.method = "PUT";
          const laskutusaiheId = state.laskutusaiheFormData.id;
          requestConfig.url += `${laskutusaiheId}/`;
        }

        const request = await doRequestWithTimeout(
          api.Sopimus,
          requestConfig,
          "doSingleRequest"
        );

        if (!request.success) throw new HttpError(request);

        commit("setSopimus", request.result.body);
      },

      async laskutusaihePoista({ commit, state }, id = null) {
        const url = id
          ? `toiminnot/laskutusaihe/${id}/`
          : "toiminnot/tyhjenna_laskutusaiheet";

        const request = await doRequestWithTimeout(
          api.Sopimus,
          {
            method: "DELETE",
            url: url,
            params: {
              id: state.sopimus.id,
            },
          },
          "doSingleRequest"
        );

        if (!request.success) throw new HttpError(request);

        commit("setSopimus", request.result.body);
      },

      async laskutusaiheetPaivita({ commit, state }) {
        const request = await doRequestWithTimeout(
          api.Sopimus,
          {
            method: "PUT",
            url: "toiminnot/paivita_laskutusaiheet/",
            params: {
              id: state.sopimus.id,
            },
          },
          "doSingleRequest"
        );

        if (!request.success) throw new HttpError(request);

        commit("setSopimus", request.result.body);
      },

      async paamiesSiirraSubmit({ commit, state }) {
        const request = await doRequestWithTimeout(
          api.Sopimus,
          {
            method: "PUT",
            url: "toiminnot/vaihda_paamies/",
            params: {
              id: state.sopimus.id,
            },
            body: {
              paamies_id: state.paamiesSiirraFormData.paamies_id,
            },
          },
          "doSingleRequest"
        );

        if (!request.success) throw new HttpError(request);

        commit("setSopimus", request.result.body);
      },
    };
  }
}
