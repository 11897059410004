var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        "position-x": _vm.positionX,
        "position-y": _vm.positionY,
        absolute: "",
        "offset-y": "",
      },
      on: {
        "update:return-value": function ($event) {
          return _vm.$emit("update:return-value")
        },
      },
      model: {
        value: _vm.naytetaanko,
        callback: function ($$v) {
          _vm.naytetaanko = $$v
        },
        expression: "naytetaanko",
      },
    },
    [
      _c(
        "v-list",
        _vm._l(_vm.vaihtoehdot, function (vaihtoehto) {
          return _c(
            "v-list-item",
            {
              key: vaihtoehto.title,
              on: {
                click: function ($event) {
                  return _vm.$emit(vaihtoehto.event, vaihtoehto)
                },
              },
            },
            [
              _c(
                "v-list-item-avatar",
                [_c("v-icon", [_vm._v(_vm._s(vaihtoehto.icon))])],
                1
              ),
              _c("v-list-item-title", [
                _vm._v(" " + _vm._s(vaihtoehto.title) + " "),
              ]),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }