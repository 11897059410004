import { render, staticRenderFns } from "./DataTableToimeksiantoAsiakirja.vue?vue&type=template&id=ba0b8b3e&scoped=true"
import script from "./DataTableToimeksiantoAsiakirja.vue?vue&type=script&lang=js"
export * from "./DataTableToimeksiantoAsiakirja.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba0b8b3e",
  null
  
)

export default component.exports