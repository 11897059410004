<template>
  <div class="login">
    <div class="login__top">
      <div class="login__wrap">
        <div class="login__col login__col--left pr-6">
          <Logo :logo-key="logoKey" :enable-link="false" class="mx-0" />
        </div>
        <div class="login__col login__col--right pa-6">
          <v-card class="pa-12">
            <h2 class="text-h5 mb-4">Salasanan uusiminen</h2>
            <Spinner v-if="loading" />
            <p v-if="formUusiSalasanaData.token === 'invalid'">
              Salasanan uusimispyyntö ei ole voimassa.
            </p>
            <template v-else-if="!loading">
              <p>Kirjoita uusi salasanasi alla olevaan kenttään.</p>
              <FormUusiSalasana
                v-model="formUusiSalasanaData"
                :loading="loading"
                @submit="asetaUusiSalasana"
              />
            </template>
          </v-card>
        </div>
      </div>
      <div class="login__wrap">
        <div class="login__col login__col--left pa-6"></div>
        <div class="login__col login__col--right pa-6 loginHelp pt-6">
          <p>Palvelu on käytössä vain toimeksiantaja-asiakkaillemme.</p>
        </div>
      </div>
    </div>
    <BusinessFooter />
  </div>
</template>

<script>
import { BusinessFooter, FormUusiSalasana, Logo } from "@/components";

export default {
  name: "UusiSalasana",
  components: {
    BusinessFooter,
    FormUusiSalasana,
    Logo,
  },
  data() {
    return {
      loading: true,
      formUusiSalasanaData: {
        password: "",
        token: "",
      },
      logoKey: "",
    };
  },
  async created() {
    this.logoKey = process.env.VUE_APP_LOGO_LOGIN
      ? process.env.VUE_APP_LOGO_LOGIN
      : process.env.VUE_APP_LOGO;

    const passwordResetToken = new URL(location.href).searchParams.get("token");
    if (!passwordResetToken) {
      this.$naytaVirheilmoitus(
        "Sivulle saavuttu virheellisen linkin kautta. Pyydä tarvittaessa uudestaan salasanan uusimista."
      );
    } else {
      this.tarkistaTokeninVoimassaolo(passwordResetToken);
    }
  },
  methods: {
    async asetaUusiSalasana() {
      this.loading = true;

      try {
        const request = await this.$doRequestWithTimeout(
          this.$api.UusiSalasana,
          {
            method: "POST",
            url: "confirm/",
            body: this.formUusiSalasanaData,
          },
          "doSingleRequest"
        );

        if (!request.success) {
          if (request.result.status === 404) {
            throw new this.$PasswordResetExpiredError();
          } else {
            throw new this.$HttpError(
              request,
              "Salasanan asettaminen epäonnistui!"
            );
          }
        }

        this.$naytaOnnistumisilmoitus("Uuden salasanan asettaminen onnistui!");

        // Redirect
        this.$router.replace({ name: "kirjaudu" });
      } catch (e) {
        console.log(e);
        this.$naytaVirheilmoitus(e.message);
        if (e.name !== "PasswordResetExpiredError")
          this.$sentryCaptureCustom(e);
      } finally {
        this.loading = false;
      }
    },
    async tarkistaTokeninVoimassaolo(token) {
      try {
        this.loading = true;

        const request = await this.$doRequestWithTimeout(
          this.$api.UusiSalasana,
          {
            method: "POST",
            url: "validate_token/",
            body: { token },
          },
          "doSingleRequest"
        );

        if (!request.success) {
          if (request.result.status === 404) {
            this.formUusiSalasanaData.token = "invalid";
            throw new this.$PasswordResetExpiredError();
          } else {
            throw new this.$HttpError(request);
          }
        } else {
          this.formUusiSalasanaData.token = token;
        }
      } catch (e) {
        console.log(e);
        this.$naytaVirheilmoitus(e.message);
        if (e.name !== "PasswordResetExpiredError")
          this.$sentryCaptureCustom(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/loginStyles.scss";
</style>
