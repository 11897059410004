var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "error404" }, [
    _c(
      "div",
      { staticClass: "sticky" },
      [
        _c(
          "v-toolbar",
          { attrs: { color: "primary", flat: "", tabs: "" } },
          [
            _vm.$route.meta.title
              ? _c("v-toolbar-title", [
                  _vm._v(" " + _vm._s(_vm.$route.meta.title) + " "),
                ])
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }