var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.miniVariant
    ? _c(
        "v-icon",
        {
          staticClass: "ml-n2 my-5",
          attrs: { color: "error", large: "" },
          on: { click: _vm.lopetaTekeytyminen },
        },
        [_vm._v(" mdi-account-multiple-minus-outline ")]
      )
    : _c(
        "v-card",
        { staticClass: "mb-3", attrs: { color: "warning" } },
        [
          _c("v-card-text", { staticClass: "font-weight-bold" }, [
            _vm._v(
              " Tekeytyminen käynnissä käyttäjälle " +
                _vm._s(_vm.kayttajanimi) +
                ". "
            ),
          ]),
          _c(
            "v-card-actions",
            { staticClass: "justify-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "black white--text",
                  attrs: { small: "" },
                  on: { click: _vm.lopetaTekeytyminen },
                },
                [_vm._v(" Lopeta tekeytyminen ")]
              ),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }