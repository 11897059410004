export default {
  name: "FormMixin",
  props: {
    loading: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    value: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    onAuki: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      valid: true,
    };
  },
  methods: {
    submit() {
      this.$emit("submit", this.value);
    },
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        this.$emit("input", val);
      },
    },
  },
};
