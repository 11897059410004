<template>
  <v-menu
    v-model="datePickerOpen"
    :close-on-content-click="false"
    max-width="290"
    nudge-left="110"
  >
    <template #activator="{ on }">
      <v-text-field
        ref="textField"
        v-bind="$attrs"
        :value="$dateFnsFormat(valueMutable)"
        readonly
        clearable
        :class="textFieldClass"
        @click:clear="clearInput"
        v-on="on"
      />
    </template>
    <template #default>
      <v-date-picker
        v-model="valueMutable"
        :locale="$languageLocale()"
        first-day-of-week="1"
        no-title
        @change="datePickerOpen = false"
      />
    </template>
  </v-menu>
</template>

<script>
export default {
  name: "InputfieldDatePicker",
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      required: false,
      default() {
        return "";
      },
    },
    textFieldClass: {
      type: String,
      required: false,
      default() {
        "";
      },
    },
  },
  data() {
    return {
      valueMutable: "",
      datePickerOpen: false,
    };
  },
  watch: {
    valueMutable() {
      this.$emit("input", this.valueMutable);
    },
  },
  updated() {
    this.valueMutable = this.value;
  },
  mounted() {
    this.valueMutable = this.value;
  },
  methods: {
    clearInput() {
      this.valueMutable = "";
      this.datePickerOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
