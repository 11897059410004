var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "formAsiakasCSV",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "px-4" },
            [
              !_vm.$store.state.user.vainYksiPaamies
                ? _c("AutocompletePaamies", {
                    ref: "autocompletePaamies",
                    attrs: {
                      "tarkistettava-oikeus":
                        "onlinerestapi.onlinerestapi_lasku",
                      "validointi-saannot": [
                        _vm.$validationRules.requiredObject,
                      ],
                    },
                    model: {
                      value: _vm.csvLomake.paamies,
                      callback: function ($$v) {
                        _vm.$set(_vm.csvLomake, "paamies", $$v)
                      },
                      expression: "csvLomake.paamies",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "px-4" },
            [
              _c("v-file-input", {
                attrs: {
                  accept: ".csv",
                  label: "CSV-tiedosto",
                  rules: [_vm.$validationRules.required],
                },
                model: {
                  value: _vm.csvLomake.csv,
                  callback: function ($$v) {
                    _vm.$set(_vm.csvLomake, "csv", $$v)
                  },
                  expression: "csvLomake.csv",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-4 pa-3 px-4", attrs: { justify: "end" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "mr-4",
              attrs: { large: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v("Peruuta")]
          ),
          _c(
            "v-btn",
            {
              attrs: {
                large: "",
                color: "action",
                disabled: !_vm.valid || _vm.loading,
              },
              on: { click: _vm.luoAsiakkaatTiedostosta },
            },
            [_vm._v(" Tuo tiedostosta ")]
          ),
        ],
        1
      ),
      _c("v-divider", { staticClass: "my-8 mx-1" }),
      _c(
        "v-row",
        { staticClass: "mt-4 py-3 px-4", attrs: { justify: "end" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "mr-4",
              attrs: { small: "", color: "primary" },
              on: {
                click: function ($event) {
                  _vm.naytaVaihtoehdot = !_vm.naytaVaihtoehdot
                },
              },
            },
            [
              _vm._v(" " + _vm._s(_vm.vaihtoehdotTeksti) + " "),
              _c("v-icon", { attrs: { right: "", small: "" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.naytaVaihtoehdot
                        ? "keyboard_arrow_up"
                        : "keyboard_arrow_down"
                    ) +
                    " "
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: {
                href: "asiakas-esimerkki.csv",
                download: "",
                small: "",
                color: "primary",
              },
            },
            [
              _vm._v(" Lataa esimerkki-csv "),
              _c("v-icon", { attrs: { right: "", small: "" } }, [
                _vm._v("download"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.naytaVaihtoehdot
        ? [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { staticClass: "px-4" },
                  [
                    _c(
                      "v-card",
                      { staticClass: "mb-4 pa-4", attrs: { color: "info" } },
                      [
                        _c(
                          "v-icon",
                          { staticClass: "mr-2", attrs: { dark: "" } },
                          [_vm._v("info")]
                        ),
                        _c(
                          "span",
                          { staticClass: "text-subtitle-2 white--text" },
                          [
                            _vm._v(
                              " Huom! Vaihtoehdot ovat selaamista varten. Niiden valinta ei vaikuta aineiston tuontiin. "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { staticClass: "px-4" },
                  [
                    _c("v-autocomplete", {
                      attrs: {
                        label: "maa",
                        items: _vm.maat,
                        "item-text": "value",
                        dense: "",
                        hint: "Vakiona FI",
                        "persistent-hint": "",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { staticClass: "px-4" },
                  [
                    _c("v-autocomplete", {
                      attrs: {
                        label: "tyyppi",
                        hint: "Henkiö tai yritys",
                        "persistent-hint": "",
                        items: _vm.tyypit,
                        dense: "",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { staticClass: "px-4" },
                  [
                    _c("v-autocomplete", {
                      attrs: {
                        label: "kieli",
                        items: _vm.kielet,
                        "item-text": "value",
                        dense: "",
                        hint: "Vakiona fi-fi",
                        "persistent-hint": "",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { staticClass: "px-4" },
                  [
                    _c("v-autocomplete", {
                      attrs: {
                        label: "operaattori",
                        hint: "Verkkolaskun operaattori (csv:lle vain operaattoritunnus)",
                        "persistent-hint": "",
                        items: _vm.operaattorit,
                        "item-text": "autocomplete_selite",
                        dense: "",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { staticClass: "px-4" },
                  [
                    _c("v-autocomplete", {
                      attrs: {
                        label: "lahetystapa",
                        items: _vm.lahetystavat,
                        "item-text": "value",
                        dense: "",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }