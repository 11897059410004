import BaseSelaa from "@/store/modules/baseSelaa";
import { doRequestWithTimeout } from "@/utils/misc";

export default class RaportitKuukausiraporttiSelaa extends BaseSelaa {
  state() {
    return {
      ...super.state(),
      apiName: "RaportitKuukausiraportti",
    };
  }

  actions() {
    return {
      ...super.actions(),
      async lahetaNyt({ getters }, id) {
        return await doRequestWithTimeout(
          getters.api,
          {
            url: `${id}/laheta_nyt/`,
            method: "POST",
          },
          "doSingleRequest"
        );
      },
      async poistaKuukausiraportti({ getters }, id) {
        return await doRequestWithTimeout(
          getters.api,
          {
            url: `${id}/`,
            method: "DELETE",
          },
          "doSingleRequest"
        );
      },
    };
  }
}
