var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "formSopimusLaskusisalto",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c("LaskuriviTable", {
        ref: "laskuriviTable",
        attrs: {
          lasku: _vm.value,
          "nayta-yhteenveto": false,
          "salli-poistaminen": false,
          tyyppi: "sopimus",
        },
      }),
      _c(
        "v-row",
        {
          staticClass: "mt-4 px-3",
          attrs: { align: "end", justify: "space-between" },
        },
        [
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { cols: "8" } },
            [
              _c("AutocompleteLaskurivi", {
                staticClass: "mb-n4",
                attrs: {
                  lasku: _vm.value,
                  tyyppi: "sopimus",
                  "input-hint": _vm.$t("common:addRowInfo"),
                },
                on: { "autocomplete-input": _vm.kasitteleAutocompleteValinta },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { cols: "4" } },
            [
              _c(
                "v-row",
                { attrs: { justify: "end" } },
                [
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close")
                        },
                      },
                    },
                    [_vm._v("Peruuta")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-4",
                      attrs: {
                        type: "submit",
                        color: "action",
                        disabled: !_vm.valid || _vm.loading,
                        loading: _vm.loading,
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.tallennuspainikkeenTeksti) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }