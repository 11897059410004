<template>
  <v-form ref="formAsiakas">
    <v-row>
      <AutocompleteYTJHaku
        input-hint="Hakutuloksen valinta täyttää yritysasiakkaan tiedot lomakkeelle."
        @autocomplete-input="asetaYrityksenTiedotLomakkeelle"
      />
    </v-row>
    <v-row>
      <v-col md="6" class="pl-4 pr-12">
        <AutocompletePaamies
          v-if="!$store.state.user.vainYksiPaamies && !paamies"
          ref="autocompletePaamies"
          v-model="asiakas.paamies"
          tarkistettava-oikeus="onlinerestapi.onlinerestapi_lasku"
          :validointi-saannot="[$validationRules.requiredObject]"
        />
      </v-col>
    </v-row>
    <v-row>
      <!-- Vasen sarake-->
      <v-col md="6" class="pl-4 pr-12">
        <h3 class="my-8">Asiakastiedot</h3>
        <v-text-field
          v-model="asiakas.nro"
          label="Asiakasnumero"
          :error-messages="asiakasnroErrors"
          @input="tarkistaAsiakasnro($event)"
        >
          <template #append-outer>
            <TooltipInfo
              icon-color="primary"
              :text="$t('common:infotekstit.customerNoInfo')"
            />
          </template>
        </v-text-field>
        <v-select
          v-model="asiakas.tyyppi"
          label="Tyyppi"
          type="text"
          :items="vastapuolenTyypit"
          item-value="value"
          item-text="selite"
          :menu-props="{ zIndex: '999' }"
          :rules="[$validationRules.required]"
          class="required"
          @input="asiakas.tunnus ? tarkistaTunnus(asiakas.tunnus) : () => {}"
        />
        <v-text-field
          v-model="asiakas.tunnus"
          label="Y-tunnus / Hetu"
          :error-messages="tunnusErrors"
          :rules="[!tunnusErrors.length]"
          @input="tunnusErrors.length ? tarkistaTunnus($event) : () => {}"
          @blur="
            [
              tarkistaTunnus($event.target.value),
              tarkistaOlemassaOlevaTunnus($event.target.value),
            ]
          "
        />
        <v-select
          v-model="asiakas.kieli"
          label="Kieli"
          type="text"
          :items="kielet || []"
          :value="kielet ? kielet[0] : ''"
          item-value="value"
          item-text="selite"
          :menu-props="{ zIndex: '999' }"
          :rules="[$validationRules.required]"
          class="required"
        />
        <h3 class="my-8">Osoitetiedot</h3>
        <div v-if="asiakas.tyyppi === 'H'" class="comboField">
          <v-text-field
            v-model="asiakas.etunimi"
            label="Etunimi"
            :rules="[$validationRules.required]"
            :hint="'Anna yksi tai useampi'"
            class="required"
            @blur="tarkistaOlemassaOlevaNimiHenkilo"
          />
          <span class="comboField__separator"></span>
          <v-text-field
            v-model="asiakas.sukunimi"
            label="Sukunimi"
            :rules="[$validationRules.required]"
            class="required"
            @blur="tarkistaOlemassaOlevaNimiHenkilo"
          />
        </div>
        <v-text-field
          v-else
          v-model="asiakas.nimi"
          label="Nimi"
          :rules="[$validationRules.required]"
          class="required"
          @blur="tarkistaOlemassaOlevaNimi($event.target.value)"
        />
        <v-text-field
          v-model="asiakas.postiosoite.saaja"
          label="Saaja / Tarkenne"
        />
        <v-text-field
          v-model="asiakas.postiosoite.osoite"
          label="Lähisoite"
          :rules="[$validationRules.required]"
          class="required"
        />
        <v-row>
          <v-col md="5" class="py-1">
            <v-text-field
              v-model="asiakas.postiosoite.numero"
              label="Postinumero"
              :rules="[$validationRules.required]"
              class="required"
            />
          </v-col>
          <v-col md="7" class="py-1">
            <v-text-field
              v-model="asiakas.postiosoite.toimipaikka"
              label="Postitoimipaikka"
              :rules="[$validationRules.required]"
              class="required"
            />
          </v-col>
        </v-row>
        <v-select
          v-model="asiakas.postiosoite.maa"
          label="Maa"
          type="text"
          :items="maat"
          item-value="value"
          item-text="selite"
          :menu-props="{ zIndex: '999', auto: true }"
          :rules="[$validationRules.required]"
          class="required"
        />
        <v-select
          v-if="asiakas.id"
          ref="vanhaOsoite"
          v-model="vanhaOsoite"
          label="Hae vanhoista osoitteista"
          item-text="osoite"
          :items="asiakasPostiosoiteItems"
          return-object
        >
          <template #item="{ item }">
            <span>
              <span v-if="item.saaja">{{ item.saaja }},</span>
              {{ item.osoite }}, {{ item.numero }}, {{ item.toimipaikka }}
            </span>
          </template>
        </v-select>
      </v-col>

      <!-- Oikea sarake -->
      <v-col md="6" class="pl-12 pr-4">
        <h3 class="my-8">Yhteystiedot</h3>
        <InputPuhelinNumero
          v-model="asiakas.puhelin.numero"
          :required="false"
          :outlined="false"
        />
        <div v-for="(email, idx) in emailosoitteet" :key="idx">
          <v-text-field
            ref="emailosoitteet[idx]"
            v-model="emailosoitteet[idx]"
            type="email"
            :rules="[$validationRules.email]"
            dense
            label="Sähköpostiosoite"
            placeholder="Kirjoita sähköpostiosoite"
          >
            <template #append>
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    @click="emailLisaa"
                    v-on="on"
                  >
                    add
                  </v-icon>
                </template>
                <span>Lisää sähköpostiosoite</span>
              </v-tooltip>
            </template>
            <template #append-outer>
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    @click="emailPoista(idx)"
                    v-on="on"
                  >
                    delete
                  </v-icon>
                </template>
                <span>Poista sähköpostiosoite</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </div>
        <v-text-field
          v-model="asiakas.verkkolaskuosoite.verkkolaskuosoite"
          label="Verkkolaskuosoite"
          :disabled="asiakas.tyyppi !== 'Y'"
          :error-messages="verkkolaskuosoiteErrors"
        />
        <v-autocomplete
          v-model="asiakas.verkkolaskuosoite.operaattori"
          class="py-1"
          clearable
          :disabled="asiakas.tyyppi !== 'Y'"
          :items="verkkolaskuoperaattorit"
          :filter="verkkolaskuoperaattoriFilter"
          item-text="autocomplete_selite"
          item-value="id"
          label="Verkkolaskuoperaattori"
          :error-messages="verkkolaskuoperaattoriErrors"
        ></v-autocomplete>
        <h3 class="my-8">Laskun lähetyksen lisätiedot</h3>
        <v-select
          v-model="asiakas.lahetystapa"
          label="Lähetystapa"
          type="text"
          :items="lahetystapaSuodatettuItems()"
          item-value="value"
          item-text="label"
          :menu-props="{ zIndex: '999' }"
        />
        <v-text-field
          v-model="asiakas.oletustoimitustapa"
          label="Toimitustapa"
        />
        <v-text-field
          v-model="asiakas.oletusmaksuehto"
          label="Maksuehto"
          suffix="pv netto"
        />
        <v-text-field v-model="asiakas.oletusviitteemme" label="Viitteemme" />
        <v-text-field v-model="asiakas.oletusviitteenne" label="Viitteenne" />
        <v-textarea
          v-model="asiakas.oletusvapaateksti"
          label="Laskulla näkyvä teksti"
          type="text"
          rows="2"
        />
      </v-col>
    </v-row>

    <v-row class="mt-4 pa-3" justify="end">
      <v-btn large class="mr-4" @click="$emit('close')">Peruuta</v-btn>
      <v-btn large color="action" @click="tallenna">Tallenna</v-btn>
    </v-row>
  </v-form>
</template>

<script>
import { Asiakas, AsiakasPostiosoite } from "@/class/Asiakas";
import AutocompletePaamies from "@/components/AutocompletePaamies";
import AutocompleteYTJHaku from "@/components/AutocompleteYTJHaku";
import {
  onkoLahetystapaDisabloitu,
  lahetystavatHintoineen,
} from "@/utils/misc";
import { mapState } from "vuex";
import InputPuhelinNumero from "@/components/InputPuhelinNumero";

export default {
  name: "FormAsiakas",
  components: {
    AutocompletePaamies,
    AutocompleteYTJHaku,
    InputPuhelinNumero,
  },
  props: {
    alustavaAsiakas: {
      type: Asiakas,
      required: false,
      default() {
        return new Asiakas();
      },
    },
    // Jos päämiestä ei anneta, ollaan asiakasnäkymässä luomassa uutta asiakasta
    paamies: {
      type: Object,
      required: false,
      default() {
        return null;
      },
    },
    onAuki: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      asiakas: new Asiakas(),
      asiakasnroErrors: [],
      debounceDelay: 300,
      tarkistaAsiakasnroTimeout: "",
      emailosoitteet: [""],
      ilmoitetutOlemassaOlevatNimet: [],
      kieliItems: [],
      lahetystavatHintoineen: [],
      tunnusErrors: [],
      vanhaOsoite: null,
      verkkolaskuoperaattoriErrors: [],
      verkkolaskuosoiteErrors: [],
    };
  },
  computed: {
    ...mapState({
      verkkolaskuoperaattorit: (state) => state.yleinen.verkkolaskuoperaattorit,
      kielet: (state) => state.yleinen.kielet,
      vastapuolenTyypit: (state) => state.yleinen.vastapuolenTyypit,
      maat: (state) => state.yleinen.maat,
    }),
    asiakasPostiosoiteItems() {
      return this.asiakas.postiosoite_set || [];
    },
  },
  watch: {
    onAuki: {
      immediate: true,
      async handler(val) {
        // Suoritetaan toimenpiteet ainoastaan, jos lomake avataan
        if (!val) return;

        this.$nextTick().then(() => {
          this.$refs.formAsiakas.resetValidation();
        });

        this.asiakas = new Asiakas(structuredClone(this.alustavaAsiakas));

        if (this.asiakas.emailosoite_set.length !== 0) {
          // Täytetään email-kenttä
          this.emailosoitteet = this.asiakas.emailosoite_set.map(
            (eo) => eo.email
          );
        } else {
          this.emailosoitteet = [""];
        }

        if (this.paamies) {
          this.asiakas.paamies = this.paamies;
        } else {
          this.asiakas.paamies = this.$store.state.user.valittuPaamies;
        }
      },
    },
    "asiakas.paamies": {
      immediate: true,
      async handler(val) {
        this.alustaLahetystavatHintoineen();
        if (val?.id) {
          this.asiakas.paamies_id = val.id;
          // Haetaan uudelle asiakkaalle alustava asiakasnumero
          if (!this.asiakas.id) {
            this.asiakas.nro = await this.haeSeuraavaAsiakasnro();
          }
        }
      },
    },
    "asiakas.postiosoite.maa": {
      handler(val) {
        this.alustaLahetystavatHintoineen();
      },
    },
    "asiakas.verkkolaskuosoite": {
      deep: true,
      handler(verkkolaskuosoite) {
        if (
          verkkolaskuosoite.verkkolaskuosoite &&
          !verkkolaskuosoite.operaattori
        ) {
          this.verkkolaskuoperaattoriErrors = [
            "Syötä myös verkkolaskuoperaattori",
          ];
        } else {
          this.verkkolaskuoperaattoriErrors = [];
        }

        if (
          !verkkolaskuosoite.verkkolaskuosoite &&
          verkkolaskuosoite.operaattori
        ) {
          this.verkkolaskuosoiteErrors = ["Syötä myös verkkolaskuosoite"];
        } else {
          this.verkkolaskuosoiteErrors = [];
        }
      },
    },
    vanhaOsoite: {
      handler(val) {
        if (val) {
          this.asiakas.postiosoite = new AsiakasPostiosoite(this.vanhaOsoite);
          this.$nextTick(() => {
            this.vanhaOsoite = "";
            this.$refs.vanhaOsoite.isFocused = false;
          });
        }
      },
    },
  },
  methods: {
    emailLisaa() {
      this.emailosoitteet.push("");
    },
    emailPoista(idx) {
      this.emailosoitteet.splice(idx, 1);
      if (!this.emailosoitteet || this.emailosoitteet.length === 0) {
        this.emailosoitteet = [""];
      }
    },

    async tallenna() {
      if (!this.$refs.formAsiakas.validate()) return;

      if (
        this.emailosoitteet.length < 1 ||
        (this.emailosoitteet.length === 1 && this.emailosoitteet[0] === "")
      ) {
        this.emailosoitteet = [];
      }

      const httpMethod = this.asiakas.id ? "PUT" : "POST";
      const url = this.asiakas.id ? `${this.asiakas.id}/` : "";

      // FIXME Päivittää joka kerta osoitteen, eli muut kentät kuin email nollaantuvat aina
      if (this.emailosoitteet.length) {
        this.asiakas.emailosoite_set = this.emailosoitteet.map((email) => ({
          email,
        }));
      } else {
        this.asiakas.emailosoite_set = [];
      }

      try {
        if (this.asiakas.id) {
          if (!this.emailosoitteet.length) {
            // jos sähköposti tyhjä poistetaan kaikki asiakkaan sähköpostit
            const email_response = await this.$doRequestWithTimeout(
              this.$api.Asiakkaat,
              {
                method: "DELETE",
                url: `${url}poista_emailosoitteet/`,
              }
            );

            if (!email_response.success)
              throw new this.$HttpError(email_response);
          }

          if (!this.asiakas.puhelin.numero) {
            // jos puhelinnumero tyhjä poistetaan kaikki asiakkaan puhelinnumerot
            const puh_response = await this.$doRequestWithTimeout(
              this.$api.Asiakkaat,
              {
                method: "DELETE",
                url: `${url}poista_puhelinnumerot/`,
              }
            );

            if (!puh_response.success) throw new this.$HttpError(puh_response);
          }
        }

        const request = await this.$doRequestWithTimeout(
          this.$api.Asiakkaat,
          {
            method: httpMethod,
            url: url,
            body: this.asiakas.getPostData(),
          },
          "doSingleRequest"
        );

        if (!request.success) throw new this.$HttpError(request);

        this.$emit("asiakasTallennettu", new Asiakas(request.result.body));

        this.$emit("close");
      } catch (e) {
        this.$oletusVirheenkasittely(e, "Asiakkaan tallennus epäonnistui!");
      }
    },
    onkoLahetystapaDisabloitu(lahetystapa, asiakas) {
      if (lahetystapa === "email") {
        return (
          !this.emailosoitteet.length &&
          onkoLahetystapaDisabloitu(lahetystapa, asiakas)
        );
      } else {
        return onkoLahetystapaDisabloitu(lahetystapa, asiakas);
      }
    },
    lahetystapaSuodatettuItems() {
      return this.lahetystavatHintoineen.map((lahetystapa) => {
        return Object.assign(
          {
            disabled: this.onkoLahetystapaDisabloitu(
              lahetystapa.value,
              this.asiakas
            ),
          },
          lahetystapa
        );
      });
    },
    alustaLahetystavatHintoineen() {
      try {
        this.lahetystavatHintoineen = Object.assign(
          [],
          lahetystavatHintoineen(
            this.asiakas.paamies,
            this.asiakas.postiosoite.maa
          )
        );
      } catch (e) {
        this.$oletusVirheenkasittely(e, "Lähetystapojen alustus epäonnistui");
      }
    },
    async haeSeuraavaAsiakasnro() {
      const paamies_id = this.asiakas.paamies_id;
      if (!paamies_id) return;
      const request = await this.$doRequestWithTimeout(
        this.$api.Asiakkaat,
        {
          url: `seuraava_asiakasnro/${paamies_id}/`,
        },
        "pushRequest"
      );

      if (request.success) {
        return request.result.body;
      }
      return "";
    },
    async tarkistaAsiakasnro(nro) {
      clearTimeout(this.tarkistaAsiakasnroTimeout);

      this.asiakasnroErrors = [];

      if (!nro || !this.asiakas.paamies_id) return;

      this.tarkistaAsiakasnroTimeout = setTimeout(async () => {
        const query = new URLSearchParams();
        query.append("method", "tarkista_asiakasnro");
        query.append("nro", nro);
        query.append("paamies_id", this.asiakas.paamies_id);

        const request = await this.$doRequestWithTimeout(
          this.$api.Yleinen,
          {
            url: "tarkista",
            query: query.toString(),
          },
          "pushRequest"
        );

        const asiakasnroVapaana = request.result.body;
        if (!asiakasnroVapaana && this.asiakas.nro) {
          this.asiakasnroErrors = ["Asiakasnumero on jo käytössä"];
        } else {
          this.asiakasnroErrors = [];
        }
      }, this.debounceDelay);
    },
    async tarkistaTunnus(tunnus) {
      this.tunnusErrors = [];

      if (!tunnus || this.asiakas.postiosoite.maa !== "FI") return;

      const query = new URLSearchParams();
      query.append("method", "tarkista_tunnus");
      query.append("tunnus", tunnus);
      query.append("tyyppi", this.asiakas.tyyppi);

      const request = await this.$doRequestWithTimeout(
        this.$api.Yleinen,
        {
          url: "tarkista/",
          query: query.toString(),
        },
        "pushRequest"
      );

      const tunnusOnOikeellinen = request.result.body;
      if (!tunnusOnOikeellinen && this.asiakas.tunnus) {
        this.tunnusErrors = ["Tarkista tunnus"];
      } else {
        this.tunnusErrors = [];
      }
    },
    async tarkistaOlemassaOlevaTunnus(tunnus) {
      if (this.asiakas.id || !this.asiakas.tunnus) return;

      try {
        const query = new URLSearchParams();
        query.append("method", "tarkista_asiakas_tunnus");
        query.append("tunnus", tunnus.trim());
        query.append("paamies_id", this.asiakas.paamies_id);

        const request = await this.$doRequestWithTimeout(
          this.$api.Asiakkaat,
          {
            url: "toiminnot/tarkista/",
            query: query.toString(),
          },
          "pushRequest"
        );
        if (!request.success) throw new this.$HttpError(request);

        const body = request.result.body;

        if (body && body.asiakkaat && body.asiakkaat.length) {
          let asiakkaatStr = "";
          body.asiakkaat.map((asiakas) => {
            asiakkaatStr += `\n- ${asiakas.nimi} (${asiakas.id})`;
          });

          this.$naytaVaroitusilmoitus(
            "Syötetyllä tunnuksella on jo olemassa oleva asiakas:" +
              asiakkaatStr,
            { position: "top-right" }
          );
        }
      } catch (e) {
        this.$sentryCaptureCustom(e);
      }
    },
    /**
     * Tarkistaa onko käyttäjän päämiehien nimissä jo syötetyn kaltainen nimi.
     * Ks. onlinerestapin metodi _tarkista_asiakas_nimi
     */
    async tarkistaOlemassaOlevaNimi(syote) {
      let nimi = syote.trim();
      if (this.asiakas.id || !nimi || nimi.length <= 3) return;

      try {
        const query = new URLSearchParams();
        query.append("method", "tarkista_asiakas_nimi");
        query.append("nimi", nimi);
        query.append("paamies_id", this.asiakas.paamies_id);

        const request = await this.$doRequestWithTimeout(
          this.$api.Asiakkaat,
          {
            url: "toiminnot/tarkista/",
            query: query.toString(),
          },
          "pushRequest"
        );
        if (!request.success) throw new this.$HttpError(request);

        const body = request.result.body;

        if (body && body.asiakkaat && body.asiakkaat.length) {
          let asiakkaatStr = "";
          body.asiakkaat.map((asiakas) => {
            if (!this.ilmoitetutOlemassaOlevatNimet.includes(asiakas.nimi))
              // Ilmoitetaan kustakin nimestä vain kerran, jotta käyttöliittymä ei ole häiritsevä.
              asiakkaatStr += `\n- ${asiakas.nimi} (${
                asiakas.nro ? "nro: " + asiakas.nro : "id: " + asiakas.id
              })`;
            this.ilmoitetutOlemassaOlevatNimet.push(asiakas.nimi);
          });

          if (asiakkaatStr) {
            this.$naytaInfoilmoitus(
              "Samankaltaisella nimellä löytyi olemassa olevia asiakkaita:" +
                asiakkaatStr,
              {
                timeout: 10000,
                position: "top-right",
              }
            );
          }
        }
      } catch (e) {
        this.$sentryCaptureCustom(e);
      }
    },
    tarkistaOlemassaOlevaNimiHenkilo() {
      if (this.asiakas.etunimi && this.asiakas.sukunimi) {
        this.tarkistaOlemassaOlevaNimi(
          `${this.asiakas.sukunimi} ${this.asiakas.etunimi}`
        );
      }
    },
    verkkolaskuoperaattoriFilter(item, hakusana) {
      const id = item.id.toLowerCase();
      const nimi = item.nimi.toLowerCase();
      const hakuteksti = hakusana.toLowerCase();

      return id.indexOf(hakuteksti) > -1 || nimi.indexOf(hakuteksti) > -1;
    },
    asetaYrityksenTiedotLomakkeelle(yritys) {
      this.asiakas = Object.assign(this.asiakas, yritys);
      this.asiakas.postiosoite = new AsiakasPostiosoite(yritys.postiosoite);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-text-field,
.v-select {
  padding-top: 0px;
  margin-top: 4px;
}

.hidden {
  visibility: hidden;
}
</style>
