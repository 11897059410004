var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    { ref: "formAsiakas" },
    [
      _c(
        "v-row",
        [
          _c("AutocompleteYTJHaku", {
            attrs: {
              "input-hint":
                "Hakutuloksen valinta täyttää yritysasiakkaan tiedot lomakkeelle.",
            },
            on: { "autocomplete-input": _vm.asetaYrityksenTiedotLomakkeelle },
          }),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pl-4 pr-12", attrs: { md: "6" } },
            [
              !_vm.$store.state.user.vainYksiPaamies && !_vm.paamies
                ? _c("AutocompletePaamies", {
                    ref: "autocompletePaamies",
                    attrs: {
                      "tarkistettava-oikeus":
                        "onlinerestapi.onlinerestapi_lasku",
                      "validointi-saannot": [
                        _vm.$validationRules.requiredObject,
                      ],
                    },
                    model: {
                      value: _vm.asiakas.paamies,
                      callback: function ($$v) {
                        _vm.$set(_vm.asiakas, "paamies", $$v)
                      },
                      expression: "asiakas.paamies",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pl-4 pr-12", attrs: { md: "6" } },
            [
              _c("h3", { staticClass: "my-8" }, [_vm._v("Asiakastiedot")]),
              _c("v-text-field", {
                attrs: {
                  label: "Asiakasnumero",
                  "error-messages": _vm.asiakasnroErrors,
                },
                on: {
                  input: function ($event) {
                    return _vm.tarkistaAsiakasnro($event)
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "append-outer",
                    fn: function () {
                      return [
                        _c("TooltipInfo", {
                          attrs: {
                            "icon-color": "primary",
                            text: _vm.$t("common:infotekstit.customerNoInfo"),
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.asiakas.nro,
                  callback: function ($$v) {
                    _vm.$set(_vm.asiakas, "nro", $$v)
                  },
                  expression: "asiakas.nro",
                },
              }),
              _c("v-select", {
                staticClass: "required",
                attrs: {
                  label: "Tyyppi",
                  type: "text",
                  items: _vm.vastapuolenTyypit,
                  "item-value": "value",
                  "item-text": "selite",
                  "menu-props": { zIndex: "999" },
                  rules: [_vm.$validationRules.required],
                },
                on: {
                  input: function ($event) {
                    _vm.asiakas.tunnus
                      ? _vm.tarkistaTunnus(_vm.asiakas.tunnus)
                      : () => {}
                  },
                },
                model: {
                  value: _vm.asiakas.tyyppi,
                  callback: function ($$v) {
                    _vm.$set(_vm.asiakas, "tyyppi", $$v)
                  },
                  expression: "asiakas.tyyppi",
                },
              }),
              _c("v-text-field", {
                attrs: {
                  label: "Y-tunnus / Hetu",
                  "error-messages": _vm.tunnusErrors,
                  rules: [!_vm.tunnusErrors.length],
                },
                on: {
                  input: function ($event) {
                    _vm.tunnusErrors.length
                      ? _vm.tarkistaTunnus($event)
                      : () => {}
                  },
                  blur: function ($event) {
                    ;[
                      _vm.tarkistaTunnus($event.target.value),
                      _vm.tarkistaOlemassaOlevaTunnus($event.target.value),
                    ]
                  },
                },
                model: {
                  value: _vm.asiakas.tunnus,
                  callback: function ($$v) {
                    _vm.$set(_vm.asiakas, "tunnus", $$v)
                  },
                  expression: "asiakas.tunnus",
                },
              }),
              _c("v-select", {
                staticClass: "required",
                attrs: {
                  label: "Kieli",
                  type: "text",
                  items: _vm.kielet || [],
                  value: _vm.kielet ? _vm.kielet[0] : "",
                  "item-value": "value",
                  "item-text": "selite",
                  "menu-props": { zIndex: "999" },
                  rules: [_vm.$validationRules.required],
                },
                model: {
                  value: _vm.asiakas.kieli,
                  callback: function ($$v) {
                    _vm.$set(_vm.asiakas, "kieli", $$v)
                  },
                  expression: "asiakas.kieli",
                },
              }),
              _c("h3", { staticClass: "my-8" }, [_vm._v("Osoitetiedot")]),
              _vm.asiakas.tyyppi === "H"
                ? _c(
                    "div",
                    { staticClass: "comboField" },
                    [
                      _c("v-text-field", {
                        staticClass: "required",
                        attrs: {
                          label: "Etunimi",
                          rules: [_vm.$validationRules.required],
                          hint: "Anna yksi tai useampi",
                        },
                        on: { blur: _vm.tarkistaOlemassaOlevaNimiHenkilo },
                        model: {
                          value: _vm.asiakas.etunimi,
                          callback: function ($$v) {
                            _vm.$set(_vm.asiakas, "etunimi", $$v)
                          },
                          expression: "asiakas.etunimi",
                        },
                      }),
                      _c("span", { staticClass: "comboField__separator" }),
                      _c("v-text-field", {
                        staticClass: "required",
                        attrs: {
                          label: "Sukunimi",
                          rules: [_vm.$validationRules.required],
                        },
                        on: { blur: _vm.tarkistaOlemassaOlevaNimiHenkilo },
                        model: {
                          value: _vm.asiakas.sukunimi,
                          callback: function ($$v) {
                            _vm.$set(_vm.asiakas, "sukunimi", $$v)
                          },
                          expression: "asiakas.sukunimi",
                        },
                      }),
                    ],
                    1
                  )
                : _c("v-text-field", {
                    staticClass: "required",
                    attrs: {
                      label: "Nimi",
                      rules: [_vm.$validationRules.required],
                    },
                    on: {
                      blur: function ($event) {
                        return _vm.tarkistaOlemassaOlevaNimi(
                          $event.target.value
                        )
                      },
                    },
                    model: {
                      value: _vm.asiakas.nimi,
                      callback: function ($$v) {
                        _vm.$set(_vm.asiakas, "nimi", $$v)
                      },
                      expression: "asiakas.nimi",
                    },
                  }),
              _c("v-text-field", {
                attrs: { label: "Saaja / Tarkenne" },
                model: {
                  value: _vm.asiakas.postiosoite.saaja,
                  callback: function ($$v) {
                    _vm.$set(_vm.asiakas.postiosoite, "saaja", $$v)
                  },
                  expression: "asiakas.postiosoite.saaja",
                },
              }),
              _c("v-text-field", {
                staticClass: "required",
                attrs: {
                  label: "Lähisoite",
                  rules: [_vm.$validationRules.required],
                },
                model: {
                  value: _vm.asiakas.postiosoite.osoite,
                  callback: function ($$v) {
                    _vm.$set(_vm.asiakas.postiosoite, "osoite", $$v)
                  },
                  expression: "asiakas.postiosoite.osoite",
                },
              }),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "py-1", attrs: { md: "5" } },
                    [
                      _c("v-text-field", {
                        staticClass: "required",
                        attrs: {
                          label: "Postinumero",
                          rules: [_vm.$validationRules.required],
                        },
                        model: {
                          value: _vm.asiakas.postiosoite.numero,
                          callback: function ($$v) {
                            _vm.$set(_vm.asiakas.postiosoite, "numero", $$v)
                          },
                          expression: "asiakas.postiosoite.numero",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-1", attrs: { md: "7" } },
                    [
                      _c("v-text-field", {
                        staticClass: "required",
                        attrs: {
                          label: "Postitoimipaikka",
                          rules: [_vm.$validationRules.required],
                        },
                        model: {
                          value: _vm.asiakas.postiosoite.toimipaikka,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.asiakas.postiosoite,
                              "toimipaikka",
                              $$v
                            )
                          },
                          expression: "asiakas.postiosoite.toimipaikka",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-select", {
                staticClass: "required",
                attrs: {
                  label: "Maa",
                  type: "text",
                  items: _vm.maat,
                  "item-value": "value",
                  "item-text": "selite",
                  "menu-props": { zIndex: "999", auto: true },
                  rules: [_vm.$validationRules.required],
                },
                model: {
                  value: _vm.asiakas.postiosoite.maa,
                  callback: function ($$v) {
                    _vm.$set(_vm.asiakas.postiosoite, "maa", $$v)
                  },
                  expression: "asiakas.postiosoite.maa",
                },
              }),
              _vm.asiakas.id
                ? _c("v-select", {
                    ref: "vanhaOsoite",
                    attrs: {
                      label: "Hae vanhoista osoitteista",
                      "item-text": "osoite",
                      items: _vm.asiakasPostiosoiteItems,
                      "return-object": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item",
                          fn: function ({ item }) {
                            return [
                              _c("span", [
                                item.saaja
                                  ? _c("span", [
                                      _vm._v(_vm._s(item.saaja) + ","),
                                    ])
                                  : _vm._e(),
                                _vm._v(
                                  " " +
                                    _vm._s(item.osoite) +
                                    ", " +
                                    _vm._s(item.numero) +
                                    ", " +
                                    _vm._s(item.toimipaikka) +
                                    " "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      204386074
                    ),
                    model: {
                      value: _vm.vanhaOsoite,
                      callback: function ($$v) {
                        _vm.vanhaOsoite = $$v
                      },
                      expression: "vanhaOsoite",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pl-12 pr-4", attrs: { md: "6" } },
            [
              _c("h3", { staticClass: "my-8" }, [_vm._v("Yhteystiedot")]),
              _c("InputPuhelinNumero", {
                attrs: { required: false, outlined: false },
                model: {
                  value: _vm.asiakas.puhelin.numero,
                  callback: function ($$v) {
                    _vm.$set(_vm.asiakas.puhelin, "numero", $$v)
                  },
                  expression: "asiakas.puhelin.numero",
                },
              }),
              _vm._l(_vm.emailosoitteet, function (email, idx) {
                return _c(
                  "div",
                  { key: idx },
                  [
                    _c("v-text-field", {
                      ref: "emailosoitteet[idx]",
                      refInFor: true,
                      attrs: {
                        type: "email",
                        rules: [_vm.$validationRules.email],
                        dense: "",
                        label: "Sähköpostiosoite",
                        placeholder: "Kirjoita sähköpostiosoite",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "append",
                            fn: function () {
                              return [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                      },
                                                      on: {
                                                        click: _vm.emailLisaa,
                                                      },
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v(" add ")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v("Lisää sähköpostiosoite"),
                                    ]),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "append-outer",
                            fn: function () {
                              return [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.emailPoista(
                                                            idx
                                                          )
                                                        },
                                                      },
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v(" delete ")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v("Poista sähköpostiosoite"),
                                    ]),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.emailosoitteet[idx],
                        callback: function ($$v) {
                          _vm.$set(_vm.emailosoitteet, idx, $$v)
                        },
                        expression: "emailosoitteet[idx]",
                      },
                    }),
                  ],
                  1
                )
              }),
              _c("v-text-field", {
                attrs: {
                  label: "Verkkolaskuosoite",
                  disabled: _vm.asiakas.tyyppi !== "Y",
                  "error-messages": _vm.verkkolaskuosoiteErrors,
                },
                model: {
                  value: _vm.asiakas.verkkolaskuosoite.verkkolaskuosoite,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.asiakas.verkkolaskuosoite,
                      "verkkolaskuosoite",
                      $$v
                    )
                  },
                  expression: "asiakas.verkkolaskuosoite.verkkolaskuosoite",
                },
              }),
              _c("v-autocomplete", {
                staticClass: "py-1",
                attrs: {
                  clearable: "",
                  disabled: _vm.asiakas.tyyppi !== "Y",
                  items: _vm.verkkolaskuoperaattorit,
                  filter: _vm.verkkolaskuoperaattoriFilter,
                  "item-text": "autocomplete_selite",
                  "item-value": "id",
                  label: "Verkkolaskuoperaattori",
                  "error-messages": _vm.verkkolaskuoperaattoriErrors,
                },
                model: {
                  value: _vm.asiakas.verkkolaskuosoite.operaattori,
                  callback: function ($$v) {
                    _vm.$set(_vm.asiakas.verkkolaskuosoite, "operaattori", $$v)
                  },
                  expression: "asiakas.verkkolaskuosoite.operaattori",
                },
              }),
              _c("h3", { staticClass: "my-8" }, [
                _vm._v("Laskun lähetyksen lisätiedot"),
              ]),
              _c("v-select", {
                attrs: {
                  label: "Lähetystapa",
                  type: "text",
                  items: _vm.lahetystapaSuodatettuItems(),
                  "item-value": "value",
                  "item-text": "label",
                  "menu-props": { zIndex: "999" },
                },
                model: {
                  value: _vm.asiakas.lahetystapa,
                  callback: function ($$v) {
                    _vm.$set(_vm.asiakas, "lahetystapa", $$v)
                  },
                  expression: "asiakas.lahetystapa",
                },
              }),
              _c("v-text-field", {
                attrs: { label: "Toimitustapa" },
                model: {
                  value: _vm.asiakas.oletustoimitustapa,
                  callback: function ($$v) {
                    _vm.$set(_vm.asiakas, "oletustoimitustapa", $$v)
                  },
                  expression: "asiakas.oletustoimitustapa",
                },
              }),
              _c("v-text-field", {
                attrs: { label: "Maksuehto", suffix: "pv netto" },
                model: {
                  value: _vm.asiakas.oletusmaksuehto,
                  callback: function ($$v) {
                    _vm.$set(_vm.asiakas, "oletusmaksuehto", $$v)
                  },
                  expression: "asiakas.oletusmaksuehto",
                },
              }),
              _c("v-text-field", {
                attrs: { label: "Viitteemme" },
                model: {
                  value: _vm.asiakas.oletusviitteemme,
                  callback: function ($$v) {
                    _vm.$set(_vm.asiakas, "oletusviitteemme", $$v)
                  },
                  expression: "asiakas.oletusviitteemme",
                },
              }),
              _c("v-text-field", {
                attrs: { label: "Viitteenne" },
                model: {
                  value: _vm.asiakas.oletusviitteenne,
                  callback: function ($$v) {
                    _vm.$set(_vm.asiakas, "oletusviitteenne", $$v)
                  },
                  expression: "asiakas.oletusviitteenne",
                },
              }),
              _c("v-textarea", {
                attrs: {
                  label: "Laskulla näkyvä teksti",
                  type: "text",
                  rows: "2",
                },
                model: {
                  value: _vm.asiakas.oletusvapaateksti,
                  callback: function ($$v) {
                    _vm.$set(_vm.asiakas, "oletusvapaateksti", $$v)
                  },
                  expression: "asiakas.oletusvapaateksti",
                },
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-4 pa-3", attrs: { justify: "end" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "mr-4",
              attrs: { large: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v("Peruuta")]
          ),
          _c(
            "v-btn",
            {
              attrs: { large: "", color: "action" },
              on: { click: _vm.tallenna },
            },
            [_vm._v("Tallenna")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }