var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-content-click": false,
            "max-width": "290",
            "nudge-bottom": "40",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-text-field",
                    _vm._g(
                      {
                        staticClass: "required",
                        attrs: {
                          label: _vm.$t("common:paymentDate"),
                          value: _vm.$dateFnsFormat(_vm.value.maksupaiva),
                          readonly: "",
                          rules: [_vm.$validationRules.required],
                        },
                      },
                      on
                    )
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.maksupaivaMenu,
            callback: function ($$v) {
              _vm.maksupaivaMenu = $$v
            },
            expression: "maksupaivaMenu",
          },
        },
        [
          _c("v-date-picker", {
            attrs: {
              max: _vm.maxDate,
              locale: _vm.$languageLocale(),
              "first-day-of-week": "1",
              "no-title": "",
            },
            on: {
              change: function ($event) {
                _vm.maksupaivaMenu = false
              },
            },
            model: {
              value: _vm.value.maksupaiva,
              callback: function ($$v) {
                _vm.$set(_vm.value, "maksupaiva", $$v)
              },
              expression: "value.maksupaiva",
            },
          }),
        ],
        1
      ),
      _c("v-text-field", {
        staticClass: "required",
        attrs: {
          type: "number",
          label: _vm.$t("common:sum"),
          rules: [
            _vm.$validationRules.required,
            _vm.$validationRules.biggerThanZero,
          ],
        },
        model: {
          value: _vm.value.summa,
          callback: function ($$v) {
            _vm.$set(_vm.value, "summa", $$v)
          },
          expression: "value.summa",
        },
      }),
      _vm.kohdistukset.length
        ? _c("v-select", {
            staticClass: "required",
            attrs: {
              label: _vm.$t("common:formOhisuoritus.alignTransaction"),
              rules: [_vm.$validationRules.requiredObject],
              items: _vm.kohdistukset,
              "item-text": "label",
              "item-value": "value",
              "return-object": "",
              "menu-props": { nudgeBottom: "40" },
              "z-index": "999",
            },
            model: {
              value: _vm.value.kohdistus,
              callback: function ($$v) {
                _vm.$set(_vm.value, "kohdistus", $$v)
              },
              expression: "value.kohdistus",
            },
          })
        : _vm._e(),
      _c("PeruutaHyvaksyPainikerivi", {
        attrs: {
          "submit-disabled": !_vm.valid || _vm.loading,
          "submit-loading": _vm.loading,
        },
        on: {
          close: function ($event) {
            return _vm.$emit("close")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }