import { Decimal } from "decimal.js";
import { addDays, parseISO, format, differenceInDays } from "date-fns";
import Laskurivi from "@/class/Laskurivi";
import { Asiakas, AsiakasPostiosoite } from "@/class/Asiakas";
import { Hinnastokoodi } from "@/enums";

const TILA_LUONNOS = "D";
const TILA_LAHETTAMATTA = "L";
const TILA_AVOINNA = "A";
// Ei ole varsinainen tila, vaan johdettu eräpäivästä backendissä
const TILA_MYOHASSA = "1";
const TILA_MAKSETTU = "M";
// Ei ole varsinainen tila, vaan johdettu suoritusten perusteella backendissä
const TILA_HYVITETTY = "HYVITETTY";
const TILA_PERINNASSA = "P";
const TILA_KESKEYTETTY = "R";
const TILA_PERUTTU = "X";
const TILA_ERAANNYTETTY = "E";
const TILA_KUITTAUKSESSA = "K";
const TILA_MYYTY = "Y";
const TILA_HYVAKSYTTY_MAKSUUN = "H";

const LAHETYSTAPA_POSTI = "posti";
const LAHETYSTAPA_VERKKOLASKU = "verkkolasku";
const LAHETYSTAPA_EMAIL = "email";
const LAHETYSTAPA_MANUAALI = "manuaali";
const LAHETYSTAVAT = Object.freeze([
  {
    value: LAHETYSTAPA_POSTI,
    label: "Posti",
    hinnastokoodi: Hinnastokoodi.POSTI,
    ulkomaahinnastokoodi: Hinnastokoodi.POSTI_ULKOMAA,
    kotimaahinnastokoodi: Hinnastokoodi.POSTI_KOTIMAA,
  },
  {
    value: LAHETYSTAPA_VERKKOLASKU,
    label: "Verkkolasku",
    hinnastokoodi: Hinnastokoodi.VERKKOLASKU,
  },
  {
    value: LAHETYSTAPA_EMAIL,
    label: "Sähköposti",
    hinnastokoodi: Hinnastokoodi.EMAIL,
  },
  {
    value: LAHETYSTAPA_MANUAALI,
    label: "Manuaalinen tulostus",
    hinnastokoodi: Hinnastokoodi.MANUAALI,
  },
]);

// Luokkaa käytetään sekä myynti- että ostolaskujen käsittelyssä
const BaseLasku = class {
  static get LAHETYSTAPA_POSTI() {
    return LAHETYSTAPA_POSTI;
  }

  static get LAHETYSTAPA_VERKKOLASKU() {
    return LAHETYSTAPA_VERKKOLASKU;
  }

  static get LAHETYSTAPA_EMAIL() {
    return LAHETYSTAPA_EMAIL;
  }

  static get LAHETYSTAPA_MANUAALI() {
    return LAHETYSTAPA_MANUAALI;
  }

  static get LAHETYSTAVAT() {
    return LAHETYSTAVAT;
  }

  static get TILA_LUONNOS() {
    return TILA_LUONNOS;
  }

  static get TILA_LAHETTAMATTA() {
    return TILA_LAHETTAMATTA;
  }

  static get TILA_AVOINNA() {
    return TILA_AVOINNA;
  }

  static get TILA_MYOHASSA() {
    return TILA_MYOHASSA;
  }

  static get TILA_MAKSETTU() {
    return TILA_MAKSETTU;
  }

  static get TILA_HYVITETTY() {
    return TILA_HYVITETTY;
  }

  static get TILA_PERINNASSA() {
    return TILA_PERINNASSA;
  }

  static get TILA_KESKEYTETTY() {
    return TILA_KESKEYTETTY;
  }

  static get TILA_PERUTTU() {
    return TILA_PERUTTU;
  }

  static get TILA_ERAANNYTETTY() {
    return TILA_ERAANNYTETTY;
  }

  static get TILA_KUITTAUKSESSA() {
    return TILA_KUITTAUKSESSA;
  }

  static get TILA_MYYTY() {
    return TILA_MYYTY;
  }

  static get TILA_HYVAKSYTTY_MAKSUUN() {
    return TILA_HYVAKSYTTY_MAKSUUN;
  }

  // Read-only -kenttiä ei lähetetä luonnin tai muokkauksen yhteydessä backendiin
  constructor(addEmptyRow = false) {
    this.erapaiva = new Date().toISOString().substr(0, 10);
    this.id = null;
    this.laskuliite_set = []; // Read-only
    this.laskunumero = null;
    this.laskupaiva = new Date().toISOString().substr(0, 10);
    this.luonnos = false; // Read-only
    this.paamies = {};
    this.pm_viite = "";
    this.rivit = [];
    this.tapahtuma_set_laskukirjauksilla = []; // Read-only
    this.tapahtuma_set = []; // Read-only
    this.toimeksianto_set = []; // Read-only
    this.tiedostot = [];
    this.tila = {}; // Read-only
    this.vero = 0; // Read-only
    this.verollinen = 0; // Read-only
    this.veroton = 0; // Read-only
    this.viitteemme = "";
    this.viitteenne = "";

    // Add empty row if requested
    if (addEmptyRow) this.rivit.push(new Laskurivi(Laskurivi.oletusPreset));

    // Calculate reference number
    // this.calculateViitenumero()
  }

  lisaaRivi(rowIndex) {
    if (typeof rowIndex !== "undefined") {
      // Laskurivin lisäys olemassaolevalla rivillä oltaessa käyttäjän painaessa enteriä.
      this.rivit.splice(rowIndex + 1, 0, new Laskurivi(Laskurivi.oletusPreset));
    } else {
      this.rivit.push(new Laskurivi(Laskurivi.oletusPreset));
    }
  }

  poistaRivi(rowIndex) {
    this.rivit.splice(rowIndex, 1);
  }

  calculateViitenumero() {
    if (this.pm_viite) return;

    try {
      const laskunumero = this.laskunumero ? this.laskunumero.toString() : null;

      if (!laskunumero) throw new Error("Laskunumero puuttuu");

      const painot = [7, 3, 1];
      let paino = 0;
      let summa = 0;

      laskunumero
        .split("")
        .reverse()
        .forEach((n) => {
          if (isNaN(n))
            throw new Error(
              "Laskunumerossa on muita kuin numeraalisia merkkejä"
            );

          summa += n * painot[paino];
          paino = (paino + 1) % 3;
        });

      const viitenumero = parseInt(
        laskunumero + String((10 - (summa % 10)) % 10)
      );

      if (!viitenumero)
        throw new Error("Viitenumeron parsiminen laskunumerosta epäonnistui");

      this.pm_viite = viitenumero;
    } catch (error) {
      this.pm_viite = "";
    }
  }

  calculateTotals() {
    // eslint-disable-next-line
    return new Promise(async (resolve) => {
      var veroton = 0;
      var vero = 0;
      var verollinen = 0;

      for (const row of this.rivit) {
        await row.calculateTotals();

        veroton = new Decimal(veroton).plus(row.veroton);
        vero = new Decimal(vero).plus(row.vero);
        verollinen = new Decimal(verollinen).plus(row.verollinen);
      }

      this.veroton = veroton.toFixed(2);
      this.vero = vero.toFixed(2);
      this.verollinen = verollinen.toFixed(2);

      resolve();
    });
  }

  calculateErapaiva() {
    if (
      isNaN(parseInt(this.maksuehto)) ||
      !/^([0-9])+$/.test(this.maksuehto) ||
      !this.laskupaiva ||
      (!this.maksuehto && this.maksuehto !== 0) ||
      this.maksuehto < 0
    ) {
      return;
    }

    const erapaiva = addDays(parseISO(this.laskupaiva), this.maksuehto);

    this.erapaiva = format(erapaiva, "yyyy-MM-dd");
  }

  calculateMaksuehto() {
    if (!this.erapaiva || !this.laskupaiva) return;

    this.maksuehto = differenceInDays(
      parseISO(this.erapaiva),
      parseISO(this.laskupaiva)
    );
    if (this.maksuehto === 0) {
      this.maksuehto = this.maksuehto.toString();
    }
  }

  getPostData() {
    const data = {
      erapaiva: this.erapaiva,
      laskupaiva: this.laskupaiva,
      laskusisalto_set: this.rivitPostDataMuotoon(),
      paamies_id: this.paamies.id,
      pm_viite: this.pm_viite,
      viitteemme: this.viitteemme,
      viitteenne: this.viitteenne,
    };

    return data;
  }

  rivitPostDataMuotoon() {
    const rivitPostData = [];
    for (const r of this.rivit) {
      rivitPostData.push(r.getPostData());
    }

    return rivitPostData;
  }
};

export const Lasku = class Lasku extends BaseLasku {
  constructor(addEmptyRow = false) {
    super(addEmptyRow);
    this.asiakas = new Asiakas();
    this.haluttu_lahetyspaiva = null;
    this.sms_muistutus_viive = null;
    this.kirjepohja_id = "";
    this.lahetystapa = LAHETYSTAPA_POSTI;
    this.laskunumeroDisabloitu = true; // Read-only
    this.maksuehto = 14;
    this.maksutapa = null;
    this.myyja_id = "";
    this.toimitusosoite = new AsiakasPostiosoite();
    this.toimitustapa = "";
    this.vapaateksti = ""; // Laskulla näkyvä teksti
    this.viivastyskorko = null;

    // Set due date
    if (this.laskupaiva && this.maksuehto) {
      const erapaiva = addDays(parseISO(this.laskupaiva), this.maksuehto);

      this.erapaiva = format(erapaiva, "yyyy-MM-dd");
    }
  }

  getPostData() {
    const data = super.getPostData();

    data.asiakas_id = this.asiakas.id;
    data.maksuehto = this.maksuehto;
    data.myyja_id = this.myyja_id ? this.myyja_id : null;
    data.toimitustapa = this.toimitustapa;
    data.vapaateksti = this.vapaateksti;
    if (this.viivastyskorko && typeof this.viivastyskorko === "string") {
      data.viivastyskorko = this.viivastyskorko.replace(",", ".");
    } else {
      data.viivastyskorko = this.viivastyskorko;
    }

    if (this.haluttu_lahetyspaiva)
      data.haluttu_lahetyspaiva = this.haluttu_lahetyspaiva;
    if (this.sms_muistutus_viive)
      data.sms_muistutus_viive = this.sms_muistutus_viive;
    if (!this.laskunumeroDisabloitu && this.laskunumero) {
      data.nro = this.laskunumero;
    }
    if (this.lahetystapa) {
      if (this.lahetystapa === "verkkolasku" && this.asiakas.tyyppi === "H") {
        data.lahetystapa = "verkkolasku_b2c";
      } else {
        data.lahetystapa = this.lahetystapa;
      }
    }
    if (this.kirjepohja_id) data.kirjepohja_id = this.kirjepohja_id;
    if (this.maksutapa && !this.lahetystapa) data.maksutapa = this.maksutapa;
    if (this.toimitusosoite.osoite)
      data.toimitusosoite = this.toimitusosoite.getPostData();

    return data;
  }

  onMuokattavissa() {
    return [TILA_LUONNOS, TILA_LAHETTAMATTA].includes(this.tila.value);
  }
};

export const Ostolasku = class Ostolasku extends BaseLasku {
  constructor(addEmptyRow = false) {
    super(addEmptyRow);
    this.epitieto = {};
    this.laskunKuva = null;
    this.maksuehto = null;
    this.toimittaja = {};
    this.viite = null;
    this.viivastyskorko = null;
  }

  getPostData() {
    const data = super.getPostData();

    data.toimittaja_id = this.toimittaja.id;
    if (!this.maksuehto) {
      this.calculateMaksuehto();
    }
    data.maksuehto = this.maksuehto;
    data.nro = this.laskunumero;
    data.epitieto = this.epitieto;
    data.epitieto.saaja = this.epitieto.saaja
      ? this.epitieto.saaja
      : this.toimittaja.nimi;
    data.epitieto.summa = this.verollinen; // Lasketaan automaattisesti rivien perusteella

    return data;
  }

  onMuokattavissa() {
    return this.tila.value === TILA_AVOINNA;
  }
};
