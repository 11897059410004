var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-tooltip", {
    staticClass: "tooltip",
    attrs: { disabled: !_vm.tooltipTeksti || !_vm.disabled, top: "" },
    scopedSlots: _vm._u(
      [
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "div",
                _vm._g({}, on),
                [
                  _c(
                    "v-btn",
                    {
                      ref: _vm.reference,
                      staticClass: "ml-2",
                      attrs: {
                        color: _vm.color,
                        disabled: _vm.disabled,
                        loading: _vm.loading,
                        to: _vm.to,
                      },
                      on: { click: _vm.click },
                    },
                    [
                      _vm.icon
                        ? _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v(" " + _vm._s(_vm.icon) + " "),
                          ])
                        : _vm._e(),
                      _vm._t("default", function () {
                        return [
                          _vm._v(" " + _vm._s(_vm.$t("common:button")) + " "),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]
          },
        },
        _vm.tooltipTeksti && _vm.disabled
          ? {
              key: "default",
              fn: function () {
                return [_vm._v(" " + _vm._s(_vm.tooltipTeksti) + " ")]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }