import Vue from "vue";
import Dinero from "dinero.js";
import { Decimal } from "decimal.js";

export default function formatSumToFixed2(sum) {
  if (sum === null || typeof sum == "undefined" || isNaN(sum)) return null;

  sum = sum || 0;

  const sumDecimal = new Decimal(sum).toFixed(2);
  const cents = Decimal.mul(sumDecimal, 100);

  return Dinero({
    amount: cents.toNumber(),
  }).toFormat();
}

Vue.filter("formatSumToFixed2", formatSumToFixed2);
