var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.value
    ? _c(
        "li",
        { staticClass: "inlineList__item" },
        [
          _vm.to
            ? _c(
                "router-link",
                { staticClass: "inlineList__link", attrs: { to: _vm.to } },
                [_vm._v(" " + _vm._s(_vm.value) + " ")]
              )
            : [_vm._v(" " + _vm._s(_vm.value) + " ")],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }