<template>
  <v-row justify="center" class="pa-6">
    <span class="text-h6">
      {{ otsikko }}
    </span>
  </v-row>
</template>

<script>
export default {
  props: {
    otsikko: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
