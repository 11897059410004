<template>
  <v-form
    ref="formSopimusLaskusisalto"
    v-model="valid"
    @submit.prevent="submit"
  >
    <LaskuriviTable
      ref="laskuriviTable"
      :lasku="value"
      :nayta-yhteenveto="false"
      :salli-poistaminen="false"
      tyyppi="sopimus"
    />
    <v-row class="mt-4 px-3" align="end" justify="space-between">
      <v-col cols="8" class="pb-0">
        <AutocompleteLaskurivi
          class="mb-n4"
          :lasku="value"
          tyyppi="sopimus"
          :input-hint="$t('common:addRowInfo')"
          @autocomplete-input="kasitteleAutocompleteValinta"
        />
      </v-col>
      <v-col cols="4" class="pb-0">
        <v-row justify="end">
          <v-btn @click="$emit('close')">Peruuta</v-btn>
          <v-btn
            type="submit"
            color="action"
            class="ml-4"
            :disabled="!valid || loading"
            :loading="loading"
          >
            {{ tallennuspainikkeenTeksti }}
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";
import AutocompleteLaskurivi from "./AutocompleteLaskurivi.vue";
import LaskuriviTable from "./LaskuriviTable.vue";
import { Kirjoitusmoodit } from "@/utils/constants";

export default {
  name: "FormSopimusLaskusisalto",
  components: {
    AutocompleteLaskurivi,
    LaskuriviTable,
  },
  mixins: [FormMixin],
  props: {
    kirjoitusmoodi: {
      type: String,
      required: true,
      validator: (arvo) =>
        [Kirjoitusmoodit.LUOMINEN, Kirjoitusmoodit.MUOKKAUS].includes(arvo),
    },
  },
  computed: {
    tallennuspainikkeenTeksti() {
      return this.kirjoitusmoodi === Kirjoitusmoodit.LUOMINEN
        ? this.$t("common:lasku.createInvoiceRow")
        : this.$t("common:saveChanges");
    },
  },
  watch: {
    "value.rivit": {
      deep: true,
      handler() {
        if (this.value.rivit.length) this.value.rivit[0].calculateTotals();
      },
    },
  },
  methods: {
    kasitteleAutocompleteValinta() {
      this.value.rivit.splice(0, 1);
    },
    validate() {
      return (
        this.$refs.formSopimusLaskusisalto.validate() &&
        this.$refs.laskuriviTable.validate()
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
