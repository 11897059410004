<template>
  <v-autocomplete
    v-model="autocompleteValue"
    class="pagination-hyppaa-autocomplete"
    auto-select-first
    dense
    outlined
    :items="autocompleteItems"
    hide-details
    :hide-no-data="true"
    :placeholder="$t('common:jump')"
    append-icon="redo"
    :menu-props="{ maxHeight: 54 }"
    @input="kasitteleSivunValinta"
  />
</template>

<script>
export default {
  name: "PaginationHyppaaAutocomplete",
  props: {
    pages: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      autocompleteValue: null,
    };
  },
  computed: {
    autocompleteItems() {
      return Array.from({ length: this.pages }, (arrayLike, x) => x + 1);
    },
  },
  methods: {
    kasitteleSivunValinta() {
      this.$emit("autocomplete-input", this.autocompleteValue);
      this.$nextTick(() => {
        this.autocompleteValue = null;
      });
    },
  },
};
</script>

<style lang="scss">
.pagination-hyppaa-autocomplete {
  width: 116px;
  flex: none;

  &.v-autocomplete.v-select--is-menu-active .v-input__icon--append .v-icon {
    // Estetään ikonin pyörähtäminen
    transform: none;
  }
}
</style>
