var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "form",
      staticClass: "white--text",
      on: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "px-8 py-4", attrs: { md: "4" } },
            [
              _c(
                "FormTable",
                [
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c(
                            "FormTableLabel",
                            { attrs: { for: "sopimusnumero" } },
                            [_vm._v("Sopimusnumero")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "sopimusnumero",
                              color: "white",
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.sopimusnumero,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "sopimusnumero", $$v)
                              },
                              expression: "value.sopimusnumero",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c("FormTableLabel", { attrs: { for: "tila" } }, [
                            _vm._v("Tila"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-select", {
                            attrs: {
                              id: "tila",
                              items: _vm.tilat,
                              "item-text": "label",
                              "item-value": "terms",
                              multiple: "",
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.tila,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "tila", $$v)
                              },
                              expression: "value.tila",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c("FormTableLabel", { attrs: { for: "asiakas" } }, [
                            _vm._v("Asiakas"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "asiakas",
                              color: "white",
                              placeholder: _vm.$t(
                                "common:formSearchSopimus.nameIdCustomerNo"
                              ),
                              "persistent-placeholder": "",
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.asiakas,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "asiakas", $$v)
                              },
                              expression: "value.asiakas",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  !_vm.$store.state.user.vainYksiPaamies
                    ? _c(
                        "FormTableRow",
                        [
                          _c(
                            "FormTableCell",
                            { attrs: { header: true } },
                            [
                              _c(
                                "FormTableLabel",
                                { attrs: { for: "paamiehet" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("common:creditors")) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "FormTableCell",
                            [
                              _c(
                                "v-theme-provider",
                                { attrs: { dark: "" } },
                                [
                                  _c("AutocompletePaamies", {
                                    attrs: {
                                      multiple: true,
                                      label: "",
                                      "tarkistettava-oikeus":
                                        "onlinerestapi.onlinerestapi_sopimus",
                                    },
                                    model: {
                                      value: _vm.value.paamies,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.value, "paamies", $$v)
                                      },
                                      expression: "value.paamies",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c("FormTableLabel", { attrs: { for: "viite" } }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("common:invoiceReference")) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "viite",
                              type: "number",
                              color: "white",
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.viite,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "viite", $$v)
                              },
                              expression: "value.viite",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "px-8 py-4", attrs: { md: "4" } },
            [
              _c(
                "FormTable",
                [
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c(
                            "FormTableLabel",
                            { attrs: { for: "viitteemme" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("common:ourReference")) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "viitteemme",
                              type: "text",
                              color: "white",
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.viitteemme,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "viitteemme", $$v)
                              },
                              expression: "value.viitteemme",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c(
                            "FormTableLabel",
                            { attrs: { for: "viitteenne" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("common:yourReference")) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "viitteenne",
                              type: "text",
                              color: "white",
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.viitteenne,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "viitteenne", $$v)
                              },
                              expression: "value.viitteenne",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c(
                            "FormTableLabel",
                            { attrs: { for: "toimittaja" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("common:validity")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.voimassaolot,
                              "item-text": "label",
                              "item-value": "terms",
                              dark: true,
                              multiple: "",
                            },
                            model: {
                              value: _vm.value.voimassa,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "voimassa", $$v)
                              },
                              expression: "value.voimassa",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c(
                            "FormTableLabel",
                            { attrs: { for: "alkamispaiva" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("common:startingDate")) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("FormTableCell", [
                        _c(
                          "div",
                          { staticClass: "comboField" },
                          [
                            _c("InputfieldDatePicker", {
                              attrs: {
                                id: "alkamispaivaAlkaen",
                                label: _vm.$t("common:starting"),
                                color: "white",
                                dark: true,
                              },
                              model: {
                                value: _vm.value.alkamispaivaAlkaen,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "alkamispaivaAlkaen", $$v)
                                },
                                expression: "value.alkamispaivaAlkaen",
                              },
                            }),
                            _c(
                              "span",
                              { staticClass: "comboField__separator" },
                              [_vm._v("–")]
                            ),
                            _c("InputfieldDatePicker", {
                              attrs: {
                                id: "alkamispaivaPaattyen",
                                label: _vm.$t("common:ending"),
                                color: "white",
                                dark: true,
                              },
                              model: {
                                value: _vm.value.alkamispaivaPaattyen,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.value,
                                    "alkamispaivaPaattyen",
                                    $$v
                                  )
                                },
                                expression: "value.alkamispaivaPaattyen",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c(
                            "FormTableLabel",
                            { attrs: { for: "loppumispaiva" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("common:endingDate")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("FormTableCell", [
                        _c(
                          "div",
                          { staticClass: "comboField" },
                          [
                            _c("InputfieldDatePicker", {
                              attrs: {
                                id: "loppumispaivaAlkaen",
                                label: _vm.$t("common:starting"),
                                color: "white",
                                dark: true,
                              },
                              model: {
                                value: _vm.value.loppumispaivaAlkaen,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.value,
                                    "loppumispaivaAlkaen",
                                    $$v
                                  )
                                },
                                expression: "value.loppumispaivaAlkaen",
                              },
                            }),
                            _c(
                              "span",
                              { staticClass: "comboField__separator" },
                              [_vm._v("–")]
                            ),
                            _c("InputfieldDatePicker", {
                              attrs: {
                                id: "loppumispaivaPaattyen",
                                label: _vm.$t("common:ending"),
                                color: "white",
                                dark: true,
                              },
                              model: {
                                value: _vm.value.loppumispaivaPaattyen,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.value,
                                    "loppumispaivaPaattyen",
                                    $$v
                                  )
                                },
                                expression: "value.loppumispaivaPaattyen",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "px-8 py-4", attrs: { md: "4" } },
            [
              _c(
                "FormTable",
                [
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c(
                            "FormTableLabel",
                            { attrs: { for: "maksuehto" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("common:termsOfPayment")) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "maksuehto",
                              type: "text",
                              color: "white",
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.maksuehto,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "maksuehto", $$v)
                              },
                              expression: "value.maksuehto",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c(
                            "FormTableLabel",
                            { attrs: { for: "lainatyyppi" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("common:formSearchSopimus.loanType")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "lainatyyppi",
                              type: "text",
                              color: "white",
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.lainatyyppi,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "lainatyyppi", $$v)
                              },
                              expression: "value.lainatyyppi",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c(
                            "FormTableLabel",
                            { attrs: { for: "sopimuslaji" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("common:typeOfContract")) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "sopimuslaji",
                              type: "text",
                              color: "white",
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.sopimuslaji,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "sopimuslaji", $$v)
                              },
                              expression: "value.sopimuslaji",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }