var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "raportti" }, [
    _vm.data.type === "41"
      ? _c(
          "div",
          { staticClass: "r-henkilo" },
          [
            _vm._m(0),
            _c("div", { staticClass: "h-perustiedot" }, [
              _c("h3", [_vm._v("Perustiedot")]),
              _c("div", { staticClass: "data-groups" }, [
                _vm._m(1),
                _c("div", { staticClass: "p-group" }, [
                  _c("div", [
                    _vm._v(_vm._s(_vm.$dateFnsFormat(_vm.data.pvm) || "-")),
                  ]),
                  _c("div", [_vm._v(_vm._s(_vm.data.tarkoitus || "-"))]),
                  _c("div", [_vm._v(_vm._s(_vm.data.hetu || "-"))]),
                  _c("div", [_vm._v(_vm._s(_vm.data.nimi || "-"))]),
                  _c("div", [
                    _vm.data.osoite
                      ? _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                `${_vm.data.osoite?.street}, ${_vm.data.osoite?.zip} ${_vm.data.osoite?.town}`
                              ) +
                              " "
                          ),
                        ])
                      : _c("div", [_vm._v("Osoitetietoja ei löydy")]),
                  ]),
                  _c("div", [
                    _vm.data.yritysyhteyksia
                      ? _c("div", [_vm._v("Kyllä")])
                      : _c("div", [_vm._v("Ei")]),
                  ]),
                  _c("div", [
                    _vm.data.liiketoimintakielto
                      ? _c("div", [_vm._v("Kyllä")])
                      : _c("div", [_vm._v("Ei")]),
                  ]),
                  _c("div", [
                    _vm.data.edunvalvonta
                      ? _c("div", [_vm._v("Kyllä")])
                      : _c("div", [_vm._v("Ei")]),
                  ]),
                ]),
              ]),
            ]),
            _c("v-divider"),
            _c(
              "div",
              { staticClass: "raportti-merkinnat" },
              [
                _c("h3", [_vm._v("Merkinnät")]),
                _vm._l(_vm.data.merkinnat, function (m, i) {
                  return _c("div", { key: i, staticClass: "merkinnat-card" }, [
                    _c(
                      "div",
                      { staticClass: "mcard-header" },
                      [
                        _c("div", { staticClass: "custom-chip" }, [
                          _vm._v(
                            " " +
                              _vm._s(m.creditInformationEntryCode || "-") +
                              " "
                          ),
                        ]),
                        _c("div", [
                          _c("b", [
                            _vm._v(_vm._s(m.creditInformationEntryText || "-")),
                          ]),
                        ]),
                        m.amountOfPaymentDefault
                          ? _c("div", [
                              _vm._v(
                                " (" +
                                  _vm._s(m.amountOfPaymentDefault || "-") +
                                  " " +
                                  _vm._s(_vm.data.valuutta || "-") +
                                  ") "
                              ),
                            ])
                          : _vm._e(),
                        _c("v-spacer"),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "mcard-content" }, [
                      _c("div", [
                        _c("div", { staticClass: "mini-header" }, [
                          _vm._v("Rekisteröinti pvm"),
                        ]),
                        _c("div", { staticClass: "mini-content" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$dateFnsFormat(
                                  m.creditInformationEntryDate
                                ) || "-"
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", [
                        _c("div", { staticClass: "mini-header" }, [
                          _vm._v("Merkintä poistuu"),
                        ]),
                        _c("div", { staticClass: "mini-content" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$dateFnsFormat(
                                  m.creditInformationEntryExpires
                                ) || "-"
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", [
                        _c("div", { staticClass: "mini-header" }, [
                          _vm._v("Selite"),
                        ]),
                        _c("div", { staticClass: "mini-content" }, [
                          _vm._v(" " + _vm._s(m.debtTypeText || "-") + " "),
                        ]),
                      ]),
                      _c("div", [
                        _c("div", { staticClass: "mini-header" }, [
                          _vm._v("Ref"),
                        ]),
                        _c("div", { staticClass: "mini-content" }, [
                          _vm._v(
                            " " +
                              _vm._s(m.ref === "0" || null ? "-" : m.ref) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", [
                        _c("div", { staticClass: "mini-header" }, [
                          _vm._v("Velkoja"),
                        ]),
                        _c("div", { staticClass: "mini-content" }, [
                          _vm._v(_vm._s(m.creditor || "-")),
                        ]),
                      ]),
                      _c("div", [
                        _c("div", { staticClass: "mini-header" }, [
                          _vm._v("Lähde"),
                        ]),
                        _c("div", { staticClass: "mini-content" }, [
                          _vm._v(
                            " " + _vm._s(m.sourceOfInformation || "-") + " "
                          ),
                        ]),
                      ]),
                    ]),
                  ])
                }),
              ],
              2
            ),
            _c("v-divider"),
            _c(
              "div",
              { staticClass: "raportti-yritysyhteydet" },
              [
                _c("h3", [_vm._v("Yritysyhteydet")]),
                _vm._l(_vm.data.yritysyhteydet, function (y, i) {
                  return _c(
                    "div",
                    { key: i, staticClass: "yritysyhteydet-card" },
                    [
                      _c("div", { staticClass: "ycard-header" }, [
                        _c("div", [
                          _c("b", [
                            _vm._v(
                              _vm._s(y.companyIdentificationData.name || "-")
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "ycard-content" }, [
                        _c("div", [
                          _c("div", [_vm._v("Rooli")]),
                          _c("div", [
                            _vm._v(
                              _vm._s(y.positionInCompany.positionText || "-")
                            ),
                          ]),
                        ]),
                        _c("div", [
                          _c("div", [_vm._v("Yhtiömuoto")]),
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                y.companyIdentificationData.companyForm || "-"
                              )
                            ),
                          ]),
                        ]),
                        _c("div", [
                          _c("div", [_vm._v("Yrityksen tila")]),
                          _c("div", [
                            y.positionInCompany.endDate
                              ? _c("div", [_vm._v("Ei Aktiivinen")])
                              : _c("div", [_vm._v("Aktiivinen")]),
                          ]),
                        ]),
                        _c("div", [
                          _c("div", [_vm._v("Viimeisin merkintä")]),
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                y.companyPaymentDefaults.latestExecution || "-"
                              )
                            ),
                          ]),
                        ]),
                        _c("div", [
                          _c("div", [_vm._v("Päiväys")]),
                          _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$dateFnsFormat(
                                    y.companyPaymentDefaults.executionDate
                                  ) || "-"
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  )
                }),
              ],
              2
            ),
          ],
          1
        )
      : _vm.data.type === "Q057"
      ? _c("div", { staticClass: "r-yritys" }, [
          _vm._m(2),
          _c("div", { staticClass: "y-yksilointi" }, [
            _c("h3", [_vm._v("Yksilöintitiedot")]),
            _c("div", { staticClass: "data-groups" }, [
              _vm._m(3),
              _c("div", [
                _c("div", [_vm._v(_vm._s(_vm.data.yksilointi.nimi || "-"))]),
                _c("div", [
                  _vm._v(_vm._s(_vm.data.yksilointi.yritysmuoto || "-")),
                ]),
                _c("div", [
                  _vm._v(_vm._s(_vm.data.yksilointi.kotipaikka || "-")),
                ]),
                _c("div", [_vm._v(_vm._s(_vm.data.yksilointi.kieli || "-"))]),
                _c("div", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.data.yksilointi.paatoimiala.koodi || "-") +
                      " " +
                      _vm._s(_vm.data.yksilointi.paatoimiala.teksti || "-") +
                      " "
                  ),
                ]),
                _c("div", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.data.yksilointi.kayntiosoite.katu || "-") +
                      ", " +
                      _vm._s(_vm.data.yksilointi.kayntiosoite.zip || "-") +
                      " " +
                      _vm._s(_vm.data.yksilointi.kayntiosoite.kaupunki || "-") +
                      " "
                  ),
                ]),
                _c("div", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.data.yksilointi.postiosoite.katu || "-") +
                      ", " +
                      _vm._s(_vm.data.yksilointi.postiosoite.zip || "-") +
                      " " +
                      _vm._s(_vm.data.yksilointi.postiosoite.kaupunki || "-") +
                      " "
                  ),
                ]),
                _c("div", [
                  _vm._v(_vm._s(_vm.data.yksilointi.yhteystiedot.email || "-")),
                ]),
                _c("div", [
                  _vm._v(
                    _vm._s(_vm.data.yksilointi.yhteystiedot.puhelin || "-")
                  ),
                ]),
                _c("div", [
                  _vm._v(_vm._s(_vm.data.yksilointi.yhteystiedot.fax || "-")),
                ]),
                _c("div", [
                  _vm._v(_vm._s(_vm.data.yksilointi.yhteystiedot.www || "-")),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "y-perus" }, [
            _c("h3", [_vm._v("Perustiedot")]),
            _c("div", { staticClass: "data-groups" }, [
              _vm._m(4),
              _c("div", [
                _c("div", [
                  _vm._v(_vm._s(_vm.data.perus.sektoriuokitus || "-")),
                ]),
                _c("div", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.combineData(
                          [
                            _vm.data.perus.liikevaihtoluokka.min,
                            "000",
                            "-",
                            _vm.data.perus.liikevaihtoluokka.max,
                            "000",
                            _vm.data.perus.valuutta,
                          ],
                          " "
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("div", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.combineData(
                          [
                            _vm.data.perus.henkilostoluokka.min,
                            "-",
                            _vm.data.perus.henkilostoluokka.max,
                          ],
                          " "
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("div", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.data.perus.osakepaaoma || "-") +
                      " " +
                      _vm._s(_vm.data.perus.valuutta || "-") +
                      " "
                  ),
                ]),
                _c("div", [
                  _vm._v(_vm._s(_vm.data.perus.osakkeidenLkm || "-")),
                ]),
                _c("div", [
                  _vm._v(_vm._s(_vm.data.perus.tuontitoimintaa || "-")),
                ]),
                _c("div", [
                  _vm._v(_vm._s(_vm.data.perus.vientitoimintaa || "-")),
                ]),
                _c("div", [
                  _vm._v(_vm._s(_vm.data.perus.omistustyyppi || "-")),
                ]),
                _c("div", [
                  _vm._v(
                    _vm._s(_vm.$dateFnsFormat(_vm.data.perus.pvmAloitus) || "-")
                  ),
                ]),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "rekisteri" },
            [
              _c("h3", [_vm._v("Rekisteritiedot")]),
              _c("v-simple-table", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [_vm._v("Rekisteri")]),
                            _c("th", [_vm._v("Tila")]),
                            _c("th", [_vm._v("Pvm")]),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          _vm._l(_vm.data.rekisteri, function (item) {
                            return _c("tr", { key: item.nimi }, [
                              _c("td", [_vm._v(_vm._s(item.nimi || "-"))]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    item.tila == "1"
                                      ? "Aktiivinen"
                                      : "Ei Aktiivinen"
                                  )
                                ),
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.$dateFnsFormat(item.pvm) || "-")
                                ),
                              ]),
                            ])
                          }),
                          0
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "y-maksuhairiot" },
            [
              _c("h3", [_vm._v("Maksuhäiriöt")]),
              _c("v-simple-table", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [_vm._v("Pvm")]),
                            _c("th", [_vm._v("Koodi")]),
                            _c("th", [_vm._v("Summa")]),
                            _c("th", [_vm._v("Velkoja")]),
                            _c("th", [_vm._v("Selite")]),
                            _c("th", [_vm._v("Ref")]),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          _vm._l(_vm.data.luottohairio, function (item) {
                            return _c("tr", { key: item.skey }, [
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$dateFnsFormat(
                                      item.paymentDefaultDate
                                    ) || "-"
                                  )
                                ),
                              ]),
                              _c("td", [
                                _vm._v(_vm._s(item.paymentDefaultCode || "-")),
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(item.amountOfPaymentDefault || "-")
                                ),
                              ]),
                              _c("td", [_vm._v(_vm._s(item.creditor || "-"))]),
                              _c("td", [
                                _vm._v(_vm._s(item.paymentDefaultText || "-")),
                              ]),
                              _c("td", [_vm._v(_vm._s(item.ref || "-"))]),
                            ])
                          }),
                          0
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
          _c("div"),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "h-header" }, [
      _c("h2", [_vm._v("Yksityishenkilön luottotietoraportti")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "p-group" }, [
      _c("div", [_c("b", [_vm._v("Päivämäärä:")])]),
      _c("div", [_c("b", [_vm._v("Kyselyn Tarkoitus:")])]),
      _c("div", [_c("b", [_vm._v("Henkilötunnus:")])]),
      _c("div", [_c("b", [_vm._v("Nimi:")])]),
      _c("div", [_c("b", [_vm._v("Osoite:")])]),
      _c("div", [_c("b", [_vm._v("Yritysyhteyksiä:")])]),
      _c("div", [_c("b", [_vm._v("Liiketoimintakiellossa:")])]),
      _c("div", [_c("b", [_vm._v("Edunvalvonnassa:")])]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "y-header" }, [
      _c("h2", [_vm._v("Yrityksen luottotietoraportti")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "key-group" }, [
      _c("div", [_vm._v("Nimi:")]),
      _c("div", [_vm._v("Yritysmuoto:")]),
      _c("div", [_vm._v("Kotipaikka:")]),
      _c("div", [_vm._v("Kieli:")]),
      _c("div", [_vm._v("Päätoimiala:")]),
      _c("div", [_vm._v("Käyntiosoite:")]),
      _c("div", [_vm._v("Postiosoite:")]),
      _c("div", [_vm._v("Sähköposti:")]),
      _c("div", [_vm._v("Puhelin:")]),
      _c("div", [_vm._v("Faksi:")]),
      _c("div", [_vm._v("Nettisivut:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "key-group" }, [
      _c("div", [_vm._v("Sektoriluokitus:")]),
      _c("div", [_vm._v("Liikevaihtoluokka:")]),
      _c("div", [_vm._v("Henkilöstöluokka:")]),
      _c("div", [_vm._v("Osakepääoma:")]),
      _c("div", [_vm._v("Osakkeiden lkm:")]),
      _c("div", [_vm._v("Tuontitoimintaa:")]),
      _c("div", [_vm._v("Vientitoimintaa:")]),
      _c("div", [_vm._v("Omistustyyppi:")]),
      _c("div", [_vm._v("Perustettu:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }