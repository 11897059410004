var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.btn
        ? _c(
            "v-btn",
            {
              attrs: { color: "action" },
              on: {
                click: function ($event) {
                  return _vm.avaaSopimusDialog()
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("common:openContract")) + " ")]
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: {
            persistent: "",
            fullscreen: _vm.wSize.sizeClass.value === "small",
          },
          model: {
            value: _vm.luottotietoSopimusAuki,
            callback: function ($$v) {
              _vm.luottotietoSopimusAuki = $$v
            },
            expression: "luottotietoSopimusAuki",
          },
        },
        [
          _c("v-card", { staticClass: "dialog-card" }, [
            _c(
              "div",
              { staticClass: "dialog-header" },
              [
                _c("h1", [
                  _vm._v(
                    _vm._s(_vm.$t("common:creditRating.creditRatingContract"))
                  ),
                ]),
                _c("v-chip", { attrs: { color: "action" } }, [_vm._v("Beta")]),
                _c("v-spacer"),
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "" },
                    on: {
                      click: function ($event) {
                        return _vm.suljeSopimusDialog()
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("close")])],
                  1
                ),
              ],
              1
            ),
            _vm.currentStep === "loading"
              ? _c(
                  "div",
                  { staticClass: "loading-container" },
                  [
                    _c("v-progress-circular", {
                      attrs: { indeterminate: "", color: "primary" },
                    }),
                  ],
                  1
                )
              : _vm.currentStep === "error"
              ? _c(
                  "div",
                  { staticClass: "container-denied content-container" },
                  [
                    _c(
                      "div",
                      { staticClass: "content-denied" },
                      [
                        _c("div", [
                          _vm._v(" " + _vm._s(_vm.error?.text) + " "),
                        ]),
                        _vm.error?.btnText
                          ? _c(
                              "v-btn",
                              {
                                attrs: { color: "action" },
                                on: {
                                  click: function ($event) {
                                    _vm.error?.btnClick()
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(_vm.error?.btnText) + " ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                )
              : _vm.currentStep === "aloitus"
              ? _c(
                  "div",
                  { staticClass: "contentainer-sign content-container" },
                  [
                    _c(
                      "div",
                      { staticClass: "content-sign" },
                      [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "common:creditRating.creditRatingContractIsNeeded"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "common:creditRating.contractAuthorizesAccess"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "common:creditRating.startContractCreation"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "action", loading: _vm.btnLoading },
                            on: {
                              click: function ($event) {
                                _vm.siirryTunnistautumaanVahvasti()
                                _vm.btnLoading = true
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("common:authenticate")) + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                )
              : _vm.currentStep === "vahvista"
              ? _c(
                  "div",
                  { staticClass: "container-check content-container" },
                  [
                    _c(
                      "div",
                      { staticClass: "content-check" },
                      [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "common:creditRating.weHaveConstructedContract"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "common:creditRating.uponSuccessfulConfirmation"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "common:creditRating.personalCreditInformationInquiriesNeed"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "action" },
                            on: {
                              click: function ($event) {
                                return _vm.vahvistaAllekirjoitus()
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("common:creditRating.confirmSignature")
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                )
              : _vm.currentStep === "sopimus"
              ? _c(
                  "div",
                  { staticClass: "container-contract content-container" },
                  [
                    _c("div", { staticClass: "paamies" }, [
                      _c("div", { staticClass: "heading" }, [
                        _vm._v(_vm._s(_vm.$t("common:creditor"))),
                      ]),
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.valittuPaamies.nimi) +
                            " (" +
                            _vm._s(_vm.valittuPaamies.id) +
                            ")"
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "tila" }, [
                      _c("div", { staticClass: "heading" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("common:creditRating.contractStatus")
                            ) +
                            " "
                        ),
                      ]),
                      _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.luottotiedot.sopimus.tila
                                ? "Voimassa"
                                : "Ei Voimassa"
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "yritys" }, [
                      _c("div", { staticClass: "heading" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "common:creditRating.businessCreditInformationSearch"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.luottotiedot.sopimus.yritys
                                ? "Käytössä"
                                : "Ei Käytössä"
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "henkilo" }, [
                      _c("div", { staticClass: "heading" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "common:creditRating.personalCreditInformationSearch"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.luottotiedot.sopimus.henkilo
                                ? "Käytössä"
                                : "Ei Käytössä"
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }