<template>
  <DefList :bordered="true">
    <DefListItem>
      <template #label>Nimi</template>
      <template v-if="value.nimi" #value>
        {{ value.nimi }}
      </template>
    </DefListItem>
    <DefListItem v-if="value.nro">
      <template #label>Asiakasnumero</template>
      <template #value>
        {{ value.nro }}
      </template>
    </DefListItem>
    <DefListItem v-if="value.id">
      <template #label>
        {{ $sovellusIdOtsake }}
      </template>
      <template #value>
        {{ value.id }}
      </template>
    </DefListItem>
    <DefListItem v-if="value.tunnus">
      <template #label>Tunnus</template>
      <template #value>
        {{ value.tunnus }}
      </template>
    </DefListItem>
    <DefListItem v-if="value.tyyppi">
      <template #label>Tyyppi</template>
      <template #value>
        {{ value.tyyppi }}
      </template>
    </DefListItem>
    <DefListItem v-if="value.paamies">
      <template #label>Päämies</template>
      <template #value>
        {{ value.paamies.nimi }}
      </template>
    </DefListItem>
    <template v-if="value.postiosoite">
      <DefListItem>
        <template #label>Postisoite</template>
        <template #value>
          {{ value.postiosoite.osoite }}
          <br />
          {{ value.postiosoite.numero }} {{ value.postiosoite.toimipaikka }}
        </template>
      </DefListItem>
    </template>
    <template v-if="value.verkkolaskuosoite">
      <DefListItem>
        <template #label>Verkkolaskuosoite</template>
        <template #value>
          {{ value.verkkolaskuosoite.verkkolaskuosoite }}
        </template>
      </DefListItem>
      <DefListItem>
        <template #label>Verkkolaskuoperaattori</template>
        <template #value>
          {{ value.verkkolaskuosoite.operaattori }}
        </template>
      </DefListItem>
    </template>
  </DefList>
</template>

<script>
export default {
  name: "OffCanvasDetailsAsiakas",
  props: {
    value: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
