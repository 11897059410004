var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "formAineisto",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "px-4" },
            [
              !_vm.$store.state.user.vainYksiPaamies
                ? _c("AutocompletePaamies", {
                    attrs: {
                      "validointi-saannot": [_vm.$validationRules.required],
                      "prop-items": _vm.paamiehet,
                      placeholder: "",
                    },
                    on: {
                      "autocomplete-input": _vm.haeAineistolataajatPaamiehella,
                    },
                    model: {
                      value: _vm.aineistoLomake.paamies,
                      callback: function ($$v) {
                        _vm.$set(_vm.aineistoLomake, "paamies", $$v)
                      },
                      expression: "aineistoLomake.paamies",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "px-4" },
            [
              _c("v-autocomplete", {
                ref: "refAineistolataaja",
                staticClass: "required",
                attrs: {
                  items: _vm.aineistolataajaItems,
                  loading: _vm.aineistolataajaLoading,
                  "search-input": _vm.aineistolataajaSearchQuery,
                  "hide-no-data":
                    !_vm.aineistolataajaSearchQuery ||
                    _vm.aineistolataajaLoading,
                  placeholder:
                    !_vm.aineistolataajaSearchQuery &&
                    !_vm.aineistolataajaItems.length
                      ? _vm.$t("common:fileHandling.noDataLoaders")
                      : "",
                  "item-text": "nimi",
                  "item-value": "id",
                  label: _vm.$t("common:fileHandling.dataLoader"),
                  "return-object": "",
                  rules: [_vm.$validationRules.required],
                },
                on: {
                  "update:searchInput": function ($event) {
                    _vm.aineistolataajaSearchQuery = $event
                  },
                  "update:search-input": function ($event) {
                    _vm.aineistolataajaSearchQuery = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-title", [
                              _vm._v(_vm._s(item.nimi)),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.aineistoLomake.aineistolataaja,
                  callback: function ($$v) {
                    _vm.$set(_vm.aineistoLomake, "aineistolataaja", $$v)
                  },
                  expression: "aineistoLomake.aineistolataaja",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "px-4" },
            [
              _c("v-file-input", {
                staticClass: "required",
                attrs: {
                  accept: ".csv,.xls,.xlsx",
                  label: _vm.$t("common:fileHandling.dataFile"),
                  rules: [_vm.$validationRules.required],
                },
                model: {
                  value: _vm.aineistoLomake.csv,
                  callback: function ($$v) {
                    _vm.$set(_vm.aineistoLomake, "csv", $$v)
                  },
                  expression: "aineistoLomake.csv",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-4 pa-3 px-4", attrs: { justify: "end" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "mr-4",
              attrs: { large: "" },
              on: { click: _vm.tyhjenna },
            },
            [_vm._v(" " + _vm._s(_vm.$t("common:clear")) + " ")]
          ),
          _c(
            "v-btn",
            {
              attrs: { large: "", color: "action", disabled: !_vm.validForm },
              on: { click: _vm.luoAineistotTiedostosta },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("common:fileHandling.bringFromFile")) + " "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }