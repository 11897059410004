export default {
  computed: {
    trafiLupaNaytaKaikki() {
      return this.$permission.checkSinglePerm(
        "trafi.onlinepalvelu_trafikysely_nayta"
      );
    },
    trafiLupaNaytaVainNimi() {
      return this.$permission.checkSinglePerm(
        "trafi.onlinepalvelu_trafikysely_nayta_nimi"
      );
    },
  },
  methods: {
    eiOikeuttaNahdaAsiakastaLasku(lasku) {
      return (
        (lasku.paamies.piilota_asiakas_ja_velallinen &&
          !this.trafiLupaNaytaKaikki) ||
        (lasku.asiakas.postiosoite?.lahde === "trafi" &&
          !this.trafiLupaNaytaKaikki &&
          !this.trafiLupaNaytaVainNimi) ||
        lasku.asiakas.postiosoite?.lahde === "gorilla"
      );
    },
    trafiLupaNaytaVainNimiLasku(lasku) {
      return (
        lasku.asiakas.postiosoite?.lahde === "trafi" &&
        this.trafiLupaNaytaVainNimi &&
        !this.trafiLupaNaytaKaikki
      );
    },
    eiOikeuttaAvataAsiakasta(asiakas) {
      return (
        (asiakas.paamies.piilota_asiakas_ja_velallinen &&
          !this.trafiLupaNaytaKaikki) ||
        (asiakas.postiosoite?.lahde === "trafi" &&
          !this.trafiLupaNaytaKaikki) ||
        asiakas.postiosoite?.lahde === "gorilla"
      );
    },
    eiOikeuttaNahdaAsiakastaValue(value) {
      return (
        (value.paamies.piilota_asiakas_ja_velallinen &&
          !this.trafiLupaNaytaKaikki) ||
        (value.osoite?.lahde === "trafi" &&
          !this.trafiLupaNaytaKaikki &&
          !this.trafiLupaNaytaVainNimi) ||
        value.osoite?.lahde === "gorilla"
      );
    },
    eiOikeuttaNahdaAsiakastaValueVelallinen(value, velallinen) {
      return (
        (value.paamies.piilota_asiakas_ja_velallinen &&
          !this.trafiLupaNaytaKaikki) ||
        (velallinen.osoite?.lahde === "trafi" &&
          !this.trafiLupaNaytaKaikki &&
          !this.trafiLupaNaytaVainNimi) ||
        velallinen.osoite?.lahde === "gorilla"
      );
    },
  },
};
