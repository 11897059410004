<template>
  <nav class="nav-main">
    <ul v-if="navItems.length" class="nav-main__list">
      <li v-for="item in navItems" :key="item.index" class="nav-main__item">
        <router-link
          :to="item.disabled ? { name: 'dummy' } : { name: item.name }"
          :class="item.disabled ? linkClassDisabled : linkClass"
        >
          <v-tooltip right :disabled="showTitles && !item.disabled">
            <template #activator="{ on }">
              <span class="nav-main__iconWrap" v-on="on">
                <span
                  v-if="item.meta.icon"
                  class="nav-main__icon"
                  :class="item.meta.icon"
                />

                <Badge v-model="item.meta.badge" />
              </span>
            </template>
            <template #default>
              {{
                item.disabled
                  ? item.disabledInfo
                    ? item.disabledInfo
                    : $t("notAvailable")
                  : item.meta.title
              }}
            </template>
          </v-tooltip>
          <v-tooltip right :disabled="!item.disabled">
            <template #activator="{ on }">
              <span v-show="showTitles" class="nav-main__title" v-on="on">
                {{ item.meta.title }}
              </span>
            </template>
            <template #default>
              {{ item.disabledInfo ? item.disabledInfo : $t("notAvailable") }}
            </template>
          </v-tooltip>
        </router-link>
      </li>

      <li
        v-if="
          $permission.checkSinglePerm(
            'onlinerestapi.onlinerestapi_luottotieto'
          ) && luottotietoModuuli
        "
        class="nav-main__item"
        @click="$refs.luottotietoHaku.avaaLuottotiedot()"
      >
        <div :class="linkClass">
          <v-tooltip right :disabled="showTitles">
            <template #activator="{ on }">
              <span class="nav-main__iconWrap" v-on="on">
                <span class="nav-main__icon">
                  <v-icon color="icons">mdi-chart-box</v-icon>
                  <v-icon
                    v-if="!showTitles"
                    color="icons"
                    size="small"
                    class="nav-main__dialog-tooltip"
                  >
                    mdi-arrow-top-right
                  </v-icon>
                </span>
              </span>
              <div class="nav-main__dialog">
                <span v-show="showTitles" class="nav-main__title">
                  Luottotietohaku
                </span>
                <span v-show="showTitles">
                  <v-icon color="icons" size="small">
                    mdi-arrow-top-right
                  </v-icon>
                </span>
              </div>
            </template>
            <span>Luottotietohaku</span>
          </v-tooltip>
        </div>
      </li>
    </ul>
    <LuottotietoHaku
      v-if="
        $permission.checkSinglePerm(
          'onlinerestapi.onlinerestapi_luottotieto'
        ) && luottotietoModuuli
      "
      ref="luottotietoHaku"
      :btn="false"
    />
  </nav>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Badge from "@/components/Badge";
import LuottotietoHaku from "@/components/LuottotietoHaku";
import { onkoModuuliKaytossa } from "@/utils/misc";

export default {
  name: "NavMain",
  components: {
    Badge,
    LuottotietoHaku,
  },
  props: {
    showTitles: {
      type: Boolean,
      required: false,
      default() {
        return true;
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    ...mapGetters({
      dataLoaded: "user/dataLoaded",
    }),
    linkClass() {
      return [
        "nav-main__link",
        this.showTitles ? null : "nav-main__link--icon",
      ];
    },
    linkClassDisabled() {
      return [...this.linkClass, "nav-main__link--disabled"];
    },
    navItems() {
      if (!this.dataLoaded) return [];

      return this.$router.options.routes.filter((route) => {
        // Jos route on merkattu staffOnly, näytetään se menussa vain henkilökunnalle
        if (
          route.meta.showInMenus === false ||
          (route.meta.staffOnly && !this.user.isStaff)
        ) {
          return false;
        }

        if (!this.$permission.match(route)) {
          // Tiettyjä routeja ei näytetä ollenkaan, jos oikeus puuttuu
          if (route.meta.hideIfNoPerm === true) {
            return null;
          }
          // Jos käyttäjältä ja päämieheltä puuttuu oikeus, näytetään route menussa disabloituna
          route.disabled = true;
          // Jos käyttäjällä on oikeus routeen, puuttuu se silloin päämieheltä.
          if (this.$permission.match(route, false)) {
            route.disabledInfo = this.$t("selectedCreditorHasNoPermission");
          }
        } else {
          // Täytyy poistaa disablointi, jotta tekeytymisen jälkeen disabloinnit resetoituvat
          route.disabled = false;
        }

        if (route.name === "viestit-selaa") {
          route.meta.badge =
            this.user.lukemattomatViestit <= 99
              ? this.user.lukemattomatViestit
              : this.user.lukemattomatViestit > 99
              ? "99+"
              : "";
        }

        return route;
      });
    },
    luottotietoModuuli() {
      return onkoModuuliKaytossa("luottotietosopimus");
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-main {
  &__list,
  &__item {
    display: block;
    margin: 0;
    padding: 0;
  }

  &__list {
    list-style: none;
  }

  &__link {
    padding: 0.65em 1em;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    text-decoration: none;
    font-size: 1.1em;
    color: inherit;
    border-radius: 0.5em;
    transition: all 200ms ease;
    cursor: pointer;

    &--icon {
      padding-left: 0;
      padding-right: 0;
      justify-content: center;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }

    &.router-link-active {
      color: #fff;
      background: var(--v-primary-base); //lighten(#000, 20);
    }

    &--disabled {
      opacity: 0.6;
    }
  }

  &__icon,
  &__title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &__iconWrap {
    position: relative;
  }

  &__icon {
    font-size: 1.4em;
    position: relative;
  }

  &__title {
    flex: 1 1 auto;
    padding-left: 0.75em;
  }
  &__dialog {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  &__dialog-tooltip {
    position: absolute;
    right: -12px;
    top: 5px;
  }
}
</style>
