var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    { ref: "formPaamies" },
    [
      _vm.loading
        ? [_c("Spinner", { attrs: { loading: _vm.loading } })]
        : [
            _c(
              "v-row",
              [
                _c("v-col", { staticClass: "pl-4 pr-12", attrs: { md: "6" } }),
                _c(
                  "v-col",
                  { staticClass: "pl-12 pr-4", attrs: { md: "6" } },
                  [
                    _c("AutocompleteYTJHaku", {
                      attrs: {
                        "input-hint":
                          "Hakutuloksen valinta täyttää yrityksen tiedot lomakkeelle.",
                      },
                      on: {
                        "autocomplete-input":
                          _vm.asetaYrityksenTiedotLomakkeelle,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { staticClass: "pl-4 pr-12", attrs: { md: "6" } },
                  [
                    _c("h3", { staticClass: "my-8" }, [
                      _vm._v("Päämiestiedot"),
                    ]),
                    _c("v-text-field", {
                      staticClass: "required",
                      attrs: {
                        label: "Y-tunnus / Hetu",
                        rules: [_vm.$validationRules.required],
                        "error-messages": _vm.tunnusErrors,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.teeTunnusTarkistukset($event)
                        },
                        blur: function ($event) {
                          return _vm.teeTunnusTarkistukset(
                            $event.target.value,
                            true,
                            true
                          )
                        },
                      },
                      model: {
                        value: _vm.paamies.tunnus,
                        callback: function ($$v) {
                          _vm.$set(_vm.paamies, "tunnus", $$v)
                        },
                        expression: "paamies.tunnus",
                      },
                    }),
                    _c("v-text-field", {
                      staticClass: "required",
                      attrs: {
                        label: "Nimi",
                        rules: [_vm.$validationRules.required],
                        hint:
                          _vm.paateltyTyyppi !== "Y"
                            ? "Anna henkilön nimi muodossa <sukunimi> <etunimet>"
                            : "",
                      },
                      on: {
                        blur: function ($event) {
                          return _vm.tarkistaOlemassaOlevaNimi(
                            $event.target.value
                          )
                        },
                      },
                      model: {
                        value: _vm.paamies.nimi,
                        callback: function ($$v) {
                          _vm.$set(_vm.paamies, "nimi", $$v)
                        },
                        expression: "paamies.nimi",
                      },
                    }),
                    _c("h3", { staticClass: "my-8" }, [_vm._v("Osoitetiedot")]),
                    _c("v-text-field", {
                      attrs: { label: "Saaja / Tarkenne" },
                      model: {
                        value: _vm.paamies.saaja,
                        callback: function ($$v) {
                          _vm.$set(_vm.paamies, "saaja", $$v)
                        },
                        expression: "paamies.saaja",
                      },
                    }),
                    _c("v-text-field", {
                      staticClass: "required",
                      attrs: {
                        label: "Lähisoite",
                        rules: [_vm.$validationRules.required],
                      },
                      model: {
                        value: _vm.paamies.osoite,
                        callback: function ($$v) {
                          _vm.$set(_vm.paamies, "osoite", $$v)
                        },
                        expression: "paamies.osoite",
                      },
                    }),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { staticClass: "py-1", attrs: { md: "5" } },
                          [
                            _c("v-text-field", {
                              staticClass: "required",
                              attrs: {
                                label: "Postinumero",
                                rules: [_vm.$validationRules.required],
                              },
                              model: {
                                value: _vm.paamies.postinumero,
                                callback: function ($$v) {
                                  _vm.$set(_vm.paamies, "postinumero", $$v)
                                },
                                expression: "paamies.postinumero",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { staticClass: "py-1", attrs: { md: "7" } },
                          [
                            _c("v-text-field", {
                              staticClass: "required",
                              attrs: {
                                label: "Postitoimipaikka",
                                rules: [_vm.$validationRules.required],
                              },
                              model: {
                                value: _vm.paamies.toimipaikka,
                                callback: function ($$v) {
                                  _vm.$set(_vm.paamies, "toimipaikka", $$v)
                                },
                                expression: "paamies.toimipaikka",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("v-select", {
                      staticClass: "required",
                      attrs: {
                        label: "Maa",
                        type: "text",
                        items: _vm.maat,
                        "item-value": "value",
                        "item-text": "selite",
                        "menu-props": { zIndex: "999", auto: true },
                        rules: [_vm.$validationRules.required],
                      },
                      model: {
                        value: _vm.paamies.maa,
                        callback: function ($$v) {
                          _vm.$set(_vm.paamies, "maa", $$v)
                        },
                        expression: "paamies.maa",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { staticClass: "pl-12 pr-4", attrs: { md: "6" } },
                  [
                    _c("h3", { staticClass: "my-8" }, [_vm._v("Yhteystiedot")]),
                    _c("v-text-field", {
                      attrs: { label: "Puhelin" },
                      model: {
                        value: _vm.paamies.puhelin,
                        callback: function ($$v) {
                          _vm.$set(_vm.paamies, "puhelin", $$v)
                        },
                        expression: "paamies.puhelin",
                      },
                    }),
                    _c("v-combobox", {
                      attrs: {
                        items: [],
                        delimiters: [",", ";"],
                        chips: "",
                        "append-icon": "",
                        multiple: "",
                        "deletable-chips": "",
                        label: "Sähköpostiosoite",
                        type: "text",
                        rules: [_vm.$validationRules.emailArray],
                      },
                      model: {
                        value: _vm.paamies.emailOsoitteet,
                        callback: function ($$v) {
                          _vm.$set(_vm.paamies, "emailOsoitteet", $$v)
                        },
                        expression: "paamies.emailOsoitteet",
                      },
                    }),
                    _c("v-text-field", {
                      attrs: {
                        label: "Verkkolaskuosoite",
                        "error-messages": _vm.verkkolaskuosoiteErrors,
                      },
                      model: {
                        value: _vm.paamies.verkkolasku_osoite,
                        callback: function ($$v) {
                          _vm.$set(_vm.paamies, "verkkolasku_osoite", $$v)
                        },
                        expression: "paamies.verkkolasku_osoite",
                      },
                    }),
                    _c("v-select", {
                      staticClass: "py-1",
                      attrs: {
                        clearable: "",
                        label: "Verkkolaskuoperaattori",
                        filter: _vm.verkkolaskuoperaattoriFilter,
                        "error-messages": _vm.verkkolaskuoperaattoriErrors,
                        items: _vm.verkkolaskuoperaattorit,
                        "item-text": "autocomplete_selite",
                        "item-value": "id",
                      },
                      model: {
                        value: _vm.paamies.verkkolasku_operaattori,
                        callback: function ($$v) {
                          _vm.$set(_vm.paamies, "verkkolasku_operaattori", $$v)
                        },
                        expression: "paamies.verkkolasku_operaattori",
                      },
                    }),
                    _c("v-text-field", {
                      staticClass: "required mt-6",
                      attrs: {
                        label: "IBAN",
                        rules: [
                          _vm.$validationRules.required,
                          _vm.$validationRules.isValidIBAN,
                        ],
                      },
                      model: {
                        value: _vm.paamies.iban,
                        callback: function ($$v) {
                          _vm.$set(_vm.paamies, "iban", $$v)
                        },
                        expression: "paamies.iban",
                      },
                    }),
                    _c("v-text-field", {
                      staticClass: "required",
                      attrs: {
                        label: "BIC",
                        rules: [
                          _vm.$validationRules.required,
                          _vm.$validationRules.isValidBIC,
                        ],
                      },
                      model: {
                        value: _vm.paamies.bic,
                        callback: function ($$v) {
                          _vm.$set(_vm.paamies, "bic", $$v)
                        },
                        expression: "paamies.bic",
                      },
                    }),
                    _c("v-text-field", {
                      attrs: { label: "Viesti kirjeeseen" },
                      model: {
                        value: _vm.paamies.viestikirjeeseen,
                        callback: function ($$v) {
                          _vm.$set(_vm.paamies, "viestikirjeeseen", $$v)
                        },
                        expression: "paamies.viestikirjeeseen",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              { staticClass: "mt-4 pa-3", attrs: { justify: "end" } },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-4",
                    attrs: { large: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("close")
                      },
                    },
                  },
                  [_vm._v("Peruuta")]
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { large: "", color: "action" },
                    on: { click: _vm.tallenna },
                  },
                  [_vm._v("Tallenna")]
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }