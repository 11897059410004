<template>
  <v-form v-model="valid" @submit.prevent="submit">
    <v-row>
      <v-col md="12" class="py-0">
        <v-textarea
          v-model="value.viestikirjeeseen"
          :label="$t('common:settings.textOnInvoiceLetter')"
          type="text"
          rows="4"
          :counter="value.viestikirjeeseen.length > 1023 ? 1023 : undefined"
          :rules="[
            $validationRules.tooManyCharacters(
              value.viestikirjeeseen.length,
              1023
            ),
          ]"
          outlined
        />
      </v-col>
    </v-row>
    <v-row class="mt-4 pa-3" justify="end">
      <v-btn large color="action" class="ml-4" type="submit" :disabled="!valid">
        {{ $t("common:save") }}
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";

export default {
  name: "FormAsetuksetPaamiesAsetukset",
  mixins: [FormMixin],
};
</script>
