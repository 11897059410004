<template>
  <v-toolbar class="grey lighten-4 my-4 px-2" flat>
    <slot name="toolbar-vasen" />
    <template v-if="hakutulokset">
      <DenseToggleButton class="mr-2" />
      <p v-show="!loadingSummary" class="ma-0">
        {{ $t("searchFoundTotal") }} {{ itemsTotal }} {{ itemPartitiivi }}.
      </p>
      <p v-show="loadingSummary" class="ma-0">
        {{ $t("loadingSummary") }}
      </p>
      <v-spacer />
      <Pagination
        v-show="!loadingSummary"
        v-model="pagination.page"
        :length="pagesTotal"
        :disabled="loading"
        :total-visible="$vuetify.breakpoint.mdAndDown ? 3 : 7"
      />
      <PaginationHyppaaAutocomplete
        v-show="pagesTotal > 10"
        class="m-2"
        :pages="pagesTotal"
        @autocomplete-input="hyppaaSivulle"
      />
    </template>
    <v-spacer />
    <slot name="btn-vasen" />
    <slot>
      <slot name="tuo-aineistoa-btn">
        <ViewSelaaToolbarBtn
          v-if="tuoAineistoaBtnConfig"
          icon="save_alt"
          color="primary"
          :click="tuoAineistoaBtnConfig.click"
        >
          {{ tuoAineistoaBtnConfig.text }}
        </ViewSelaaToolbarBtn>
      </slot>
      <slot name="lataa-csv-btn">
        <ViewSelaaToolbarBtn
          v-if="psizeKaikkiCsvHaku > 0"
          icon="download"
          :click="haeCsvKaikkiRivit"
          :disabled="liikaaRiveja"
          :loading="loadingKaikkiRivit"
          :tooltip-teksti="csvKaikkiTooltipTeksti"
        >
          {{ $t("loadCsv") }}
        </ViewSelaaToolbarBtn>
      </slot>
      <slot name="alt-selaa-btn">
        <ViewSelaaToolbarBtn
          v-if="altSelaaBtnConfig && altSelaaBtnPermission"
          :to="{ name: altSelaaBtnConfig.toName }"
          icon="view_list"
        >
          {{ $t("browse") }} {{ altSelaaBtnConfig.itemPartitiivi }}
        </ViewSelaaToolbarBtn>
      </slot>
      <slot name="btn-keskella" />
      <slot name="uusi-btn">
        <ViewSelaaToolbarBtn
          v-if="uusiBtnConfig && uusiBtnPermission"
          :to="uusiBtnConfig.toName ? { name: uusiBtnConfig.toName } : null"
          color="action"
          icon="library_add"
          :click="uusiBtnConfig.click"
        >
          {{ $t("new") }} {{ uusiBtnConfig.itemName }}
        </ViewSelaaToolbarBtn>
      </slot>
    </slot>
    <slot name="btn-oikea" />
  </v-toolbar>
</template>

<script>
import Pagination from "@/components/Pagination";
import PaginationHyppaaAutocomplete from "@/components/PaginationHyppaaAutocomplete";
import ViewSelaaToolbarBtn from "@/components/ViewSelaaToolbarBtn";

export default {
  components: {
    Pagination,
    PaginationHyppaaAutocomplete,
    ViewSelaaToolbarBtn,
  },
  props: {
    vuexModuleName: {
      type: String,
      required: true,
    },
    itemPartitiivi: {
      type: String,
      required: true,
    },
    altSelaaBtnConfig: {
      type: Object,
      required: false,
      default: null,
    },
    uusiBtnConfig: {
      type: Object,
      required: false,
      default: null,
    },
    tuoAineistoaBtnConfig: {
      type: Object,
      required: false,
      default: null,
    },
    hakutulokset: {
      type: Boolean,
      default: true,
    },
    psizeKaikkiCsvHaku: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    loadingKaikkiRivit() {
      return this.$store.state[this.vuexModuleName].loadingKaikkiRivit;
    },
    liikaaRiveja() {
      return (
        this.$store.state[this.vuexModuleName].itemsTotal === 0 ||
        this.$store.state[this.vuexModuleName].itemsTotal >
          this.psizeKaikkiCsvHaku
      );
    },
    itemsTotal() {
      return this.$store.state[this.vuexModuleName].itemsTotal;
    },
    csvKaikkiTooltipTeksti() {
      return this.$t("common:fileHandling.allCsvToolTip", {
        maxRivienMaara: this.psizeKaikkiCsvHaku,
      });
    },
    loading() {
      return this.$store.state[this.vuexModuleName].loading;
    },
    loadingSummary() {
      return this.$store.state[this.vuexModuleName].loadingSummary;
    },
    pagesTotal() {
      return this.$store.state[this.vuexModuleName].pagesTotal;
    },
    autocompleteItems() {
      return Array.from({ length: this.pagesTotal }, (arrayLike, x) => x + 1);
    },
    pagination: {
      get() {
        return this.$store.state[this.vuexModuleName].pagination;
      },
      set(val) {
        this.$store.commit(`${this.vuexModuleName}/setPagination`, val);
      },
    },
    uusiBtnPermission() {
      return this.uusiBtnConfig && this.uusiBtnConfig.permission !== false;
    },
    altSelaaBtnPermission() {
      return (
        this.altSelaaBtnConfig && this.altSelaaBtnConfig.permission !== false
      );
    },
  },
  methods: {
    haeCsvKaikkiRivit() {
      this.$store.commit(`${this.vuexModuleName}/setLoadingKaikkiRivit`, true);
      this.$emit("loadItemsKaikkiRivit");
    },
    hyppaaSivulle(sivu) {
      this.pagination.page = sivu;
    },
  },
};
</script>

<style></style>
