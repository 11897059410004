<template>
  <v-form ref="formAineisto" v-model="valid" @submit.prevent="submit">
    <v-row>
      <v-col class="px-4">
        <AutocompletePaamies
          v-if="!$store.state.user.vainYksiPaamies"
          v-model="aineistoLomake.paamies"
          :validointi-saannot="[$validationRules.required]"
          :prop-items="paamiehet"
          placeholder=""
          @autocomplete-input="haeAineistolataajatPaamiehella"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="px-4">
        <v-autocomplete
          ref="refAineistolataaja"
          v-model="aineistoLomake.aineistolataaja"
          :items="aineistolataajaItems"
          :loading="aineistolataajaLoading"
          :search-input.sync="aineistolataajaSearchQuery"
          :hide-no-data="!aineistolataajaSearchQuery || aineistolataajaLoading"
          :placeholder="
            !aineistolataajaSearchQuery && !aineistolataajaItems.length
              ? $t('common:fileHandling.noDataLoaders')
              : ''
          "
          item-text="nimi"
          item-value="id"
          :label="$t('common:fileHandling.dataLoader')"
          return-object
          class="required"
          :rules="[$validationRules.required]"
        >
          <template #item="{ item }">
            <v-list-item-content>
              <v-list-item-title>{{ item.nimi }}</v-list-item-title>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="px-4">
        <v-file-input
          v-model="aineistoLomake.csv"
          accept=".csv,.xls,.xlsx"
          :label="$t('common:fileHandling.dataFile')"
          class="required"
          :rules="[$validationRules.required]"
        ></v-file-input>
      </v-col>
    </v-row>
    <v-row class="mt-4 pa-3 px-4" justify="end">
      <v-btn large class="mr-4" @click="tyhjenna">
        {{ $t("common:clear") }}
      </v-btn>
      <v-btn
        large
        color="action"
        :disabled="!validForm"
        @click="luoAineistotTiedostosta"
      >
        {{ $t("common:fileHandling.bringFromFile") }}
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";
import AutocompletePaamies from "@/components/AutocompletePaamies";

export default {
  name: "FormAineisto",
  components: {
    AutocompletePaamies,
  },
  mixins: [FormMixin],
  data() {
    return {
      aineistoLomake: {
        paamies: this.$store.state.user.valittuPaamies,
        csv: null,
      },
      lataaTiedostoa: false,
      debounceDelay: 500,
      debounceTimeout: "",
      aineistolataajaItems: [],
      aineistolataajaLoading: false,
      aineistolataajaSearchQuery: "",
      paamiehet: [],
    };
  },
  computed: {
    validForm() {
      return this.valid && !this.lataaTiedostoa && this.aineistoLomake.csv;
    },
  },
  watch: {
    onAuki: {
      immediate: true,
      handler(arvo) {
        if (arvo) {
          this.$nextTick().then(() => {
            this.$refs.formAineisto.resetValidation();
          });
        }
      },
    },
    async aineistolataajaSearchQuery(val, oldVal) {
      if (!this.aineistoLomake.paamies) return;
      if (
        this.aineistoLomake &&
        this.aineistoLomake.aineistolataaja &&
        val === this.aineistoLomake.aineistolataaja.nimi
      )
        return;
      if (val === oldVal) return;
      clearTimeout(this.debounceTimeout);
      this.aineistolataajaItems = [];
      this.aineistolataajaLoading = true;
      this.debounceTimeout = setTimeout(async () => {
        try {
          await this.haeAineistolataajat(val);
        } catch (e) {
          this.$oletusVirheenkasittely(
            e,
            this.$t("common:fileHandling.dataLoaderQueryUnsuccesful")
          );
        } finally {
          this.aineistolataajaLoading = false;
        }
      }, this.debounceDelay);
    },
  },
  async mounted() {
    await this.haePaamiehet();
  },
  methods: {
    async haePaamiehet() {
      try {
        const query = new URLSearchParams();
        query.append("paamiehella_tiedostolatausrutiini", true);

        const request = await this.$doRequestWithTimeout(
          this.$api.UserPaamies,
          {
            method: "GET",
            query: query.toString(),
          },
          "pushRequest"
        );
        if (!request.success) throw new this.$HttpError(request);

        this.paamiehet = request.result.body;
      } catch (e) {
        this.$oletusVirheenkasittely(
          e,
          "Tietojen lataaminen ei onnistunut! Yritä hetken kuluttua uudelleen."
        );
      }
    },
    async haeAineistolataajatPaamiehella() {
      await this.haeAineistolataajat();
    },
    async haeAineistolataajat(val) {
      try {
        if (!this.aineistoLomake?.paamies) return;
        let nimi = "";
        if (typeof val === "string") {
          nimi = val ? val : "";
        }
        const query = new URLSearchParams();
        query.append("nimi", nimi);
        const paamies_id = this.aineistoLomake.paamies.id
          ? this.aineistoLomake.paamies.id
          : this.aineistoLomake.paamies;

        const request = await this.$doRequestWithTimeout(
          this.$api.Aineistolataaja,
          {
            method: "GET",
            params: {
              paamies_id: paamies_id,
            },
            url: "",
            query: query.toString(),
          },
          "pushRequest"
        );
        if (!request.success) throw new this.$HttpError(request);
        this.aineistolataajaItems = request.result.body.results.map(
          (aineistolataaja) => ({
            ...aineistolataaja,
          })
        );

        this.aineistolataajaItems = request.result.body.results;
      } catch (e) {
        this.$oletusVirheenkasittely(
          e,
          "Tietojen lataaminen ei onnistunut! Yritä hetken kuluttua uudelleen."
        );
      }
    },
    async luoAineistotTiedostosta() {
      try {
        this.lataaTiedostoa = true;
        let result = await this.$store.dispatch(
          "laskutusNayta/aineistolataajaSubmit",
          this.aineistoLomake
        );
        if (!result) {
          result = this.$t("common:fileHandling.noDataLoaded");
        }
        this.$naytaOnnistumisilmoitus(result);
      } catch (e) {
        this.$oletusVirheenkasittely(
          e,
          this.$t("common:fileHandling.loadingUnsuccesfulError"),
          e
        );
      } finally {
        this.aineistoLomake.csv = null;
        this.lataaTiedostoa = false;
      }
    },
    tyhjenna() {
      this.aineistoLomake.aineistolataaja = {};
      this.aineistoLomake.csv = null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
