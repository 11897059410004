const Kuukausiraportti = class {
  constructor(preset = {}) {
    this.id = null;
    this.ajopaiva = 1;
    this.ajotunti = 8;
    this.alkamispaiva = 1;
    this.email = "";
    this.emailOsoitteet = [];
    this.loppumispaiva = 31;
    this.paamies = {};
    this.paamies_id = null;
    this.raportti_id = null;
    this.seuraava_ajopaiva = null;
    this.vain_arkena = false;

    Object.assign(this, preset);

    if (preset.seuraava_ajoaika) {
      this.seuraava_ajopaiva = preset.seuraava_ajoaika.substr(0, 10);
    }

    this.emailOsoitteet = this.email ? this.email.split(",") : [];
  }

  getPostData(config = { vainAjopaivanLaskeminen: false }) {
    const postData = JSON.parse(JSON.stringify(this));
    delete postData.paamies;
    delete postData.raportti;
    delete postData.id;

    if (this.seuraava_ajopaiva === null) delete postData.seuraava_ajopaiva;

    if (config.vainAjopaivanLaskeminen) {
      delete postData.email;
    } else {
      postData.email = this.emailOsoitteet.join(",");
    }
    delete postData.emailOsoitteet;

    return postData;
  }
};

export default Kuukausiraportti;
