<template>
  <v-row>
    <!-- Vasen sarake -->
    <v-col class="px-6 pb-6" md="6">
      <div class="pa-2">
        <div v-if="naytaAlkaenPaattyen" class="comboField">
          <InputfieldDatePicker
            v-model="raporttilomake.asetukset.alkaen"
            label="Alkaen"
          />
          <span class="comboField__separator">&ndash;</span>
          <InputfieldDatePicker
            v-model="raporttilomake.asetukset.loppuen"
            label="Päättyen"
          />
        </div>
        <slot name="vasen-sarake"></slot>
      </div>
    </v-col>
    <!-- Oikea sarake -->
    <v-col class="px-6 pb-6" md="6">
      <div class="pa-2">
        <slot name="oikea-sarake"></slot>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import InputfieldDatePicker from "@/components/InputfieldDatePicker";

export default {
  name: "FormRaporttiAsetuksetBase",
  components: {
    InputfieldDatePicker,
  },
  props: {
    naytaAlkaenPaattyen: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    ...mapState({
      raporttilomake: (state) => state.raportitUusi.raporttilomake,
    }),
  },
};
</script>

<style></style>
