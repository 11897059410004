<template>
  <DefList :bordered="true">
    <DefListItem>
      <template #label>
        {{ $t("common:status") }}
      </template>
      <template v-if="value.tila && value.ikoni_ja_vari" #value>
        <StatusText
          :text="value.tila.selite"
          :color="value.ikoni_ja_vari.vari"
        />
      </template>
    </DefListItem>
    <DefListItem>
      <template #label>
        {{ $t("common:contractNumber") }}
      </template>
      <template v-if="value.nro" #value>
        {{ value.nro }}
      </template>
    </DefListItem>
    <DefListItem>
      <template #label>
        {{ $sovellusIdOtsake }}
      </template>
      <template #value>
        {{ value.id }}
      </template>
    </DefListItem>
    <DefListItem>
      <template #label>
        {{ $t("common:customer") }}
      </template>
      <template v-if="value.asiakas" #value>
        {{ value.asiakas.nimi }}
        <template v-if="value.asiakas.nro">({{ value.asiakas.nro }})</template>
      </template>
    </DefListItem>
    <DefListItem>
      <template #label>
        {{ $t("common:creditor") }}
      </template>
      <template v-if="value.paamies" #value>
        {{ value.paamies.nimi }}
        <template v-if="value.paamies.id">({{ value.paamies.id }})</template>
      </template>
    </DefListItem>
    <DefListItem>
      <template #label>
        {{ $t("common:startingDate") }}
      </template>
      <template v-if="value.alkamispaiva" #value>
        {{ $dateFnsFormat(value.alkamispaiva) }}
      </template>
    </DefListItem>
    <DefListItem>
      <template #label>
        {{ $t("common:endingDate") }}
      </template>
      <template #value>
        <span v-if="value.loppumispaiva">
          {{ $dateFnsFormat(value.loppumispaiva) }}
        </span>
        <span v-else>{{ $t("common:validForNow") }}</span>
      </template>
    </DefListItem>
    <DefListItem v-if="value.laskutuspaiva && value.seuraava_laskutuspaiva">
      <template #label>
        {{ $t("common:nextInvoiceDate") }}
      </template>
      <template #value>
        {{ $dateFnsFormat(value.seuraava_laskutuspaiva) }}
      </template>
    </DefListItem>
    <DefListItem v-else-if="value.erapaiva && value.seuraava_erapaiva">
      <template #label>
        {{ $t("common:nextDueDate") }}
      </template>
      <template #value>
        {{ $dateFnsFormat(value.seuraava_erapaiva) }}
      </template>
    </DefListItem>
    <DefListItem>
      <template #label>
        {{ $t("common:reference") }}
      </template>
      <template v-if="value.viite" #value>
        {{ value.viite }}
      </template>
    </DefListItem>
    <DefListItem v-if="value.viitteemme">
      <template #label>
        {{ $t("common:ourReference") }}
      </template>
      <template #value>
        {{ value.viitteemme }}
      </template>
    </DefListItem>
    <DefListItem v-if="value.viitteenne">
      <template #label>
        {{ $t("common:yourReference") }}
      </template>
      <template #value>
        {{ value.viitteenne }}
      </template>
    </DefListItem>
    <!-- <DefListItem>
      <template v-slot:label>
        Eräpäivä
      </template>
      <template
        v-if="value.erapaiva"
        v-slot:value
      >
        <strong v-if="value.onko_myohassa">
          {{ $dateFnsFormat(value.erapaiva) }} (myöhässä)
        </strong>
        <template v-else>
          {{ $dateFnsFormat(value.erapaiva) }}
        </template>
      </template>
    </DefListItem> -->
  </DefList>
</template>

<script>
import StatusText from "@/components/StatusText";

export default {
  name: "OffCanvasDetailsLasku",
  components: {
    StatusText,
  },
  props: {
    value: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
