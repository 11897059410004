var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-autocomplete", {
        ref: "autocompleteLaskurivi",
        attrs: {
          label: _vm.$t("common:autocompleteLaskurivi.invoiceRowSearch"),
          items: _vm.laskuriviItems,
          loading: _vm.autocompleteLoading,
          "search-input": _vm.laskuriviSearchQuery,
          "hide-no-data": !_vm.laskuriviSearchQuery || _vm.autocompleteLoading,
          "item-text": "autocomplete_selite",
          "item-value": "id",
          "prepend-inner-icon": "search",
          disabled: !_vm.lasku.paamies.id,
          placeholder: _vm.placeholder,
          "persistent-placeholder": "",
          outlined: "",
          "return-object": "",
          hint: _vm.inputHint,
        },
        on: {
          "update:searchInput": function ($event) {
            _vm.laskuriviSearchQuery = $event
          },
          "update:search-input": function ($event) {
            _vm.laskuriviSearchQuery = $event
          },
          input: _vm.kasitteleValinta,
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function ({ item }) {
              return [
                _c(
                  "v-list-item-content",
                  [
                    _c("v-list-item-title", [
                      _vm._v(" " + _vm._s(item.autocomplete_selite) + " "),
                    ]),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "no-data",
            fn: function () {
              return [
                _c("p", { staticClass: "pa-4" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "common:autocompleteLaskurivi.searchFoundNoInvoiceRows"
                        )
                      ) +
                      " "
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.autocompleteValue,
          callback: function ($$v) {
            _vm.autocompleteValue = $$v
          },
          expression: "autocompleteValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }