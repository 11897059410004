<template>
  <v-form ref="formPaamies">
    <template v-if="loading">
      <Spinner :loading="loading" />
    </template>
    <template v-else>
      <v-row>
        <v-col md="6" class="pl-4 pr-12"></v-col>
        <v-col md="6" class="pl-12 pr-4">
          <AutocompleteYTJHaku
            input-hint="Hakutuloksen valinta täyttää yrityksen tiedot lomakkeelle."
            @autocomplete-input="asetaYrityksenTiedotLomakkeelle"
          />
        </v-col>
      </v-row>

      <v-row>
        <!-- Vasen sarake-->
        <v-col md="6" class="pl-4 pr-12">
          <h3 class="my-8">Päämiestiedot</h3>
          <v-text-field
            v-model="paamies.tunnus"
            label="Y-tunnus / Hetu"
            :rules="[$validationRules.required]"
            class="required"
            :error-messages="tunnusErrors"
            @input="teeTunnusTarkistukset($event)"
            @blur="teeTunnusTarkistukset($event.target.value, true, true)"
          />
          <v-text-field
            v-model="paamies.nimi"
            label="Nimi"
            :rules="[$validationRules.required]"
            class="required"
            :hint="
              paateltyTyyppi !== 'Y'
                ? 'Anna henkilön nimi muodossa <sukunimi> <etunimet>'
                : ''
            "
            @blur="tarkistaOlemassaOlevaNimi($event.target.value)"
          />
          <h3 class="my-8">Osoitetiedot</h3>
          <v-text-field v-model="paamies.saaja" label="Saaja / Tarkenne" />
          <v-text-field
            v-model="paamies.osoite"
            label="Lähisoite"
            :rules="[$validationRules.required]"
            class="required"
          />
          <v-row>
            <v-col md="5" class="py-1">
              <v-text-field
                v-model="paamies.postinumero"
                label="Postinumero"
                :rules="[$validationRules.required]"
                class="required"
              />
            </v-col>
            <v-col md="7" class="py-1">
              <v-text-field
                v-model="paamies.toimipaikka"
                label="Postitoimipaikka"
                :rules="[$validationRules.required]"
                class="required"
              />
            </v-col>
          </v-row>
          <v-select
            v-model="paamies.maa"
            label="Maa"
            type="text"
            :items="maat"
            item-value="value"
            item-text="selite"
            :menu-props="{ zIndex: '999', auto: true }"
            :rules="[$validationRules.required]"
            class="required"
          />
        </v-col>

        <!-- Oikea sarake -->
        <v-col md="6" class="pl-12 pr-4">
          <h3 class="my-8">Yhteystiedot</h3>
          <v-text-field v-model="paamies.puhelin" label="Puhelin" />
          <v-combobox
            v-model="paamies.emailOsoitteet"
            :items="[]"
            :delimiters="[',', ';']"
            chips
            append-icon=""
            multiple
            deletable-chips
            label="Sähköpostiosoite"
            type="text"
            :rules="[$validationRules.emailArray]"
          />
          <v-text-field
            v-model="paamies.verkkolasku_osoite"
            label="Verkkolaskuosoite"
            :error-messages="verkkolaskuosoiteErrors"
          />
          <v-select
            v-model="paamies.verkkolasku_operaattori"
            clearable
            class="py-1"
            label="Verkkolaskuoperaattori"
            :filter="verkkolaskuoperaattoriFilter"
            :error-messages="verkkolaskuoperaattoriErrors"
            :items="verkkolaskuoperaattorit"
            item-text="autocomplete_selite"
            item-value="id"
          />
          <v-text-field
            v-model="paamies.iban"
            label="IBAN"
            :rules="[$validationRules.required, $validationRules.isValidIBAN]"
            class="required mt-6"
          />
          <v-text-field
            v-model="paamies.bic"
            label="BIC"
            :rules="[$validationRules.required, $validationRules.isValidBIC]"
            class="required"
          />
          <v-text-field
            v-model="paamies.viestikirjeeseen"
            label="Viesti kirjeeseen"
          />
        </v-col>
      </v-row>
      <!-- <template :v-if="process.env.VUE_APP_DEV_MODE== 1" >{{ paamies }}</template> -->

      <v-row class="mt-4 pa-3" justify="end">
        <v-btn large class="mr-4" @click="$emit('close')">Peruuta</v-btn>
        <v-btn large color="action" @click="tallenna">Tallenna</v-btn>
      </v-row>
    </template>
  </v-form>
</template>

<script>
import { mapState } from "vuex";
import AutocompleteYTJHaku from "@/components/AutocompleteYTJHaku";
import { PaamieshallintaPaamies } from "@/class/Paamies";

export default {
  name: "FormPaamies",
  components: {
    AutocompleteYTJHaku,
  },
  props: {
    // Päämiehen sisältävä objekti kuten sopimus. Jos sitä ei ole, ollaan päämieshallinnassa luomassa
    // uutta päämiestä.
    kaareObjekti: {
      type: Object,
      required: false,
      default() {
        return null;
      },
    },
    onAuki: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      ilmoitetutOlemassaOlevatNimet: [],
      loading: false,
      paamies: new PaamieshallintaPaamies(),
      paateltyTyyppi: "",
      paatteleTyyppiTimeout: null,
      tarkistaOlemassaOlevaNimiTimeout: null,
      tarkistaOlemassaOlevaTunnusTimeout: null,
      tarkistaTunnuksenOikeellisuusTimeout: null,
      tunnusErrors: [],
      verkkolaskuosoiteErrors: [],
      verkkolaskuoperaattoriErrors: [],
    };
  },
  computed: {
    ...mapState({
      verkkolaskuoperaattorit: (state) => state.yleinen.verkkolaskuoperaattorit,
      maat: (state) => state.yleinen.maat,
    }),
    verkkolaskuosoiteJaOperaattori() {
      return `${this.paamies.verkkolasku_osoite}|${this.paamies.verkkolasku_operaattori}`;
    },
    paamiesNimiTunnus() {
      return `${this.paamies.nimi}|${this.paamies.tunnus}`;
    },
  },
  watch: {
    onAuki: {
      immediate: true,
      handler(onAuki) {
        this.$nextTick().then(() => {
          this.$refs.formPaamies.resetValidation();
        });

        if (onAuki) {
          if (this.kaareObjekti && this.kaareObjekti.paamies.id) {
            // Päämiehen muokkaus
            this.haePaamiesTiedot(this.kaareObjekti.paamies.id);
          } else {
            // Uusi päämies
            this.paamies = new PaamieshallintaPaamies();
          }
        }
      },
    },
    paamiesNimiTunnus: {
      deep: true,
      async handler(arvot) {
        const [nimi, tunnus] = arvot.split("|");

        clearTimeout(this.paatteleTyyppiTimeout);

        if (tunnus && tunnus.length >= 9) {
          this.paatteleTyyppiTimeout = setTimeout(async () => {
            try {
              const query = new URLSearchParams();
              query.append("nimi", nimi);
              query.append("tunnus", tunnus);
              query.append("method", "paattele_tyyppi");

              const request = await this.$doRequestWithTimeout(
                this.$api.Yleinen,
                {
                  url: "tarkista/",
                  query: query.toString(),
                },
                "pushRequest"
              );
              if (!request.success) throw new this.$HttpError(request);

              // Tyypin päättely ei ole vedenpitävä. Epäselvissä tapauksissa palautetaan henkilö, joten
              // henkilö-tyyppiä ei haluta ylikirjoittaa inputtiin.
              if (request.result.body === "Y") this.paateltyTyyppi = "Y";
            } catch (e) {
              this.$sentryCaptureCustom(e);
            }
          }, 300);
        } else {
          this.paateltyTyyppi = "";
        }
      },
    },
    verkkolaskuosoiteJaOperaattori: {
      handler() {
        if (
          this.paamies.verkkolasku_osoite &&
          !this.paamies.verkkolasku_operaattori
        ) {
          this.verkkolaskuoperaattoriErrors = [
            "Syötä myös verkkolaskuoperaattori",
          ];
        } else {
          this.verkkolaskuoperaattoriErrors = [];
        }

        if (
          !this.paamies.verkkolasku_osoite &&
          this.paamies.verkkolasku_operaattori
        ) {
          this.verkkolaskuosoiteErrors = ["Syötä myös verkkolaskuosoite"];
        } else {
          this.verkkolaskuosoiteErrors = [];
        }
      },
    },
  },
  methods: {
    async haePaamiesTiedot(id) {
      this.loading = true;
      try {
        const request = await this.$api.Paamieshallinta.pushRequest({
          url: `${id}/`,
          method: "GET",
        });

        if (!request.success)
          throw new this.$HttpError(
            request,
            "Päämiestietojen haku epäonnistui"
          );

        this.paamies = new PaamieshallintaPaamies(request.result.body);
      } catch (e) {
        this.$oletusVirheenkasittely(e);
      } finally {
        this.loading = false;
      }
    },
    async tallenna() {
      if (!this.$refs.formPaamies.validate()) return;

      const httpMethod = this.paamies.id ? "PUT" : "POST";
      const url = this.paamies.id ? `${this.paamies.id}/` : "";

      try {
        const request = await this.$doRequestWithTimeout(
          this.$api.Paamieshallinta,
          {
            method: httpMethod,
            url: url,
            body: this.paamies.getPostData(),
          },
          "doSingleRequest"
        );

        if (!request.success) throw new this.$HttpError(request);

        this.$emit("paamiesTallennettu", request.result.body);
        this.$emit("close");
      } catch (e) {
        if (!this.$onkoUiVirhe(e)) {
          this.$sentryCaptureCustom(e);
          this.$naytaVirheilmoitus("Päämiehen tallennus epäonnistui!");
        }
      }
    },
    verkkolaskuoperaattoriFilter(item, hakusana) {
      const id = item.id.toLowerCase();
      const nimi = item.nimi.toLowerCase();
      const hakuteksti = hakusana.toLowerCase();

      return id.indexOf(hakuteksti) > -1 || nimi.indexOf(hakuteksti) > -1;
    },
    teeTunnusTarkistukset(
      tunnus,
      ohitaPituudenTarkistus = false,
      blur = false
    ) {
      clearTimeout(this.tarkistaTunnuksenOikeellisuusTimeout);
      clearTimeout(this.tarkistaOlemassaOlevaTunnusTimeout);

      if (
        !tunnus ||
        (tunnus.length < 9 && !ohitaPituudenTarkistus) ||
        this.paamies.maa !== "FI"
      )
        return;

      this.tarkistaTunnuksenOikeellisuus(tunnus);
      if (blur) this.tarkistaOlemassaOlevaTunnus(tunnus);
    },
    async tarkistaTunnuksenOikeellisuus(tunnus) {
      this.tunnusErrors = [];

      this.tarkistaTunnuksenOikeellisuusTimeout = setTimeout(async () => {
        try {
          const query = new URLSearchParams();
          query.append("method", "tarkista_tunnus");
          query.append("tunnus", tunnus);
          query.append("tyyppi", null);

          const request = await this.$doRequestWithTimeout(
            this.$api.Yleinen,
            {
              url: "tarkista/",
              query: query.toString(),
            },
            "pushRequest"
          );
          if (!request.success) throw new this.$HttpError(request);

          const tunnusOnOikeellinen = request.result.body;
          if (!tunnusOnOikeellinen && this.paamies.tunnus) {
            this.tunnusErrors = ["Tarkista tunnus"];
          } else {
            this.tunnusErrors = [];
          }
        } catch (e) {
          this.$sentryCaptureCustom(e);
        }
      }, 300);
    },
    tarkistaOlemassaOlevaTunnus(tunnus) {
      if (this.kaareObjekti) return;

      this.tarkistaOlemassaOlevaTunnusTimeout = setTimeout(async () => {
        try {
          const query = new URLSearchParams();
          query.append("method", "tarkista_paamies_tunnus");
          query.append("tunnus", tunnus.trim());

          const request = await this.$doRequestWithTimeout(
            this.$api.Yleinen,
            {
              url: "tarkista/",
              query: query.toString(),
            },
            "pushRequest"
          );
          if (!request.success) throw new this.$HttpError(request);

          const body = request.result.body;

          if (body && body.paamiehet && body.paamiehet.length) {
            let paamiehetStr = "";
            body.paamiehet.map((pm) => {
              paamiehetStr += `\n- ${pm.nimi} (${pm.id})`;
            });

            this.$naytaVaroitusilmoitus(
              "Syötetyllä tunnuksella on jo olemassa oleva päämies:" +
                paamiehetStr,
              { position: "top-right" }
            );
          }
        } catch (e) {
          this.$sentryCaptureCustom(e);
        }
      }, 500);
    },
    /**
     * Tarkistaa onko käyttäjän päämiehien nimissä jo syötetyn kaltainen nimi.
     * Ks. onlinerestapin metodi _tarkista_paamies_nimi
     */
    async tarkistaOlemassaOlevaNimi(syote) {
      clearTimeout(this.tarkistaOlemassaOlevaNimiTimeout);

      let nimi = syote.trim();
      if (this.paamies.id || !nimi || nimi.length <= 3) return;

      this.tarkistaOlemassaOlevaNimiTimeout = setTimeout(async () => {
        try {
          const query = new URLSearchParams();
          query.append("method", "tarkista_paamies_nimi");
          query.append("nimi", nimi);

          const request = await this.$doRequestWithTimeout(
            this.$api.Yleinen,
            {
              url: "tarkista/",
              query: query.toString(),
            },
            "pushRequest"
          );
          if (!request.success) throw new this.$HttpError(request);

          const body = request.result.body;

          if (body && body.paamiehet && body.paamiehet.length) {
            let paamiehetStr = "";
            body.paamiehet.map((pm) => {
              if (!this.ilmoitetutOlemassaOlevatNimet.includes(pm.nimi))
                // Ilmoitetaan kustakin nimestä vain kerran, jotta käyttöliittymä ei ole häiritsevä.
                paamiehetStr += `\n- ${pm.nimi} (${pm.id})`;
              this.ilmoitetutOlemassaOlevatNimet.push(pm.nimi);
            });

            if (paamiehetStr) {
              this.$naytaInfoilmoitus(
                "Samankaltaisella nimellä löytyi olemassa olevia päämiehiä:" +
                  paamiehetStr,
                {
                  timeout: 10000,
                  position: "top-right",
                }
              );
            }
          }
        } catch (e) {
          this.$sentryCaptureCustom(e);
        }
      }, 1000);
    },
    asetaYrityksenTiedotLomakkeelle(yritys) {
      this.paamies = Object.assign(this.paamies, yritys, yritys.postiosoite);
      if (yritys.postiosoite && yritys.postiosoite.numero)
        this.paamies.postinumero = yritys.postiosoite.numero;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-text-field,
.v-select {
  padding-top: 0px;
  margin-top: 4px;
}

.hidden {
  visibility: hidden;
}
</style>
