import { isNumeric } from "@/utils/misc";

export class Tuote {
  constructor(preset = {}) {
    this.ahinta = null;
    this.alvtunnus_id = null;
    this.hankintahinta = null;
    this.id = null;
    this.koodi = "";
    this.laatu = "";
    this.nykyinen_varastotilanne = null; // Read-only
    this.paamies = {};
    this.selite = "";
    this.sisalv = false;
    this.tuoteryhma = {};
    this.valuutta = "";
    this.vapaateksti = "";
    this.varastotilanne = null;

    Object.assign(this, preset);
    this.varastotilanne = this.nykyinen_varastotilanne;
  }

  getPostData() {
    const postData = {
      ahinta: this.ahinta,
      alvtunnus_id: this.alvtunnus_id,
      koodi: this.koodi,
      paamies_id: this.paamies.id,
      sisalv: this.sisalv,
      tuoteryhma_id: this.tuoteryhma.id,
    };

    if (this.id) postData.id = this.id;
    if (this.laatu) postData.laatu = this.laatu;
    if (this.hankintahinta) postData.hankintahinta = this.hankintahinta;
    if (this.selite) postData.selite = this.selite;
    if (this.valuutta) postData.valuutta = this.valuutta;
    if (this.vapaateksti) postData.vapaateksti = this.vapaateksti;
    if (this.varastotilanne) postData.varastotilanne = this.varastotilanne;

    return postData;
  }

  validate() {
    let virheet = [];

    // katsotaan vain pakolliset kentät
    if (!this.koodi) virheet.push("koodi");
    if (!isNumeric(this.ahinta)) virheet.push("ahinta");
    if (!isNumeric(this.alvtunnus_id)) virheet.push("alvtunnus_id");
    if (!this.tuoteryhma || !this.tuoteryhma.id) virheet.push("tuoteryhma");

    return virheet;
  }
}

export class Tuoteryhma {
  constructor(preset = {}) {
    this.id = null;
    this.nimi = "";
    this.paamies = {};

    Object.assign(this, preset);
  }

  getPostData() {
    const postData = {
      nimi: this.nimi,
      paamies_id: this.paamies.id,
    };

    if (this.id) postData.id = this.id;

    return postData;
  }

  validate() {
    let virheet = [];

    // katsotaan vain pakolliset kentät
    if (!this.nimi) virheet.push("nimi");
    if (!this.paamies || !this.paamies.id) virheet.push("paamies");

    return virheet;
  }
}
