<template>
  <div>
    <div v-if="naytaMaksutiedot">
      <h2 class="my-4">
        {{ $t("common:paymentInfo") }}
      </h2>
      <FormAsetuksetMaksutiedot
        v-model="maksutiedotFormData"
        @submit="maksutiedotSubmit"
      />
      <v-divider class="my-6" />
    </div>
    <v-row>
      <v-col>
        <CardTitle
          color="black--text"
          :text="$t('common:settings.invoiceLogo')"
          :center="false"
        />
        <div class="logo-content">
          <div
            v-if="paamieslogo !== null"
            class="d-flex align-left app-bg--white rounded"
          >
            <v-img
              alt="Logo"
              class="mx-4"
              contain
              :src="paamieslogo.src"
              aspect-ratio="1"
              :height="$vuetify.breakpoint.smAndDown ? 40 : 100"
              position="left"
            />
          </div>
          <div v-else class="d-flex align-left app-bg--white rounded">
            {{ $t("common:settings.logoNotUploaded") }}
          </div>
          <div class="logo-buttons">
            <v-btn
              v-if="
                $permission.checkSinglePerm('onlinerestapi.onlinerestapi_lasku')
              "
              class="mt-3 align-self-start d-block"
              color="action"
              @click="avaaPaamiesLogoDialog"
            >
              {{ $t("common:settings.uploadLogo") }}
            </v-btn>
            <v-btn
              v-if="paamieslogo !== null"
              class="mt-3 align-self-start d-block"
              color="error"
              @click="paamiesLogoRemove"
            >
              {{ $t("common:settings.removeLogo") }}
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-divider class="my-6" />
    <CardTitle
      color="black--text"
      :text="$t('common:settings.invoiceLetterText')"
      :center="false"
    />
    <div class="teksti-content">
      <FormAsetuksetPaamiesasetukset
        v-model="paamiesasetuksetFormData"
        @submit="paamiesasetuksetSubmit"
      />
    </div>
    <div v-if="$permission.checkSinglePerm('onlinerestapi.onlinerestapi_beta')">
      <v-divider class="my-6" />
      <h2 class="my-4">Laskunumero (2.vaihe)</h2>
      <ul>
        <li>seuraavan laskunumeron valinta</li>
        <li>laskunumeron etuliitteen valinta</li>
        <li>laskunumeron takaliiteen valinta</li>
      </ul>
      <h2 class="my-4">Oletusasetukset (2.vaihe)</h2>
      <ul>
        <li>sähköpostiviestin saatesanat oletuksena</li>
        <li>oletusasetus: laskulla näkyvä teksti ennen tuoterivejä</li>
        <li>oletusasetus: (vain yritykset): viivästyskorko</li>
        <li>oletusasetus: maksuehto</li>
      </ul>
    </div>
    <CardDialog
      ref="refLogoLataus"
      :dialog-auki="paamiesLogoDialogAuki"
      title="Lisää laskukirjeen logo"
      @close="paamiesLogoDialogAuki = false"
    >
      <template v-if="paamieslogo !== null">
        <v-card
          class="mx-4 mb-4 pa-4 d-flex justify-space-between"
          color="info"
        >
          <div>
            <v-icon dark class="mr-2">info</v-icon>
            <span class="text-subtitle-1 white--text">Logo on jo lisätty.</span>
          </div>
        </v-card>
        <v-card class="mx-4 mb-4 pa-4" color="info">
          <v-icon dark class="mr-2">info</v-icon>
          <span class="text-subtitle-1 white--text">
            Uuden logon lisääminen korvaa vanhan logon.
          </span>
        </v-card>
      </template>
      <FormLiite
        v-model="paamiesLogoFormData"
        class="pa-4"
        :loading="paamiesLogoFormLoading"
        :on-auki="paamiesLogoDialogAuki"
        tarkista-logon-kuvasuhde="true"
        @submit="paamiesLogoSubmit"
        @close="paamiesLogoDialogAuki = false"
      ></FormLiite>
    </CardDialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FormAsetuksetPaamiesasetukset from "@/components/FormAsetuksetPaamiesasetukset";
import FormAsetuksetMaksutiedot from "@/components/FormAsetuksetMaksutiedot";
import FormLiite from "@/components/FormLiite";

export default {
  name: "AsetuksetLaskutus",
  components: {
    FormAsetuksetMaksutiedot,
    FormLiite,
    FormAsetuksetPaamiesasetukset,
  },
  data() {
    return {
      maksutiedotFormData: {},
      paamiesasetuksetFormData: {},
      paamiesLogoFormData: {},
      paamiesLogoFormLoading: false,
      paamiesLogoDialogAuki: false,
      info: {
        name: "",
        src: "",
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      paamies: (state) => state.user.valittuPaamies,
      paamieslogo: (state) => state.asetukset.paamieslogo,
    }),
    naytaMaksutiedot() {
      // Maksutietoja saa muokata vain, jos päämiehellä on palvelusopimus, joka käyttää päämiehen
      // omia maksutietoja
      if (
        this.$permission.checkSinglePerm(
          "onlinerestapi.onlinerestapi_palvelusopimus"
        ) &&
        this.paamies.palvelusopimus &&
        this.paamies.palvelusopimus.omat_maksutiedot
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    "paamies.laskutusmaksutieto": {
      immediate: true,
      deep: true,
      handler() {
        if (this.naytaMaksutiedot) {
          this.maksutiedotFormData = {
            iban: this.paamies.laskutusmaksutieto.iban,
            bic: this.paamies.laskutusmaksutieto.bic,
          };
        }
      },
    },
    "paamies.viestikirjeeseen": {
      immediate: true,
      handler() {
        if (
          this.$permission.checkSinglePerm("onlinerestapi.onlinerestapi_lasku")
        ) {
          this.paamiesasetuksetFormData = {
            viestikirjeeseen: this.paamies?.viestikirjeeseen || "",
          };
        }
      },
    },
  },
  methods: {
    async paamiesasetuksetSubmit() {
      try {
        const request = await this.$doRequestWithTimeout(
          this.$api.Paamies,
          {
            method: "put",
            url: `${this.paamies.id}/toiminnot/laskutusasetukset/`,
            body: this.paamiesasetuksetFormData,
          },
          "doSingleRequest"
        );

        if (!request.success) throw new this.$HttpError(request);

        this.$store.commit(
          "user/setValittuPaamiesPaamiesasetukset",
          this.paamiesasetuksetFormData
        );

        this.$naytaOnnistumisilmoitus(
          this.$t("common:settings.invoicingSettingsSaved")
        );
      } catch (e) {
        this.$oletusVirheenkasittely(
          e,
          this.$t("common:settings.invoicingSettingsSaveFailed")
        );
      }
    },
    async maksutiedotSubmit() {
      try {
        const request = await this.$doRequestWithTimeout(
          this.$api.Paamies,
          {
            method: "put",
            url: `laskutusmaksutieto/${this.paamies.laskutusmaksutieto.id}/`,
            body: this.maksutiedotFormData,
          },
          "doSingleRequest"
        );

        if (!request.success) throw new this.$HttpError(request);

        this.$store.commit(
          "user/setValittuPaamiesMaksutiedot",
          this.maksutiedotFormData
        );

        this.$naytaOnnistumisilmoitus(
          this.$t("common:settings.paymentInfoSaved")
        );
      } catch (e) {
        this.$oletusVirheenkasittely(
          e,
          this.$t("common:settings.paymentInfoSaveFailed")
        );
      }
    },
    avaaPaamiesLogoDialog() {
      this.paamiesLogoDialogAuki = true;
    },
    async haeTalletettuPaamieslogo() {
      try {
        await this.$store.dispatch("asetukset/haeTalletettuPaamieslogo");
      } catch (e) {
        this.$oletusVirheenkasittely(
          e,
          this.$t("common:settings.logoFetchFailed")
        );
      }
    },
    async paamiesLogoSubmit() {
      try {
        const formData = new FormData();
        formData.set("attachment_file", this.paamiesLogoFormData.tiedosto);

        const request = await this.$doRequestWithTimeout(
          this.$api.WithAuthService,
          {
            method: "POST",
            body: formData,
            url: `paamies_logo/${this.paamies.id}/`,
          }
        );

        if (!request.success) throw new this.$HttpError(request);

        this.paamiesLogoDialogAuki = false;
        this.$naytaOnnistumisilmoitus("Logo lisätty!");
      } catch (e) {
        this.$oletusVirheenkasittely(e, "Logon lisääminen ei onnistunut!");
      }
      this.haeTalletettuPaamieslogo();
    },
    async paamiesLogoRemove() {
      try {
        const result = await this.$swal({
          text: this.$t("common:settings.removeLogoSure"),
          showCancelButton: true,
          focusCancel: true,
          cancelButtonText: this.$t("cancel"),
          confirmButtonText: this.$t("delete"),
        });

        if (!result.value) return;

        const request = await this.$doRequestWithTimeout(
          this.$api.WithAuthService,
          {
            method: "DELETE",
            url: `paamies_logo/${this.paamies.id}`,
          }
        );
        if (!request.success) throw new this.$HttpError(request);
        this.$store.commit("asetukset/setPaamieslogo", null);
        this.$naytaOnnistumisilmoitus("Laskukirjeen logo poistettu!");
      } catch (e) {
        this.$oletusVirheenkasittely(e, "Logon poistaminen ei onnistunut!");
      }
    },
  },
};
</script>
<style scoped lang="scss">
.logo-buttons {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.dialog-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  min-height: 30vh;
  min-width: 30vw;
  gap: 1rem;
}
.logo-content,
.teksti-content {
  padding: 0em 1em;
}
</style>
