<template>
  <v-card :class="cardClass" color="info" width="480">
    <v-row>
      <v-col class="pa-0 d-flex justify-center align-center" cols="2">
        <v-icon dark x-large>sentiment_dissatisfied</v-icon>
      </v-col>
      <v-col cols="10" class="pa-0">
        <span class="text-subtitle-1" :class="tekstiClass">{{ teksti }}</span>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "CardPahoittelu",
  props: {
    cardClass: {
      type: String,
      required: false,
      default: "card-pahoittelu pa-4",
    },
    teksti: {
      type: String,
      required: false,
      default:
        "Olemme pahoillamme, mutta tietojen lataus epäonnistui. Yritä hetken kuluttua uudelleen. Ongelman jatkuessa ota yhteys asiakaspalveluun.",
    },
    tekstiClass: {
      type: String,
      required: false,
      default: "white--text",
    },
  },
};
</script>

<style lang="scss" scoped>
.card-pahoittelu {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(calc(50vw - 50%), calc(50vh - 50%));
}
</style>
