<template>
  <div v-if="palvelusopimusId">
    <div class="sub-container">
      <CardTitle
        color="black--text"
        :text="$t('common:settings.subscription')"
        :center="false"
      />
      <v-card class="sub-card" flat outlined>
        <v-radio-group
          v-model="palvelusopimusId"
          hide-details
          class="radio"
          :items="palvelusopimusItems"
          :disabled="!onkoVoimassaolevaTilausAktiivinen"
        >
          <div class="subscription">
            <v-radio :value="palvelusopimukset[0].id"></v-radio>
            <div class="sub-info">
              <div>{{ palvelusopimukset[0].nimi }}</div>
              <div>
                {{ palvelusopimukset[0].kkhinta | formatSumToFixed2 }} /
                {{ $t("common:monthShorthand") }} ({{
                  palvelusopimukset[0].vuosihinta | formatSumToFixed2
                }}
                / {{ $t("common:yearLowerCase") }})
              </div>
              <div>{{ palvelusopimus0Selite }}</div>
            </div>
          </div>

          <v-divider width="100%"></v-divider>
          <div class="subscription">
            <v-radio :value="palvelusopimukset[1].id"></v-radio>
            <div class="sub-info">
              <div>{{ palvelusopimukset[1].nimi }}</div>
              <div>
                {{ palvelusopimukset[1].kkhinta | formatSumToFixed2 }} /
                {{ $t("common:monthShorthand") }} ({{
                  palvelusopimukset[1].vuosihinta | formatSumToFixed2
                }}
                / {{ $t("common:yearLowerCase") }})
              </div>
              <div>{{ palvelusopimus1Selite }}</div>
            </div>
          </div>
          <v-divider width="100%"></v-divider>
        </v-radio-group>
      </v-card>
      <div class="sub-btn">
        <v-btn
          class="action width330px"
          :disabled="!onkoTallennaTilausSallittu"
          @click="vaihdaSopimus()"
        >
          {{ $t("common:settings.saveSubscription") }}
        </v-btn>
      </div>
    </div>
    <div class="billing-container">
      <CardTitle
        color="black--text"
        :text="$t('common:settings.changeInvoicingPeriod')"
        :center="false"
      />

      <div class="switch">
        <div class="switch-box">
          <v-switch
            v-model="palvelusopimusLaskutusjakso"
            true-value="VUOSI"
            false-value="KUUKAUSI"
            :disabled="!onkoVoimassaolevaTilausAktiivinen"
          ></v-switch>
          <div>{{ $t("common:settings.yearlyBilling") }}</div>
          <div>({{ $t("common:settings.saveTenPercent") }})</div>
        </div>
        <div class="switch-btn">
          <v-btn
            class="action width330px"
            :disabled="
              !(
                onkoVoimassaolevaTilausAktiivinen &&
                onkoVaihdettuLaskutusjaksovalintaa
              )
            "
            @click="vaihdaLaskutusjakso()"
          >
            {{ $t("common:settings.saveInvoicingPeriod") }}
          </v-btn>
        </div>
      </div>
    </div>
    <div class="history-container">
      <CardTitle
        color="black--text"
        :text="$t('common:settings.history')"
        :center="false"
      />
      <v-tabs
        v-model="selectedTab"
        background-color="primary"
        color="grey lighten-4"
        slider-color="transparent"
        show-arrows="mobile"
        centered
      >
        <v-tab
          v-for="item in tabs"
          :key="item.index"
          :href="`#${item.id}`"
          :ripple="false"
        >
          <p>{{ item.label }}</p>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="selectedTab">
        <div v-show="selectedTab === 'tilaushistoria'" class="pb-6">
          <v-data-table
            :headers="tilausHeaders"
            :items="tilaukset"
            item-key="id"
            sort-by="alkamispaiva"
            class="elevation-1"
            :dense="tiivisNakyma"
            :hide-default-footer="asetukset.tilauksetKokonaisLkm <= 3"
            :server-items-length="asetukset.tilauksetKokonaisLkm"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            :footer-props="{
              'items-per-page-options': [itemsPerPage],
              'items-per-page-text': 'Rivejä per sivu:',
              'items-per-page-all-text': 'Kaikki',
            }"
          >
            <template #item="{ item }">
              <TableRow :data="item">
                <td class="text-no-wrap text-left">
                  {{ item.laskutusjakso }}
                </td>
                <td class="text-no-wrap text-left">
                  {{ $dateFnsFormat(item.alkamispaiva) }}
                </td>
                <td class="text-no-wrap text-left">
                  {{ $dateFnsFormat(item.paattymispaiva) }}
                </td>
                <td class="text-no-wrap text-left">
                  {{ item.selite }}
                </td>
                <td class="text-no-wrap text-left">
                  {{ $dateFnsFormat(item.seuraava_laskutuspaiva) }}
                </td>
                <td class="text-no-wrap text-left">
                  {{ getTilausTila(item.tila) }}
                </td>
              </TableRow>
            </template>
          </v-data-table>
        </div>
        <div v-show="selectedTab === 'maksuhistoria'" class="pb-4">
          <v-data-table
            :headers="paamiesLaskuHeaders"
            :items="asetukset.paamieslaskut"
            item-key="id"
            sort-by="erapaiva"
            class="elevation-1"
            :dense="tiivisNakyma"
            :hide-default-footer="asetukset.paamieslaskutKokonaisLkm <= 3"
            :server-items-length="asetukset.paamieslaskutKokonaisLkm"
            :page.sync="laskuPage"
            :items-per-page="itemsPerPage"
            :footer-props="{
              'items-per-page-options': [itemsPerPage],
              'items-per-page-text': 'Rivejä per sivu:',
              'items-per-page-all-text': 'Kaikki',
            }"
          >
            <template #item="{ item }">
              <TableRow :data="item">
                <td class="text-no-wrap text-left">
                  {{ $dateFnsFormat(item.laskupaiva) }}
                </td>
                <td class="text-no-wrap text-left">
                  {{ $dateFnsFormat(item.erapaiva) }}
                </td>
                <td class="text-no-wrap text-left">
                  {{ item.selite }} - {{ item.laskutusjakso }}
                </td>
                <td class="text-no-wrap text-left">
                  {{ item.summa | formatSumToFixed2 }}
                </td>
                <td class="text-no-wrap text-left">
                  {{ item.avoinna | formatSumToFixed2 }}
                </td>
                <td class="text-no-wrap text-left">
                  <div v-if="item.tila === 'A'">
                    {{ $t("common:open") }}
                  </div>
                  <div v-if="item.tila === 'M'">
                    {{ $t("common:paid") }}
                  </div>
                </td>
                <td class="text-no-wrap text-left">
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-btn
                        text
                        icon
                        :small="tiivisNakyma"
                        v-on="on"
                        @click="haeLaskukopio(item)"
                      >
                        <v-icon :small="tiivisNakyma">
                          anitta-icon-file-download
                        </v-icon>
                      </v-btn>
                    </template>
                    <template #default>
                      <span>Lataa laskukopio</span>
                    </template>
                  </v-tooltip>
                </td>
              </TableRow>
            </template>
          </v-data-table>
        </div>
      </v-tabs-items>
    </div>
    <div class="cancel-container">
      <div v-if="onkoVoimassaolevaTilausAktiivinen" class="cancel-sub">
        <div>
          <CardTitle
            color="black--text"
            :text="$t('common:settings.cancelSubscription')"
          />
        </div>

        <div class="cs-content">
          {{ $t("common:settings.youCanCancelAnytime") }}
        </div>
        <div class="cs-btn">
          <v-btn class="grey" @click="peruutaTilaus()">
            {{ $t("common:settings.cancelSubscription") }}
          </v-btn>
        </div>
      </div>
      <div v-if="onkoVoimassaolevaTilausPeruutettu" class="cancel-sub">
        <div>
          <CardTitle
            color="black--text"
            :text="$t('common:settings.continueCanceledSubscription')"
          />
        </div>

        <div class="cs-content">
          {{ $t("common:settings.youCanContinueAnytime") }}
        </div>

        <div class="cs-btn">
          <v-btn class="grey" @click="aktivoiTilaus()">
            {{ $t("common:settings.continueCanceledSubscription") }}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="else">
    <h2>
      {{ $t("common:settings.creditorLacksServiceAgreement") }}
    </h2>
  </div>
</template>

<script>
import { Tilaustilat } from "@/utils/constants";
import FormMixin from "@/mixins/FormMixin";
import { mapState } from "vuex";
import TiivisNakymaMixin from "@/mixins/TiivisNakymaMixin";
import { PalvelusopimusTilat } from "@/class/Paamies";

export default {
  name: "AsetuksetTilaus",
  mixins: [FormMixin, TiivisNakymaMixin],
  data() {
    return {
      palvelusopimusId: null,
      vuexModuleName: "asetukset",
      palvelusopimusLaskutusjakso: null,
      tilaustilat: Tilaustilat,
      page: 1,
      laskuPage: 1,
      itemsPerPage: 10,
      selectedTab: "tilaushistoria",
      tilausHeaders: [
        {
          text: this.$t("common:settings.invoicingPeriod"),
          value: "laskutusjakso",
          sortable: false,
        },
        {
          text: this.$t("common:settings.startDate"),
          value: "alkamispaiva",
          sortable: false,
        },
        {
          text: this.$t("common:settings.endDate"),
          value: "paattymispaiva",
          sortable: false,
        },
        {
          text: this.$t("common:settings.description"),
          value: "selite",
          sortable: false,
        },
        {
          text: this.$t("common:settings.nextBillingDate"),
          value: "seuraava_laskutuspaiva",
          sortable: false,
        },
        { text: this.$t("common:status"), value: "tila", sortable: false },
      ],
      paamiesLaskuHeaders: [
        {
          text: this.$t("common:settings.billingDate"),
          value: "laskupaiva",
          sortable: false,
        },
        {
          text: this.$t("common:settings.dueDate"),
          value: "erapaiva",
          sortable: false,
        },
        {
          text: this.$t("common:settings.description"),
          value: "selite",
          sortable: false,
        },
        { text: this.$t("common:sum"), value: "summa", sortable: false },
        { text: this.$t("common:open"), value: "avoinna", sortable: false },
        { text: this.$t("common:status"), value: "tila", sortable: false },
        {
          text: this.$t("common:actions"),
          value: "toiminnot",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapState(["user", "asetukset"]),
    ...mapState({
      palvelusopimukset: (state) => state.user.palvelusopimukset,
      paamiehenPalvelusopimus: (state) =>
        state.user.valittuPaamies.palvelusopimus,
      voimassaOlevanTilauksenLaskutusjakso: (state) =>
        state.asetukset.voimassaOlevanTilauksenLaskutusjakso,
      paamies: (state) => state.user.valittuPaamies,
      tilaukset: (state) => state.asetukset.tilaukset,
    }),
    palvelusopimus0Selite() {
      return this.palvelusopimusSelite(0);
    },
    palvelusopimus1Selite() {
      return this.palvelusopimusSelite(1);
    },
    palvelusopimusItems() {
      // Ei sallita vaihtoa palvelusopimukseen, joka päämiehellä on jo käytössä
      const disablePalvelusopimusKaytossa = function (ps, ps_id_kaytossa) {
        if (ps.id === ps_id_kaytossa)
          return { selite: ps.nimi, value: ps.id, disabled: true };

        return { selite: ps.nimi, value: ps.id, disabled: false };
      };

      return this.palvelusopimukset.map((ps) =>
        disablePalvelusopimusKaytossa(ps, this.paamiehenPalvelusopimus.id)
      );
    },
    onkoVoimassaolevaTilausAktiivinen() {
      return (
        this.asetukset.voimassaOlevanTilauksenTila === Tilaustilat.AKTIIVINEN
      );
    },
    onkoVoimassaolevaTilausPeruutettu() {
      return (
        this.asetukset.voimassaOlevanTilauksenTila === Tilaustilat.PERUUTETTU
      );
    },
    onkoTallennaTilausSallittu() {
      return (
        this.onkoVoimassaolevaTilausAktiivinen &&
        this.onkoVaihdettuPalvelusopimusvalintaa
      );
    },
    onkoVaihdettuLaskutusjaksovalintaa() {
      return (
        this.voimassaOlevanTilauksenLaskutusjakso !==
        this.palvelusopimusLaskutusjakso
      );
    },
    onkoVaihdettuPalvelusopimusvalintaa() {
      return (
        this.user.valittuPaamies?.palvelusopimus?.id !== this.palvelusopimusId
      );
    },
    onkoMuutettuAktiivistaPalvelusopimusta() {
      return (
        this.onkoVaihdettuLaskutusjaksovalintaa ||
        this.onkoVaihdettuPalvelusopimusvalintaa
      );
    },
    tabs() {
      let tabs = [
        {
          id: "tilaushistoria",
          label: this.$t("settings.subscriptionHistory"),
        },
      ];
      tabs.push({
        id: "maksuhistoria",
        label: this.$t("settings.paymentHistory"),
      });
      return tabs;
    },
  },
  watch: {
    page(current, old) {
      this.$store.dispatch("asetukset/haeTilaukset", current);
    },
    laskuPage(current, old) {
      this.$store.dispatch("asetukset/haePaamieslaskut", current);
    },

    paamiehenPalvelusopimus: {
      immediate: true,
      handler() {
        this.palvelusopimusId = this.paamiehenPalvelusopimus?.id;
      },
    },
    voimassaOlevanTilauksenLaskutusjakso: {
      immediate: true,
      handler(current) {
        this.palvelusopimusLaskutusjakso = current;
      },
    },
    onkoMuutettuAktiivistaPalvelusopimusta(current) {
      this.$emit("muokkaus", current);
    },
  },
  methods: {
    palvelusopimusSelite(palvelusopimusIndex) {
      try {
        if (
          this.palvelusopimukset &&
          this.palvelusopimukset.length > 1 &&
          this.palvelusopimukset[0].kkhinta &&
          this.palvelusopimukset[1].kkhinta
        ) {
          if (
            parseFloat(this.palvelusopimukset[0].kkhinta.replace(",", ".")) >
            parseFloat(this.palvelusopimukset[1].kkhinta.replace(",", "."))
          ) {
            if (palvelusopimusIndex === 0) {
              return this.$t("common:settings.automaticPaymentMonitoring");
            } else {
              return this.$t("common:settings.manualCollection");
            }
          }
        }
      } catch (err) {
        this.$oletusVirheenkasittely(err);
      }
      return "";
    },
    getTilausTila(tila) {
      return PalvelusopimusTilat.get(tila);
    },

    async haeLaskukopio(item) {
      try {
        const laskukopio_id = item.kirje_set.slice(-1);
        const request = await this.$doRequestWithTimeout(this.$api.Lasku, {
          url: `toiminnot/hae_paamieslaskukopio/${laskukopio_id}/`,
          params: {
            id: item.id,
          },
        });

        if (!request.success) throw new this.$HttpError(request);

        this.$naytaTiedostonlataamisdialog({
          fileData: request.result.body,
          filename: this.laskukopionTiedostonNimi,
          headers: request.result.headers,
        });
      } catch (e) {
        this.$oletusVirheenkasittely(e);
      }
    },
    async peruutaTilaus() {
      try {
        const vahvistusRequest = await this.$doRequestWithTimeout(
          this.$api.Paamies,
          {
            method: "get",
            url: `${this.paamies.id}/toiminnot/tarkista_peruuta_palvelusopimus/`,
          },
          "doSingleRequest"
        );

        if (!vahvistusRequest.success) {
          throw new this.$HttpError(vahvistusRequest);
        }

        if (vahvistusRequest.result.body?.warning) {
          const result = await this.$swal({
            text: vahvistusRequest.result.body.warning,
            showCancelButton: true,
            focusCancel: true,
            cancelButtonText: this.$t("return"),
            confirmButtonText: this.$t("common:settings.cancelSubscription"),
          });

          if (!result.value) return;
        }

        const request = await this.$doRequestWithTimeout(
          this.$api.Paamies,
          {
            method: "post",
            url: `${this.paamies.id}/toiminnot/peruuta_palvelusopimus/`,
          },
          "doSingleRequest"
        );

        if (!request.success) throw new this.$HttpError(request);

        await this.$store.dispatch("asetukset/haeTilaukset");

        // Asetetaan valintoihin backendin arvo, jotta sivulta poistumisen
        // yhteydessä ei tule varoitusta tietojen muuttumisesta.
        this.palvelusopimusLaskutusjakso =
          this.voimassaOlevanTilauksenLaskutusjakso;
        this.palvelusopimusId = this.paamiehenPalvelusopimus?.id;

        this.$naytaOnnistumisilmoitus(
          this.$t("common:settings.subscriptionCanceled")
        );
      } catch (e) {
        this.$oletusVirheenkasittely(
          e,
          this.$t("common:settings.subscriptionCancelFailed")
        );
      }
    },
    async aktivoiTilaus() {
      try {
        const request = await this.$doRequestWithTimeout(
          this.$api.Paamies,
          {
            method: "post",
            url: `${this.paamies.id}/toiminnot/aktivoi_palvelusopimus/`,
          },
          "doSingleRequest"
        );

        if (!request.success) throw new this.$HttpError(request);

        await this.$store.dispatch("asetukset/haeTilaukset");

        this.$naytaOnnistumisilmoitus(
          this.$t("common:settings.subscriptionActivated")
        );
      } catch (e) {
        this.$oletusVirheenkasittely(
          e,
          this.$t("common:settings.subscriptionActivationFailed")
        );
      }
    },
    async vaihdaSopimus() {
      try {
        const vahvistusRequest = await this.$doRequestWithTimeout(
          this.$api.Paamies,
          {
            method: "get",
            url: `${this.paamies.id}/toiminnot/tarkista_vaihda_palvelusopimus/${this.palvelusopimusId}/`,
          },
          "doSingleRequest"
        );

        if (!vahvistusRequest.success) {
          throw new this.$HttpError(vahvistusRequest);
        }

        if (vahvistusRequest.result.body?.warning) {
          const result = await this.$swal({
            text: vahvistusRequest.result.body.warning,
            showCancelButton: true,
            focusCancel: true,
            cancelButtonText: this.$t("cancel"),
            confirmButtonText: this.$t("common:settings.saveSubscription"),
          });

          if (!result.value) return;
        }

        const body = { palvelusopimus_id: this.palvelusopimusId };
        const request = await this.$doRequestWithTimeout(
          this.$api.Paamies,
          {
            method: "post",
            url: `${this.paamies.id}/toiminnot/vaihda_palvelusopimus/`,
            body: body,
          },
          "doSingleRequest"
        );

        if (!request.success) throw new this.$HttpError(request);

        this.$store.commit("user/setValittuPaamiesPalvelusopimus", {
          palvelusopimusId: this.palvelusopimusId,
        });
        await this.$store.dispatch("asetukset/haeTilaukset");

        this.$naytaOnnistumisilmoitus(
          this.$t("common:settings.subscriptionChanged")
        );
      } catch (e) {
        this.$oletusVirheenkasittely(
          e,
          this.$t("common:settings.subscriptionChangeFailed")
        );
      }
    },
    async vaihdaLaskutusjakso() {
      try {
        const body = { laskutusjakso: this.palvelusopimusLaskutusjakso };
        const request = await this.$doRequestWithTimeout(
          this.$api.Paamies,
          {
            method: "post",
            url: `${this.paamies.id}/toiminnot/vaihda_laskutusjakso/`,
            body: body,
          },
          "doSingleRequest"
        );

        if (!request.success) throw new this.$HttpError(request);

        // Tilausten haku päivittää myös voimassa olevan tilauksen tilan ja laskutusjakson storeen
        await this.$store.dispatch("asetukset/haeTilaukset");

        this.$naytaOnnistumisilmoitus(
          this.$t("common:settings.invoicingPeriodChanged")
        );
      } catch (e) {
        this.$oletusVirheenkasittely(
          e,
          this.$t("common:settings.invoicingPeriodChangeFailed")
        );
      }
    },
  },
};
</script>
<style scoped lang="scss">
.subscription {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  width: 100%;
}
.invoicing-period-header {
  padding-top: 2rem;
  padding-left: 1rem;
}
.switch {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.switch-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
}
.switch-btn {
  display: flex;
  align-self: center;
  justify-self: flex-end;
  margin-right: 1rem;
}
.width330px {
  width: 330px;
}
.sub-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.cancel-sub {
  display: flex;
  flex-direction: column;
}
.cs-content {
  display: flex;
  margin-left: 1em;
}
.cs-btn {
  display: flex;
  margin-left: 1em;
  padding-top: 1rem;
}
.radio {
  margin-top: 0px !important;
}
.else {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sub-btn {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0rem;
}
</style>
