var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefList",
    { attrs: { bordered: true } },
    [
      _c("DefListItem", {
        scopedSlots: _vm._u(
          [
            {
              key: "label",
              fn: function () {
                return [_vm._v(" " + _vm._s(_vm.$t("common:status")) + " ")]
              },
              proxy: true,
            },
            _vm.value.tila && _vm.value.ikoni_ja_vari
              ? {
                  key: "value",
                  fn: function () {
                    return [
                      _c("StatusText", {
                        attrs: {
                          text: _vm.value.tila.selite,
                          color: _vm.value.ikoni_ja_vari.vari,
                        },
                      }),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _c("DefListItem", {
        scopedSlots: _vm._u(
          [
            {
              key: "label",
              fn: function () {
                return [_vm._v(" " + _vm._s(_vm.$t("common:creditor")) + " ")]
              },
              proxy: true,
            },
            _vm.value.paamies
              ? {
                  key: "value",
                  fn: function () {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.value.paamies.nimi) +
                          " (" +
                          _vm._s(_vm.value.paamies.id) +
                          ") "
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _vm.value.toimeksiantovelallinen_set
        ? _c("DefListItem", {
            scopedSlots: _vm._u(
              [
                {
                  key: "label",
                  fn: function () {
                    return [
                      _vm.value.toimeksiantovelallinen_set.length > 1
                        ? _c("span", [_vm._v(" Velalliset ")])
                        : _c("span", [_vm._v(_vm._s(_vm.$t("common:debtor")))]),
                    ]
                  },
                  proxy: true,
                },
                _vm.value.toimeksiantovelallinen_set
                  ? {
                      key: "value",
                      fn: function () {
                        return _vm._l(
                          _vm.value.toimeksiantovelallinen_set,
                          function (taVe) {
                            return _c(
                              "span",
                              { key: taVe.id },
                              [
                                _vm.eiOikeuttaNahdaAsiakastaValueVelallinen(
                                  _vm.value,
                                  taVe
                                )
                                  ? [
                                      _c(
                                        "span",
                                        {
                                          key: taVe.id,
                                          staticClass: "font-italic",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "common:notAbleToShowRestriction"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  : [
                                      _vm._v(
                                        " " + _vm._s(taVe.velallinen.nimi) + " "
                                      ),
                                      taVe.vastuuprosentti
                                        ? _c("span", [
                                            _vm._v(
                                              " (" +
                                                _vm._s(taVe.vastuuprosentti) +
                                                " %) "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _c("br"),
                                    ],
                              ],
                              2
                            )
                          }
                        )
                      },
                      proxy: true,
                    }
                  : null,
              ],
              null,
              true
            ),
          })
        : _vm._e(),
      _c("DefListItem", {
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [_vm._v(" " + _vm._s(_vm.$t("common:caseNo")) + " ")]
            },
            proxy: true,
          },
          {
            key: "value",
            fn: function () {
              return [_vm._v(" " + _vm._s(_vm.value.id) + " ")]
            },
            proxy: true,
          },
        ]),
      }),
      _c("DefListItem", {
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [_vm._v(" " + _vm._s(_vm.$t("common:invoiceNos")) + " ")]
            },
            proxy: true,
          },
          {
            key: "value",
            fn: function () {
              return [
                _c(
                  "InlineList",
                  { staticClass: "pl-0" },
                  _vm._l(_vm.value.saatavarivi_set, function (lasku) {
                    return _c("InlineListItem", {
                      key: lasku.id,
                      attrs: {
                        value: lasku.laskunumero,
                        to: lasku.lasku_id
                          ? {
                              name: "laskutus-nayta",
                              params: { id: lasku.lasku_id },
                            }
                          : null,
                      },
                    })
                  }),
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("DefListItem", {
        scopedSlots: _vm._u(
          [
            {
              key: "label",
              fn: function () {
                return [_vm._v(" " + _vm._s(_vm.$t("common:dueDate")) + " ")]
              },
              proxy: true,
            },
            _vm.value.saatavarivi_set
              ? {
                  key: "value",
                  fn: function () {
                    return [
                      _vm.value.saatavarivi_set.length
                        ? [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$dateFnsFormat(
                                    _vm.value.saatavarivi_set[0].erapaiva
                                  )
                                ) +
                                " "
                            ),
                          ]
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _c("DefListItem", {
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [_vm._v(" " + _vm._s(_vm.$t("common:open")) + " ")]
            },
            proxy: true,
          },
          {
            key: "value",
            fn: function () {
              return [
                _c("strong", [
                  _vm._v(
                    _vm._s(_vm._f("formatSumToFixed2")(_vm.value.avoinna))
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }