<template>
  <div class="phone">
    <div class="phone-cc">
      <v-select
        v-model="countryCode"
        :outlined="outlined"
        :items="countryCodesFormatted"
        item-value="countryCode"
        item-text="comboCode"
      ></v-select>
    </div>
    <div class="phone-nn">
      <v-text-field
        v-model="nationalNumber"
        :outlined="outlined"
        :label="$t('common:phone')"
        :rules="rulesArr"
      ></v-text-field>
    </div>
  </div>
</template>
<script>
import {
  getCountries,
  getCountryCallingCode,
  parsePhoneNumber,
  isValidPhoneNumber,
} from "libphonenumber-js";

export default {
  name: "InputPuhelinNumero",
  model: {
    prop: "value",
    event: "update:value",
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default() {
        return true;
      },
    },
    outlined: {
      type: Boolean,
      default() {
        return false;
      },
    },
    defaultCountryCode: {
      type: String,
      default: "FI",
    },
  },
  emits: ["update:value"],

  data() {
    return {
      countryCodes: getCountries(),
      countryCodesFormatted: [],
      currentCountryCode: this.defaultCountryCode,
      currentNationalNumber: "",
      rulesArr: [],
      rules: {
        validation: (v) =>
          isValidPhoneNumber(this.nationalNumber, this.countryCode) ||
          this.nationalNumber === "" ||
          "Tarkista puhelinnumeron muotoilu",
      },
    };
  },
  computed: {
    countryCode: {
      get() {
        return this.currentCountryCode;
      },
      set(newCountry) {
        this.currentCountryCode = newCountry;
        this.handlePhoneNumberChange({
          phoneNumber: this.nationalNumber,
          countryCode: newCountry,
        });
      },
    },
    nationalNumber: {
      get() {
        return this.currentNationalNumber;
      },
      set(newNationalNumber) {
        this.currentNationalNumber = newNationalNumber;
        this.handlePhoneNumberChange({
          phoneNumber: newNationalNumber,
          countryCode: this.countryCode,
        });
      },
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue, oldValue) {
        // init values (currentCountry & currentNationalNumber) if phone number is provided
        if ((newValue || newValue === "") && newValue !== oldValue) {
          this.handlePhoneNumberChange({ phoneNumber: newValue });
        }
      },
    },
  },
  mounted() {
    this.formatCountryCodes();
    this.initializeValidation();
  },
  methods: {
    formatCountryCodes() {
      this.countryCodes.forEach((e) => {
        this.countryCodesFormatted.push({
          countryCode: e,
          callingCode: getCountryCallingCode(e),
          comboCode: e + " +" + getCountryCallingCode(e),
        });
      });
    },
    initializeValidation() {
      this.rulesArr.push(this.rules.validation);
      if (this.required) {
        this.rulesArr.push(this.$validationRules.required);
      }
    },
    handlePhoneNumberChange({ phoneNumber, countryCode }) {
      let nNumber;
      if (isValidPhoneNumber(phoneNumber, countryCode)) {
        const pNumber = parsePhoneNumber(phoneNumber, countryCode);
        this.currentCountryCode = pNumber.country;
        nNumber = pNumber.nationalNumber;
        this.$emit("update:value", pNumber.formatInternational());
      } else {
        nNumber = phoneNumber;
        this.$emit("update:value", nNumber);
      }

      this.currentNationalNumber = nNumber;
    },
  },
};
</script>
<style lang="scss" scoped>
.phone {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1rem;
}
.phone-cc {
  width: 30%;
}
.phone-nn {
  width: 70%;
}
</style>
