<template>
  <transition name="slidePanel">
    <slot></slot>
  </transition>
</template>

<script>
export default {
  name: "TransitionSlidePanel",
};
</script>

<style lang="scss" scoped>
.slidePanel-enter-active,
.slidePanel-leave-active {
  max-height: 1000px;
  transition: max-height 500ms ease;
}
.slidePanel-enter,
.slidePanel-leave-to {
  max-height: 0;
}
</style>
