<template>
  <DefList :bordered="true">
    <DefListItem>
      <template #label>Tila</template>
      <template v-if="value.tila && value.ikoni_ja_vari" #value>
        <StatusText
          :text="value.tila.selite"
          :color="value.ikoni_ja_vari.vari"
        />
      </template>
    </DefListItem>
    <DefListItem>
      <template #label>Laskunumero</template>
      <template v-if="value.nro" #value>
        {{ value.nro }}
      </template>
    </DefListItem>
    <DefListItem>
      <template #label>Asiakas</template>
      <template v-if="value.asiakas" #value>
        <template v-if="eiOikeuttaNahdaAsiakastaValue(value)">
          <span class="font-italic">Ei voida näyttää tietosuojasyistä</span>
        </template>
        <template v-else>
          {{ value.asiakas.nimi }}
          <template v-if="value.asiakas.nro">
            ({{ value.asiakas.nro }})
          </template>
        </template>
      </template>
    </DefListItem>
    <DefListItem>
      <template #label>Päämies</template>
      <template v-if="value.paamies" #value>
        {{ value.paamies.nimi }}
        <template v-if="value.paamies.id">({{ value.paamies.id }})</template>
      </template>
    </DefListItem>
    <DefListItem>
      <template #label>Laskupäivä</template>
      <template v-if="value.laskupaiva" #value>
        {{ $dateFnsFormat(value.laskupaiva) }}
      </template>
    </DefListItem>
    <DefListItem>
      <template #label>Viite</template>
      <template v-if="value.viite" #value>
        {{ value.viite }}
      </template>
    </DefListItem>
    <DefListItem v-if="value.pm_viite">
      <template #label>PM-viite</template>
      <template #value>
        {{ value.pm_viite }}
      </template>
    </DefListItem>
    <DefListItem v-if="value.viitteemme">
      <template #label>Viitteemme</template>
      <template #value>
        {{ value.viitteemme }}
      </template>
    </DefListItem>
    <DefListItem v-if="value.viitteenne">
      <template #label>Viitteenne</template>
      <template #value>
        {{ value.viitteenne }}
      </template>
    </DefListItem>
    <DefListItem>
      <template #label>Eräpäivä</template>
      <template v-if="value.erapaiva" #value>
        <strong v-if="value.onko_myohassa">
          {{ $dateFnsFormat(value.erapaiva) }} (myöhässä)
        </strong>
        <template v-else>
          {{ $dateFnsFormat(value.erapaiva) }}
        </template>
      </template>
    </DefListItem>
    <DefListItem>
      <template #label>Summa</template>
      <template #value>
        {{ value.summa | formatSumToFixed2 }}
      </template>
    </DefListItem>
    <DefListItem>
      <template #label>Veroton</template>
      <template #value>
        {{ value.veroton | formatSumToFixed2 }}
      </template>
    </DefListItem>
    <DefListItem>
      <template #label>Vero</template>
      <template #value>
        {{ value.vero | formatSumToFixed2 }}
      </template>
    </DefListItem>
    <DefListItem>
      <template #label>Avoinna</template>
      <template #value>
        <strong>{{ value.avoinna | formatSumToFixed2 }}</strong>
      </template>
    </DefListItem>
  </DefList>
</template>

<script>
import StatusText from "@/components/StatusText";
import TrafiLupaMixin from "@/mixins/TrafiLupaMixin";

export default {
  name: "OffCanvasDetailsLasku",
  components: {
    StatusText,
  },
  mixins: [TrafiLupaMixin],
  props: {
    value: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
