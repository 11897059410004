<template>
  <v-form
    ref="formSeliteMuokkaukselle"
    v-model="valid"
    @submit.prevent="submit"
  >
    <v-row>
      <slot></slot>
    </v-row>
    <v-row align="center">
      <v-col class="grow">
        <v-textarea
          v-model="value.selite"
          class="pt-0 mt-0"
          rows="1"
          label="Selite"
          :rules="[$validationRules.required]"
          :hide-details="true"
          required
          auto-grow
          :placeholder="placeholder"
          persistent-placeholder
          :validate-on-blur="false"
        />
      </v-col>
    </v-row>
    <v-row class="mt-4 px-3" justify="end">
      <v-btn
        color=""
        class="ml-0 mr-4"
        :disabled="loading"
        :loading="loading"
        @click="peruutaMuutokset"
      >
        Peruuta muutokset
      </v-btn>
      <v-btn
        type="submit"
        color="action"
        class="ml-0"
        :disabled="!valid || loading"
        :loading="loading"
      >
        Tallenna muutokset
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";

export default {
  name: "FormSeliteMuokkaukselle",
  mixins: [FormMixin],
  props: {
    label: {
      type: String,
      required: false,
      default() {
        return "Selite";
      },
    },
    placeholder: {
      type: String,
      required: false,
      default() {
        return "";
      },
    },
  },
  watch: {
    onAuki: {
      immediate: true,
      handler(arvo) {
        if (arvo) {
          this.$nextTick(() => {
            this.$refs.formSeliteMuokkaukselle.resetValidation();
          });
        }
      },
    },
  },
  methods: {
    peruutaMuutokset() {
      this.$emit("peruuta-muutokset");
    },
  },
};
</script>

<style lang="scss" scoped></style>
