var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "TyopoytaPikanappain",
    {
      attrs: { height: "100%", klikattava: false },
      scopedSlots: _vm._u([
        {
          key: "otsikko",
          fn: function () {
            return [
              _c("span", { staticClass: "tyopoyta-myynnin-kuvaaja--otsikko" }, [
                _vm._v("Myynti"),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex white justify-center primary--text text-h6 py-4 rounded",
          class: _vm.numeroClass,
        },
        [
          _c("span", [
            _vm._v(_vm._s(_vm._f("formatSumToFixed2")(_vm.aikajanteenMyynti))),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "mt-4 d-flex justify-center primary--text" },
        [
          _c("v-select", {
            staticClass: "px-12",
            attrs: {
              type: "text",
              items: _vm.myyntiItems,
              "item-value": "aikajanne",
              "item-text": _vm.aikajanteenSelite,
              "menu-props": { zIndex: "999" },
              filled: "",
              dense: "",
              outlined: "",
              color: "primary",
            },
            model: {
              value: _vm.aikajanneValinta,
              callback: function ($$v) {
                _vm.aikajanneValinta = $$v
              },
              expression: "aikajanneValinta",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }