var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { class: _vm.cardClass, attrs: { color: "warning" } },
    [
      _c("v-icon", { staticClass: "mr-2", attrs: { dark: "" } }, [
        _vm._v("warning"),
      ]),
      _c("span", { staticClass: "text-subtitle-1", class: _vm.tekstiClass }, [
        _vm._v(_vm._s(_vm.teksti)),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }