import BaseOptimizedCountSelaa from "@/store/modules/baseOptimizedCountSelaa";
import { TabsPerinta } from "@/utils/tabs";

export default class PerintaSelaa extends BaseOptimizedCountSelaa {
  state() {
    return {
      ...super.state(),
      apiName: "Toimeksiannot",
      csvApiName: "ToimeksiannotCsv",
      tabs: TabsPerinta || [],
    };
  }
}
