<template>
  <v-form ref="formLiitteet" v-model="valid" @submit.prevent="submit">
    <v-file-input
      v-model="value.tiedostot"
      chips
      counter="5"
      :multiple="true"
      :rules="[
        $validationRules.requiredArrayLength(value.tiedostot, 1),
        $validationRules.smallerThanOrEqualTo(5, 5),
      ]"
      :validate-on-blur="true"
      :hint="fileInputHint"
    ></v-file-input>
    <PeruutaHyvaksyPainikerivi
      :submit-disabled="submitDisabloitu"
      :submit-loading="loading"
      @close="$emit('close')"
    />
  </v-form>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";

export default {
  name: "FormLiitteet",
  mixins: [FormMixin],
  props: {
    title: {
      type: String,
      required: false,
      default() {
        return this.$t("title");
      },
    },
    fileInputHint: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      liianMontaLiitetta: false,
    };
  },
  computed: {
    submitDisabloitu() {
      return this.liianMontaLiitetta || !this.valid;
    },
  },
  watch: {
    "value.tiedostot": {
      handler(tiedostot) {
        if (Array.isArray(tiedostot) && tiedostot.length > 5) {
          this.liianMontaLiitetta = true;
        } else {
          this.liianMontaLiitetta = false;
        }
      },
    },
    onAuki: {
      immediate: true,
      handler(arvo) {
        if (arvo) {
          this.$nextTick(() => {
            this.$refs.formLiitteet.resetValidation();
          });
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>

<i18n>

  {
    "fi": {
      "title": "Lisää liitteitä"
    },
    "en": {
      "title": "Add attachments"
    }
  }

</i18n>
