<template>
  <!-- TODO siivoa luokkien nimiä -->

  <div v-if="renderoiKomponentti" class="osoiteKappale">
    <span v-if="nimi && naytaNimi" class="osoiteKappale__rivi">
      <span class="osoiteKappale__item osoiteKappale__item--nimi">
        {{ nimi }}
      </span>
    </span>
    <span v-if="saaja" class="osoiteKappale__rivi">
      <span class="osoiteKappale__item osoiteKappale__item--nimi">
        {{ saaja }}
      </span>
    </span>
    <span v-if="postiosoite" class="osoiteKappale__rivi">
      <span class="osoiteKappale__item osoiteKappale__item--postiosoite">
        {{ postiosoite }}
      </span>
    </span>
    <span v-if="postinumero || postitoimipaikka" class="osoiteKappale__rivi">
      <span
        v-if="postinumero"
        class="osoiteKappale__item osoiteKappale__item--postinumero"
      >
        {{ postinumero }}
      </span>
      <span
        v-if="postitoimipaikka"
        class="osoiteKappale__item osoiteKappale__item--postitoimipaikka"
      >
        {{ postitoimipaikka }}
      </span>
    </span>
    <!-- <span v-if="maa && maa != 'FI'" class="osoiteKappale__rivi">
      <span v-html="maa"></span>
    </span> TODO maan nimen haku tähän -->
  </div>
</template>

<script>
import TiivisNakymaMixin from "@/mixins/TiivisNakymaMixin";

export default {
  nimi: "OsoiteKappale",
  mixins: [TiivisNakymaMixin],
  props: {
    renderoiTyhja: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    nimi: {
      type: String,
      required: false,
      default() {
        return this.renderoiTyhja ? "&nbsp;" : "";
      },
    },
    saaja: {
      type: String,
      required: false,
      default() {
        return "";
      },
    },
    postiosoite: {
      type: String,
      required: false,
      default() {
        return this.renderoiTyhja ? "&nbsp;" : "";
      },
    },
    postinumero: {
      type: String,
      required: false,
      default() {
        return this.renderoiTyhja ? "&nbsp;" : "";
      },
    },
    postitoimipaikka: {
      type: String,
      required: false,
      default() {
        return this.renderoiTyhja ? "&nbsp;" : "";
      },
    },
    maa: {
      type: String,
      required: false,
      default() {
        return "";
      },
    },
    naytaNimi: {
      type: Boolean,
      required: false,
      default() {
        return true;
      },
    },
  },
  computed: {
    renderoiKomponentti() {
      return !(
        !this.renderoiTyhja &&
        !this.nimi &&
        !this.postiosoite &&
        !this.postinumero &&
        !this.postitoimipaikka
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.osoiteKappale {
  font-size: 1.1em;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  &__item {
    &--postinumero {
      &:after {
        content: " ";
      }
    }
  }
}
</style>
