import api from "@/plugins/api";
import { doRequestWithTimeout } from "@/utils/misc";
import apiInse from "@/plugins/apiInse";
import { HttpError } from "@/utils/errors";
import { sentryCaptureCustom } from "@/utils/misc";

export default {
  namespaced: true,
  state: {
    data: null,
    sopimus: null,
  },
  mutations: {
    setData(state, payload) {
      state.data = payload;
    },
    setSopimus(state, payload) {
      state.sopimus = payload;
    },
    setAccessToken(state, payload) {
      state.accessToken = payload;
    },
    setRefreshToken(state, payload) {
      state.refreshToken = payload;
    },
  },
  actions: {
    async haeLuottotiedot(
      { commit, state },
      { tyyppi, tunnus, paamies_id, kayttotarkoitus }
    ) {
      commit("setData", null);

      const request = await doRequestWithTimeout(api.AsiakasLuottotiedot, {
        method: "POST",
        body: {
          tyyppi: tyyppi,
          tunnus: tunnus,
          paamies_id: paamies_id,
          kayttotarkoitus: kayttotarkoitus,
        },
      });
      if (!request.success) {
        const error = new HttpError(request);
        sentryCaptureCustom(error);
        throw error;
        // "Valitettavasti jotain meni pieleen, suosittelemme tarkistamaan että olette valinneet oikean päämiehen";
      }

      commit("setData", request.result.body);
    },
    async haeSopimuksenTila({ commit }, paamies_id) {
      const request = await doRequestWithTimeout(
        api.PaamiesLuottotietosopimus,
        {
          method: "GET",
          params: { paamies_id },
        }
      );
      if (!request.success) {
        const error = new HttpError(
          request,
          "Valitettavasti jotain meni pieleen"
        );
        sentryCaptureCustom(error);
        throw error;
      }

      commit("setSopimus", request.result.body);
    },
    async haeAllekirjoituksenTila({ commit }, paamies_id) {
      const request = await doRequestWithTimeout(
        api.PaamiesLuottotietosopimusTarkistaAllekirjoitus,
        {
          method: "GET",
          params: { paamies_id },
        }
      );
      if (!request.success) {
        const error = new HttpError(
          request,
          "Valitettavasti jotain meni pieleen"
        );
        sentryCaptureCustom(error);
        throw error;
      }
      return request.result.body;
    },
    async tarkistaEdustusoikeus({ commit, state, dispatch }) {
      await dispatch("paivitaAnittaToken");

      const accessToken = localStorage.getItem("inseAccessToken");
      const anittaToken = localStorage.getItem("accessToken");
      const request = await doRequestWithTimeout(
        apiInse.LuottotietosopimusEdustusoikeus,
        {
          method: "POST",

          headers: {
            Authorization: "Bearer " + accessToken,
            "X-Anitta-Token": "Bearer " + anittaToken,
          },
        }
      );
      if (!request.success) {
        const error = new HttpError(
          request,
          "Valitettavasti jotain meni pieleen, suosittelemme tarkistamaan että olette valinneet oikean päämiehen"
        );
        sentryCaptureCustom(error);
        throw error;
      }
      console.log(request.result.body.jwt);
      localStorage.setItem("lsJwt", request.result.body.jwt);
      window.location = request.result.body.authorization_url;
    },

    async aloitaAllekirjoitus(
      { commit, state, dispatch },
      { jwt, redirect_response, paamies_id }
    ) {
      await dispatch("paivitaAnittaToken");

      const accessToken = localStorage.getItem("inseAccessToken");
      const anittaToken = localStorage.getItem("accessToken");
      const request = await doRequestWithTimeout(
        apiInse.LuottotietosopimusAloitaAllekirjoitus,
        {
          method: "POST",
          body: {
            jwt: jwt,
            redirect_response: redirect_response,
          },
          params: { paamies_id },
          headers: {
            Authorization: "Bearer " + accessToken,
            "X-Anitta-Token": "Bearer " + anittaToken,
          },
        }
      );
      console.log(request);
      if (!request.success) {
        const error = new HttpError(
          request,
          "Valitettavasti jotain meni pieleen"
        );
        sentryCaptureCustom(error);
        throw error;
      }
      if (request.result.body.redirect_url) {
        localStorage.removeItem("inseAccessToken");
        localStorage.removeItem("inseRefreshToken");
        localStorage.removeItem("lsJwt");
        console.log(request.result.body.redirect_url);
        window.location = request.result.body.redirect_url;
      } else {
        return request.result.body.tila;
      }
    },
    async paivitaAnittaToken() {
      // Päivitetään Anittan token varmuuden vuoksi, koska vanhentuneen tokenin automaattinen
      // päivitys ei toimi pyynnön mennessä Inseen.
      // api.RefreshToken asettaa uuden tokenin localStorageen apissa
      const tokenRequest = await api.RefreshToken.doUniqueRequest();
      if (!tokenRequest.success) {
        const error = new HttpError(
          tokenRequest,
          "Valitettavasti jotain meni pieleen. Yritä myöhemmin uudelleen"
        );
        sentryCaptureCustom(error);
        throw error;
      }
    },
  },
};
