<template>
  <v-form v-model="valid" @submit.prevent="submit">
    <v-text-field
      v-model="value.password"
      label="Uusi salasana"
      :rules="[
        $validationRules.required,
        $validationRules.requiredLength(value.password, 8),
      ]"
      type="password"
      required
      class="required"
    />
    <v-btn
      type="submit"
      :disabled="!valid || loading"
      :loading="loading"
      color="action"
      class="ml-0"
    >
      Lähetä
    </v-btn>
  </v-form>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";

export default {
  name: "FormUusiSalasana",
  mixins: [FormMixin],
};
</script>

<style lang="scss" scoped></style>
