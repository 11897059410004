var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("table", { staticClass: "riviTable" }, [
      _c("thead", [
        _c("tr", [
          _c(
            "th",
            {
              staticClass:
                "riviTable__cell riviTable__cell--header riviTable__cell--min-width-large",
              attrs: { scope: "col" },
            },
            [_vm._v(" " + _vm._s(_vm.$t("common:invoicingPeriod")) + " ")]
          ),
          _c(
            "th",
            {
              staticClass:
                "riviTable__cell riviTable__cell--header riviTable__cell--min-width-large, riviTable__cell--required",
              attrs: { scope: "col" },
            },
            [_vm._v(" " + _vm._s(_vm.$t("common:invoicingDate")) + " ")]
          ),
          _c(
            "th",
            {
              staticClass: "riviTable__cell riviTable__cell--header",
              attrs: { scope: "col" },
            },
            [_vm._v(" " + _vm._s(_vm.$t("common:code")) + " ")]
          ),
          _c(
            "th",
            {
              staticClass:
                "riviTable__cell riviTable__cell--header riviTable__cell--min-width-medium riviTable__cell--required",
              attrs: { scope: "col" },
            },
            [_vm._v(" " + _vm._s(_vm.$t("common:definition")) + " ")]
          ),
          _c(
            "th",
            {
              class: [..._vm.thClassArr, "riviTable__cell--required"],
              attrs: { scope: "col" },
            },
            [_vm._v(" " + _vm._s(_vm.$t("common:amount")) + " ")]
          ),
          _c("th", { class: _vm.thClassArr, attrs: { scope: "col" } }, [
            _vm._v(" " + _vm._s(_vm.$t("common:quantityUnit")) + " "),
          ]),
          _c(
            "th",
            {
              class: [..._vm.thClassArr, "riviTable__cell--required"],
              attrs: { scope: "col" },
            },
            [_vm._v(" " + _vm._s(_vm.$t("common:uPrice")) + " ")]
          ),
          _c(
            "th",
            {
              class: [..._vm.thClassArr, "riviTable__cell--required"],
              attrs: { scope: "col" },
            },
            [_vm._v(" " + _vm._s(_vm.$t("common:discountPercentShort")) + " ")]
          ),
          _c(
            "th",
            {
              staticClass: "riviTable__cell riviTable__cell--header text-right",
              attrs: { scope: "col" },
            },
            [_vm._v(" " + _vm._s(_vm.$t("common:inclVAT")) + " ")]
          ),
          _c(
            "th",
            {
              class: [..._vm.thClassArr, "riviTable__cell--required"],
              attrs: { scope: "col" },
            },
            [_vm._v(" " + _vm._s(_vm.$t("common:vatPercent")) + " ")]
          ),
          _c("th", { class: _vm.thClassArr, attrs: { scope: "col" } }, [
            _vm._v(" " + _vm._s(_vm.$t("common:taxFree")) + " "),
          ]),
          _c("th", { class: _vm.thClassArr, attrs: { scope: "col" } }, [
            _vm._v(" " + _vm._s(_vm.$t("common:tax")) + " "),
          ]),
          _c("th", { class: _vm.thClassArr, attrs: { scope: "col" } }, [
            _vm._v(" " + _vm._s(_vm.$t("common:taxable")) + " "),
          ]),
          _c("th", {
            staticClass: "riviTable__cell riviTable__cell--header text-right",
            attrs: { scope: "col" },
          }),
        ]),
      ]),
      _c("tbody", [
        _c("tr", [
          _c(
            "td",
            { class: [..._vm.tdClassArr, "riviTable__cell--min-width-large"] },
            [
              _c("v-select", {
                attrs: {
                  type: "text",
                  "hide-details": "",
                  "item-text": "laskutusjakso",
                  "item-value": "id",
                  items: _vm.laskutusjaksot,
                },
                model: {
                  value: _vm.laskutusaihe.laskutusjakso,
                  callback: function ($$v) {
                    _vm.$set(_vm.laskutusaihe, "laskutusjakso", $$v)
                  },
                  expression: "laskutusaihe.laskutusjakso",
                },
              }),
            ],
            1
          ),
          _c(
            "td",
            { class: _vm.tdClassArr },
            [
              _c("v-menu", {
                attrs: { "close-on-content-click": false, "max-width": "290" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ on }) {
                      return [
                        _c(
                          "v-text-field",
                          _vm._g(
                            {
                              attrs: {
                                value: _vm.$dateFnsFormat(
                                  _vm.laskutusaihe.laskutuspaiva
                                ),
                                readonly: "",
                                rules: [_vm.$validationRules.required],
                              },
                              on: {
                                "click:clear": function ($event) {
                                  _vm.laskutusaihe.laskutuspaiva = null
                                },
                              },
                            },
                            on
                          )
                        ),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _c("v-date-picker", {
                          attrs: {
                            rules: [_vm.$validationRules.required],
                            locale: "fi-FI",
                            "first-day-of-week": "1",
                            "no-title": "",
                          },
                          on: {
                            change: function ($event) {
                              _vm.laskutuspaivaMenu = false
                            },
                          },
                          model: {
                            value: _vm.laskutusaihe.laskutuspaiva,
                            callback: function ($$v) {
                              _vm.$set(_vm.laskutusaihe, "laskutuspaiva", $$v)
                            },
                            expression: "laskutusaihe.laskutuspaiva",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.laskutuspaivaMenu,
                  callback: function ($$v) {
                    _vm.laskutuspaivaMenu = $$v
                  },
                  expression: "laskutuspaivaMenu",
                },
              }),
            ],
            1
          ),
          _c(
            "td",
            { class: _vm.tdClassArr },
            [
              _c("v-text-field", {
                attrs: { type: "text", "hide-details": "" },
                model: {
                  value: _vm.laskutusaihe.koodi,
                  callback: function ($$v) {
                    _vm.$set(_vm.laskutusaihe, "koodi", $$v)
                  },
                  expression: "laskutusaihe.koodi",
                },
              }),
            ],
            1
          ),
          _c(
            "td",
            { class: _vm.tdClassArr },
            [
              _c("v-text-field", {
                attrs: {
                  type: "text",
                  rules: [_vm.$validationRules.required],
                  "hide-details": "",
                },
                model: {
                  value: _vm.laskutusaihe.selite,
                  callback: function ($$v) {
                    _vm.$set(_vm.laskutusaihe, "selite", $$v)
                  },
                  expression: "laskutusaihe.selite",
                },
              }),
            ],
            1
          ),
          _c(
            "td",
            { class: _vm.tdClassArr },
            [
              _c("v-text-field", {
                attrs: {
                  type: "number",
                  rules: [_vm.$validationRules.required],
                  required: "",
                  reverse: "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.laskutusaihe.maara,
                  callback: function ($$v) {
                    _vm.$set(_vm.laskutusaihe, "maara", $$v)
                  },
                  expression: "laskutusaihe.maara",
                },
              }),
            ],
            1
          ),
          _c(
            "td",
            { class: _vm.tdClassArr },
            [
              _c("v-text-field", {
                attrs: { type: "text", reverse: "", "hide-details": "" },
                model: {
                  value: _vm.laskutusaihe.laatu,
                  callback: function ($$v) {
                    _vm.$set(_vm.laskutusaihe, "laatu", $$v)
                  },
                  expression: "laskutusaihe.laatu",
                },
              }),
            ],
            1
          ),
          _c(
            "td",
            { class: _vm.tdClassArr },
            [
              _c("v-text-field", {
                attrs: {
                  type: "number",
                  rules: [
                    _vm.$validationRules.required,
                    _vm.$validationRules.positiveUnitPrice,
                  ],
                  required: "",
                  reverse: "",
                },
                model: {
                  value: _vm.laskutusaihe.ahinta,
                  callback: function ($$v) {
                    _vm.$set(_vm.laskutusaihe, "ahinta", $$v)
                  },
                  expression: "laskutusaihe.ahinta",
                },
              }),
            ],
            1
          ),
          _c(
            "td",
            { class: _vm.tdClassArr },
            [
              _c("v-text-field", {
                attrs: {
                  type: "number",
                  reverse: "",
                  "hide-details": "",
                  rules: [
                    _vm.$validationRules.zeroOrBigger,
                    _vm.$validationRules.smallerThanOrEqualTo(
                      _vm.laskutusaihe.alennusp,
                      100
                    ),
                    _vm.$validationRules.requiredLoose,
                    _vm.$validationRules.maxDecimalPlaces(
                      _vm.laskutusaihe.alennusp,
                      2
                    ),
                  ],
                },
                model: {
                  value: _vm.laskutusaihe.alennusp,
                  callback: function ($$v) {
                    _vm.$set(_vm.laskutusaihe, "alennusp", $$v)
                  },
                  expression: "laskutusaihe.alennusp",
                },
              }),
            ],
            1
          ),
          _c(
            "td",
            { class: _vm.tdClassArr },
            [
              _c("v-checkbox", {
                staticClass: "riviTable__cell--checkbox",
                attrs: { "hide-details": "" },
                model: {
                  value: _vm.laskutusaihe.sisalv,
                  callback: function ($$v) {
                    _vm.$set(_vm.laskutusaihe, "sisalv", $$v)
                  },
                  expression: "laskutusaihe.sisalv",
                },
              }),
            ],
            1
          ),
          _c(
            "td",
            { class: _vm.tdClassArr },
            [
              _c("v-select", {
                attrs: {
                  type: "number",
                  "hide-details": "",
                  "item-text": "tunnus",
                  "item-value": "id",
                  items: _vm.ALVTunnusItems,
                  reverse: "",
                  "return-object": "",
                  required: "",
                  rules: [_vm.$validationRules.requiredObject],
                },
                on: {
                  input: function ($event) {
                    return _vm.paivitaALVPros($event, _vm.laskutusaihe)
                  },
                },
                model: {
                  value: _vm.laskutusaihe.alvtunnus,
                  callback: function ($$v) {
                    _vm.$set(_vm.laskutusaihe, "alvtunnus", $$v)
                  },
                  expression: "laskutusaihe.alvtunnus",
                },
              }),
            ],
            1
          ),
          _c(
            "td",
            { class: _vm.tdClassArr },
            [
              _c("v-text-field", {
                attrs: { type: "number", disabled: "", reverse: "" },
                model: {
                  value: _vm.laskutusaihe.veroton,
                  callback: function ($$v) {
                    _vm.$set(_vm.laskutusaihe, "veroton", $$v)
                  },
                  expression: "laskutusaihe.veroton",
                },
              }),
            ],
            1
          ),
          _c(
            "td",
            { class: _vm.tdClassArr },
            [
              _c("v-text-field", {
                attrs: { type: "number", disabled: "", reverse: "" },
                model: {
                  value: _vm.laskutusaihe.vero,
                  callback: function ($$v) {
                    _vm.$set(_vm.laskutusaihe, "vero", $$v)
                  },
                  expression: "laskutusaihe.vero",
                },
              }),
            ],
            1
          ),
          _c(
            "td",
            { class: _vm.tdClassArr },
            [
              _c("v-text-field", {
                attrs: { type: "number", disabled: "", reverse: "" },
                model: {
                  value: _vm.laskutusaihe.verollinen,
                  callback: function ($$v) {
                    _vm.$set(_vm.laskutusaihe, "verollinen", $$v)
                  },
                  expression: "laskutusaihe.verollinen",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }