var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-menu", {
    attrs: {
      "close-on-content-click": false,
      "max-width": "290",
      "nudge-left": "110",
    },
    scopedSlots: _vm._u([
      {
        key: "activator",
        fn: function ({ on }) {
          return [
            _c(
              "v-text-field",
              _vm._g(
                _vm._b(
                  {
                    ref: "textField",
                    class: _vm.textFieldClass,
                    attrs: {
                      value: _vm.$dateFnsFormat(_vm.valueMutable),
                      readonly: "",
                      clearable: "",
                    },
                    on: { "click:clear": _vm.clearInput },
                  },
                  "v-text-field",
                  _vm.$attrs,
                  false
                ),
                on
              )
            ),
          ]
        },
      },
      {
        key: "default",
        fn: function () {
          return [
            _c("v-date-picker", {
              attrs: {
                locale: _vm.$languageLocale(),
                "first-day-of-week": "1",
                "no-title": "",
              },
              on: {
                change: function ($event) {
                  _vm.datePickerOpen = false
                },
              },
              model: {
                value: _vm.valueMutable,
                callback: function ($$v) {
                  _vm.valueMutable = $$v
                },
                expression: "valueMutable",
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.datePickerOpen,
      callback: function ($$v) {
        _vm.datePickerOpen = $$v
      },
      expression: "datePickerOpen",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }