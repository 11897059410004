import _ from "lodash";
import { t } from "./lang";

const sovellusIdOtsake = `${process.env.VUE_APP_INSTANCE_TITLE}-ID`;

export const TableHeadersLaskut = [
  {
    text: null,
    align: "left",
    sortable: false,
    value: "icon",
  },
  {
    text: t("invoiceNo"),
    value: "nro",
  },
  {
    text: t("" + sovellusIdOtsake),
    value: "id",
  },
  {
    text: t("customer"),
    value: "asiakas__nimi",
  },
  {
    text: t("status"),
    value: "tila",
  },
  {
    text: t("creditor"),
    value: "paamies__nimi",
  },
  {
    text: t("invoiceDate"),
    value: "laskupaiva",
    align: "right",
  },
  {
    text: t("dueDate"),
    value: "erapaiva",
    align: "right",
  },
  {
    text: t("sum"),
    value: "laskurivi__summa",
    align: "right",
  },
  {
    text: t("taxFree"),
    value: "veroton",
    align: "right",
    sortable: false,
  },
  {
    text: t("open"),
    value: "avoinna",
    align: "right",
    sortable: false,
  },
];

export const TableHeadersOstolaskut = [
  {
    text: null,
    align: "left",
    sortable: false,
    value: "icon",
  },
  {
    text: t("invoiceNo"),
    value: "nro",
  },
  {
    text: t("" + sovellusIdOtsake),
    value: "id",
  },
  {
    text: t("supplier"),
    value: "toimittaja__nimi",
  },
  {
    text: t("status"),
    value: "tila",
  },
  {
    text: t("creditor"),
    value: "paamies__nimi",
  },
  {
    text: t("invoiceDate"),
    value: "laskupaiva",
    align: "right",
  },
  {
    text: t("dueDate"),
    value: "erapaiva",
    align: "right",
  },
  {
    text: t("sum"),
    value: "laskurivi__summa",
    align: "right",
  },
];

export const TableHeadersLaskurivit = [
  {
    text: t("code"),
    value: "koodi",
    sortable: false,
  },
  {
    text: t("definition"),
    value: "selite",
    sortable: false,
  },
  {
    text: t("vatPercent"),
    value: "alvp",
    sortable: false,
    align: "right",
  },
  {
    text: t("unitPrice"),
    value: "ahinta",
    sortable: false,
    align: "right",
  },
  {
    text: t("amount"),
    value: "maara",
    sortable: false,
    align: "right",
  },
  {
    text: t("taxFree"),
    value: "veroton",
    sortable: false,
    align: "right",
  },
  {
    text: t("tax"),
    value: "vero",
    sortable: false,
    align: "right",
  },
  {
    text: t("taxable"),
    value: "verollinen",
    sortable: false,
    align: "right",
  },
];

export const TableHeadersLaskuKirjaukset = [
  {
    text: "",
    value: "maksaja",
    sortable: false,
  },
  {
    text: t("paymentDate"),
    value: "maksupaiva",
    sortable: false,
    align: "right",
  },
  {
    text: t("transactionDate"),
    value: "kirjauspaiva",
    sortable: false,
    align: "right",
  },
  {
    text: t("sum"),
    value: "summa",
    sortable: false,
    align: "right",
  },
];

export const TableHeadersLaskuliite = [
  {
    text: t("type"),
    value: "tyyppi",
  },
  {
    text: t("fileName"),
    value: "nimi",
  },
  {
    text: t("created"),
    value: "luotu",
  },
  {
    text: t("additionalInfo"),
    value: "lisatiedot",
    sortable: false,
  },
  {
    text: t("actions"),
    value: "toiminnot",
    align: "right",
    sortable: false,
  },
];

export const TableHeadersAsiakas = [
  {
    text: null,
    align: "left",
    sortable: false,
    value: "icon",
  },
  {
    text: t("customerName"),
    value: "nimi",
  },
  {
    text: t("customerNo"),
    value: "nro",
  },
  {
    text: t("" + sovellusIdOtsake),
    value: "id",
  },
  {
    text: t("identification"),
    value: "tunnus",
  },
  {
    text: t("type"),
    value: "tyyppi",
  },
  {
    text: t("creditor"),
    value: "paamies",
  },
];

export const TableHeadersVastapuoliLaskut = [
  {
    text: null,
    align: "left",
    sortable: false,
    value: "icon",
  },
  {
    text: t("invoiceNo"),
    value: "nro",
  },
  {
    text: t("status"),
    value: "tila",
  },
  {
    text: t("invoiceDate"),
    value: "laskupaiva",
    align: "right",
  },
  {
    text: t("dueDate"),
    value: "erapaiva",
    align: "right",
  },
  {
    text: t("sum"),
    value: "summa",
    align: "right",
  },
  {
    text: t("taxFree"),
    value: "veroton",
    align: "right",
  },
  {
    text: t("open"),
    value: "avoinna",
    align: "right",
  },
];

export const TableHeadersVastapuoliKirjaukset = [
  {
    text: t("payer"),
    value: "maksaja",
    sortable: false,
  },
  {
    text: t("invoice"),
    value: "lasku",
    sortable: false,
  },
  {
    text: t("paymentDate"),
    value: "maksupaiva",
    sortable: false,
    align: "right",
  },
  {
    text: t("transactionDate"),
    value: "kirjauspaiva",
    align: "right",
    sortable: false,
  },
  {
    text: t("sum"),
    value: "summa",
    sortable: false,
    align: "right",
  },
];

export const TableHeadersAsiakasTapahtumat = [
  {
    text: t("payer"),
    value: "maksaja",
  },
  {
    text: t("transactionDate"),
    value: "kirjauspaiva",
    align: "right",
  },
  {
    text: t("reference"),
    value: "viite",
  },
  {
    text: t("message"),
    value: "viesti",
  },
  {
    text: t("sum"),
    value: "summa",
    align: "right",
  },
];

export const TableHeadersVastapuoliTapahtumat = [
  {
    text: t("payer"),
    value: "maksaja",
  },
  {
    text: t("invoices"),
    value: "laskut",
  },
  {
    text: t("transactionDate"),
    value: "kirjauspaiva",
    align: "right",
  },
  {
    text: t("reference"),
    value: "viite",
  },
  {
    text: t("message"),
    value: "viesti",
  },
  {
    text: t("sum"),
    value: "summa",
    align: "right",
  },
];

const headersAsiakasLaskutSopimuksella = TableHeadersVastapuoliLaskut.slice();
const headerSopimus = {
  text: t("contract"),
  value: "sopimus",
};
headersAsiakasLaskutSopimuksella.splice(3, 0, headerSopimus);
export const TableHeadersAsiakasLaskutSopimuksella =
  headersAsiakasLaskutSopimuksella;

export const TableHeadersAsiakasSopimukset = [
  {
    text: t("number"),
    value: "nro",
  },
  {
    text: t("status"),
    value: "tila",
  },
  {
    text: t("active"),
    value: "voimassa",
  },
  {
    text: t("type"),
    value: "tyyppi",
  },
  {
    text: t("genre"),
    value: "laji",
  },
  {
    text: t("open"),
    value: "avoinna",
    align: "right",
  },
];

export const TableHeadersVastapuoliMuistiinpanot = [
  {
    text: t("writer"),
    value: "lahettaja",
  },
  {
    text: t("writeTime"),
    value: "lahetysaika",
    align: "right",
  },
  {
    text: t("case"),
    value: "tapaus",
    sortable: false,
  },
  {
    text: t("contents"),
    value: "sisalto",
  },
  {
    text: t("actions"),
    value: "toiminnot",
    align: "right",
    sortable: false,
  },
];

const tableHeadersAsiakasMuistiinpanotLaskunLuonnille = [
  ...TableHeadersVastapuoliMuistiinpanot,
];
tableHeadersAsiakasMuistiinpanotLaskunLuonnille.pop();
export const TableHeadersAsiakasMuistiinpanotLaskunLuonnille =
  tableHeadersAsiakasMuistiinpanotLaskunLuonnille;

export const TableHeadersVastapuoliViestit = [
  {
    text: t("sender"),
    value: "lahettaja",
  },
  {
    text: t("senderTime"),
    value: "lahetysaika",
    align: "right",
  },
  {
    text: t("case"),
    value: "tapaus",
    sortable: false,
  },
  {
    text: t("contents"),
    value: "sisalto",
  },
  {
    text: t("read"),
    value: "luettu",
  },
];

export const TableHeadersAsiakasAsiakasrekisteri = [
  {
    text: t("number"),
    value: "nro",
  },
  {
    text: t("name"),
    value: "nimi",
  },
  {
    text: t("group"),
    value: "ryhma",
  },
];

export const TableHeadersToimeksiannot = [
  {
    text: null,
    align: "left",
    sortable: false,
    value: "icon",
  },
  {
    text: t("caseNo"),
    value: "id",
  },
  {
    text: t("debtor"),
    value: "toimeksiantovelallinen__velallinen__nimi",
  },
  {
    text: t("status"),
    value: "tila",
  },
  {
    text: t("phase"),
    value: "vaihe__vaihe",
  },
  {
    text: t("creditor"),
    value: "paamies__nimi",
  },
  {
    text: t("invoiceNo"),
    value: "saatavarivi__laskunumero",
    align: "right",
  },
  {
    text: t("invoiceDate"),
    value: "saatavarivi__laskupaiva",
    align: "right",
  },
  {
    text: t("dueDate"),
    value: "saatavarivi__erapaiva",
    align: "right",
  },
  {
    text: t("open"),
    value: "avoinna",
    align: "right",
    sortable: false,
  },
];

export const TableHeadersToimeksiantoAsiakirja = [
  {
    text: t("fileName"),
    value: "nimi",
  },
  {
    text: t("created"),
    value: "luotu",
  },
  {
    text: t("actions"),
    value: "toiminnot",
    align: "right",
    sortable: false,
  },
];

export const TableHeadersToimeksiantoKirje = [
  {
    text: t("type"),
    value: "tyyppi",
  },
  {
    text: t("sent"),
    value: "lahetysaika",
  },
  {
    text: t("actions"),
    value: "toiminnot",
    align: "right",
    sortable: false,
  },
];

export const TableHeadersViestit = [
  {
    text: null,
    align: "left",
    sortable: false,
    value: "icon",
  },
  {
    text: t("" + sovellusIdOtsake),
    value: "id",
  },
  {
    text: t("sender"),
    value: "lahettaja__username",
  },
  {
    text: t("creditor"),
    value: "paamies__nimi",
  },
  {
    text: t("case"),
    value: "tapaus_id",
    sortable: false,
  },
  {
    text: t("message"),
    value: "viesti",
  },
  {
    text: t("sent"),
    value: "luotu",
    align: "right",
  },
];

export const TableHeadersLisatiedot = [
  {
    text: t("key"),
    value: "key",
    sortable: false,
  },
  {
    text: t("value"),
    value: "value",
    sortable: false,
  },
];

export const TableHeadersKassaAlennukset = [
  {
    text: t("active"),
    value: "voimassa",
    sortable: false,
  },
  {
    text: t("discountPercent"),
    value: "alennusp",
    sortable: false,
  },
  {
    text: t("discount"),
    value: "alennus",
    sortable: false,
  },
];

export const TableHeadersSaatavaerittely = [
  {
    text: t("invoiceNo"),
    value: "laskunumero",
    sortable: false,
  },
  {
    text: t("invoiceDate"),
    value: "laskupaiva",
    sortable: false,
  },
  {
    text: t("dueDate"),
    value: "erapaiva",
    sortable: false,
  },
  {
    text: t("pmReference"),
    value: "pm_viite",
    sortable: false,
  },
  {
    text: t("definition"),
    value: "selite",
    sortable: false,
  },
  {
    text: t("capital"),
    value: "paaoma",
    align: "right",
    sortable: false,
  },
  {
    text: t("fee"),
    value: "kulu",
    align: "right",
    sortable: false,
  },
  {
    text: t("lateInterest"),
    value: "viivkorko",
    align: "right",
    sortable: false,
  },
  {
    text: t("open"),
    value: "avoinna",
    align: "right",
    sortable: false,
  },
];

export const TableHeadersMaksusopimus = [
  {
    text: t("parcel"),
    value: "nro",
    sortable: false,
  },
  {
    text: t("dueDate"),
    value: "erapaiva",
    align: "right",
    sortable: false,
  },
  {
    text: t("paymentDate"),
    value: "maksupaiva",
    align: "right",
    sortable: false,
  },
  {
    text: t("sum"),
    value: "summa",
    align: "right",
    sortable: false,
  },
  {
    text: t("open"),
    value: "avoinna",
    align: "right",
    sortable: false,
  },
];

export const TableHeadersUlosotto = [
  {
    text: "",
    value: "",
    sortable: false,
  },
  {
    text: t("matterName"),
    value: "asianimi",
    sortable: false,
  },
  {
    text: t("parcel"),
    value: "hakemusNro",
    sortable: false,
  },
  {
    text: t("reference") + "1",
    value: "viite1",
    sortable: false,
  },
  {
    text: t("reference") + "2",
    value: "viite2",
    sortable: false,
  },
  {
    text: t("invoiceReference"),
    value: "viiteNro",
    sortable: false,
  },
  {
    text: t("description"),
    value: "selite",
    sortable: false,
  },
  {
    text: t("paymentYear"),
    value: "maksuunpanovuosi",
    sortable: false,
  },
  {
    text: t("ready"),
    value: "valmis",
    sortable: false,
  },
  {
    text: t("actions"),
    value: "toiminnot",
    sortable: false,
  },
];

export const TableHeadersKirjaukset = [
  {
    text: "",
    value: "",
    sortable: false,
  },
  {
    text: t("invoiceNo"),
    value: "id",
    sortable: false,
  },
  {
    text: t("tableHeaders.source"),
    value: "maksaja",
    sortable: false,
  },
  {
    text: t("paymentDate"),
    value: "maksupaiva",
    align: "right",
    sortable: false,
  },
  {
    text: t("transactionDate"),
    value: "kirjauspaiva",
    align: "right",
    sortable: false,
  },
  {
    text: t("capital"),
    value: "kirjattu_paaoma",
    align: "right",
    sortable: false,
  },
  {
    text: t("fee"),
    value: "kulu",
    align: "right",
    sortable: false,
  },
  {
    text: t("lateInterest"),
    value: "viivkorko",
    align: "right",
    sortable: false,
  },
  {
    text: t("sum"),
    value: "summa",
    align: "right",
    sortable: false,
  },
];

export const TableHeadersKirjauksetAsiakas = [
  {
    text: "",
    value: "",
    sortable: false,
  },
  {
    text: t("assignment"),
    value: "id",
    sortable: false,
  },
  {
    text: t("sourceInvoice"),
    value: "maksaja",
    sortable: false,
  },
  {
    text: t("paymentDate"),
    value: "maksupaiva",
    align: "right",
    sortable: false,
  },
  {
    text: t("transactionDate"),
    value: "kirjauspaiva",
    align: "right",
    sortable: false,
  },
  {
    text: t("reference"),
    value: "viite",
    align: "right",
    sortable: false,
  },
  {
    text: t("message"),
    value: "viesti",
    align: "right",
    sortable: false,
  },
  {
    text: t("sum"),
    value: "summa",
    align: "right",
    sortable: false,
  },
];

export const TableHeadersTuoterekisteri = [
  {
    text: null,
    align: "left",
    sortable: false,
    value: "icon",
  },
  {
    text: t("productGroup.productGroupHeader"),
    value: "tuoteryhma__nimi",
    sortable: true,
  },
  {
    text: t("code"),
    value: "koodi",
    sortable: true,
  },
  {
    text: t("definition"),
    value: "selite",
    sortable: false,
  },
  {
    text: t("" + sovellusIdOtsake),
    value: "id",
    sortable: true,
  },
  {
    text: t("unitPrice"),
    value: "ahinta",
    align: "right",
    sortable: true,
  },
  {
    text: t("quantityUnit"),
    value: "laatu",
    sortable: false,
  },
  {
    text: t("vatP"),
    value: "alvtunnus__alvp",
    sortable: false,
  },
  {
    text: t("currency"),
    value: "valuutta",
    sortable: false,
  },
  {
    text: t("stockStatus"),
    value: "varastotilanne",
    align: "right",
    sortable: true,
  },
  {
    text: t("purchasePrice"),
    value: "hankintahinta",
    align: "right",
    sortable: false,
  },
];

export const TableHeadersTuoteryhma = [
  {
    text: null,
    align: "left",
    sortable: false,
    value: "icon",
  },
  {
    text: t("name"),
    value: "nimi",
    sortable: true,
  },
  {
    text: t("" + sovellusIdOtsake),
    value: "id",
    sortable: true,
  },
];

export const TableHeadersSopimukset = [
  {
    text: null,
    align: "left",
    sortable: false,
    value: "icon",
  },
  {
    text: t("number"),
    value: "nro",
  },
  {
    text: t("creditor"),
    value: "paamies__nimi",
  },
  {
    text: t("customer"),
    value: "asiakas__nimi",
    sortable: false,
  },
  {
    text: t("status"),
    value: "tila",
  },
  {
    text: t("" + sovellusIdOtsake),
    value: "id",
  },
  {
    text: t("startingDate"),
    value: "alkamispaiva",
    align: "right",
  },
  {
    text: t("endingDate"),
    value: "loppumispaiva",
    align: "right",
  },
  {
    text: t("ourReference"),
    value: "viitteemme",
  },
  {
    text: t("yourReference"),
    value: "viitteenne",
  },
];

const TableHeadersSopimuksetTiivis = _.cloneDeep(TableHeadersSopimukset);
TableHeadersSopimuksetTiivis[1].text = t("no");
TableHeadersSopimuksetTiivis[5].text = t("ID");
TableHeadersSopimuksetTiivis[6].text = t("startDateWithDots");
TableHeadersSopimuksetTiivis[7].text = t("endDateWithDots");
export { TableHeadersSopimuksetTiivis };

export const TableHeadersSopimusYhteenveto = [
  {
    text: t("definition"),
    value: "selite",
  },
  {
    text: t("invoiced"),
    value: "laskutettu",
    align: "right",
  },
  {
    text: t("carriedOut"),
    value: "suoritettu",
    align: "right",
  },
  {
    text: t("openInvoiced"),
    value: "laskutettu_avoinna",
    align: "right",
  },
];

export const TableHeadersSopimusLainasopimusYhteenveto = [
  {
    text: t("definition"),
    value: "selite",
  },
  {
    text: t("invoiced"),
    value: "laskutettu",
    align: "right",
  },
  {
    text: t("carriedOut"),
    value: "suoritettu",
    align: "right",
  },
  {
    text: t("open"),
    value: "avoinna",
    align: "right",
  },
];

export const TableHeadersSopimusLaskusisalto = [
  ...TableHeadersLaskurivit,
  {
    text: t("actions"),
    value: "toiminnot",
    sortable: false,
  },
];

export const TableHeadersLaskutusjakso = [
  {
    text: t("invoicingPeriod"),
    value: "laskutusjakso",
    sortable: false,
  },
  {
    text: null,
    align: "left",
    sortable: false,
    value: "icon",
  },
  {
    text: t("invoice"),
    value: "lasku",
  },
  {
    text: t("invoicingDate"),
    value: "laskutuspaiva_display",
  },
  {
    text: t("status"),
    value: "tila",
  },
  {
    text: t("dueDate"),
    value: "erapaiva",
  },
  {
    text: t("definition"),
    value: "selite",
    sortable: false,
  },
  {
    text: t("vatPercent"),
    value: "alvp",
    sortable: false,
    align: "right",
  },
  {
    text: t("unitPrice"),
    value: "ahinta",
    sortable: false,
    align: "right",
  },
  {
    text: t("amount"),
    value: "maara",
    sortable: false,
    align: "right",
  },
  {
    text: t("taxFree"),
    value: "veroton",
    sortable: false,
    align: "right",
  },
  {
    text: t("taxable"),
    value: "verollinen",
    sortable: false,
    align: "right",
  },
  {
    text: t("actions"),
    value: "toiminnot",
    sortable: false,
  },
];

const tableHeadersLaskutusaiheLainasopimus = _.cloneDeep(
  TableHeadersLaskutusjakso
);
tableHeadersLaskutusaiheLainasopimus.shift();
export const TableHeadersLaskutusaiheLainasopimus =
  tableHeadersLaskutusaiheLainasopimus;

export const TableHeadersSopimusAsiakirja = [
  {
    text: t("fileName"),
    value: "nimi",
  },
  {
    text: t("created"),
    value: "luotu",
  },
  {
    text: t("actions"),
    value: "toiminnot",
    align: "right",
    sortable: false,
  },
];

export const TableHeadersSopimusLisatiedot = [
  {
    text: t("key"),
    value: "key",
  },
  {
    text: t("value"),
    value: "value",
  },
  {
    text: t("created"),
    value: "luotu",
  },
];

export const TableHeadersToimittaja = [
  {
    text: null,
    align: "left",
    sortable: false,
    value: "icon",
  },
  {
    text: t("supplierName"),
    value: "nimi",
  },
  {
    text: t("supplierNo"),
    value: "nro",
  },
  {
    text: t("" + sovellusIdOtsake),
    value: "id",
  },
  {
    text: t("identification"),
    value: "tunnus",
  },
  {
    text: t("type"),
    value: "tyyppi",
  },
  {
    text: t("creditor"),
    value: "paamies",
  },
];

export const TableHeadersToimittajaToimittajarekisteri = [
  {
    text: t("number"),
    value: "nro",
  },
  {
    text: t("name"),
    value: "nimi",
  },
  {
    text: t("group"),
    value: "ryhma",
  },
];

export const TableHeadersTilioinnit = [
  {
    text: t("account"),
    value: "tili",
  },
  {
    text: t("sum"),
    value: "summa",
    align: "right",
  },
  {
    text: t("vatPercent"),
    value: "alvp",
    sortable: false,
    align: "right",
  },
  {
    text: t("definition"),
    value: "selite",
  },
];

export const TableHeadersMaksutoimeksiannot = [
  {
    text: null,
    align: "left",
    sortable: false,
    value: "icon",
  },
  {
    text: t("file"),
    value: "tiedosto",
  },
  {
    text: t("created"),
    value: "luotu",
  },
  {
    text: t("" + sovellusIdOtsake),
    value: "id",
  },
  {
    text: t("tableHeaders.exportToNetbox"),
    align: "left",
    sortable: false,
    value: "netbox",
  },
];

export const TableHeadersPaamieshallinta = [
  {
    text: null,
    align: "left",
    sortable: false,
    value: "icon",
  },
  {
    text: t("name"),
    value: "nimi",
  },
  {
    text: t("" + sovellusIdOtsake),
    value: "id",
  },
  {
    text: t("identification"),
    value: "tunnus",
  },
];

export const TableHeadersRaportit = [
  {
    text: null,
    align: "left",
    sortable: false,
    value: "icon",
  },
  {
    text: t("" + sovellusIdOtsake),
    value: "id",
  },
  {
    text: t("creditors"),
    value: "paamiehet",
  },
  {
    text: t("report"),
    value: "luokka",
  },
  {
    text: t("created"),
    value: "luotu",
  },
  {
    text: t("status"),
    value: "tila",
  },
  {
    text: t("starting"),
    value: "alkaen",
  },
  {
    text: t("ending"),
    value: "loppuen",
  },
  {
    text: t("onlyTotal"),
    value: "vain_yhteenveto",
  },
];

export const TableHeadersRaportitKuukausiraportti = [
  {
    text: null,
    align: "left",
    sortable: false,
    value: "icon",
  },
  {
    text: t("" + sovellusIdOtsake),
    value: "id",
  },
  {
    text: t("creditor"),
    value: "paamies",
  },
  {
    text: t("report"),
    value: "raportti",
  },
  {
    text: t("nextReport"),
    value: "seuraava_raportti",
  },
  {
    text: t("reportDays"),
    value: "raportin_paivat",
    sortable: false,
  },
  {
    text: t("emailTo"),
    value: "email",
  },
  {
    text: t("lastSent"),
    value: "ajettu_viimeksi",
  },
  {
    text: t("actions"),
    value: "toiminnot",
    sortable: false,
  },
];

export const TableHeadersTilitys = [
  {
    text: null,
    align: "left",
    sortable: false,
    value: "icon",
  },
  {
    text: t("" + sovellusIdOtsake),
    value: "id",
  },
  {
    text: t("creditor"),
    value: "paamies",
  },
  {
    text: t("specifications"),
    value: "tilityserittely_set",
    sortable: false,
  },
  {
    text: t("invoices"),
    value: "laskut",
    sortable: false,
  },
  {
    text: t("type"),
    value: "tyyppi",
  },
  {
    text: t("settlementDate"),
    value: "luotu",
  },
  {
    text: t("paymentDate"),
    value: "lahetetty",
    sortable: false,
  },
  {
    text: t("toBePaid"),
    value: "maksetaan",
    sortable: false,
  },
  {
    text: t("notes"),
    value: "muistiinpanot",
  },
];

export const TableHeadersKayttajat = [
  {
    text: t("" + sovellusIdOtsake),
    value: "id",
  },
  {
    text: t("username"),
    value: "username",
  },
  {
    text: t("email"),
    value: "email",
    sortable: false,
  },
  {
    text: t("groups"),
    value: "group_set",
    sortable: false,
  },
  {
    text: t("active"),
    value: "is_active",
    sortable: false,
  },
  {
    text: t("superuser"),
    value: "is_superuser",
    sortable: false,
  },
  {
    text: t("staff"),
    value: "is_staff",
    sortable: false,
  },
];

export const TableHeadersPorrashinnasto = [
  {
    text: t("service"),
    value: "selite",
  },
  {
    text: t("price"),
    value: "ahinta",
  },
];
