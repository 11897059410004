<template>
  <v-form ref="formToimittaja">
    <v-row v-if="!$store.state.user.vainYksiPaamies && !kaareObjekti">
      <v-col md="6" class="pl-4 pr-12">
        <AutocompletePaamies
          ref="autocompletePaamies"
          v-model="toimittaja.paamies"
          tarkistettava-oikeus="onlinerestapi.onlinerestapi_ostolasku"
        />
      </v-col>
      <v-col md="6" class="pl-12 pr-4"></v-col>
    </v-row>
    <v-row>
      <!-- Vasen sarake-->
      <v-col md="6" class="pl-4 pr-12">
        <h3 class="my-8">Toimittajatiedot</h3>
        <v-text-field
          v-model="toimittaja.nro"
          label="Toimittajanumero"
          :error-messages="toimittajanroErrors"
          @input="tarkistaToimittajanro($event)"
        />
        <v-select
          v-model="toimittaja.tyyppi"
          label="Tyyppi"
          type="text"
          :items="vastapuolenTyypit"
          item-value="value"
          item-text="selite"
          :menu-props="{ zIndex: '999' }"
          :rules="[$validationRules.required]"
          class="required"
        />
        <v-text-field
          v-model="toimittaja.tunnus"
          label="Y-tunnus / Hetu"
          :error-messages="tunnusErrors"
          @input="tarkistaTunnus($event)"
        />
        <v-select
          v-model="toimittaja.kieli"
          label="Kieli"
          type="text"
          :items="kielet || []"
          :value="kielet ? kielet[0] : ''"
          item-value="value"
          item-text="selite"
          :menu-props="{ zIndex: '999' }"
          :rules="[$validationRules.required]"
          class="required"
        />
        <h3 class="my-8">Osoitetiedot</h3>
        <div v-if="toimittaja.tyyppi === 'H'" class="comboField">
          <v-text-field
            v-model="toimittaja.etunimi"
            label="Etunimi"
            :rules="[$validationRules.required]"
            :hint="'Anna yksi tai useampi'"
            class="required"
          />
          <span class="comboField__separator"></span>
          <v-text-field
            v-model="toimittaja.sukunimi"
            label="Sukunimi"
            :rules="[$validationRules.required]"
            class="required"
          />
        </div>
        <v-text-field
          v-else
          v-model="toimittaja.nimi"
          label="Nimi"
          :rules="[$validationRules.required]"
          class="required"
          :hint="
            toimittaja.tyyppi !== 'Y'
              ? 'Anna henkilön nimi muodossa <sukunimi> <etunimet>'
              : ''
          "
        />
        <v-text-field
          v-model="toimittaja.postiosoite.saaja"
          label="Saaja / Tarkenne"
        />
        <v-text-field
          v-model="toimittaja.postiosoite.osoite"
          label="Lähisoite"
          :rules="[$validationRules.required]"
          class="required"
        />
        <v-row>
          <v-col md="5" class="py-1">
            <v-text-field
              v-model="toimittaja.postiosoite.numero"
              label="Postinumero"
              :rules="[$validationRules.required]"
              class="required"
            />
          </v-col>
          <v-col md="7" class="py-1">
            <v-text-field
              v-model="toimittaja.postiosoite.toimipaikka"
              label="Postitoimipaikka"
              :rules="[$validationRules.required]"
              class="required"
            />
          </v-col>
        </v-row>
        <v-select
          v-model="toimittaja.postiosoite.maa"
          label="Maa"
          type="text"
          :items="maat"
          item-value="value"
          item-text="selite"
          :menu-props="{ zIndex: '999', auto: true }"
          :rules="[$validationRules.required]"
          class="required"
        />
        <v-select
          v-if="toimittaja.id"
          ref="vanhaOsoite"
          v-model="vanhaOsoite"
          label="Hae vanhoista osoitteista"
          item-text="osoite"
          :items="toimittajaPostiosoiteItems"
          return-object
        >
          <template #item="{ item }">
            <span>
              <span v-if="item.saaja">{{ item.saaja }},</span>
              {{ item.osoite }}, {{ item.numero }}, {{ item.toimipaikka }}
            </span>
          </template>
        </v-select>
      </v-col>

      <!-- Oikea sarake -->
      <v-col md="6" class="pl-12 pr-4">
        <h3 class="my-8">Yhteystiedot</h3>
        <InputPuhelinNumero
          v-model:puh="toimittaja.puhelin.numero"
          :required="false"
          :outlined="false"
        />
        <v-text-field
          v-model="toimittaja.emailosoite.email"
          label="Sähköpostiosoite"
          :rules="[$validationRules.email]"
        />
        <v-text-field
          v-model="toimittaja.verkkolaskuosoite.verkkolaskuosoite"
          label="Verkkolaskuosoite"
          :disabled="toimittaja.tyyppi !== 'Y'"
          :error-messages="verkkolaskuosoiteErrors"
        />
        <v-autocomplete
          v-model="toimittaja.verkkolaskuosoite.operaattori"
          class="py-1"
          clearable
          :disabled="toimittaja.tyyppi !== 'Y'"
          :items="verkkolaskuoperaattorit"
          :filter="verkkolaskuoperaattoriFilter"
          item-text="autocomplete_selite"
          item-value="id"
          label="Verkkolaskuoperaattori"
          :error-messages="verkkolaskuoperaattoriErrors"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <!-- <template :v-if="process.env.VUE_APP_DEV_MODE== 1" >{{ this.toimittaja }}</template> -->

    <v-row class="mt-4 pa-3" justify="end">
      <v-btn large class="mr-4" @click="$emit('close')">Peruuta</v-btn>
      <v-btn large color="action" @click="tallenna">Tallenna</v-btn>
    </v-row>
  </v-form>
</template>

<script>
import _ from "lodash";
import { AsiakasPostiosoite } from "@/class/Asiakas";
import { Toimittaja } from "@/class/Toimittaja";
import AutocompletePaamies from "@/components/AutocompletePaamies";
import { mapState } from "vuex";
import InputPuhelinNumero from "@/components/InputPuhelinNumero";

export default {
  name: "FormToimittaja",
  components: {
    AutocompletePaamies,
    InputPuhelinNumero,
  },
  props: {
    // Toimittajan sisältävä objekti kuten ostolasku. Jos sitä ei ole, ollaan toimittajanäkymässä luomassa
    // uutta toimittajaa.
    kaareObjekti: {
      type: Object,
      required: false,
      default() {
        return null;
      },
    },
    onAuki: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      toimittaja: new Toimittaja(),
      toimittajanroErrors: [],
      tunnusErrors: [],
      vanhaOsoite: null,
      verkkolaskuosoiteErrors: [],
      verkkolaskuoperaattoriErrors: [],
    };
  },
  computed: {
    ...mapState({
      verkkolaskuoperaattorit: (state) => state.yleinen.verkkolaskuoperaattorit,
      kielet: (state) => state.yleinen.kielet,
      vastapuolenTyypit: (state) => state.yleinen.vastapuolenTyypit,
      maat: (state) => state.yleinen.maat,
    }),
    toimittajaPostiosoiteItems() {
      return this.toimittaja.postiosoite_set || [];
    },
  },
  watch: {
    onAuki: {
      immediate: true,
      handler() {
        this.$nextTick().then(() => {
          this.$refs.formToimittaja.resetValidation();
        });

        if (this.kaareObjekti) {
          if (this.kaareObjekti.toimittaja.id) {
            this.toimittaja = _.cloneDeep(this.kaareObjekti.toimittaja);
            this.toimittaja.id = this.kaareObjekti.toimittaja.id;
          } else if (
            this.kaareObjekti.paamies &&
            this.kaareObjekti.paamies.id
          ) {
            this.toimittaja = new Toimittaja({
              paamies_id: this.kaareObjekti.paamies.id,
            });
          }
        } else {
          this.toimittaja = new Toimittaja();
          this.toimittaja.paamies = this.$store.state.user.valittuPaamies;
        }
      },
    },
    toimittaja: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.paamies) {
          this.toimittaja.paamies_id = val.paamies.id;

          if (!this.kaareObjekti && val.paamies.id) {
            this.$store.commit(
              "user/setValittuPaamies",
              this.toimittaja.paamies
            );
          }
        }
      },
    },
    "toimittaja.verkkolaskuosoite": {
      deep: true,
      handler(verkkolaskuosoite) {
        if (
          verkkolaskuosoite.verkkolaskuosoite &&
          !verkkolaskuosoite.operaattori
        ) {
          this.verkkolaskuoperaattoriErrors = [
            "Syötä myös verkkolaskuoperaattori",
          ];
        } else {
          this.verkkolaskuoperaattoriErrors = [];
        }

        if (
          !verkkolaskuosoite.verkkolaskuosoite &&
          verkkolaskuosoite.operaattori
        ) {
          this.verkkolaskuosoiteErrors = ["Syötä myös verkkolaskuosoite"];
        } else {
          this.verkkolaskuosoiteErrors = [];
        }
      },
    },
    vanhaOsoite: {
      handler(val) {
        if (val) {
          this.toimittaja.postiosoite = new AsiakasPostiosoite(
            this.vanhaOsoite
          );
          this.$nextTick(() => {
            this.vanhaOsoite = "";
            this.$refs.vanhaOsoite.isFocused = false;
          });
        }
      },
    },
  },
  methods: {
    async tallenna() {
      if (!this.$refs.formToimittaja.validate()) return;

      const httpMethod = this.toimittaja.id ? "PUT" : "POST";
      const url = this.toimittaja.id ? `${this.toimittaja.id}/` : "";

      try {
        const request = await this.$doRequestWithTimeout(
          this.$api.Toimittajat,
          {
            method: httpMethod,
            url: url,
            body: this.toimittaja.getPostData(),
          },
          "doSingleRequest"
        );

        if (!request.success) throw new this.$HttpError(request);

        this.$emit(
          "toimittajaTallennettu",
          new Toimittaja(request.result.body)
        );

        this.$emit("close");
      } catch (e) {
        this.$oletusVirheenkasittely(e, "Toimittajan tallennus epäonnistui!");
      }
    },
    async tarkistaToimittajanro(nro) {
      // TODO Odottaa backend-toteutusta
      // this.toimittajanroErrors = []
      // if (!nro || !this.toimittaja.paamies_id && !this.toimittaja.paamies) return
      // const query = new URLSearchParams()
      // query.append('method', 'tarkista_toimittajanro')
      // query.append('nro', nro)
      // query.append('paamies_id', this.toimittaja.paamies_id || this.toimittaja.paamies.id)
      // const request = await this.$doRequestWithTimeout(
      //   this.$api.Yleinen,
      //   {
      //     url: 'toiminnot/tarkista',
      //     query: query.toString(),
      //   },
      //   'pushRequest',
      // )
      // const toimittajanroVapaana = request.result.body
      // if (!toimittajanroVapaana && this.toimittaja.nro) {
      //   this.toimittajanroErrors = ['Toimittajanumero on jo käytössä']
      // } else {
      //   this.toimittajanroErrors = []
      // }
    },
    async tarkistaTunnus(tunnus) {
      this.tunnusErrors = [];

      if (!tunnus || this.toimittaja.postiosoite.maa !== "FI") return;

      const query = new URLSearchParams();
      query.append("method", "tarkista_tunnus");
      query.append("tunnus", tunnus);
      query.append("tyyppi", this.toimittaja.tyyppi);

      const request = await this.$doRequestWithTimeout(
        this.$api.Yleinen,
        {
          url: "tarkista",
          query: query.toString(),
        },
        "pushRequest"
      );

      const tunnusOnOikeellinen = request.result.body;
      if (!tunnusOnOikeellinen && this.toimittaja.tunnus) {
        this.tunnusErrors = ["Tarkista tunnus"];
      } else {
        this.tunnusErrors = [];
      }
    },
    verkkolaskuoperaattoriFilter(item, hakusana) {
      const id = item.id.toLowerCase();
      const nimi = item.nimi.toLowerCase();
      const hakuteksti = hakusana.toLowerCase();

      return id.indexOf(hakuteksti) > -1 || nimi.indexOf(hakuteksti) > -1;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-text-field,
.v-select {
  padding-top: 0px;
  margin-top: 4px;
}

.hidden {
  visibility: hidden;
}
</style>
