<template>
  <v-autocomplete
    ref="autocompletePaamies"
    v-model="paamies"
    :items="paamiehet"
    :loading="paamiesLoading"
    :hide-no-data="paamiesLoading"
    :item-text="getPaamiesNimi"
    item-value="id"
    :label="label"
    :placeholder="placeholder"
    :persistent-placeholder="persistentPlaceholder"
    return-object
    class="show-hidden-child-on-hover"
    :class="{ required: requiredClass }"
    :clearable="clearable"
    :cache-items="multiple"
    :chips="multiple"
    :deletable-chips="multiple"
    :multiple="multiple"
    :counter="valintojenMaksimiLkm"
    :rules="validointiSaannotKoottu"
    :disabled="disabled"
    :outlined="outlined"
    :dense="dense"
    @input="kasitteleValinta"
  >
    <template #item="{ item }">
      <v-list-item-content>
        <v-list-item-title>{{ item.nimi }} ({{ item.id }})</v-list-item-title>
        <v-list-item-subtitle
          v-if="tarkistaLaskuEsto && item.esta_laskun_luonti"
        >
          {{ $t("common:autocompletePaamies.createInvoiceNotPermitted") }}
        </v-list-item-subtitle>
        <v-list-item-subtitle
          v-else-if="tarkistaToimeksiantoEsto && item.esta_toimeksiannon_luonti"
        >
          {{ $t("common:autocompletePaamies.createAssignmentNotPermitted") }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-else-if="item.postiosoite">
          <template v-if="item.postiosoite.postiosoite">
            {{ item.postiosoite.postiosoite }},
          </template>
          <template v-if="item.postiosoite.postinumero">
            {{ item.postiosoite.postinumero }}
          </template>
          <template v-if="item.postiosoite.postitoimipaikka">
            {{ item.postiosoite.postitoimipaikka }}
          </template>
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
    <template #no-data>
      <p class="pa-4">
        {{ $t("common:autocompletePaamies.searchFoundNoCreditors") }}
      </p>
    </template>
    <template
      v-if="
        paamieshallinta &&
        $permission.checkSinglePerm(
          'onlinerestapi.onlinerestapi_paamieshallinta'
        )
      "
      #append-outer
    >
      <LomakenappiIkonilla
        ikoni="add"
        :infoteksti="$t('autocompletePaamies.createNewCreditor')"
        @click="$emit('open-dialog-paamies')"
      />
    </template>
  </v-autocomplete>
</template>

<script>
import LomakenappiIkonilla from "@/components/LomakenappiIkonilla";

export default {
  name: "AutocompletePaamies",
  components: {
    LomakenappiIkonilla,
  },
  model: {
    prop: "paamiesModel",
    event: "update:paamies",
  },
  props: {
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    // Used to be  return this.$t("common:invoicer");
    label: {
      type: String,
      required: false,
      default() {
        return this.$t("common:creditor");
      },
    },
    placeholder: {
      type: String,
      required: false,
      default() {
        return this.$t(
          "common:autocompletePaamies.searchWithCreditorNameOrNumber"
        );
      },
    },
    persistentPlaceholder: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    multiple: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    paamieshallinta: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    tarkistaLaskuEsto: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    tarkistaToimeksiantoEsto: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    tarkistettavaOikeus: {
      type: String,
      required: false,
      default: "",
    },
    paamiesModel: {
      type: [Object, Array],
      required: true,
    },
    validointiSaannot: {
      type: Array,
      required: false,
      default: () => [],
    },
    valintojenMaksimiLkm: {
      type: [Boolean, Number],
      required: false,
      default: false, // Ei maksimia kun arvo on false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    propItems: {
      type: [Array, null],
      required: false,
      default: null,
    },
    requiredClass: {
      type: Boolean,
      required: false,
      default() {
        return true;
      },
    },
    // TODO Tällä hetkellä tätä käytetään, kun v-modelina on storen valittuPaamies.
    // Komponentti päivittää storen valittuPaamies-kenttää kuitenkin automaattisesti,
    // joten sen antaminen v-modelina ei olisi edes tarpeellista.
    emitEmpty: {
      type: Boolean,
      required: false,
      default() {
        return true;
      },
    },
  },
  emits: ["update:paamies", "autocomplete-input", "open-dialog-paamies"],
  data() {
    return {
      internalPaamies: null,
      paamiesItems: [],
      paamiesLoading: false,
    };
  },
  computed: {
    paamies: {
      get() {
        return this.internalPaamies;
      },
      set(value) {
        this.internalPaamies = value;
        if (this.emitEmpty || value?.id) this.$emit("update:paamies", value);
      },
    },
    paamiehet() {
      if (this.propItems !== null) {
        return this.propItems;
      } else {
        return this.paamiesItems;
      }
    },
    validointiSaannotKoottu() {
      const maksimiLkm = this.valintojenMaksimiLkm
        ? [this.validoiValintojenMaksimiLkm]
        : [];
      return [...this.validointiSaannot, ...maksimiLkm];
    },
    user() {
      return this.$store.state.user;
    },
  },
  watch: {
    paamiesModel: {
      immediate: true,
      handler(newValue, oldValue) {
        if (this.multiple) {
          if (newValue !== oldValue) {
            this.internalPaamies = newValue;
          }
        } else {
          if (newValue?.id !== oldValue?.id) {
            this.internalPaamies = newValue;
          }
        }
      },
    },
  },
  created() {
    // Päämiehien haussa voi kestää, joten tehdään alustus ensin tiedoilla mitä on.
    // Sen jälkeen haetaan päämiehet ja alustetaan uudestaan siltä varalta, että on
    // luotu uusi päämies jonkun toisen käyttäjän toimesta.
    this.paamiesLoading = true;
    this.alustaPaamiehet();
    this.paamiesLoading = false;
    this.haePaamiehet();
    this.alustaPaamiehet();
  },
  methods: {
    getPaamiesNimi(paamies) {
      if (this.$store.state.user.vainYksiPaamies) {
        return paamies.nimi;
      } else {
        return paamies.nimi + " (" + paamies.id + ")";
      }
    },
    validoiValintojenMaksimiLkm(paamiesArr) {
      if (paamiesArr.length <= this.valintojenMaksimiLkm) {
        return true;
      } else {
        if (this.valintojenMaksimiLkm === 1) {
          return this.$t("common:autocompletePaamies.selectOnlyOneCreditor");
        } else {
          return this.$t(
            "common:autocompletePaamies.selectAtHighestCreditors",
            { maxNoOfCreditors: this.valintojenMaksimiLkm }
          );
        }
      }
    },
    async kasitteleValinta(paamies) {
      if (!paamies) {
        // v-autocomplete asettaa paamiehen arvoksi tyhjennettäessä undefined tai null,
        // joten asetetaan arvoksi manuaalisesti objekti ja monihaussa array.
        this.paamies = this.multiple ? [] : {};
        if (!this.emitEmpty) return;
      }
      if (!this.multiple) await this.valitsePaamies(this.paamies);
      this.$emit("autocomplete-input", this.paamies);
    },
    async valitsePaamies(paamies) {
      if (paamies && paamies.id) {
        await this.$store.dispatch("user/haeValittuPaamies", paamies.id);
      }
    },
    async alustaPaamiehet() {
      if (
        this.tarkistettavaOikeus &&
        this.$store.getters["user/tarkistetaankoPaamieskohtaisetOikeudet"]
      ) {
        // Jos jonkun päämiehen oikeuksia ei löydy storesta, on mahdollista että päämies on luotu
        // sisäänkirjautumisen jälkeen. Päämieskohtaiset oikeudet pitää tällöin päivittää varmuuden
        // vuoksi.
        const paamiesIds = this.user.paamiehet.map((paamies) => paamies.id);
        if (!paamiesIds.every((id) => id in this.user.permissionsPaamiehet)) {
          await this.$store.dispatch("user/getUserWithoutPaamiehet");
        }

        // Have to do this in parts for some reason, otherwise all are undefined
        const paamiehet = this.user.paamiehet;

        paamiehet.map((paamies) => {
          let paamiehellaEiOikeutta = false;
          const paamiesPerms = this.user.permissionsPaamiehet[paamies.id];

          paamiehellaEiOikeutta = !paamiesPerms.includes?.(
            this.tarkistettavaOikeus
          );
          if (
            paamiehellaEiOikeutta ||
            (this.tarkistaLaskuEsto && paamies.esta_laskun_luonti) ||
            (this.tarkistaToimeksiantoEsto && paamies.esta_toimeksiannon_luonti)
          ) {
            paamies.disabled = true;
          }
        });
        this.paamiesItems = paamiehet;
      } else {
        this.paamiesItems = this.user.paamiehet;
      }
    },
    async haePaamiehet() {
      await this.$store.dispatch("user/haePaamiehet");
    },
  },
};
</script>

<style scoped lang="scss"></style>
