var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("FormRaporttiAsetuksetBase", {
    scopedSlots: _vm._u([
      {
        key: "vasen-sarake",
        fn: function () {
          return [
            _c("v-text-field", {
              attrs: {
                label: "Pankkitunnus",
                rules: [_vm.validoiPankkitunnus],
                hint: _vm.$t("common:infotekstit.kTLInfo.useBankId"),
              },
              model: {
                value: _vm.raporttilomake.asetukset.kayta_pankkitunnus,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.raporttilomake.asetukset,
                    "kayta_pankkitunnus",
                    $$v
                  )
                },
                expression: "raporttilomake.asetukset.kayta_pankkitunnus",
              },
            }),
            _c("v-text-field", {
              attrs: {
                label: "Tilinumero (IBAN)",
                rules: _vm.raporttilomake.asetukset.kayta_tilinumero
                  ? [_vm.$validationRules.isValidIBAN]
                  : [],
                hint: _vm.$t("common:infotekstit.kTLInfo.useAccountNumber"),
              },
              model: {
                value: _vm.raporttilomake.asetukset.kayta_tilinumero,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.raporttilomake.asetukset,
                    "kayta_tilinumero",
                    $$v
                  )
                },
                expression: "raporttilomake.asetukset.kayta_tilinumero",
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "oikea-sarake",
        fn: function () {
          return [
            _c("v-text-field", {
              attrs: {
                label: "Kirjauspäivän viive",
                hint: _vm.$t(
                  "common:infotekstit.kTLInfo.ktlTransactionDateSettlement"
                ),
              },
              model: {
                value:
                  _vm.raporttilomake.asetukset.ktl_kirjauspaiva_tilityksesta,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.raporttilomake.asetukset,
                    "ktl_kirjauspaiva_tilityksesta",
                    $$v
                  )
                },
                expression:
                  "raporttilomake.asetukset.ktl_kirjauspaiva_tilityksesta",
              },
            }),
            _c("v-select", {
              attrs: {
                label: "Viitteenä käytettävä tunniste",
                type: "text",
                items: _vm.KAYTA_TUNNISTETTA_VAIHTOEHDOT,
                "item-value": "value",
                "item-text": "selite",
                "menu-props": { zIndex: "999", auto: true },
                hint: _vm.$t("common:infotekstit.kTLInfo.useId"),
              },
              model: {
                value: _vm.raporttilomake.asetukset.kayta_tunnistetta,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.raporttilomake.asetukset,
                    "kayta_tunnistetta",
                    $$v
                  )
                },
                expression: "raporttilomake.asetukset.kayta_tunnistetta",
              },
            }),
            _c("v-select", {
              attrs: {
                label: "Rivinvaihto",
                type: "text",
                items: _vm.KTL_RIVINVAIHTO_VAIHTOEHDOT,
                "item-value": "value",
                "item-text": "selite",
                "menu-props": { zIndex: "999", auto: true },
                hint: _vm.$t("common:infotekstit.kTLInfo.ktlNewLine"),
              },
              model: {
                value: _vm.raporttilomake.asetukset.ktl_rivinvaihto,
                callback: function ($$v) {
                  _vm.$set(_vm.raporttilomake.asetukset, "ktl_rivinvaihto", $$v)
                },
                expression: "raporttilomake.asetukset.ktl_rivinvaihto",
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }