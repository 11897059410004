<template>
  <v-form v-model="valid" @submit.prevent="submit">
    <v-select
      v-model="value.laatu"
      :label="$t('quality')"
      type="text"
      :items="laadut"
      item-value="id"
      item-text="label"
      :menu-props="{ zIndex: '999' }"
      :rules="[$validationRules.required]"
      class="required"
    />
    <div class="comboField">
      <v-menu
        v-model="tapahtumaPvmMenu"
        :close-on-content-click="false"
        max-width="290"
      >
        <template #activator="{ on }">
          <v-text-field
            :label="$t('eventDate')"
            :value="$dateFnsFormat(value.tapahtumaPvm)"
            readonly
            :rules="[$validationRules.required]"
            class="required"
            v-on="on"
          />
        </template>
        <template #default>
          <v-date-picker
            ref="alkamispaivaDatePicker"
            v-model="value.tapahtumaPvm"
            locale="fi-FI"
            first-day-of-week="1"
            no-title
            @change="tapahtumaPvmMenu = false"
          />
        </template>
      </v-menu>
      <TooltipInfo
        icon-class="align-self-start ml-2"
        icon-color="primary"
        :text="$t('eventDateInfo')"
      />
    </div>
    <v-text-field
      v-model="value.maksettuRahamaara"
      type="text"
      :label="$t('paidMoney')"
      :rules="[
        $validationRules.required,
        $validationRules.maxDecimalPlacesOrEmpty(value.summa, 2),
        $validationRules.numberOrEmptyWithDecimal,
      ]"
      suffix="€"
    />
    <v-text-field
      v-model="value.muutoksenSelite"
      class="required"
      type="text"
      :label="$t('changeDescription')"
      :rules="[$validationRules.required]"
    />
    <PeruutaHyvaksyPainikerivi
      :submit-disabled="!valid || loading"
      :submit-loading="loading"
      :submit-teksti="$t('common:collectionShow.cancelDistraint')"
      :peruuta-teksti="$t('common:close')"
      @close="$emit('close')"
    />
  </v-form>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";

export default {
  name: "FormUlosotonPeruutus",
  mixins: [FormMixin],
  data() {
    return {
      tapahtumaPvmMenu: false,
      laadut: [
        { id: "1", label: this.$t("distraintCancelIssues.paidCancellation") },
        { id: "2", label: this.$t("distraintCancelIssues.receiptCancel") },
        {
          id: "3",
          label: this.$t("distraintCancelIssues.otherReasonCancellation"),
        },
        {
          id: "36",
          label: this.$t(
            "distraintCancelIssues.unreasonablePaymentCancellation"
          ),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
