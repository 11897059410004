<template>
  <v-menu transition="slide-y-transition" nudge-bottom="40">
    <template #activator="{ on }">
      <div
        class="loggedInUser__wrap my-6"
        :class="miniVariant ? 'px-0' : 'px-2'"
        v-on="on"
      >
        <div class="loggedInUser__col loggedInUser__col--avatar">
          <span class="loggedInUser__iconWrap">
            <v-icon class="loggedInUser__icon">person</v-icon>
          </span>
        </div>
        <template v-if="showUsername">
          <span
            class="loggedInUser__col loggedInUser__col--content loggedInUser__col--fill"
          >
            {{ user.naytettavaNimi }}
          </span>
          <div class="loggedInUser__col loggedInUser__col--actions">
            <v-icon>arrow_drop_down</v-icon>
          </div>
        </template>
      </div>
    </template>
    <template #default>
      <v-list>
        <v-list-item
          v-if="
            !tekeytyminenKaynnissa &&
            onlineUrl &&
            $permission.checkSinglePerm('anitta_user.onlinepalvelu')
          "
          @click="$emit('siirry-onlineen')"
        >
          <v-list-item-avatar>
            <v-icon>east</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Online</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="
            $permission.checkSinglePerm('onlinerestapi.onlinerestapi_asetukset')
          "
          :to="{ name: 'asetukset' }"
        >
          <v-list-item-avatar>
            <v-icon>settings</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Asetukset</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="!tekeytyminenKaynnissa" @click="$emit('logOut')">
          <v-list-item-avatar>
            <v-icon>logout</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Kirjaudu ulos</v-list-item-title>
        </v-list-item>
        <div v-if="user.isSuperuser">
          <LanguageSwitch />
        </div>
      </v-list>
    </template>
  </v-menu>
</template>

<script>
import { mapState } from "vuex";
import LanguageSwitch from "@/components/LanguageSwitch";

export default {
  name: "LoggedInUser",
  components: {
    LanguageSwitch,
  },
  props: {
    miniVariant: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    showUsername: {
      type: Boolean,
      required: false,
      default() {
        return true;
      },
    },
    tekeytyminenKaynnissa: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    onlineUrl() {
      return process.env.VUE_APP_ANITTA_ONLINE_URL;
    },
    devMode() {
      return process.env.VUE_APP_ENV === "development";
    },
  },
};
</script>

<style lang="scss" scoped>
.loggedInUser {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  &__wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    max-width: 100%;
    cursor: pointer;
  }

  &__col {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;

    &--fill {
      flex: 1 1 auto;
    }

    &--content {
      margin-left: 1em;
      margin-right: 0.5em;
      text-align: left;
      overflow: hidden;
    }
  }

  &__iconWrap {
    overflow: hidden;
    position: relative;
    display: inline-block;
    width: 1.8rem;
    height: 1.8rem;
    border: solid 2px lighten(#000, 50);
    border-radius: 10rem;
  }

  &__icon {
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 0;
    padding: 0;
    color: lighten(#000, 50);
    transform: translateY(-40%) translateX(-50%) scale(1.3);
  }
}
</style>
