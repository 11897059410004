/**
 * https://stackoverflow.com/questions/48327261/way-to-make-inheritance-in-vuex-modules/56790942#56790942
 */

import api from "@/plugins/api";
import { HttpError, SallittuKokoYlitettyError } from "@/utils/errors";
import { doRequestWithTimeout } from "@/utils/misc";
import BaseModule from "@/store/modules/baseModule";
import { MaksimiUploadTiedostoKoko } from "@/utils/constants";

export default class PerintaNayta extends BaseModule {
  state() {
    return {
      ...super.state(),
      ulosottoPeruutusFormData: {
        asia_id: null,
        laatu: "",
        tapahtumaPvm: null,
        maksettuRahamaara: 0,
        muutoksenSelite: "",
      },
      ohisuoritusFormData: {
        maksupaiva: null,
        summa: 0,
        kohdistus: {
          value: null,
          label: null,
        },
      },
      asiakirjaFormData: {},
      ohisuoritusKohdistukset: [],
      uusiViestiFormData: {},
      toimeksianto: {
        ikoni_ja_vari: {
          ikoni: null,
          vari: null,
        },
        paamies: {
          nimi: "",
        },
        toimeksiantovelallinen_set: [],
        tapahtuma_set: [],
        saatavarivi_set: [],
        avoinna: null,
        tila: null,
        aikajana: [],
        asiakirja_set: [],
        kirje_set: [],
      },
    };
  }

  getters() {
    return {};
  }
  mutations() {
    return {
      setToimeksianto(state, payload) {
        state.toimeksianto = payload;
      },
      setOhisuoritusFormData(state, payload) {
        state.ohisuoritusFormData = payload;
      },
      setUlosottoPeruutusFormData(state, payload) {
        state.ulosottoPeruutusFormData = payload;
      },
      setOhisuoritusKohdistukset(state, payload) {
        state.ohisuoritusKohdistukset = payload;
      },
      setUusiViestiFormData(state, payload) {
        state.uusiViestiFormData = payload;
      },
      setAsiakirjaFormData(state, payload) {
        state.asiakirjaFormData = payload;
      },
    };
  }

  actions() {
    return {
      async clearDetails({ commit }) {
        commit("setToimeksianto", {});
        commit("setOhisuoritusFormData", {});
        commit("setOhisuoritusKohdistukset", {});
        commit("setUusiViestiFormData", {});
      },
      async loadItem({ commit }, sroute) {
        const request = await doRequestWithTimeout(
          api.Toimeksianto,
          {
            method: "GET",
            url: "",
            params: {
              id: sroute.params.id,
            },
          },
          "pushRequest"
        );
        if (!request.success) throw new HttpError(request);

        commit("setToimeksianto", request.result.body);
      },
      alustaOhisuoritusFormData({ commit, state }) {
        const ohisuoritusFormData = {
          maksupaiva: new Date().toISOString().substr(0, 10),
          summa: state.toimeksianto.avoinna_paaoma,
          kohdistus: {
            value: null,
            label: null,
          },
        };

        const ohisuoritusKohdistukset = [];
        ohisuoritusKohdistukset.push({
          value: null,
          label: "Vanhin ensin",
        });

        state.toimeksianto.saatavarivi_set.forEach((item) => {
          ohisuoritusKohdistukset.push({
            value: item.id,
            label: item.laskunumero,
          });
        });

        ohisuoritusFormData.kohdistus = state.ohisuoritusKohdistukset[0];
        commit("setOhisuoritusFormData", ohisuoritusFormData);
        commit("setOhisuoritusKohdistukset", ohisuoritusKohdistukset);
      },
      async ohisuoritusSubmit({ commit, state }) {
        const requestBody = {
          maksupaiva: state.ohisuoritusFormData.maksupaiva,
          summa: state.ohisuoritusFormData.summa,
        };

        if (state.ohisuoritusFormData.kohdistus.value) {
          requestBody.kohdesaatava = state.ohisuoritusFormData.kohdistus.value;
        }

        const request = await doRequestWithTimeout(
          api.Toimeksianto,
          {
            method: "POST",
            url: "toiminnot/ohisuoritus/",
            body: requestBody,
            params: {
              id: state.toimeksianto.id,
            },
          },
          "doSingleRequest"
        );
        if (!request.success) throw new HttpError(request);
        commit("setToimeksianto", request.result.body);
      },
      alustaUlosottoPeruutusFormData({ commit, state }, asia_id) {
        const ulosottoPeruutusFormData = {
          asia_id: asia_id,
          laatu: null,
          tapahtumaPvm: new Date().toISOString().substr(0, 10),
          maksettuRahamaara: "",
          muutoksenSelite: "",
        };

        commit("setUlosottoPeruutusFormData", ulosottoPeruutusFormData);
      },
      async ulosotonPeruutusSubmit({ commit, state }) {
        if (state.ulosottoPeruutusFormData?.maksettuRahamaara) {
          state.ulosottoPeruutusFormData.maksettuRahamaara =
            state.ulosottoPeruutusFormData.maksettuRahamaara.replace(",", ".");
        }
        const requestBody = {
          asia_id: state.ulosottoPeruutusFormData.asia_id,
          laatu: state.ulosottoPeruutusFormData.laatu,
          tapahtumaPvm: state.ulosottoPeruutusFormData.tapahtumaPvm,
          maksettuRahamaara: state.ulosottoPeruutusFormData.maksettuRahamaara,
          muutoksenSelite: state.ulosottoPeruutusFormData.muutoksenSelite,
        };
        const request = await doRequestWithTimeout(
          api.Toimeksianto,
          {
            method: "POST",
            url: "toiminnot/ulosoton_peruutus/",
            body: requestBody,
            params: {
              id: state.toimeksianto.id,
            },
          },
          "doSingleRequest"
        );
        if (!request.success) throw new HttpError(request);
      },
      async viestiSubmit({ commit, state }) {
        const requestBody = {
          sisalto: state.uusiViestiFormData.message,
        };

        const request = await doRequestWithTimeout(
          api.Toimeksianto,
          {
            method: "POST",
            url: "toiminnot/viesti/",
            body: requestBody,
            params: {
              id: state.toimeksianto.id,
            },
          },
          "doSingleRequest"
        );

        if (!request.success) throw new HttpError(request);

        commit("setToimeksianto", request.result.body);
        commit("setUusiViestiFormData", {});
      },
      async asiakirjaSubmit({ commit, state }) {
        const formData = new FormData();
        if (state.asiakirjaFormData.tiedosto.size > MaksimiUploadTiedostoKoko)
          throw new SallittuKokoYlitettyError();
        formData.set("attachment_file", state.asiakirjaFormData.tiedosto);

        const request = await doRequestWithTimeout(
          api.Toimeksianto,
          {
            method: "POST",
            url: `toiminnot/asiakirja/`,
            body: formData,
            params: {
              id: state.toimeksianto.id,
            },
          },
          "doSingleRequest"
        );

        if (!request.success) throw new HttpError(request);

        commit("setToimeksianto", request.result.body);
      },
    };
  }
}
