<template>
  <div>
    <h3 v-if="titleText" :class="titleClass">
      {{ titleText }}
    </h3>
    <v-data-table
      :items="sopimus.yhteenvetorivit"
      :headers="headers"
      :hide-default-footer="
        !sopimus.yhteenvetorivit || sopimus.yhteenvetorivit.length <= 10
      "
      :no-data-text="$t('common:noRows')"
      :dense="tiivisNakyma"
    >
      <template #item="{ item }">
        <TableRow>
          <td>{{ item.selite }}</td>
          <td class="text-right">
            {{ item.laskutettu | formatSumToFixed2 }}
          </td>
          <td class="text-right">
            {{ item.suoritettu | formatSumToFixed2 }}
          </td>
          <td class="text-right">
            {{
              sopimus.onko_lainasopimus
                ? item.avoinna
                : item.laskutettu_avoinna | formatSumToFixed2
            }}
          </td>
        </TableRow>
      </template>
      <template #body.append>
        <TableRow>
          <td>
            <strong>{{ $t("common:total") }}</strong>
          </td>
          <td class="text-right">
            <strong>{{ sopimus.laskutettu | formatSumToFixed2 }}</strong>
          </td>
          <td class="text-right">
            <strong>
              {{
                (sopimus.laskutettu -
                  (sopimus.avoinna_kaikki - sopimus.laskuttamatta))
                  | formatSumToFixed2
              }}
            </strong>
          </td>
          <td class="text-right">
            <strong>
              {{
                sopimus.onko_lainasopimus
                  ? sopimus.avoinna_kaikki
                  : (sopimus.avoinna - sopimus.laskuttamatta)
                    | formatSumToFixed2
              }}
            </strong>
          </td>
        </TableRow>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  TableHeadersSopimusYhteenveto,
  TableHeadersSopimusLainasopimusYhteenveto,
} from "@/utils/tableHeaders";
import TiivisNakymaMixin from "@/mixins/TiivisNakymaMixin";

export default {
  nimi: "SopimusYhteenveto",
  mixins: [TiivisNakymaMixin],
  props: {
    sopimus: {
      type: Object,
      required: true,
    },
    titleClass: {
      type: String,
      required: false,
      default() {
        return "mb-4";
      },
    },
    titleText: {
      type: String,
      required: false,
      default() {
        return "";
      },
    },
  },
  computed: {
    headers() {
      return this.sopimus.onko_lainasopimus
        ? TableHeadersSopimusLainasopimusYhteenveto
        : TableHeadersSopimusYhteenveto;
    },
  },
};
</script>

<style></style>
