<template>
  <div>
    <div
      v-if="!user.vainYksiPaamies && paamies"
      :class="tiivisNakyma ? 'mb-2' : 'mb-3'"
    >
      <p class="mb-0 font-weight-bold grey--text darken-2">
        {{ $t("common:creditor") }}
      </p>
      <h3>
        <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
        {{ paamies.nimi }}
        <template v-if="paamies.id">({{ paamies.id }})</template>
      </h3>
    </div>
    <slot name="vastaanottaja">
      <VastapuoliKappale
        :vastapuoli="vastapuoli"
        :vastapuoli-rooli="vastapuoliRooli"
        :vastapuoli-route="vastapuoliRoute"
        :ei-vastapuoli-linkkia="eiVastapuoliLinkkia"
      />
    </slot>
  </div>
</template>

<script>
import VastapuoliKappale from "@/components/VastapuoliKappale";
import TiivisNakymaMixin from "@/mixins/TiivisNakymaMixin";
import { mapState } from "vuex";

export default {
  name: "OsapuoltenTiedotKappale",
  components: {
    VastapuoliKappale,
  },
  mixins: [TiivisNakymaMixin],
  props: {
    paamies: {
      type: Object,
      required: true,
    },
    vastapuoli: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    vastapuoliRooli: {
      type: String,
      required: false,
      default() {
        return "asiakas";
      },
      validator: (arvo) =>
        ["asiakas", "toimittaja", "velallinen"].includes(arvo),
    },
    vastapuoliRoute: {
      type: String,
      required: false,
      default() {
        return "laskutus-asiakas-nayta";
      },
    },
    eiVastapuoliLinkkia: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
};
</script>

<style></style>
