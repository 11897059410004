var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.user.vainYksiPaamies && _vm.paamies
        ? _c("div", { class: _vm.tiivisNakyma ? "mb-2" : "mb-3" }, [
            _c(
              "p",
              { staticClass: "mb-0 font-weight-bold grey--text darken-2" },
              [_vm._v(" " + _vm._s(_vm.$t("common:creditor")) + " ")]
            ),
            _c(
              "h3",
              [
                _vm._v(" " + _vm._s(_vm.paamies.nimi) + " "),
                _vm.paamies.id
                  ? [_vm._v("(" + _vm._s(_vm.paamies.id) + ")")]
                  : _vm._e(),
              ],
              2
            ),
          ])
        : _vm._e(),
      _vm._t("vastaanottaja", function () {
        return [
          _c("VastapuoliKappale", {
            attrs: {
              vastapuoli: _vm.vastapuoli,
              "vastapuoli-rooli": _vm.vastapuoliRooli,
              "vastapuoli-route": _vm.vastapuoliRoute,
              "ei-vastapuoli-linkkia": _vm.eiVastapuoliLinkkia,
            },
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }