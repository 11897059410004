import BaseOptimizedCountSelaa from "@/store/modules/baseOptimizedCountSelaa";
import { TabsLaskutus } from "@/utils/tabs";

export default class LaskutusSelaa extends BaseOptimizedCountSelaa {
  state() {
    return {
      ...super.state(),
      apiName: "Laskut",
      csvApiName: "LaskutCsv",
      tabs: TabsLaskutus || [],
    };
  }
}
