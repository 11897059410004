<template>
  <v-form v-model="valid" @submit.prevent="submit">
    <v-select
      v-model="value.lahetystapa"
      class="required"
      label="Lähetystapa"
      type="text"
      :items="lahetystapaSuodatettuItems"
      item-value="value"
      item-text="label"
      :menu-props="{ zIndex: '999' }"
    />
    <v-select
      v-model="value.kirjepohja"
      class="required mt-1 pt-1"
      label="Laskupohja"
      type="text"
      :items="kirjepohjat"
      item-text="nimi"
      item-value="id"
      no-data-text="Ei laskupohjia"
    />
    <v-text-field
      v-show="value.lahetystapa === 'email'"
      v-model="value.email"
      :class="onkoEmailKenttaPakollinen ? 'required' : ''"
      type="text"
      label="Email"
      :placeholder="emailPlaceholder"
      persistent-placeholder
      :rules="emailValidationRules"
    />
    <v-textarea
      v-show="vapaatekstiSallittu"
      v-model="value.vapaateksti"
      type="text"
      label="Vapaateksti"
      :maxlength="2047"
    />
    <PeruutaHyvaksyPainikerivi
      :submit-disabled="!valid || loading"
      :submit-loading="loading"
      submit-teksti="Lähetä"
      @close="$emit('close')"
    />
  </v-form>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";
import {
  onkoLahetystapaDisabloitu,
  lahetystavatHintoineen,
} from "@/utils/misc";

export default {
  name: "FormLaskukopioLaheta",
  mixins: [FormMixin],
  props: {
    lasku: {
      type: Object,
      required: true,
    },
    kirjepohjat: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      lahetystavatHintoineen: [],
    };
  },
  computed: {
    emailPlaceholder() {
      return this.onkoAsiakkaallaEmailOsoite
        ? "Käytetään asiakkaan oletussähköpostiosoitetta"
        : "Anna sähköpostiosoite tai lisää se asiakkaalle";
    },
    onkoAsiakkaallaEmailOsoite() {
      return (
        this.lasku.asiakas.emailosoite && this.lasku.asiakas.emailosoite.email
      );
    },
    onkoEmailKenttaPakollinen() {
      return (
        !this.onkoAsiakkaallaEmailOsoite && this.value.lahetystapa === "email"
      );
    },
    emailValidationRules() {
      const rules = [this.$validationRules.email];
      if (this.onkoEmailKenttaPakollinen)
        rules.push(this.$validationRules.required);
      return rules;
    },
    lahetystapaSuodatettuItems() {
      const lahetystavat = this.lahetystavatHintoineen.map((lahetystapa) => {
        // Email sallitaan aina, vaikka asiakkaalle olisi määritetty sitä, koska käyttäjä voi syöttää sen
        const disabled =
          lahetystapa.value === "email"
            ? false
            : onkoLahetystapaDisabloitu(lahetystapa.value, this.lasku.asiakas);
        return Object.assign({ disabled }, lahetystapa);
      });
      return lahetystavat;
    },
    vapaatekstiSallittu() {
      return this.kirjepohjat.find(
        (kirjepohja) => kirjepohja.id === this.value.kirjepohja
      )?.vapaateksti_sallittu;
    },
  },
  created() {
    this.alustaLahetystavatHintoineen();

    let alkupLaskupohja = {};
    if (this.lasku.kirje_set.length) {
      alkupLaskupohja = this.kirjepohjat.find(
        (kirjepohja) => kirjepohja.id === this.lasku.kirje_set[0].kirjepohja.id
      );
    }

    // Tehdään lähetystavasta ja kirjepohjasta valuen reaktiivinen property
    this.value = Object.assign({}, this.value, {
      lahetystapa: this.lasku.lahetystapa_key,
      kirjepohja: alkupLaskupohja?.id,
    });
  },
  methods: {
    alustaLahetystavatHintoineen() {
      try {
        this.lahetystavatHintoineen = Object.assign(
          [],
          lahetystavatHintoineen(
            this.lasku.paamies,
            this.lasku.asiakas.postiosoite.maa
          )
        );
      } catch (e) {
        this.$oletusVirheenkasittely(
          e,
          "Laskun lähetystapojen alustus epäonnistui"
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
