var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("CardTitle", {
        attrs: {
          color: "black--text",
          text: _vm.$t("common:priceList"),
          center: false,
        },
      }),
      _c("v-data-table", {
        attrs: {
          headers: _vm.TableHeadersPorrashinnasto,
          items: _vm.porrashinnasto,
          "hide-default-footer": _vm.porrashinnasto?.length <= 10,
        },
        scopedSlots: _vm._u([
          {
            key: "item.ahinta",
            fn: function ({ item }) {
              return [_vm._v(" " + _vm._s(item.ahinta) + " € / kpl ")]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }