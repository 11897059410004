/**
 * Anittaan kytkettyyn inseen yhteyden ottava API-moduuli.
 *
 * Huom. insessä on määritetty INSE_ANITTA_PALVELUHAKEMUS_BASE_URL, joka määrittää mihin
 * anitta-instanssiin tämän api-moduulin kyselyt insen kautta ohjataan. Mikäli haluat kehittää/testata
 * näitä inse-kyselyitä lokaalisti, määritä testi-insen kokoonpanoon yo. env-avaimen arvoksi
 * lokaalin anittan osoite testi-anittan tilalle.
 */

import Vue from "vue";
import fetch from "@apicase/adapter-fetch";
import { ApiService } from "@apicase/services";
import store from "@/store";

const Base = new ApiService({
  adapter: fetch,
  url: process.env.VUE_APP_INSE_ANITTA_BASE_URL,
  mode: "cors",
});

const Palveluhakemus = Base.extend({
  url: "palveluhakemus",
  hooks: {
    async fail({ payload, result, retry, next }) {
      // Todennäköisesti token vanhentunut
      if (result.status === 401) {
        const { success, result } = await RefreshToken.doUniqueRequest();

        if (success) {
          const { access } = result.body;
          store.commit("palveluhakemus/setAccessToken", access);
          payload.headers = {
            ...payload.headers,
            Authorization: "Bearer " + access,
          };

          retry(payload);
        } else {
          // Retry epäonnistui, palautetaan poikkeus
          next();
        }
      } else {
        // Muu virhekoodi, palautetaan poikkeus
        next();
      }
    },
  },
});

const Luottotietosopimus = Base.extend({
  url: "luottotietosopimus",
  hooks: {
    async fail({ payload, result, retry, next }) {
      // Todennäköisesti token vanhentunut
      if (result.status === 401) {
        const { success, result } = await RefreshToken.doUniqueRequest();

        if (success) {
          const { access } = result.body;
          store.commit("luottotiedot/setAccessToken", access);
          payload.headers = {
            ...payload.headers,
            Authorization: "Bearer " + access,
          };

          retry(payload);
        } else {
          // Retry epäonnistui, palautetaan poikkeus
          next();
        }
      } else {
        // Muu virhekoodi, palautetaan poikkeus
        next();
      }
    },
  },
});

const LuottotietosopimusEdustusoikeus = Luottotietosopimus.extend({
  url: "luottotietojen_haku_sopimus_edustusoikeus/",
});

const LuottotietosopimusAloitaAllekirjoitus = Luottotietosopimus.extend({
  url: "luottotietojen_haku_sopimus_aloita_allekirjoitus/:paamies_id/",
});

const RefreshToken = Base.extend({
  method: "POST",
  hooks: {
    before({ payload, next }) {
      payload.url =
        process.env.VUE_APP_INSE_ANITTA_BASE_URL + "/token/refresh/";
      payload.body = {
        refresh: localStorage.getItem("inseRefreshToken"),
      };

      next(payload);
    },
  },
}).on("done", (res) => {
  // Refresh was successful, save new access token
  localStorage.setItem("inseAccessToken", res.body.access);
});

const apiModule = {
  Base,
  Palveluhakemus,
  RefreshToken,
  LuottotietosopimusEdustusoikeus,
  LuottotietosopimusAloitaAllekirjoitus,
};

const apiPlugin = {
  install() {
    Vue.apiInse = apiModule;
    Vue.prototype.$apiInse = apiModule;
  },
};

Vue.use(apiPlugin);

export default apiModule;
