<template>
  <div class="login">
    <div class="login__top">
      <div class="login__wrap">
        <div class="login__col login__col--left pr-6">
          <Logo :logo-key="logoKey" :enable-link="false" class="mx-0" />
        </div>
        <div class="login__col login__col--right pa-6">
          <v-card class="pa-10 text-center">
            <p class="text-subtitle-1">Uudelleenohjataan...</p>
            <Spinner :loading="loading" />
          </v-card>
        </div>
      </div>
    </div>
    <BusinessFooter />
  </div>
</template>

<script>
import { BusinessFooter, Logo } from "@/components";
import { mapGetters } from "vuex";

export default {
  name: "KirjauduUudelleenohjaus",
  components: {
    BusinessFooter,
    Logo,
  },
  data() {
    return {
      loading: true,
      logoKey: "",
    };
  },
  computed: {
    ...mapGetters({
      defaultPageName: "user/defaultPageName",
    }),
  },
  created() {
    this.logoKey = process.env.VUE_APP_LOGO_LOGIN || process.env.VUE_APP_LOGO;

    this.login();
  },
  methods: {
    async login() {
      this.loading = true;

      try {
        const access_token = new URL(location.href).searchParams.get(
          "access_token"
        );
        const refresh_token = new URL(location.href).searchParams.get(
          "refresh_token"
        );

        if (!refresh_token) {
          window.location.replace(process.env.VUE_APP_ANITTA_ONLINE_URL);

          return;
        }

        localStorage.setItem("accessToken", access_token);
        localStorage.setItem("refreshToken", refresh_token);

        // onko tekeytyminen käynnissä
        const kayttajaId = new URL(location.href).searchParams.get(
          "tekeydy_kayttajaksi"
        );

        if (kayttajaId) {
          await this.$store.dispatch("user/tekeydy", {
            kayttajaId,
          });
          return;
        }

        const siirrySivulle = this.defaultPageName;
        await this.$router.replace({ name: siirrySivulle });
      } catch (e) {
        this.$sentryCaptureCustom(e);
        this.$naytaVaroitusilmoitus(
          "Uudelleenohjaus ei onnistunut. Ole hyvä ja kirjaudu sisään."
        );
        await this.$router.replace({
          name: "kirjaudu",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/loginStyles.scss";
</style>
