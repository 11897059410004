<template>
  <v-form v-model="valid" @submit.prevent="submit">
    <v-menu
      v-model="loppumispaivaMenu"
      :close-on-content-click="false"
      max-width="290"
      nudge-bottom="40"
    >
      <template #activator="{ on }">
        <v-text-field
          label="Päättymispäivä"
          :value="$dateFnsFormat(value.loppumispaiva)"
          clearable
          readonly
          placeholder="Voimassa toistaiseksi"
          persistent-placeholder
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="value.loppumispaiva"
        locale="fi-FI"
        first-day-of-week="1"
        no-title
        @change="loppumispaivaMenu = false"
      />
    </v-menu>
    <v-text-field
      v-model="value.selite"
      label="Selite"
      :rules="[$validationRules.required]"
      class="required"
    />
    <PeruutaHyvaksyPainikerivi
      :submit-disabled="!valid || loading"
      :submit-loading="loading"
      @close="$emit('close')"
    />
  </v-form>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";

export default {
  name: "FormOhisuoritus",
  mixins: [FormMixin],
  data() {
    return {
      loppumispaivaMenu: false,
    };
  },
};
</script>

<style lang="scss" scoped></style>
