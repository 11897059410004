var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "mt-n1 mb-1", attrs: { cols: "6" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: _vm.vuosiOtsikko,
                  disabled: _vm.disabled,
                  "error-messages": _vm.virheet,
                  hint: _vm.infoteksti,
                  rules: [_vm.$validationRules.isInteger],
                  "persistent-hint": "",
                },
                model: {
                  value: _vm.pvm.vuosi,
                  callback: function ($$v) {
                    _vm.$set(_vm.pvm, "vuosi", $$v)
                  },
                  expression: "pvm.vuosi",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "mt-n1 mb-1", attrs: { cols: "3" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: _vm.$t("Kuukausi"),
                  disabled: _vm.disabled,
                  error: !!_vm.virheet.length,
                  rules: [_vm.$validationRules.isInteger, _vm.tarkistaKuukausi],
                },
                model: {
                  value: _vm.pvm.kuukausi,
                  callback: function ($$v) {
                    _vm.$set(_vm.pvm, "kuukausi", $$v)
                  },
                  expression: "pvm.kuukausi",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "mt-n1 mb-1", attrs: { cols: "3" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: _vm.$t("Päivä"),
                  disabled: _vm.disabled,
                  error: !!_vm.virheet.length,
                  rules: [_vm.$validationRules.isInteger, _vm.tarkistaPaiva],
                },
                model: {
                  value: _vm.pvm.paiva,
                  callback: function ($$v) {
                    _vm.$set(_vm.pvm, "paiva", $$v)
                  },
                  expression: "pvm.paiva",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }