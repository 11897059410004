import permission from "@/plugins/permission";
import {
  doRequestWithTimeout,
  naytaVirheilmoitus,
  sentryCaptureCustom,
} from "@/utils/misc";
import api from "@/plugins/api";
import router from "@/utils/router";
import { HttpError, AuthenticationError } from "@/utils/errors";

/**
 * Käyttäjään liittyvät tiedot ja oikeudet päivitetään sisäänkirjautumisen yhteydessä. Lisäksi
 * tiedot päivitetään mikäli 'valittuPaamies' on päämies, jolla ei ole lainkaan oikeuksia tai
 * päämiestä ei löydy 'permissionsPaamiehet'-objektista. Näin voi käydä, jos päämies on luotu
 * nykyisen session aikana. Tarkistukset tehdään navigoinnin yhteydessä (router.js) ja
 * päämieslistauksen haussa (AutocompletePaamies.vue).
 */

export default {
  namespaced: true,
  state: {
    username: "",
    firstName: "",
    lastName: "",
    naytettavaNimi: "",
    email: "",
    vainYksiPaamies: true,
    lukemattomatViestit: 0,
    permissions: [],
    permissionsPaamiehet: {},
    valittuPaamies: {},
    paamiehet: [],
    palvelusopimukset: [],
    tiivisNakyma: localStorage.getItem("tiivisNakyma") === "true",
    isSuperuser: false,
    isStaff: false,
    tekeytyminenKaynnissa: false,
  },
  getters: {
    dataLoaded: (state) => {
      return state.username.length > 0;
    },
    defaultPageName: () => {
      let name = "perinta";
      if (process.env.VUE_APP_LOGIN_REDIRECT_ROUTE) {
        name = process.env.VUE_APP_LOGIN_REDIRECT_ROUTE;
      } else if (permission.match(router.resolve({ name: "tyopoyta" }).route)) {
        name = "tyopoyta";
      }
      return name;
    },
    valittuPaamies: (state) => {
      return state.valittuPaamies;
    },
    salliPaamiehenTyhjennys: (state) => {
      return (
        permission.checkSinglePerm(
          "onlinerestapi.onlinerestapi_paamieshallinta"
        ) || !state.vainYksiPaamies
      );
    },
    getPaamiesPerms: (state) => (paamiesId) => {
      if (!(paamiesId in state.permissionsPaamiehet)) {
        return [];
      }

      return state.permissionsPaamiehet[paamiesId];
    },
    getValittuPaamiesPerms: (state, getters) => {
      const { valittuPaamies } = state;
      if (!valittuPaamies || !valittuPaamies.id) {
        return [];
      }

      return getters.getPaamiesPerms(valittuPaamies.id);
    },
    tarkistetaankoPaamieskohtaisetOikeudet: (state) => {
      return !state.isStaff && !state.isSuperuser;
    },
    onkoPalvelusopimuskayttaja: (state) => {
      return (
        permission.checkSinglePerm(
          "onlinerestapi.onlinerestapi_palvelusopimus"
        ) &&
        !state.isStaff &&
        !state.isSuperuser
      );
    },
  },
  mutations: {
    setUsername(state, payload) {
      state.username = payload;
    },
    setFirstName(state, payload) {
      state.firstName = payload;
    },
    setLastName(state, payload) {
      state.lastName = payload;
    },
    setNaytettavaNimi(state, payload) {
      state.naytettavaNimi = payload;
    },
    setEmail(state, payload) {
      state.email = payload;
    },
    setLukemattomatViestit(state, payload) {
      state.lukemattomatViestit = payload;
    },
    setVainYksiPaamies(state, payload) {
      state.vainYksiPaamies = payload;
    },
    setPaamiehet(state, payload) {
      state.paamiehet = payload;
    },
    setPermissions(state, payload) {
      state.permissions = payload;
    },
    setPermissionsPaamiehet(state, payload) {
      state.permissionsPaamiehet = payload;
    },
    setValittuPaamies(state, payload) {
      state.valittuPaamies = payload || {};
    },
    setValittuPaamiesMaksutiedot(state, payload) {
      state.valittuPaamies.laskutusmaksutieto.iban = payload.iban;
      state.valittuPaamies.laskutusmaksutieto.bic = payload.bic;
    },
    setValittuPaamiesPaamiesasetukset(state, payload) {
      state.valittuPaamies.viestikirjeeseen = payload.viestikirjeeseen;
    },
    setValittuPaamiesPalvelusopimus(state, payload) {
      state.valittuPaamies.palvelusopimus = state.palvelusopimukset.filter(
        (ps) => ps.id === payload.palvelusopimusId
      )[0];
    },
    setTiivisNakyma(state, payload) {
      state.tiivisNakyma = payload;
      localStorage.setItem("tiivisNakyma", payload);
    },
    setIsSuperuser(state, payload) {
      state.isSuperuser = payload;
    },
    setIsStaff(state, payload) {
      state.isStaff = payload;
    },
    setTekeytyminenKaynnissa(state, payload) {
      state.tekeytyminenKaynnissa = payload;
    },
    setPalvelusopimukset(state, payload) {
      state.palvelusopimukset = payload;
    },
  },
  actions: {
    setUserWithoutPaamiehet({ commit, state }, payload) {
      return new Promise((resolve) => {
        commit("setUsername", payload.username);
        commit("setFirstName", payload.first_name);
        commit("setLastName", payload.last_name);
        commit("setNaytettavaNimi", payload.naytettava_nimi);
        commit("setEmail", payload.email);
        commit("setLukemattomatViestit", payload.lukemattomat_viestit_kpl);
        commit("setVainYksiPaamies", payload.vain_yksi_paamies);
        commit("setPermissions", payload.permissions);
        commit("setPermissionsPaamiehet", payload.permissions_paamies);
        commit("setIsSuperuser", payload.is_superuser);
        commit("setIsStaff", payload.is_staff);
        commit("setTekeytyminenKaynnissa", payload.tekeytyminen_kaynnissa);
        if (!state.valittuPaamies.id)
          commit("setValittuPaamies", payload.oletuspaamies);
        resolve();
      });
    },

    clearDetails({ commit }) {
      return new Promise((resolve) => {
        commit("setUsername", "");
        commit("setFirstName", "");
        commit("setLastName", "");
        commit("setNaytettavaNimi", "");
        commit("setEmail", "");
        commit("setLukemattomatViestit", 0);
        commit("setVainYksiPaamies", true);
        commit("setValittuPaamies", {});
        commit("setPermissions", []);
        commit("setPermissionsPaamiehet", {});
        commit("setPaamiehet", []);
        commit("setIsSuperuser", false);
        commit("setIsStaff", false);
        commit("setTekeytyminenKaynnissa", false);
        commit("asetukset/setTilaukset", [], { root: true });
        commit("yleinen/setLaskulisatietoAvaimet", [], { root: true });
        commit("yleinen/setLaskuMyyntiData", [], { root: true });

        resolve();
      });
    },

    async login({ dispatch }, loginData) {
      try {
        const loginRequest = await doRequestWithTimeout(
          api.Token,
          {
            method: "POST",
            body: loginData,
          },
          "doSingleRequest"
        );

        if (!loginRequest.success) {
          if (loginRequest.result.status === 401) {
            throw new AuthenticationError(loginRequest);
          } else {
            throw new HttpError(loginRequest, "Kirjautuminen epäonnistui!");
          }
        }

        // Haetaan käyttäjätiedot jo ennen uudelleenohjausta, jotta navigointi ei jää välitilaan
        // odottamaan niiden latautumista ennen seuraavalle sivulle saapumista.
        await dispatch("getUserDetails");
        await dispatch("uudelleenohjaaKirjautunutKayttaja");
      } catch (error) {
        naytaVirheilmoitus(error.message);
        if (error.name !== "AuthenticationError") sentryCaptureCustom(error);
      }
    },

    async uudelleenohjaaKirjautunutKayttaja({ getters }) {
      const nextPath = router.currentRoute.query.next;

      if (nextPath) {
        await router.replace({ path: nextPath });
      } else {
        const name = getters.defaultPageName;
        await router.replace({ name });
      }
    },

    async logout({ commit, dispatch }) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("inseAccessToken");
      localStorage.removeItem("inseRefreshToken");

      dispatch("clearDetails");

      const redirectUrl = process.env.VUE_APP_LOGOUT_REDIRECT_URL;
      if (redirectUrl) {
        window.location.replace(redirectUrl);

        return;
      }

      await router.push({ name: "kirjaudu" });
    },

    async getUserDetails({ dispatch }) {
      await Promise.all([
        dispatch("getUserWithoutPaamiehet"),
        dispatch("haePaamiehet"),
      ]);
      // Vaatii tiedot käyttäjän oikeuksista, joten ei voi tehdä samalla kuin edeltävät kyselyt
      await dispatch("yleinen/haeAlustustiedot", null, { root: true });
    },

    async getUserWithoutPaamiehet({ dispatch }) {
      const request = await api.User.doRequest({ method: "GET" });
      if (!request.success) throw new HttpError(request);
      await dispatch("setUserWithoutPaamiehet", request.result.body);
    },

    async haePalvelusopimukset({ commit }) {
      const request = await doRequestWithTimeout(
        api.Palvelusopimus,
        {},
        "doSingleRequest"
      );

      if (!request.success) throw new HttpError(request);

      commit("setPalvelusopimukset", request.result.body);
    },

    async haeValittuPaamies({ commit }, paamies_id) {
      try {
        const request = await api.Paamies.pushRequest({
          url: `${paamies_id}/`,
          method: "GET",
        });

        if (!request.success)
          throw new HttpError(request, "Päämiestietojen haku epäonnistui");

        const paamies = request.result.body;
        commit("setValittuPaamies", paamies);
      } catch (error) {
        sentryCaptureCustom(error);
      }
    },
    async haePaamiehet({ commit, state, dispatch }) {
      const request = await api.UserPaamies.doRequest({ method: "GET" });

      if (!request.success) throw new HttpError(request);

      commit("setPaamiehet", request.result.body);
    },

    async tekeydy({ dispatch, commit, getters }, payload) {
      const res = await doRequestWithTimeout(api.TokenTekeydy, {
        method: "GET",
        params: { id: payload.kayttajaId },
      });
      if (!res.success) throw new HttpError(res);

      localStorage.setItem(
        "tekeytyjaAccess",
        localStorage.getItem("accessToken")
      );
      localStorage.setItem(
        "tekeytyjaRefresh",
        localStorage.getItem("refreshToken")
      );

      localStorage.setItem("accessToken", res.result.body.access);
      localStorage.setItem("refreshToken", res.result.body.refresh);

      dispatch("clearDetails");

      // Haetaan tekeytyneen käyttäjän tiedot, jotta tiedetään mille sivulle
      // voidaan uudelleenohjata
      await dispatch("getUserDetails");

      const siirrySivulle = getters.defaultPageName;
      if (router.currentRoute.name !== siirrySivulle) {
        await router.replace({ name: siirrySivulle });
      }
    },

    async poistaTekeytymisenAsetukset({ dispatch }, payload) {
      localStorage.setItem(
        "accessToken",
        localStorage.getItem("tekeytyjaAccess")
      );
      localStorage.setItem(
        "refreshToken",
        localStorage.getItem("tekeytyjaRefresh")
      );

      localStorage.removeItem("tekeytyjaAccess");
      localStorage.removeItem("tekeytyjaRefresh");

      dispatch("clearDetails");
    },

    async lopetaTekeytyminen({ dispatch, state }, payload) {
      if (router.currentRoute.name !== payload.siirrySivulle) {
        await router.replace({ name: payload.siirrySivulle });
      }
      dispatch("poistaTekeytymisenAsetukset");

      // haetaan käyttäjän tiedot jos ollaan samalla sivulle kuin minne
      // oltaisiin siirrytty
      await dispatch("getUserDetails");
    },
  },
};
