var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pp-container" },
    [
      _c(
        "v-dialog",
        {
          staticClass: "dialog-palvelutasot",
          model: {
            value: _vm.palvelutasoVertailu,
            callback: function ($$v) {
              _vm.palvelutasoVertailu = $$v
            },
            expression: "palvelutasoVertailu",
          },
        },
        [
          _c("v-card", { staticClass: "dialog-card" }, [
            _c(
              "div",
              { staticClass: "dialog-lists" },
              [
                _c(
                  "div",
                  { staticClass: "dialog-list" },
                  [
                    _c("h1", [_vm._v("Ominaisuudet")]),
                    _c(
                      "v-list",
                      _vm._l(
                        _vm.valittavatPalvelusopimukset[0]?.ominaisuudet
                          .ominaisuudet_palvelutaso,
                        function (ominaisuus) {
                          return _c(
                            "v-list-item",
                            {
                              key: ominaisuus.teksti,
                              staticClass: "feature-yes list-item-left",
                            },
                            [_c("h2", [_vm._v(_vm._s(ominaisuus.teksti))])]
                          )
                        }
                      ),
                      1
                    ),
                  ],
                  1
                ),
                _vm._l(
                  _vm.valittavatPalvelusopimukset,
                  function (palvelusopimus) {
                    return _c(
                      "div",
                      { key: palvelusopimus.nimi, staticClass: "dialog-list" },
                      [
                        _c("h1", [_vm._v(_vm._s(palvelusopimus.nimi))]),
                        _c(
                          "v-list",
                          _vm._l(
                            palvelusopimus.ominaisuudet
                              .ominaisuudet_palvelutaso,
                            function (ominaisuus) {
                              return _c(
                                "v-list-item",
                                { key: ominaisuus.teksti },
                                [
                                  ominaisuus.hinta
                                    ? _c(
                                        "div",
                                        { staticClass: "feature-yes" },
                                        [
                                          _c("h4", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  ominaisuus.hinta.toFixed(2)
                                                ) +
                                                "€ "
                                            ),
                                            ominaisuus.hinta_extra
                                              ? _c("span", [
                                                  _vm._v(
                                                    " (" +
                                                      _vm._s(
                                                        ominaisuus.hinta_extra.toFixed(
                                                          2
                                                        )
                                                      ) +
                                                      "€*) "
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]),
                                        ]
                                      )
                                    : ominaisuus.sisaltyy_sopimukseen === true
                                    ? _c(
                                        "div",
                                        { staticClass: "feature-yes" },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "check" },
                                            [_vm._v("mdi-check")]
                                          ),
                                        ],
                                        1
                                      )
                                    : ominaisuus.sisaltyy_sopimukseen === false
                                    ? _c(
                                        "div",
                                        { staticClass: "feature-no" },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "close" },
                                            [_vm._v("mdi-close")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    )
                  }
                ),
                _c("h4", { staticClass: "feature-yes" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("common:serviceApplication.extraPrice")) +
                      " "
                  ),
                ]),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "dialog-close" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { color: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.palvelutasoVertailu = false
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("common:close")) + " ")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "pp-subheader1" }, [
        _vm._v(" " + _vm._s(_vm.$t("common:testForFreeNoCommitment")) + " "),
      ]),
      _c("div", { staticClass: "pp-subheader2" }, [
        _vm._v(" " + _vm._s(_vm.$t("common:openBillsWillBeTakenCareOf")) + " "),
      ]),
      _c("div", { staticClass: "pp-billing" }, [
        _c("div", { staticClass: "billing-switch" }, [
          _c(
            "div",
            {
              staticClass: "switch-left",
              class: { switchOn: _vm.laskutusjaksoOnKuukausi },
              on: { click: _vm.valitseLaskutusjaksoKuukausi },
            },
            [_c("h2", [_vm._v(_vm._s(_vm.$t("monthUpperCase")))])]
          ),
          _c(
            "div",
            {
              staticClass: "switch-right",
              class: { switchOn: _vm.laskutusjaksoOnVuosi },
              on: { click: _vm.valitseLaskutusjaksoVuosi },
            },
            [_c("h2", [_vm._v(_vm._s(_vm.$t("yearUpperCase")))])]
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "pp-service" },
        _vm._l(_vm.valittavatPalvelusopimukset, function (palvelusopimus) {
          return _c(
            "div",
            { key: palvelusopimus.id, staticClass: "service-level" },
            [
              _c("div", { staticClass: "level-header" }, [
                _c(
                  "div",
                  { staticClass: "header-top" },
                  [
                    _vm.laskutusjakso === _vm.laskutusjaksoVuosi
                      ? _c(
                          "v-chip",
                          {
                            staticClass: "save-chip",
                            attrs: { color: "success" },
                          },
                          [
                            palvelusopimus.id ===
                            _vm.valittavatPalvelusopimukset[0].id
                              ? _c("span", [
                                  _vm._v(
                                    " " + _vm._s(_vm.saastaBasicYear) + " "
                                  ),
                                ])
                              : _vm._e(),
                            palvelusopimus.id ===
                            _vm.valittavatPalvelusopimukset[1].id
                              ? _c("span", [
                                  _vm._v(" " + _vm._s(_vm.saastaProYear) + " "),
                                ])
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                    _c("h1", [_vm._v(_vm._s(palvelusopimus.nimi))]),
                  ],
                  1
                ),
                _vm.laskutusjakso === _vm.laskutusjaksoKuukausi
                  ? _c("h1", [
                      _vm._v(
                        " " +
                          _vm._s(palvelusopimus.kkhinta) +
                          " €/" +
                          _vm._s(_vm.$t("common:monthShorthand")) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                _vm.laskutusjakso === _vm.laskutusjaksoVuosi
                  ? _c("h1", [
                      _vm._v(
                        " " +
                          _vm._s(palvelusopimus.vuosihinta) +
                          " €/" +
                          _vm._s(_vm.$t("common:yearLowerCase")) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "div",
                { staticClass: "level-features" },
                _vm._l(
                  _vm.ominaisuudetTarkeat(
                    palvelusopimus.ominaisuudet.ominaisuudet_palvelutaso
                  ),
                  function (ominaisuus) {
                    return _c(
                      "div",
                      {
                        key: ominaisuus.teksti,
                        staticClass: "features-feature",
                      },
                      [
                        ominaisuus.sisaltyy_sopimukseen
                          ? _c("v-icon", { staticClass: "check" }, [
                              _vm._v(" mdi-check "),
                            ])
                          : _c("v-icon", { staticClass: "close" }, [
                              _vm._v("mdi-close"),
                            ]),
                        ominaisuus.sisaltyy_sopimukseen
                          ? _c("h2", [
                              _vm._v(" " + _vm._s(ominaisuus.teksti) + " "),
                            ])
                          : _c("h2", { staticClass: "feature-no" }, [
                              _vm._v(" " + _vm._s(ominaisuus.teksti) + " "),
                            ]),
                      ],
                      1
                    )
                  }
                ),
                0
              ),
              _c(
                "div",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-active",
                      on: {
                        click: function ($event) {
                          return _vm.valitsePalvelutaso(palvelusopimus)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("common:serviceApplication:select")) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          )
        }),
        0
      ),
      _c(
        "div",
        {
          staticClass: "compare",
          on: {
            click: function ($event) {
              _vm.palvelutasoVertailu = true
            },
          },
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("common:serviceApplication:seeAllContractFeatures")
              ) +
              " "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }