import BaseSelaa from "@/store/modules/baseSelaa";

export default class KayttajatSelaa extends BaseSelaa {
  state() {
    return {
      ...super.state(),
      apiName: "Kayttajat",
    };
  }
}
