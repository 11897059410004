<template>
  <v-form ref="formSopimusHyvita" v-model="valid" @submit.prevent="submit">
    <v-row>
      <v-col cols="12">
        <v-select
          v-model="value.tyyppi"
          :label="$t('common:type')"
          type="text"
          :items="tyyppiItems"
          :menu-props="{ zIndex: '999' }"
          :rules="[$validationRules.required]"
          class="required"
        />
        <v-menu
          v-model="alkamispaivaMenuAuki"
          :close-on-content-click="true"
          max-width="290"
          nudge-bottom="40"
        >
          <template #activator="{ on }">
            <v-text-field
              :label="$t('common:paymentDate')"
              :value="$dateFnsFormat(value.maksupaiva)"
              :rules="[$validationRules.required]"
              class="required"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="value.maksupaiva"
            :locale="$languageLocale()"
            first-day-of-week="1"
            no-title
            @change="maksupaivaMenu = false"
          />
        </v-menu>
        <v-select
          v-model="value.suunta"
          :label="$t('common:direction')"
          type="text"
          :items="suuntaItems"
          item-value="value"
          item-text="selite"
          :menu-props="{ zIndex: '999' }"
          :rules="[$validationRules.required]"
          class="required"
        />
        <v-text-field
          v-model="value.summa"
          type="number"
          :label="$t('common:taxableAmount')"
          required
          class="required"
          :rules="[
            $validationRules.requiredLoose,
            $validationRules.zeroOrBigger,
          ]"
        />
      </v-col>
    </v-row>
    <PeruutaHyvaksyPainikerivi
      :submit-disabled="!valid || loading"
      :submit-loading="loading"
      @close="$emit('close')"
    />
  </v-form>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";

export default {
  name: "FormSopimusHyvita",
  mixins: [FormMixin],
  data() {
    return {
      alkamispaivaMenuAuki: false,
      suuntaItems: [],
      tyyppiItems: [],
    };
  },
  watch: {
    onAuki: {
      immediate: true,
      handler(arvo) {
        if (arvo) {
          this.$nextTick(() => {
            this.$refs.formSopimusHyvita.resetValidation();
          });
        }
      },
    },
  },
  created() {
    this.haeLomakkeenVaihtoehdot();
    this.value.maksupaiva = new Date().toISOString().substr(0, 10);
  },
  methods: {
    async haeLomakkeenVaihtoehdot() {
      try {
        const request = await this.$doRequestWithTimeout(
          this.$api.Sopimukset,
          {
            url: "toiminnot/hyvita/kenttien_valinnat",
          },
          "pushRequest"
        );

        if (!request.success) throw new this.$HttpError(request);

        this.tyyppiItems = request.result.body.tyypit;
        this.value.tyyppi = this.tyyppiItems[0];
        this.suuntaItems = request.result.body.suunnat.sort((a, b) =>
          a.selite > b.selite ? 1 : -1
        );
        this.value.suunta = this.suuntaItems[0].value;
      } catch (e) {
        this.$oletusVirheenkasittely(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
