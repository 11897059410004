<template>
  <ul :class="classObj">
    <slot />
  </ul>
</template>

<script>
export default {
  name: "DefList",
  props: {
    bordered: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
  },
  computed: {
    classObj() {
      return ["pl-0", "defList", this.bordered ? "defList--bordered" : null];
    },
  },
};
</script>

<style lang="scss">
.defList {
  display: table;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;

  &__item {
    display: table-row;

    &:first-child {
      .defList__label,
      .defList__value {
        border-top: 0;
      }
    }

    &--edit {
      &:first-child {
        .defList__label,
        .defList__value {
          padding: 0;
        }
      }

      input {
        font-size: 14px;
      }
    }
  }

  &__label,
  &__value {
    padding: 8px 0;
    display: table-cell;

    .defList--bordered & {
      border-top: solid 1px lighten(#000, 85);
    }

    &--tiivis {
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }

  &__label {
    white-space: nowrap;
    padding-right: 1.5em;
    font-weight: bold;

    &--emphasized {
      font-weight: 900;
      letter-spacing: 0.05em;
    }
  }

  &__value {
    width: 100%;
  }
}
</style>
