<template>
  <div class="formTable__row">
    <slot />
  </div>
</template>

<script>
export default {
  name: "FormTableRow",
};
</script>

<style lang="scss" scoped>
.formTable__row {
  display: table-row;
}
</style>
