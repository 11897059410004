var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tyopoyta" }, [
    _c(
      "div",
      { staticClass: "t-header sticky" },
      [
        _c(
          "v-toolbar",
          {
            staticClass: "px-2",
            attrs: { color: "primary", flat: "", tabs: "" },
          },
          [
            _vm.$route.meta.title
              ? _c("v-toolbar-title", [
                  _vm._v(
                    " Tervetuloa " + _vm._s(_vm.user.naytettavaNimi) + " "
                  ),
                ])
              : _vm._e(),
            _c("v-spacer"),
          ],
          1
        ),
      ],
      1
    ),
    !_vm.user.paamiehet || !_vm.user.paamiehet.length
      ? _c(
          "div",
          { staticClass: "t-spinner" },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { staticClass: "d-flex justify-center align-center" },
                  [
                    !_vm.user.paamiehet || !_vm.user.paamiehet.length
                      ? _c("Spinner", {
                          attrs: {
                            "div-class": "text-center mt-12",
                            justify: "center",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          {
            class: {
              tcontent:
                _vm.laskutusKaytossa &&
                _vm.$permission.checkSinglePerm(
                  "onlinerestapi.onlinerestapi_lasku"
                ),
              tcontent2: !(
                _vm.laskutusKaytossa &&
                _vm.$permission.checkSinglePerm(
                  "onlinerestapi.onlinerestapi_lasku"
                )
              ),
            },
          },
          [
            _c(
              "div",
              { attrs: { id: "tc-filter" } },
              [
                Boolean(_vm.palvelusopimustrialPaattyy)
                  ? _c(
                      "v-alert",
                      { staticClass: "ma-4", attrs: { type: "info" } },
                      [
                        _vm._v(
                          " Kokeilujakso päättyy " +
                            _vm._s(
                              _vm.$dateFnsFormat(_vm.palvelusopimustrialPaattyy)
                            ) +
                            " "
                        ),
                      ]
                    )
                  : _vm._e(),
                Boolean(_vm.palvelusopimusMaksamatta)
                  ? _c(
                      "v-alert",
                      { staticClass: "ma-4", attrs: { type: "error" } },
                      [
                        _vm._v(
                          " Cloud-tilisi lasku on maksamatta. Tilisi suljetaan " +
                            _vm._s(
                              _vm.$dateFnsFormat(_vm.palvelusopimusMaksamatta)
                            ) +
                            " ellei laskua makseta. Sulkemisen jälkeen et voi enää luoda uusia laskuja ja toimeksiantoja. "
                        ),
                      ]
                    )
                  : _vm._e(),
                Boolean(_vm.palvelusopimusPaattyy)
                  ? _c(
                      "v-alert",
                      { staticClass: "ma-4", attrs: { type: "warning" } },
                      [
                        _vm._v(
                          " Cloud-tilisi tilaus on loppumassa " +
                            _vm._s(
                              _vm.$dateFnsFormat(_vm.palvelusopimusPaattyy)
                            ) +
                            ". Loppumisen jälkeen et voi enää luoda uusia laskuja ja toimeksiantoja. "
                        ),
                      ]
                    )
                  : Boolean(_vm.palvelusopimusPaattynyt)
                  ? _c(
                      "v-alert",
                      { staticClass: "ma-4", attrs: { type: "error" } },
                      [
                        _vm._v(
                          " Cloud-tilisi tilaus on päättynyt " +
                            _vm._s(
                              _vm.$dateFnsFormat(_vm.palvelusopimusPaattynyt)
                            ) +
                            ". Et voi enää luoda uusia laskuja ja toimeksiantoja. "
                        ),
                      ]
                    )
                  : _vm._e(),
                !_vm.user.vainYksiPaamies
                  ? _c(
                      "v-row",
                      { staticClass: "pb-1" },
                      [
                        _c(
                          "v-col",
                          { staticClass: "d-flex flex-column" },
                          [
                            _c(
                              "v-card",
                              {
                                staticClass:
                                  "paamies-card pa-6 pt-8 d-flex flex-column flex-grow-1 tyopoyta__gradient",
                              },
                              [
                                _c("CardTitle", {
                                  attrs: { text: "Päämies", center: true },
                                }),
                                !_vm.user.vainYksiPaamies
                                  ? _c("AutocompletePaamies", {
                                      attrs: {
                                        outlined: true,
                                        dense: true,
                                        "emit-empty": false,
                                        label: "",
                                      },
                                      on: {
                                        "autocomplete-input": function (
                                          $event
                                        ) {
                                          return _vm.haePaamiehentiedot(
                                            _vm.user.valittuPaamies.id
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.user.valittuPaamies,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.user,
                                            "valittuPaamies",
                                            $$v
                                          )
                                        },
                                        expression: "user.valittuPaamies",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c("div", { attrs: { id: "tc-btns" } }, [
              _vm.laskutusKaytossa &&
              _vm.$permission.checkSinglePerm(
                "onlinerestapi.onlinerestapi_lasku"
              ) &&
              _vm.$permission.checkSinglePerm(
                "onlinerestapi.onlinerestapi_lasku_uusi"
              )
                ? _c(
                    "div",
                    { attrs: { id: "tc-btns-inner" } },
                    [
                      _c("TyopoytaPikanappain", {
                        attrs: {
                          text: "Uusi lasku",
                          icon: "invoice-add",
                          to: { name: "laskutus-uusi" },
                        },
                      }),
                      _c("TyopoytaPikanappain", {
                        attrs: {
                          text: "Uusi asiakas",
                          icon: "customer-person",
                          to: {
                            name: "laskutus-asiakas-selaa",
                            params: {
                              uusiAsiakas: true,
                            },
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _vm.laskutusKaytossa &&
            _vm.$permission.checkSinglePerm("onlinerestapi.onlinerestapi_lasku")
              ? _c(
                  "div",
                  { attrs: { id: "tc-myynti" } },
                  [
                    _c("TyopoytaMyynninKuvaaja", {
                      attrs: {
                        "lasku-myynti-data": _vm.yleinen.laskuMyyntiData,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "b-combo", on: { load: _vm.initializeTabs } },
              [
                _c(
                  "v-card",
                  { staticClass: "l-container" },
                  [
                    _c(
                      "v-tabs",
                      { attrs: { grow: "" } },
                      [
                        _vm.laskutusKaytossa &&
                        _vm.$permission.checkSinglePerm(
                          "onlinerestapi.onlinerestapi_lasku"
                        )
                          ? _c(
                              "v-tab",
                              {
                                staticClass: "primary--text",
                                on: {
                                  click: function ($event) {
                                    _vm.selectedTab = "laskutus"
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("invoicing")) + " ")]
                            )
                          : _vm._e(),
                        _vm.perintaKaytossa &&
                        _vm.$permission.checkSinglePerm(
                          "onlinerestapi.onlinerestapi_toimeksianto"
                        )
                          ? _c(
                              "v-tab",
                              {
                                staticClass: "primary--text",
                                on: {
                                  click: function ($event) {
                                    _vm.selectedTab = "perinta"
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("collection")) + " ")]
                            )
                          : _vm._e(),
                        _vm.rahoitusKaytossa &&
                        _vm.$permission.checkSinglePerm(
                          "onlinerestapi.onlinerestapi_rahoitus"
                        )
                          ? _c(
                              "v-tab",
                              {
                                staticClass: "primary--text",
                                on: {
                                  click: function ($event) {
                                    _vm.selectedTab = "rahoitus"
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("financing")) + " ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "tab-content" }, [
                      _vm.selectedTab === "laskutus"
                        ? _c(
                            "div",
                            { staticClass: "tab-laskutus" },
                            [
                              _c("v-lazy", { staticClass: "lazy" }, [
                                _c(
                                  "div",
                                  { staticClass: "chart-container" },
                                  [
                                    _vm.ladataanLaskutilastoja
                                      ? _c("Spinner", {
                                          attrs: {
                                            justify: "center",
                                            teksti: "Ladataan tilastoja",
                                          },
                                        })
                                      : _vm.paamiehellaEiLaskutilastoDataa
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-no-data text-center pa-12",
                                          },
                                          [_vm._v(" Ei ole dataa. ")]
                                        )
                                      : _c("ChartPie", {
                                          attrs: {
                                            "chart-data": _vm.laskutilastoData,
                                            height: 400,
                                            width: 600,
                                          },
                                        }),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.selectedTab === "perinta"
                        ? _c("div", { staticClass: "tab-perinta" }, [
                            _c(
                              "div",
                              { staticClass: "chart-container" },
                              [
                                _vm.ladataanPerintatilastoja
                                  ? _c("Spinner", {
                                      attrs: {
                                        justify: "center",
                                        teksti: "Ladataan tilastoja",
                                      },
                                    })
                                  : _vm.paamiehellaEiPerintatilastoDataa
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "font-no-data text-center pa-12",
                                      },
                                      [_vm._v(" Ei ole dataa. ")]
                                    )
                                  : _c("ChartPie", {
                                      attrs: {
                                        "chart-data": _vm.perintatilastoData,
                                        height: 400,
                                        width: 600,
                                      },
                                    }),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm.selectedTab === "rahoitus"
                        ? _c(
                            "div",
                            { staticClass: "tab-rahoitus" },
                            [
                              _c(
                                "v-lazy",
                                { staticClass: "lazy" },
                                [
                                  _vm.ladataanRahoitettavanYhteenvetoa
                                    ? _c("Spinner", {
                                        staticClass: "rahoitus-object",
                                        attrs: {
                                          justify: "center",
                                          teksti:
                                            "Ladataan rahoituksen yhteenvetoa",
                                        },
                                      })
                                    : _vm.paamiehellaEiRahoitustilastoDataa
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-no-data text-center pa-12 rahoitus-object",
                                        },
                                        [_vm._v(" Ei ole dataa. ")]
                                      )
                                    : _c("RahoitettavanYhteenveto", {
                                        attrs: {
                                          rahoitusdata:
                                            _vm.rahoitettavanYhteenveto[0]
                                              .limiitti,
                                        },
                                      }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
    _vm.user.isSuperuser ? _c("div", { attrs: { id: _vm.versio } }) : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }