var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-data-table", {
    attrs: {
      items: _vm.items,
      headers: _vm.tableHeadersLaskuliite,
      "hide-default-footer": _vm.items.length <= 10,
      dense: _vm.tiivisNakyma,
    },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function ({ item }) {
          return [
            _c(
              "TableRow",
              { key: `${item.id}-liiterivi` },
              [
                item.nimi
                  ? [
                      _c("td", [
                        _c("strong", [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.tyyppi === "laskun kuva"
                                  ? "Laskun kuva"
                                  : "Liite"
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("td", [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.haeLiite(item)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.nimi))]
                        ),
                      ]),
                      _c("td", { staticClass: "text-no-wrap" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$dateFnsFormat(item.luotu)) + " "
                        ),
                      ]),
                      _c("td"),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _vm.salliPoisto && item.tyyppi !== "laskun kuva"
                            ? _c("v-tooltip", {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  text: "",
                                                  icon: "",
                                                  small: _vm.tiivisNakyma,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.avaaDialogPoistaTiedosto(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    small: _vm.tiivisNakyma,
                                                  },
                                                },
                                                [_vm._v("delete")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "default",
                                      fn: function () {
                                        return [
                                          _c("span", [_vm._v("Poista liite")]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              })
                            : _vm._e(),
                          _c("v-tooltip", {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              text: "",
                                              icon: "",
                                              small: _vm.tiivisNakyma,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.haeLiite(item)
                                              },
                                            },
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                small: _vm.tiivisNakyma,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " anitta-icon-file-download "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "default",
                                  fn: function () {
                                    return [_c("span", [_vm._v("Lataa liite")])]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                    ]
                  : item.kirjepohja
                  ? [
                      _c("td", [
                        _c("strong", [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.kirjepohja.tyyppi.includes("-sms-")
                                  ? "SMS"
                                  : "Kirje"
                              ) +
                              ": " +
                              _vm._s(item.kirjepohja.nimi) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("td", [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.haeLaskukopio(item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.laskukopionTiedostonNimi(item)) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _c("td", { staticClass: "text-no-wrap" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$dateFnsFormat(item.luotu)) + " "
                        ),
                      ]),
                      _c(
                        "td",
                        [
                          item.lahetysaika
                            ? [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.kuitattu_lahetetyksi
                                        ? "Kuitattu lähetetyksi:"
                                        : "Lähetetty:"
                                    ) +
                                    " " +
                                    _vm._s(
                                      _vm.$dateFnsFormat(
                                        item.kuitattu_lahetetyksi,
                                        "d.M.yyyy HH:mm"
                                      )
                                    ) +
                                    " "
                                ),
                                _c("br"),
                              ]
                            : item.haluttu_lahetyspaiva
                            ? [
                                _vm._v(
                                  " Lähdössä " +
                                    _vm._s(
                                      _vm.$dateFnsFormat(
                                        item.haluttu_lahetyspaiva
                                      )
                                    ) +
                                    " "
                                ),
                                _c("br"),
                              ]
                            : _vm._e(),
                          item.lahetystila
                            ? [
                                _vm._v(" " + _vm._s(item.lahetystila) + " "),
                                _c("br"),
                              ]
                            : _vm._e(),
                          _vm._v(
                            " " +
                              _vm._s(`Lähetystapa: ${item.lahetystapa_ui}`) +
                              " "
                          ),
                        ],
                        2
                      ),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          !item.lahetysaika &&
                          _vm.$permission.checkSinglePerm(
                            "onlinerestapi.onlinerestapi_lasku_kirje_kuittaa"
                          ) &&
                          _vm.sallitaankoSMSToimenpide(item)
                            ? _c("v-tooltip", {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  text: "",
                                                  icon: "",
                                                  small: _vm.tiivisNakyma,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.kuittaaKirjeLahetetyksi(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    small: _vm.tiivisNakyma,
                                                  },
                                                },
                                                [_vm._v("mark_email_read")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "default",
                                      fn: function () {
                                        return [
                                          _c("span", [
                                            _vm._v("Kuittaa lähetetyksi"),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              })
                            : _vm._e(),
                          _vm.salliPoisto &&
                          _vm.tyyppi === _vm.laskutyypit.LASKU &&
                          _vm.$permission.checkSinglePerm(
                            "onlinerestapi.onlinerestapi_lasku_laskukopio_poista"
                          ) &&
                          item.lahetysaika === null &&
                          _vm.sallitaankoSMSToimenpide(item)
                            ? _c("v-tooltip", {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  text: "",
                                                  icon: "",
                                                  small: _vm.tiivisNakyma,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.avaaDialogPoistaLaskukopio(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    small: _vm.tiivisNakyma,
                                                  },
                                                },
                                                [_vm._v("delete")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "default",
                                      fn: function () {
                                        return [
                                          _c("span", [
                                            _vm._v("Poista laskukirje"),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              })
                            : item.kuitattu_lahetetyksi
                            ? _c("v-tooltip", {
                                key: `${item.id}-kuitattu`,
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "div",
                                            _vm._g(
                                              {
                                                staticClass:
                                                  "v-btn v-btn--icon v-btn--round v-btn--text margin-r-6px",
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    small: _vm.tiivisNakyma,
                                                    disabled: "",
                                                  },
                                                },
                                                [_vm._v("check")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "default",
                                      fn: function () {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              " Kuitattu lähetetyksi " +
                                                _vm._s(
                                                  _vm.$dateFnsFormat(
                                                    item.kuitattu_lahetetyksi,
                                                    "d.M.yyyy HH:mm"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              })
                            : _vm._e(),
                          _c("v-tooltip", {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              text: "",
                                              icon: "",
                                              small: _vm.tiivisNakyma,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.haeLaskukopio(item)
                                              },
                                            },
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                small: _vm.tiivisNakyma,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " anitta-icon-file-download "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "default",
                                  fn: function () {
                                    return [_c("span", [_vm._v("Lataa")])]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }