<template>
  <v-card ref="wrap" :width="width" :height="width">
    <div v-if="title">
      <v-row align="center" :class="titleClass">
        <v-col class="px-4">
          <h2 v-if="title">
            {{ title }}
            <TooltipInfo
              v-if="tooltipInfoTeksti"
              :text="tooltipInfoTeksti"
              icon-color="primary"
            />
          </h2>
        </v-col>
      </v-row>
    </div>
    <div v-if="$slots['default']" ref="content" :class="contentClass">
      <slot />
    </div>
  </v-card>
</template>

<script>
export default {
  name: "CardDialogNotModal",
  props: {
    width: {
      type: String,
      required: false,
      default() {
        return "500";
      },
    },
    title: {
      type: String,
      required: false,
      default() {
        return "";
      },
    },
    scrollToBottom: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    contentClass: {
      type: String,
      required: false,
      default() {
        return "px-4 pb-4";
      },
    },
    titleClass: {
      type: String,
      required: false,
      default() {
        return "pa-4 mx-0";
      },
    },
    footerClass: {
      type: String,
      required: false,
      default() {
        return "pa-4";
      },
    },
    tooltipInfoTeksti: {
      type: String,
      required: false,
      default() {
        return "";
      },
    },
  },
  watch: {
    dialogAuki: {
      handler(arvo) {
        if (arvo) {
          this.$nextTick().then(() => {
            this.$refs["wrap"].$el.parentElement.scrollTop = 0;
          });
        }
      },
    },
  },
  updated() {
    // Vieritä näkymä alas, kun dialogissa on tehty jokin korkeutta kasvattava toiminto,
    // kuten esim. viestin lähettäminen
    this.$nextTick().then(() => {
      if (this.scrollToBottom && this.$refs["content"] && this.$refs["wrap"]) {
        this.$refs["wrap"].$el.parentElement.scrollTop =
          this.$refs["content"].clientHeight;
      }
    });
  },
};
</script>

<style lang="scss" scoped></style>
