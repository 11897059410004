/**
 * Anittan Django REST -backendiin (onlinerestapi) yhteyden ottava API-moduuli.
 */

import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import fetch from "@apicase/adapter-fetch";
import { ApiService } from "@apicase/services";
import router from "@/utils/router";
import {
  naytaOnnistumisilmoitus,
  naytaVaroitusilmoitus,
  naytaVirheilmoitus,
} from "@/utils/misc";
const { isNavigationFailure, NavigationFailureType } = VueRouter;

// Base service
const BaseService = new ApiService({
  adapter: fetch,
  url: process.env.VUE_APP_API_ROOT,
  mode: "cors",
  hooks: {
    // When request is finished
    async done({ result, next }) {
      if (typeof result.body.ui_viesti !== "undefined") {
        naytaOnnistumisilmoitus(result.body.ui_viesti);
      }

      next(result);
    },

    // When request has failed
    async fail({ result, next }) {
      kasitteleVirheilmoitus(result, "ui_virhe");
      kasitteleVirheilmoitus(result, "non_field_errors");
      next(result);
    },
  },
});

function kasitteleVirheilmoitus(result, errorName) {
  let errorText = "";
  let virheilmoitus = "";

  // palautuukin lista virheitä
  if (Array.isArray(result.body)) {
    virheilmoitus = result.body[0][errorName];
  } else {
    virheilmoitus = result.body[errorName];
  }

  if (typeof virheilmoitus !== "undefined") {
    if (Array.isArray(virheilmoitus)) {
      virheilmoitus.forEach((virhe) => {
        errorText += virhe;
      });
    } else {
      errorText = virheilmoitus;
    }

    if (errorText.length) {
      naytaVirheilmoitus(errorText);
      return;
    }
  }
}

// Service with authentication
// Access token will be injected to headers of the every request.
// If access token is expired, the service will try to refresh it.
const WithAuthService = BaseService.extend({
  hooks: {
    before({ payload, next }) {
      // Inject access token to each request. Access token may change, so we always get the latest token from the localStorage
      payload.headers = {
        ...payload.headers,
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      };

      next(payload);
    },

    // If request has failed
    async fail({ done, payload, result, retry, next }) {
      switch (result.status) {
        // Unauthorized access (token has expired?)
        case 401: {
          // Kaksivaiheinen tunnistautuminen puuttuu
          if (result.body.detail === "ei_tunnistautunut") {
            if (router.currentRoute.name !== "mfa") {
              try {
                await router.replace({
                  name: "mfa",
                  query: {
                    next: router.currentRoute.query.next,
                  },
                });
              } catch (e) {
                // After login many requests are made at the same time. This causes a race condition
                // and all requests are trying to navigate to mfa route. This causes router to throw
                // navigation cancelled failure, but in this case it can be simply ignored.
                if (!isNavigationFailure(e, NavigationFailureType.cancelled)) {
                  throw e;
                }
              }
            }

            return;
          }

          // Try to refresh token
          const { success } = await RefreshToken.doUniqueRequest();

          // Token refresh was successful
          if (success) {
            // Update payload headers
            payload.headers = {
              ...payload.headers,
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
            };

            retry(payload);
          } else {
            // Token refresh has failed. Clear tokens and redirect to the login page.

            // Hacky solution to not spam same operations when multiple requests expire at the
            // same time. There is probably a better way, but apicase is antique and the whole
            // apicode should be replaced anyway.
            if (localStorage.getItem("accessToken") === null) {
              return;
            }
            naytaVaroitusilmoitus("Kirjautumisistuntosi on vanhentunut.");

            await store.dispatch("user/logout");
            // Ei kutsuta nextiä, koska ei haluta palauttaa epäonnistunutta pyyntöä ja laukaista error handlereita.
            // FIXME Tämä aiheuttaa turhan virheviestin, jos kutsussa on käytetty
            // doRequestWithTimeout. Ongelma korjaantuu, kun apicase vaihdetaan pois.
          }

          break;
        }

        // Forbidden
        case 403: {
          if (router.currentRoute.name !== "error403")
            router.replace({ name: "error403" });

          next(result);

          break;
        }

        // By default, we just proceed to the next request in the queue
        default: {
          next(result);

          break;
        }
      }
    },
  },
});

// Get tokens and save them to the localStorage
const Token = BaseService.extend({
  url: "token/",
}).on("done", (res) => {
  localStorage.setItem("accessToken", res.body.access);
  localStorage.setItem("refreshToken", res.body.refresh);
});

// Obtain a new access token by offering refresh token.
const RefreshToken = BaseService.extend({
  method: "POST",
  hooks: {
    before({ payload, next }) {
      payload.url = process.env.VUE_APP_API_ROOT + "/token/refresh/";
      payload.body = {
        refresh: localStorage.getItem("refreshToken"),
      };

      next(payload);
    },
  },
}).on("done", (res) => {
  // Refresh was successful, save new access token
  localStorage.setItem("accessToken", res.body.access);
});

const Palveluhakemus = BaseService.extend({
  url: "palveluhakemus",
});

const MFAToken = WithAuthService.extend({
  url: "mfa/token/",
}).on("done", (res) => {
  localStorage.setItem("accessToken", res.body.access);
  localStorage.setItem("refreshToken", res.body.refresh);
});

const TokenTekeydy = WithAuthService.extend({
  url: "token/tekeydy/:id",
});

const User = WithAuthService.extend({
  url: "user/",
});

const UserLukemattomatViestitKpl = WithAuthService.extend({
  url: "user/lukemattomat_viestit_kpl/",
}).on("done", (res) => {
  const kpl =
    res && res.body && res.body.lukemattomat_viestit_kpl
      ? res.body.lukemattomat_viestit_kpl
      : 0;
  store.commit("user/setLukemattomatViestit", kpl);
});

// Get all päämies objects for the current user
const UserPaamies = WithAuthService.extend({
  url: "user/paamies/",
});

// Get päämies statistics
const UserPaamiesPerintatilasto = WithAuthService.extend({
  url: "user/paamiesperintatilasto",
});

const UserPaamiesLaskutilasto = WithAuthService.extend({
  url: "user/paamieslaskutilasto",
});

const Paamies = WithAuthService.extend({
  url: "paamies",
});

const Paamieshallinta = WithAuthService.extend({
  url: "paamieshallinta",
});

const PaamiesLuottotietosopimus = WithAuthService.extend({
  url: "paamiesluottotieto/luottotietojen_haku_sopimus/:paamies_id/",
});

const PaamiesLuottotietosopimusTarkistaAllekirjoitus = WithAuthService.extend({
  url: "paamiesluottotieto/luottotietojen_haku_sopimus_tarkista_allekirjoitus/:paamies_id/",
});

const Toimeksiannot = WithAuthService.extend({
  url: "toimeksianto",
});

const ToimeksiannotCsv = WithAuthService.extend({
  url: "toimeksiannot/csv",
});

const ToimeksiannotCount = WithAuthService.extend({
  url: "toimeksianto/count",
});

const Toimeksianto = WithAuthService.extend({
  url: "toimeksianto/:id",
});

const Laskut = WithAuthService.extend({
  url: "lasku",
});

const LaskutCsv = WithAuthService.extend({
  url: "laskut/csv",
});

const LaskutCount = WithAuthService.extend({
  url: "lasku/count",
});

const Lasku = WithAuthService.extend({
  url: "lasku/:id",
});

const LaskulisatietoAvaimet = WithAuthService.extend({
  url: "lasku_lisatieto_avaimet",
});

const Laskusisalto = WithAuthService.extend({
  url: "laskusisalto",
});

const Ostolaskut = WithAuthService.extend({
  url: "ostolasku",
});

const Ostolasku = WithAuthService.extend({
  url: "ostolasku/:id",
});

const OstolaskuTilita = WithAuthService.extend({
  url: "ostolasku_tilita",
});

const OstolaskuLaskusisalto = WithAuthService.extend({
  url: "ostolasku_laskusisalto",
});

const OstolaskuMaksutoimeksiannot = WithAuthService.extend({
  url: "maksutoimeksiantotiedosto",
});

const OstolaskuMaksutoimeksiantoTiedosto = WithAuthService.extend({
  url: "maksutoimeksiantotiedosto/hae_tiedosto/:id",
});

const ALVTunnus = WithAuthService.extend({
  url: "alvtunnus",
});

const Asiakas = WithAuthService.extend({
  url: "asiakas/:id",
});

const Asiakkaat = WithAuthService.extend({
  url: "asiakas",
});

const AsiakkaatCsv = WithAuthService.extend({
  url: "asiakkaat/csv",
});

const AsiakkaatCount = WithAuthService.extend({
  url: "asiakas/count",
});

const AsiakasLaskulle = WithAuthService.extend({
  url: "asiakas_laskulle/",
});

const AsiakasLaskut = WithAuthService.extend({
  url: "asiakas/:id/laskut/",
});

const AsiakasLuottotiedot = WithAuthService.extend({
  url: "asiakas/toiminnot/luottotiedot/",
});

const Viestit = WithAuthService.extend({
  url: "viesti",
});

const ViestitCount = WithAuthService.extend({
  url: "viesti/count",
});

const Viesti = WithAuthService.extend({
  url: "viesti/:id",
});

const Muistiinpano = WithAuthService.extend({
  url: "muistiinpano/:id",
});

const Finvoice = WithAuthService.extend({
  url: "finvoice",
});

// const RedirectAnittaOnline = WithAuthService.extend({
//   url: 'redirect/luo_anitta_sessio',
// })

const Tuotteet = WithAuthService.extend({
  url: "tuote",
});

const TuotteetCsv = WithAuthService.extend({
  url: "tuotteet/csv",
});

const TuotteetCount = WithAuthService.extend({
  url: "tuote/count",
});
const TuotteetHaku = WithAuthService.extend({
  url: "tuotehaku",
});

const Tuoteryhmat = WithAuthService.extend({
  url: "tuoteryhma",
});
const TuoteryhmatCount = WithAuthService.extend({
  url: "tuoteryhma/count",
});

const UusiSalasana = WithAuthService.extend({
  url: "password_reset",
});

const Sopimukset = WithAuthService.extend({
  url: "sopimus",
});

const SopimuksetCount = WithAuthService.extend({
  url: "sopimus/count",
});

const Sopimus = WithAuthService.extend({
  url: "sopimus/:id",
});

const SopimusLaskusisalto = WithAuthService.extend({
  url: "sopimus_laskusisalto",
});

const Ostotili = WithAuthService.extend({
  url: "ostotili",
});

const Toimittajat = WithAuthService.extend({
  url: "toimittaja",
});

const Toimittaja = WithAuthService.extend({
  url: "toimittaja/:id",
});

const Tiliointitilit = WithAuthService.extend({
  url: "tiliointitili",
});

const Tiliointi = WithAuthService.extend({
  url: "tiliointi/:id",
});

const Yleinen = WithAuthService.extend({
  url: "yleinen",
});

const Velalliset = WithAuthService.extend({
  url: "velallinen",
});

const Raportit = WithAuthService.extend({
  url: "raportti",
});

const RaportitCount = WithAuthService.extend({
  url: "raportti/count",
});

const ReskontraRaportti = WithAuthService.extend({
  url: "reskontraraportti",
});

const RaportitKuukausiraportti = WithAuthService.extend({
  url: "paamieskuukausiraportti",
});

const Tilasto = WithAuthService.extend({
  url: "tilasto",
});

const Tilastorivi = WithAuthService.extend({
  url: "tilastorivi",
});

const FlexmonsterRaportti = WithAuthService.extend({
  url: "flexmonsterraportti",
});

const Tilitys = WithAuthService.extend({
  url: "tilitys",
});

const TilitysCount = WithAuthService.extend({
  url: "tilitys/count",
});

const TilitysKululasku = WithAuthService.extend({
  url: "tilitys_kululasku",
});

const MFA = WithAuthService.extend({
  url: "mfa/",
});

const MFAEmail = WithAuthService.extend({
  url: "mfa/email/",
});

const MFALahetaEmailTunniste = WithAuthService.extend({
  url: "mfa/lahetaemailtunniste",
});

const Kayttajat = WithAuthService.extend({
  url: "kayttajat",
});

const PalvelusopimusPaamieslaskut = WithAuthService.extend({
  url: "palvelusopimuspaamieslaskut/:paamies_id",
});

const Palvelusopimustilaus = WithAuthService.extend({
  url: "palvelusopimustilaus/",
});
const Palvelusopimus = WithAuthService.extend({
  url: "palvelusopimus/",
});
const Aineistolataaja = WithAuthService.extend({
  url: "aineistolataaja/:paamies_id",
});
const AineistolataajaTuo = WithAuthService.extend({
  url: "aineistolataaja_tuo/:aineistolataaja_id/:paamies_id/",
});

// Create module
const apiModule = {
  // RedirectAnittaOnline,
  ALVTunnus,
  Asiakas,
  AsiakasLaskulle,
  AsiakasLaskut,
  AsiakasLuottotiedot,
  Asiakkaat,
  AsiakkaatCsv,
  AsiakkaatCount,
  BaseService,
  Finvoice,
  FlexmonsterRaportti,
  Kayttajat,
  Lasku,
  Aineistolataaja,
  AineistolataajaTuo,
  LaskulisatietoAvaimet,
  Laskusisalto,
  Laskut,
  LaskutCsv,
  LaskutCount,
  MFA,
  MFAEmail,
  MFALahetaEmailTunniste,
  MFAToken,
  Muistiinpano,
  Ostolasku,
  OstolaskuLaskusisalto,
  OstolaskuMaksutoimeksiannot,
  OstolaskuMaksutoimeksiantoTiedosto,
  OstolaskuTilita,
  Ostolaskut,
  Ostotili,
  Paamies,
  Paamieshallinta,
  PaamiesLuottotietosopimus,
  PaamiesLuottotietosopimusTarkistaAllekirjoitus,
  Palveluhakemus,
  PalvelusopimusPaamieslaskut,
  Palvelusopimus,
  Palvelusopimustilaus,
  Raportit,
  RaportitCount,
  RaportitKuukausiraportti,
  RefreshToken,
  ReskontraRaportti,
  Sopimukset,
  SopimuksetCount,
  Sopimus,
  SopimusLaskusisalto,
  Tilasto,
  Tilastorivi,
  Tiliointi,
  Tiliointitilit,
  Tilitys,
  TilitysCount,
  TilitysKululasku,
  Toimeksiannot,
  ToimeksiannotCsv,
  ToimeksiannotCount,
  Toimeksianto,
  Toimittaja,
  Toimittajat,
  Token,
  TokenTekeydy,
  Tuoteryhmat,
  TuoteryhmatCount,
  Tuotteet,
  TuotteetCsv,
  TuotteetCount,
  TuotteetHaku,
  User,
  UserLukemattomatViestitKpl,
  UserPaamies,
  UserPaamiesLaskutilasto,
  UserPaamiesPerintatilasto,
  UusiSalasana,
  Velalliset,
  Viesti,
  Viestit,
  ViestitCount,
  WithAuthService,
  Yleinen,
};

// Create Vue plugin
const apiPlugin = {
  install() {
    Vue.api = apiModule;
    Vue.prototype.$api = apiModule;
  },
};

Vue.use(apiPlugin);

export default apiModule;
