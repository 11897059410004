import { Decimal } from "decimal.js";
import { muotoileDesimaaliluku } from "@/utils/misc";
import store from "@/store";
/**
 * Luokkaa hyödynnetään sekä laskuilla, ostolaskuilla että sopimuksilla
 */
const Laskurivi = class {
  // Read-only -kenttiä ei lähetetä luonnin tai muokkauksen yhteydessä backendiin
  constructor(preset = {}) {
    this.id = null;
    this.koodi = "";
    this.selite = "";
    this.maara = 1;
    this.laatu = "kpl";
    this.ahinta = null;
    this.alvp = 24;
    this.alennusp = null;
    this.alvtunnus_id = null;
    this.alvtunnus = {};
    this.sisalv = false;
    this.laskusisaltolisatieto_set = [];
    this.veroton = null; // Read-only
    this.vero = null; // Read-only
    this.verollinen = null; // Read-only
    this.verotonMuotoiltu = null; // Read-only
    this.veroMuotoiltu = null; // Read-only
    this.verollinenMuotoiltu = null; // Read-only

    Object.assign(this, preset);
    if (preset.alvtunnus && !preset.alvp) this.alvp = preset.alvtunnus.alvp;
    if (preset.alvtunnus_id) this.alvtunnus.id = preset.alvtunnus_id;
    if (preset.alvtunnus_id && !preset.alvtunnus) {
      this.alvtunnus = store.state.yleinen.ALVTunnusItems.find(
        (el) => el.id == preset.alvtunnus_id
      );
    }

    this.calculateTotals();
  }

  static get oletusPreset() {
    return {
      alennusp: 0,
      alvtunnus: store.state.yleinen.ALVTunnusItems[0], // ALV 24 %
      alvtunnus_id: store.state.yleinen.ALVTunnusItems[0]?.id,
    };
  }

  isEmpty() {
    return !this.koodi && !this.selite && !this.ahinta;
  }

  calculateTotals() {
    return new Promise((resolve) => {
      const maara = this.maara ? new Decimal(this.maara) : new Decimal(0);
      const ahinta = this.ahinta ? new Decimal(this.ahinta) : new Decimal(0);
      const alvp = this.alvp ? new Decimal(this.alvp) : new Decimal(0);
      const alennuskerroin = this.alennusp
        ? new Decimal(1) - new Decimal(this.alennusp) / new Decimal(100)
        : new Decimal(1);

      let verollinen = new Decimal(0);
      let veroton = new Decimal(0);
      let vero = new Decimal(0);

      if (this.sisalv) {
        verollinen =
          ahinta && maara
            ? ahinta.mul(maara).mul(alennuskerroin)
            : new Decimal(0);
        veroton = verollinen
          ? verollinen.div(new Decimal(100).plus(alvp).div(new Decimal(100)))
          : new Decimal(0);
        vero =
          alvp && veroton
            ? alvp.div(new Decimal(100)).mul(veroton)
            : new Decimal(0);
      } else {
        veroton =
          ahinta && maara
            ? ahinta.mul(maara.mul(alennuskerroin))
            : new Decimal(0);
        vero =
          alvp && veroton
            ? alvp.div(new Decimal(100)).mul(veroton)
            : new Decimal(0);
        verollinen = veroton && vero ? veroton.plus(vero) : new Decimal(0);
      }

      this.veroton = veroton.toFixed(2);
      this.vero = vero.toFixed(2);
      this.verollinen = verollinen.toFixed(2);
      this.verotonMuotoiltu = muotoileDesimaaliluku(this.veroton);
      this.veroMuotoiltu = muotoileDesimaaliluku(this.vero);
      this.verollinenMuotoiltu = muotoileDesimaaliluku(this.verollinen);

      return resolve();
    });
  }

  getPostData(config = { lahetaId: false }) {
    const postData = {
      ahinta: this.ahinta,
      alvp: this.alvp,
      maara: this.maara,
      selite: this.selite,
      sisalv: this.sisalv,
    };

    if (this.alennusp !== undefined && this.alennusp !== null)
      postData.alennusp = this.alennusp;
    if (this.alvtunnus && this.alvtunnus.id)
      postData.alvtunnus_id = this.alvtunnus.id;
    if (this.koodi) postData.koodi = this.koodi;
    if (this.laatu) postData.laatu = this.laatu;
    if (config.lahetaId) postData.id = this.id;
    if (this.laskusisaltolisatieto_set.length) {
      postData.laskusisaltolisatieto_set = this.laskusisaltolisatieto_set;
    }

    return postData;
  }
};

export default Laskurivi;
