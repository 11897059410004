var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-autocomplete", {
    ref: "autocompletePaamies",
    staticClass: "show-hidden-child-on-hover",
    class: { required: _vm.requiredClass },
    attrs: {
      items: _vm.paamiehet,
      loading: _vm.paamiesLoading,
      "hide-no-data": _vm.paamiesLoading,
      "item-text": _vm.getPaamiesNimi,
      "item-value": "id",
      label: _vm.label,
      placeholder: _vm.placeholder,
      "persistent-placeholder": _vm.persistentPlaceholder,
      "return-object": "",
      clearable: _vm.clearable,
      "cache-items": _vm.multiple,
      chips: _vm.multiple,
      "deletable-chips": _vm.multiple,
      multiple: _vm.multiple,
      counter: _vm.valintojenMaksimiLkm,
      rules: _vm.validointiSaannotKoottu,
      disabled: _vm.disabled,
      outlined: _vm.outlined,
      dense: _vm.dense,
    },
    on: { input: _vm.kasitteleValinta },
    scopedSlots: _vm._u(
      [
        {
          key: "item",
          fn: function ({ item }) {
            return [
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", [
                    _vm._v(_vm._s(item.nimi) + " (" + _vm._s(item.id) + ")"),
                  ]),
                  _vm.tarkistaLaskuEsto && item.esta_laskun_luonti
                    ? _c("v-list-item-subtitle", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "common:autocompletePaamies.createInvoiceNotPermitted"
                              )
                            ) +
                            " "
                        ),
                      ])
                    : _vm.tarkistaToimeksiantoEsto &&
                      item.esta_toimeksiannon_luonti
                    ? _c("v-list-item-subtitle", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "common:autocompletePaamies.createAssignmentNotPermitted"
                              )
                            ) +
                            " "
                        ),
                      ])
                    : item.postiosoite
                    ? _c(
                        "v-list-item-subtitle",
                        [
                          item.postiosoite.postiosoite
                            ? [
                                _vm._v(
                                  " " +
                                    _vm._s(item.postiosoite.postiosoite) +
                                    ", "
                                ),
                              ]
                            : _vm._e(),
                          item.postiosoite.postinumero
                            ? [
                                _vm._v(
                                  " " +
                                    _vm._s(item.postiosoite.postinumero) +
                                    " "
                                ),
                              ]
                            : _vm._e(),
                          item.postiosoite.postitoimipaikka
                            ? [
                                _vm._v(
                                  " " +
                                    _vm._s(item.postiosoite.postitoimipaikka) +
                                    " "
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          },
        },
        {
          key: "no-data",
          fn: function () {
            return [
              _c("p", { staticClass: "pa-4" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "common:autocompletePaamies.searchFoundNoCreditors"
                      )
                    ) +
                    " "
                ),
              ]),
            ]
          },
          proxy: true,
        },
        _vm.paamieshallinta &&
        _vm.$permission.checkSinglePerm(
          "onlinerestapi.onlinerestapi_paamieshallinta"
        )
          ? {
              key: "append-outer",
              fn: function () {
                return [
                  _c("LomakenappiIkonilla", {
                    attrs: {
                      ikoni: "add",
                      infoteksti: _vm.$t(
                        "autocompletePaamies.createNewCreditor"
                      ),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("open-dialog-paamies")
                      },
                    },
                  }),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
    model: {
      value: _vm.paamies,
      callback: function ($$v) {
        _vm.paamies = $$v
      },
      expression: "paamies",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }