<template>
  <div ref="wrapper">
    <template v-if="ehto">
      <router-link
        :to="{ name: to.name, params: to.params }"
        :class="linkkiClass"
      >
        <slot />
      </router-link>
    </template>
    <template v-else-if="renderoiIlmanLinkkia">
      <slot />
    </template>
  </div>
</template>

<script>
export default {
  props: {
    to: {
      type: Object,
      required: true,
    },
    ehto: {
      type: Boolean,
      required: true,
    },
    renderoiIlmanLinkkia: {
      type: Boolean,
      required: false,
      default() {
        return true;
      },
    },
    customContextmenu: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    eiAlleviivausta: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
  },
  computed: {
    linkkiClass() {
      return this.eiAlleviivausta ? "ei-alleviivausta" : "";
    },
  },
  mounted() {
    if (this.customContextmenu && this.ehto === true) {
      this.$refs.wrapper.addEventListener("contextmenu", this.contextmenu);
    }
  },
  methods: {
    contextmenu(e) {
      this.$emit("contextmenu", e);
    },
  },
};
</script>

<style lang="scss" scoped>
.ei-alleviivausta {
  text-decoration: none;
}
</style>
