<template>
  <v-form v-model="valid" @submit.prevent="submit">
    <v-select
      v-model="value.paamies_id"
      :label="$t('common:newCreditor')"
      class="required"
      :items="paamiehet"
      :item-text="(paamies) => paamies.nimi + ' (' + paamies.id + ')'"
      item-value="id"
      :rules="[$validationRules.required]"
    />
    <PeruutaHyvaksyPainikerivi
      :submit-disabled="!valid || loading"
      :submit-loading="loading"
      @close="$emit('close')"
    />
  </v-form>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";

export default {
  name: "FormSopimusLaskutusjaksoMuokkaa",
  mixins: [FormMixin],
  props: {
    paamiehet: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
