<template>
  <v-form v-model="valid" @submit.prevent="submit">
    <v-menu
      v-model="maksupaivaMenu"
      :close-on-content-click="false"
      max-width="290"
      nudge-bottom="40"
    >
      <template #activator="{ on }">
        <v-text-field
          :label="$t('common:paymentDate')"
          :value="$dateFnsFormat(value.maksupaiva)"
          readonly
          :rules="[$validationRules.required]"
          class="required"
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="value.maksupaiva"
        :max="maxDate"
        :locale="$languageLocale()"
        first-day-of-week="1"
        no-title
        @change="maksupaivaMenu = false"
      />
    </v-menu>
    <v-text-field
      v-model="value.summa"
      type="number"
      :label="$t('common:sum')"
      :rules="[$validationRules.required, $validationRules.biggerThanZero]"
      class="required"
    />

    <v-select
      v-if="kohdistukset.length"
      v-model="value.kohdistus"
      :label="$t('common:formOhisuoritus.alignTransaction')"
      :rules="[$validationRules.requiredObject]"
      :items="kohdistukset"
      item-text="label"
      item-value="value"
      class="required"
      return-object
      :menu-props="{ nudgeBottom: '40' }"
      z-index="999"
    />
    <PeruutaHyvaksyPainikerivi
      :submit-disabled="!valid || loading"
      :submit-loading="loading"
      @close="$emit('close')"
    />
  </v-form>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";

export default {
  name: "FormOhisuoritus",
  mixins: [FormMixin],
  props: {
    kohdistukset: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      maksupaivaMenu: false,
    };
  },
  computed: {
    maxDate() {
      return new Date().toISOString().substr(0, 10);
    },
  },
};
</script>

<style lang="scss" scoped></style>
