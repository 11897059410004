var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$permission.checkSinglePerm(
    "onlinerestapi.onlinerestapi_luottotieto"
  ) && _vm.luottotietoModuuli
    ? _c(
        "div",
        [
          _c("CardTitle", {
            attrs: {
              color: "black--text",
              text: _vm.$t("common:creditRating.creditRatingContract"),
              center: false,
            },
          }),
          _c("LuottotietoSopimus"),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }