var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "phone" }, [
    _c(
      "div",
      { staticClass: "phone-cc" },
      [
        _c("v-select", {
          attrs: {
            outlined: _vm.outlined,
            items: _vm.countryCodesFormatted,
            "item-value": "countryCode",
            "item-text": "comboCode",
          },
          model: {
            value: _vm.countryCode,
            callback: function ($$v) {
              _vm.countryCode = $$v
            },
            expression: "countryCode",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "phone-nn" },
      [
        _c("v-text-field", {
          attrs: {
            outlined: _vm.outlined,
            label: _vm.$t("common:phone"),
            rules: _vm.rulesArr,
          },
          model: {
            value: _vm.nationalNumber,
            callback: function ($$v) {
              _vm.nationalNumber = $$v
            },
            expression: "nationalNumber",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }