import { Decimal } from "decimal.js";
import Laskurivi from "@/class/Laskurivi";
import { Asiakas } from "@/class/Asiakas";
import _ from "lodash";
import { parseISO, format as dateFnsFormat } from "date-fns";

export const ROOLI_LASKUTUS = "LASKUTUS";
export const ROOLI_TAKAAJA = "TAKAAJA";
export const ROOLI_TOIMITUS = "TOIMITUS";
export const ROOLI_LISA_ASIAKAS = "LISA_ASIAKAS";

export const SopimusAsiakas = class {
  static get SOPIMUSROOLI2SELITE() {
    return {
      [ROOLI_LASKUTUS]: "Laskutusasiakas",
      [ROOLI_TAKAAJA]: "Takaaja",
      [ROOLI_TOIMITUS]: "Toimitus",
      [ROOLI_LISA_ASIAKAS]: "Asiakas",
    };
  }

  constructor(preset = {}) {
    this.id = null;
    this.asiakas = new Asiakas();
    this.rooli_id = ROOLI_LASKUTUS;

    Object.assign(this, preset);
    if (preset.asiakas) this.asiakas = new Asiakas(preset.asiakas);
  }
};

const TILA_AKTIIVINEN = "A";
const TILA_MYYTY = "M";
const TILA_PAATTYNYT = "P";
const TILA_HYVAKSYTTAVA = "S";
const TILA_HYLATTY = "H";
const TILA_ERAANNYTETTY = "E";
const TILA_KESKEYTETTY = "K";

export const TILA2ICON = Object.freeze({
  [TILA_AKTIIVINEN]: "repeat-invoice",
  [TILA_MYYTY]: "check",
  [TILA_PAATTYNYT]: "check",
  [TILA_ERAANNYTETTY]: "invoice-notice",
  [TILA_HYVAKSYTTAVA]: "repeat-invoice",
  [TILA_HYLATTY]: "cancel",
  [TILA_KESKEYTETTY]: "pause",
});
export const TILA2COLOR = Object.freeze({
  [TILA_AKTIIVINEN]: "indigo darken-1",
  [TILA_MYYTY]: "green darken-1",
  [TILA_PAATTYNYT]: "green darken-1",
  [TILA_ERAANNYTETTY]: "yellow darken-1",
  [TILA_HYVAKSYTTAVA]: "indigo lighten-3",
  [TILA_HYLATTY]: "grey darken-3",
  [TILA_KESKEYTETTY]: "grey darken-3",
});

export const Sopimus = class {
  static get TILA_AKTIIVINEN() {
    return TILA_AKTIIVINEN;
  }

  static get TILA_MYYTY() {
    return TILA_MYYTY;
  }

  static get TILA_PAATTYNYT() {
    return TILA_PAATTYNYT;
  }

  static get TILA_HYVAKSYTTAVA() {
    return TILA_HYVAKSYTTAVA;
  }

  static get TILA_HYLATTY() {
    return TILA_HYLATTY;
  }

  static get TILA_ERAANNYTETTY() {
    return TILA_ERAANNYTETTY;
  }

  static get TILA_KESKEYTETTY() {
    return TILA_KESKEYTETTY;
  }

  static get LAHETYSPAIVAN_PERUSTEET() {
    return [
      {
        value: "laskutuspaiva",
        selite: "Laskutuspäivä",
      },
      {
        value: "erapaiva",
        selite: "Eräpäivä",
      },
    ];
  }

  static get LASKUTUSJAKSOT() {
    return [
      {
        value: "KK",
        selite: "Kuukausi",
        paivanValintaSelite: "Kuukauden",
      },
      {
        value: "VP",
        selite: "Kuukausi (viimeinen päivä)",
        paivanValintaSelite: "Kuukauden viimeinen päivä",
      },
      {
        value: "KV",
        selite: "Kvartaali",
        paivanValintaSelite: "Kvartaalin 1. kuukauden",
      },
      {
        value: "PU",
        selite: "Puoli vuotta",
        paivanValintaSelite: "Puolivuotiskauden 1. kuukauden",
      },
      {
        value: "VU",
        selite: "Vuosi",
        paivanValintaSelite: "Yksivuotiskauden 1. kuukauden",
      },
    ];
  }

  static get SOPIMUSJAKSOT() {
    return [
      {
        value: "VU",
        selite: "Vuosi",
      },
      {
        value: "KK",
        selite: "Kuukausi",
      },
      {
        value: "Vi",
        selite: "Viikko",
      },
    ];
  }

  static get LASKUN_LAHETYS() {
    return [
      {
        value: "K",
        selite: "Kaikki laskut",
      },
      {
        value: "1",
        selite: "Vain ensimmäinen lasku",
      },
      {
        value: "0",
        selite: "Ei laskun lähetystä",
      },
    ];
  }

  // Read-only -kenttiä ei lähetetä luonnin tai muokkauksen yhteydessä backendiin
  constructor(lisaaTyhjaRivi = false) {
    this.id = null;
    this.alkamispaiva = new Date().toISOString().substring(0, 10);
    this.sopimusasiakas_set = [new SopimusAsiakas()];
    this.erapaiva = "";
    this.kassaalennus_maksuaika = 0;
    this.kassaalennus_prosentti = 0;
    this.kirjepohja_id = "";
    this.kuukautta_aikaisemmin = false; // Aloitetaanko laskutus alkamispäivään nähden kk:ta aiemmin
    this.lahetyspaivanPeruste = "laskutuspaiva"; // Read-only
    this.laji = "";
    this.laskunlahetys = "K";
    this.laskutusjakso = "KK";
    this.laskutuspaiva = "";
    this.loppumispaiva = ""; // Päättymispäivä
    this.maksuehto = 14;
    this.paamies = {};
    this.paivanumero = null; // Laskupäivän tai eräpäivän numero
    this.pm_viite = "";
    this.rivit = [];
    this.seuraava_erapaiva = null; // Ei käytetä sopimusta luodessa
    this.seuraava_laskutuspaiva = null; // Ei käytetä sopimusta luodessa
    this.sopimusaika = 1;
    this.sopimusjakso = "VU";
    this.sopimuslisatieto_set = []; // Read-only
    this.tila = {}; // Read-only
    this.laskunteksti = ""; // Laskulla näkyvä teksti
    this.vero = 0; // Read-only
    this.verollinen = 0; // Read-only
    this.veroton = 0; // Read-only
    this.viitteemme = "";
    this.viitteenne = "";
    this.viivastyskorko = null; // Lasketaan backendissä automaattisesti kun postataan null

    if (lisaaTyhjaRivi) this.rivit.push(new Laskurivi(Laskurivi.oletusPreset));
  }

  asetaLisatietoKuukauttaAikaisemminJasenmuuttujaan() {
    this.kuukautta_aikaisemmin = !!this.sopimuslisatieto_set.find(
      (lisatieto) => {
        return (
          lisatieto.key === "aloita_laskutus_kuukautta_aikaisemmin" &&
          // eslint-disable-next-line
          lisatieto.value === 1
        );
      }
    );
  }

  lisaaRivi(rowIndex) {
    if (typeof rowIndex !== "undefined") {
      // Laskurivin lisäys olemassaolevalla rivillä oltaessa käyttäjän painaessa enteriä.
      this.rivit.splice(rowIndex + 1, 0, new Laskurivi(Laskurivi.oletusPreset));
    } else {
      this.rivit.push(new Laskurivi(Laskurivi.oletusPreset));
    }
  }

  poistaRivi(rowIndex) {
    this.rivit.splice(rowIndex, 1);
  }

  lisaaAsiakas() {
    this.sopimusasiakas_set.push(
      new SopimusAsiakas({ rooli_id: ROOLI_LISA_ASIAKAS })
    );
  }

  asetaLaskutusasiakas(asiakasIndex) {
    // Laskutusasiakkaita saa olla ainoastaan yksi, joten nollataan muut ennen uuden asettamista
    this.sopimusasiakas_set.forEach((sopimusasiakas) => {
      if (sopimusasiakas.rooli_id === ROOLI_LASKUTUS)
        sopimusasiakas.rooli_id = ROOLI_LISA_ASIAKAS;
    });
    this.sopimusasiakas_set[asiakasIndex].rooli_id = ROOLI_LASKUTUS;
  }

  poistaAsiakas(asiakasIndex) {
    // Jos laskutusasiakas poistetaan, asetetaan ensimmäinen asiakas laskutusasiakkaaksi, jotta
    // laskutusasiakkaita on aina yksi
    let lippu = false;
    if (this.sopimusasiakas_set[asiakasIndex].rooli_id === ROOLI_LASKUTUS) {
      lippu = true;
    }
    this.sopimusasiakas_set.splice(asiakasIndex, 1);
    if (lippu) {
      this.sopimusasiakas_set[0].rooli_id = ROOLI_LASKUTUS;
    }
  }

  laskeYhteissummat() {
    // eslint-disable-next-line
    return new Promise(async (resolve) => {
      let veroton = 0;
      let vero = 0;
      let verollinen = 0;

      for (const row of this.rivit) {
        await row.calculateTotals();

        veroton = new Decimal(veroton).plus(row.veroton);
        vero = new Decimal(vero).plus(row.vero);
        verollinen = new Decimal(verollinen).plus(row.verollinen);
      }

      this.veroton = veroton.toFixed(2);
      this.vero = vero.toFixed(2);
      this.verollinen = verollinen.toFixed(2);

      resolve();
    });
  }

  getPostData(paivitys = false) {
    let data = {
      kassaalennus_maksuaika: this.kassaalennus_maksuaika
        ? this.kassaalennus_maksuaika
        : 0,
      kassaalennus_prosentti: this.kassaalennus_prosentti
        ? this.kassaalennus_prosentti
        : 0,
      laji: this.laji,
      laskunlahetys: this.laskunlahetys,
      laskunteksti: this.laskunteksti,
      laskutusjakso: this.laskutusjakso,
      maksuehto: this.maksuehto,
      viitteemme: this.viitteemme,
      viitteenne: this.viitteenne,
    };

    if (this.laskutusjakso !== "VP") {
      if (this.lahetyspaivanPeruste === "erapaiva") {
        data.erapaiva = this.paivanumero;
      } else {
        data.laskutuspaiva = this.paivanumero;
      }
    } else {
      // Asetetaan laskutuspäivän arvoksi 1 kun valitaan kuukauden viimeinen päivä
      data.laskutuspaiva = 1;
    }
    data.loppumispaiva = this.loppumispaiva ? this.loppumispaiva : null;
    if (this.viivastyskorko && typeof this.viivastyskorko === "string") {
      data.viivastyskorko = this.viivastyskorko.replace(",", ".");
    } else {
      data.viivastyskorko = this.viivastyskorko;
    }
    if (this.kirjepohja_id) data.kirjepohja_id = this.kirjepohja_id;

    if (!paivitys) {
      const luontiData = {
        alkamispaiva: this.alkamispaiva,
        aloita_laskutus_kuukautta_aikaisemmin: !!this.kuukautta_aikaisemmin,
        paamies_id: this.paamies.id,
        sopimusaika: this.sopimusaika,
        sopimusasiakas_set: this.sopimusasiakas_set.map((sa) => ({
          asiakas_id: sa.asiakas.id,
          rooli_id: sa.rooli_id,
        })),
        sopimusjakso: this.sopimusjakso,
        sopimuslaskusisalto_set: this.rivitPostDataMuotoon(),
      };
      data = {
        ...luontiData,
        ...data,
      };
    } else {
      data.seuraava_laskutuspaiva = this.seuraava_laskutuspaiva;
    }

    return data;
  }

  rivitPostDataMuotoon() {
    const rivitPostData = [];
    for (const r of this.rivit) {
      const riviData = r.getPostData();
      riviData.id = r.id ? r.id : null;
      riviData.sopimus_id = this.id ? this.id : null;
      rivitPostData.push(riviData);
    }

    return rivitPostData;
  }

  getLaskusisaltoPostData() {
    return {
      sopimuslaskusisalto_set: this.rivitPostDataMuotoon(),
    };
  }

  tasmaakoSeuraavaLaskutuspaiva() {
    if (!this.seuraava_laskutuspaiva) return true;
    if (this.erapaiva) {
      return this.erapaiva == new Date(this.seuraava_erapaiva).getDate();
    } else if (this.laskutuspaiva) {
      return (
        this.laskutuspaiva == new Date(this.seuraava_laskutuspaiva).getDate()
      );
    } else {
      return true;
    }
  }

  seuraavaLaskutuspaivaVaroitus() {
    const paivanValintaSelite = _.find(Sopimus.LASKUTUSJAKSOT, [
      "value",
      this.laskutusjakso,
    ]).paivanValintaSelite.toLowerCase();
    let seuraavaPaivaTeksti = "";
    let laskutusvaliTeksti = "";

    if (this.erapaiva) {
      seuraavaPaivaTeksti = `eräpäivä ${dateFnsFormat(
        parseISO(this.seuraava_erapaiva),
        "d.M.yyyy"
      )}`;
      laskutusvaliTeksti = `eräpäivä ${paivanValintaSelite} ${this.erapaiva}. päivä`;
    } else {
      seuraavaPaivaTeksti = `laskutuspäivä ${dateFnsFormat(
        parseISO(this.seuraava_laskutuspaiva),
        "d.M.yyyy"
      )}`;
      laskutusvaliTeksti = `laskutuspäivä ${paivanValintaSelite} ${this.laskutuspaiva}. päivä`;
    }

    return `
      Sopimuksen seuraava ${seuraavaPaivaTeksti} ei vastaa sopimukselle määritettyä laskutusväliä: ${laskutusvaliTeksti}. Korjaa ohjaustietoja muokkaamalla joko eräpäivä tai seuraava eräpäivä. Poista sitten tarvittaessa laskuttamattomat laskutusaiheet ja lisää laskutusjaksoja. Mikäli päivää ei korjata edellä mainitulla tavalla, muodostuvat tulevat automaattisesti täydennettävät laskutusjaksot väärin.
      `;
  }
};

export default Sopimus;
